/**
 * Understand Demand Analysis Tool.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';

import Button from 'Lib/Button';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import RadioGroupField from 'Lib/ReduxFormFields/RadioGroupField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import { canEditVISN, getCurrentMarketName, getCurrentVISN } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';
import { WorkloadTypeText } from 'Utilities/constants';

import { Form, formValueSelector, getFormValues, reduxForm, reset } from 'redux-form';

import {
    getAnalysisToolAnalysisToolFormSelector,
    getAnalysisToolHspcTypesSelector,
    getAnalysisToolMarketsSelector,
    getAnalysisToolVisnsSelector,
    getAnalysisToolWorkloadTypesSelector
} from '../../Services/selector';
import AnalysisToolTable from './UDATTable';

class UDAnalysisTool extends React.Component {
    static propTypes = {
        marketName: PropTypes.string.isRequired,
        currentSelectedVISN: PropTypes.string.isRequired,
        currentSelectedMarket: PropTypes.string.isRequired,
        currentSelectedComparedVISN: PropTypes.string.isRequired,
        currentSelectedShowHSPCDetail: PropTypes.string.isRequired,
        currentWorkloadType: PropTypes.string.isRequired,
        visns: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        markets: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        workloadTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        analysisToolForm: PropTypes.shape({
            saving: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        saveAnalysisReportTool: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        getATVISNs: PropTypes.func.isRequired,
        getATMarkets: PropTypes.func.isRequired,
        getATWorkloadTypes: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        resetTable: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false
    };

    componentWillMount() {
        const {
            visns,
            markets,
            workloadTypes,
            getATVISNs,
            getATMarkets,
            getATWorkloadTypes
        } = this.props;

        if (visns.list.length === 0) {
            getATVISNs();
        }
        if (markets.list.length === 0) {
            getATMarkets();
        }
        if (workloadTypes.list.length === 0) {
            getATWorkloadTypes();
        }
        // if(hspcTypes.list.length === 0 && )
        //     getPoFacilities(marketName);
    }

    componentWillUpdate(nextProps) {
        const { marketName, resetTable, dispatch } = this.props;
        const { marketName: nextMarketName } = nextProps;

        if (nextMarketName !== marketName) {
            dispatch(reset('UDAnalysisTool'));
            resetTable('analysisToolList');
            resetTable('analysisToolForm');
        }
    }

    componentWillUnmount() {
        const { resetTable, dispatch } = this.props;

        dispatch(reset('UDAnalysisTool'));
        resetTable('analysisToolList');
        resetTable('analysisToolForm');
    }

    resetForm = () => {
        const { dispatch } = this.props;

        dispatch(reset('UDAnalysisTool'));
    };

    onVISNChange = (option, inputName) => {
        if (inputName === 'Visn') {
            this.resetForm();
        }
    };

    formSubmit = () => {
        const {
            marketName,
            currentSelectedVISN,
            currentSelectedMarket,
            currentSelectedComparedVISN,
            currentSelectedShowHSPCDetail,
            currentWorkloadType,
            saveAnalysisReportTool,
            dispatch
        } = this.props;

        const reportTool =
            currentSelectedVISN != null
                ? `VISN::${currentSelectedVISN}${
                      currentSelectedMarket != null
                          ? `; Market::${currentSelectedMarket}${
                                currentSelectedComparedVISN != null
                                    ? `; Comparison VISN::${currentSelectedComparedVISN}${
                                          currentSelectedShowHSPCDetail != null
                                              ? `; Summary::${
                                                    currentSelectedShowHSPCDetail === 'true'
                                                        ? 'Yes'
                                                        : 'No'
                                                }`
                                              : ''
                                      }${
                                          currentWorkloadType != null
                                              ? `; Workload Type::${WorkloadTypeText[currentWorkloadType]}`
                                              : ''
                                      }`
                                    : ''
                            }`
                          : ''
                  }`
                : '';
        saveAnalysisReportTool({
            reportTool,
            market: marketName
        });
        dispatch(reset('UDAnalysisTool'));
    };

    render() {
        const {
            visns,
            markets,
            workloadTypes,
            currentSelectedVISN,
            currentSelectedMarket,
            currentWorkloadType,
            currentSelectedComparedVISN,
            currentSelectedShowHSPCDetail,
            analysisToolForm,
            canEdit,
            handleSubmit
        } = this.props;

        return (
            <div>
                <div className="col-md-8 offset-md-2">
                    <Form style={{ marginTop: '15px' }} onSubmit={handleSubmit(this.formSubmit)}>
                        <div>
                            <DropdownField
                                name="Visn"
                                label="VISN"
                                options={visns.list}
                                loading={visns.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                                onChangeFunction={this.onVISNChange}
                            />
                            <DropdownField
                                name="Market"
                                label="Market"
                                options={_filter(
                                    markets.list,
                                    (item) => currentSelectedVISN === item.VisnCode
                                )}
                                loading={markets.loading}
                                displayField="MarketName"
                                valueField="MarketName"
                                labelPosition={3}
                                inputPosition={9}
                            />
                            <DropdownField
                                name="ComparisonVisn"
                                label="Comparison VISN"
                                options={_filter(
                                    visns.list,
                                    (item) => currentSelectedVISN !== item.ValueField
                                )}
                                loading={visns.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                            />
                            <DropdownField
                                name="WorkloadType"
                                label="Workload Type"
                                options={workloadTypes.list}
                                loading={workloadTypes.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                            />
                            <RadioGroupField
                                name="ShowHspcDetail"
                                headerLabel="Show HSPC Detail:"
                                labelPosition={3}
                                inputPosition={9}
                                firstLabel="Yes"
                                secondLabel="No"
                            />
                            <TextboxField
                                name="ReportTool"
                                label="Analysis Tool Title"
                                disabled
                                labelPosition={3}
                                inputPosition={9}
                                defaultValue={
                                    currentSelectedVISN != null
                                        ? `VISN::${currentSelectedVISN}${
                                              currentSelectedMarket != null
                                                  ? `; Market::${currentSelectedMarket}${
                                                        currentSelectedComparedVISN != null
                                                            ? `; Comparison VISN::${currentSelectedComparedVISN}${
                                                                  currentSelectedShowHSPCDetail !=
                                                                  null
                                                                      ? `; Summary::${
                                                                            currentSelectedShowHSPCDetail ===
                                                                            'true'
                                                                                ? 'Yes'
                                                                                : 'No'
                                                                        }`
                                                                      : ''
                                                              }${
                                                                  currentWorkloadType != null
                                                                      ? `; Workload Type::${WorkloadTypeText[currentWorkloadType]}`
                                                                      : ''
                                                              }`
                                                            : ''
                                                    }`
                                                  : ''
                                          }`
                                        : ''
                                }
                            />
                        </div>
                        <div className="col-xs-12">
                            <div className="form-group">
                                <div style={{ textAlign: 'center', marginTop: 15 }}>
                                    <Button
                                        color="success"
                                        type="submit"
                                        label={
                                            analysisToolForm.saving
                                                ? 'Saving...'
                                                : 'Save for Report'
                                        }
                                        disabled={!canEdit || analysisToolForm.saving}
                                    />
                                </div>
                            </div>
                            <br />
                        </div>
                    </Form>
                </div>
                <AnalysisToolTable
                    visn={currentSelectedVISN}
                    market={currentSelectedMarket}
                    comparedVisn={currentSelectedComparedVISN}
                    showHspcDetail={currentSelectedShowHSPCDetail}
                    workloadType={currentWorkloadType}
                />
            </div>
        );
    }
}

const ReduxFormUDAnalysisTool = reduxForm({
    form: 'UDAnalysisTool',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.Visn) {
            errors.Visn = 'Required';
        }
        if (!values.Market) {
            errors.Market = 'Required';
        }
        if (!values.ComparisonVisn) {
            errors.ComparisonVisn = 'Required';
        }
        if (!values.ShowHspcDetail) {
            errors.ShowHspcDetail = 'Required';
        }
        if (!values.WorkloadType && values.WorkloadType !== 0) {
            errors.WorkloadType = 'Required';
        }
        return errors;
    }
})(UDAnalysisTool);

const selector = formValueSelector('UDAnalysisTool');

const mapDispatchToProps = (dispatch) => {
    return {
        getATVISNs: dispatch.AnalysisToolModel.fetchATVisnsAsync,
        getATMarkets: dispatch.AnalysisToolModel.fetchATMarketsAsync,
        getATWorkloadTypes: dispatch.AnalysisToolModel.fetchATWorkloadTypesAsync,
        getATGapMPCTypes: dispatch.AnalysisToolModel.fetchATGapHspcTypesAsync,
        saveAnalysisReportTool: dispatch.AnalysisToolModel.saveATReportAsync,
        resetTable: dispatch.AnalysisToolModel.resetTable
    };
};

const mapStateToProps = (state) => ({
    formValues: getFormValues('UDAnalysisTool')(state),
    currentSelectedVISN: selector(state, 'Visn'),
    currentSelectedMarket: selector(state, 'Market'),
    currentSelectedComparedVISN: selector(state, 'ComparisonVisn'),
    currentSelectedShowHSPCDetail: selector(state, 'ShowHspcDetail'),
    currentWorkloadType: selector(state, 'WorkloadType'),
    // initialValues: state.demand.patientOriginTool.AnalysisToolForm.data || {},
    analysisToolForm: getAnalysisToolAnalysisToolFormSelector(state),
    workloadTypes: getAnalysisToolWorkloadTypesSelector(state),
    visns: getAnalysisToolVisnsSelector(state),
    markets: getAnalysisToolMarketsSelector(state),
    hspcTypes: getAnalysisToolHspcTypesSelector(state),
    marketName: getCurrentMarketName(state),
    currentVISN: getCurrentVISN(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormUDAnalysisTool);
