import React from 'react';
import { useSelector } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getDrilldownCostDataForFacility } from 'Modules/MatchCapacityDemand/Services/selector';
import PropTypes from 'prop-types';

import MCDSupplyDrilldownColumns from './MCDSupplyDrilldownColumns';

const MCDSupplyDrilldown = React.memo(
    ({
        calculationType,
        canEdit,
        filter,
        updateWorkspaceData,
        isEditable,
        tableType,
        facility
    }) => {
        const tableData = useSelector((state) =>
            getDrilldownCostDataForFacility(state, filter, facility)
        );

        return (
            <ReactDataTable
                list={tableData}
                columns={MCDSupplyDrilldownColumns}
                isExportable={false}
                isSearchable={false}
                isPageable={false}
                isSortable={false}
                striped={false}
                hover={false}
                showHeader={false}
                rowClasses="matchExpandRowColor"
                containerStyle={{
                    height: '100%',
                    marginBottom: '-18px',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    marginTop: '-5px',
                    borderRadius: 0,
                    border: 0
                }}
            />
        );
    }
);

MCDSupplyDrilldown.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    filter: PropTypes.string.isRequired,
    updateWorkspaceData: PropTypes.func,
    isEditable: PropTypes.bool.isRequired,
    tableType: PropTypes.string.isRequired,
    calculationType: PropTypes.string,
    facility: PropTypes.string.isRequired
};

export default MCDSupplyDrilldown;
