/**
 * Report selector.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import { createSelector } from 'reselect';

const getUserReports = state => state.SavedReportModel.userReports
const getTemplateReports = state => state.SavedReportModel.templateReports
const getReportForm = state => state.ReportFormModel.ReportForm

export const getUserReportsSelector       = createSelector(getUserReports, (userReports) => userReports)
export const getTemplateReportsSelector   = createSelector(getTemplateReports, (templateReports) => templateReports)
export const getReportFormSelector        = createSelector(getReportForm, (ReportForm) => ReportForm)