import React, { useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import styled from 'styled-components/macro';

import AccessibleNavigationAnnouncer from 'Lib/AccessibleNavigationAnnouncer';
import { REACT_APP_APPLICATION_ROOT_ROUTE } from 'Utilities/constants';

import { createBrowserHistory } from 'history';

import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import EnvScanModule from '../EnvironmentalScan/Components/ESContainer';
import Home from '../Home';
import MatchModule from '../MatchCapacityDemand/Components/MCDContainer';
import ReportModule from '../Report/Components/ReportContainer';
import StrategicOutlookModule from '../StrategicOutlook/Components/SOContainer';
import UnderstandCapacityModule from '../UnderstandCapacity/Components/UCContainer';
import UnderstandDemandModule from '../UnderstandDemand/Components/UDContainer';
import HSPANavbar from './HSPANavbar';
import SkipLink from './SkipLink';

const Content = styled.div`
    display: flex;
    align-items: stretch;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
    > * {
        width: 100%;
    }
`;

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableCorsCorrelation: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

/**
 *
 */
const App = () => {
    const contentRef = useRef();

    return (
        <div className="appContainer">
            <AccessibleNavigationAnnouncer />
            <SkipLink contentElement={contentRef.current} />
            <HSPANavbar />

            <Content ref={contentRef}>
                <Switch>
                    <Route exact path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/Home`}>
                        <Home />
                    </Route>
                    <Route path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/StrategicOutlook`}>
                        <StrategicOutlookModule />
                    </Route>
                    <Route path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/EnvironmentalScan`}>
                        <EnvScanModule />
                    </Route>
                    <Route path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/UnderstandDemand`}>
                        <UnderstandDemandModule />
                    </Route>
                    <Route path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/UnderstandCapacity`}>
                        <UnderstandCapacityModule />
                    </Route>
                    <Route path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/Match`}>
                        <MatchModule />
                    </Route>
                    <Route path={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/Reports`}>
                        <ReportModule />
                    </Route>
                    <Route>
                        <Redirect to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/Home`} />
                    </Route>
                </Switch>
            </Content>
        </div>
    );
};

export default withAITracking(reactPlugin, App, 'App', 'ai-app-wrapper');
