import React, { Component } from 'react';
import proptypes from 'prop-types';
import './style.css';

export default class Stepper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            compState: this.props.startAtStep,
            navState: this.getNavStates(this.props.startAtStep, this.props.steps)
        };

        this.hidden = {
            display: 'none'
        };
    }
    componentWillUpdate(nextProps, nextState) {
        if(nextProps.currentStep !== this.state.navState.current || nextProps.steps[0].isValid !== this.props.steps[0].isValid){
            this.setNavState(nextProps.currentStep, nextProps.steps);
        }
    }
    // update the header nav states via classes so they can be styled via css
    getNavStates(indx, steps) {
        let styles = [];
        for (let i=0; i<steps.length; i++) {
            if (!steps[i].isValid)
                styles.push('error');
            else if (i < indx)
                styles.push('done');
            else if (i === indx)
                styles.push('doing');
            else
                styles.push('todo');
        }
        return { current: indx, styles }
    }

    // set the nav state
    setNavState(next, steps) {
        this.setState({navState: this.getNavStates(next, steps)});
        if (next < this.props.steps.length) {
            this.setState({compState: next});
        }
    }

    // jumpToStep(evt) {
    //     if(evt.target.value !== undefined){
    //         this.setNavState(evt.target.value, this.props.steps);
    //         this.props.movePageByStepNum(evt.target.value);
    //     }
    // }

    // get the classmame of steps
    getClassName(className, i){
        let liClassName = className + "-" + this.state.navState.styles[i];

        // if step ui based navigation is disabled, then dont highlight step
        if (!this.props.stepsNavigation)
            liClassName += " no-hl";

        return liClassName;
    }

    // onClick={(evt) => {this.jumpToStep(evt)}} 
    renderSteps() {
        return this.props.steps.map((s, i)=> (
            <li className={this.getClassName("progtrckr", i)} key={i} value={i}>
                <em>{i+1}</em>
                <span>{this.props.steps[i].name}</span>
            </li>
        ));
    }

    render() {
        return (
            <div className="multi-step">
                {
                    this.props.showSteps
                        ? <ol className="progtrckr">
                            {this.renderSteps()}
                        </ol>
                        : <span></span>
                }
            </div>
        );
    }
    static propTypes = {
        steps: proptypes.arrayOf(proptypes.shape({
            name: proptypes.string.isRequired
        })).isRequired,
        showSteps: proptypes.bool,
        stepsNavigation: proptypes.bool,
        startAtStep: proptypes.number,
        currentStep: proptypes.number,
        movePageByStepNum: proptypes.func.isRequired,
        invalidPages: proptypes.array,
        formIsValid: proptypes.bool
    }
}

Stepper.defaultProps = {
    showSteps: true,
    stepsNavigation: true,
    startAtStep: 0,
    invalidPages: []
};

