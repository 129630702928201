import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * A number control that allows for bounding by minimum and maximum values.
 *
 * @param {object} props - React props passed into this component
 * @returns {React.ReactNode} the component to render
 */
const NumberInput = (props) => {
    const { onUpdate, value: propValue, min, max } = props;

    const [value, setValue] = useState(propValue);

    const input = useRef(null);
    useEffect(() => {
        input.current.focus();
    });

    const onChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    const onBlur = useCallback(() => {
        const roofCheck = Number.isNaN(max) ? value : Math.min(value, max);
        const floorCheck = Number.isNaN(min) ? roofCheck : Math.max(roofCheck, min);
        onUpdate(floorCheck);
    }, [onUpdate, value, min, max]);

    return (
        <input
            type="number"
            className="form-control"
            min={min}
            max={max}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={input}
        />
    );
};

NumberInput.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number
};

NumberInput.defaultProps = {
    min: undefined,
    max: undefined
};

export default NumberInput;
