import { commaNAFormatter } from 'Utilities/formatters';

const renderNumericColumnDef = (dataField, label) => ({
    accessorKey: dataField,
    header: label,
    meta: {
        headerDisplayName: label,
        columnClassName: 'text-center',
        columnStyle: { whiteSpace: 'pre-wrap', fontSize: 12 },
        cellClassName: 'text-center',
        cellStyle: { fontSize: 12 },
        formatter: commaNAFormatter
    }
});

export default renderNumericColumnDef;
