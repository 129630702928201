import React from 'react';
import Fade from 'react-bootstrap/Fade';
import Spinner from 'react-bootstrap/Spinner';

import styled from 'styled-components/macro';

interface LoadingPanelProps {
    isLoading: boolean;
    label: string;
}

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 100;
    display: flex;
    padding-top: 20rem;
    flex-direction: column;
    align-items: center;
    color: #606060;
`;

const BigSpinner = styled(Spinner)`
    margin-bottom: 1rem;
    width: 3rem;
    height: 3rem;
`;

const Label = styled.span`
    font-size: 1.5rem;
`;

/**
 * A loading panel that will cover the contents of its parent until it's ready.
 *
 * @param props - props passed into this component
 * @param props.label - the loading text message to display (i.e. "Please wait...")
 * @param props.isLoading - when false, the panel will fade away
 * @returns the rendered loading panel
 */
const LoadingPanel = ({ label, isLoading }: LoadingPanelProps): JSX.Element => (
    <Fade in={isLoading} unmountOnExit>
        <Container>
            <BigSpinner animation="border" />
            <Label>{label}</Label>
        </Container>
    </Fade>
);

export default LoadingPanel;
