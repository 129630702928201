/**
 * Capitalize the first character of a string.
 *
 * @param s - a string value
 * @returns a copy of the original string with the first character capitalized
 */
const capitalizeFirstCharacter = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default capitalizeFirstCharacter;
