import React from 'react';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

const INACTIVE_COLOR = '#e0e0e0';

interface StepLinkProps extends LinkProps {
    color: string;
}

const StyledLink = styled(Link)<StepLinkProps>`
    position: relative;
    font-size: 1.2em;
    background-color: ${INACTIVE_COLOR};
    margin: 0 3px;
    color: #303030;

    &:hover {
        background-color: ${(props) => props.color};
        color: #fff;
    }

    &:hover::after {
        border-left-color: ${(props) => props.color};
    }

    .nav-pills &.active {
        background-color: ${(props) => props.color};
    }

    &::before,
    &::after {
        content: ' ';
        position: absolute;
        top: 26px;
        right: -15px;
        width: 0;
        height: 0;
        border-top: 19px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 17px solid ${INACTIVE_COLOR};
        z-index: 2;
    }

    &::before {
        right: auto;
        left: 0;
        border-left: 17px solid #fff;
        z-index: 0;
    }

    .nav-pills .nav-item:first-child &::before {
        border: none;
    }

    .nav-pills .nav-item:last-child &::after {
        border: none;
    }

    &.active::after {
        border-left: 17px solid ${(props) => props.color};
    }
`;

const StepLink: React.FC<StepLinkProps> = (props) => {
    const match = useRouteMatch(props.to as string);

    const classNames = ['nav-link'];

    if (match) {
        classNames.push('active');
    }

    return <StyledLink {...props} className={classNames.join(' ')} />;
};

export default StepLink;
