import React, { useEffect } from 'react';
import Fade from 'react-bootstrap/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AsyncContent from 'Lib/AsyncContent';
import {
    loadFacilityReport,
    selectFacility,
    selectFacilityReport,
    selectFacilityReportLoadingStatus,
    selectLoadedFacility
} from 'Modules/Report/Services/FacilityReport';
import { getDefaultYear } from 'Modules/UserSession/selector';
import { AsyncState } from 'Utilities/constants';

import useFacilityList from '../useFacilityList';
import FacilityReportContent from './FacilityReportContent';
import FacilityReportNavigation from './FacilityReportNavigation';

/**
 * A React hook to retrieve the facility report data for a facility.
 *
 * @param stationNumber - the facility station number
 * @param baseYear - the base year
 * @returns facility report data, along with the loading status
 */
const useFacilityReportData = (stationNumber, baseYear) => {
    const report = useSelector(selectFacilityReport);
    const loadingStatus = useSelector(selectFacilityReportLoadingStatus);
    const loadedFacility = useSelector(selectLoadedFacility);
    const dispatch = useDispatch();

    useEffect(() => {
        if (stationNumber && baseYear) {
            dispatch(loadFacilityReport({ stationNumber, year: baseYear }));
        }
    }, [stationNumber, baseYear, dispatch]);

    const currentLoadingStatus = loadedFacility === stationNumber ? loadingStatus : undefined;

    return { report, loadingStatus: currentLoadingStatus };
};

/**
 * Renders a facility report table containing capacity and supply data across all HSPCs at a
 * facility.
 *
 * @returns the rendered facility report component
 */
const FacilityReport = () => {
    const { stationNumber } = useParams();
    const facility = useSelector((state) => selectFacility(state, stationNumber)) || {};
    const baseYear = useSelector(getDefaultYear);

    const { loadingStatus } = useFacilityList(baseYear);
    const { report, loadingStatus: reportLoadingStatus } = useFacilityReportData(
        stationNumber,
        baseYear
    );

    const isFacilityLoaded = loadingStatus === AsyncState.SUCCESS;
    const isReportLoaded = reportLoadingStatus === AsyncState.SUCCESS;

    return (
        <>
            <Fade in={isFacilityLoaded}>
                <FacilityReportNavigation facility={facility} />
            </Fade>
            <div style={{ position: 'relative', minHeight: 800 }}>
                <AsyncContent
                    isLoading={!isReportLoaded}
                    loadingLabel="Please wait, facility report loading..."
                    loadedLabel="Facility report loaded"
                >
                    <Fade in={isReportLoaded} unmountOnExit>
                        <FacilityReportContent
                            stationNumber={stationNumber}
                            report={report}
                            baseYear={baseYear}
                        />
                    </Fade>
                </AsyncContent>
            </div>
        </>
    );
};

export default FacilityReport;
