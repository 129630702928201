import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from 'react-router-dom';

import _filter from 'lodash/filter';
import _map from 'lodash/map';

import analytics from 'Utilities/analytics';

type ModuleNavigationProps = {
    tabs: Array<{
        path: string;
        label: string;
        disabled?: boolean;
        hidden?: boolean;
    }>;
};

/**
 * Renders a set of Bootstrap toggle button pill tabs that use react-router for navigation.
 *
 * @param props - props passed into this component
 * @param props.tabs - tab configuration
 * @returns the rendered tab navigation component
 */
const ModuleNavigation = ({ tabs }: ModuleNavigationProps): JSX.Element => {
    const location = useLocation();
    const { url } = useRouteMatch();
    const analyticsObj = analytics.getInstance();

    const visibleTabs = _filter(tabs, ({ hidden }) => !hidden);
    const buttons = _map(visibleTabs, ({ path, label, disabled }) => (
        <LinkContainer key={path} to={`${url}/${path}`}>
            <Button
                className="flex-fill"
                type="radio"
                name="navigation"
                disabled={disabled}
                value={`${url}/${path}`}
                active={`${url}/${path}` === location.pathname}
                onClick={() => {
                    if (`${url}/${path}` !== location.pathname) {
                        analyticsObj.trackTabChange(label);
                    }
                }}
            >
                {label}
            </Button>
        </LinkContainer>
    ));

    return <ButtonGroup className="navigation d-flex">{buttons}</ButtonGroup>;
};

export default ModuleNavigation;
