import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormValues, Form } from 'redux-form';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import MultiSelectField from 'Lib/ReduxFormFields/MultiSelectField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import { getVISNList, getMarketList, canEditVISN } from 'Modules/UserSession/selector';
import FormButtonGroup from '../RWFButtonGroup';

class RWFHeader extends React.Component {
    static propTypes = {
        formValues: PropTypes.shape({
            VisnCode: PropTypes.string,
            ReportId: PropTypes.number
        }).isRequired,
        change: PropTypes.func.isRequired,
        untouch: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        currentVISN: PropTypes.string.isRequired,
        marketList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        visnList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        report: PropTypes.shape({
            ReportForm: PropTypes.shape({
                loading: PropTypes.bool
            })
        }).isRequired,
        gapMPCTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        submitting: PropTypes.bool,
        saving: PropTypes.bool,
        generating: PropTypes.bool,
        loading: PropTypes.bool,
        invalid: PropTypes.bool,
        pristine: PropTypes.bool,
        previousPage: PropTypes.func.isRequired,
        currentPage: PropTypes.func.isRequired,
        getReportDropdowns: PropTypes.func.isRequired,
        generateReportById: PropTypes.func.isRequired,
        generateReportByFormData: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false,
        submitting: false,
        saving: false,
        generating: false,
        loading: false,
        invalid: false,
        pristine: true
    };

    onVISNAndMarketSelect = (option, inputName) => {
        const { formValues, getReportDropdowns, change, untouch } = this.props;

        if (inputName === 'MarketName') {
            getReportDropdowns(option, formValues.VisnCode);
        }
        if (inputName === 'VISN') {
            change('MarketName', '');
            untouch('ReportForm', 'MarketName');
        }
    };

    formGenerate = () => {
        const { formValues, generateReportById, generateReportByFormData } = this.props;

        if (formValues.ReportId) {
            generateReportById(formValues.ReportId);
        } else {
            generateReportByFormData(formValues);
        }
    };

    render() {
        const {
            currentVISN,
            marketList,
            visnList,
            report,
            gapMPCTypes,
            previousPage,
            submitting,
            saving,
            generating,
            loading,
            invalid,
            pristine,
            reset,
            currentPage,
            canEdit,
            handleSubmit
        } = this.props;

        return (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
                <TextboxField
                    name="ReportName"
                    label="Report Title"
                    labelPosition={12}
                    inputPosition={12}
                    loading={report.ReportForm.loading}
                />
                <DropdownField
                    name="VisnCode"
                    label="VISN"
                    options={visnList.list}
                    onChangeFunction={this.onVISNAndMarketSelect}
                    displayField="DisplayName"
                    valueField="VisnCode"
                    labelPosition={12}
                    inputPosition={12}
                />
                <DropdownField
                    name="MarketName"
                    label="Market"
                    options={_filter(marketList.list, (item) => currentVISN === item.VisnCode)}
                    onChangeFunction={this.onVisnAndMarketSelect}
                    displayField="MarketName"
                    valueField="MarketName"
                    labelPosition={12}
                    inputPosition={12}
                />
                <MultiSelectField
                    name="Hspc"
                    label="HSPC"
                    options={gapMPCTypes.list}
                    valueField="Hspc"
                    delimiter={'{;}'}
                    placeholder="To customize a report based on a specific Health Strategic Planning Category (HSPC), please select the HSPC(s) you would like to view."
                    labelPosition={12}
                    inputPosition={12}
                    loading={gapMPCTypes.loading}
                />
                <div>
                    <div className="col-xs-12" style={{ paddingTop: 15 }}>
                        <FormButtonGroup
                            formGenerate={this.formGenerate}
                            previousPage={previousPage}
                            submitting={submitting}
                            saving={saving}
                            generating={generating}
                            loading={loading}
                            invalid={invalid}
                            pristine={pristine}
                            reset={reset}
                            isFirstPage={currentPage === 0}
                            isLastPage={currentPage === 4}
                            canEdit={canEdit}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

const ReduxRWFHeader = reduxForm({
    form: 'ReportForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.ReportName) {
            errors.ReportName = 'Report name is required';
        }
        if (!values.VisnCode) {
            errors.VisnCode = 'Visn is required';
        }
        if (!values.MarketName) {
            errors.MarketName = 'Market is required';
        }
        return errors;
    }
})(RWFHeader);

const selector = formValueSelector('ReportForm');
const mapDispatchToProps = (dispatch) => {
    return {
        getReportDropdowns: (marketName, visnCode) =>
            dispatch.ReportFormModel.fetchDropdownOptionsAsync({
                marketName,
                visnCode
            }),
        clearReportForm: () => dispatch.ReportFormModel.resetTable('ReportForm'),
        generateReportById: dispatch.ReportFormModel.generateReportByIdAsync,
        generateReportByFormData: dispatch.ReportFormModel.generateReportByFormDataAsync
    };
};

const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ReportFormModel.ReportForm.data) ? 'create' : 'edit',
    formValues: getFormValues('ReportForm')(state),
    currentVISN: selector(state, 'VisnCode'),
    initialValues: state.ReportFormModel.ReportForm.data || {},
    saving: state.ReportFormModel.ReportForm.saving,
    generating: state.ReportFormModel.ReportForm.generating,
    loading: state.ReportFormModel.ReportForm.loading,
    gapMPCTypes: state.UserSessionModel.gapMpcTypes,
    report: state.ReportFormModel,
    visnList: getVISNList(state),
    marketList: getMarketList(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxRWFHeader);
