import { commaCurrencyFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

/**
 * Formats the facility data name.
 *
 * @param {string} str - The facility data name.
 * @returns - The formatted name.
 */
const formatString = (str) =>
    str
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, (val) => val.toUpperCase())
        .replace(' And ', ' & ')
        // Remove any white space left around the word
        .trim();

const MCDSupplyDrilldownColumns = [
    columnHelper.display({
        id: 'blank-expand',
        header: null,
        cell: () => null,
        meta: {
            cellClassName: 'text-center',
            cellStyle: { width: '35px' }
        }
    }),
    {
        accessorKey: 'FacilityDataName',
        header: null,
        meta: {
            headerDisplayName: 'Facility',
            cellStyle: { width: '20%', whiteSpace: 'normal', fontSize: 12 },
            formatter: formatString
        }
    },
    {
        accessorKey: 'Year6',
        header: null,
        meta: {
            cellClassName: 'text-right',
            cellStyle: { fontSize: 12, width: '14.65%' },
            formatter: commaCurrencyFormatter
        }
    },
    {
        accessorKey: 'Year7',
        header: null,
        meta: {
            cellClassName: 'text-right',
            cellStyle: { fontSize: 12, width: '14.65%' },
            formatter: commaCurrencyFormatter
        }
    },
    {
        accessorKey: 'Year8',
        header: null,
        meta: {
            cellClassName: 'text-right',
            cellStyle: { fontSize: 12, width: '14.65%' },
            formatter: commaCurrencyFormatter
        }
    },
    {
        accessorKey: 'Year9',
        header: null,
        meta: {
            cellClassName: 'text-right',
            cellStyle: { fontSize: 12, width: '14.65%' },
            formatter: commaCurrencyFormatter
        }
    },
    {
        accessorKey: 'Year10',
        header: null,
        meta: {
            cellClassName: 'text-right',
            cellStyle: { fontSize: 12, width: '14.65%' },
            formatter: commaCurrencyFormatter
        }
    },
    columnHelper.display({
        id: 'blank-1',
        header: null,
        cell: () => null,
        meta: {
            cellClassName: 'text-center',
            cellStyle: { width: '45px' }
        }
    }),
    columnHelper.display({
        id: 'blank-2',
        header: null,
        cell: () => null,
        meta: {
            cellClassName: 'text-center',
            cellStyle: { width: '45px' }
        }
    })
];

export default MCDSupplyDrilldownColumns;
