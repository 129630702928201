import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import proptypes from 'prop-types';

import AdminResponseForm from '../SAAdminResponseForm';
import UserQuestionsColumnDefs from './TableOutline';

class SAViewQuestion extends React.Component {
    static propTypes = {
        changeTab: proptypes.func.isRequired
    };

    componentDidMount() {
        if (this.props.userQuestions.list.length === 0) {
            this.props.askAQuestionFetchList();
        }
    }

    expandQuestion = () => ({
        renderer: (row) => (
            <AdminResponseForm
                row={row}
                editQuestionResponse={(record, input) => this.editQuestionResponse(record, input)}
            />
        ),
        canRender: (row) => true,
        showExpandColumn: true,
        onlyOneExpanding: true
    });

    editQuestionResponse = (record, input) => {
        record.ResponseText = input.userResponse;
        this.props.submitQuestionResponse(record);
    };

    render() {
        return (
            <div>
                <p>
                    <strong>
                        Table below is used to display Ask A Question logs. Everytime a question is
                        asked, it is logged in HSPA
                    </strong>
                </p>
                <div>
                    <ReactDataTable
                        keyValue="Id"
                        list={this.props.userQuestions.list.sort((a, b) => {
                            return new Date(b.DateRec).getTime() - new Date(a.DateRec).getTime();
                        })}
                        loading={this.props.userQuestions.loading}
                        columns={UserQuestionsColumnDefs}
                        csvFileName={'UserQuestions.csv'}
                        expandContent={this.expandQuestion()}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    askAQuestionFetchList: () => dispatch.AdministrationModel.fetchUserQuestionsAsync(),
    submitQuestionResponse: (formValues) =>
        dispatch.AdministrationModel.submitQuestionResponseAsync(formValues)
});

const mapStateToProps = (state) => ({
    userQuestions: state.AdministrationModel.userQuestions,
    user: state.UserSessionModel
});

export default connect(mapStateToProps, mapDispatchToProps)(SAViewQuestion);
