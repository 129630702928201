import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Render a navigation announcement to the page, only visible to screen readers.  This listens for
 * changes to routes set by react-router, and on change, updates the announcement.
 *
 * Based on code from here: https://estevanmaito.me/blog/accessible-navigation-using-react-router
 *
 * @returns A navigation announcement for screen readers.
 */
const AccessibleNavigationAnnouncer = (): JSX.Element => {
    // the message that will be announced
    const [message, setMessage] = useState('');
    // get location from router
    const location = useLocation();

    // only run this when location change (note the dependency [location])
    useEffect(() => {
        // ignore the /
        if (location.pathname.slice(1)) {
            // make sure navigation has occurred and screen reader is ready
            setTimeout(() => setMessage(`Navigated to ${location.pathname.slice(1)} page.`), 500);
        } else {
            // in my case, / redirects to /dashboard, so I found it better to
            // just ignore the / route
            setMessage('');
        }
    }, [location]);

    // .sr-only comes from Bootstrap CSS and makes sure it will only be visible for SRs
    return (
        <span className="sr-only" role="status" aria-live="polite" aria-atomic="true">
            {message}
        </span>
    );
};

export default AccessibleNavigationAnnouncer;
