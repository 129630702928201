import { createCapitalProject as createCapitalProjectAPI } from 'Data/capital-projects';
import epicRequest from 'Utilities/epicRequest';
import { CapitalProject } from 'Utilities/types';

import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    createCapitalProject as createCapitalProjectAction,
    createCapitalProjectDone,
    createCapitalProjectError,
    createCapitalProjectStart,
    createdCapitalProject
} from '../capitalProjectsSlice';

/**
 * An epic that listens for createCapitalProjects actions and calls the API.
 *
 * @param action$ - A stream of the redux actions dispatched to the application's redux store.
 * @returns Actions to set capital projects on load.
 */
const createCapitalProject = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        filter(createCapitalProjectAction.match),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Send a create request and return the capital project.
             *
             * @param action - The action to take.
             * @param action.payload - The capital project to create.
             * @returns An observable of redux actions.
             */
            request: ({ payload }: PayloadAction<CapitalProject>) =>
                from(createCapitalProjectAPI(payload)).pipe(
                    map((capitalProject) => createdCapitalProject(capitalProject as CapitalProject))
                ),
            actions: {
                start: createCapitalProjectStart,
                done: createCapitalProjectDone,
                fail: createCapitalProjectError
            }
        })
    );

export default createCapitalProject;
