import React from 'react';

import _map from 'lodash/map';
import PropTypes from 'prop-types';
import analytics from 'Utilities/analytics';

const ModuleNavigation = ({ tabs, activeTab, handleOnClick }) => {
    const analyticsObj = analytics.getInstance();
    return (
        <div>
            <div
                className="btn-group d-flex navBtn"
                role="group"
                style={{ width: '100%', overflowX: 'auto' }}
            >
                {_map(tabs, (item) => {
                    const tabClassNames = ['myBtnGroup', 'btn', 'btn-color1'];
                    if (activeTab === item.key) {
                        tabClassNames.push('active');
                    }
                    return (
                        <button
                            title={item.label}
                            type="button"
                            key={item.key}
                            onClick={() => {
                                if (activeTab !== item.key) {
                                    analyticsObj.trackTabChange(item.label);
                                }
                                handleOnClick(item.key);
                            }}
                            className={tabClassNames.join(' ')}
                            disabled={item.disabled}
                            hidden={item.hidden}
                        >
                            {item.label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

ModuleNavigation.propTypes = {
    activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
            label: PropTypes.string.isRequired,
            hidden: false,
            disabled: false
        })
    ).isRequired,
    handleOnClick: PropTypes.func
};

ModuleNavigation.defaultProps = {
    activeTab: 0,
    tabs: {},
    handleOnClick: null
};

export default ModuleNavigation;
