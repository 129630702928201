import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import App from 'Modules/App';
import GovernmentSystemWarning from 'Modules/App/GovernmentSystemWarning';

import { authProvider } from '../../authProvider';
import VAApplicationOverlay from '../VAApplicationOverlay';
import DatabaseStartPage from './DatabaseStartPage';

const url = `${process.env.REACT_APP_HSPA_API}/Management/StatusRequest`;

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 * Function to determine whether the Database Startup component should be shown.
 * Checks database status continuously until it is 'Online.'
 *
 * @returns {Function} to return the DatabaseStartPage or the Application
 */
const AppStart = () => {
    const [paused, setPaused] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (paused) {
            if (process.env.NODE_ENV === 'production') {
                setPaused(false);
                return; // short-circuit out of this function
            }
            let timesRun = 0;
            const interval = setInterval(async () => {
                if (timesRun === 0) {
                    dispatch.UserSessionModel.fetchDefaultYear();
                }
                timesRun += 1;
                if (timesRun >= 130) {
                    clearInterval(interval);
                }
                const response = await fetch(url, {
                    method: 'get',
                    credentials: 'include',
                    accept: 'gzip',
                    headers: {
                        Authorization: `Bearer ${
                            (await authProvider.getAccessToken()).accessToken
                        }`,
                        'Cache-Control': 'no-cache',
                        pragma: 'no-cache'
                    }
                });
                const data = await response.text();
                console.log(`${data} : ${paused} : ${timesRun}`);
                console.log(paused);

                if (data === 'Online') {
                    setPaused(false);
                    clearInterval(interval);
                }
            }, 1000);
        }
    }, [dispatch.UserSessionModel, paused]);

    return (
        <>
            <VAApplicationOverlay fadePage={paused}>
                <DatabaseStartPage />
            </VAApplicationOverlay>
            <GovernmentSystemWarning />
            <App />
        </>
    );
};

export default AppStart;
