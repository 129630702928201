import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import EditReportButton from '../EditReportButton';

const columnHelper = createColumnHelper();

const TemplateReportColumnDefs = () => [
    {
        accessorKey: 'ReportName',
        header: 'Report Name',
        meta: {
            headerDisplayName: 'Report Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    columnHelper.display({
        id: 'CreateNewReport',
        header: 'Create New Report',
        enableSorting: false,
        cell: ({ row }) => <EditReportButton id={row.original.Id} />,
        meta: {
            headerDisplayName: 'Create New Report',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    })
];

export default TemplateReportColumnDefs;
