/**
 * Handles demographic tabs.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import DDFContainer from 'Lib/DDFSlides/DDFContainer';
import {
    getCountyEligibleSelector,
    getCountyEnrollmentSelector,
    getCountyMarketShareSelector,
    getCountySpecialGenderSelector,
    getCountySpecialOefoifondSelector,
    getMarketEligibleAgeSelector,
    getMarketEligiblePrioritySelector,
    getMarketEnrollmentAgeSelector,
    getMarketEnrollmentPrioritySelector,
    getMarketShareAgeSelector,
    getMarketSharePrioritySelector,
    getMarketSpecialGenderSelector,
    getMarketSpecialOefoifondSelector
} from 'Modules/EnvironmentalScan/Services/selector';
import { getCurrentMarketName, getDefaultYearObject } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import ESDTable from './ESDTable';
import * as TableOutline from './TableOutline';

class Master extends React.Component {
    static propTypes = {
        defaultYear: PropTypes.object.isRequired,
        marketName: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            tabDisplay: 'tab1'
        };
    }

    handleClick = (tab) => {
        this.setState({ tabDisplay: tab });
    };

    render() {
        const { defaultYear, marketName } = this.props;

        return (
            <div>
                <DDFContainer
                    text={'There is relevant data published in the Data Discovery and Findings.'}
                    reports={[
                        {
                            title: 'Market Slides',
                            subSections: [
                                {
                                    sectionHeader: 'Current Market Reports',
                                    sectionSlides: [8, 9, 16, 17, 18, 188]
                                },
                                {
                                    sectionHeader: 'Market Enrollee Reports',
                                    sectionSlides: [10, 11, 12, 13, 14, 15]
                                },
                                {
                                    sectionHeader: 'Facility Reports',
                                    sectionSlides: [21, 22, 195, 192, 193]
                                },
                                {
                                    sectionHeader: 'Rurality Reports',
                                    sectionSlides: [19, 20]
                                }
                            ]
                        }
                    ]}
                ></DDFContainer>
                <div className="row">
                    <div className="col-md-2">
                        <ul
                            className="nav nav-pills nav-pills--vertical"
                            style={{ display: 'inline-block' }}
                        >
                            <li style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <p className="tabHeader">Market Level Data</p>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab1')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab1' ? 'active' : '')
                                    }
                                >
                                    <span>Veteran Eligible EOFY</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab2')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab2' ? 'active' : '')
                                    }
                                >
                                    <span>Veteran Enrollment EOFY</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab3')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab3' ? 'active' : '')
                                    }
                                >
                                    Market Share Percentage
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab4')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab4' ? 'active' : '')
                                    }
                                >
                                    <span>Special Population Gender</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab5')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab5' ? 'active' : '')
                                    }
                                >
                                    <span>Special Population OEF/OIF/OND</span>
                                </a>
                            </li>
                            <li style={{ marginTop: '20px', marginBottom: '10px' }}>
                                <p className="tabHeader">County Level Data</p>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab6')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab6' ? 'active' : '')
                                    }
                                >
                                    <span>Veteran Eligible EOFY</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab7')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab7' ? 'active' : '')
                                    }
                                >
                                    <span>Veteran Enrollment EOFY</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab8')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab8' ? 'active' : '')
                                    }
                                >
                                    Market Share Percentage
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab9')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab9' ? 'active' : '')
                                    }
                                >
                                    <span>Special Population Gender</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={() => this.handleClick('tab10')}>
                                <a
                                    className={
                                        'nav-link ' +
                                        (this.state.tabDisplay === 'tab10' ? 'active' : '')
                                    }
                                >
                                    <span>Special Population OEF/OIF/OND</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-10" style={{ paddingTop: '15px' }}>
                        {this.state.tabDisplay === 'tab1' ? (
                            <div>
                                <DDFContainer
                                    text={
                                        'There is relevant data published in the Data Discovery and Findings.'
                                    }
                                    reports={[
                                        {
                                            title:
                                                'Overall Market Population (Veterans and Non-Veterans)',
                                            slideNumbers: [21]
                                        }
                                    ]}
                                ></DDFContainer>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketVeteranEligibleColumnDefs1(
                                        defaultYear
                                    )}
                                    tableData={this.props.marketEligiblePriorityList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketEligiblePriorityFetchList}
                                    csvFileName={marketName + '-VeteranEligiblePriority.csv'}
                                />
                                <DDFContainer
                                    text={
                                        'There is relevant data published in the Data Discovery and Findings.'
                                    }
                                    reports={[
                                        {
                                            title: 'Market Enrollee and Eligible Demographics',
                                            slideNumbers: [12, 13]
                                        }
                                    ]}
                                ></DDFContainer>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketVeteranEligibleColumnDefs2(
                                        defaultYear
                                    )}
                                    tableData={this.props.marketEligibleAgeList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketEligibleAgeFetchList}
                                    csvFileName={marketName + '-VeteranEligibleAge.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab2' ? (
                            <div>
                                <DDFContainer
                                    text={
                                        'There is relevant data published in the Data Discovery and Findings.'
                                    }
                                    reports={[
                                        {
                                            title: 'Market Enrollee Priority Groups',
                                            slideNumbers: [14]
                                        }
                                    ]}
                                ></DDFContainer>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketVeteranEnrollmentColumnDefs1(
                                        defaultYear
                                    )}
                                    tableData={this.props.marketEnrollmentPriorityList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketEnrollmentPriorityFetchList}
                                    csvFileName={marketName + '-VeteranEnrollmentPriority.csv'}
                                />
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketVeteranEnrollmentColumnDefs2(
                                        defaultYear
                                    )}
                                    tableData={this.props.marketEnrollmentAgeList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketEnrollmentAgeFetchList}
                                    csvFileName={marketName + '-VeteranEnrollmentAge.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab3' ? (
                            <div>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketShareTableDef1(defaultYear)}
                                    tableData={this.props.marketSharePriorityList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketSharePriorityFetchList}
                                    csvFileName={marketName + '-MarketPriority.csv'}
                                />
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketShareTableDef2(defaultYear)}
                                    tableData={this.props.marketShareAgeList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketShareAgeFetchList}
                                    csvFileName={marketName + '-MarketAge.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab4' ? (
                            <div>
                                <DDFContainer
                                    text={
                                        'There is relevant data published in the Data Discovery and Findings.'
                                    }
                                    reports={[
                                        {
                                            title: 'Market Enrollee Population - Age and Gender',
                                            slideNumbers: [15]
                                        }
                                    ]}
                                ></DDFContainer>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketSpecialPopulationGenderColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.marketSpecialGenderList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketSpecialGenderFetchList}
                                    csvFileName={marketName + '-SpecialPopulationGender.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab5' ? (
                            <div>
                                <DDFContainer
                                    text={
                                        'There is relevant data published in the Data Discovery and Findings.'
                                    }
                                    reports={[
                                        {
                                            title:
                                                'Overall Market Population (Veterans and Non-Veterans)',
                                            slideNumbers: [21]
                                        }
                                    ]}
                                ></DDFContainer>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.MarketSpecialPopulationOefColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.marketSpecialOefoifondList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.marketSpecialOefoifondFetchList}
                                    csvFileName={marketName + '-SpecialPopulationAge.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab6' ? (
                            <div>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.CountyVeteranEligibleColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.countyEligibleList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.countyEligibleFetchList}
                                    csvFileName={marketName + '-VeteranEligibleCounty.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab7' ? (
                            <div>
                                <DDFContainer
                                    text={
                                        'There is relevant data published in the Data Discovery and Findings.'
                                    }
                                    reports={[
                                        {
                                            title: 'Market Enrollee Demographics',
                                            slideNumbers: [10]
                                        }
                                    ]}
                                ></DDFContainer>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.CountyVeteranEnrollmentColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.countyEnrollmentList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.countyEnrollmentFetchList}
                                    csvFileName={marketName + '-VetranEnrollmentCounty.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab8' ? (
                            <div>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.CountyMarketShareColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.countyMarketShareList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.countyMarketShareFetchList}
                                    csvFileName={marketName + '-VetranMarketShareCounty.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab9' ? (
                            <div>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.CountySpecialPopulationGenderColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.countySpecialGenderList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.countySpecialGenderFetchList}
                                    csvFileName={marketName + '-SpecialPopulationGender.csv'}
                                />
                            </div>
                        ) : null}
                        {this.state.tabDisplay === 'tab10' ? (
                            <div>
                                <ESDTable
                                    keyValue={'Id'}
                                    tableColumnDefs={TableOutline.CountySpecialPopulationOefColumnDefs(
                                        defaultYear
                                    )}
                                    tableData={this.props.countySpecialOefoifondList}
                                    currentMarket={marketName}
                                    fetchDataFunc={this.props.countySpecialOefoifondFetchList}
                                    csvFileName={marketName + '-SpecialConflicCounty.csv'}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        marketEligiblePriorityFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketEligiblePriorityAsync(market),
        marketEligibleAgeFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketEligibleAgeAsync(market),
        marketEnrollmentPriorityFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketEnrollmentPriorityAsync(market),
        marketEnrollmentAgeFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketEnrollmentAgeAsync(market),
        marketSharePriorityFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketSharePriorityAsync(market),
        marketShareAgeFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketShareAgeAsync(market),
        marketSpecialGenderFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketSpecialGenderAsync(market),
        marketSpecialOefoifondFetchList: (market) =>
            dispatch.DemographicModel.fetchMarketSpecialOefoifondAsync(market),
        countyEligibleFetchList: (market) =>
            dispatch.DemographicModel.fetchCountyEligibleAsync(market),
        countyEnrollmentFetchList: (market) =>
            dispatch.DemographicModel.fetchCountyEnrollmentAsync(market),
        countyMarketShareFetchList: (market) =>
            dispatch.DemographicModel.fetchCountyMarketShareAsync(market),
        countySpecialGenderFetchList: (market) =>
            dispatch.DemographicModel.fetchCountySpecialGenderAsync(market),
        countySpecialOefoifondFetchList: (market) =>
            dispatch.DemographicModel.fetchCountySpecialOefoifondAsync(market)
    };
};

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    defaultYear: getDefaultYearObject(state),
    marketEligiblePriorityList: getMarketEligiblePrioritySelector(state),
    marketEligibleAgeList: getMarketEligibleAgeSelector(state),
    marketEnrollmentPriorityList: getMarketEnrollmentPrioritySelector(state),
    marketEnrollmentAgeList: getMarketEnrollmentAgeSelector(state),
    marketSharePriorityList: getMarketSharePrioritySelector(state),
    marketShareAgeList: getMarketShareAgeSelector(state),
    marketSpecialGenderList: getMarketSpecialGenderSelector(state),
    marketSpecialOefoifondList: getMarketSpecialOefoifondSelector(state),
    countyEligibleList: getCountyEligibleSelector(state),
    countyEnrollmentList: getCountyEnrollmentSelector(state),
    countyMarketShareList: getCountyMarketShareSelector(state),
    countySpecialGenderList: getCountySpecialGenderSelector(state),
    countySpecialOefoifondList: getCountySpecialOefoifondSelector(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(Master);
