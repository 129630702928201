import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Form } from 'redux-form';
import _isEmpty from 'lodash/isEmpty';
import MultiSelectField from 'Lib/ReduxFormFields/MultiSelectField';
import CheckboxField from 'Lib/ReduxFormFields/CheckboxField';
import { canEditVISN } from 'Modules/UserSession/selector';
import FormButtonGroup from '../RWFButtonGroup';

class RWFEnvironmentalScan extends React.Component {
    static propTypes = {
        formValues: PropTypes.shape({
            ReportId: PropTypes.number,
            ChkStakeholders: PropTypes.bool,
            ChkRurality: PropTypes.bool,
            ChkRuralityCounty: PropTypes.bool,
            ChkVeteranEligibleAge: PropTypes.bool,
            ChkVeteranEligiblePriority: PropTypes.bool,
            ChkVeteranEnrollementAge: PropTypes.bool,
            ChkVeteranEnrollementPriority: PropTypes.bool,
            ChkMarketShareAge: PropTypes.bool,
            ChkMarketSharePriority: PropTypes.bool,
            ChkSpecialPopGender: PropTypes.bool,
            ChkSpecialConflict: PropTypes.bool,
            ChkVeteranEligibleCounty: PropTypes.bool,
            ChkVeteranEnrollmentCounty: PropTypes.bool,
            ChkMarkShareCounty: PropTypes.bool,
            ChkMarketShareCounty: PropTypes.bool,
            ChkSpecialPopGenderCounty: PropTypes.bool,
            ChkSpecialConflictCounty: PropTypes.bool,
            ChkEnrolledVeterans: PropTypes.bool,
            ChkEligibleEnrollVeterans: PropTypes.bool
        }).isRequired,
        submitting: PropTypes.bool,
        saving: PropTypes.bool,
        generating: PropTypes.bool,
        loading: PropTypes.bool,
        invalid: PropTypes.bool,
        pristine: PropTypes.bool,
        change: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        currentPage: PropTypes.number.isRequired,
        previousPage: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        report: PropTypes.shape({
            savedMapUrls: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({})),
                loading: PropTypes.bool
            })
        }).isRequired,
        generateReportById: PropTypes.func.isRequired,
        generateReportByFormData: PropTypes.func.isRequired
    };

    static defaultProps = {
        submitting: false,
        saving: false,
        generating: false,
        loading: false,
        invalid: false,
        pristine: true,
        canEdit: false
    };

    formGenerate = () => {
        const { formValues, generateReportById, generateReportByFormData } = this.props;

        if (formValues.ReportId) {
            generateReportById(formValues.ReportId);
        } else {
            generateReportByFormData(formValues);
        }
    };

    selectAllCheck = () => {
        const { formValues, change } = this.props;
        change(
            'ChkStakeholders',
            !formValues || !formValues.ChkStakeholders ? true : !formValues.ChkStakeholders
        );
        change(
            'ChkRurality',
            !formValues || !formValues.ChkRurality ? true : !formValues.ChkRurality
        );
        change(
            'ChkRuralityCounty',
            !formValues || !formValues.ChkRuralityCounty ? true : !formValues.ChkRuralityCounty
        );
        change(
            'ChkVeteranEligibleAge',
            !formValues || !formValues.ChkVeteranEligibleAge
                ? true
                : !formValues.ChkVeteranEligibleAge
        );
        change(
            'ChkVeteranEligiblePriority',
            !formValues || !formValues.ChkVeteranEligiblePriority
                ? true
                : !formValues.ChkVeteranEligiblePriority
        );
        change(
            'ChkVeteranEnrollementAge',
            !formValues || !formValues.ChkVeteranEnrollementAge
                ? true
                : !formValues.ChkVeteranEnrollementAge
        );
        change(
            'ChkVeteranEnrollementPriority',
            !formValues || !formValues.ChkVeteranEnrollementPriority
                ? true
                : !formValues.ChkVeteranEnrollementPriority
        );
        change(
            'ChkMarketShareAge',
            !formValues || !formValues.ChkMarketShareAge ? true : !formValues.ChkMarketShareAge
        );
        change(
            'ChkMarketSharePriority',
            !formValues || !formValues.ChkMarketSharePriority
                ? true
                : !formValues.ChkMarketSharePriority
        );
        change(
            'ChkSpecialPopGender',
            !formValues || !formValues.ChkSpecialPopGender ? true : !formValues.ChkSpecialPopGender
        );
        change(
            'ChkSpecialConflict',
            !formValues || !formValues.ChkSpecialConflict ? true : !formValues.ChkSpecialConflict
        );
        change(
            'ChkVeteranEligibleCounty',
            !formValues || !formValues.ChkVeteranEligibleCounty
                ? true
                : !formValues.ChkVeteranEligibleCounty
        );
        change(
            'ChkVeteranEnrollmentCounty',
            !formValues || !formValues.ChkVeteranEnrollmentCounty
                ? true
                : !formValues.ChkVeteranEnrollmentCounty
        );
        change(
            'ChkMarketShareCounty',
            !formValues || !formValues.ChkMarketShareCounty
                ? true
                : !formValues.ChkMarketShareCounty
        );
        change(
            'ChkSpecialPopGenderCounty',
            !formValues || !formValues.ChkSpecialPopGenderCounty
                ? true
                : !formValues.ChkSpecialPopGenderCounty
        );
        change(
            'ChkSpecialConflictCounty',
            !formValues || !formValues.ChkSpecialConflictCounty
                ? true
                : !formValues.ChkSpecialConflictCounty
        );
        change(
            'ChkEnrolledVeterans',
            !formValues || !formValues.ChkEnrolledVeterans ? true : !formValues.ChkEnrolledVeterans
        );
        change(
            'ChkEligibleEnrollVeterans',
            !formValues || !formValues.ChkEligibleEnrollVeterans
                ? true
                : !formValues.ChkEligibleEnrollVeterans
        );
    };

    render() {
        const {
            report,
            previousPage,
            submitting,
            saving,
            generating,
            loading,
            invalid,
            pristine,
            reset,
            currentPage,
            canEdit,
            handleSubmit
        } = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckboxField
                                name="envscanSelectAll"
                                label="Select All"
                                onChangeFunction={this.selectAllCheck}
                            />
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <h5 className="card-header card-info">
                                    Demographic Data - Market Level
                                </h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Table
                                </h6>
                                <div className="card-body">
                                    <CheckboxField
                                        name="ChkVeteranEligibleAge"
                                        label="Veteran Eligible - Age"
                                    />
                                    <CheckboxField
                                        name="ChkVeteranEligiblePriority"
                                        label="Veteran Eligible - Priority"
                                    />
                                    <CheckboxField
                                        name="ChkVeteranEnrollementAge"
                                        label="Veteran Enrollment - Age"
                                    />
                                    <CheckboxField
                                        name="ChkVeteranEnrollementPriority"
                                        label="Veteran Enrollment - Priority"
                                    />
                                    <CheckboxField
                                        name="ChkMarketShareAge"
                                        label="Market Share Percentage - Age"
                                    />
                                    <CheckboxField
                                        name="ChkMarketSharePriority"
                                        label="Market Share Percentage - Priority"
                                    />
                                    <CheckboxField
                                        name="ChkSpecialPopGender"
                                        label="Special Population Gender"
                                    />
                                    <CheckboxField
                                        name="ChkSpecialConflict"
                                        label="Special Population OEF/OIF/OND - Age"
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <h5 className="card-header card-info">
                                    Demographic Data - County Level
                                </h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Table
                                </h6>
                                <div className="card-body">
                                    <CheckboxField
                                        name="ChkVeteranEligibleCounty"
                                        label="Veteran Eligible"
                                    />
                                    <CheckboxField
                                        name="ChkVeteranEnrollmentCounty"
                                        label="Veteran Enrollment"
                                    />
                                    <CheckboxField
                                        name="ChkMarketShareCounty"
                                        label="Market Share Percentage"
                                    />
                                    <CheckboxField
                                        name="ChkSpecialPopGenderCounty"
                                        label="Special Population Gender"
                                    />
                                    <CheckboxField
                                        name="ChkSpecialConflictCounty"
                                        label="Special Population OEF/OIF/OND"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <h5 className="card-header card-info">Stakeholders</h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Text
                                </h6>
                                <div className="card-body">
                                    <CheckboxField name="ChkStakeholders" label="Stakeholders" />
                                </div>
                            </div>
                            <div className="card">
                                <h5 className="card-header card-info">Geography and Rurality</h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Map
                                </h6>
                                <div className="card-body">
                                    <MultiSelectField
                                        name="SavedMaps"
                                        label="Select Saved Map URL"
                                        options={report.savedMapUrls.list}
                                        valueField="ReportText"
                                        delimiter={'{;}'}
                                        labelPosition={12}
                                        inputPosition={12}
                                        loading={report.savedMapUrls.loading}
                                    />
                                </div>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Table
                                </h6>
                                <div className="card-body">
                                    <div>
                                        <CheckboxField name="ChkRurality" label="Rurality Data" />
                                        <CheckboxField
                                            name="ChkRuralityCounty"
                                            label="County Rurality Data"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <h5 className="card-header card-info">40-Mile Veterans</h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Table
                                </h6>
                                <div className="card-body">
                                    <CheckboxField
                                        name="ChkEnrolledVeterans"
                                        label="Enrolled Veterans"
                                    />
                                    <CheckboxField
                                        name="ChkEligibleEnrollVeterans"
                                        label="Eligible to Enroll Veterans"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12" style={{ paddingTop: 15 }}>
                    <FormButtonGroup
                        formGenerate={this.formGenerate}
                        previousPage={previousPage}
                        submitting={submitting}
                        saving={saving}
                        generating={generating}
                        loading={loading}
                        invalid={invalid}
                        pristine={pristine}
                        reset={reset}
                        isFirstPage={currentPage === 0}
                        isLastPage={currentPage === 4}
                        canEdit={canEdit}
                    />
                </div>
            </Form>
        );
    }
}

const ReduxRWFEnvironmentalScan = reduxForm({
    form: 'ReportForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.ReportName) {
            errors.ReportName = 'Report name is required';
        }
        if (!values.VisnCode) {
            errors.VisnCode = 'Visn is required';
        }
        if (!values.MarketName) {
            errors.MarketName = 'Market is required';
        }
        return errors;
    }
})(RWFEnvironmentalScan);

const mapDispatchToProps = (dispatch) => {
    return {
        getReportDropdowns: (marketName, visnCode) =>
            dispatch.ReportFormModel.fetchDropdownOptionsAsync({
                marketName,
                visnCode
            }),
        clearReportForm: () => dispatch.ReportFormModel.resetTable('ReportForm'),
        generateReportById: (reportId) =>
            dispatch.ReportFormModel.generateReportByIdAsync(reportId),
        generateReportByFormData: (formValues) =>
            dispatch.ReportFormModel.generateReportByFormDataAsync(formValues)
    };
};

const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ReportFormModel.ReportForm.data) ? 'create' : 'edit',
    formValues: getFormValues('ReportForm')(state),
    initialValues: state.ReportFormModel.ReportForm.data || {},
    saving: state.ReportFormModel.ReportForm.saving,
    generating: state.ReportFormModel.ReportForm.generating,
    loading: state.ReportFormModel.ReportForm.loading,
    report: state.ReportFormModel,
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxRWFEnvironmentalScan);
