import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { useDispatch } from 'react-redux';

import { map } from 'lodash';
import { useHSPADispatch, useHSPASelector } from 'storeTypes';

import { loadCapitalProjects, setCapitalProjectApproval } from 'Modules/CapitalProject';
import SOMetricGroup from 'Modules/StrategicOutlook/Components/SOMetricGroup';
import {
    getStrategicOutlookMetrics,
    getVisnMetrics
} from 'Modules/StrategicOutlook/Services/selector';
import {
    getCurrentMarketName,
    getCurrentVISN,
    getMarketList,
    isCAMUserForCurrentVISN,
    isFacilityPlannerForCurrentVISN,
    isPlannerForCurrentVISN
} from 'Modules/UserSession/selector';
import { ApprovalQuestion, CapitalProject } from 'Utilities/types';

type CapitalApprovalProps = {
    capitalProject: CapitalProject;
};

const CapitalApproval = ({ capitalProject }: CapitalApprovalProps): JSX.Element => {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const hspaDispatch = useHSPADispatch();
    //const [displayView, setDisplayView] = useState('market');
    const displayView = 'market';
    const currentVISN = useHSPASelector(getCurrentVISN);
    const metrics = useHSPASelector(getStrategicOutlookMetrics);
    const visnMetrics = useHSPASelector(getVisnMetrics);
    const marketName = useHSPASelector(getCurrentMarketName);
    const [projectionType, setProjectionType] = useState('Enrollment');
    const marketList = useHSPASelector(getMarketList);
    const metricFilter = useHSPASelector((state) => state.MetricFilterModel);
    const [justification, setJustification] = useState(capitalProject.approvalJustification || '');
    const visnPlanner = useHSPASelector(isPlannerForCurrentVISN);
    const [enrollVisible, setEnrollVisible] = useState(true);
    const [demandVisible, setDemandVisible] = useState(false);
    const camUser = useHSPASelector(isCAMUserForCurrentVISN);
    const facilityPlanner = useHSPASelector(isFacilityPlannerForCurrentVISN);
    const [projectId, setProjectId] = useState(0);

    const [approvalQuestions, setApprovalQuestions] = useState(
        map(capitalProject.approvalQuestions || [], (q): ApprovalQuestion => ({ ...q }))
    );

    const handleModalClose = () => {
        setShowModal(false);
        setProjectId(0);
        setEnrollVisible(true);
        setDemandVisible(false);
        setJustification('');
        setApprovalQuestions([]);
    };

    const handleModalOpen = () => {
        setShowModal(true);
        setProjectId(capitalProject.id || 0);
        setApprovalQuestions(
            map(capitalProject.approvalQuestions || [], (q): ApprovalQuestion => ({ ...q }))
        );
        setJustification(capitalProject.approvalJustification || '');
    };

    const handleEnrollOpen = () => {
        setEnrollVisible(true);
        setDemandVisible(false);
        setProjectionType('Enrollment');
    };
    const handleDemandOpen = () => {
        setEnrollVisible(false);
        setDemandVisible(true);
        setProjectionType('Demand');
    };

    const handleJustificationChanged = (just: string) => {
        setJustification(just);
    };

    useEffect(() => {
        if (displayView === 'market' && marketName) {
            hspaDispatch.MetricProjectionsModel.fetchMetricProjectionsByMarketAsync(marketName);
        }
    }, [displayView, marketName]);

    const enrollToggleModalFunc = (id, hsp, market) => {
        const metric = metrics.find((item) => item.Id === id);

        hspaDispatch.MetricPopupModel.toggleShow(metric);
        hspaDispatch.MetricPopupModel.fetchMetricModalDataAsync({
            hspc: hsp,
            market,
            projectionType
        });
    };

    const filteredMarkets = marketList.list.filter(
        (item) => item.VisnCode === currentVISN.VisnCode
    );

    const enrollMetricGroups = filteredMarkets.map((item) => {
        const filteredMetrics = visnMetrics.filter(
            (metric) =>
                metric.market === item.MarketName &&
                metric.projectionType === projectionType &&
                ((metric.status === 'good' && metricFilter.WithinThreshold.filter === true) ||
                    (metric.status === 'bad' &&
                        metricFilter.BelowThreshold.filter === true &&
                        metric.status !== 'undetermined'))
        );

        return (
            <div key={item.Id}>
                <SOMetricGroup
                    {...item}
                    id={item.Id}
                    metrics={filteredMetrics || []}
                    market={item.MarketName}
                    display={displayView}
                    toggleModalFunc={enrollToggleModalFunc}
                />
            </div>
        );
    });

    const demandToggleModalFunc = (id, hsp, market) => {
        const metric = metrics.find((item) => item.Id === id);

        hspaDispatch.MetricPopupModel.toggleShow(metric);
        hspaDispatch.MetricPopupModel.fetchMetricModalDataAsync({
            hspc: hsp,
            market,
            projectionType
        });
    };
    const demandMetricGroups = filteredMarkets.map((item) => {
        const filteredMetrics = visnMetrics.filter(
            (metric) =>
                metric.market === item.MarketName &&
                metric.projectionType === projectionType &&
                ((metric.status === 'good' && metricFilter.WithinThreshold.filter === true) ||
                    (metric.status === 'bad' &&
                        metricFilter.BelowThreshold.filter === true &&
                        metric.status !== 'undetermined'))
        );

        return (
            <div key={item.Id}>
                <SOMetricGroup
                    {...item}
                    id={item.Id}
                    metrics={filteredMetrics || []}
                    market={item.MarketName}
                    display={displayView}
                    toggleModalFunc={demandToggleModalFunc}
                />
            </div>
        );
    });

    const approveProject = () => {
        dispatch(
            setCapitalProjectApproval({
                id: projectId,
                approvalJustification: justification,
                approved: true,
                questions: approvalQuestions
            })
        );
    };

    const approveButton =
        visnPlanner === true || camUser === true ? (
            <Button
                variant="success"
                type="button"
                onClick={() => {
                    approveProject();
                    handleModalClose();
                }}
            >
                Approve
            </Button>
        ) : null;

    const saveButton =
        visnPlanner === true || camUser === true || facilityPlanner === true ? (
            <Button
                variant="primary"
                type="button"
                onClick={() => {
                    saveProject();
                    handleModalClose();
                }}
            >
                Save
            </Button>
        ) : null;

    const saveProject = async () => {
        dispatch(
            setCapitalProjectApproval({
                id: projectId,
                approvalJustification: justification,
                approved: false,
                questions: approvalQuestions
            })
        );

        setTimeout(() => dispatch(loadCapitalProjects(capitalProject.marketID)), 3000);
    };

    const onYesChange = (id: number, checked: boolean) => {
        const newApprovalQuestions = [...approvalQuestions];
        for (const element of newApprovalQuestions) {
            if (element.id === id) {
                element.answer = checked;
            }
        }

        setApprovalQuestions(newApprovalQuestions);
    };

    const approvalQuestionContent = map(
        capitalProject.approvalQuestions,
        (q): JSX.Element => {
            return (
                <>
                    <Row>
                        <Col>{q.question}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Check
                                inline
                                id={q.id.toString()}
                                label="Yes"
                                name={q.id.toString()}
                                type="radio"
                                defaultChecked={q.answer}
                                onChange={(e) => onYesChange(parseInt(e.target.id, 10), true)}
                            />
                            <Form.Check
                                inline
                                id={q.id.toString()}
                                label="No"
                                name={q.id.toString()}
                                type="radio"
                                defaultChecked={!q.answer}
                                onChange={(e) => onYesChange(parseInt(e.target.id, 10), false)}
                            />
                        </Col>
                    </Row>
                </>
            );
        }
    );

    return (
        <>
            <Button
                onClick={handleModalOpen}
                variant="success"
                style={{ float: 'right', borderRadius: '16px' }}
            >
                <div>Review</div>
            </Button>
            <Modal
                show={showModal}
                onHide={handleModalClose}
                size="lg"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={{ paddingLeft: '50%' }} closeButton>
                    {enrollVisible && (
                        <Modal.Title>
                            <div>Enrollment</div>
                        </Modal.Title>
                    )}
                    {demandVisible && <Modal.Title>Demand</Modal.Title>}
                </Modal.Header>

                <Modal.Body>
                    <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                        <Sidebar>
                            <Menu>
                                <MenuItem onClick={handleEnrollOpen}> Enrollment</MenuItem>
                                <MenuItem onClick={handleDemandOpen}> Demand</MenuItem>
                            </Menu>
                        </Sidebar>
                        <main style={{ padding: 10 }}>
                            {enrollVisible && (
                                <div>
                                    {approvalQuestionContent}
                                    <Row>
                                        <Col>
                                            Review possible relevant metrics from the SO module as
                                            you develop and review this plan:
                                            {enrollMetricGroups}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={5}
                                                        onChange={(e) =>
                                                            handleJustificationChanged(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Please use the metrics above to justify..."
                                                        defaultValue={justification}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {demandVisible && (
                                <div>
                                    {approvalQuestionContent}
                                    <Row>
                                        <Col>
                                            Review possible relevant metrics from the SO module as
                                            you develop and review this plan:
                                            {demandMetricGroups}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={5}
                                                        onChange={(e) =>
                                                            handleJustificationChanged(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Please use the metrics above to justify..."
                                                        defaultValue={justification}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </main>
                    </div>
                </Modal.Body>
                <Modal.Footer className="mt-3">
                    {saveButton}
                    {approveButton}
                    <Button
                        variant="outline-secondary"
                        type="button"
                        onClick={() => {
                            handleModalClose();
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CapitalApproval;
