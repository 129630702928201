import React from 'react';
import { Form } from 'react-bootstrap';

import { WrappedFieldProps } from 'redux-form';

type RadioGroupProps = WrappedFieldProps & {
    options: {
        title: React.ReactNode;
        value: string;
    }[];
};

/**
 * A redux-form enabled collection of radio buttons.
 *
 * @param props - props passed into this component
 * @param props.input - values to pass into the native input control
 * @param props.meta - additional values from redux-form
 * @param props.options - array of radio button display options
 * @returns a collection of radio buttons
 */
const RadioGroup = ({ input, meta, options }: RadioGroupProps): JSX.Element => {
    const hasError = (meta.touched && meta.error) as boolean;

    // Standard behavior for React-Form wrapped components is to spread the input property
    const radioButtons = options.map((o) => (
        <Form.Check
            key={o.value}
            id={`${input.name}-${o.value}`}
            inline
            label={o.title}
            type="radio"
            checked={o.value === input.value}
            {...input}
            value={o.value}
        />
    ));

    return (
        <div>
            {radioButtons}
            {hasError && <span className="invalid-feedback d-block">{meta.error}</span>}
        </div>
    );
};

export default RadioGroup;
