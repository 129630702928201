/**
 * An epic to request the report data for a facility and emit actions to update the redux state.
 *
 * @author Brandan D. Reed
 */
import shallowEqual from 'shallowequal';
import { from, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import epicRequest from 'Utilities/epicRequest';
import { getFacilityReport } from 'Modules/Report/Data/FacilityReport';
import {
    setFacilityReport,
    loadFacilityReport as loadFacilityReportAction,
    loadFacilityReportStart,
    loadFacilityReportDone,
    loadFacilityReportError
} from '../slice';

/**
 * When a loadFacilityReport action is emitted to the store, this epic makes a request to get the
 * facility's report data.  The report data sent back is then emitted as the payload for a
 * setFacilityReport redux action.
 * Any loadFacilityReport actions whose payload matches the payload of the previous
 * loadFacilityReport action are ignored.
 *
 * @param {Observable} action$ - stream of actions dispatched to the Redux store
 * @returns {Observable} actions emitted by this epic
 */
const loadFacilityReport = (action$) =>
    action$.pipe(
        ofType(loadFacilityReportAction),
        distinctUntilChanged((previous, current) =>
            shallowEqual(previous.payload, current.payload)
        ),
        epicRequest({
            request: ({ payload }) =>
                from(getFacilityReport(payload.stationNumber, payload.year)).pipe(
                    switchMap((report) =>
                        of(setFacilityReport({ stationNumber: payload.stationNumber, report }))
                    )
                ),
            actions: {
                start: loadFacilityReportStart,
                done: loadFacilityReportDone,
                fail: loadFacilityReportError
            }
        })
    );

export default loadFacilityReport;
