import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { deleteRequest, getJSON, postJSON, putJSON } from 'Utilities/apiCalls';

const userApiUrl = `${process.env.REACT_APP_HSPA_API}/User/`;

export const ManageUserModel = {
    state: {
        show: false,
        formData: {},
        grantAccessFormData: {},
        saving: false,
        accessOptionsExistingUser: { list: [], loading: false },
        accessOptionsNewUser: { list: [], loading: false },
        usersInNetwork: { list: [], loading: false },
        allSystemUsers: { list: [], loading: false }
    },
    reducers: {
        toggle(state) {
            return {
                ...state,
                show: !state.show
            };
        },
        populate(state, data, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    list: data
                }
            };
        },
        populateUserForm(state, id) {
            let user = state.usersInNetwork.list.find((obj) => obj.Id === id);

            return {
                ...state,
                formData: user
            };
        },
        toggleLoading(state, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: !state[object].loading
                }
            };
        },
        toggleSaving(state, object) {
            return {
                ...state,
                saving: !state.saving
            };
        },
        resetTable(state, tableName) {
            if (tableName === 'formData')
                return {
                    ...state,
                    formData: {}
                };
            return {
                ...state,
                [tableName]: { list: [], loading: false }
            };
        },
        reset(state) {
            return {
                ...state,
                formData: {},
                grantAccessFormData: {},
                saving: false,
                accessOptionsExistingUser: { list: [], loading: false },
                accessOptionsNewUser: { list: [], loading: false },
                usersInNetwork: { list: [], loading: false },
                allSystemUsers: { list: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchExistingUserAccessOptionsAsync(state) {
            try {
                this.toggleLoading('accessOptionsExistingUser');
                let result = await getJSON(`${userApiUrl}GetAccessOptionsExistingUser`);
                this.populate(result, 'accessOptionsExistingUser');
                this.toggleLoading('accessOptionsExistingUser');
            } catch (error) {
                this.toggleLoading('accessOptionsExistingUser');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch existing user access options',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchNewUserAccessOptionsAsync(state) {
            try {
                this.toggleLoading('accessOptionsNewUser');
                let result = await getJSON(`${userApiUrl}GetAccessOptionsNewUser`);
                this.populate(result, 'accessOptionsNewUser');
                this.toggleLoading('accessOptionsNewUser');
            } catch (error) {
                this.toggleLoading('accessOptionsNewUser');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch new user access options',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchUsersInNetworkAsync(visnCode, state) {
            try {
                this.toggleLoading('usersInNetwork');
                let result = await getJSON(`${userApiUrl}GetUsersInNetwork?visnCode=` + visnCode);
                this.populate(result, 'usersInNetwork');
                this.toggleLoading('usersInNetwork');
            } catch (error) {
                this.toggleLoading('usersInNetwork');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch users in network',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchAllSystemUsersAsync(state) {
            try {
                this.toggleLoading('allSystemUsers');
                let result = await getJSON(`${userApiUrl}GetAllSystemUsers`);
                this.populate(result, 'allSystemUsers');
                this.toggleLoading('allSystemUsers');
            } catch (error) {
                this.toggleLoading('allSystemUsers');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch all system users',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async updateUserAsync(payload, state) {
            try {
                this.toggleSaving();
                await putJSON(
                    `${userApiUrl}UpdateUser?selectedGroup=` +
                        payload.selectedGroup +
                        '&visnCode=' +
                        payload.visnCode,
                    payload.formValues
                );
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'User has been updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.resetTable('formData');
                this.fetchUsersInNetworkAsync(payload.visnCode);
                this.fetchAllSystemUsersAsync();
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to update user',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveNewUserAsync(payload, state) {
            try {
                this.toggleSaving();
                await postJSON(
                    `${userApiUrl}SaveNewUser?selectedGroup=` + payload.selectedGroup,
                    payload.formValues
                );
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'New user has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.resetTable('formData');
                this.fetchUsersInNetworkAsync(payload.visnCode);
                this.fetchAllSystemUsersAsync();

                this.fetchExistingUserAccessOptionsAsync();
                this.fetchNewUserAccessOptionsAsync();
            } catch (error) {
                this.toggleSaving();
                if (error.status === 409)
                    dispatch(
                        addNotification({
                            message: 'Error: A user with the same domain already exists.',
                            level: HSPANotificationLevel.ERROR
                        })
                    );
                else
                    dispatch(
                        addNotification({
                            message: 'Error: Unable to save new user',
                            level: HSPANotificationLevel.ERROR
                        })
                    );
            }
        },
        async deleteUserAsync(payload, state) {
            try {
                await deleteRequest(
                    `${userApiUrl}DeleteUser?userId=` +
                        payload.userId +
                        '&visnCode=' +
                        payload.visnCode
                );
                dispatch(
                    addNotification({
                        message: 'User has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchUsersInNetworkAsync(payload.visnCode);
                this.fetchAllSystemUsersAsync();
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to submit response',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async deleteUserRoleAsync(payload, state) {
            try {
                await deleteRequest(`${userApiUrl}DeleteRole?id=` + payload.id);
                dispatch(
                    addNotification({
                        message: 'User role has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchUsersInNetworkAsync(payload.visnCode);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to delete user role',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async grantAccessToExistingUserAsync(payload, state) {
            try {
                this.toggleSaving();
                await putJSON(
                    `${userApiUrl}GrantAccessExistingUser?selectedGroup=` +
                        payload.selectedGroup +
                        '&visnCode=' +
                        payload.visnCode,
                    payload.formValues
                );
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Access has been granted to the provided user',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.resetTable('grantAccessFormData');
                this.fetchUsersInNetworkAsync(payload.visnCode);
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to grant access to user',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
