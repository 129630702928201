/**
 * @file The column schema for the ambulatory care capacity modal.
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';

import Button from 'Lib/Button';
import { commaFormatter } from 'Utilities/formatters';

import CustomActiveProductivityStandardCell from './CustomActiveProductivityStandardCell';
import NumberInput from './NumberInput';

/**
 * Bootstrap table column schema creator for the EditAmbulatoryCareCapacityModal component.
 *
 * @param {boolean} saving - is table data currently being saved?
 * @param {Function} updateCellRowValue - called when updating a cell's value
 * @returns {object[]} column schema definition for the ambulatory care modal
 */
const AmbulatoryCareCapacityColumns = (saving, updateCellRowValue) => [
    {
        dataField: 'Hspc',
        text: 'HSPC',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap', width: '40%' },
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        dataField: 'ProviderPosition',
        text: 'Provider Position',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        dataField: 'WorkFTEE',
        text: 'Work FTEE',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'right',
        align: 'right',
        validator: (newValue) => {
            if (saving) return false;
            const regex = /^[0-9]+([,.][0-9]+)?$/g;
            if (!regex.test(newValue)) {
                return {
                    valid: false,
                    message: 'Input must be a number'
                };
            }
            return true;
        }
    },
    {
        dataField: 'ActiveProductivityStandard',
        text: 'Productivity Standard',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'right',
        align: 'right',
        formatExtraData: { updateCellRowValue },
        formatter: (val, row, rowIndex, formatExtraData) => {
            const { updateCellRowValue: update } = formatExtraData;
            const button =
                val === row.ProductivityStandard ? null : (
                    <Button
                        color="warning"
                        type="button"
                        title="Reset to default"
                        icon="undo"
                        btnPaddingTop={1}
                        btnPaddingBottom={1}
                        size="btn-sm"
                        style={{ position: 'absolute', left: -40, top: -1 }}
                        onClick={(e) => {
                            e.stopPropagation();
                            update(
                                val,
                                row.ProductivityStandard,
                                {
                                    ...row,
                                    ActiveProductivityStandard: row.ProductivityStandard
                                },
                                {
                                    dataField: 'ActiveProductivityStandard'
                                }
                            );
                        }}
                    />
                );

            return (
                <div style={{ display: 'inline-block', position: 'relative' }}>
                    {commaFormatter(val)}
                    {button}
                </div>
            );
        },
        editorRenderer: (editorProps, value, row) => (
            <>
                <NumberInput
                    onUpdate={editorProps.onUpdate}
                    value={value}
                    min={row.ProductivityStandardMinimum}
                    max={row.ProductivityStandardMaximum}
                />
            </>
        ),
        validator: (newValue) => {
            const regex = /^[0-9]+([,.][0-9]+)?$/g;
            if (!regex.test(newValue)) {
                return {
                    valid: false,
                    message: 'Input must be a number'
                };
            }
            return true;
        }
    },
    {
        dataField: 'Capacity',
        text: 'Capacity',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'right',
        align: 'right',
        formatter: commaFormatter,
        editable: false
    }
];

/**
 * Bootstrap table column schema creator for the EditAmbulatoryCareCapacityModal component.
 *
 * @param {boolean} saving - is table data currently being saved?
 * @param {Function} updateCellRowValue - called when updating a cell's value
 * @returns {object[]} column schema definition for the ambulatory care modal
 */
export const AmbulatoryCareCapacityColumnDefs = (saving, updateCellRowValue) => [
    {
        accessorKey: 'Hspc',
        header: 'HSPC',
        meta: {
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '40%' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'ProviderPosition',
        header: 'Provider Position',
        meta: {
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'WorkFTEE',
        header: 'Work FTEE',
        meta: {
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            editable: true,
            editInputAttributes: { type: 'number' }
        }
    },
    {
        accessorKey: 'ActiveProductivityStandard',
        header: 'Productivity Standard',
        cell: ({ getValue, row, column, table }) => (
            <CustomActiveProductivityStandardCell
                val={getValue()}
                row={row}
                column={column}
                table={table}
                update={updateCellRowValue}
            />
        ),
        meta: {
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Capacity',
        header: 'Capacity',
        meta: {
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: commaFormatter
        }
    }
];

export default AmbulatoryCareCapacityColumns;
