import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

/**
 * A button to open the settings for the row's report.  Uses react-router to go to the link.
 *
 * @param {object} props - props passed into this component
 * @returns {React.ReactNode} the rendered button component
 */
const EditReportButton = ({ id }) => {
    const { url } = useRouteMatch();
    const parentUrl = url.substring(0, url.lastIndexOf('/'));

    return (
        <Link to={`${parentUrl}/Edit/${id}`}>
            <Button variant="warning" type="button" style={{ paddingTop: 1, paddingBottom: 1 }}>
                Edit
            </Button>
        </Link>
    );
};

EditReportButton.propTypes = {
    /** The ID of the row related to this report. */
    id: PropTypes.number.isRequired
};

export default EditReportButton;
