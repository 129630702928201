/**
 * Master component that controls the rurality tables placement.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import {
    getRuralityCountySelector,
    getRuralityPercentSelector,
    getRuralityTotalsSelector
} from 'Modules/EnvironmentalScan/Services/selector';
import { getCurrentMarketName, getCurrentVISN } from 'Modules/UserSession/selector';

import ESRTable from './ESRTable';
import * as TableOutline from './TableOutline';

const ESRurality = (props) => {
    return (
        <div>
            <div className="row">
                <div className="col-sm-6">
                    <ESRTable
                        keyValue="Id"
                        tableColumnDefs={TableOutline.RuralityTotalColumnDefs()}
                        tableData={props.ruralityTotalsList}
                        currentMarket={props.marketName}
                        fetchDataFunc={props.ruralityClassFetchList}
                        csvFileName={`${props.marketName}-RuralityTotal.csv`}
                        generatePowerpointFunc={() =>
                            props.generatePowerPointAsync({
                                ruralityTable: 'Rurality1',
                                market: props.marketName,
                                visnCode: props.currentVISN.VisnCode
                            })
                        }
                    />
                </div>
                <div className="col-sm-6">
                    <ESRTable
                        keyValue="Id"
                        tableColumnDefs={TableOutline.RuralityPercentageColumnDefs()}
                        tableData={props.ruralityPercentList}
                        currentMarket={props.marketName}
                        fetchDataFunc={props.ruralityPercentFetchList}
                        csvFileName={`${props.marketName}-Rurality.csv`}
                        generatePowerpointFunc={() =>
                            props.generatePowerPointAsync({
                                ruralityTable: 'Rurality2',
                                market: props.marketName,
                                visnCode: props.currentVISN.VisnCode
                            })
                        }
                    />
                </div>
                <div className="col-sm-12">
                    <ESRTable
                        keyValue="Id"
                        tableColumnDefs={TableOutline.RuralityCitationCountyColumnDefs()}
                        tableData={props.ruralityCountyList}
                        currentMarket={props.marketName}
                        fetchDataFunc={props.ruralityCountyFetchList}
                        csvFileName={`${props.marketName}-RuralityCounty.csv`}
                        generatePowerpointFunc={() =>
                            props.generatePowerPointAsync({
                                ruralityTable: 'RuralityByCounty',
                                market: props.marketName,
                                visnCode: props.currentVISN.VisnCode
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ruralityClassFetchList: (market) => dispatch.RuralityModel.fetchRuralityTotalsAsync(market),
        ruralityPercentFetchList: (market) =>
            dispatch.RuralityModel.fetchRuralityPercentAsync(market),
        ruralityCountyFetchList: (market) =>
            dispatch.RuralityModel.fetchRuralityCountyAsync(market),
        generatePowerPointAsync: (payload) =>
            dispatch.RuralityModel.generatePowerPointAsync(payload)
    };
};

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    currentVISN: getCurrentVISN(state),
    ruralityCountyList: getRuralityCountySelector(state),
    ruralityPercentList: getRuralityPercentSelector(state),
    ruralityTotalsList: getRuralityTotalsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ESRurality);
