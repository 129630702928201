import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadFacilities,
    selectFacilities,
    selectFacilitiesLoadingStatus
} from 'Modules/Report/Services/FacilityReport';
import { getCurrentMarketName } from 'Modules/UserSession/selector';

/**
 * A React hook to retrieve the facility list data for a specific base year.
 *
 * @param baseYear - the base year
 * @returns the list of facilities
 */
const useFacilityList = (baseYear) => {
    const marketName = useSelector(getCurrentMarketName);
    const facilities = useSelector(selectFacilities);
    const loadingStatus = useSelector(selectFacilitiesLoadingStatus);
    const dispatch = useDispatch();

    // Dispatch an action to load facilities for the current market
    useEffect(() => {
        if (marketName && baseYear) {
            dispatch(loadFacilities({ marketName, year: baseYear }));
        }
    }, [marketName, baseYear, dispatch]);

    return { facilities, loadingStatus };
};

export default useFacilityList;
