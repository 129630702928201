import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

type DebouncedFormControlProps = {
    value?: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size' | 'value'> &
    Omit<FormControlProps, 'onChange' | 'value'>;

/**
 * FormControl component for handling debounced inputs.
 *
 * @param props - The input properties, including properties from FormControlProps.
 * @param props.value - The initial value for the control.
 * @param props.onChange - The onChange event.
 * @param props.debounce - The debounce timeout in milliseconds (defaults to 500).
 * @returns - The DebouncedFormControl component.
 */
const DebouncedFormControl = ({
    value: initialValue = '',
    onChange,
    debounce = 500,
    ...props
}: DebouncedFormControlProps) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => onChange(value), debounce);
        return () => clearTimeout(timeout);
    }, [value]);

    return <Form.Control {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
};

export default DebouncedFormControl;
