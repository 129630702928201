import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@tanstack/react-table';

export type ColumnVisibilityButtonProps = {
    table?: Table<unknown>;
    visibilityButtonText?: string;
    visibilityButtonColor?: ButtonVariant;
};

/**
 * The column visibility component.
 *
 * @param props - The visibility properties.
 * @param props.table - The table object.
 * @param props.visibilityButtonText - The text of the dropdown button.
 * @param props.visibilityButtonColor - The color of the dropdown button.
 * @returns - The column visibility component.
 */
const ColumnVisibilityButton = ({
    table,
    visibilityButtonText = 'Show/Hide Columns',
    visibilityButtonColor = 'secondary'
}: ColumnVisibilityButtonProps) => (
    <DropdownButton title={visibilityButtonText} variant={visibilityButtonColor}>
        {table?.getAllLeafColumns().map((column) => {
            if (column.columnDef.enableHiding !== true) {
                return null;
            }

            const icon = column.getIsVisible() === true ? faEye : faEyeSlash;
            const displayName = column.columnDef.meta?.headerDisplayName || column.id;
            return (
                <Dropdown.Item
                    key={column.id}
                    value={column.id}
                    onClick={column.getToggleVisibilityHandler()}
                >
                    <FontAwesomeIcon icon={icon} />
                    &nbsp;{displayName}
                </Dropdown.Item>
            );
        })}
    </DropdownButton>
);

export default ColumnVisibilityButton;
