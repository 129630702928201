import { getJSON } from 'Utilities/apiCalls';

const facilityReportApiUrl = `${process.env.REACT_APP_HSPA_API}/FacilityReport/`;

/**
 * Submit a request to get all facilities within a market.
 *
 * @param {string} marketName - name of a VA market
 * @param {string} year - the base year of the report data
 * @returns {string[]} array of all facility names within the requested market
 */
export const getFacilitiesByMarket = (marketName, year) =>
    getJSON(`${facilityReportApiUrl}GetFacilitiesByMarket?marketName=${marketName}&year=${year}`);
/**
 * Request to get a facility's demand/capacity report data.
 *
 * @param {string} stationNumber - station number of a VA health care facility
 * @param {string} year - the base year of the report data
 * @returns {object} data used in rendering a facility's demand/capacity report
 */
export const getFacilityReport = (stationNumber, year) =>
    getJSON(`${facilityReportApiUrl}GetFacilityReport?stationNumber=${stationNumber}&year=${year}`);
