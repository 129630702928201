/**
 * Dashboard for Understand Capacity providers.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */

import React from 'react';
import { connect } from 'react-redux';

import _forOwn from 'lodash/forOwn';
import _groupBy from 'lodash/groupBy';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getProvidersSelector } from 'Modules/UnderstandCapacity/Services/selector';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import AllProvidersColumnDefs from './TableOutline';

class UCProvidersDashboard extends React.Component {
    static propTypes = {
        marketName: PropTypes.string.isRequired,
        providers: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        changeTab: PropTypes.func.isRequired,
        fetchProvidersAsync: PropTypes.func.isRequired,
        populateProviderForm: PropTypes.func.isRequired,
        deleteProvider: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false
    };

    componentDidMount() {
        const { marketName, providers, fetchProvidersAsync } = this.props;

        if (marketName && providers.list.length === 0) {
            fetchProvidersAsync(marketName);
        }
    }

    componentDidUpdate(prevProps) {
        const { marketName, fetchProvidersAsync } = this.props;
        const { marketName: prevMarketName } = prevProps;

        if (prevMarketName !== marketName) {
            fetchProvidersAsync(marketName);
        }
    }

    facilityFilter() {
        const { providers } = this.props;

        const filterArray = [];
        _forOwn(_groupBy(providers.list, 'FacilityTypeString'), (value, key) => {
            filterArray.push(key);
        });
        const result = {};
        for (let i = 0; i < filterArray.length; i++) {
            result[filterArray[i]] = filterArray[i];
        }
        return result;
    }

    render() {
        const {
            marketName,
            providers,
            canEdit,
            changeTab,
            populateProviderForm,
            deleteProvider
        } = this.props;

        return (
            <div>
                <ReactDataTable
                    keyValue="Id"
                    list={providers.list}
                    loading={providers.loading}
                    columns={AllProvidersColumnDefs(
                        async (id) => {
                            await populateProviderForm(id);
                            changeTab(1);
                        },
                        (id) => deleteProvider(id, marketName),
                        this.facilityFilter(),
                        canEdit
                    )}
                    csvFileName={`${marketName}-Providers.csv`}
                    defaultVisibility={{ Id: false }}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProvidersAsync: (market) => dispatch.ProvidersModel.fetchProvidersAsync(market),
        deleteProvider: (providerId, market) =>
            dispatch.ProvidersModel.deleteProviderAsync({ providerId, market }),
        populateProviderForm: (Id) => dispatch.ProvidersModel.populateProviderForm(Id)
    };
};

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    providers: getProvidersSelector(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UCProvidersDashboard);
