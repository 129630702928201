import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import globalFilterFn from 'Lib/ReactDataTable/globalFilterFn';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { loadCapitalProjects } from 'Modules/CapitalProject/capitalProjectsSlice';
import {
    selectCapitalProjects,
    selectCapitalProjectsApprovalStatus,
    selectCapitalProjectsDeleteStatus,
    selectCapitalProjectsLoadingStatus
} from 'Modules/CapitalProject/selectors';
import { getCurrentMarket } from 'Modules/UserSession/selector';
import { AsyncState } from 'Utilities/constants';
import { CapitalProject } from 'Utilities/types';

import { ColumnDef, FilterFn } from '@tanstack/react-table';

import columns from './CapitalProjectTable/columns';

/**
 * Table card for the Capital Projects lists based on the current selected Market.
 *
 * @returns - The JSX for the table card.
 */
const CapitalProjectTableCard = (): JSX.Element => {
    const dispatch = useDispatch();
    const market = useSelector(getCurrentMarket);
    const loadingStatus = useSelector(selectCapitalProjectsLoadingStatus);
    const approvalStatus = useSelector(selectCapitalProjectsApprovalStatus);
    const deleteStatus = useSelector(selectCapitalProjectsDeleteStatus);
    const capitalProjects = useSelector(selectCapitalProjects);

    useEffect(() => {
        if (
            approvalStatus !== AsyncState.FAILURE &&
            approvalStatus !== AsyncState.PENDING &&
            deleteStatus !== AsyncState.FAILURE &&
            deleteStatus !== AsyncState.PENDING
        ) {
            dispatch(loadCapitalProjects(market.Id as number));
        }
    }, [dispatch, market.MarketId, approvalStatus, deleteStatus]);

    useEffect(() => {
        if (approvalStatus === AsyncState.SUCCESS) {
            dispatch(
                addNotification({
                    message: 'Successfully updated the capital project approval status.',
                    level: HSPANotificationLevel.SUCCESS
                })
            );
        }
        if (approvalStatus === AsyncState.FAILURE) {
            dispatch(
                addNotification({
                    message: 'Failed to update the capital project approval status.',
                    level: HSPANotificationLevel.ERROR
                })
            );
        }
    }, [approvalStatus]);

    useEffect(() => {
        if (deleteStatus === AsyncState.SUCCESS) {
            dispatch(
                addNotification({
                    message: 'Failed to delete the capital project.',
                    level: HSPANotificationLevel.SUCCESS
                })
            );
        }
        if (deleteStatus === AsyncState.FAILURE) {
            dispatch(
                addNotification({
                    message: 'Successfully deleted the capital project.',
                    level: HSPANotificationLevel.ERROR
                })
            );
        }
    }, [deleteStatus]);

    const globalFilterFunction: FilterFn<unknown> = (row, columnId, value) => {
        const search = value.toLowerCase();
        const description = (row?.original as CapitalProject)?.description?.toLowerCase();
        return (
            globalFilterFn(row, columnId, value, () => {}) === true ||
            description?.includes(search) === true
        );
    };

    return (
        <>
            <h4 className="text-primary">
                <b>Capital Projects</b>
            </h4>
            <ReactDataTable
                keyValue="Id"
                list={capitalProjects}
                loading={loadingStatus === AsyncState.PENDING}
                columns={columns as ColumnDef<unknown>[]}
                csvFileName={`${market.MarketName}-Capital-Projects.csv`}
                showHideColumns={true}
                globalFilterFunction={globalFilterFunction}
                isPageable={false}
                containerStyle={{ paddingBottom: '15px' }}
            />
        </>
    );
};

export default CapitalProjectTableCard;
