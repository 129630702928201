import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import {
    getStrategicOutlookMetrics,
    getVisnMetrics
} from 'Modules/StrategicOutlook/Services/selector';
import { getCurrentMarketName, getCurrentVISN, getMarketList } from 'Modules/UserSession/selector';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SOMetricGroup from './SOMetricGroup';

const TallNav = styled(Nav)`
    height: 30px;
`;

const displayViewFilterConfig = [
    {
        viewName: 'visn',
        label: 'Display All Markets Under VISN'
    },
    {
        viewName: 'market',
        label: 'Display Selected Market'
    }
];

const thresholdFilterConfig = [
    {
        color: '#0059ff',
        label: 'Metrics Within +/- 25% Change',
        filterType: 'WithinThreshold'
    },
    {
        color: '#ffa500',
        label: 'Metrics Greater than +/- 25% Change',
        filterType: 'BelowThreshold'
    }
];

const SOMetricDashboard = ({ showsUtilizationMetrics = false, projectionType = 'Enrollment' }) => {
    const dispatch = useDispatch();
    const [displayView, setDisplayView] = useState('visn');
    const metricFilter = useSelector((state) => state.MetricFilterModel);
    const metrics = useSelector(getStrategicOutlookMetrics);
    const visnMetrics = useSelector(getVisnMetrics);
    const currentVISN = useSelector(getCurrentVISN);
    const marketList = useSelector(getMarketList);
    const marketName = useSelector(getCurrentMarketName);

    useEffect(() => {
        if (displayView === 'visn' && currentVISN.VisnCode) {
            dispatch.MetricProjectionsModel.fetchMetricProjectionsByVisnAsync(currentVISN.VisnCode);
        }
    }, [displayView, currentVISN.VisnCode]);

    useEffect(() => {
        if (displayView === 'market' && marketName) {
            dispatch.MetricProjectionsModel.fetchMetricProjectionsByMarketAsync(marketName);
        }
    }, [displayView, marketName]);

    const toggleModalFunc = (id, hsp, market) => {
        const metric = metrics.find((item) => item.Id === id);

        if (projectionType === 'Enrollment' || projectionType === 'Demand') {
            dispatch.MetricPopupModel.toggleShow(metric);
            dispatch.MetricPopupModel.fetchMetricModalDataAsync({
                hspc: hsp,
                market,
                projectionType
            });
        }
    };

    const filterMetrics = (currentValue, type) => {
        dispatch.MetricFilterModel.updateThresholdFilter(type, !currentValue);
    };

    const filteredMarkets = marketList.list.filter(
        (item) => item.VisnCode === currentVISN.VisnCode
    );

    const metricGroups = filteredMarkets.map((item) => {
        const filteredMetrics = visnMetrics.filter(
            (metric) =>
                metric.market === item.MarketName &&
                metric.projectionType === projectionType &&
                ((metric.status === 'good' && metricFilter.WithinThreshold.filter === true) ||
                    (metric.status === 'bad' &&
                        metricFilter.BelowThreshold.filter === true &&
                        metric.status !== 'undetermined'))
        );

        return (
            <div key={item.Id}>
                <SOMetricGroup
                    id={item.Id}
                    metrics={filteredMetrics || []}
                    market={item.MarketName}
                    display={displayView}
                    toggleModalFunc={toggleModalFunc}
                />
            </div>
        );
    });

    const notice = showsUtilizationMetrics ? (
        <div>
            {/* <Alert role="note" variant="warning" className="mt-2">
                <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                <span style={{ paddingLeft: '5px' }}>
                    Due to complications caused by the COVID-19 pandemic, data for 2022-2023 is invalid and will not be shown.
                </span>
            </Alert> */}
            <Alert role="note" variant="secondary" className="mt-2">
                <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                <span style={{ paddingLeft: '5px' }}>
                    Metrics shown here only include in-market utilization.
                </span>
            </Alert>
        </div>
    ) : null;

    // Generate VISN/Market view toggle buttons using displayViewFilterConfig
    const displayViewToggles = displayViewFilterConfig.map((item) => (
        <Nav.Item key={item.label}>
            <Nav.Link
                className={displayView === item.viewName ? 'active' : ''}
                onClick={() => setDisplayView(item.viewName)}
            >
                {item.label}
            </Nav.Link>
        </Nav.Item>
    ));

    // Generate threshold filter buttons using thresholdFilterConfig
    const thresholdFilterToggles = thresholdFilterConfig.map((item) => {
        const isFilterActive = metricFilter[item.filterType]?.filter;
        return (
            <Nav.Item key={item.label}>
                <Nav.Link
                    style={{ backgroundColor: isFilterActive ? item.color : '' }}
                    className={isFilterActive ? 'active' : ''}
                    onClick={() => filterMetrics(isFilterActive, item.filterType)}
                >
                    {item.label}
                </Nav.Link>
            </Nav.Item>
        );
    });

    const thresholdFilterNav =
        projectionType === 'In-House Capacity' ? null : (
            <Col xs={6}>
                <TallNav fill variant="pills">
                    {thresholdFilterToggles}
                </TallNav>
            </Col>
        );

    return (
        <Container>
            <Row>
                <Col xs={6}>
                    <TallNav fill variant="pills" style={{ paddingLeft: 15 }}>
                        {displayViewToggles}
                    </TallNav>
                </Col>
                {thresholdFilterNav}
            </Row>
            <Row>
                <Col>{notice}</Col>
            </Row>
            <Row>
                <Col>{metricGroups}</Col>
            </Row>
        </Container>
    );
};

export default SOMetricDashboard;
