/**
 * Displays report templates in a table.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getTemplateReportsSelector } from 'Modules/Report/Services/selector';
import { getCurrentMarketName } from 'Modules/UserSession/selector';

import TemplateReportColumnDefs from './TableOutline';

/**
 * React component that displays HSPA report templates in a table.
 *
 * @returns {React.ReactNode} the rendered report template table component
 */
const TemplateReports = () => {
    const dispatch = useDispatch();
    const marketName = useSelector(getCurrentMarketName);
    const templateReports = useSelector(getTemplateReportsSelector);

    // If the market has changed, request the template reports
    useEffect(() => {
        if (marketName) {
            dispatch.SavedReportModel.fetchTemplateReportsAsync(marketName);
        }
    }, [dispatch, marketName]);

    return (
        <div>
            <h4 className="text-primary">
                <b>Template Reports</b>
            </h4>
            <ReactDataTable
                keyValue="Id"
                list={templateReports.list}
                columns={TemplateReportColumnDefs()}
                loading={templateReports.loading}
                isExportable={false}
            />
        </div>
    );
};

export default TemplateReports;
