/**
 * Understand demand selector
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import { createSelector } from 'reselect';

const getGapMpcTypes = (state) => state.UserSessionModel.gapMpcTypes;
export const getGapMpcTypesSelector = createSelector(getGapMpcTypes, (gapMpcTypes) => gapMpcTypes);

const getFutureMarketTrends = (state) => state.FutureMarketTrendsModel;
export const getFutureMarketTrendsSelector = createSelector(
    getFutureMarketTrends,
    (FutureMarketTrendsModel) => FutureMarketTrendsModel
);

const getReviewProjectedDemand = (state) => state.ReviewProjectedDemandModel;
export const getReviewProjectedDemandSelector = createSelector(
    getReviewProjectedDemand,
    (ReviewProjectedDemandModel) => ReviewProjectedDemandModel
);

export const makeGetFutureMarketTrend = () => {
    return createSelector(
        getFutureMarketTrends,
        (state, props) => props,
        (state, props) => {
            switch (props) {
                case 'HSPC Group Utilization':
                    return state.hspcGroupUtilization;
                case 'HSPC Group RVUs':
                    return state.hspcGroupRvu;
                case 'HSPC Utilization':
                    return state.hspcUtilization;
                case 'HSPC RVUs':
                    return state.hspcRvu;
                default:
                    return [];
            }
        }
    );
};

export const makeGetReviewProjectedDemand = () => {
    return createSelector(
        getReviewProjectedDemand,
        (state, props) => props,
        (state, props) => {
            switch (props) {
                case 'Market Utilization':
                    return state.marketUtilization;
                case 'Sub-Market Utilization':
                    return state.subMarketUtilization;
                case 'Market RVUs':
                    return state.marketRvu;
                case 'Sub-Market RVUs':
                    return state.subMarketRvu;
                case 'Facility Utilization':
                    return state.facilityUtilization;
                case 'Facility RVUs':
                    return state.facilityRvu;
                case 'Market Level Utilization':
                    return state.hspcLevelUtilization;
                case 'Market Level RVU':
                    return state.hspcLevelRvu;
                default:
                    return [];
            }
        }
    );
};

const getPatientOriginFacilities = (state) => state.PatientOriginToolModel.facilities;
export const getPatientOriginFacilitiesSelector = createSelector(
    getPatientOriginFacilities,
    (facilities) => facilities
);

const getPatientOriginMpcs = (state) => state.PatientOriginToolModel.hspcs;
export const getPatientOriginMpcsSelector = createSelector(getPatientOriginMpcs, (hspcs) => hspcs);

const getPatientOriginOriginsList = (state) => state.PatientOriginToolModel.origins;
export const getPatientOriginOriginsListSelector = createSelector(
    getPatientOriginOriginsList,
    (origins) => origins
);

const getPatientOrigins = (state) => state.PatientOriginToolModel.patientOrigins;
export const getPatientOriginsSelector = createSelector(
    getPatientOrigins,
    (patientOrigins) => patientOrigins
);

const getPatientOriginForm = (state) => state.PatientOriginToolModel.patientOriginForm;
export const getPatientOriginFormSelector = createSelector(
    getPatientOriginForm,
    (patientOriginForm) => patientOriginForm
);

const getPatientOriginWorkloadTypes = (state) => state.PatientOriginToolModel.workloadTypes;
export const getPatientOriginWorkloadTypesSelector = createSelector(
    getPatientOriginWorkloadTypes,
    (workloadTypes) => workloadTypes
);

const getAnalysisToolVisns = (state) => state.AnalysisToolModel.visns;
export const getAnalysisToolVisnsSelector = createSelector(getAnalysisToolVisns, (visns) => visns);

const getAnalysisToolMarkets = (state) => state.AnalysisToolModel.markets;
export const getAnalysisToolMarketsSelector = createSelector(
    getAnalysisToolMarkets,
    (markets) => markets
);

const getAnalysisToolWorkloadTypes = (state) => state.AnalysisToolModel.workloadTypes;
export const getAnalysisToolWorkloadTypesSelector = createSelector(
    getAnalysisToolWorkloadTypes,
    (workloadTypes) => workloadTypes
);

const getAnalysisToolHspcTypes = (state) => state.AnalysisToolModel.hspcTypes;
export const getAnalysisToolHspcTypesSelector = createSelector(
    getAnalysisToolHspcTypes,
    (hspcTypes) => hspcTypes
);

const getAnalysisToolList = (state) => state.AnalysisToolModel.analysisToolList;
export const getAnalysisToolListSelector = createSelector(
    getAnalysisToolList,
    (analysisToolList) => analysisToolList
);

const getAnalysisToolAnalysisToolForm = (state) => state.AnalysisToolModel.analysisToolForm;
export const getAnalysisToolAnalysisToolFormSelector = createSelector(
    getAnalysisToolAnalysisToolForm,
    (analysisToolForm) => analysisToolForm
);

const getWaitTimes = (state) => state.WaitTimesModel;
export const getWaitTimesSelector = createSelector(
    getWaitTimes,
    (WaitTimesModel) => WaitTimesModel
);

const getWorkloadAllocationMatrixMpcs = (state) => state.WorkloadAllocationMatrixModel.hspcOptions;
export const getWorkloadAllocationMatrixMpcsSelector = createSelector(
    getWorkloadAllocationMatrixMpcs,
    (hspcOptions) => hspcOptions
);

const getWorkloadAllocationMatrixData = (state) =>
    state.WorkloadAllocationMatrixModel.matrixTableData;
export const getWorkloadAllocationMatrixDataSelector = createSelector(
    getWorkloadAllocationMatrixData,
    (matrixTableData) => matrixTableData
);
