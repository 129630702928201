/**
 * The Review Projected Demand Market table expandable section table contents.
 *
 * @author Brandan D. Reed
 */
import React from 'react';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import renderNumericColumnDef from 'Modules/UnderstandDemand/Components/renderNumericColumn';
import PropTypes from 'prop-types';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

// Settings to generate props for each of the year-based sections in the table
const sectionConfiguration = [
    {
        sectionLabelGenerator: (defaultYear) =>
            `FY ${defaultYear.twoYearActual} Actual Utilization`,
        dataFieldPrefix: 'ActualYear2'
    },
    {
        sectionLabelGenerator: (defaultYear) =>
            `FY ${defaultYear.oneYearActual} Actual Utilization`,
        dataFieldPrefix: 'ActualYear1'
    },
    {
        sectionLabelGenerator: (defaultYear) =>
            defaultYear.defaultYearWithFiscalStringBaselineUtilization,
        dataFieldPrefix: 'Year0',
        isKey: true
    },
    {
        sectionLabelGenerator: (defaultYear) =>
            defaultYear.fiveYearWithFiscalStringAllocationUtilization,
        dataFieldPrefix: 'Year5'
    },
    {
        sectionLabelGenerator: (defaultYear) =>
            defaultYear.tenYearWithFiscalStringAllocationUtilization,
        dataFieldPrefix: 'Year10'
    },
    {
        sectionLabelGenerator: (defaultYear) =>
            defaultYear.twentyYearWithFiscalStringAllocationUtilization,
        dataFieldPrefix: 'Year20'
    }
];

const renderColumnDefSection = (sectionLabel, dataFieldPrefix) => columnHelper.group({
        header: sectionLabel,
        meta: {
            headerDisplayName: sectionLabel,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', fontSize: 12 }
        },
        columns: [
            renderNumericColumnDef(`${dataFieldPrefix}Total`, 'Total'),
            renderNumericColumnDef(`${dataFieldPrefix}ProvidedIn`, 'Provided In Market'),
            renderNumericColumnDef(`${dataFieldPrefix}ProvidedOut`, 'Provided In Other Markets'),
            renderNumericColumnDef(`${dataFieldPrefix}Purchased`, 'Community Care')
        ]
    });

/**
 * The Projected Demand Market table's content for expanded section.
 *
 * @param {object} props - props passed into this component
 * @returns {React.ReactNode} the rendered component
 */
const ExpandTable = (props) => {
    const { defaultYear, data } = props;

    if (data.length === 0 || !defaultYear || defaultYear.defaultYear === '') {
        return <p>There is no RVU data to display</p>;
    }

    const columnDefs = [
        columnHelper.group({
            header: `${data[0].HspcGroup} RVU`,
            meta: {
                headerDisplayName: `${data[0].HspcGroup} RVU`,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap', fontSize: 12 }
            },
            columns: sectionConfiguration.map((section) => {
                const { sectionLabelGenerator, dataFieldPrefix } = section;
                return renderColumnDefSection(sectionLabelGenerator(defaultYear), dataFieldPrefix);
            })
        })
    ];

    return (
        <ReactDataTable
            list={data}
            columns={columnDefs}
            isSortable={false}
            isPageable={false}
            isSearchable={false}
            isExportable={false}
        />
    );
};

ExpandTable.propTypes = {
    /** The formatted default year collection. */
    defaultYear: PropTypes.shape({
        /** The year two years ago. */
        twoYearActual: PropTypes.string,
        /** The previous year. */
        oneYearActual: PropTypes.string,
        /** FY-formatted current year with baseline utilization label. */
        defaultYearWithFiscalStringBaselineUtilization: PropTypes.string,
        previousYearWithFiscalStringBaselineUtilization: PropTypes.string,
        /** FY-formatted current year. */
        defaultYearWithFiscalString: PropTypes.string,
        /** FY-formatted value five years in the future with baseline utilization label. */
        fiveYearWithFiscalStringAllocationUtilization: PropTypes.string,
        /** FY-formatted value five years in the future. */
        fiveYearWithFiscalString: PropTypes.string,
        /** FY-formatted value ten years in the future with allocated utilization label. */
        tenYearWithFiscalStringAllocationUtilization: PropTypes.string,
        /** FY-formatted value ten years in the future. */
        tenYearWithFiscalString: PropTypes.string,
        /** FY-formatted value twenty years in the future with allocated utilization label. */
        twentyYearWithFiscalStringAllocationUtilization: PropTypes.string,
        /** FY-formatted value twenty years in the future. */
        twentyYearWithFiscalString: PropTypes.string
    }),
    /** The data to display in the table */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            /** The related specialty. */
            HspcGroup: PropTypes.string
        })
    )
};

ExpandTable.defaultProps = {
    defaultYear: {},
    data: []
};

export default ExpandTable;
