import React, { useCallback } from 'react';

import styled from 'styled-components/macro';

const StyledLink = styled.a`
    position: fixed;
    display: flex;
    align-items: center;
    padding: 10px;
    top: -1000px;
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    z-index: 100;
    color: #303030;

    &:focus {
        top: 0;
        text-decoration: none;
    }
`;

type SkipLinkProps = {
    contentElement: HTMLElement;
};

/**
 * A link that hides until it has focus.  This is used to assist keyboard navigation to skip over
 * menu content.
 *
 * @param props - props passed into this component
 * @param props.contentElement - the HTML element to move focus to on click
 * @returns the link element
 */
const SkipLink = ({ contentElement }: SkipLinkProps): JSX.Element => {
    const onClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            contentElement.setAttribute('tabindex', '0');
            contentElement.focus();

            /**
             * Remove focus capability from element when it loses focus.
             */
            contentElement.onblur = () => {
                contentElement.removeAttribute('tabindex');
                contentElement.onblur = null;
            };
        },
        [contentElement]
    );

    return (
        <StyledLink onClick={onClick} href="">
            Skip to main content
        </StyledLink>
    );
};

export default SkipLink;
