import { AsyncState } from 'Utilities/constants';
import { FacilitySelectionDTO } from 'Utilities/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FacilitySelectionState = {
    items: FacilitySelectionDTO[];
    market?: string;
    loadingStatus?: AsyncState;
};

export type FacilitySelectionPayload = {
    types: FacilitySelectionDTO[];
    market: string;
};

const facilitySelectionSlice = createSlice({
    name: 'Facility Selection',
    initialState: { items: [] } as FacilitySelectionState,
    reducers: {
        /**
         * Reducer for setting the facility selection.
         *
         * @param draftState - The initial draft state.
         * @param action - The payload action.
         * @param action.payload - The payload contained in the action.
         */
        setFacilitySelection(draftState, { payload }: PayloadAction<FacilitySelectionPayload>) {
            draftState.items = payload.types;
            draftState.market = payload.market;
        },
        /**
         * Does not update the state, but the related action is use to kick off an epic that loads
         * the facility selection.
         *
         * @param draftState - The draft state.
         * @param action - The payload action.
         */
        loadFacilitySelection(
            draftState,
            action: PayloadAction<string>
        ) {},
        /**
         * Set the facility selection loading status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadFacilitySelectionStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },

        /**
         * Set the facility selection loading status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadFacilitySelectionDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the facility selection loading status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadFacilitySelectionError(draftState) {
            draftState.loadingStatus = AsyncState.FAILURE;
        }
    }
});

export const {
    setFacilitySelection,
    loadFacilitySelection,
    loadFacilitySelectionStart,
    loadFacilitySelectionDone,
    loadFacilitySelectionError
} = facilitySelectionSlice.actions;

export default facilitySelectionSlice.reducer;
