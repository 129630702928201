import React, { useState } from 'react';
import { Container, Table, TableProps } from 'react-bootstrap';

import { CSSProperties } from 'styled-components';

import {
    Column,
    ColumnDef,
    ColumnFiltersState,
    ExpandedState,
    FilterFn,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getGroupedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    GroupingState,
    Row,
    SortingFn,
    SortingState,
    TableOptions,
    useReactTable,
    VisibilityState
} from '@tanstack/react-table';

import CellEditOptions from './CellEditOptions';
import CustomExpandToggleColumnDef from './CustomExpandToggleColumnDef';
import editColumn from './editColumn';
import ExpandToggleColumnDef from './ExpandToggleColumnDef';
import getRowIdFunction from './getRowIdFunction';
import globalFilterFn from './globalFilterFn';
import TableBody, { BodyProps } from './TableBody';
import TableControls, { TableControlProps } from './TableControls';
import TableHeader, { HeaderProps } from './TableHeader';
import TablePagination, { PaginationProps } from './TablePagination';
import useSkipper from './useSkipper';

export type ReactDataTableProps = {
    columns: ColumnDef<unknown>[];
    list: unknown[];
    keyValue?: string;
    isPageable?: boolean;
    isSortable?: boolean;
    defaultSorted?: SortingState;
    defaultGrouped?: GroupingState;
    defaultVisibility?: VisibilityState;
    tableClassName?: string;
    tableStyle?: CSSProperties;
    isEditable?: boolean;
    cellEditOptions?: CellEditOptions<unknown>;
    subRowGenerator?: (originalRow: unknown, index: number) => undefined | unknown[];
    sortingFns?: Record<string, SortingFn<unknown>>;
    containerStyle?: CSSProperties;
    showHeader?: boolean;
    globalFilterFunction?: FilterFn<unknown>;
} & TableProps &
    Omit<TableControlProps, 'onSearch'> &
    Omit<PaginationProps, 'table'> &
    Omit<BodyProps, 'table'> &
    Omit<HeaderProps, 'table'>;

const ReactDataTable = ({
    columns: defaultColumns,
    list,
    keyValue = 'Id',
    isExportable = true,
    isSearchable = true,
    showCsvPptBtnGroup = false,
    isPageable = true,
    isSortable = true,
    defaultSorted = [],
    defaultGrouped = [],
    defaultVisibility = {},
    pageSizes = [10, 25, 30, 50],
    csvFileName = '',
    loading = false,
    showHideColumns = false,
    btnRightFunc,
    btnRightColor = 'primary',
    btnRightLabel = '',
    visibilityButtonText,
    visibilityButtonColor,
    subRowGenerator,
    expandContent,
    striped = true,
    bordered = true,
    hover = true,
    size = 'sm',
    borderless = false,
    responsive = false,
    variant,
    bsPrefix = 'table',
    tableClassName = '',
    tableStyle,
    isEditable = false,
    cellEditOptions,
    sortingFns,
    rowClasses,
    rowStyles,
    containerStyle = { marginBottom: '15px' },
    showHeader = true,
    globalFilterFunction
}: ReactDataTableProps): JSX.Element => {
    const [sorting, setSorting] = useState<SortingState>(defaultSorted);
    const [grouping, setGrouping] = useState<GroupingState>(defaultGrouped);
    const [globalFilter, setGlobalFilter] = useState<string | number>('');
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultVisibility);
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

    const getColumns = () => {
        if (subRowGenerator && expandContent) {
            return [CustomExpandToggleColumnDef, ExpandToggleColumnDef, ...defaultColumns];
        }

        if (subRowGenerator) {
            return [ExpandToggleColumnDef, ...defaultColumns];
        }

        if (expandContent) {
            return [CustomExpandToggleColumnDef, ...defaultColumns];
        }

        return defaultColumns;
    };

    const hasGroupableColumn = defaultColumns.findIndex((column) => column.enableGrouping) > -1;

    let defaultColumn: Partial<ColumnDef<unknown, unknown>> = {
        enableSorting: isSortable,
        enableGlobalFilter: isSearchable,
        enableHiding: showHideColumns,
        enableColumnFilter: false,
        enableGrouping: false,
        enableResizing: false
    };

    if (isEditable === true) {
        defaultColumn = { ...defaultColumn, ...editColumn };
    } else {
        defaultColumn = {
            ...defaultColumn,
            cell: ({ getValue, column }) =>
                column.columnDef.meta?.formatter
                    ? column.columnDef.meta.formatter(getValue())
                    : getValue()
        };
    }

    let tableOptions: TableOptions<unknown> = {
        data: list,
        columns: getColumns(),
        defaultColumn,
        state: {
            grouping,
            sorting,
            columnFilters,
            globalFilter,
            columnVisibility,
            expanded
        },
        sortingFns,
        autoResetPageIndex,
        getSubRows: subRowGenerator,
        getRowId: getRowIdFunction(keyValue),
        onGroupingChange: setGrouping,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onExpandedChange: setExpanded,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: globalFilterFunction ?? globalFilterFn,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        meta: {
            expandContent,
            cellEditOptions,
            updateData: (
                oldValue: unknown,
                newValue: unknown,
                row: Row<unknown>,
                column: Column<unknown, unknown>
            ) => {
                skipAutoResetPageIndex();
                if (
                    !cellEditOptions?.validateSaveCell ||
                    cellEditOptions.validateSaveCell(oldValue, newValue, row, column) === true
                ) {
                    if (cellEditOptions?.beforeSaveCell) {
                        cellEditOptions.beforeSaveCell(oldValue, newValue, row.original, column);
                    }
                    list.forEach((item, index) => {
                        if (typeof item === 'object' && item && index === row.index) {
                            item[column.id] = newValue;
                            item['edited'] = true;
                        }
                    });

                    if (cellEditOptions?.afterSaveCell) {
                        cellEditOptions.afterSaveCell(oldValue, newValue, row.original, column);
                    }
                }
            }
        }
    };

    if (isPageable && !hasGroupableColumn) {
        tableOptions = { ...tableOptions, getPaginationRowModel: getPaginationRowModel() };
    }

    const table = useReactTable(tableOptions);

    if (!defaultColumns || defaultColumns.length <= 0 || !list) {
        return <></>;
    }

    return (
        <Container className="overflow-x-auto" fluid="sm" style={containerStyle}>
            {isExportable === true || showCsvPptBtnGroup === true || isSearchable === true ? (
                <TableControls
                    isExportable={isExportable}
                    isSearchable={isSearchable}
                    showCsvPptBtnGroup={showCsvPptBtnGroup}
                    csvFileName={csvFileName}
                    onSearch={setGlobalFilter}
                    table={table}
                    showHideColumns={showHideColumns}
                    btnRightFunc={btnRightFunc}
                    btnRightColor={btnRightColor}
                    btnRightLabel={btnRightLabel}
                    visibilityButtonText={visibilityButtonText}
                    visibilityButtonColor={visibilityButtonColor}
                />
            ) : null}
            <Table
                className={tableClassName}
                style={tableStyle}
                borderless={borderless}
                striped={!expandContent ? striped : false}
                bordered={bordered}
                hover={hover}
                size={size}
                responsive={responsive}
                variant={variant}
                bsPrefix={bsPrefix}
            >
                {showHeader === true ? <TableHeader table={table} /> : null}
                <TableBody
                    key={keyValue}
                    table={table}
                    loading={loading}
                    expandContent={expandContent}
                    rowClasses={rowClasses}
                    rowStyles={rowStyles}
                />
            </Table>
            {isPageable && !hasGroupableColumn ? (
                <TablePagination table={table} pageSizes={pageSizes} />
            ) : null}
        </Container>
    );
};

export default ReactDataTable;
