import React from 'react';
import { CSVLink } from 'react-csv';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@tanstack/react-table';

export type ExportCSVButtonProps = {
    table?: Table<unknown>;
    csvFileName?: string;
};

/**
 * The export CSV button component.
 *
 * @param props - The properties of the component.
 * @param props.table - The table object.
 * @param props.csvFileName - The name of the file to export.
 * @returns - The export CSV button component.
 */
const ExportCSVButton = ({ table, csvFileName }: ExportCSVButtonProps) => {
    const headers = table
        ?.getAllLeafColumns()
        .filter(
            (column) =>
                column.getIsVisible() === true && column.columnDef.meta?.csvExportIgnore !== true
        )
        .map((column) => ({
            label: column.columnDef.meta?.headerDisplayName || column.id,
            key: column.id
        }));

    const data = table?.getCoreRowModel().rows.map((row) => {
        const result = {};
        row.getAllCells()
            .filter(
                (cell) =>
                    cell.column.getIsVisible() === true &&
                    cell.column.columnDef.meta?.csvExportIgnore !== true
            )
            .forEach((cell) => {
                const value = cell.column.columnDef.meta?.formatter
                    ? cell.column.columnDef.meta.formatter(cell.getValue())
                    : cell.getValue();
                result[cell.column.id] = value;
            });
        return result;
    });

    return (
        <CSVLink
            data={data}
            headers={headers}
            filename={csvFileName}
            className="btn btn-success"
            target="_blank"
        >
            <FontAwesomeIcon icon={faDownload} /> Export to CSV
        </CSVLink>
    );
};

export default ExportCSVButton;
