import React from 'react';

import DebouncedFormControl from 'Lib/DebouncedFormControl/DebouncedFormControl';

export type SearchBarProps = {
    onSearch: (value: string | number) => void;
};

/**
 * The Search Bar component for the table.
 *
 * @param props - The component properties.
 * @param props.onSearch - The search event.
 * @returns - The search bar component.
 */
const SearchBar = ({ onSearch }: SearchBarProps): JSX.Element => (
    <DebouncedFormControl
        className="mb-1"
        type="text"
        placeholder="Search..."
        onChange={onSearch}
    />
);

export default SearchBar;
