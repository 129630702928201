import React, { useState } from 'react';

import FocusTrap from 'focus-trap-react';
import _uniqueId from 'lodash/uniqueId';
import { Fade } from 'react-bootstrap';
import styled from 'styled-components/macro';

import VAFooter from '../VAFooter';
import VAHeader from '../VAHeader';

interface Props {
    children: JSX.Element;
    fadePage: boolean;
}

const ChildContainer = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    background-color: white;
`;

const VAContainer = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    position: absolute;
    display: flex;
    flex-direction: column;
`;

/**
 * React Element for placing a header with VA logo on a page
 *
 * @param {Props} Props - props passed into this component
 * @returns {JSX.Element} component to add a VA header and footer to a page
 */
const VAApplicationOverlay = ({ children, fadePage }: Props): JSX.Element => {
    const [id] = useState(_uniqueId('overlay-'));

    return (
        <Fade in={fadePage} unmountOnExit>
            <FocusTrap focusTrapOptions={{ initialFocus: `.${id}` }}>
                <VAContainer className={id} tabIndex={-1} role="dialog" aria-modal="true">
                    <VAHeader />
                    <ChildContainer>{children}</ChildContainer>
                    <VAFooter />
                </VAContainer>
            </FocusTrap>
        </Fade>
    );
};

export default VAApplicationOverlay;
