import { array } from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';

const email = 'vha108spasasstaff@va.gov';

interface CostErrorPanelProps {
    calculationType: string;
    costData: any[];
}

function CostErrorPanel(props: CostErrorPanelProps) {
    // If we are not on the cost page, the cost data is not an array, or the array is not empty
    if (
        props.calculationType !== 'Cost' ||
        !Array.isArray(props.costData) ||
        props.costData.length
    ) {
        return null;
    }

    const mailToLink = `mailto:${email}?subject=Missing Cost Data`;

    return (
        <Alert variant={'warning'} style={{ display: 'flex' }}>
            <div>
                Cost data was not found for this HSPC and/or Market.{' '}
                <a href={mailToLink}>Please contact the HSPA Support Team.</a>
            </div>
        </Alert>
    );
}

export default CostErrorPanel;
