import './editClasses.css';

import React, { CSSProperties } from 'react';
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';

import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Cell, flexRender, Table } from '@tanstack/react-table';

import ExpandContent from './ExpandContent';

export type BodyProps = {
    table: Table<unknown>;
    loading?: boolean;
    expandContent?: ExpandContent;
    rowClasses?: (row: unknown) => string | string;
    rowStyles?: (row: unknown) => CSSProperties;
};

/**
 * The table body component.
 *
 * @param props - The table properties.
 * @param props.table - The table object.
 * @param props.expandContent - The custom expanded content.
 * @param props.loading - Whether or not the data is loading.
 * @param props.rowClasses - The row classes that can to be applied to the row.
 * @param props.rowStyles - The row styles that can be applied to the row.
 * @returns - The table body.
 */
const TableBody = ({ table, expandContent, loading = false, rowClasses, rowStyles }: BodyProps) => {
    const dataRows = table.getRowModel().rows;
    let colSpan = 0;

    table.getHeaderGroups().forEach((headerGroup) =>
        headerGroup.headers.forEach((header) => {
            colSpan += header.colSpan;
        })
    );

    const noDataRow = (
        <tr key="no-data-row">
            <td key="no-data" colSpan={colSpan} className="text-center">
                No data available
            </td>
        </tr>
    );

    const loadingRow = (
        <tr key="loading-row">
            <td key="loading" colSpan={colSpan}>
                <ProgressBar animated now={100} label="Fetching Table Data" />
            </td>
        </tr>
    );

    /**
     * Renders the Cell element of the table.
     *
     * @param cell - The cell to render.
     * @returns - The rendered cell.
     */
    const renderCell = (cell: Cell<unknown, unknown>): JSX.Element => {
        if (cell.getIsGrouped() === true) {
            return (
                <Row>
                    <Col xs={1}>
                        <Button
                            size="sm"
                            variant="light"
                            onClick={cell.row.getToggleExpandedHandler()}
                        >
                            <FontAwesomeIcon
                                icon={
                                    cell.row.getIsExpanded() === true ? faCaretDown : faCaretRight
                                }
                            />
                        </Button>
                    </Col>
                    <Col>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Col>
                </Row>
            );
        }

        if (cell.getIsAggregated() === true) {
            return (
                <>
                    {flexRender(
                        cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                        cell.getContext()
                    )}
                </>
            );
        }

        return <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>;
    };

    const renderTableData = (
        cell: Cell<unknown, unknown>,
        rowIndex: number,
        columnIndex: number
    ): JSX.Element => {
        let cellClassName = '';
        if (cell.column.columnDef.meta?.cellClassName) {
            if (typeof cell.column.columnDef.meta.cellClassName === 'string') {
                cellClassName = cell.column.columnDef.meta.cellClassName;
            } else if (typeof cell.column.columnDef.meta.cellClassName === 'function') {
                cellClassName = cell.column.columnDef.meta.cellClassName(
                    cell.getValue(),
                    cell.row.original,
                    rowIndex,
                    columnIndex
                );
            }
        }

        if (cell.column.columnDef.meta?.editable === true) {
            cellClassName += ' editable-cell';
        }

        cellClassName = cellClassName.trim();

        let cellStyle: CSSProperties = {};
        if (cell.column.columnDef.meta?.cellStyle) {
            if (typeof cell.column.columnDef.meta.cellStyle === 'function') {
                cellStyle = cell.column.columnDef.meta.cellStyle(
                    cell.getValue(),
                    cell.row.original,
                    rowIndex,
                    columnIndex
                );
            } else if (typeof cell.column.columnDef.meta.cellStyle === 'object') {
                cellStyle = cell.column.columnDef.meta.cellStyle;
            }
        }

        return (
            <td key={cell.id} tabIndex={columnIndex} className={cellClassName} style={cellStyle}>
                {renderCell(cell)}
            </td>
        );
    };

    if (loading === true) {
        return <tbody key="react-data-table-body">{loadingRow}</tbody>;
    }

    if (dataRows.length <= 0) {
        return <tbody key="react-data-table-body">{noDataRow}</tbody>;
    }

    return (
        <tbody key="react-data-table-body">
            {dataRows.map((row, rowIndex) => {
                let rowClass = '';
                if (rowClasses) {
                    if (typeof rowClasses === 'string') {
                        rowClass = rowClasses;
                    } else if (typeof rowClasses === 'function') {
                        rowClass = rowClasses(row.original);
                    }
                }

                return (
                    <>
                        <tr
                            key={row.id}
                            className={rowClass}
                            style={rowStyles ? rowStyles(row.original) : undefined}
                        >
                            {row
                                .getVisibleCells()
                                .map((cell, columnIndex) =>
                                    renderTableData(cell, rowIndex, columnIndex)
                                )}
                        </tr>
                        {expandContent?.renderer &&
                        (!expandContent?.canRender || expandContent.canRender(row.original)) ? (
                            <tr
                                key={`custom-${row.id}`}
                                className="collapse multi-collapse"
                                id={`custom-${row.id}`}
                            >
                                <td key={`custom-${row.id}-expand`} colSpan={colSpan}>
                                    {expandContent.renderer(row.original, rowIndex)}
                                </td>
                            </tr>
                        ) : null}
                    </>
                );
            })}
        </tbody>
    );
};

export default TableBody;
