import { getJSON } from 'Utilities/apiCalls';

if (typeof process.env.REACT_APP_HSPA_API === 'undefined') {
    throw new Error('REACT_APP_HSPA_API environment variable should be defined.');
}

const UNDERSTAND_DEMAND_API_URL = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand`;

type HSPC = {
    MpcType: 'Inpatient' | 'Outpatient' | 'Primary';
    HspcGroup: string;
    Hspc: string;
};

/**
 * Request all HSPCs that can be used within HSPA.
 *
 * @returns An array of all HSPCs available to use within HSPA.
 */
export const getAllHSPCs = async (): Promise<HSPC[]> => {
    const hspcs = await getJSON(`${UNDERSTAND_DEMAND_API_URL}/GetAllGapMpcTypes`);
    return hspcs as HSPC[];
};

export default { getAllHSPCs };
