import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserProfile, isAdministrator, isVISNPlanner } from 'Modules/UserSession/selector';

import useUserGuideMenuItemConfig from './useUserGuideMenuItemConfig';

/**
 * A React hook to generate the site action menu item configuration.  This determines the options to
 * display to the current user, depending on their permissions.  Items with null labels should be
 * interpreted as a divider between menu sections.
 *
 * @returns site action menu option configuration settings
 */
const useApplicationMenuItemConfig = () => {
    const dispatch = useDispatch();
    const isAdmin = useSelector(isAdministrator);
    const isPlanner = useSelector(isVISNPlanner);
    const userProfile = useSelector(getUserProfile);
    const helpItem = useUserGuideMenuItemConfig();

    return useMemo(() => {
        const menuItems = [
            { key: 'my-account', label: 'My Account', onClick: dispatch.UserAccountModel.toggle },
            { key: 'changelog', label: 'Changelog', onClick: dispatch.ChangelogModel.toggle },
            { key: 'faqs', label: 'FAQs', onClick: dispatch.FaqModel.toggle },
            {
                key: 'ask-a-question',
                label: 'Ask-a-Question',
                /**
                 * Open a dialog to submit feedback using the user's name, or no name if the user
                 * info has not been loaded.
                 */
                onClick: () => {
                    if (userProfile) {
                        const { FirstName, LastName, UserEmail } = userProfile;
                        dispatch.AskAQuestionModel.toggle(`${FirstName} ${LastName}`, UserEmail);
                    } else {
                        dispatch.AskAQuestionModel.toggle(null, null);
                    }
                }
            },
            {
                key: 'administration-divider',
                label: null
            },
            {
                key: 'administration',
                label: 'Administration',
                hidden: !(isAdmin || isPlanner),
                onClick: dispatch.AdministrationModel.toggle
            },
            {
                key: 'manage-users',
                label: 'Manage Users',
                hidden: !(isAdmin || isPlanner),
                onClick: dispatch.ManageUserModel.toggle
            },
            { key: 'tutorials', label: 'Tutorials', onClick: dispatch.UserTrainingModel.toggle }
        ];

        if (helpItem) {
            menuItems.push(helpItem);
        }

        return menuItems;
    }, [dispatch, isAdmin, isPlanner, userProfile, helpItem]);
};

export default useApplicationMenuItemConfig;
