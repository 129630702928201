/**
 * Displays distance eligible information.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getDistanceEligibleSelector } from 'Modules/EnvironmentalScan/Services/selector';
import { getCurrentMarketName, getDefaultYearObject } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import DistanceEligibleColumnDefs from './TableOutline';

/**
 * Displays distance eligible information for veterans in a market.
 */
class ESDistanceEligible extends React.Component {
    static propTypes = {
        /** The name of the current market. */
        marketName: PropTypes.string,
        /** The formatted default year collection. */
        defaultYear: PropTypes.shape({}),
        /** The distance eligible data structure. */
        distanceEligible: PropTypes.shape({
            /** The list of all distance eligible numbers by county. */
            list: PropTypes.arrayOf(PropTypes.shape({})),
            /** Is the distance eligible data currently being loaded? */
            loading: PropTypes.bool
        }),
        /** Called on mount to request the distance eligible data. */
        distanceEligibleFetch: PropTypes.func
    };

    static defaultProps = {
        marketName: undefined,
        defaultYear: {},
        distanceEligible: { list: [], loading: false },
        distanceEligibleFetch: () => {}
    };

    /**
     * Called when the component is first mounted.  Ensures that requests are made for data used by
     * this component.
     */
    componentDidMount() {
        const { marketName, distanceEligible, distanceEligibleFetch } = this.props;

        if (marketName && distanceEligible.list.length === 0) {
            distanceEligibleFetch(marketName);
        }
    }

    /**
     * When the component is updated, check if the VISN has changed.  If so, submit a request for
     * the users in the VISN.
     *
     * @param {object} previousProps - the props from the previous update
     */
    componentDidUpdate(previousProps) {
        const { marketName, distanceEligibleFetch } = this.props;
        const { marketName: prevMarketName } = previousProps;

        if (prevMarketName !== marketName) {
            distanceEligibleFetch(marketName);
        }
    }

    /**
     * Render the Distance Eligible component, displaying two tables containing enrolled veterans
     * and veterans that are eligible to enroll.
     *
     * @returns {React.ReactNode} the rendered component
     */
    render() {
        const { marketName, defaultYear, distanceEligible } = this.props;

        return (
            <div>
                <h4 className="text-primary">
                    <b>Enrolled Veterans</b>
                </h4>
                <ReactDataTable
                    keyValue="Id"
                    columns={DistanceEligibleColumnDefs(defaultYear)}
                    list={_filter(distanceEligible.list, { EligibleEnrollFlag: 0 })}
                    loading={distanceEligible.loading}
                    csvFileName={`${marketName}-EnrolledVeterans.csv`}
                />
                <h4 className="text-primary">
                    <b>Eligible to Enroll Veterans</b>
                </h4>
                <ReactDataTable
                    keyValue="Id"
                    columns={DistanceEligibleColumnDefs(defaultYear)}
                    list={_filter(distanceEligible.list, { EligibleEnrollFlag: 1 })}
                    loading={distanceEligible.loading}
                    csvFileName={`${marketName}-EligibleToEnrollVeterans.csv`}
                />
            </div>
        );
    }
}

/**
 * Map state values to props for this component.
 *
 * @param {object} state - application redux state
 * @returns {object} an object whose properties will be passed as props to the component
 */
const mapStateToProps = (state) => ({
    distanceEligible: getDistanceEligibleSelector(state),
    marketName: getCurrentMarketName(state),
    defaultYear: getDefaultYearObject(state)
});

/**
 * Map dispatch to function props the component can call to update the redux state.
 *
 * @param {Function} dispatch - a function to dispatch actions to the redux state
 * @returns {object} an object whose function properties will be passed as props to the component
 */
const mapDispatchToProps = (dispatch) => ({
    distanceEligibleFetch: (market) => dispatch.FortyMileVeteransModel.fetchDataAsync(market)
});

export default connect(mapStateToProps, mapDispatchToProps)(ESDistanceEligible);
