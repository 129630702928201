import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

/**
 * A button that links to a separate view in the application.
 *
 * @author Brandan D. Reed
 */
import PropTypes from 'prop-types';

/**
 * This component wraps a react-bootstrap Button with a react-router Link.
 *
 * @param {object} props - props passed into this component
 * @param {string|object|Function} props.to - the location the link goes to
 * @param {boolean} props.replace - when true, clicking the button will replace the current entry in
 * the history
 * @returns {React.ReactNode} the rendered link button component
 */
const LinkButton = ({ to, replace, ...buttonProps }) => (
    <Link to={to} replace={replace}>
        <Button {...buttonProps} />
    </Link>
);

LinkButton.propTypes = {
    /** The location the link goes to, string, object, or function. */
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            /** The path to link to. */
            pathname: PropTypes.string,
            /** Query parameters. */
            search: PropTypes.string,
            /** Hash to put in the URL. */
            hash: PropTypes.string,
            /** State to persist the location. */
            state: PropTypes.string
        }),
        PropTypes.func
    ]),
    /** When true, clicking the button will replace the current entry in the history stack instead
     * of adding a new one. */
    replace: PropTypes.bool,
    /** Bootstrap button variant styles. */
    variant: PropTypes.string,
    /** CSS style object. */
    style: PropTypes.shape({}),
    /** Child elements to render within the button. */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

LinkButton.defaultProps = {
    to: '',
    replace: false,
    variant: undefined,
    style: undefined,
    children: undefined
};

export default LinkButton;
