import { commaFormatter, commaNAFormatter, percentFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const renderProjectedColumns = (year, columnPrefix) =>
    columnHelper.group({
        id: columnPrefix,
        header: `${year} Projected`,
        enableSorting: false,
        meta: {
            headerDisplayName: `${year} Projected`,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            cellStyle: { whiteSpace: 'normal' },
            csvExportIgnore: true
        },
        columns: [
            {
                accessorKey: `${columnPrefix}Modeled`,
                header: 'Modeled',
                meta: {
                    headerDisplayName: `${year} Modeled`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                accessorKey: `${columnPrefix}Change`,
                header: 'Change',
                meta: {
                    headerDisplayName: `${year} Change`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaFormatter
                }
            },
            {
                accessorKey: `${columnPrefix}PercentChangeStr`,
                header: '% Change',
                meta: {
                    headerDisplayName: `${year} % Change`,
                    columnClassName: 'text-right',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: percentFormatter
                }
            }
        ]
    });

const PatientOriginColumns = (MPC, defaultYear) => {
    if (!defaultYear || defaultYear.defaultYear === '') {
        return [];
    }

    return [
        columnHelper.group({
            id: 'TopHeader',
            header: MPC,
            enableSorting: false,
            meta: {
                headerDisplayName: MPC,
                csvExportIgnore: true,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap', fontSize: 15 },
                cellClassName: 'text-center'
            },
            columns: [
                {
                    accessorKey: 'HspcGroup',
                    header: 'HSPCG',
                    meta: {
                        headerDisplayName: 'HSPC Group',
                        columnClassName: 'text-center',
                        columnStyle: { whiteSpace: 'pre-wrap' },
                        cellClassName: 'text-center',
                        cellStyle: { whiteSpace: 'normal' }
                    }
                },
                {
                    accessorKey: 'EnrolleeMarket',
                    header: 'Market of Patient Origin',
                    meta: {
                        headerDisplayName: 'Market of Patient Origin',
                        columnClassName: 'text-center',
                        columnStyle: { whiteSpace: 'pre-wrap' },
                        cellClassName: 'text-center',
                        cellStyle: { whiteSpace: 'normal' }
                    }
                },
                {
                    accessorKey: 'Baseline',
                    header: `${defaultYear.defaultYearWithFiscalString} Baseline`,
                    meta: {
                        headerDisplayName: `${defaultYear.defaultYearWithFiscalString} Baseline`,
                        columnClassName: 'text-center',
                        columnStyle: { whiteSpace: 'pre-wrap' },
                        cellClassName: 'text-center',
                        cellStyle: { whiteSpace: 'normal' },
                        formatter: commaNAFormatter
                    }
                },
                renderProjectedColumns(defaultYear.fiveYearsOfDefault, 'FiveYear'),
                renderProjectedColumns(defaultYear.tenYearsOfDefault, 'TenYear'),
                renderProjectedColumns(defaultYear.twentyYearsOfDefault, 'TwentyYear')
            ]
        })
    ];
};

export default PatientOriginColumns;
