import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const strategicOutlookApi = `${process.env.REACT_APP_HSPA_API}/StrategicOutlook/`;

export const MetricProjectionsModel = {
    state: {
        list: [],
        loading: false
    },
    reducers: {
        populate: (state, data) => ({ ...state, list: data }),
        updateLoading: (state, loading) => ({ ...state, loading: loading }),
        reset: (state) => ({ list: [], loading: false })
    },
    effects: (dispatch) => ({
        async fetchMetricProjectionsByMarketAsync(market, state) {
            try {
                this.updateLoading(true);
                let result = await getJSON(
                    `${strategicOutlookApi}GetMetricsByMarket?market=${market}`
                );
                this.populate(result);
                this.updateLoading(false);
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch strategic outlook projections',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMetricProjectionsByVisnAsync(visn, state) {
            try {
                this.updateLoading(true);
                let result = await getJSON(`${strategicOutlookApi}GetMetricsByVisn?visn=${visn}`);
                this.populate(result);
                this.updateLoading(false);
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch strategic outlook projections',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
