/**
 * Form for configuring output of Understand Demand module data for reports.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { reduxForm, getFormValues, Form } from 'redux-form';
import MultiSelectField from 'Lib/ReduxFormFields/MultiSelectField';
import CheckboxField from 'Lib/ReduxFormFields/CheckboxField';
import { canEditVISN } from 'Modules/UserSession/selector';
import FormButtonGroup from '../RWFButtonGroup';

class RWFUnderstandDemand extends React.Component {
    static propTypes = {
        formValues: PropTypes.shape({
            VisnCode: PropTypes.string,
            ReportId: PropTypes.number,
            ChkProjectedDemandMarketUtil: PropTypes.bool,
            ChkProjectedDemandFacilityUtil: PropTypes.bool,
            ChkProjectedDemandMarketRvu: PropTypes.bool,
            ChkProjectedDemandFacilityRvu: PropTypes.bool,
            ChkFutureMarketHspgUtil: PropTypes.bool,
            ChkFutureMarketHspcUtil: PropTypes.bool,
            ChkFutureMarketHspgRvu: PropTypes.bool,
            ChkFutureMarketHspcRvu: PropTypes.bool,
            ChkMatrixBase: PropTypes.bool,
            ChkMatrixTen: PropTypes.bool,
            ChkMatrixTwenty: PropTypes.bool
        }).isRequired,
        change: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        report: PropTypes.shape({
            patientOriginToolConfigs: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({})),
                loading: PropTypes.bool
            }),
            facilitiesWaitTimeRetrieval: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({})),
                loading: PropTypes.bool
            }),
            analysisToolConfigs: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({})),
                loading: PropTypes.bool
            })
        }).isRequired,
        submitting: PropTypes.bool,
        saving: PropTypes.bool,
        generating: PropTypes.bool,
        loading: PropTypes.bool,
        invalid: PropTypes.bool,
        pristine: PropTypes.bool,
        previousPage: PropTypes.func.isRequired,
        currentPage: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        generateReportById: PropTypes.func.isRequired,
        generateReportByFormData: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        submitting: false,
        saving: false,
        generating: false,
        loading: false,
        invalid: false,
        pristine: true,
        canEdit: false
    };

    formGenerate = () => {
        const { formValues, generateReportById, generateReportByFormData } = this.props;

        if (formValues.ReportId) {
            generateReportById(formValues.ReportId);
        } else {
            generateReportByFormData(formValues);
        }
    };

    selectAllCheck = () => {
        const { formValues, change } = this.props;

        change(
            'ChkProjectedDemandMarketUtil',
            !formValues || !formValues.ChkProjectedDemandMarketUtil
                ? true
                : !formValues.ChkProjectedDemandMarketUtil
        );
        change(
            'ChkProjectedDemandFacilityUtil',
            !formValues || !formValues.ChkProjectedDemandFacilityUtil
                ? true
                : !formValues.ChkProjectedDemandFacilityUtil
        );
        change(
            'ChkProjectedDemandMarketRvu',
            !formValues || !formValues.ChkProjectedDemandMarketRvu
                ? true
                : !formValues.ChkProjectedDemandMarketRvu
        );
        change(
            'ChkProjectedDemandFacilityRvu',
            !formValues || !formValues.ChkProjectedDemandFacilityRvu
                ? true
                : !formValues.ChkProjectedDemandFacilityRvu
        );
        change(
            'ChkFutureMarketHspgUtil',
            !formValues || !formValues.ChkFutureMarketHspgUtil
                ? true
                : !formValues.ChkFutureMarketHspgUtil
        );
        change(
            'ChkFutureMarketHspcUtil',
            !formValues || !formValues.ChkFutureMarketHspcUtil
                ? true
                : !formValues.ChkFutureMarketHspcUtil
        );
        change(
            'ChkFutureMarketHspgRvu',
            !formValues || !formValues.ChkFutureMarketHspgRvu
                ? true
                : !formValues.ChkFutureMarketHspgRvu
        );
        change(
            'ChkFutureMarketHspcRvu',
            !formValues || !formValues.ChkFutureMarketHspcRvu
                ? true
                : !formValues.ChkFutureMarketHspcRvu
        );
        change(
            'ChkMatrixBase',
            !formValues || !formValues.ChkMatrixBase ? true : !formValues.ChkMatrixBase
        );
        change(
            'ChkMatrixTen',
            !formValues || !formValues.ChkMatrixTen ? true : !formValues.ChkMatrixTen
        );
        change(
            'ChkMatrixTwenty',
            !formValues || !formValues.ChkMatrixTwenty ? true : !formValues.ChkMatrixTwenty
        );
    };

    render() {
        const {
            submitting,
            saving,
            generating,
            report,
            loading,
            invalid,
            pristine,
            currentPage,
            reset,
            canEdit,
            previousPage,
            handleSubmit
        } = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckboxField
                                name="udSelectAll"
                                label="Select All"
                                onChangeFunction={this.selectAllCheck}
                            />
                        </div>
                        <div className="col-sm-6">
                            <div>
                                <div className="card">
                                    <h5 className="card-header card-info">
                                        Review Projected Demand
                                    </h5>
                                    <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                        Content Type: Table
                                    </h6>
                                    <div className="card-body">
                                        <CheckboxField
                                            name="ChkProjectedDemandMarketUtil"
                                            label="Projected Utilization Demand - Market"
                                        />
                                        <CheckboxField
                                            name="ChkProjectedDemandFacilityUtil"
                                            label="Projected Utilization Demand - Facility"
                                        />
                                        <CheckboxField
                                            name="ChkProjectedDemandMarketRvu"
                                            label="Projected RVU Demand - Market"
                                        />
                                        <CheckboxField
                                            name="ChkProjectedDemandFacilityRvu"
                                            label="Projected RVU Demand - Facility"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <h5 className="card-header card-info">
                                        VISN Workload Allocation
                                    </h5>
                                    <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                        Content Type: Table
                                    </h6>
                                    <div className="card-body">
                                        <CheckboxField
                                            name="ChkMatrixBase"
                                            label="Baseline"
                                            type="checkbox"
                                        />
                                        <CheckboxField
                                            name="ChkMatrixTen"
                                            label="Ten-Year Allocated"
                                            type="checkbox"
                                        />
                                        <CheckboxField
                                            name="ChkMatrixTwenty"
                                            label="Twenty-Year Allocated"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <h5 className="card-header card-info">Patient Origin Tool</h5>
                                    <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                        Content Type: Table
                                    </h6>
                                    <div className="card-body" style={{ marginBottom: -15 }}>
                                        <MultiSelectField
                                            name="PotConfigs"
                                            label="Select Patient Origin Tool Configuration"
                                            options={report.patientOriginToolConfigs.list}
                                            valueField="ReportText"
                                            delimiter={'{;}'}
                                            labelPosition={12}
                                            inputPosition={12}
                                            loading={report.patientOriginToolConfigs.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div>
                                <div className="card">
                                    <h5 className="card-header card-info">Future Market Trends</h5>
                                    <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                        Content Type: Table
                                    </h6>
                                    <div className="card-body">
                                        <CheckboxField
                                            name="ChkFutureMarketHspgUtil"
                                            label="Future Market Trends - HSPG - Utilization"
                                            type="checkbox"
                                        />
                                        <CheckboxField
                                            name="ChkFutureMarketHspcUtil"
                                            label="Future Market Trends - HSPC - Utilization"
                                            type="checkbox"
                                        />
                                        <CheckboxField
                                            name="ChkFutureMarketHspgRvu"
                                            label="Future Market Trends - HSPG - RVU"
                                            type="checkbox"
                                        />
                                        <CheckboxField
                                            name="ChkFutureMarketHspcRvu"
                                            label="Future Market Trends - HSPC - RVU"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <h5 className="card-header card-info">Wait Times</h5>
                                    <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                        Content Type: Table
                                    </h6>
                                    <div className="card-body" style={{ marginBottom: -15 }}>
                                        <MultiSelectField
                                            name="WaitTimeRetrievals"
                                            label="Select the facilities for wait time retrieval"
                                            options={report.facilitiesWaitTimeRetrieval.list}
                                            valueField="Facility"
                                            delimiter={'{;}'}
                                            labelPosition={12}
                                            inputPosition={12}
                                            loading={report.facilitiesWaitTimeRetrieval.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <h5 className="card-header card-info">Analysis Tools</h5>
                                    <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                        Content Type: Table
                                    </h6>
                                    <div className="card-body" style={{ marginBottom: -15 }}>
                                        <MultiSelectField
                                            name="AtConfigs"
                                            label="Select Analysis Tool Configuration"
                                            options={report.analysisToolConfigs.list}
                                            valueField="ReportText"
                                            delimiter={'{;}'}
                                            labelPosition={12}
                                            inputPosition={12}
                                            loading={report.analysisToolConfigs.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="col-sm-12" style={{ paddingTop: 15 }}>
                        <FormButtonGroup
                            formGenerate={this.formGenerate}
                            previousPage={previousPage}
                            submitting={submitting}
                            saving={saving}
                            generating={generating}
                            loading={loading}
                            invalid={invalid}
                            pristine={pristine}
                            reset={reset}
                            isFirstPage={currentPage === 0}
                            isLastPage={currentPage === 4}
                            canEdit={canEdit}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

const ReduxFormRWFUnderstandDemand = reduxForm({
    form: 'ReportForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.ReportName) {
            errors.ReportName = 'Report name is required';
        }
        if (!values.VisnCode) {
            errors.VisnCode = 'Visn is required';
        }
        if (!values.MarketName) {
            errors.MarketName = 'Market is required';
        }
        return errors;
    }
})(RWFUnderstandDemand);

const mapDispatchToProps = (dispatch) => {
    return {
        getReportDropdowns: (marketName, visnCode) =>
            dispatch.ReportFormModel.fetchDropdownOptionsAsync({
                marketName,
                visnCode
            }),
        clearReportForm: () => dispatch.ReportFormModel.resetTable('ReportForm'),
        generateReportById: (reportId) =>
            dispatch.ReportFormModel.generateReportByIdAsync(reportId),
        generateReportByFormData: (formValues) =>
            dispatch.ReportFormModel.generateReportByFormDataAsync(formValues)
    };
};

const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ReportFormModel.ReportForm.data) ? 'create' : 'edit',
    formValues: getFormValues('ReportForm')(state),
    initialValues: state.ReportFormModel.ReportForm.data || {},
    saving: state.ReportFormModel.ReportForm.saving,
    loading: state.ReportFormModel.ReportForm.loading,

    generating: state.ReportFormModel.ReportForm.generating,
    report: state.ReportFormModel,
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormRWFUnderstandDemand);
