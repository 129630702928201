/**
 * View all system users.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';

import AllSystemUsersColumnDefs from './TableOutline';

class AllSystemUsers extends React.Component {
    componentDidMount() {
        if (this.props.allSystemUsers.list.length === 0) this.props.allSystemUsersFetchList();
    }

    render() {
        return (
            <div>
                <p>
                    <strong>All system users have read-only access to each VISN.</strong>
                </p>
                <div>
                    <ReactDataTable
                        keyValue="FirstName"
                        list={this.props.allSystemUsers.list}
                        loading={this.props.allSystemUsers.loading}
                        columns={AllSystemUsersColumnDefs}
                        csvFileName={'AllHSPAUsers.csv'}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    allSystemUsersFetchList: () => dispatch.ManageUserModel.fetchAllSystemUsersAsync()
});

const mapStateToProps = (state) => ({
    allSystemUsers: state.ManageUserModel.allSystemUsers,
    user: state.UserSessionModel
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSystemUsers);
