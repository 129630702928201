import './style.css';

import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { REACT_APP_APPLICATION_ROOT_ROUTE } from 'Utilities/constants';

import HSPAApplicationMenu from './HSPAApplicationMenu';

/**
 * Display the navbar for HSPA.
 *
 * @returns the rendered component.
 */
const HSPANavbar = (): JSX.Element => (
    <Navbar className="navbar-hspa" variant="dark" expand="lg">
        <Navbar.Brand as={NavLink} to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/`}>
            HSPA
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
            <Nav className="mr-auto">
                <Nav.Link as={NavLink} to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/StrategicOutlook`}>
                    Strategic Outlook
                </Nav.Link>
                <Nav.Link
                    as={NavLink}
                    to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/EnvironmentalScan`}
                >
                    Environmental Scan
                </Nav.Link>
                <Nav.Link as={NavLink} to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/UnderstandDemand`}>
                    Understand Demand
                </Nav.Link>
                <Nav.Link
                    as={NavLink}
                    to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/UnderstandCapacity`}
                >
                    Understand Capacity
                </Nav.Link>
                <Nav.Link as={NavLink} to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/Match`}>
                    Match Capacity/Demand
                </Nav.Link>
                <Nav.Link as={NavLink} to={`/${REACT_APP_APPLICATION_ROOT_ROUTE}/Reports`}>
                    Report
                </Nav.Link>
            </Nav>
            <HSPAApplicationMenu />
        </Navbar.Collapse>
    </Navbar>
);

export default HSPANavbar;
