import { HSPAState } from 'storeTypes';

import { createSelector } from '@reduxjs/toolkit';

/**
 * Get the opportunities slice of state from the HSPA state.
 *
 * @param state - the HSPA redux state
 * @returns the opportunities slice of state
 */
const selectOpportunitiesState = (state: HSPAState) => state.opportunities;

/**
 * Get the list of opportunities from the HSPA state.
 *
 * @param state - the HSPA redux state
 * @returns the list of opportunities
 */
export const selectOpportunities = createSelector(
    selectOpportunitiesState,
    (opportunitiesState) => opportunitiesState?.items || []
);

/**
 * Get the opportunities loading status from the HSPA state.
 *
 * @param state - the HSPA redux state
 * @returns the loading status
 */
export const selectOpportunitiesLoadingStatus = createSelector(
    selectOpportunitiesState,
    (opportunitiesState) => opportunitiesState?.loadingStatus
);

/**
 * Get the opportunities market from the HSPA state.
 *
 * @param state - the HSPA redux state
 * @returns the market associated with the currently loaded opportunities
 */
export const selectOpportunitiesMarket = createSelector(
    selectOpportunitiesState,
    (opportunitiesState) => opportunitiesState?.market
);
