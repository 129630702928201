import _map from 'lodash/map';

import { getOpportunitiesByMarket } from 'Data/opportunities';
import epicRequest from 'Utilities/epicRequest';

import { from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    loadOpportunities as loadOpportunitiesAction,
    loadOpportunitiesDone,
    loadOpportunitiesError,
    loadOpportunitiesStart,
    setOpportunities
} from '../slice';

/**
 * An epic that listens for loadOpportunities actions and requests opportunities from the server.
 *
 * @param action$ - a stream of the redux actions dispatched to the application's redux store
 * @returns actions to set opportunities on load
 */
const loadOpportunities = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        // The match method of redux-toolkit actions do not reference `this`, so it's safe to pass
        // it as a standalone value
        filter(loadOpportunitiesAction.match),
        distinctUntilChanged((previous, current) => previous.payload === current.payload),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Request opportunities for a market and emit a setOpportunities action with the
             * opportunities response.
             *
             * @param action - the loadOpportunities action
             * @param action.payload - the market name
             * @returns an observable of redux actions
             */
            request: ({ payload }: PayloadAction<string>) =>
                from(getOpportunitiesByMarket(payload)).pipe(
                    // For now, we need to add a status for each AIR opportunity
                    map((opportunities) =>
                        _map(opportunities, (opportunity) => {
                            return opportunity;
                        })
                    ),
                    map((opportunities) => setOpportunities({ market: payload, opportunities }))
                ),
            actions: {
                start: loadOpportunitiesStart,
                done: loadOpportunitiesDone,
                fail: loadOpportunitiesError
            }
        })
    );

export default loadOpportunities;
