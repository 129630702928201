import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Dictionary } from 'lodash';

import { USER_GUIDE_PATHS } from 'Utilities/filePaths';

// TODO: Use a master module config/constants for some of these values
const moduleGuideMap: Dictionary<{ title: string; path: string }> = {
    StrategicOutlook: { title: 'Strategic Outlook', path: USER_GUIDE_PATHS.STRATEGIC_OUTLOOK },
    EnvironmentalScan: { title: 'Environmental Scan', path: USER_GUIDE_PATHS.ENVIRONMENTAL_SCAN },
    UnderstandDemand: { title: 'Understand Demand', path: USER_GUIDE_PATHS.UNDERSTAND_DEMAND },
    UnderstandCapacity: {
        title: 'Understand Capacity',
        path: USER_GUIDE_PATHS.UNDERSTAND_CAPACITY
    },
    Match: { title: 'Match', path: USER_GUIDE_PATHS.MATCH },
    Reports: { title: 'Report', path: USER_GUIDE_PATHS.REPORT }
};

/**
 * A React hook that returns the current HSPA module.
 *
 * @returns the current HSPA module based on the current route
 */
const useModuleRoute = () => {
    const location = useLocation();

    const module = location.pathname.split('/')[2];

    return module;
};

/**
 * A React hook that returns the config for the help menu entry for each module.
 *
 * @returns a callback that opens a module's User Guide in a new window
 */
const useUserGuideMenuItemConfig = (): { key: string; label: string; onClick: () => void } => {
    const moduleRoute = useModuleRoute();

    const helpOption = moduleGuideMap[moduleRoute];

    const onClick = useCallback(() => {
        window.open(helpOption.path);
    }, [helpOption]);

    return useMemo(
        () =>
            helpOption && {
                key: 'help',
                label: `${helpOption.title} Help`,
                onClick
            },
        [helpOption, onClick]
    );
};

export default useUserGuideMenuItemConfig;
