import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Facilities from './Facilities';
import FacilityReport from './FacilityReport';

/**
 * React component that displays a table of facilities for the current market.  Selecting one will
 * show the facility report for that facility.
 *
 * @returns the rendered component
 */
const FacilityReportManagement = () => {
    const { path } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${path}/:stationNumber`} component={FacilityReport} />
                <Route component={Facilities} />
            </Switch>
        </>
    );
};

export default FacilityReportManagement;
