import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components/macro';

/**
 * Container component to handle tabs.
 *
 * @author Brandan D. Reed, Anthony P. Pancerella
 */
import HeaderContainer from 'Modules/App/HeaderContainer';
import analytics from 'Utilities/analytics';

import MCDPlannerWorkbench from '../MCDPlannerWorkbench';
import PlanManagement from '../PlanManagement';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    flex-grow: 1;
`;

/**
 * The Match module container.  This renders the selection of plans and the planner workbench.
 *
 * @returns {React.Element} the Match module component
 */
const MCDContainer: React.FC = () => {
    const { path } = useRouteMatch();

    useEffect(() => {
        document.title = 'HSPA - Match Capacity/Demand';
        const analyticsObj = analytics.getInstance();
        analyticsObj.trackPageChange({ pathName: 'Match' });
        analyticsObj.trackTabChange('Plans');
    }, []);

    return (
        <Container>
            <HeaderContainer pageName="Match Capacity/Demand" />
            <Content>
                <Switch>
                    <Route path={`${path}/:id`}>
                        <MCDPlannerWorkbench />
                    </Route>
                    <Route>
                        <PlanManagement />
                    </Route>
                </Switch>
            </Content>
        </Container>
    );
};

export default MCDContainer;
