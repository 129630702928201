import React from 'react';
import StateCard from './StateCard';
import './style.css';

type StateGroupContainerProps = {
    states: string[]
};

const url = "https://dvagov.sharepoint.com/sites/VHAChiefStrategyOffice/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FVHAChiefStrategyOffice%2FShared%20Documents%2FState%20Policy%20Profiles%2FState%20Policy%20Profile%5F{stateName}%2Epdf&parent=%2Fsites%2FVHAChiefStrategyOffice%2FShared%20Documents%2FState%20Policy%20Profiles"
const StateGroupContainer = ({states}: StateGroupContainerProps): JSX.Element => {
    const stateCards = states.map((state, index) =>{
        const stateUrl = url.replace("{stateName}", state.replace(/ /g, "%20"));
        
        return (
            <StateCard id={index} image={window.location.origin + "/states/" + state + ".gif"} name={state} url={stateUrl} />
        )
    });
    return (
        <div className="stateGroupContainer">
            {stateCards}
        </div>
    );
};

export default StateGroupContainer;