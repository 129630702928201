import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components/macro';

import AsyncContent from 'Lib/AsyncContent';
import ErrorAlert from 'Lib/ErrorAlert';
import {
    selectOpportunities,
    selectOpportunitiesLoadingStatus
} from 'Modules/StrategicOutlook/Services/opportunities/selectors';
import { getCurrentMarket } from 'Modules/UserSession/selector';
import { AsyncState } from 'Utilities/constants';

import { loadOpportunities } from '../../Services/opportunities';
import OpportunityTable from './OpportunityTable';

const CardHeader = styled.div`
    font-size: 16pt;
    margin-bottom: 10px;
`;

/**
 * A card component containing a table of MAHSO opportunities.
 *
 * @returns a card with a table of MAHSO opportunities
 */
const OpportunityTableCard = (): JSX.Element => {
    const dispatch = useDispatch();
    const market = useSelector(getCurrentMarket);
    const recommendations = useSelector(selectOpportunities);
    const loadingStatus = useSelector(selectOpportunitiesLoadingStatus);
    const actionDescription = 'retrieving the recommendations';

    useEffect(() => {
        dispatch(loadOpportunities(market.MarketId));
    }, [dispatch, market.MarketId]);

    return (
        <div style={{ position: 'relative', minHeight: '500px' }}>
            <AsyncContent
                isLoading={loadingStatus === AsyncState.PENDING}
                loadingLabel="Please wait, recommendations are loading..."
                loadedLabel="Recommendations have loaded"
            >
                <Fade in={loadingStatus !== AsyncState.PENDING} unmountOnExit>
                    <Card>
                        {loadingStatus === AsyncState.FAILURE ? (
                            <div className="m-4">
                                <CardHeader>Market Assessment Recommendations</CardHeader>
                                <ErrorAlert actionDescription={actionDescription} />
                            </div>
                        ) : (
                            <OpportunityTable recommendations={recommendations} />
                        )}
                    </Card>
                </Fade>
            </AsyncContent>
        </div>
    );
};
export default OpportunityTableCard;
