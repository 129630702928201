import React from 'react';

import Button from 'Lib/Button';

const AllProvidersColumnDefs = (editProvider, deleteProvider, selectOptions, canEdit) => {
    return [
        {
            accessorKey: 'Id',
            header: 'id',
            hidden: true,
            meta: {
                headerDisplayName: 'Id',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'Name',
            header: 'Facility Name',
            meta: {
                headerDisplayName: 'Facility Name',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'FacilityTypeString',
            header: 'Facility Type',
            enableColumnFilter: true,
            meta: {
                headerDisplayName: 'Facility Type',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap', width: '250px' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'ProviderType',
            header: 'Provider Type',
            meta: {
                headerDisplayName: 'Provider Type',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'DisplayCurrentRelationship',
            header: 'Is Provider Partner?',
            meta: {
                headerDisplayName: 'Is Provider Partner?',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'SPCListString',
            header: 'Associated HSPCs',
            meta: {
                headerDisplayName: 'Associated HSPCs',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center'
            }
        },
        {
            id: '1',
            header: 'Edit',
            enableSorting: false,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                csvExportIgnore: true
            },
            cell: ({ row }) => (<Button
                color="warning"
                type="button"
                label="Edit"
                onClick={() => editProvider(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />)
        },
        {
            id: '2',
            header: 'Delete',
            enableSorting: false,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                csvExportIgnore: true
            },
            cell: ({ row }) => (<Button
                color="danger"
                type="button"
                label="Delete"
                disabled={!canEdit}
                onClick={() => deleteProvider(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />)
        }
    ];
};

export default AllProvidersColumnDefs;
