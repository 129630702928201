import { commaFormatter, percentFormatter } from 'Utilities/formatters';

// Demographic page
// County Tabs
export const CountyMarketShareColumnDefs = (defaultYear) => [
    {
        header: 'County',
        accessorKey: 'CountyName',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Sector',
        accessorKey: 'Sector',
        meta: {
            headerDisplayName: 'Sector',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const CountyVeteranEnrollmentColumnDefs = (defaultYear) => [
    {
        accessorKey: 'CountyName',
        header: 'County',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Sector',
        header: 'Sector',
        meta: {
            headerDisplayName: 'Sector',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5ChangeString',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10ChangeString',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20ChangeString',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const CountyVeteranEligibleColumnDefs = (defaultYear) => [
    {
        accessorKey: 'CountyName',
        header: 'County',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Sector',
        header: 'Sector',
        meta: {
            headerDisplayName: 'Sector',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5ChangeString',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10ChangeString',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20ChangeString',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const CountySpecialPopulationOefColumnDefs = (defaultYear) => [
    {
        accessorKey: 'CountyName',
        header: 'County',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Sector',
        header: 'Sector',
        meta: {
            headerDisplayName: 'Sector',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5ChangeString',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10ChangeString',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20ChangeString',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const CountySpecialPopulationGenderColumnDefs = (defaultYear) => [
    {
        accessorKey: 'Gender',
        Header: 'Gender',
        meta: {
            headerDisplayName: 'Gender',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'CountyName',
        header: 'County',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Sector',
        header: 'Sector',
        meta: {
            headerDisplayName: 'Sector',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5ChangeString',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10ChangeString',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20ChangeString',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '10%' },
            cellClassName: 'text-right'
        }
    }
];

// Market Tabs
export const MarketShareTableDef1 = (defaultYear) => [
    {
        accessorKey: 'PriorityGroup',
        header: 'Priority Group',
        meta: {
            headerDisplayName: 'Priority Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketShareTableDef2 = (defaultYear) => [
    {
        accessorKey: 'AgeGroup',
        header: 'Age Group',
        meta: {
            headerDisplayName: 'Age Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0String',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5String',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10String',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20String',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketSpecialPopulationGenderColumnDefs = (defaultYear) => [
    {
        accessorKey: 'Gender',
        header: 'Gender',
        meta: {
            headerDisplayName: 'Gender',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'AgeGroup',
        header: 'Age Group',
        meta: {
            headerDisplayName: 'Age Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultFiveYear',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTenYear',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTwentyYear',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketSpecialPopulationOefColumnDefs = (defaultYear) => [
    {
        accessorKey: 'AgeGroup',
        header: 'Age Group',
        meta: {
            headerDisplayName: 'Age Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultFiveYear',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTenYear',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTwentyYear',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketVeteranEnrollmentColumnDefs1 = (defaultYear) => [
    {
        accessorKey: 'PriorityGroup',
        header: 'Priority Group',
        meta: {
            headerDisplayName: 'Priority Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultFiveYear',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTenYear',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTwentyYear',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketVeteranEnrollmentColumnDefs2 = (defaultYear) => [
    {
        accessorKey: 'AgeGroup',
        header: 'Age Group',
        meta: {
            headerDisplayName: 'Age Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultFiveYear',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTenYear',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTwentyYear',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketVeteranEligibleColumnDefs1 = (defaultYear) => [
    {
        accessorKey: 'PriorityGroup',
        header: 'Priority Group',
        meta: {
            headerDisplayName: 'Priority Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultFiveYear',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTenYear',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTwentyYear',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const MarketVeteranEligibleColumnDefs2 = (defaultYear) => [
    {
        accessorKey: 'AgeGroup',
        header: 'Age Group',
        meta: {
            headerDisplayName: 'Age Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Year0',
        header: defaultYear.defaultYearWithFiscalStringNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.defaultYearWithFiscalStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultFiveYear',
        header: defaultYear.changeDefaultFiveYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultFiveYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTenYear',
        header: defaultYear.changeDefaultTenYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTenYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
        meta: {
            formatter: commaFormatter,
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjectedNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ChangeDefaultTwentyYear',
        header: defaultYear.changeDefaultTwentyYearStringNoInHouse,
        meta: {
            formatter: percentFormatter,
            headerDisplayName: defaultYear.changeDefaultTwentyYearStringNoInHouse,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];
