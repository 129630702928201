/**
 * A table of VA Facilities for the Understand Capacity module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getFacilityListSelector } from 'Modules/UnderstandCapacity/Services/selector';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import VaFacilitiesColumnDefs from './TableOutline';

class UCVFTable extends React.Component {
    static propTypes = {
        marketName: PropTypes.string.isRequired,
        facilityList: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        fetchVaFacilitiesAsync: PropTypes.func.isRequired,
        toggleFacilityModal: PropTypes.func.isRequired,
        populateForm: PropTypes.func.isRequired,
        deleteVaFacilityAsync: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false
    };

    componentWillMount() {
        const { marketName, facilityList, fetchVaFacilitiesAsync } = this.props;

        if (marketName) {
            if (facilityList.list.length === 0) {
                fetchVaFacilitiesAsync(marketName);
            }
        }
    }

    componentWillUpdate(nextProps) {
        const { marketName, fetchVaFacilitiesAsync } = this.props;
        const { marketName: nextMarketName } = nextProps;

        if (nextMarketName !== marketName) {
            fetchVaFacilitiesAsync(nextMarketName);
        }
    }

    facilityModal = (id) => {
        const { toggleFacilityModal, populateForm } = this.props;
        toggleFacilityModal();
        populateForm(id);
    };

    render() {
        const {
            marketName,
            facilityList,
            canEdit,
            toggleFacilityModal,
            deleteVaFacilityAsync
        } = this.props;

        return (
            <div>
                <ReactDataTable
                    keyValue="Id"
                    list={facilityList.list}
                    columns={VaFacilitiesColumnDefs(
                        (id) => this.facilityModal(id),
                        (id) => deleteVaFacilityAsync(id, marketName),
                        canEdit
                    )}
                    loading={facilityList.loading}
                    csvFileName={`${marketName}-VaFacilities.csv`}
                    showCsvPptBtnGroup
                    btnRightColor="info"
                    btnRightFunc={() => toggleFacilityModal(true)}
                    btnRightLabel="Create New Facility"
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleFacilityModal: () => dispatch.VaFacilitiesModel.toggleFacilityModal(),
        fetchVaFacilitiesAsync: (market) =>
            dispatch.VaFacilitiesModel.fetchVaFacilitiesAsync(market),
        deleteVaFacilityAsync: (id, market) =>
            dispatch.VaFacilitiesModel.deleteVaFacilityAsync({ id, market }),
        populateForm: (id) => dispatch.VaFacilitiesModel.populateForm(id)
    };
};
const mapStateToProps = (state) => ({
    facilityList: getFacilityListSelector(state),
    marketName: getCurrentMarketName(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UCVFTable);
