import React from 'react';

import HighchartsExporting from 'highcharts-exporting';
import HighchartsMore from 'highcharts-more';
import { getDefaultYear } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { useSelector } from 'react-redux';

HighchartsMore(ReactHighcharts.Highcharts);
HighchartsExporting(ReactHighcharts.Highcharts);

const SoMetricChart = React.memo(
    React.forwardRef(({ data, onVisibilityChange, useYearOffset }, ref) => {
        const defaultYear = useSelector(getDefaultYear) - (useYearOffset ? 1 : 0);

        const categories = Array(12)
            .fill()
            .map((item, index) => defaultYear + index);

        const options = {
            chart: {
                type: 'line',
                height: '500',
                align: 'center'
            },
            title: {
                text: 'Multi Year Metric Projection'
            },
            xAxis: {
                categories
            },
            yAxis: {
                title: null,
                reversedStacks: false
            },
            exporting: {
                enabled: true,
                sourceHeight: '500',
                sourceWidth: '1000'
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                },
                series: {
                    events: {
                        show: onVisibilityChange,
                        hide: onVisibilityChange
                    }
                }
            },
            series: data,
            colors: [
                '#68354C',
                '#7B5E3F',
                '#2C3E50',
                '#617487',
                '#85919C',
                '#D0B090',
                '#AF7992',
                '#6B9A79',
                '#1B502B'
            ]
        };

        return <ReactHighcharts config={options} ref={ref} />;
    })
);

SoMetricChart.propTypes = {
    data: PropTypes.array,
    onVisibilityChange: PropTypes.func,
    useYearOffset: PropTypes.bool
};

export default SoMetricChart;
