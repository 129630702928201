import React from 'react';

import Button from 'Lib/Button';

export const FqhcFacilityGridColumns = (selectFacility) => {
    return [
        {
            accessorKey: 'Name',
            header: 'Facility Name',
            meta: {
                headerDisplayName: 'Facility Name',
                columnClassName: 'header-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'header-center'
            }
        },
        {
            accessorKey: 'Address',
            header: 'Address',
            meta: {
                headerDisplayName: 'Address',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'City',
            header: 'City',
            meta: {
                headerDisplayName: 'City',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'ZipCode',
            header: 'Zip Code',
            meta: {
                headerDisplayName: 'Zip Code',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: '',
            header: 'Select',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                    color="success"
                    type="button"
                    label="Select"
                    onClick={() => selectFacility(row.original)}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}
                />
            ),
            meta: {
                headerDisplayName: 'Select',
                csvExportIgnore: true,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        }
    ];
};

export const DoDFacilityGridColumns = (selectFacility) => {
    return [
        {
            accessorKey: 'FacilityName',
            header: 'Facility Name',
            meta: {
                headerDisplayName: 'Facility Name',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'FacilityCityName',
            header: 'City',
            meta: {
                headerDisplayName: 'City',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'Facility5DigitZIPCode',
            header: 'Zip Code',
            meta: {
                headerDisplayName: 'Zip Code',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: '',
            header: 'Select',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                    color="success"
                    type="button"
                    label="Select"
                    onClick={() => selectFacility(row.original)}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}
                />
            ),
            meta: {
                headerDisplayName: 'Select',
                csvExportIgnore: true,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        }
    ];
};

export const CommunityFacilityGridColumns = (selectFacility) => {
    return [
        {
            accessorKey: 'FacilityNameString',
            header: 'Provider',
            meta: {
                headerDisplayName: 'Provider',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'Address',
            header: 'Address',
            meta: {
                headerDisplayName: 'Address',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'Address2',
            header: 'Address (2nd line)',
            meta: {
                headerDisplayName: 'Address (2nd line)',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'Zip',
            header: 'Zip Code',
            meta: {
                headerDisplayName: 'Zip Code',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: '',
            header: 'Select',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                    color="success"
                    type="button"
                    label="Select"
                    onClick={() => selectFacility(row.original)}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}
                />
            ),
            meta: {
                headerDisplayName: 'Select',
                csvExportIgnore: true,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        }
    ];
};

export const AaFacilityGridColumns = (selectFacility) => {
    return [
        {
            accessorKey: 'Affiliate',
            header: 'Affiliate Name',
            meta: {
                headerDisplayName: 'Affiliate Name',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'City',
            header: 'City',
            meta: {
                headerDisplayName: 'City',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: '',
            header: 'Select',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                    color="success"
                    type="button"
                    label="Select"
                    onClick={() => selectFacility(row.original)}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}
                />
            ),
            meta: {
                headerDisplayName: 'Select',
                csvExportIgnore: true,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        }
    ];
};

export const IhsFacilityGridColumns = (selectFacility) => {
    return [
        {
            accessorKey: 'FacilityName',
            header: 'Facility Name',
            meta: {
                headerDisplayName: 'Facility Name',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'Address',
            header: 'Address',
            meta: {
                headerDisplayName: 'Address',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'City',
            header: 'City',
            meta: {
                headerDisplayName: 'City',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: 'ZIP',
            header: 'Zip Code',
            meta: {
                headerDisplayName: 'Zip Code',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        },
        {
            accessorKey: '',
            header: 'Select',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                    color="success"
                    type="button"
                    label="Select"
                    onClick={() => selectFacility(row.original)}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}
                />
            ),
            meta: {
                headerDisplayName: 'Select',
                csvExportIgnore: true,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellColumnName: 'text-center'
            }
        }
    ];
};
