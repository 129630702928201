import React, { ReactNode } from 'react';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NoWrapButton from './NoWrapButton';

interface PDFButtonProps {
    url: string;
    title: ReactNode;
}

/**
 * Render a button used for downloading PDF files.  For now, this is only used for the Cost Data
 * Description document.
 *
 * This may be moved upwards in the directory hierarchy if used elsewhere.
 *
 * @param props - props passed into the component
 * @param props.url - the url to load
 * @param props.title - the label for the button
 * @returns the rendered button
 */
const PDFButton: React.FC<PDFButtonProps> = ({ url, title }) => (
    <NoWrapButton href={url}>
        <FontAwesomeIcon icon={faFilePdf} size="lg" className="mr-2" />
        {title}
    </NoWrapButton>
);

export default PDFButton;
