export interface HSPANotification {
    message: string;
    level: HSPANotificationLevel;
    timestamp: Date;
}

export enum HSPANotificationLevel {
    SUCCESS = 'success',
    ERROR = 'danger',
    INFO = 'info',
    WARNING = 'warning'
}
