import { commaFormatter, percentFormatter } from 'Utilities/formatters';

const DistanceEligibleColumnDefs = (defaultYear) => [
    {
        accessorKey: 'County',
        header: 'County',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'State',
        header: 'State',
        meta: {
            headerDisplayName: 'State',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Baseline',
        header: defaultYear.defaultYearWithFiscalString,
        meta: {
            headerDisplayName: defaultYear.defaultYearWithFiscalString,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: commaFormatter
        }
    },
    {
        accessorKey: 'Year5',
        header: defaultYear.fiveYearWithFiscalStringProjected,
        meta: {
            headerDisplayName: defaultYear.fiveYearWithFiscalStringProjected,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: commaFormatter
        }
    },
    {
        accessorKey: 'Year5Change',
        header: defaultYear.changeDefaultFiveYearString,
        meta: {
            headerDisplayName: defaultYear.changeDefaultFiveYearString,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: percentFormatter
        }
    },
    {
        accessorKey: 'Year10',
        header: defaultYear.tenYearWithFiscalStringProjected,
        meta: {
            headerDisplayName: defaultYear.tenYearWithFiscalStringProjected,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: commaFormatter
        }
    },
    {
        accessorKey: 'Year10Change',
        header: defaultYear.changeDefaultTenYearString,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTenYearString,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: percentFormatter
        }
    },
    {
        accessorKey: 'Year20',
        header: defaultYear.twentyYearWithFiscalStringProjected,
        meta: {
            headerDisplayName: defaultYear.twentyYearWithFiscalStringProjected,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: commaFormatter
        }
    },
    {
        accessorKey: 'Year20Change',
        header: defaultYear.changeDefaultTwentyYearString,
        meta: {
            headerDisplayName: defaultYear.changeDefaultTwentyYearString,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right',
            formatter: percentFormatter
        }
    }
];

export default DistanceEligibleColumnDefs;
