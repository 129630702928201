import _filter from 'lodash/filter';

import {
    downloadDataDefinitions,
    downloadUserInstructions,
    downloadVACoreValuesAndCharacteristics,
    getVideoTutorials
} from 'Data/export';
import { getAllHSPCs } from 'Data/understand-demand';
import { getAllMarkets, getAllVISNs, getBaseYear, getUserProfile } from 'Data/user';
import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import analytics from 'Utilities/analytics';

export const UserSessionModel = {
    state: {
        gapMpcTypes: { list: [], loading: false },
        visns: { list: [], loading: false },
        markets: { list: [], loading: false },
        currentVisn: {},
        currentMarket: { MarketName: '' },
        userProfile: { profile: {}, loading: false },
        videoTutorials: { list: [], loading: false },
        defaultYear: {
            oneYearActual: '',
            twoYearActual: '',
            defaultYear: '',
            fiveYearsOfDefault: '',
            firstYear: '',
            tenYearsOfDefault: '',
            fifteenYearsOfDefault: '',
            twentyYearsOfDefault: '',
            defaultYearWithFiscalString: '',
            defaultYearWithFiscalStringNoInHouse: '',
            defaultYearWithFiscalStringActual: '',
            defaultYearWithFiscalStringProjected: '',
            fiveYearWithFiscalString: '',
            fiveYearWithFiscalStringNoInHouse: '',
            fiveYearWithFiscalStringActual: '',
            fiveYearWithFiscalStringProjected: '',
            fiveYearWithFiscalStringProjectedNoInHouse: '',
            changeDefaultFiveYearString: '',
            changeDefaultFiveYearStringNoInHouse: '',
            changeDefaultTenYearString: '',
            changeDefaultTenYearStringNoInHouse: '',
            changeDefaultTwentyYearString: '',
            changeDefaultTwentyYearStringNoInHouse: '',
            tenYearWithFiscalString: '',
            tenYearWithFiscalStringNoInHouse: '',
            tenYearWithFiscalStringActual: '',
            tenYearWithFiscalStringProjected: '',
            tenYearWithFiscalStringProjectedNoInHouse: '',
            fifteenYearWithFiscalString: '',
            fifeteenYearWithFiscalStringActual: '',
            fifteenYearWithFiscalStringProjected: '',
            twentyYearWithFiscalString: '',
            twentyYearWithFiscalStringNoInHouse: '',
            twentyYearWithFiscalStringActual: '',
            twentyYearWithFiscalStringProjected: '',
            twentyYearWithFiscalStringProjectedNoInHouse: '',
            previousYear: '',
            previousYearWithFiscalStringBaselineUtilization: '',
            defaultYearWithFiscalStringBaselineUtilization: '',
            fiveYearWithFiscalStringModeledUtilization: '',
            tenYearWithFiscalStringModeledUtilization: '',
            twentyYearWithFiscalStringModeledUtilization: '',
            fiveYearWithFiscalStringModeledAllocation: '',
            tenYearWithFiscalStringModeledAllocation: '',
            twentyYearWithFiscalStringModeledAllocation: ''
        }
    },
    reducers: {
        setGapMpcTypes(state, data) {
            return {
                ...state,
                gapMpcTypes: {
                    ...state.gapMpcTypes,
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: isLoading
                }
            };
        },
        setVisns(state, visns) {
            return {
                ...state,
                visns: {
                    ...state.visns,
                    list: visns
                }
            };
        },
        setMarkets(state, markets) {
            return {
                ...state,
                markets: {
                    ...state.markets,
                    list: markets
                }
            };
        },
        setCurrentVISN(state, visn) {
            let analyticsObj = analytics.getInstance();
            analyticsObj.setVisn(visn.VisnNumber ? visn.VisnNumber : 0);
            return {
                ...state,
                currentVisn: visn
            };
        },
        setCurrentMarket(state, market) {
            let analyticsObj = analytics.getInstance();
            analyticsObj.setMarket(market.MarketName ? market.MarketName : '');
            return {
                ...state,
                currentMarket: market
            };
        },
        setUserProfile(state, profile) {
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    profile: profile
                }
            };
        },
        setVideoTutorials(state, data) {
            return {
                ...state,
                videoTutorials: {
                    ...state.videoTutorials,
                    list: data
                }
            };
        },
        setDefaultYear(state, year) {
            return {
                ...state,
                defaultYear: {
                    oneYearActual: year - 1,
                    oneYearWithFiscalStringShortActual:
                        'FY' +
                        (year - 1).toString().substr((year - 1).toString().length - 2) +
                        ' Actual',
                    twoYearActual: year - 2,
                    twoYearWithFiscalStringShortActual:
                        'FY' +
                        (year - 2).toString().substr((year - 2).toString().length - 2) +
                        ' Actual',
                    defaultYear: year,
                    fiveYearsOfDefault: year + 5,
                    firstYear: year + 5,
                    tenYearsOfDefault: year + 10,
                    fifteenYearsOfDefault: year + 15,
                    twentyYearsOfDefault: year + 20,
                    defaultYearWithFiscalString: 'FY ' + year,
                    defaultYearWithFiscalStringShort:
                        'FY' + year.toString().substr(year.toString().length - 2),
                    defaultYearWithBudgetStringShort:
                        'BY' + year.toString().substr(year.toString().length - 2),
                    defaultYearWithFiscalStringNoInHouse: 'FY ' + year,
                    defaultYearWithFiscalStringActual: 'FY ' + year + ' Actual',
                    defaultYearWithFiscalStringProjected: 'FY ' + year + ' Projected',
                    defaultYearWithFiscalStringShortBaseline:
                        'FY' + year.toString().substr(year.toString().length - 2) + ' Baseline',
                    fiveYearWithFiscalString: 'FY ' + (year + 5),
                    fiveYearWithFiscalStringNoInHouse: 'FY ' + (year + 5),
                    fiveYearWithFiscalStringActual: 'FY ' + (year + 5) + ' Actual',
                    fiveYearWithFiscalStringProjected: 'FY ' + (year + 5) + ' Projected',
                    fiveYearWithFiscalStringProjectedNoInHouse: 'FY ' + (year + 5) + ' Projected',
                    fiveYearWithFiscalStringShortProjection:
                        'FY' +
                        (year + 5).toString().substr((year + 5).toString().length - 2) +
                        ' Projection',
                    changeDefaultFiveYearString: '% Change FY ' + year + ' to FY ' + (year + 5),
                    changeDefaultFiveYearStringNoInHouse:
                        '% Change FY ' + year + ' to FY ' + (year + 5),
                    changeDefaultTenYearString: '% Change FY ' + year + ' to FY ' + (year + 10),
                    changeDefaultTenYearStringNoInHouse:
                        '% Change FY ' + year + ' to FY ' + (year + 10),
                    changeDefaultTwentyYearString: '% Change FY ' + year + ' to FY ' + (year + 20),
                    changeDefaultTwentyYearStringNoInHouse:
                        '% Change FY ' + year + ' to FY ' + (year + 20),
                    tenYearWithFiscalString: 'FY ' + (year + 10),
                    tenYearWithFiscalStringNoInHouse: 'Fy ' + (year + 10),
                    tenYearWithFiscalStringActual: 'FY ' + (year + 10) + ' Actual',
                    tenYearWithFiscalStringProjected: 'FY ' + (year + 10) + ' Projected',
                    tenYearWithFiscalStringProjectedNoInHouse: 'FY ' + (year + 10) + ' Projected',
                    tenYearWithFiscalStringShortProjection:
                        'FY' +
                        (year + 10).toString().substr((year + 10).toString().length - 2) +
                        ' Projection',
                    fifteenYearWithFiscalString: 'FY ' + (year + 15),
                    fifeteenYearWithFiscalStringActual: 'FY ' + (year + 15) + ' Actual',
                    fifteenYearWithFiscalStringProjected: 'FY ' + (year + 15) + ' Projected',
                    fifteenYearWithFiscalStringShortProjection:
                        'FY' +
                        (year + 15).toString().substr((year + 15).toString().length - 2) +
                        ' Projection',
                    twentyYearWithFiscalString: 'FY ' + (year + 20),
                    twentyYearWithFiscalStringNoInHouse: 'FY ' + (year + 20),
                    twentyYearWithFiscalStringActual: 'FY ' + (year + 20) + ' Actual',
                    twentyYearWithFiscalStringProjected: 'FY ' + (year + 20) + ' Projected',
                    twentyYearWithFiscalStringProjectedNoInHouse:
                        'FY ' + (year + 20) + ' Projected',
                    twentyYearWithFiscalStringShortProjection:
                        'FY' +
                        (year + 20).toString().substr((year + 20).toString().length - 2) +
                        ' Projection',
                    previousYear: year - 1,
                    previousYearWithFiscalStringBaselineUtilization:
                        'FY ' + (year - 1) + ' Baseline Utilization',
                    defaultYearWithFiscalStringBaselineUtilization:
                        'FY ' + year + ' Baseline Utilization',
                    fiveYearWithFiscalStringModeledUtilization:
                        'FY ' + (year + 5) + ' Modeled Utilization',
                    tenYearWithFiscalStringModeledUtilization:
                        'FY ' + (year + 10) + ' Modeled Utilization',
                    twentyYearWithFiscalStringModeledUtilization:
                        'FY ' + (year + 20) + ' Modeled Utilization',
                    fiveYearWithFiscalStringAllocationUtilization:
                        'FY ' + (year + 5) + ' Allocated Utilization',
                    tenYearWithFiscalStringAllocationUtilization:
                        'FY ' + (year + 10) + ' Allocated Utilization',
                    twentyYearWithFiscalStringAllocationUtilization:
                        'FY ' + (year + 20) + ' Allocated Utilization'
                }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchMarketsAndVisnsAsync(userVisn, state) {
            try {
                let visns = [];
                let markets = [];

                this.updateLoading(true, 'visns');
                visns = await getAllVISNs();
                this.setVisns(visns);
                this.updateLoading(false, 'visns');
                this.updateLoading(true, 'markets');
                markets = await getAllMarkets();
                this.setMarkets(markets);
                this.updateLoading(false, 'markets');

                if (userVisn === null || userVisn === undefined || userVisn === 0) {
                    this.setCurrentVISN(visns[0]);
                    this.setCurrentMarket(markets[0]);
                } else {
                    let defaultVisn = _filter(visns, (item) => {
                        if (item.VisnNumber === userVisn) return item;
                    });
                    if (defaultVisn.length === 0) {
                        this.setCurrentVISN(visns[0]);
                        this.setCurrentMarket(markets[0]);
                    } else {
                        let defaultMarket = _filter(markets, (item) => {
                            if (item.VisnCode === defaultVisn[0].VisnCode) return item;
                        });
                        this.setCurrentVISN(defaultVisn[0]);
                        this.setCurrentMarket(defaultMarket[0]);
                    }
                }
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch visns',
                        level: HSPANotificationLevel.ERROR
                    })
                );
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch markets',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchUserProfileAsync(state) {
            try {
                this.updateLoading(true, 'userProfile');
                let profile = await getUserProfile();
                this.updateLoading(false, 'userProfile');
                this.setUserProfile(profile);
                this.fetchMarketsAndVisnsAsync(profile.User.DefaultVisn);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch user profile',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchDefaultYear(state) {
            try {
                let defaultYear = await getBaseYear();
                this.setDefaultYear(defaultYear);
            } catch (error) {
                this.setDefaultYear(2022);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch default year',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchGapMpcTypesAsync(state) {
            try {
                this.updateLoading(true, 'gapMpcTypes');
                let data = await getAllHSPCs();
                this.updateLoading(false, 'gapMpcTypes');
                this.setGapMpcTypes(data);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch gap HSPC types',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchUserInstructionsAsync(state) {
            try {
                dispatch(
                    addNotification({
                        message: 'Downloading: User instructions',
                        level: HSPANotificationLevel.INFO
                    })
                );
                await downloadUserInstructions();
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch HSPA User Instructions',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchVaCoreValuesAndCharacteristicsPdfAsync(state) {
            try {
                dispatch(
                    addNotification({
                        message: 'Downloading: VA core values and characteristics',
                        level: HSPANotificationLevel.INFO
                    })
                );
                await downloadVACoreValuesAndCharacteristics();
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch VA Core Values and Characteristics PDF',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchDataDefinitionsAsync(state) {
            try {
                dispatch(
                    addNotification({
                        message: `Downloading: Current Projected Utilization Data Definitions`,
                        level: HSPANotificationLevel.INFO
                    })
                );
                await downloadDataDefinitions();
            } catch (error) {
                dispatch(
                    addNotification({
                        message: `Error: Unable to fetch Projected Utilization Data Definitions`,
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchVideoTutorialsAsync(state) {
            try {
                this.updateLoading(true, 'videoTutorials');
                const data = await getVideoTutorials();
                this.setVideoTutorials(data);
                this.updateLoading(false, 'videoTutorials');
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch Video Tutorials',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        resetTablesOnVISNAndMarketChangeAsync(changeType, state) {
            dispatch.StakeholdersFormModel.resetForm();
            dispatch.FortyMileVeteransModel.reset();
            dispatch.RuralityModel.resetTables();
            dispatch.DemographicModel.resetTables();
            dispatch.SurveyDataModel.resetTable('surveyResults');
            dispatch.MetricPopupModel.reset();
            dispatch.MetricFilterModel.reset();

            //understand demand
            dispatch.WorkloadAllocationMatrixModel.resetTable('matrixTableData');
            dispatch.ReviewProjectedDemandModel.reset();
            dispatch.PatientOriginToolModel.reset();
            dispatch.PatientOriginToolModel.resetTable('patientOrigins');
            dispatch.FutureMarketTrendsModel.reset();
            dispatch.AnalysisToolModel.reset();
            dispatch.AnalysisToolModel.resetTable('analysisToolList');

            //Report
            dispatch.ReportFormModel.resetTables();
            dispatch.SavedReportModel.resetTables();

            //understand capacity
            dispatch.InHouseCapacityModel.reset();
            dispatch.MarketDescriptionModel.reset();
            dispatch.ProvidersModel.reset();
            dispatch.VaFacilitiesModel.reset();

            //Match Capacity Demand
            dispatch.ManagePlansModel.reset();
            dispatch.PlannerModel.reset();

            //site actions
            dispatch.ManageUserModel.reset();

            if (changeType === 'visn') {
                dispatch.WaitTimesModel.reset();
                dispatch.MetricProjectionsModel.reset();
                dispatch.VisnFormModel.resetForm();
            }
        }
    })
};
