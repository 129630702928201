import { commaFormatter } from 'Utilities/formatters';

export const PrimaryCareCapacityColumnDefs = [
    {
        header: 'Facility',
        accessorKey: 'Facility',
        meta: {
            headerDisplayName: 'Facility',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '21.25%' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Panelized Enrollees',
        accessorKey: 'PanelizedEnrolleesFormatted',
        meta: {
            headerDisplayName: 'Panelized Enrollees',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '16%' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'APP FTEs',
        accessorKey: 'APPFTEFormatted',
        meta: {
            headerDisplayName: 'APP FTEs',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '16%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'MDFTEFormatted',
        header: 'Physician FTEs',
        meta: {
            headerDisplayName: 'Physician FTEs',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '16%' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'PanelCapacityFormatted',
        header: 'Panel Capacity',
        meta: {
            headerDisplayName: 'Panal Capacity',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '16%' },
            cellClassName: 'text-right'
        }
    }
];

export const NonPrimaryCareCapacityColumnDefs = [
    {
        accessorKey: 'Facility',
        header: 'Facility',
        meta: {
            headerDisplayName: 'Facility',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '30%' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'HspcGroup',
        header: 'HSPC Group',
        meta: {
            headerDisplayName: 'HSPC Group',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Hspc',
        header: 'HSPC',
        meta: {
            headerDisplayName: 'HSPC',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Adc',
        header: 'Average Daily Census',
        meta: {
            headerDisplayName: 'Average Daily Census',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'BedsFormatted',
        header: 'HSPC Beds',
        meta: {
            headerDisplayName: 'HSPC Beds',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'BaseCapacityFormatted',
        header: 'Bed Days of Care',
        meta: {
            headerDisplayName: 'Bed Days of Care',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const AmbulatoryCareCapacityColumnDefs = [
    {
        accessorKey: 'FacilityName',
        header: 'Facility',
        meta: {
            headerDisplayName: 'Facility',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '30%' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Hspc',
        header: 'HSPC',
        meta: {
            headerDisplayName: 'HSPC',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'ProviderPosition',
        header: 'Provider Position',
        meta: {
            headerDisplayName: 'Provider Position',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'WorkFTEE',
        header: 'Work FTEE',
        meta: {
            headerDisplayName: 'Work FTEE',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'ActiveProductivityStandard',
        header: 'Productivity Standard',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Productivity Standard',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        accessorKey: 'Capacity',
        header: 'Capacity',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Capacity',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];
