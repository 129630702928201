/**
 * Multi-select field for Redux Forms.
 */
import React, { useState } from 'react';
import Select from 'react-select';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import proptypes from 'prop-types';
import { Field } from 'redux-form';
import FontAwesome from 'react-fontawesome';
import './style.css';

const RenderMultiSelect = (props) => {
    const simpleOptions = _map(props.options, (option) => option[props.valueField]);
    let validationState = '';
    if (props.meta.touched) {
        if (props.meta.error) {
            validationState = 'invalidDropdown';
        } else {
            validationState = 'validDropdown';
        }
    }

    return (
        <div className="form-group row">
            <div className={`col-sm-${props.labelPosition} col-form-label`}>
                <strong>{`${props.label}:`}</strong>
            </div>
            <div className={`col-sm-${props.inputPosition}`}>
                <Select
                    className={validationState}
                    aria-label={props.label}
                    {...props.input}
                    closeOnSelect={false}
                    disabled={props.disabled}
                    isMulti
                    options={simpleOptions}
                    value={_filter(
                        simpleOptions,
                        (option) => props.input.value.indexOf(option) >= 0
                    )}
                    getOptionLabel={(option) => option}
                    getOptionValue={(option) => option}
                    placeholder={props.placeholder}
                    delimiter={props.delimiter}
                />
                {props.loading === true ? (
                    <div className="feedback-icon">
                        <FontAwesome name="check" spin className="fa fa-spinner" />
                    </div>
                ) : null}
                <span className="invalid-feedback d-block">
                    {props.meta.touched && props.meta.error ? props.meta.error : null}
                </span>
            </div>
        </div>
    );
};

export default class MultiSelectField extends React.Component {
    render() {
        return (
            <Field
                name={this.props.name}
                label={this.props.label}
                options={this.props.options}
                valueField={this.props.valueField}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                delimiter={this.props.delimiter}
                labelPosition={this.props.labelPosition}
                inputPosition={this.props.inputPosition}
                loading={this.props.loading}
                component={RenderMultiSelect}
                normalize={(value) => (Array.isArray(value) ? value.join('; ') : value)}
            />
        );
    }

    static propTypes = {
        name: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        options: proptypes.array.isRequired,
        valueField: proptypes.string.isRequired,
        placeholder: proptypes.string,
        delimiter: proptypes.string,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        disabled: proptypes.bool,
        loading: proptypes.bool
    };
}

MultiSelectField.defaultProps = {
    placeholder: 'None Selected...',
    disabled: false,
    delimiter: '; ',
    labelPosition: 2,
    inputPosition: 10,
    value: '',
    loading: false
};
