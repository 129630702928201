import { concat, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

// TODO: Integrate with dl-core-js
/**
 * Create a standard asynchronous request epic.  The created epic will listen to a particular action
 * type and will emit a start action prior to making the request, a done action upon completion, and
 * a fail action on error.
 *
 * @param {object} options - options to be passed into this operator
 * @param {Function<*[]>|undefined} options.payloadReducer - this will convert the payload from the
 * action into an array of parameters that will be passed to the START/DONE/FAIL action creators
 * @param {Function<Observable>} options.request - this will be called to make an asynchronous
 * request and should return an Observable that will emit any extra actions necessary
 * @param {object} options.actions - the START/DONE/FAIL action creator functions
 * @param {Function} options.actions.start - the action to emit before the request is made
 * @param {Function} options.actions.done - the action to emit after the request has successfully
 * completed
 * @param {Function} options.actions.fail - the action to emit after the request has failed
 * @returns {Observable} a stream of actions, including the START/DONE/FAIL actions along with any
 * actions emitted by the responseHandler Observable
 */
const epicRequest = ({ payloadReducer, request, actions }) => (source) =>
    source.pipe(
        mergeMap((action) => {
            // Create the request parameters by calling payloadReducer on the action
            const requestParameters = payloadReducer ? payloadReducer(action) : [];
            // Concatenate the start, request and the done actions in order
            return concat(
                // Emit the start action
                of(actions.start(...requestParameters)),
                // Execute the request
                request(action),
                // Emit the done action
                of(actions.done(...requestParameters))
                // If an error occurs during the process, emit a failure action
            ).pipe(catchError((error) => of(actions.fail(...requestParameters, error))));
        })
    );

export default epicRequest;
