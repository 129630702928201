import { canEdit, hasRoleInVISN as utilityHasRoleInVISN } from 'Utilities/authorization';
import {
    ADMINISTRATOR_ROLE,
    CAM_ROLE,
    FACILITY_PLANNER,
    VISN_PLANNER_ROLE
} from 'Utilities/constants';

import createCachedSelector from 're-reselect';
/**
 * Redux selectors related to the user session.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import { createSelector } from 'reselect';

/**
 * Get the current user's session data from state.
 *
 * @param {object} state - The redux state.
 * @returns {object} The user session data.
 */
const getUserSessionModel = (state) => state.UserSessionModel;

export const getCurrentMarket = createSelector(getUserSessionModel, (model) => model.currentMarket);
export const getCurrentMarketName = createSelector(
    getCurrentMarket,
    (market) => market.MarketName || ''
);
export const getMarketList = createSelector(getUserSessionModel, (model) => model.markets);
export const getCurrentVISN = createSelector(getUserSessionModel, (model) => model.currentVisn);
export const getVISNList = createSelector(getUserSessionModel, (model) => model.visns);
export const getDefaultYearObject = createSelector(
    getUserSessionModel,
    (model) => model.defaultYear
);
export const getDefaultYear = createSelector(
    getDefaultYearObject,
    (yearInfo) => yearInfo.defaultYear || ''
);
export const getUserProfile = createSelector(
    getUserSessionModel,
    (model) => model.userProfile.profile.User
);
export const getVideoTutorialList = createSelector(
    getUserSessionModel,
    (model) => model.videoTutorials
);
/**
 * Checks if user has read and write permission depending on current role.
 *
 * @param {object} state - The UserSession state.
 * @returns {boolean}
 */
export const canEditVISN = createSelector(getUserProfile, getCurrentVISN, (user, visn) =>
    canEdit(user, visn.VisnNumber)
);

/**
 * Does the specified user
 *
 * @param {object} user - User profile object.
 * @param {number} visnID - ID of the VISN.
 * @returns {boolean} True if the user can approve capital projects related to the VISN.
 */
export const isCAMUser = createSelector(getUserProfile, getCurrentVISN, (user, visn) =>
    utilityHasRoleInVISN(user, CAM_ROLE, visn)
);

/**
 * Check if the user has the specified role for the current VISN.
 *
 * @param {object} state - The UserSession state.
 * @param {string} role - The specified role to check.
 * @returns {boolean} True if the current user has the specified role in the current VISN.
 */
const hasRoleInVISN = createCachedSelector(
    getUserProfile,
    (_state, role) => role,
    getCurrentVISN,
    (user, role, visn) => utilityHasRoleInVISN(user, role, visn.VisnNumber)
)((_state, role) => role);

/**
 * Check if the current user has the specified role.
 *
 * @param {object} state - The application redux state.
 * @param {string} role - The specified role to check.
 * @returns {boolean} True if the current user has the specified role.
 */
const hasRole = createCachedSelector(
    getUserProfile,
    (_state, role) => role,
    (user, role) =>
        !!user &&
        user.UserGroups.some(({ Group }) => {
            const { RoleCode } = Group;
            return RoleCode === role || RoleCode === ADMINISTRATOR_ROLE;
        })
)((_state, role) => role);

/**
 * Indicate whether the current user is an Administrator.
 *
 * @param {object} state - The application redux state.
 * @returns {boolean} True if the current user is an Administrator.
 */
export const isAdministrator = (state) => hasRole(state, ADMINISTRATOR_ROLE);

/**
 * Indicate if the current user is a VISN Planner.
 *
 * This is called when enabling application-level functionality for planners, such as the Manage
 * Users dialog.
 *
 * @param {object} state - The application redux state.
 * @returns {boolean} True if the current user is a VISN Planner for any VISN
 */
export const isVISNPlanner = (state) => hasRole(state, VISN_PLANNER_ROLE);

/**
 * Indicate whether the current user is a VISN planner for the current VISN.  This is different than
 * isVISNPlanner in that it has the additional condition verifying that the user is a planner for
 * the current VISN.
 *
 * This is called when enabling module-level functionality for planners, such as the create plan
 * button in the Match module.
 *
 * @param {object} state - The application redux state.
 * @returns {boolean} True if the current user is a VISN Planner for the current VISN.
 */
export const isPlannerForCurrentVISN = (state) => hasRoleInVISN(state, VISN_PLANNER_ROLE);

/**
 * Indicate whether the current user is a CAM User for the current VISN.
 *
 * @param {object} state - The application redux state.
 * @returns {boolean} True if the current user is a CAM User for the current VISN.
 */
export const isCAMUserForCurrentVISN = (state) => hasRoleInVISN(state, CAM_ROLE);

/**
 * Indicate whether the current user is a Facility Planner for the current VISN.
 *
 * @param {object} state - The application redux state.
 * @returns {boolean} True if the current user is a Facility Planner for the current VISN.
 */
export const isFacilityPlannerForCurrentVISN = (state) => hasRoleInVISN(state, FACILITY_PLANNER);
