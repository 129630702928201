import React from 'react';

import {
    AMBULATORY_SPACE_ESTIMATOR_TOOL_PATH,
    INPATIENT_SPACE_ESTIMATOR_TOOL_PATH
} from 'Utilities/filePaths';

import ActionContent from './ActionContent';
import ExcelButton from './ExcelButton';

type SpaceEstimatorSpreadsheetInfo = {
    title: string;
    careTitle: string;
    url: string;
};

/**
 * Based on a type of care, get the related Space Estimator Tool info.
 *
 * @param typeOfCare - a type of care (i.e. Inpatient, Outpatient, or Primary)
 * @returns title and filename for the related Space Estimator Tool
 * spreadsheet, or undefined if there is no related spreadsheet
 */
const getSpaceSpreadsheetFromTypeOfCare = (
    typeOfCare: string
): SpaceEstimatorSpreadsheetInfo | undefined => {
    switch (typeOfCare) {
        case 'Outpatient': {
            return {
                title: 'VHA Ambulatory Services Space Estimator Tool',
                careTitle: 'specialty',
                url: AMBULATORY_SPACE_ESTIMATOR_TOOL_PATH
            };
        }
        case 'Inpatient': {
            return {
                title: 'VHA Inpatient Bed Estimator Tool',
                careTitle: 'inpatient',
                url: INPATIENT_SPACE_ESTIMATOR_TOOL_PATH
            };
        }
        default:
            return undefined;
    }
};

type CostEstimatorContentProps = {
    typeOfCare: 'Primary' | 'Inpatient' | 'Outpatient';
};

/**
 * A React component to display info about, and a button to download, one of the Space Estimator
 * Tools.
 *
 * @param props - props passed into this component
 * @param props.typeOfCare - the type of care (Primary, Inpatient, Outpatient)
 * @returns the rendered component
 */
const SpaceEstimatorContent = ({ typeOfCare }: CostEstimatorContentProps): JSX.Element | null => {
    const spreadsheet = getSpaceSpreadsheetFromTypeOfCare(typeOfCare);

    if (!spreadsheet) {
        return null;
    }

    return (
        <ActionContent
            content={
                <>
                    <div>
                        The space calculations in HSPA are based on formulas used in the Space
                        Estimator Tool spreadsheets created in collaboration between the Chief
                        Strategy Office and the VA Office of Construction &amp; Facilities
                        Management.
                    </div>
                    <div>
                        For the purposes of easily testing various scenarios, you can download the
                        Space Estimator Tool for {spreadsheet?.careTitle} care using the button on
                        the right.
                    </div>
                </>
            }
            button={<ExcelButton url={spreadsheet.url} title={spreadsheet.title} />}
        />
    );
};

export default SpaceEstimatorContent;
