import {
    commaCurrencyFormatter,
    commaDecimalFormatter,
    commaFormatter
} from 'Utilities/formatters';

/**
 * Determines the formatting style for cells in the table.
 *
 * @param {number} val - The table data value to be formatted.
 * @param {string} calculationType - The calculation type.
 * @returns {Function} - Returns a functions for formatting.
 */
const dataFormatter = (val, calculationType) => {
    switch (calculationType) {
        case 'Staffing':
            return commaDecimalFormatter(val);
        case 'Cost':
            return commaCurrencyFormatter(val);
        default:
            return commaFormatter(val);
    }
};

/**
 * Adds "FTE: " to the front of the row name field if staffing calculation is set.
 *
 * @param {string} rowName - The name of the row.
 * @param {string} calculationType - The calculation type.
 * @returns {Function} - Returns a function for formatting.
 */
const nameFormatter = (rowName, calculationType) => {
    switch (calculationType) {
        case 'Staffing':
            return `FTE: ${rowName}`;
        default:
            return rowName;
    }
};

const GenerateCapacityTableColumns = (
    defaultYear,
    calculationType,
    justificationBtn,
    resetRow,
    columnClassNameFormat
) => {
    if (!defaultYear) {
        return [];
    }

    if (calculationType !== 'Cost') {
        return [
            {
                accessorKey: 'RowName',
                header: 'Supply',
                meta: {
                    headerDisplayName: 'Supply',
                    columnClassName: 'text-center',
                    columnStyle: { width: '20%' },
                    cellStyle: { whiteSpace: 'normal' },
                    cellStyle: { width: '20%' },
                    formatter: (value) => nameFormatter(value, calculationType),
                    editable: false
                }
            },
            {
                accessorKey: 'Year0',
                header: defaultYear,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: false
                }
            },
            {
                accessorKey: 'Year1',
                header: defaultYear + 1,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year2',
                header: defaultYear + 2,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year3',
                header: defaultYear + 3,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year4',
                header: defaultYear + 4,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year5',
                header: defaultYear + 5,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year6',
                header: defaultYear + 6,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year7',
                header: defaultYear + 7,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year8',
                header: defaultYear + 8,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year9',
                header: defaultYear + 9,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: true,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year10',
                header: defaultYear + 10,
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: columnClassNameFormat,
                    cellStyle: { width: '6.66%' },
                    formatter: (value) => dataFormatter(value, calculationType),
                    editable: false
                }
            },
            {
                id: 'justification-button',
                header: null,
                cell: ({ getValue, row }) => justificationBtn(getValue(), row.original),
                meta: {
                    columnClassName: 'text-center',
                    columnStyle: { width: '45px' },
                    cellClassName: 'text-center',
                    cellStyle: { width: '45px' },
                    editable: false
                }
            },
            {
                id: 'reset-row-button',
                header: null,
                cell: ({ getValue, row }) => resetRow(getValue(), row.original),
                meta: {
                    columnClassName: 'text-center',
                    columnStyle: { width: '45px' },
                    cellClassName: 'text-center',
                    cellStyle: { width: '45px' },
                    editable: false
                }
            }
        ];
    }

    return [
        {
            accessorKey: 'RowName',
            header: 'Supply',
            meta: {
                headerDisplayName: 'Supply',
                columnClassName: 'text-center',
                columnStyle: { width: '20%' },
                cellStyle: { whiteSpace: 'normal', width: '20%' },
                formatter: (value) => nameFormatter(value, calculationType),
                editable: false
            }
        },
        {
            accessorKey: 'Year6',
            header: defaultYear + 6,
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.65%' },
                cellClassName: columnClassNameFormat,
                cellStyle: { width: '14.65%' },
                formatter: (value) => dataFormatter(value, calculationType),
                editable: true,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year7',
            header: defaultYear + 7,
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.65%' },
                cellClassName: columnClassNameFormat,
                cellStyle: { width: '14.65%' },
                formatter: (value) => dataFormatter(value, calculationType),
                editable: true,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year8',
            header: defaultYear + 8,
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.65%' },
                cellClassName: columnClassNameFormat,
                cellStyle: { width: '14.65%' },
                formatter: (value) => dataFormatter(value, calculationType),
                editable: true,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year9',
            header: defaultYear + 9,
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.65%' },
                cellClassName: columnClassNameFormat,
                cellStyle: { width: '14.65%' },
                formatter: (value) => dataFormatter(value, calculationType),
                editable: true,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year10',
            header: defaultYear + 10,
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.65%' },
                cellClassName: columnClassNameFormat,
                cellStyle: { width: '14.65%' },
                formatter: (value) => dataFormatter(value, calculationType),
                editable: false
            }
        },
        {
            id: 'justification-button',
            header: null,
            cell: ({ getValue, row }) => justificationBtn(getValue(), row.original),
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' },
                editable: false
            }
        },
        {
            id: 'reset-row-button',
            header: null,
            cell: ({ getValue, row }) => resetRow(getValue(), row.original),
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' },
                editable: false
            }
        }
    ];
};

export default GenerateCapacityTableColumns;
