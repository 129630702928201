const AllSystemUsersColumnDefs = [
    {
        accessorKey: 'FirstName',
        header: 'First Name',
        meta: {
            headerDisplayName: 'First Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'LastName',
        header: 'Last Name',
        meta: {
            headerDisplayName: 'Last Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Username',
        header: 'Username',
        meta: {
            headerDisplayName: 'Username',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'UserEmail',
        header: 'Email',
        meta: {
            headerDisplayName: 'Email',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'UserPhone',
        header: 'Phone',
        meta: {
            headerDisplayName: 'Phone',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    }
];

export default AllSystemUsersColumnDefs;
