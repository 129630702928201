import React from 'react';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import PropTypes from 'prop-types';
import { commaFormatter, commaNAFormatter, percentFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

// Settings to generate props for each of the year-based sections in the table
const sectionConfiguration = [
    {
        sectionLabelsGenerator: (defaultYear) => [
            defaultYear.fiveYearWithFiscalString,
            '5-Year Diff #',
            '5-Year Diff %'
        ],
        dataFieldPrefix: 'Year5'
    },
    {
        sectionLabelsGenerator: (defaultYear) => [
            defaultYear.tenYearWithFiscalString,
            '10-Year Diff #',
            '10-Year Diff %'
        ],
        dataFieldPrefix: 'Year10'
    },
    {
        sectionLabelsGenerator: (defaultYear) => [
            defaultYear.twentyYearWithFiscalString,
            '20-Year Diff #',
            '20-Year Diff %'
        ],
        dataFieldPrefix: 'Year20'
    }
];

const renderColumnDef = (options) => {
    const { dataField, dataFormat, label } = options;
    return {
        accessorKey: dataField,
        header: label,
        meta: {
            headerDisplayName: label,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', fontSize: 12 },
            cellClassName: 'text-right',
            cellStyle: { fontSize: 12 },
            formatter: dataFormat
        }
    };
};

const renderColumnSectionDef = (labels, dataFieldPrefix) => [
    renderColumnDef({
        dataField: `${dataFieldPrefix}Total`,
        dataFormat: commaNAFormatter,
        label: labels[0]
    }),
    renderColumnDef({
        dataField: `${dataFieldPrefix}TotalDiff`,
        dataFormat: commaFormatter,
        label: labels[1]
    }),
    renderColumnDef({
        dataField: `${dataFieldPrefix}TotalDiffPercent`,
        dataFormat: percentFormatter,
        label: labels[2]
    })
];

/**
 * The Future Market Trends Group table's content for expanded section.
 *
 * @param {object} props - props passed into this component
 * @returns {React.ReactNode} the rendered component
 */
const ExpandTable = (props) => {
    const { defaultYear, data } = props;

    if (data.length === 0 || !defaultYear || defaultYear.defaultYear === '') {
        return <p>There is no RVU data to display</p>;
    }

    const subColumns = [
        renderColumnDef({
            dataField: 'ActualYear2Total',
            dataFormat: commaNAFormatter,
            label: `FY ${defaultYear.twoYearActual}`
        }),
        renderColumnDef({
            dataField: 'ActualYear1Total',
            dataFormat: commaNAFormatter,
            label: `FY ${defaultYear.oneYearActual}`
        }),
        renderColumnDef({
            dataField: 'Year0Total',
            dataFormat: commaNAFormatter,
            label: defaultYear.defaultYearWithFiscalString
        })
    ];

    sectionConfiguration.forEach((section) => {
        const { sectionLabelsGenerator, dataFieldPrefix } = section;
        subColumns.push(
            ...renderColumnSectionDef(sectionLabelsGenerator(defaultYear), dataFieldPrefix)
        );
    });

    const columnDefs = [
        columnHelper.group({
            header: `${data[0].HspcGroup} RVU`,
            meta: {
                headerDisplayName: `${data[0].HspcGroup} RVU`,
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap', fontSize: 12 }
            },
            columns: subColumns
        })
    ];

    return (
        <ReactDataTable
            list={data}
            columns={columnDefs}
            isSortable={false}
            isPageable={false}
            isSearchable={false}
            isExportable={false}
        />
    );
};

ExpandTable.propTypes = {
    /** The formatted default year collection. */
    defaultYear: PropTypes.shape({
        /** The year two years ago. */
        twoYearActual: PropTypes.number,
        /** The previous year. */
        oneYearActual: PropTypes.number,
        /** FY-formatted current year. */
        defaultYearWithFiscalString: PropTypes.string,
        /** FY-formatted value five years in the future. */
        fiveYearWithFiscalString: PropTypes.string,
        /** FY-formatted value ten years in the future. */
        tenYearWithFiscalString: PropTypes.string,
        /** FY-formatted value twenty years in the future. */
        twentyYearWithFiscalString: PropTypes.string
    }),
    /** The data to display in the table */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            /** The related specialty. */
            HspcGroup: PropTypes.string
        })
    )
};

ExpandTable.defaultProps = {
    defaultYear: {},
    data: []
};

export default ExpandTable;
