import React from 'react';
import Card from 'react-bootstrap/Card';
import { useParams } from 'react-router-dom';

import styled from 'styled-components/macro';

const Container = styled(Card.Header)`
    position: sticky;
    top: 0;
    z-index: 3;
    white-space: pre;
    display: flex;
    justify-content: space-between;
    align-items: bottom;
    color: #303030;
    background-color: #f8f8f8;
`;

const getUnitsFromType = (type, workloadUnits) => {
    switch (type) {
        default:
        case 'General':
            return workloadUnits;
        case 'Staffing':
            return 'FTEs';
        case 'Space':
            return 'Square Feet';
        case 'Cost':
            return 'US Dollars';
    }
};

const getUnitsFromPlan = (plan) => {
    if (plan?.SPCString === 'Primary Care') {
        return "Panelized Enrollees";
    }

    if (plan?.Hspc?.MpcType === 'Inpatient') {
        return "Beds";
    }

    return "Services";
};

const getWorkloadTypeFromPlan = (plan) => {
    if (plan?.SPCString === 'Primary Care') {
        return "Enrollment";
    }

    if (plan?.Hspc?.MpcType === 'Inpatient') {
        return "Bed Days of Care (BDOC)";
    }

    return "RVUs";
};

const pathToType = {
    workload: 'General',
    staffing: 'Staffing',
    space: 'Space',
    cost: 'Cost'
};

/**
 * The header of the plan.  Displays high-level identifying information about the plan, like the
 * HSPC, VISN, and market.
 *
 * @param {object} props - props passed into this component
 * @param {object} props.plan - the current plan data
 * @param {string} props.type - the type of plan data currently shown
 * @returns {React.ReactNode} the rendered header
 */
const PlanHeader = ({ plan }) => {
    const { type } = useParams();
    const calculationType = pathToType[type];
    const workloadTypeString = plan?.CurrentHspcWorkloadTypeString || '';
    const workloadType =
        type !== 'workload' ? calculationType : getWorkloadTypeFromPlan(plan) || workloadTypeString;

    const workloadUnits = plan?.WorkloadUnit || '';
    const units =
        type !== 'workload'
            ? getUnitsFromType(calculationType, workloadUnits)
            : getUnitsFromPlan(plan);
    const descriptionContent = (description) => {
        if (!description) {
            return null;
        }

        return <div style={{ display: 'flex', color: '#303030' }}>{description}</div>;
    };

    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div style={{ fontSize: '1.5rem', color: '#303030' }}>{plan?.SPCString}</div>
                {descriptionContent(plan?.Description)}
                <div style={{ display: 'flex', color: '#808080' }}>
                    <div style={{ marginRight: 20 }}>
                        <strong>Units of Service: </strong>
                        {units}
                    </div>
                    <div>
                        <strong>Workload Type: </strong>
                        {workloadType}
                    </div>
                </div>
            </div>
            <div style={{ fontSize: '1.4rem', color: '#808080' }}>{plan?.MarketName}</div>
        </Container>
    );
};

export default PlanHeader;
