/**
 * Site action -> edit my settings modal.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import TextboxField from 'Lib/Fields/TextboxField';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';

import UserGroupColumnDefs from './TableOutline';

class SAUserAccountModal extends React.Component {
    formatVisnDisplay = (visnNumber) => {
        let { list } = this.props.user.visns;
        for(let i = 0; i < list.length; i++) {
            if(list[i].VisnNumber === visnNumber)
                return list[i].DisplayName;
        }
        return "VISN " + visnNumber;
    };

    formatVisn = (group) => {
        let { list } = this.props.user.visns;
        for(let i = 0; i < list.length; i++) {
            if(list[i].VisnNumber === group.VisnId)
                return list[i].DisplayName;
        }

        return null;
    };

    handleHide = () => {
        this.props.userAccountModalToggleShow();
    };

    render() {
        const isEmpty = (myObject) => {
            for(var key in myObject) {
                if (myObject.hasOwnProperty(key))
                    return false;
            }
            return true;
        };

        const { profile, loading } = this.props.user.userProfile;
        return(
            <Modal size="lg" show={this.props.show} onHide={this.handleHide}>
                <Modal.Header closeButton>
                    <Modal.Title>My Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextboxField
                        label="Name"
                        value={
                            isEmpty(profile)
                                ? ''
                                : (profile.User.FirstName || '') +
                                    ' ' +
                                    (profile.User.LastName || '')
                        }
                        disabled
                    />
                    <TextboxField
                        label="VISN"
                        value={
                            isEmpty(profile)
                                ? ''
                                : this.formatVisnDisplay(profile.User.DefaultVisn) || ''
                        }
                        disabled
                    />
                    <TextboxField
                        label="Domain"
                        value={isEmpty(profile) ? '' : profile.User.Username || ''}
                        disabled
                    />
                    <TextboxField
                        label="Email"
                        value={isEmpty(profile) ? "" : profile.User.UserEmail || ""}
                        disabled
                    />
                    <TextboxField
                        label="Phone"
                        value={isEmpty(profile) ? "" : profile.User.UserPhone || "" }
                        disabled
                    />
                    <br />
                    <div>
                        <ReactDataTable
                            keyValue="Id"
                            list={isEmpty(profile) ? [] : profile.User.UserGroups}
                            loading={loading}
                            columns={UserGroupColumnDefs((group) => this.formatVisn(group))}
                            isExportable={false}
                            defaultVisibility={{ Delete: false }}
                        />
                    </div>

                </Modal.Body>
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    userAccountModalToggleShow: () => dispatch.UserAccountModel.toggle()
});

const mapStateToProps = state => ({
    show: state.UserAccountModel.show,
    user: state.UserSessionModel
});

export default connect(mapStateToProps, mapDispatchToProps)(SAUserAccountModal);
