import React, { useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { ColumnInstance, useAsyncDebounce } from 'react-table';

type GlobalSearchFilterProps = {
    globalFilter: string;
    setGlobalFilter: (value: string | undefined) => void;
};

export const GlobalSearchFilter = ({
    globalFilter,
    setGlobalFilter
}: GlobalSearchFilterProps): JSX.Element => {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((filterQuery: string) => {
        setGlobalFilter(filterQuery || undefined);
    }, 200);

    return (
        <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="fa fa-search" />
                </span>
            </div>
            <input
                className="form-control"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Search for recommendations"
            />
        </div>
    );
};

// This is a custom filter UI for selecting
// a unique option from a list
type SelectColumnFilterProps = {
    column: ColumnInstance;
};

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id }
}: SelectColumnFilterProps): JSX.Element => {
    const options = React.useMemo((): unknown[] => {
        const optionsSet = new Set();
        preFilteredRows.forEach((row) => {
            // arrValues allows you to split a potential array of values for a proper dropdown
            const arrValues: [string] = Array.isArray(row.values[id])
                ? row.values[id]
                : [row.values[id]];
            optionsSet.add(...arrValues);
        });
        return [...optionsSet.values()];
    }, [id, preFilteredRows]);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    return (
        // Render a multi-select box
        <div style={{ display: 'inline' }}>
            <button
                type="button"
                className={`btn btn-xs ${filterValue ? 'btn-info' : 'btn-secondary'}`}
                style={{ marginLeft: '10px' }}
                ref={target}
                id={`filter-button-${id}`}
                onClick={() => setShow(!show)}>
                <i className="fas fa-sm fa-filter" />
                <span className={`${filterValue ? 'ml-1' : ''}`}>{filterValue}</span>
            </button>
            <Overlay target={target.current} show={show} rootClose onHide={() => setShow(false)}>
                <Popover id={`filter-popover-${id}`}>
                    <Popover.Title as="h3">Column Filter</Popover.Title>
                    <Popover.Content>
                        <div className="form-group mb-0">
                            <select
                                className="form-control"
                                value={String(filterValue)}
                                onChange={(e) => {
                                    setFilter(e.target.value || undefined);
                                    setShow(false);
                                }}
                            >
                                <option value="">All</option>
                                {options.map((option, i) => {
                                    if (option) {
                                        return (
                                            <option key={String(i)} value={String(option)}>
                                                {String(option)}
                                            </option>
                                        );
                                    }
                                    return null;
                                })}
                            </select>
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
};

export default { SelectColumnFilter, GlobalSearchFilter };
