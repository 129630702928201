import { AsyncState } from 'Utilities/constants';
import { NameId } from 'Utilities/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CapitalProjectTypeState = {
    items: NameId[];
    loadingStatus?: AsyncState;
};

export type CapitalProjectTypePayload = {
    types: NameId[];
};

const capitalProjectTypesSlice = createSlice({
    name: 'Capital Project Type',
    initialState: { items: [] } as CapitalProjectTypeState,
    reducers: {
        /**
         * Reducer for setting the capital project types.
         *
         * @param draftState - The initial draft state.
         * @param action - The payload action.
         * @param action.payload - The payload contained in the action.
         */
        setCapitalProjectTypes(draftState, { payload }: PayloadAction<CapitalProjectTypePayload>) {
            draftState.items = payload.types;
        },
        /**
         * Does not update the state, but the related action is use to kick off an epic that loads
         * the capital project types.
         *
         * @param draftState - The draft state.
         * @param action - The payload action.
         */
        loadCapitalProjectTypes(
            draftState,
            action: PayloadAction
        ) {},
        /**
         * Set the capital projects type loading status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadCapitalProjectTypeStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },

        /**
         * Set the capital projects type loading status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadCapitalProjectTypeDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the capital projects type loading status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadCapitalProjectTypeError(draftState) {
            draftState.loadingStatus = AsyncState.FAILURE;
        }
    }
});

export const {
    setCapitalProjectTypes,
    loadCapitalProjectTypes,
    loadCapitalProjectTypeStart,
    loadCapitalProjectTypeDone,
    loadCapitalProjectTypeError
} = capitalProjectTypesSlice.actions;

export default capitalProjectTypesSlice.reducer;
