import { reducer as formReducer } from 'redux-form';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { init } from '@rematch/core';

// reducers
import notifications from './Modules/App/HSPANotifications/notifications';
import capitalProjects, { epic as capitalProjectsEpic } from './Modules/CapitalProject';
import capitalProjectTypes, { epic as capitalProjectTypesEpic } from './Modules/CapitalProjectType';
import { DemographicModel } from './Modules/EnvironmentalScan/Services/DemographicModel';
import { FortyMileVeteransModel } from './Modules/EnvironmentalScan/Services/FortyMileVeteransModel';
import { RuralityModel } from './Modules/EnvironmentalScan/Services/RuralityModel';
import { SavedMapsModel } from './Modules/EnvironmentalScan/Services/SavedMapsModel';
import { StakeholdersFormModel } from './Modules/EnvironmentalScan/Services/StakeholdersFormModel';
import { SurveyDataModel } from './Modules/EnvironmentalScan/Services/SurveyDataModel';
// models
import { VisnFormModel } from './Modules/EnvironmentalScan/Services/VisnFormModel';
import facilitySelection, { epic as facilitySelectionEpic } from './Modules/FacilitySelection';
import hspcSelection, { epic as hspcSelectionEpic } from './Modules/HspcSelection';
import { ManagePlansModel } from './Modules/MatchCapacityDemand/Services/ManagePlansModel';
import { PlannerModel } from './Modules/MatchCapacityDemand/Services/PlannerModel';
import planSelection, { epic as planSelectionEpic } from './Modules/PlanSelection';
import facilityReport, {
    epic as facilityReportEpic
} from './Modules/Report/Services/FacilityReport';
import { ReportFormModel } from './Modules/Report/Services/ReportFormModel';
import { SavedReportModel } from './Modules/Report/Services/SavedReportModel';
import { AdministrationModel } from './Modules/SiteActions/Services/AdministrationModel';
import { AskAQuestionModel } from './Modules/SiteActions/Services/AskAQuestionModel';
import { ChangelogModel } from './Modules/SiteActions/Services/ChangelogModel';
import { FaqModel } from './Modules/SiteActions/Services/FaqModel';
import { ManageUserModel } from './Modules/SiteActions/Services/ManageUserModel';
import { UserAccountModel } from './Modules/SiteActions/Services/UserAccountModel';
import { UserTrainingModel } from './Modules/SiteActions/Services/UserTrainingModel';
import { MetricFilterModel } from './Modules/StrategicOutlook/Services/MetricFilterModel';
import MetricPopupModel from './Modules/StrategicOutlook/Services/MetricPopupModel';
import { MetricProjectionsModel } from './Modules/StrategicOutlook/Services/MetricProjectionsModel';
import opportunities, {
    epic as opportunitiesEpic
} from './Modules/StrategicOutlook/Services/opportunities';
import { InHouseCapacityModel } from './Modules/UnderstandCapacity/Services/InHouseCapacityModel';
import { MarketDescriptionModel } from './Modules/UnderstandCapacity/Services/MarketDescriptionModel';
import { ProviderFormModel } from './Modules/UnderstandCapacity/Services/ProviderFormModel';
import { ProvidersModel } from './Modules/UnderstandCapacity/Services/ProvidersModel';
import { VaFacilitiesModel } from './Modules/UnderstandCapacity/Services/VaFacilitiesModel';
import { AnalysisToolModel } from './Modules/UnderstandDemand/Services/AnalysisToolModel';
import { FutureMarketTrendsModel } from './Modules/UnderstandDemand/Services/FutureMarketTrendsModel';
import { PatientOriginToolModel } from './Modules/UnderstandDemand/Services/PatientOriginToolModel';
import { ReviewProjectedDemandModel } from './Modules/UnderstandDemand/Services/ReviewProjectedDemandModel';
import { WaitTimesModel } from './Modules/UnderstandDemand/Services/WaitTimesModel';
import { WorkloadAllocationMatrixModel } from './Modules/UnderstandDemand/Services/WorkloadAllocationMatrixModel';
import { UserSessionModel } from './Modules/UserSession/model';

const epicMiddleware = createEpicMiddleware();

const rootEpic = combineEpics(
    facilityReportEpic,
    opportunitiesEpic,
    capitalProjectsEpic,
    capitalProjectTypesEpic,
    facilitySelectionEpic,
    planSelectionEpic,
    hspcSelectionEpic
);

const store = init({
    models: {
        AdministrationModel,
        AnalysisToolModel,
        AskAQuestionModel,
        ChangelogModel,
        DemographicModel,
        FaqModel,
        FortyMileVeteransModel,
        FutureMarketTrendsModel,
        InHouseCapacityModel,
        ManagePlansModel,
        ManageUserModel,
        MarketDescriptionModel,
        MetricFilterModel,
        MetricPopupModel,
        MetricProjectionsModel,
        PatientOriginToolModel,
        PlannerModel,
        ProviderFormModel,
        ProvidersModel,
        ReportFormModel,
        ReviewProjectedDemandModel,
        RuralityModel,
        SavedMapsModel,
        SavedReportModel,
        StakeholdersFormModel,
        SurveyDataModel,
        UserAccountModel,
        UserSessionModel,
        UserTrainingModel,
        VaFacilitiesModel,
        VisnFormModel,
        WaitTimesModel,
        WorkloadAllocationMatrixModel
    },
    redux: {
        devtoolOptions: {
            disabled: true
        },
        reducers: {
            notifications,
            // router: routerReducer,
            form: formReducer,
            facilityReport,
            opportunities,
            capitalProjects,
            capitalProjectTypes,
            facilitySelection,
            planSelection,
            hspcSelection
        },
        createStore: (reducer, preloadedState, enhancer) => {
            const toolkitStore = configureStore({
                reducer,
                middleware: [
                    ...getDefaultMiddleware({
                        thunk: false,
                        immutableCheck: false,
                        // Hack fix for an error related to improper Redux set up.
                        serializableCheck: false
                    }),
                    epicMiddleware
                ],
                preloadedState,
                enhancers: [enhancer]
            });

            epicMiddleware.run(rootEpic);

            return toolkitStore;
        },
        middlewares: [
            // routerMiddleware(history)
        ]
    }
});

export default store;
