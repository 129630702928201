import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import notifications from 'Modules/App/HSPANotifications/notifications';
import capitalProjects from 'Modules/CapitalProject';
import capitalProjectTypes from 'Modules/CapitalProjectType';
import facilitySelection from 'Modules/FacilitySelection';
import hspcSelection from 'Modules/HspcSelection';
import planSelection from 'Modules/PlanSelection';
import opportunities from 'Modules/StrategicOutlook/Services/opportunities';

import store from './store';

export type HSPAState = ReturnType<typeof store.getState> & {
    opportunities: ReturnType<typeof opportunities>;
    notifications: ReturnType<typeof notifications>;
    capitalProjects: ReturnType<typeof capitalProjects>;
    capitalProjectTypes: ReturnType<typeof capitalProjectTypes>;
    facilitySelection: ReturnType<typeof facilitySelection>;
    planSelection: ReturnType<typeof planSelection>;
    hspcSelection: ReturnType<typeof hspcSelection>;
};

export type HSPADispatch = typeof store.dispatch;

export const useHSPADispatch: () => HSPADispatch = useDispatch;

export const useHSPASelector: TypedUseSelectorHook<HSPAState> = useSelector;
