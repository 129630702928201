/**
 * Goals and objectives of the HSPA.
 * @author Anthony P. Pancerella
 * @version November 27, 2018
 */
import React from 'react';

export default class ESSPFGeographyRurality extends React.Component {
    render() {
        return (
            <div>
                <strong>Rural Veteran Demographics</strong>
                <p>
                    There are 4.7 million rural and highly rural Veterans with 2.87 million enrolled
                    in VA.
                </p>
                <ul>
                    <li>
                        <p>33 percent of enrollees live in a rural area</p>
                    </li>
                    <li>
                        <p>47 percent of rural enrolled Veterans reported exposure to combat</p>
                    </li>
                    <li>
                        <p>7 percent of enrolled rural Veterans are women</p>
                    </li>
                    <li>
                        <p>47 percent earn less than $35,000 annually</p>
                    </li>
                    <li>
                        <p>25 percent do not access the internet at home</p>
                    </li>
                </ul>
                <p>
                    Rural Veterans enrolled in VA's health care system are also significantly older:
                    54 percent are over the age of 65.
                </p>
                <ul>
                    <li>
                        <p>
                            This older Veteran population is medically complex and more likely to be
                            diagnosed with diabetes, obesity, high blood pressure and heart
                            conditions that require more frequent, ongoing and costly care.
                        </p>
                    </li>
                </ul>
                <p>
                    The next generation of rural Veterans also has multiple medical and
                    combat-related issues, which will require significant on-going access to care.
                </p>
                <ul>
                    <li>
                        <p>
                            Nearly 654,000 rural Veterans served in Operation Enduring Freedom
                            (OEF), Operation Iraqi Freedom (OIF), or Operation New Dawn (OND)
                        </p>
                    </li>
                </ul>
                <br />
                <strong>Rural Definition</strong>
                <p>
                    VA uses the Rural-Urban Commuting Areas (RUCA) system to define rurality.
                    Developed by the Department of Agriculture (USDA) and the Department of Health
                    and Human Services (HHS), the RUCA system takes into account population density
                    as well as how closely a community is linked socio-economically to larger urban
                    centers. RUCA is based on how the U.S. Census Bureau counts citizens.
                </p>
                <ul>
                    <li>
                        <p>
                            Urban Area: Census tracts with at least 30 percent of the population
                            residing in an urbanized area as defined by the Census Bureau.
                        </p>
                    </li>
                    <li>
                        <p>Rural Area: Land areas not defined as urban or highly rural.</p>
                    </li>
                    <li>
                        <p>
                            Highly Rural Area: Sparsely populated areas – less than 10 percent of
                            the working population commutes to any community larger than an
                            urbanized cluster, which is typically a town of no more than 2,500
                            people.
                        </p>
                    </li>
                </ul>
                <div style={{ width: '100%', height: '30px' }}></div>
            </div>
        );
    }
}
