import { useRouteMatch } from 'react-router-dom';

/**
 * A React hook to retrieve the route above the current one.
 *
 * @returns the parent route
 */
const useParentRoute = (): string => {
    const { url } = useRouteMatch();

    return url.substring(0, url.lastIndexOf('/'));
};

export default useParentRoute;
