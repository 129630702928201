import React from 'react';

import { REACT_APP_APPLICATION_ROOT_ROUTE } from 'Utilities/constants';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const nameColumnCell = (getValue, row) => {
    if (typeof row.original.StationNumber === 'undefined' || row.original.StationNumber === '') {
        return getValue();
    }

    return (
        <a
            style={{ color: 'inherit' }}
            href={
                `${process.env.PUBLIC_URL}#/` +
                `${REACT_APP_APPLICATION_ROOT_ROUTE}/Reports/Facility/` +
                `${row.original.StationNumber}`
            }
            target="_blank"
            rel="noreferrer"
        >
            {getValue()}
            <FontAwesomeIcon
                style={{ marginLeft: '0.3rem', color: '#505050' }}
                icon={faExternalLinkAlt}
            />
        </a>
    );
};

const GeneratePlannerWorkbenchDrilldownColumns = (
    calculationType,
    popOverFormatter,
    sortFunc,
    dataFormatter,
    justificationBtn,
    resetRow,
    enableEditing
) => {
    if (calculationType !== 'Cost') {
        return [
            {
                id: 'pop-over-column',
                header: null,
                cell: ({ getValue, row }) => popOverFormatter(getValue(), row.original),
                meta: {
                    cellStyle: { whiteSpace: 'normal', width: '34px' },
                    editable: false
                }
            },
            {
                accessorKey: 'Id',
                header: null,
                enableHiding: true,
                enableSorting: true,
                sortingFn: sortFunc,
                meta: {
                    editable: false
                }
            },
            {
                accessorKey: 'Name',
                header: null,
                cell: ({ getValue, row }) => nameColumnCell(getValue, row),
                meta: {
                    columnClassName: 'text-center',
                    cellStyle: { whiteSpace: 'normal', fontSize: 12, width: '20%' },
                    editable: false
                }
            },
            {
                accessorKey: 'Year0',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: false
                }
            },
            {
                accessorKey: 'Year1',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year2',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year3',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year4',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year5',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year6',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year7',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year8',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year9',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                accessorKey: 'Year10',
                header: null,
                meta: {
                    formatter: dataFormatter,
                    cellClassName: 'text-right',
                    cellStyle: { fontSize: 12, width: '6.66%' },
                    editable: enableEditing,
                    editInputAttributes: { type: 'number' }
                }
            },
            {
                id: 'justification-button',
                header: null,
                cell: ({ getValue, row }) => justificationBtn(getValue(), row.original),
                meta: {
                    cellClassName: 'text-center',
                    cellStyle: { width: '45px' },
                    editable: false
                }
            },
            {
                id: 'reset-row-button',
                header: null,
                cell: ({ getValue, row }) => resetRow(getValue(), row.original),
                meta: {
                    cellClassName: 'text-center',
                    cellStyle: { width: '45px' },
                    editable: false
                }
            }
        ];
    }

    return [
        {
            accessorKey: 'Id',
            header: null,
            enableHiding: true,
            sortingFn: sortFunc,
            meta: {
                editable: false
            }
        },
        {
            accessorKey: 'Name',
            header: null,
            cell: ({ getValue, row }) => nameColumnCell(getValue, row),
            meta: {
                columnClassName: 'text-center',
                cellStyle: { whiteSpace: 'normal', fontSize: 12, width: '20%' },
                editable: false
            }
        },
        {
            accessorKey: 'Year6',
            header: null,
            meta: {
                formatter: dataFormatter,
                cellClassName: 'text-right',
                cellStyle: { fontSize: 12, width: '14.65%' },
                editable: enableEditing,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year7',
            header: null,
            meta: {
                formatter: dataFormatter,
                cellClassName: 'text-right',
                cellStyle: { fontSize: 12, width: '14.65%' },
                editable: enableEditing,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year8',
            header: null,
            meta: {
                formatter: dataFormatter,
                cellClassName: 'text-right',
                cellStyle: { fontSize: 12, width: '14.65%' },
                editable: enableEditing,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year9',
            header: null,
            meta: {
                formatter: dataFormatter,
                cellClassName: 'text-right',
                cellStyle: { fontSize: 12, width: '14.65%' },
                editable: enableEditing,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Year10',
            header: null,
            meta: {
                formatter: dataFormatter,
                cellClassName: 'text-right',
                cellStyle: { fontSize: 12, width: '14.65%' },
                editable: enableEditing,
                editInputAttributes: { type: 'number' }
            }
        },
        columnHelper.display({
            id: 'blank-1',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' }
            }
        }),
        columnHelper.display({
            id: 'blank-2',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' }
            }
        })
    ];
};

export default GeneratePlannerWorkbenchDrilldownColumns;
