/**
 * Displays a table containing the understand demand projections
 * for the specified plan
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { useSelector } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getDemandOverheadData } from 'Modules/MatchCapacityDemand/Services/selector';
import PropTypes from 'prop-types';

import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import MCDPlannerWorkbenchDrilldown from '../../MCDPlannerWorkbenchDrilldown';
import GenerateDemandTableColumns from './MCDPWDemandTableColumns';

const MCDPWDemandTable = React.memo(({ calculationType, currentPlan, defaultYear }) => {
    const tableData = useSelector((state) => getDemandOverheadData(state, calculationType));

    /**
     * Passes table record elements to the MCDPlannerWorkbenchDrilldown component
     *
     * @returns {React.ReactNode} - returns a MCDPlannerWorkbenchDrilldown component
     */
    const expandComponent = () => (
        <MCDPlannerWorkbenchDrilldown
            calculationType={calculationType}
            canEdit={false}
            filter="Demand"
            isEditable={false}
            updateTable={() => null}
            tableType="Demand"
        />
    );

    const columns = GenerateDemandTableColumns(defaultYear, calculationType);

    return (
        <ReactDataTable
            list={tableData}
            columns={columns}
            loading={currentPlan.loading}
            isExportable={false}
            isSearchable={false}
            isPageable={false}
            isSortable={false}
            striped={false}
            hover={false}
            keyValue="RowName"
            expandContent={{
                canRender: (row) => row.IsExpandable,
                renderer: expandComponent,
                expandedIcon: faCaretDown,
                collapsedIcon: faCaretRight
            }}
            containerStyle={{ height: '100%', marginBottom: '-14px'}}
        />
    );
});

MCDPWDemandTable.propTypes = {
    currentPlan: PropTypes.shape({}).isRequired,
    defaultYear: PropTypes.number.isRequired,
    calculationType: PropTypes.string.isRequired
};

export default MCDPWDemandTable;
