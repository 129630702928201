/**
 * Site action -> ask a question modal.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';

import { formValueSelector, reduxForm, reset } from 'redux-form';

import Button from '../../../Lib/Button';
import DropdownField from '../../../Lib/ReduxFormFields/DropdownField';
import TextAreaField from '../../../Lib/ReduxFormFields/TextAreaField';
import TextboxField from '../../../Lib/ReduxFormFields/TextboxField';

class SAAskAQuestionModal extends React.Component {
    render() {
        return (
            <div>
                <Modal size="lg" show={this.props.show} onHide={this.handleHide}>
                    <form
                        className="needs validation"
                        onSubmit={this.props.handleSubmit(this.formSubmit)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>HSPA - Ask a Question</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ paddingBottom: '15px' }}>
                                Your questions and concerns are important to us. Please contact your
                                <a href="http://vaww.va.gov/VHAOPP/10P1B_liaisons.asp" target="_blank" rel="noreferrer">
                                    {' '}
                                    VISN Strategic Planning Liaison
                                </a>
                                , or complete the following form for any questions you have on the
                                Health Systems Planning Application (HSPA) and select the "Submit
                                Question" button to send your request. A representative from VHA's
                                Office of Strategic Planning and Analysis will contact you promptly
                                to provide assistance. Thank you.
                            </div>
                            <DropdownField
                                name="VisnCode"
                                label="VISN"
                                placeholder="Select VISN or leave blank"
                                options={this.props.user.visns.list}
                                displayField="DisplayName"
                                valueField="VisnCode"
                                onChangeFunction={this.resetMarketNameField}
                                labelPosition={2}
                                inputPosition={10}
                            />
                            <DropdownField
                                name="MarketName"
                                label="Market"
                                placeholder="Select Market or leave blank"
                                options={_filter(this.props.user.markets.list, (item) => {
                                    if (this.props.currentVisn === item.VisnCode) return item;
                                })}
                                currentVisn={this.props.currentVisn}
                                displayField="MarketName"
                                valueField="MarketName"
                                labelPosition={2}
                                inputPosition={10}
                            />
                            <TextboxField
                                name="Name"
                                label="Name"
                                placeholder="Enter Name..."
                                labelPosition={2}
                                inputPosition={10}
                            />
                            <TextboxField
                                name="Phone"
                                label="Phone"
                                placeholder="Enter Phone... Ex: 555-555-5551x123"
                                labelPosition={2}
                                inputPosition={10}
                            />
                            <TextboxField
                                name="Email"
                                label="E-mail"
                                placeholder="Enter E-mail..."
                                labelPosition={2}
                                inputPosition={10}
                            />
                            <TextboxField
                                name="Subject"
                                label="Subject"
                                placeholder="Enter Subject..."
                                labelPosition={2}
                                inputPosition={10}
                            />
                            <TextAreaField
                                name="Question"
                                label="Question"
                                placeholder="Enter the question description..."
                                type="text"
                                labelPosition={2}
                                inputPosition={10}
                            />
                        </Modal.Body>
                        <Modal.Footer style={{ height: '55px' }}>
                            <div className="col-sm-12">
                                <div className="form-group" style={{ paddingTop: '20px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            label={'Submit Question'}
                                            disabled={this.props.invalid || this.props.submitting}
                                            onClick={this.formGenerate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
    resetMarketNameField = () => {
        this.props.change('MarketName', '');
        this.props.untouch('AskQuestionModal', 'MarketName');
    };
    handleHide = () => {
        this.props.askQuestionModalToggleShow(false);
        this.props.askQuestionReset();
        this.props.dispatch(reset('SAAskAQuestionModal'));
    };
    formSubmit = (values) => {
        this.props.submitQuestion(values);
        this.props.askQuestionReset();
        this.props.dispatch(reset('SAAskAQuestionModal'));
    };
}
SAAskAQuestionModal = reduxForm({
    form: 'SAAskAQuestionModal',
    enableReinitialize: true,
    validate: function (values, props) {
        const errors = {};
        if (!values.Name) errors.Name = 'Name is required';
        if (!values.Email) errors.Email = 'E-mail is required';
        else {
            if (
                !/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
                    values.Email
                )
            )
                errors.Email = 'E-mail is not in the correct format';
        }
        if (!values.Subject) errors.Subject = 'Subject is required';
        if (
            !/^[01]?[- .]?(\([2-9]\d{2}\)|[2-9]\d{2})[- .]?\d{3}[- .]?\d{4}[x]?(\d{3,5})?$/.test(
                values.Phone
            )
        )
            errors.Phone = 'Phone is not in US format';
        if (!values.VisnCode) errors.VisnCode = 'Visn is required';
        if (!values.Question) errors.Question = 'Question is required';
        return errors;
    }
})(SAAskAQuestionModal);

const mapDispatchToProps = (dispatch) => {
    return {
        askQuestionReset: () => dispatch.AskAQuestionModel.resetForm(),
        askQuestionModalToggleShow: () => dispatch.AskAQuestionModel.toggle(),
        submitQuestion: (formValues) => dispatch.AskAQuestionModel.submitQuestionAsync(formValues)
    };
};
const selector = formValueSelector('SAAskAQuestionModal');
const mapStateToProps = (state) => ({
    currentVisn: selector(state, 'VisnCode'),
    show: state.AskAQuestionModel.show,
    initialValues: state.AskAQuestionModel.formData || {},
    user: state.UserSessionModel
});

export default connect(mapStateToProps, mapDispatchToProps)(SAAskAQuestionModal);
