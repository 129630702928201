import React from 'react';

import Button from 'Lib/Button';

export const UserGroupColumns = (formatVisn, deleteRole, isAdmin, isVisnAdmin, curVisn) =>  {
    return [{
        dataField: 'Group[GroupName]',
        text: 'Role Name',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'center',
        align: 'center'
    },
    {
        dataField: 'Group[VisnId]',
        text: 'VISN',
        sort: true,
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'center',
        align: 'center',
        formatter: (cell, row, enumObject, rowIndex) => {
            let visn = formatVisn(row.Group);
            return(
                visn
            );
        }
    },
    {
        dataField: "2",
        text: "Delete",
        headerStyle: { whiteSpace: 'pre-wrap' },
        headerAlign: 'center',
        align: 'center',
        hidden: typeof curVisn === 'undefined',
        formatter: (cell, row, enumObject, rowIndex) => {
            return(
                <Button
                    color="danger"
                    type="button"
                    label="Delete"
                    disabled={!isAdmin && row.Group.VisnId !== curVisn.VisnNumber}
                    onClick={() => deleteRole(row.Id)}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}/>
            );
        }
    }];
}

const UserGroupColumnDefs = (formatVisn, deleteRole, isAdmin, isVisnAdmin, curVisn) => [
    {
        id: 'GroupName',
        accessorFn: (originalRow) => originalRow.Group.GroupName,
        header: 'Role Name',
        meta: {
            headerDisplayName: 'Role Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'VISN',
        accessorFn: (originalRow) => originalRow.Group.VisnId,
        header: 'VISN',
        meta: {
            headerDisplayName: 'VISN',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: formatVisn
        }
    },
    {
        id: "Delete",
        header: "Delete",
        cell: ({ row }) => (
            <Button
                color="danger"
                type="button"
                label="Delete"
                disabled={!isAdmin && curVisn?.VisnNumber && row.original.Group.VisnId !== curVisn.VisnNumber}
                onClick={() => deleteRole(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}/>
        ),
        meta: {
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        },
        hidden: typeof curVisn === 'undefined'
    }
];

export default UserGroupColumnDefs;
