import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useHSPADispatch, useHSPASelector } from 'storeTypes';

import { canEditVISN } from 'Modules/UserSession/selector';
import { Plan, PlanDTO } from 'Utilities/types';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getPlanData } from '../../Services/selector';
import CreateCapitalProjectModal from './CreateCapitalProjectModal';

type Props = {
    plans: Plan[];
};

const CreateCapitalProjectButton = ({ plans }: Props): JSX.Element => {
    const dispatch = useHSPADispatch();
    const hasPermission = useHSPASelector(canEditVISN);
    const [showCreateCapitalProject, setShowCreateCapitalProject] = useState(false);
    const selectedPlan = useSelector(getPlanData) as PlanDTO;
    const currentPlan = plans?.find((plan) => plan.IsSelected === true) as Plan;
    const [loadingPlan, setLoadingPlan] = useState(false);

    const handleShowCreateCapitalProject = () => setShowCreateCapitalProject(true);

    const handleHideCreateCapitalProject = () => setShowCreateCapitalProject(false);

    const getPlanDetails = async () => {
        if (!currentPlan?.Id) {
            return;
        }

        setLoadingPlan(true);
        await dispatch.PlannerModel.fetchPlanByIdAsync(currentPlan.Id).then(() => {
            setLoadingPlan(false);
            handleShowCreateCapitalProject();
        });
    };

    return (
        <>
            <Button
                variant="success"
                size="sm"
                type="button"
                disabled={!hasPermission || loadingPlan === true}
                style={{ borderRadius: '16px' }}
                onClick={() => getPlanDetails()}
            >
                {loadingPlan === true ? (
                    <Spinner animation="border" variant="light" size="sm" />
                ) : (
                    <span>
                        <FontAwesomeIcon icon={faPlusCircle} />
                        &nbsp;Create Capital Project
                    </span>
                )}
            </Button>
            <CreateCapitalProjectModal
                showModal={showCreateCapitalProject}
                plan={selectedPlan}
                createFinish={handleHideCreateCapitalProject}
            />
        </>
    );
};

export default CreateCapitalProjectButton;
