/**
 * Strategic planning container.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';

import SubTabComponent from '../../../../Lib/Tabs/SubTabComponent';
import ESSPFGeographyRurality from './ESSPFGeographyRurality';
import ESSPFGoalsObjectives from './ESSPFGoalsObjectives';
import ESSPFStrategicPolicy from './ESSPFStrategicPolicy';
import ESSPFValuesCharacteristics from './ESSPFValuesCharacteristics';
import ESSPFVhaPriorities from './ESSPFVhaPriorities';
import StatePolicyProfiles from './ESStatePolicyProfiles';

export default class ESStrategicPlanningFoundations extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: 0 };
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const tabs = [
            { key: 0, label: 'VA Core Values and Characteristics' },
            { key: 1, label: 'VA Strategic Plan Goals and Objectives' },
            { key: 2, label: 'VHA Priorities' },
            { key: 3, label: 'Strategic Policy' },
            { key: 4, label: "State Policy Reference"}
            // { key: 5, label: "Geography and Rurality" }
        ];
        return (
            <div>
                <div>
                    <div style={{ paddingTop: 15 }}>
                        <SubTabComponent
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            handleOnClick={this.changeTab}
                        />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        {this.state.activeTab === 0 ? <ESSPFValuesCharacteristics /> : null}
                        {this.state.activeTab === 1 ? <ESSPFGoalsObjectives /> : null}
                        {this.state.activeTab === 2 ? <ESSPFVhaPriorities /> : null}
                        {this.state.activeTab === 3 ? <ESSPFStrategicPolicy /> : null}
                        {this.state.activeTab === 4 ? <StatePolicyProfiles /> : null}
                        {this.state.activeTab === 5 ? <ESSPFGeographyRurality /> : null}
                    </div>
                </div>
            </div>
        );
    }
}
