/**
 * Manage users in current market.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getCurrentVISN, getVISNList, isPlannerForCurrentVISN } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';
import { hasRoleInVISN } from 'Utilities/authorization';
import { ADMINISTRATOR_ROLE } from 'Utilities/constants';

import GrantAccessForm from '../SAGrantAccessForm';
import Instructions from './Instructions';
import { UserGroupColumnDefs, UsersInNetworkColumnDefs } from './TableOutline';

/**
 * A React component to display the users in the current VISN.
 */
class SAUsersInNetwork extends React.Component {
    static propTypes = {
        /** Is the current user a HSPA administrator? */
        isAdmin: PropTypes.bool,
        /** Is the current user a VISN planner? */
        isVISNPlanner: PropTypes.bool,
        /** The currently selected VISN. */
        currentVISN: PropTypes.shape({
            /** The VISN ID. */
            VisnCode: PropTypes.number
        }),
        /** Data about the user's current session. */
        user: PropTypes.shape({
            /** The currently selected market. */
            currentMarket: PropTypes.shape({
                /** The name of the market. */
                MarketName: PropTypes.string
            }),
            /** The currently selected VISN. */
            currentVisn: PropTypes.shape({
                /** The VISN ID. */
                VisnCode: PropTypes.number
            })
        }),
        /** Users associated with the current session's VISN. */
        usersInNetwork: PropTypes.shape({
            /** The array of users associated with the VISN. */
            list: PropTypes.arrayOf(PropTypes.shape({})),
            /** Is the list of users currently being loaded? */
            loading: PropTypes.bool
        }),
        /** All users registered in HSPA. */
        allSystemUsers: PropTypes.shape({
            /** The array of all users in HSPA. */
            list: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        /** Called when a user is selected for editing. */
        changeTab: PropTypes.func,
        /** Called on mount to request the current user's access options. */
        getAccessOptionsExistingUser: PropTypes.func,
        /** Called on mount to request the list of all users in a particular VISN. */
        usersInNetworkFetchList: PropTypes.func,
        /** Called on mount to request the list of all users registered in HSPA. */
        allSystemUsersFetchList: PropTypes.func,
        /** Called when a user is selected for editing. */
        editExistingUser: PropTypes.func,
        /** Called when a user is selected for deletion. */
        deleteUser: PropTypes.func,
        /** Called when a user's role assignment is selected for deletion. */
        deleteRole: PropTypes.func
    };

    static defaultProps = {
        currentVISN: {},
        user: { currentMarket: {}, currentVisn: {} },
        usersInNetwork: { list: [], loading: false },
        allSystemUsers: { list: [] },
        changeTab: () => {},
        getAccessOptionsExistingUser: () => {},
        usersInNetworkFetchList: () => {},
        allSystemUsersFetchList: () => {},
        editExistingUser: () => {},
        deleteUser: () => {},
        deleteRole: () => {},
        isAdmin: false,
        isVISNPlanner: false
    };

    /**
     * Called when the component is first mounted.  Ensures that requests are made for data used by
     * this component.
     */
    componentDidMount() {
        const {
            user,
            usersInNetwork,
            allSystemUsers,
            getAccessOptionsExistingUser,
            usersInNetworkFetchList,
            allSystemUsersFetchList
        } = this.props;

        getAccessOptionsExistingUser();

        if (user.currentMarket.MarketName && usersInNetwork.list.length === 0) {
            usersInNetworkFetchList(user.currentVisn.VisnCode);
        }
        if (allSystemUsers.list.length === 0) {
            allSystemUsersFetchList();
        }
    }

    /**
     * When the component is updated, check if the VISN has changed.  If so, submit a request for
     * the users in the VISN.
     *
     * @param {object} previousProps - the props from the previous update
     */
    componentDidUpdate(previousProps) {
        const { user, usersInNetworkFetchList } = this.props;
        const { user: previousUser } = previousProps;

        if (previousUser.currentVisn.VisnCode !== user.currentVisn.VisnCode) {
            usersInNetworkFetchList(user.currentVisn.VisnCode);
        }
    }

    /**
     * Called when a user is selected for editing.
     *
     * @param {number} id - ID of the user
     */
    editUser = (id) => {
        const { editExistingUser, changeTab } = this.props;

        editExistingUser(id);
        changeTab(0);
    };

    /**
     * Delete the specified user from the current VISN.
     *
     * @param {number} id - ID of the user
     */
    deleteUser = (id) => {
        const { currentVISN, deleteUser } = this.props;

        deleteUser(id, currentVISN.VisnCode);
    };

    /**
     * Delete the role assignment from a user.
     *
     * @param {number} id - ID of the role assignment
     */
    deleteRole = (id) => {
        const { currentVISN, deleteRole } = this.props;
        deleteRole(id, currentVISN.VisnCode);
    };

    /**
     * Render the Users in Network component.
     *
     * @returns {React.ReactNode} the rendered element
     */
    render() {
        const { isAdmin, isVISNPlanner, currentVISN, usersInNetwork } = this.props;

        // Filter out the application administrators from the result set
        const users = _filter(usersInNetwork.list, (user) => {
            const hasAdminRole = hasRoleInVISN(user, ADMINISTRATOR_ROLE, currentVISN.VisnCode);
            return !(hasAdminRole && !isAdmin);
        });

        return (
            <div>
                <Instructions />
                <br />
                <div>
                    <ReactDataTable
                        keyValue="Id"
                        list={users}
                        columns={UsersInNetworkColumnDefs(
                            (id) => this.editUser(id),
                            (id) => this.deleteUser(id)
                        )}
                        csvFileName={`${currentVISN.VisnCode}-Users.csv`}
                        loading={usersInNetwork.loading}
                        expandContent={{
                            renderer: (row) => (
                                <div>
                                    <ReactDataTable
                                        keyValue="Id"
                                        list={_isEmpty(row.UserGroups) ? [] : row.UserGroups}
                                        columns={UserGroupColumnDefs(
                                            (group) => `VISN ${group.VisnId}`,
                                            this.deleteRole,
                                            isAdmin,
                                            isVISNPlanner,
                                            currentVISN
                                        )}
                                        isExportable={false}
                                        isSearchable={false}
                                        isPageable={false}
                                    />
                                </div>
                            ),
                            canRender: (row) => true,
                            showExpandColumn: true,
                            expandByColumnOnly: true
                        }}
                    />
                </div>
                <GrantAccessForm />
            </div>
        );
    }
}

/**
 * Map state values to props for this component.
 *
 * @param {object} state - application redux state
 * @returns {object} an object whose properties will be passed as props to the component
 */
const mapStateToProps = (state) => ({
    allSystemUsers: state.ManageUserModel.allSystemUsers,
    usersInNetwork: state.ManageUserModel.usersInNetwork,
    user: state.UserSessionModel,
    visnList: getVISNList(state),
    accessOptions: state.ManageUserModel.accessOptionsExistingUser,
    currentVISN: getCurrentVISN(state),
    isVISNPlanner: isPlannerForCurrentVISN(state)
});

/**
 * Map dispatch to function props the component can call to update the redux state.
 *
 * @param {Function} dispatch - a function to dispatch actions to the redux state
 * @returns {object} an object whose function properties will be passed as props to the component
 */
const mapDispatchToProps = (dispatch) => ({
    allSystemUsersFetchList: dispatch.ManageUserModel.fetchAllSystemUsersAsync,
    usersInNetworkFetchList: dispatch.ManageUserModel.fetchUsersInNetworkAsync,
    deleteUser: (userId, visnCode) =>
        dispatch.ManageUserModel.deleteUserAsync({ userId, visnCode }),
    deleteRole: (id, visnCode) => dispatch.ManageUserModel.deleteUserRoleAsync({ id, visnCode }),
    getAccessOptionsExistingUser: dispatch.ManageUserModel.fetchExistingUserAccessOptionsAsync,
    editExistingUser: dispatch.ManageUserModel.populateUserForm
});

export default connect(mapStateToProps, mapDispatchToProps)(SAUsersInNetwork);
