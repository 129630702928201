import React from 'react';

import { COST_DATA_DESCRIPTION_PATH } from 'Utilities/filePaths';

import ActionContent from './ActionContent';
import PDFButton from './PDFButton';

/**
 * A React component to display info about, and a button to download, the Cost Data Description
 * document.
 *
 * @returns the rendered component
 */
const CostDataDescriptionContent = (): JSX.Element => (
    <ActionContent
        content={
            <>
                <div>
                    The Office of Enrollment &amp; Forecasting developed the base year 2019 unit
                    costs for the VA Enrollee Health Care Projection Model (EHCPM), intended to be
                    used for the Market Area Health Systems Optimization (MAHSO) Cost-Benefit
                    Analysis.
                </div>
                <div>This document defines how this cost data was derived.</div>
            </>
        }
        button={<PDFButton url={COST_DATA_DESCRIPTION_PATH} title="Cost Data Description" />}
    />
);

export default CostDataDescriptionContent;
