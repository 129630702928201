/**
 * Displays a table containing the capacity projections
 * for the specified plan
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import './style.css';

import React from 'react';
import { useSelector } from 'react-redux';

import _isEmpty from 'lodash/isEmpty';

import Button from 'Lib/Button';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import {
    getSupplyOverheadData,
    getSupplyOverheadDataForCost
} from 'Modules/MatchCapacityDemand/Services/selector';
import PropTypes from 'prop-types';

import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import MCDPlannerWorkbenchDrilldown from '../../MCDPlannerWorkbenchDrilldown';
import GenerateCapacityTableColumns from './MCDPWSupplyTableColumns';

const MCDPWSupplyTable = React.memo(
    ({
        calculationType,
        canEdit,
        currentPlan,
        defaultYear,
        toggleJustificationModal,
        updateWorkspaceData,
        resetPlanRow,
        tableType = 'Supply'
    }) => {
        const tableData =
            calculationType === 'Cost'
                ? useSelector((state) => getSupplyOverheadDataForCost(state, calculationType))
                : useSelector((state) => getSupplyOverheadData(state, calculationType));

        const supplyTableStyles = (row) => {
            if (row.RowName === 'Imbalance' || row.RowName === 'Total') {
                return 'matchSupplyTotals';
            }
            if (row.SupplyFee === true) {
                return 'matchSupplyFee';
            }
            return '';
        };

        const expandComponent = (row) => {
            if (row.RowName === 'VA Current and Projected In-House Utilization') {
                return (
                    <MCDPlannerWorkbenchDrilldown
                        calculationType={calculationType}
                        canEdit={canEdit}
                        filter="Current Projected Utilization"
                        isEditable
                        updateWorkspaceData={updateWorkspaceData}
                        tableType="Supply"
                    />
                );
            }
            if (row.RowName === 'Community Providers') {
                return (
                    <MCDPlannerWorkbenchDrilldown
                        calculationType={calculationType}
                        canEdit={canEdit}
                        filter="Community Providers"
                        isEditable
                        updateWorkspaceData={updateWorkspaceData}
                        tableType="Supply"
                    />
                );
            }
            if (row.RowName === 'Federal Partners' && calculationType !== 'Cost') {
                return (
                    <MCDPlannerWorkbenchDrilldown
                        calculationType={calculationType}
                        canEdit={canEdit}
                        filter="Federal Partners"
                        isEditable
                        updateWorkspaceData={updateWorkspaceData}
                        tableType="Supply"
                    />
                );
            }
            if (row.RowName === 'Affiliates' && calculationType !== 'Cost') {
                return (
                    <MCDPlannerWorkbenchDrilldown
                        calculationType={calculationType}
                        canEdit={canEdit}
                        filter="Affiliates"
                        isEditable
                        updateWorkspaceData={updateWorkspaceData}
                        tableType="Supply"
                    />
                );
            }
            return '';
        };

        const columnClassNameFormat = (fieldValue, row, rowIndex, columnIndex) => {
            if (_isEmpty(currentPlan.planData)) {
                return 'text-right';
            }
            if (row.RowName === 'Total') {
                const upperDemand = currentPlan.planData.OverheadRows.find(
                    (x) => x.RowName === 'Upper Demand Estimate'
                );
                const lowerDemand = currentPlan.planData.OverheadRows.find(
                    (x) => x.RowName === 'Lower Demand Estimate'
                );
                if (!(upperDemand && lowerDemand)) {
                    return 'text-right';
                }
                if (
                    fieldValue > upperDemand[`Year${columnIndex - 1}`] ||
                    fieldValue < lowerDemand[`Year${columnIndex - 1}`]
                ) {
                    return 'text-right aboveBelowDemand';
                }
            }
            return 'text-right';
        };

        const justificationBtn = (cell, row) => {
            if (calculationType !== 'General' || !row.SupplyFee) {
                return '';
            }
            // TODO: Find/create a link-styled button component
            return (
                <a data-toggle="tooltip" data-placement="top" title="Add Justification">
                    <Button
                        color="success"
                        type="button"
                        label=""
                        title="Edit Justification"
                        icon="edit"
                        btnPaddingTop={1}
                        btnPaddingBottom={1}
                        size="btn-sm"
                        onClick={() => toggleJustificationModal(row)}
                    />
                </a>
            );
        };

        const resetRow = (cell, row) => {
            if (calculationType !== 'General' || !row?.SupplyFee) {
                return '';
            }

            const tableObj = calculationType === 'Staffing' ? `Staffing${tableType}` : tableType;

            return (
                <a data-toggle="tooltip" data-placement="top" title="Reset Row">
                    <Button
                        color="warning"
                        type="button"
                        label=""
                        title="Reset Row"
                        icon="undo"
                        btnPaddingTop={1}
                        btnPaddingBottom={1}
                        disabled={!canEdit}
                        size="btn-sm"
                        onClick={() =>
                            resetPlanRow(
                                currentPlan.planData.Plan.Id,
                                row.Id,
                                tableObj,
                                row.RowName
                            )
                        }
                    />
                </a>
            );
        };

        const cellEditOptions = {
            canSaveCell: (_value, row) => (row.RowName === 'VA Fee'),
            afterSaveCell: (_oldValue, newValue, row, column) => {
                const tableObj =
                    calculationType === 'Staffing' ? `Staffing${tableType}` : tableType;
                updateWorkspaceData(tableObj, row.Id, column.id, newValue, 'VA Fee');
            }
        };

        const columns = GenerateCapacityTableColumns(
            defaultYear,
            calculationType,
            justificationBtn,
            resetRow,
            columnClassNameFormat
        );

        return (
            <ReactDataTable
                list={tableData}
                loading={currentPlan.loading}
                columns={columns}
                isExportable={false}
                isSearchable={false}
                isPageable={false}
                isSortable={false}
                striped={false}
                hover={false}
                isEditable={calculationType !== 'Staffing'}
                cellEditOptions={calculationType !== 'Staffing' ? cellEditOptions : undefined}
                rowClasses={supplyTableStyles}
                keyValue="RowName"
                expandContent={{
                    canRender: (row) => row.IsExpandable,
                    renderer: expandComponent,
                    expandedIcon: faCaretDown,
                    collapsedIcon: faCaretRight
                }}
                containerStyle={{ height: '100%', marginBottom: '-14px' }}
            />
        );
    }
);

MCDPWSupplyTable.propTypes = {
    calculationType: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    currentPlan: PropTypes.shape({
        loading: PropTypes.bool,
        planData: PropTypes.shape({
            Plan: PropTypes.shape({
                Id: PropTypes.number
            }),
            OverheadRows: PropTypes.arrayOf(PropTypes.shape({}))
        })
    }).isRequired,
    defaultYear: PropTypes.number.isRequired,
    toggleJustificationModal: PropTypes.func.isRequired,
    updateWorkspaceData: PropTypes.func.isRequired,
    resetPlanRow: PropTypes.func.isRequired,
    tableType: PropTypes.string.isRequired
};

export default MCDPWSupplyTable;
