import React from 'react';
import Chart from './Chart';
import Bar from './Bar';

const BarChart = ({ data, color }) => {
    const itemWidth = 13;
    const itemMargin = 3;
    const dataLength = data.length;
    const massagedData = data.map(item =>
        Object.assign({}, item, { repos: item.value})
    );
    const mostRepos = massagedData.reduce((acc, cur) => {
        const { repos } = cur;
        return repos > acc ? repos : acc
    }, 0);
    const chartHeight = mostRepos * 0.25;
    return (
        <Chart
            width={dataLength * (itemWidth + itemMargin)}
            height={chartHeight}
        >
            {massagedData.map((datum, index) => {
                const itemHeight = isNaN(datum.repos) ? 0 : datum.repos
                return (
                    <Bar
                        key={index}
                        x={index * (itemWidth + itemMargin)}
                        y={chartHeight - (itemHeight * 0.25)}
                        width={itemWidth}
                        height={itemHeight}
                        color={color}
                    />
                )
            })}
        </Chart>
    )
}
export default BarChart;