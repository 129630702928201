import { LoginType, MsalAuthProvider } from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: `${process.env.REACT_APP_LOGIN_AUTHORITY}/${process.env.REACT_APP_TENANT_ID}`,
        clientId: process.env.REACT_APP_CLIENT_ID,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`]
};

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.protocol}//${window.location.host}/auth.html`
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
