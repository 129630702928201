/**
 * Displays a table containing the solution calculations
 * for the specified plan
 * @author Anthony P. Pancerella
 */
import React from 'react';
import { useSelector } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import proptypes from 'prop-types';

import { getSolutionsOverheadData } from '../../../Services/selector';
import GenerateSolutionTableColumns from './MCDPWSolutionsTableColumns';

const MCDPWSolutionsTable = React.memo(
    ({ calculationType, currentPlan, defaultYear, tableType = 'Solutions' }) => {
        const tableData = useSelector((state) => getSolutionsOverheadData(state, calculationType));

        const columns = GenerateSolutionTableColumns(defaultYear, calculationType);

        return (
            <ReactDataTable
                list={tableData}
                columns={columns}
                loading={currentPlan.loading}
                isExportable={false}
                isSearchable={false}
                isPageable={false}
                isSortable={false}
                striped={false}
                hover={false}
                keyValue="RowName"
                containerStyle={{ height: '100%' }}
            />
        );
    }
);

MCDPWSolutionsTable.propTypes = {
    currentPlan: proptypes.object.isRequired,
    defaultYear: proptypes.number.isRequired,
    calculationType: proptypes.string.isRequired,
    tableType: proptypes.string
};

export default MCDPWSolutionsTable;
