import numeral from 'numeral';

export const commaFormatter = (val) => {
    if (val === null || val === '') return 0;
    return numeral(val).format('0,0');
};

export const commaNAFormatter = (val) => {
    if (val === null || val === '') return 0;
    if (val < 0) return 'N/A';
    return numeral(val).format('0,0');
};

export const commaDecimalFormatter = (val) => {
    if (val === null || val === '') return 0;
    return numeral(val).format('0,0.00');
};

export const commaCurrencyFormatter = (val) => {
    if (val === null || val === '') return 0;
    return numeral(val).format('$0,0.00');
};

export const singlePlaceDecimalFormatter = (val) => {
    if (val === null || val === '') return 0;
    return numeral(val).format('0,0.0');
};

export const percentFormatter = (val) => {
    if (val === null || val === '') return 0;
    return numeral(val).format('0%');
};

export const decimalFormatter = (val) => {
    if (val === null || val === '') return '';
    return numeral(val).format('0.00');
};

export const percentDecimalFormatter = (val) => {
    if (val === null || val === '') return '';
    return numeral(val).format('0.00%');
};

export const dateFormatter = (val) => {
    if (val === null || val === '') return '';
    var date = new Date(val);
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
};

export const visnFormatter = (val) => {
    if (val === null || val === '') return '';

    return val > 9 ? 'V' + val : 'V0' + val;
};

export const enumFormatter = (cell, row, enumObject) => {
    return enumObject[cell];
};

export const stringToHash = (string) => {
    let hash = 0;
    if (!string || string.length === 0) {
        return hash;
    }

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash;
    }

    return hash;
};
