import { getSpecialtySelections } from 'Data/capital-projects';
import epicRequest from 'Utilities/epicRequest';

import { from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    loadHspcSelectionDone,
    loadHspcSelectionError,
    loadHspcSelections as loadHspcSelectionsAction,
    loadHspcSelectionStart,
    setHspcSelections
} from '../hspcSelectionSlice';

/**
 * Listens for loadHspcSelections actions and requests capital project types from the API.
 *
 * @param action$ - A stream of the redux actions dispatched to the application's redux store.
 * @returns Actions to set capital project types on load.
 */
const loadHspcSelections = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        filter(loadHspcSelectionsAction.match),
        distinctUntilChanged((previous, current) => previous.payload === current.payload),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Request capital project types and emit a setCapitalProjectTypes action when acquired.
             *
             * @param _ - Placeholder for the PayloadAction.
             * @returns An observable of redux actions.
             */
            request: (_: PayloadAction) =>
                from(getSpecialtySelections()).pipe(
                    map((hspcSelections) => setHspcSelections({ types: hspcSelections }))
                ),
            actions: {
                start: loadHspcSelectionStart,
                done: loadHspcSelectionDone,
                fail: loadHspcSelectionError
            }
        })
    );

export default loadHspcSelections;
