import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON } from 'Utilities/apiCalls';
import { SURVEY_OF_ENROLLEES_YEAR } from 'Utilities/constants';

const envscanApi = `${process.env.REACT_APP_HSPA_API}/EnvironmentalScan/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const SurveyDataModel = {
    state: {
        surveyResults: { list: [], loading: false, generating: false },
        surveyQuestions: { list: [], loading: false, generating: false }
    },
    reducers: {
        populate(state, data, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: !state[tableName].loading
                }
            };
        },
        updateGenerating(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    generating: !state[tableName].generating
                }
            };
        },
        resetTable(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false,
                    generating: false
                }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchSurveyResultsAsync(payload, state) {
            try {
                this.resetTable('surveyResults');
                this.updateLoading('surveyResults');
                let result = await getJSON(
                    `${envscanApi}GetSurveyDataByQuestion?market=` +
                        payload.market +
                        '&question=' +
                        payload.question +
                        '&year=' +
                        SURVEY_OF_ENROLLEES_YEAR
                );
                this.populate(result, 'surveyResults');
                this.updateLoading('surveyResults');
            } catch (error) {
                this.updateLoading('surveyResults');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch survey results',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchSurveyQuestionsAsync(state) {
            try {
                this.resetTable('surveyQuestions');
                this.updateLoading('surveyQuestions');
                let result = await getJSON(
                    `${envscanApi}GetSurveyQuestions?year=` + SURVEY_OF_ENROLLEES_YEAR
                );
                this.populate(result, 'surveyQuestions');
                this.updateLoading('surveyQuestions');
            } catch (error) {
                this.updateLoading('surveyQuestions');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch survey questions',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generateExcelDocAsync(market, state) {
            try {
                this.updateGenerating('surveyResults');
                await downloadFile(
                    `${exportApiUrl}ExportSurveyData?market=` +
                        market +
                        '&year=' +
                        SURVEY_OF_ENROLLEES_YEAR
                );
                this.updateGenerating('surveyResults');
            } catch (error) {
                this.updateGenerating('surveyResults');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate report ' + error,
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
