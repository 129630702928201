import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const envscanApi = `${process.env.REACT_APP_HSPA_API}/EnvironmentalScan/`;

export const FortyMileVeteransModel = {
    state: {
        list: [],
        loading: false
    },
    reducers: {
        populate(state, data) {
            return {
                ...state,
                list: data
            };
        },
        updateLoading(state, isLoading) {
            return {
                ...state,
                loading: isLoading
            };
        },
        reset(state) {
            return {
                ...state,
                list: [],
                loading: false
            };
        }
    },
    effects: (dispatch) => ({
        async fetchDataAsync(market, state) {
            try {
                this.reset();
                this.updateLoading(true);
                let result = await getJSON(`${envscanApi}GetDistanceEligible?marketname=${market}`);
                this.populate(result);
                this.updateLoading(false);
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch distance eligible',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
