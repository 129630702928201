import React from 'react';
import Table from 'react-bootstrap/Table';

/**
 * The component for an HSPC group table in a facility report.
 *
 * @author Brandan D. Reed
 */
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import styled from 'styled-components/macro';

import PropTypes from 'prop-types';

import HSPCRow from './HSPCRow';

const TotalRow = styled(HSPCRow)`
    & > td {
        background-color: #ffffe0;
    }
`;

const createProjectionObject = () => ({
    Year0: 0,
    Year1: 0,
    Year2: 0,
    Year3: 0,
    Year4: 0,
    Year5: 0,
    Year6: 0,
    Year7: 0,
    Year8: 0,
    Year9: 0,
    Year10: 0
});

const generateTotalRow = (rows, label) => {
    return _reduce(
        rows,
        (summingRow, row) => {
            for (let baseYearOffset = 0; baseYearOffset <= 10; baseYearOffset++) {
                if (row.Capacity) {
                    summingRow.Capacity[`Year${baseYearOffset}`] +=
                        row.Capacity[`Year${baseYearOffset}`];
                }
                if (row.Supply) {
                    summingRow.Supply[`Year${baseYearOffset}`] +=
                        row.Supply[`Year${baseYearOffset}`];
                }
                if (row.Staffing) {
                    summingRow.Staffing.APP = summingRow.Staffing.APP || createProjectionObject();
                    summingRow.Staffing.MD = summingRow.Staffing.MD || createProjectionObject();
                    summingRow.Staffing.APP[`Year${baseYearOffset}`] +=
                        row.Staffing.APP[`Year${baseYearOffset}`];
                    summingRow.Staffing.MD[`Year${baseYearOffset}`] +=
                        row.Staffing.MD[`Year${baseYearOffset}`];
                }
                if (row.Space) {
                    summingRow.Space[`Year${baseYearOffset}`] += row.Space[`Year${baseYearOffset}`];
                }
                if (row.Cost && baseYearOffset > 5) {
                    const costOffset = baseYearOffset - 6;
                    summingRow.Cost[costOffset] = summingRow.Cost[costOffset] || {
                        Overhead: 0,
                        ResearchAndEducation: 0,
                        VaSpecificIndirect: 0,
                        Indirect: 0,
                        FixedDirect: 0,
                        SpecificDirect: 0,
                        Direct: 0
                    };

                    const summingCost = summingRow.Cost[costOffset];
                    const rowCost = row.Cost[costOffset];

                    summingCost.Overhead += rowCost.Overhead;
                    summingCost.ResearchAndEducation += rowCost.ResearchAndEducation;
                    summingCost.VaSpecificIndirect += rowCost.VaSpecificIndirect;
                    summingCost.Indirect += rowCost.Indirect;
                    summingCost.FixedDirect += rowCost.FixedDirect;
                    summingCost.SpecificDirect += rowCost.SpecificDirect;
                    summingCost.Direct += rowCost.Direct;
                }
            }
            return summingRow;
        },
        {
            HSPC: { Hspc: label },
            Capacity: createProjectionObject(),
            Supply: createProjectionObject(),
            Staffing: {},
            Space: createProjectionObject(),
            Cost: [0, 0, 0, 0, 0],
            isTotal: true
        }
    );
    /* eslint-enable no-param-reassign */
};

/**
 * Represents a section for an HSPC group in a facility report.
 *
 * @param {object} props - props passed into this component
 * @returns the rendered section
 */
const HSPCGroupSection = ({ title, units, rows, baseYear, hideTotalsRow }) => {
    const yearHeaders = [];
    for (let index = 0; index <= 10; ++index) {
        yearHeaders.push(
            <th key={index} style={{ textAlign: 'right' }}>
                {baseYear + index}
            </th>
        );
    }

    const allRows = [...rows];

    if (!hideTotalsRow) {
        allRows.push(generateTotalRow(rows, `Total ${units}`));
    }

    const rowComponents = _map(allRows, (row) => {
        const RowComponent = row.isTotal ? TotalRow : HSPCRow;
        return (
            <RowComponent
                key={row.HSPC.Hspc}
                hspc={row.HSPC.Hspc}
                capacity={row.Capacity}
                supply={row.Supply}
                staffing={row.Staffing}
                space={row.Space}
                cost={row.Cost}
            />
        );
    });

    return (
        <div>
            <h4>{title}</h4>
            <Table striped bordered hover className="table-center-content">
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>HSPC</th>
                        <th colSpan="3"> </th>
                        {yearHeaders}
                    </tr>
                </thead>
                <tbody>{rowComponents}</tbody>
            </Table>
        </div>
    );
};

HSPCGroupSection.propTypes = {
    /** Title of the section. */
    title: PropTypes.string.isRequired,
    /** Unit of measurement for the HSPC group. */
    units: PropTypes.string.isRequired,
    /** Rows of data. */
    rows: PropTypes.arrayOf({}),
    /** The base year of the projections. */
    baseYear: PropTypes.number.isRequired,
    /** Should the totals row be displayed? */
    hideTotalsRow: PropTypes.bool
};

HSPCGroupSection.defaultProps = {
    rows: [],
    hideTotalsRow: false
};

export default HSPCGroupSection;
