import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './Content/css/bootstrapEdits.css';
import './Content/css/index.css';
import './Content/css/verticalTabs.css';
import 'video-react/dist/video-react.css';
import 'style.css';

import React from 'react';
import { AzureAD } from 'react-aad-msal';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';

import { authProvider } from './authProvider';
import HSPANotifications from './Modules/App/HSPANotifications';
import AppStart from './Modules/AppStart';
import * as serviceWorker from './serviceWorker';
import store from './store';
import analytics from './Utilities/analytics';

require('intersection-observer');

const analyticsObj = analytics.getInstance();

window.addEventListener(
    'beforeunload',
    () => {
        analyticsObj.sendLogReport();
    },
    false
);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AzureAD provider={authProvider} forceLogin style={{ display: 'flex' }}>
                <CookiesProvider>
                    <ProSidebarProvider>
                        <HSPANotifications />
                        <AppStart />
                     </ProSidebarProvider>
                </CookiesProvider>
            </AzureAD>
        </Router>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
