import { MarketDDFContent } from 'Modules/SlideData/DDFSlides';
import React from 'react';

interface reportPaneProps {
    slide: MarketDDFContent;
}

function DDFReportPane(props: reportPaneProps) {
    const order = props.slide.ddfSlideNumber;

    return (
        <iframe
            title={props.slide.concatenatedKey}
            className="embed-responsive-item"
            width="1140"
            src={
                props.slide.isGIS
                    ? `${process.env.REACT_APP_GIS_URL}/apps/webappviewer/index.html${encodeURI(
                          props.slide.powerBIContent.url
                      )}`
                    : `${process.env.REACT_APP_POWER_BI_URL}/reportEmbed?autoAuth=true&${encodeURI(
                          props.slide.powerBIContent.url
                      )}`
            }
            allowFullScreen={true}
        ></iframe>
    );
}

export default DDFReportPane;
