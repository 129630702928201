/**
 * @file A generic Button component for taking advantage of Bootstrap css.
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import FontAwesome from 'react-fontawesome';

import PropTypes from 'prop-types';

/**
 * A simple button component.
 *
 * @param {object} props - props passed into this component to control the appearance of the button
 * @returns {React.ReactNode} the button component
 */
const Button = (props) => {
    const {
        color,
        type,
        label,
        title,
        disabled,
        btnPaddingTop,
        btnPaddingBottom,
        onClick,
        icon,
        size,
        style,
        hidden
    } = props;

    const classNames = ['btn', `btn-${color}`, size];
    return (
        <button
            className={classNames.join(' ')}
            type={type}
            title={title}
            aria-label={title}
            alt={title}
            disabled={disabled}
            style={{ paddingTop: btnPaddingTop, paddingBottom: btnPaddingBottom, ...style }}
            onClick={(...rest) => onClick && onClick(...rest)}
            hidden={hidden}
        >
            {icon === '' ? label : <FontAwesome name={icon} />}
        </button>
    );
    /* eslint-enable react/button-has-type */
};

Button.propTypes = {
    color: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    btnPaddingTop: PropTypes.number,
    btnPaddingBottom: PropTypes.number,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    style: PropTypes.shape({}),
    hidden: PropTypes.bool
};

Button.defaultProps = {
    color: 'primary',
    type: 'button',
    label: '',
    title: '',
    disabled: false,
    btnPaddingTop: 6,
    btnPaddingBottom: 6,
    onClick: null,
    icon: '',
    size: '',
    style: {},
    hidden: false
};

export default Button;
