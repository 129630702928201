import { HSPAState } from 'storeTypes';

import { createSelector } from '@reduxjs/toolkit';

/**
 * Get the capital project slice of state from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The capital project slice of state.
 */
const selectCapitalProjectsState = (state: HSPAState) => state.capitalProjects;

/**
 * Get the list of capital projects from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The list of capital projects.
 */
export const selectCapitalProjects = createSelector(
    selectCapitalProjectsState,
    (capitalProjectsState) => capitalProjectsState?.items || []
);

/**
 * Get the capital projects loading status from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The loading status.
 */
export const selectCapitalProjectsLoadingStatus = createSelector(
    selectCapitalProjectsState,
    (capitalProjectsState) => capitalProjectsState?.loadingStatus
);

/**
 * Get the capital projects create status from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The create status.
 */
export const selectCapitalProjectsCreateStatus = createSelector(
    selectCapitalProjectsState,
    (capitalProjectsState) => capitalProjectsState?.createStatus
);

/**
 * Get the capital projects delete status from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The delete status.
 */
export const selectCapitalProjectsDeleteStatus = createSelector(
    selectCapitalProjectsState,
    (capitalProjectsState) => capitalProjectsState?.deleteStatus
);

/**
 * Get the capital projects market from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The market associated with the currently loaded opportunities.
 */
export const selectCapitalProjectsMarket = createSelector(
    selectCapitalProjectsState,
    (capitalProjectsState) => capitalProjectsState?.marketId
);

/**
 * Get the capital projects approval status from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The market associated with the currently loaded opportunities.
 */
export const selectCapitalProjectsApprovalStatus = createSelector(
    selectCapitalProjectsState,
    (capitalProjectsState) => capitalProjectsState?.approvalStatus
);
