import React, { ReactNode } from 'react';

import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NoWrapButton from './NoWrapButton';

interface ExcelButtonProps {
    url: string;
    title: ReactNode;
}

/**
 * Render a button used for downloading Excel files.  For now, this is only used for the Space
 * Estimator Tool spreadsheets.
 *
 * This may be moved upwards in the directory hierarchy if used elsewhere.
 *
 * @param props - props passed into the component
 * @param props.url - the path to the Excel file
 * @param props.title - the label to display on the button
 * @returns the rendered button
 */
const ExcelButton: React.FC<ExcelButtonProps> = ({ url, title }) => (
    <NoWrapButton variant="success" href={url}>
        <FontAwesomeIcon icon={faFileExcel} size="lg" className="mr-2" />
        {title}
    </NoWrapButton>
);

export default ExcelButton;
