import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { groupBy } from 'lodash';

import slidesSource from 'Modules/SlideData/index';
import { getCurrentMarket } from 'Modules/UserSession/selector';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import DDFModal from './DDFModal';

interface SubSection {
    sectionHeader: string;
    sectionSlides: number[];
    isFacilityReport?: boolean;
}

interface Report {
    title: string;
    slideNumbers?: number[];
    slideSection?: number;
    facilityId: string;
    subSections: SubSection[];
}

interface DDFContainerProps {
    text?: string;
    iconSize?: SizeProp;
    reports: Report[];
}

const defaultProps = {
    text: '',
    iconSize: '2x' as SizeProp,
    reports: []
};

/**
 * A container that shows the related DD&F slides.
 *
 * @param props - props passed into this component
 *
 * @returns the rendered component
 */
function DDFContainer({
    reports,
    iconSize,
    text
}: DDFContainerProps = defaultProps): JSX.Element | null {
    const slides = slidesSource;

    const selectedMarket = useSelector(getCurrentMarket).MarketId;
    const marketSlides =
        selectedMarket !== undefined ? slides.find((x) => x.market.id == selectedMarket) : null;

    const slidesByNumber = useMemo(
        () => groupBy(marketSlides?.marketDDFContent, 'ddfSlideNumber'),
        [marketSlides]
    );
    const sections = useMemo(
        () =>
            reports.map(({ title, subSections, slideNumbers }) => {
                const sections = slideNumbers
                    ? [
                          {
                              sectionHeader: title,
                              sectionSlides: slideNumbers
                                  .map((id) => slidesByNumber[id])
                                  .filter((slideGroup) => !!slideGroup)
                          }
                      ]
                    : subSections.map(({ sectionHeader, sectionSlides }) => ({
                          sectionHeader,
                          sectionSlides: sectionSlides
                              .map((id) => slidesByNumber[id])
                              .filter((slideGroup) => !!slideGroup)
                      }));

                const reportSections = sections.filter(
                    ({ sectionSlides }) => sectionSlides.length > 0
                );

                return { title, reportSections };
            }),
        [reports, slidesByNumber]
    );

    const reportButtons = sections.map((report) => {
        if (report.reportSections.length > 0) {
            return (
                <DDFModal
                    key={report.title}
                    title={report.title}
                    iconSize={iconSize}
                    reportSections={report.reportSections}
                />
            );
        }
    });

    // Cant see why this is still returning a single undefined element but tired of fighting it
    const hide =
        reportButtons.length === 0 ||
        (reportButtons.length === 1 && reportButtons[0] === undefined);

    const wrapper =
        text !== '' ? (
            <Alert
                role="note"
                variant={'warning'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <span style={{ flexGrow: 1 }}>{text}</span>
                {reportButtons}
            </Alert>
        ) : (
            <div>{reportButtons}</div>
        );

    if (!hide) {
        return <>{wrapper}</>;
    }
    return null;
}

export default DDFContainer;
