import { getCapitalProjectsByMarketId } from 'Data/capital-projects';
import epicRequest from 'Utilities/epicRequest';

import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    loadCapitalProjectDone,
    loadCapitalProjectError,
    loadCapitalProjects as loadCapitalProjectsAction,
    loadCapitalProjectStart,
    setCapitalProjects
} from '../capitalProjectsSlice';

/**
 * An epic that listens for loadCapitalProjects actions and requests capital projects from the API.
 *
 * @param action$ - A stream of the redux actions dispatched to the application's redux store.
 * @returns Actions to set capital projects on load.
 */
const loadCapitalProjects = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        filter(loadCapitalProjectsAction.match),
        //distinctUntilChanged((previous, current) => previous.payload === current.payload ),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Request capital projects by the specified market identifier and emits a
             * setCapitalProjects action.
             *
             * @param action - The action to take.
             * @param action.payload - The market identifier.
             * @returns An observable of redux actions.
             */
            request: ({ payload }: PayloadAction<number>) =>
                from(getCapitalProjectsByMarketId(payload)).pipe(
                    map((capitalProjects) =>
                        setCapitalProjects({ items: capitalProjects, marketId: payload })
                    )
                ),
            actions: {
                start: loadCapitalProjectStart,
                done: loadCapitalProjectDone,
                fail: loadCapitalProjectError
            }
        })
    );

export default loadCapitalProjects;
