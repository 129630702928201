import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON, putJSON } from 'Utilities/apiCalls';

const capacityApi = `${process.env.REACT_APP_HSPA_API}/UnderstandCapacity/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const MarketDescriptionModel = {
    state: {
        form: {},
        loading: false,
        saving: false,
        generating: false
    },
    reducers: {
        populate(state, data) {
            return {
                ...state,
                form: data
            };
        },
        toggleLoading(state) {
            return {
                ...state,
                loading: !state.loading
            };
        },
        toggleSaving(state) {
            return {
                ...state,
                saving: !state.saving
            };
        },
        toggleGenerating(state) {
            return {
                ...state,
                generating: !state.generating
            };
        },
        reset(state) {
            return {
                ...state,
                form: {}
            };
        }
    },
    effects: (dispatch) => ({
        async fetchMarketDescriptionAsync(market, state) {
            try {
                this.toggleLoading();
                let result = await getJSON(
                    `${capacityApi}GetMarketDescription?marketname=${market}`
                );
                this.populate(result);
                this.toggleLoading();
            } catch (error) {
                this.toggleLoading();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market description',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveMarketDescriptionAsync(formValues, state) {
            try {
                this.toggleSaving();
                await putJSON(`${capacityApi}UpdateCapitalAssetNarrative`, formValues);
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'The market description has been updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchMarketDescriptionAsync(formValues.MarketName);
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to update market description',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generateMarketDescriptionAsync(payload, state) {
            try {
                this.toggleGenerating();
                await downloadFile(
                    `${exportApiUrl}ExportMarketNarrativePPT?market=` +
                        payload.market +
                        '&visnCode=' +
                        payload.visnCode
                );
                this.toggleGenerating();
            } catch (error) {
                this.toggleGenerating();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate market description PowerPoint',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
