import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components/macro';

import ModuleNavigation from 'Lib/Tabs/RoutingModuleNavigation';
import HeaderContainer from 'Modules/App/HeaderContainer';
import analytics from 'Utilities/analytics';

import CapitalProjectTableCard from './CapitalProjectTableCard';
import MetricDashboard from './MetricDashboard';
import NationalDDFReport from './NationalDDFReport';
import OpportunityTableCard from './OpportunityTableCard';
import MetricModal from './SOMetricDetailsModal';

const analyticsObj = analytics.getInstance();

const ContentPane = styled.div`
    margin-top: 16px;
`;

/**
 * The top-level container for the Strategic Outlook module.
 *
 * @returns the content for the Strategic Outlook module
 */
const SOContainer = (): JSX.Element => {
    const { path } = useRouteMatch();
    const location = useLocation();

    const tabs = [
        { label: 'Enrollment', path: 'enrollment' },
        { label: 'Demand', path: 'demand' },
        { label: 'Market Assessment Recommendations', path: 'recommendations' },
        { label: 'Capital Projects', path: 'capitalprojects' },
        { label: 'National DDF Report', path: 'nationalddfreport' }
    ];

    const currentTab = tabs.find((tab) => tab.path === location.pathname.split('/').slice(-1)[0]);

    useEffect(() => {
        document.title = 'HSPA - Strategic Outlook';
        analyticsObj.trackPageChange({ pathName: 'StrategicOutlook' });
    }, []);

    useEffect(() => {
        if (currentTab) {
            analyticsObj.trackTabChange(currentTab.label);
        }
    }, [currentTab]);

    return (
        <div className="col-sm-12">
            <HeaderContainer pageName="Strategic Outlook" />
            <ModuleNavigation tabs={tabs} />
            <ContentPane>
                <Switch>
                    <Route path={`${path}/enrollment`}>
                        <MetricDashboard projectionType="Enrollment" />
                    </Route>
                    <Route path={`${path}/demand`}>
                        <MetricDashboard showsUtilizationMetrics projectionType="Demand" />
                    </Route>
                    <Route path={`${path}/recommendations`}>
                        <OpportunityTableCard />
                    </Route>
                    <Route path={`${path}/capitalprojects`}>
                        <CapitalProjectTableCard />
                    </Route>
                    <Route path={`${path}/nationalddfreport`}>
                        <NationalDDFReport />
                    </Route>
                    <Route>
                        <Redirect to={`${path}/enrollment`} />
                    </Route>
                </Switch>
            </ContentPane>
            <MetricModal />
        </div>
    );
};

export default SOContainer;
