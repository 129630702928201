/**
 * Displays a table of all facilities in the current market and links to open their facility-level
 * reports.  Dispatches actions to load the facilities for the current market.
 *
 * @author Brandan D. Reed
 */
import React from 'react';
import Fade from 'react-bootstrap/Fade';
import { useSelector } from 'react-redux';

import { getDefaultYear } from 'Modules/UserSession/selector';
import { AsyncState } from 'Utilities/constants';

import useFacilityList from '../useFacilityList';
import FacilityTable from './FacilityTable';

/**
 * React component that displays a table of all facilities in the current market and links to open
 * their facility-level reports.  Dispatches actions to load the facilities for the current market.
 *
 * @returns the rendered component
 */
const Facilities = () => {
    const baseYear = useSelector(getDefaultYear);

    const { facilities, loadingStatus } = useFacilityList(baseYear);

    const isLoaded = loadingStatus === AsyncState.SUCCESS;

    return (
        <Fade in={isLoaded}>
            <FacilityTable facilities={facilities} />
        </Fade>
    );
};

export default Facilities;
