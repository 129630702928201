import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { reduxForm, getFormValues, reset, Form } from 'redux-form';
import CheckboxField from 'Lib/ReduxFormFields/CheckboxField';
import { getCurrentMarketName, canEditVISN } from 'Modules/UserSession/selector';
import FormButtonGroup from '../RWFButtonGroup';

class RWFMatchCapacityDemand extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        formValues: PropTypes.shape({
            ReportId: PropTypes.number,
            ChkDcspTableUtil: PropTypes.bool,
            ChkDcspChartUtil: PropTypes.bool,
            ChkDcspJustificationUtil: PropTypes.bool,
            ChkDcspTableRvu: PropTypes.bool,
            ChkDcspChartRvu: PropTypes.bool,
            ChkDcspJustificationRvu: PropTypes.bool
        }).isRequired,
        submitting: PropTypes.bool,
        saving: PropTypes.bool,
        generating: PropTypes.bool,
        loading: PropTypes.bool,
        invalid: PropTypes.bool,
        pristine: PropTypes.bool,
        change: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        currentPage: PropTypes.number.isRequired,
        previousPage: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        marketName: PropTypes.string.isRequired,
        saveReport: PropTypes.func.isRequired,
        clearReportForm: PropTypes.func.isRequired,
        resetStepper: PropTypes.func.isRequired,
        generateReportById: PropTypes.func.isRequired,
        generateReportByFormData: PropTypes.func.isRequired
    };

    static defaultProps = {
        submitting: false,
        saving: false,
        generating: false,
        loading: false,
        invalid: false,
        pristine: true,
        canEdit: false
    };

    formGenerate = () => {
        const { formValues, generateReportById, generateReportByFormData } = this.props;

        if (formValues.ReportId) {
            generateReportById(formValues.ReportId);
        } else {
            generateReportByFormData(formValues);
        }
    };

    formSubmit = (values) => {
        const { marketName, dispatch, saveReport, clearReportForm, resetStepper } = this.props;

        saveReport(values, marketName);
        clearReportForm();
        dispatch(reset('ReportForm'));
        resetStepper();
    };

    selectAllCheck = () => {
        const { formValues, change } = this.props;

        change(
            'ChkDcspTableUtil',
            !formValues || !formValues.ChkDcspTableUtil ? true : !formValues.ChkDcspTableUtil
        );
        change(
            'ChkDcspJustificationUtil',
            !formValues || !formValues.ChkDcspJustificationUtil
                ? true
                : !formValues.ChkDcspJustificationUtil
        );
        change(
            'ChkDcspChartUtil',
            !formValues || !formValues.ChkDcspChartUtil ? true : !formValues.ChkDcspChartUtil
        );
        change(
            'ChkDcspTableRvu',
            !formValues || !formValues.ChkDcspTableRvu ? true : !formValues.ChkDcspTableRvu
        );
        change(
            'ChkDcspJustificationRvu',
            !formValues || !formValues.ChkDcspJustificationRvu
                ? true
                : !formValues.ChkDcspJustificationRvu
        );
        change(
            'ChkDcspChartRvu',
            !formValues || !formValues.ChkDcspChartRvu ? true : !formValues.ChkDcspChartRvu
        );
    };

    render() {
        const {
            submitting,
            saving,
            generating,
            loading,
            invalid,
            pristine,
            currentPage,
            canEdit,
            reset: resetForm,
            previousPage,
            handleSubmit
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.formSubmit)}>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckboxField
                                name="matchCapacityDemandSelectAll"
                                label="Select All"
                                onChangeFunction={this.selectAllCheck}
                            />
                        </div>
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header card-info">
                                    Demand, Capacity, and Supply Planner - Utilization
                                </div>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Table and Chart
                                </h6>
                                <div className="card-body">
                                    <CheckboxField
                                        name="ChkDcspTableRvu"
                                        label="Demand, Capacity, and Supply Planner Table"
                                    />
                                    <CheckboxField
                                        name="ChkDcspJustificationRvu"
                                        label="Demand, Capacity, and Supply Planner Justifications"
                                    />
                                    <CheckboxField
                                        name="ChkDcspChartRvu"
                                        label="Demand, Capacity, and Supply Planner Chart"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="col-sm-12" style={{ paddingTop: 15 }}>
                        <FormButtonGroup
                            formGenerate={this.formGenerate}
                            submitting={submitting}
                            previousPage={previousPage}
                            saving={saving}
                            generating={generating}
                            loading={loading}
                            invalid={invalid}
                            pristine={pristine}
                            reset={resetForm}
                            isFirstPage={currentPage === 0}
                            isLastPage={currentPage === 4}
                            canEdit={canEdit}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

const ReduxFormRWFMatchCapacityDemand = reduxForm({
    form: 'ReportForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.ReportName) {
            errors.ReportName = 'Report name is required';
        }
        if (!values.VisnCode) {
            errors.VisnCode = 'Visn is required';
        }
        if (!values.MarketName) {
            errors.MarketName = 'Market is required';
        }
        return errors;
    }
})(RWFMatchCapacityDemand);

const mapDispatchToProps = (dispatch) => {
    return {
        saveReport: (formValues, marketName) =>
            dispatch.ReportFormModel.saveReportAsync({
                formValues,
                marketName
            }),
        getReportDropdowns: (marketName, visnCode) =>
            dispatch.ReportFormModel.fetchDropdownOptionsAsync({
                marketName,
                visnCode
            }),
        clearReportForm: () => dispatch.ReportFormModel.resetTable('ReportForm'),
        generateReportById: dispatch.ReportFormModel.generateReportByIdAsync,
        generateReportByFormData: dispatch.ReportFormModel.generateReportByFormDataAsync
    };
};

const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ReportFormModel.ReportForm.data) ? 'create' : 'edit',
    formValues: getFormValues('ReportForm')(state),
    initialValues: state.ReportFormModel.ReportForm.data || {},
    saving: state.ReportFormModel.ReportForm.saving,
    generating: state.ReportFormModel.ReportForm.generating,
    loading: state.ReportFormModel.ReportForm.loading,
    marketName: getCurrentMarketName(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormRWFMatchCapacityDemand);
