import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const strategicOutlookApi = `${process.env.REACT_APP_HSPA_API}/StrategicOutlook/`;

const MetricPopupModel = {
    state: {
        show: false,
        graph: [],
        data: {},
        graph1: { data: [], loading: false },
        graph2: { data: [], loading: false }
    },
    reducers: {
        toggleShow(state, metric) {
            const soGraph = {
                name: `${metric.ProjectionTypeString} Projection`,
                type: 'line',
                data: [],
                zindex: 1
            };
            soGraph.data.push(metric.YearM1);
            soGraph.data.push(metric.Year0);
            for (let j = 1; j < 11; j++) {
                soGraph.data.push(metric[`Year${j}Projected`]);
            }
            return {
                ...state,
                show: !state.show,
                data: metric,
                graph: soGraph
            };
        },
        updateLoading(state, isLoading, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: isLoading
                }
            };
        },
        setProjectionData(state, data, object) {
            const lineGraphs = [];
            for (let i = 0; i < data.length; i++) {
                const line = {
                    name: data[i].Name,
                    type: 'line',
                    data: [],
                    zIndex: i + 2
                };
                if (data[i]['YearM1Total']) {
                    line.data.push(data[i]['YearM1Total']);
                }
                for (let j = 0; j < 11; j++) {
                    line.data.push(data[i][`Year${j.toString()}Total`]);
                }
                lineGraphs.push(line);
            }
            return {
                ...state,
                [object]: {
                    ...state[object],
                    data: lineGraphs
                }
            };
        },
        reset(state) {
            return {
                ...state,
                show: false,
                graph: [],
                data: {},
                graph1: { data: [], loading: false },
                graph2: { data: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchMetricModalDataAsync(payload) {
            let graphTypes = [];
            let graphApiData = [];
            try {
                if (payload.projectionType === 'Demand') {
                    graphTypes = ['Hspc', 'Facilities'];
                } else {
                    switch (payload.hspc) {
                        case 'Enrollment_Gender':
                            graphTypes = ['Gender'];
                            break;
                        case 'Enrollment':
                            graphTypes = ['Priority', 'Age'];
                            break;
                        case 'Eligible':
                            graphTypes = ['Priority', 'Age'];
                            break;
                        case 'Enrollment_Special_Conflict':
                            graphTypes = ['Age'];
                            break;
                        default:
                            dispatch(
                                addNotification({
                                    message: 'Error: Unable to fetch ',
                                    level: HSPANotificationLevel.ERROR
                                })
                            );
                    }
                }

                for (let i = 0; i < graphTypes.length; i++) {
                    graphApiData.push(
                        getJSON(
                            `${strategicOutlookApi}GetSOProjectionGraph?hspc=${payload.hspc}&market=${payload.market}&projectionType=${payload.projectionType}&graphType=${graphTypes[i]}`
                        )
                    );
                }
                const values = await Promise.all(graphApiData);

                for (let i = 0; i < values.length; i++) {
                    this.setProjectionData(values[i], `graph${i + 1}`);
                }
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch ',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};

export default MetricPopupModel;
