import { commaNAFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const createFYColumns = (groupYear, groupName, columnPrefix, csvPrefix) =>
    columnHelper.group({
        id: groupYear,
        header: groupName,
        enableHiding: false,
        enableSorting: false,
        meta: {
            headerDisplayName: groupName,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            cellStyle: { whiteSpace: 'normal' },
            csvExportIgnore: true
        },
        columns: [
            {
                accessorKey: `${columnPrefix}InHouse`,
                header: 'VA In-House',
                meta: {
                    headerDisplayName: `${csvPrefix} VA In-House`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-center',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                accessorKey: `${columnPrefix}Purchased`,
                header: 'Community Care',
                meta: {
                    headerDisplayName: `${csvPrefix} Community Care`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-center',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            }
        ]
    });

const FacilityColumns = (defaultYear, patientOriginModalBtn) => {
    if (!defaultYear || defaultYear.defaultYear === '') {
        return [];
    }

    return [
        {
            accessorKey: 'HspcGroup',
            header: 'HSPCG',
            enableHiding: false,
            meta: {
                headerDisplayName: 'HSPC Group',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                cellStyle: { whiteSpace: 'normal' }
            }
        },
        {
            accessorKey: 'Facility',
            header: 'Facility',
            enableHiding: false,
            cell: ({ getValue, row }) => patientOriginModalBtn(getValue(), row.original),
            meta: {
                headerDisplayName: 'Facility',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                cellStyle: { whiteSpace: 'normal' }
            }
        },
        createFYColumns(
            defaultYear.twoYearActual,
            `FY ${defaultYear.twoYearActual} Actual Utilization`,
            'ActualYear2',
            `FY ${defaultYear.twoYearActual} Actual Utilization`
        ),
        createFYColumns(
            defaultYear.oneYearActual,
            `FY ${defaultYear.oneYearActual} Actual Utilization`,
            'ActualYear0',
            `FY ${defaultYear.oneYearActual} Actual Utilization`
        ),
        createFYColumns(
            defaultYear.defaultYear,
            defaultYear.defaultYearWithFiscalStringBaselineUtilization,
            'Year0',
            defaultYear.defaultYearWithFiscalStringBaselineUtilization
        ),
        createFYColumns(
            defaultYear.fiveYearsOfDefault,
            defaultYear.fiveYearWithFiscalStringAllocationUtilization,
            'Year5',
            defaultYear.fiveYearWithFiscalStringAllocationUtilization
        ),
        createFYColumns(
            defaultYear.tenYearsOfDefault,
            defaultYear.tenYearWithFiscalStringAllocationUtilization,
            'Year10',
            defaultYear.tenYearWithFiscalStringAllocationUtilization
        ),
        createFYColumns(
            defaultYear.twentyYearsOfDefault,
            defaultYear.twentyYearWithFiscalStringAllocationUtilization,
            'Year20',
            defaultYear.twentyYearWithFiscalStringAllocationUtilization
        )
    ];
};

export default FacilityColumns;
