import React from 'react';
import proptypes from 'prop-types';
import { Field } from 'redux-form';
import './style.css';
export default class CheckboxField extends React.Component {
    render() {
        return (
            <Field
                name={this.props.name}
                label={this.props.label}
                disabled={this.props.disabled}
                type="checkbox"
                position={this.props.position}
                onChangeFunction={this.props.onChangeFunction}
                component={CheckboxField.renderCheckboxField}
            />
        );
    }
    static renderCheckboxField(props) {
        const handleChange = (event) => {
            if (props.onChangeFunction) props.onChangeFunction(props.input.name);
            props.input.onChange(event);
        };
        return (
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={props.input.name}
                    disabled={props.disabled}
                    onClick={handleChange}
                    aria-label={props.label}
                    {...props.input}
                />
                <label className="custom-control-label" htmlFor={props.input.name}>
                    {props.label}
                </label>
            </div>
            // <div className="checkbox">
            //     <div className={"col-sm-" + props.position}>
            //         <label><input type="checkbox" {...props.input} onChange={handleChange} disabled={props.disabled}/><strong>{props.label}</strong></label>
            //     </div>
            // </div>
        );
    }
    static propTypes = {
        name: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        disabled: proptypes.bool,
        position: proptypes.number,
        onChangeFunction: proptypes.func
    };
}
CheckboxField.defaultProps = {
    position: 12,
    onChangeFunction: null
};
