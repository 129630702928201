import React from 'react';

import Button from 'Lib/Button';
import { dateFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const VaFacilitiesColumnDefs = (showFacilityModal, deleteVaFacility, canEdit) => [
    {
        header: 'Facility',
        accessorKey: 'Facility',
        meta: {
            headerDisplayName: 'Facility',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Station Number',
        accessorKey: 'StationNumber',
        meta: {
            headerDisplayName: 'Station Number',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'State',
        accessorKey: 'State',
        meta: {
            headerDisplayName: 'State',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'City',
        accessorKey: 'City',
        meta: {
            headerDisplayName: 'City',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Date',
        accessorKey: 'AnticipatedDate',
        meta: {
            formatter: dateFormatter,
            headerDisplayName: 'Date',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'SPCListString',
        header: 'HSPC',
        meta: {
            headerDisplayName: 'HSPC',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    columnHelper.display({
        id: 'edit',
        header: 'Edit',
        enableSorting: false,
        cell: (props) => (
            <Button
                color="primary"
                type="button"
                label="Edit"
                onClick={() => showFacilityModal(props.row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    }),
    columnHelper.display({
        id: 'delete',
        header: 'Delete',
        enableSorting: false,
        cell: (props) => (
            <Button
                color="danger"
                type="button"
                label="Delete"
                disabled={!canEdit}
                onClick={() => deleteVaFacility(props.row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    })
];

export default VaFacilitiesColumnDefs;
