import React from 'react';

import LoadingPanel from './LoadingPanel';

type AsyncContentProps = {
    isLoading: boolean;
    loadingLabel: string;
    loadedLabel: string;
    children: React.ReactNode;
};

/**
 * Dynamically display a loading message or content based on whether loading has completed.
 *
 * @param props - props passed into this component
 * @param props.isLoading - indicating whether the content is loaded or not
 * @param props.loadingLabel - the label to display when the content is being loaded
 * @param props.loadedLabel - the label to display when the content has loaded (only for screen
 * readers)
 * @param props.children - the content to display when loaded
 * @returns the rendered component
 */
const AsyncContent = ({
    isLoading,
    loadingLabel = 'Loading',
    loadedLabel = 'Loaded',
    children
}: AsyncContentProps): JSX.Element => (
    <>
        <LoadingPanel isLoading={isLoading} label={loadingLabel} />
        <div aria-busy={isLoading} aria-hidden={isLoading}>
            {children}
        </div>
        <div role="status" className="sr-only">
            {isLoading ? loadingLabel : loadedLabel}
        </div>
    </>
);

export default AsyncContent;
