import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ButtonActions, { ButtonActionProps } from './ButtonActions';
import SearchBar, { SearchBarProps } from './SearchBar';

export type TableControlProps = { isSearchable?: boolean } & ButtonActionProps & SearchBarProps;

const TableControls = ({
    isExportable = true,
    isSearchable = true,
    showCsvPptBtnGroup = false,
    showHideColumns = false,
    btnRightFunc,
    btnRightColor = 'primary',
    btnRightLabel = '',
    visibilityButtonText,
    visibilityButtonColor,
    table,
    csvFileName,
    onSearch
}: TableControlProps) => (
    <Row>
        <Col sm={8}>
            {isExportable === true || showCsvPptBtnGroup === true || showHideColumns === true ? (
                <ButtonActions
                    isExportable={isExportable}
                    showCsvPptBtnGroup={showCsvPptBtnGroup}
                    showHideColumns={showHideColumns}
                    btnRightFunc={btnRightFunc}
                    btnRightLabel={btnRightLabel}
                    btnRightColor={btnRightColor}
                    visibilityButtonText={visibilityButtonText}
                    visibilityButtonColor={visibilityButtonColor}
                    csvFileName={csvFileName}
                    table={table}
                />
            ) : null}
        </Col>
        <Col
            sm={4}
            style={{
                float: 'right'
            }}
        >
            {isSearchable ? <SearchBar onSearch={onSearch} /> : null}
        </Col>
    </Row>
);

export default TableControls;
