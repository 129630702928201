import {
    commaCurrencyFormatter,
    commaDecimalFormatter,
    commaFormatter
} from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

/**
 * Gets the capacity label from the calculation type.
 *
 * @param {string} type - The calculation type.
 * @returns {string} - The Capacity Label.
 */
const getCapacityLabelFromType = (type) => {
    switch (type) {
        default:
        case 'General':
            return 'Estimated Internal Capacity';
        case 'Staffing':
            return 'Planned FTEs';
        case 'Space':
            return 'Planned Space';
        case 'Cost':
            return 'US Dollars';
    }
};

/**
 * Determines the formatting style for cells in the table.
 *
 * @param {number} val - The table data value to be formatted.
 * @param {string} calculationType - The calculation type.
 * @returns {Function} - Returns a functions for formatting.
 */
const dataFormatter = (val, calculationType) => {
    switch (calculationType) {
        case 'Staffing':
            return commaDecimalFormatter(val);
        case 'Cost':
            return commaCurrencyFormatter(val);
        default:
            return commaFormatter(val);
    }
};

/**
 * Adds "FTE: " to the front of the row name field if staffing calculation is set.
 *
 * @param {string} rowName - The name of the row.
 * @param {string} calculationType - The calculation type.
 * @returns {Function} - Returns a function for formatting.
 */
const nameFormatter = (rowName, calculationType) => {
    switch (calculationType) {
        case 'Staffing':
            return `FTE: ${rowName}`;
        default:
            return rowName;
    }
};

const GenerateCapacityTableColumns = (defaultYear, calculationType) => {
    if (!defaultYear) {
        return [];
    }

    return [
        {
            accessorKey: 'RowName',
            header: getCapacityLabelFromType(calculationType),
            meta: {
                headerDisplayName: getCapacityLabelFromType(calculationType),
                columnClassName: 'text-center',
                columnStyle: { width: '20%' },
                cellStyle: { whiteSpace: 'normal', width: '20%' },
                formatter: (value) => nameFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year0',
            header: defaultYear,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year1',
            header: defaultYear + 1,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year2',
            header: defaultYear + 2,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year3',
            header: defaultYear + 3,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year4',
            header: defaultYear + 4,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year5',
            header: defaultYear + 5,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year6',
            header: defaultYear + 6,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year7',
            header: defaultYear + 7,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year8',
            header: defaultYear + 8,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year9',
            header: defaultYear + 9,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year10',
            header: defaultYear + 10,
            meta: {
                columnClassName: 'text-right',
                cellClassName: 'text-right',
                columnStyle: { width: '6.66%' },
                cellStyle: { width: '6.66%' },
                formatter: (value) => dataFormatter(value, calculationType),
                editable: false
            }
        },
        columnHelper.display({
            id: 'blank-1',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' }
            }
        }),
        columnHelper.display({
            id: 'blank-2',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' }
            }
        })
    ];
};

export default GenerateCapacityTableColumns;
