import React from 'react';
import styled from 'styled-components/macro';

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    background: #05263f;
    color: #010c29;
`;

const VALinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    a::after {
        margin: 0 20px 0 20px;
        content: '|';
        padding: 0 0.15em;
    }
    a:last-of-type::after {
        margin: 0;
        padding: 0;
        content: '';
    }
`;

const Address = styled.p`
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-weight: normal;
    color: white;
    margin-top: 5px;
`;

/**
 * React Element for placing a footer with VA information on a page
 *
 * @returns {JSX.Element} component to render the VA footer
 */
const VAFooter = (): JSX.Element => (
    <Footer>
        <VALinks>
            <a className="style-guide-footer-hyperlink" href="http://www.va.gov">
                VA HOME
            </a>
            <a className="style-guide-footer-hyperlink" href="http://www.va.gov/privacy">
                PRIVACY
            </a>
            <a className="style-guide-footer-hyperlink" href="http://www.va.gov/foia">
                FOIA
            </a>
        </VALinks>
        <Address>
            U.S. Department of Veterans Affairs | 810 Vermont Avenue, NW Washington DC 20420
        </Address>
    </Footer>
);

export default VAFooter;
