import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const envscanApi = `${process.env.REACT_APP_HSPA_API}/EnvironmentalScan/`;

export const DemographicModel = {
    state: {
        marketEligiblePriority: { list: [], loading: false },
        marketEligibleAge: { list: [], loading: false },
        marketEnrollmentPriority: { list: [], loading: false },
        marketEnrollmentAge: { list: [], loading: false },
        marketSharePriority: { list: [], loading: false },
        marketShareAge: { list: [], loading: false },
        marketSpecialGender: { list: [], loading: false },
        marketSpecialOefoifond: { list: [], loading: false },
        countyEligible: { list: [], loading: false },
        countyEnrollment: { list: [], loading: false },
        countyMarketShare: { list: [], loading: false },
        countySpecialGender: { list: [], loading: false },
        countySpecialOefoifond: { list: [], loading: false }
    },
    reducers: {
        populate(state, data, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: isLoading
                }
            };
        },
        resetTable(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    data: {},
                    loading: false,
                    saving: false,
                    generating: false
                }
            };
        },
        resetTables(state) {
            return {
                ...state,
                marketEligiblePriority: { list: [], loading: false },
                marketEligibleAge: { list: [], loading: false },
                marketEnrollmentPriority: { list: [], loading: false },
                marketEnrollmentAge: { list: [], loading: false },
                marketSharePriority: { list: [], loading: false },
                marketShareAge: { list: [], loading: false },
                marketSpecialGender: { list: [], loading: false },
                marketSpecialOefoifond: { list: [], loading: false },
                countyEligible: { list: [], loading: false },
                countyEnrollment: { list: [], loading: false },
                countyMarketShare: { list: [], loading: false },
                countySpecialGender: { list: [], loading: false },
                countySpecialOefoifond: { list: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchMarketEligiblePriorityAsync(market, state) {
            try {
                this.resetTable('marketEligiblePriority');
                this.updateLoading(true, 'marketEligiblePriority');
                let result = await getJSON(
                    `${envscanApi}GetEligiblePriorityByMarket?market=${market}`
                );
                this.populate(result, 'marketEligiblePriority');
                this.updateLoading(false, 'marketEligiblePriority');
            } catch (error) {
                this.updateLoading(false, 'marketEligiblePriority');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market eligible priorities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketEligibleAgeAsync(market, state) {
            try {
                this.resetTable('marketEligibleAge');
                this.updateLoading(true, 'marketEligibleAge');
                let result = await getJSON(`${envscanApi}GetEligibleAgeByMarket?market=${market}`);
                this.populate(result, 'marketEligibleAge');
                this.updateLoading(false, 'marketEligibleAge');
            } catch (error) {
                this.updateLoading(false, 'marketEligibleAge');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market eligible ages',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketEnrollmentPriorityAsync(market, state) {
            try {
                this.resetTable('marketEnrollmentPriority');
                this.updateLoading(true, 'marketEnrollmentPriority');
                let result = await getJSON(
                    `${envscanApi}GetEnrollmentPriorityByMarket?market=${market}`
                );
                this.populate(result, 'marketEnrollmentPriority');
                this.updateLoading(false, 'marketEnrollmentPriority');
            } catch (error) {
                this.updateLoading(false, 'marketEnrollmentPriority');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market enrollement priorities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketEnrollmentAgeAsync(market, state) {
            try {
                this.resetTable('marketEnrollmentAge');
                this.updateLoading(true, 'marketEnrollmentAge');
                let result = await getJSON(
                    `${envscanApi}GetEnrollmentAgeByMarket?market=${market}`
                );
                this.populate(result, 'marketEnrollmentAge');
                this.updateLoading(false, 'marketEnrollmentAge');
            } catch (error) {
                this.updateLoading(false, 'marketEnrollmentAge');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market enrollment ages',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketSharePriorityAsync(market, state) {
            try {
                this.resetTable('marketSharePriority');
                this.updateLoading(true, 'marketSharePriority');
                let result = await getJSON(
                    `${envscanApi}GetMarketSharePriorityByMarket?market=${market}`
                );
                this.populate(result, 'marketSharePriority');
                this.updateLoading(false, 'marketSharePriority');
            } catch (error) {
                this.updateLoading(false, 'marketSharePriority');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market share priorities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketShareAgeAsync(market, state) {
            try {
                this.resetTable('marketShareAge');
                this.updateLoading(true, 'marketShareAge');
                let result = await getJSON(
                    `${envscanApi}GetMarketShareAgeByMarket?market=${market}`
                );
                this.populate(result, 'marketShareAge');
                this.updateLoading(false, 'marketShareAge');
            } catch (error) {
                this.updateLoading(false, 'marketShareAge');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market share ages',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketSpecialGenderAsync(market, state) {
            try {
                this.resetTable('marketSpecialGender');
                this.updateLoading(true, 'marketSpecialGender');
                let result = await getJSON(
                    `${envscanApi}GetVetPopSpecialGenderByMarket?market=${market}`
                );
                this.populate(result, 'marketSpecialGender');
                this.updateLoading(false, 'marketSpecialGender');
            } catch (error) {
                this.updateLoading(false, 'marketSpecialGender');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market special genders',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMarketSpecialOefoifondAsync(market, state) {
            try {
                this.resetTable('marketSpecialOefoifond');
                this.updateLoading(true, 'marketSpecialOefoifond');
                let result = await getJSON(
                    `${envscanApi}GetVetPopSpecialConflictByMarket?market=${market}`
                );
                this.populate(result, 'marketSpecialOefoifond');
                this.updateLoading(false, 'marketSpecialOefoifond');
            } catch (error) {
                this.updateLoading(false, 'marketSpecialOefoifond');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market special Oefoifond',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCountyEligibleAsync(market, state) {
            try {
                this.resetTable('countyEligible');
                this.updateLoading(true, 'countyEligible');
                let result = await getJSON(
                    `${envscanApi}GetEligibleCountyByMarket?market=${market}`
                );
                this.populate(result, 'countyEligible');
                this.updateLoading(false, 'countyEligible');
            } catch (error) {
                this.updateLoading(false, 'countyEligible');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch county eligible list',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCountyEnrollmentAsync(market, state) {
            try {
                this.resetTable('countyEnrollment');
                this.updateLoading(true, 'countyEnrollment');
                let result = await getJSON(
                    `${envscanApi}GetEnrollmentCountyByMarket?market=${market}`
                );
                this.populate(result, 'countyEnrollment');
                this.updateLoading(false, 'countyEnrollment');
            } catch (error) {
                this.updateLoading(false, 'countyEnrollment');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch county enrollments',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCountyMarketShareAsync(market, state) {
            try {
                this.resetTable('countyMarketShare');
                this.updateLoading(true, 'countyMarketShare');
                let result = await getJSON(
                    `${envscanApi}GetMarketShareCountyByMarket?market=${market}`
                );
                this.populate(result, 'countyMarketShare');
                this.updateLoading(false, 'countyMarketShare');
            } catch (error) {
                this.updateLoading(false, 'countyMarketShare');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch county market shares',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCountySpecialGenderAsync(market, state) {
            try {
                this.resetTable('countySpecialGender');
                this.updateLoading(true, 'countySpecialGender');
                let result = await getJSON(
                    `${envscanApi}GetVetPopSpecialGenderCountyByMarket?market=${market}`
                );
                this.populate(result, 'countySpecialGender');
                this.updateLoading(false, 'countySpecialGender');
            } catch (error) {
                this.updateLoading(false, 'countySpecialGender');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch county special genders',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCountySpecialOefoifondAsync(market, state) {
            try {
                this.resetTable('countySpecialOefoifond');
                this.updateLoading(true, 'countySpecialOefoifond');
                let result = await getJSON(
                    `${envscanApi}GetVetPopSpecialConflictCountyByMarket?market=${market}`
                );
                this.populate(result, 'countySpecialOefoifond');
                this.updateLoading(false, 'countySpecialOefoifond');
            } catch (error) {
                this.updateLoading(false, 'countySpecialOefoifond');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch county special Oefoifond',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
