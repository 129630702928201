import { commaFormatter, percentFormatter } from 'Utilities/formatters';

export const RuralityTotalColumnDefs = () => [
    {
        header: 'Rurality',
        accessorKey: 'Rurality',
        meta: {
            headerDisplayName: 'Facility',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Enrollees',
        accessorKey: 'Enrollees',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Enrollees',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Patients',
        accessorKey: 'Patients',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Patients',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const RuralityPercentageColumnDefs = () => [
    {
        header: 'Rurality',
        accessorKey: 'Rurality',
        meta: {
            headerDisplayName: 'Rurality',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Enrollees',
        accessorKey: 'Enrollees',
        meta: {
            formatter: percentFormatter,
            headerDisplayName: 'Enrollees',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Patients',
        accessorKey: 'Patients',
        meta: {
            formatter: percentFormatter,
            headerDisplayName: 'Patients',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export const RuralityCitationCountyColumnDefs = () => [
    {
        header: 'State',
        accessorKey: 'EnrollState',
        meta: {
            headerDisplayName: 'State',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'County',
        accessorKey: 'EnrollCounty',
        meta: {
            headerDisplayName: 'County',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Sector',
        accessorKey: 'SectorString',
        meta: {
            headerDisplayName: 'Sector',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Urban Enrollees',
        accessorKey: 'UrbanEnroll',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Urban Enrollees',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Rural Enrollees',
        accessorKey: 'RuralEnroll',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Rural Enrollees',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Highly Rural Enrollee',
        accessorKey: 'HighlyRuralEnroll',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Highly Rural Enrollee',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Urban Patients',
        accessorKey: 'UrbanPatient',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Urban Patients',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Rural Patients',
        accessorKey: 'RuralPatient',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Rural Patients',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    },
    {
        header: 'Highly Rural Patients',
        accessorKey: 'HighlyRuralPatient',
        meta: {
            formatter: commaFormatter,
            headerDisplayName: 'Highly Rural Patients',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];
