import React, { useState } from 'react';

import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@tanstack/react-table';

import ExpandContent from './ExpandContent';

export type CustomExpandToggleCellProps = {
    row: Row<unknown>;
    expandContent?: ExpandContent;
};

/**
 * Component for the custom expand content cell.
 *
 * @param props - The component properties.
 * @param props.row - The current row in the table.
 * @param props.expandContent - The expand content options.
 * @returns - The component to be rendered in the cell.
 */
const CustomExpandToggleCell = ({
    row,
    expandContent
}: CustomExpandToggleCellProps): JSX.Element => {
    const [expanded, setExpanded] = useState(false);
    if (
        !expandContent ||
        (expandContent.canRender && expandContent.canRender(row.original) !== true)
    ) {
        return <></>;
    }

    const icon =
        expanded === true
            ? expandContent.expandedIcon ?? faMinusCircle
            : expandContent.collapsedIcon ?? faPlusCircle;

    return (
        <a
            data-toggle="collapse"
            href={`#custom-${row.id}`}
            role="button"
            data-target={`#custom-${row.id}`}
            aria-expanded="false"
            aria-controls={`custom-${row.id}`}
            onClick={() => setExpanded(!expanded)}
            title="Collapse/Expand Details"
        >
            <FontAwesomeIcon icon={icon} color="black" />
        </a>
    );
};

export default CustomExpandToggleCell;
