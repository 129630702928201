import { commaNAFormatter, percentFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const generateYearSection = (groupHeader, keyPrefix, headerPrefix) =>
    columnHelper.group({
        id: groupHeader,
        header: groupHeader,
        enableSorting: false,
        meta: {
            headerDisplayName: groupHeader,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            csvExportIgnore: true
        },
        columns: [
            {
                accessorKey: keyPrefix,
                header: 'Modeled',
                meta: {
                    headerDisplayName: `${headerPrefix} Modeled`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                accessorKey: `${keyPrefix}Change`,
                header: 'Change',
                meta: {
                    headerDisplayName: `${headerPrefix} Change`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal' },
                    formatter: commaNAFormatter
                }
            },
            {
                accessorKey: `${keyPrefix}PercentChange`,
                header: '% Change',
                meta: {
                    headerDisplayName: `${headerPrefix} % Change`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap', background: '#87ceeb' },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal', backgroundColor: '#87ceeb' },
                    formatter: percentFormatter
                }
            }
        ]
    });

const generateYearColumns = (headerPrefix, dataPrefix, color) =>
    columnHelper.group({
        id: headerPrefix,
        header: `${headerPrefix} Comparison`,
        enableSorting: false,
        meta: {
            headerDisplayName: `${headerPrefix} Comparison`,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', background: color },
            cellClassName: 'text-center',
            cellStyle: { whiteSpace: 'normal', backgroundColor: color },
            csvExportIgnore: true
        },
        columns: [
            {
                accessorKey: `${dataPrefix}Year5PercentChange`,
                header: '5 Year % Change',
                meta: {
                    headerDisplayName: `${headerPrefix} Comparison 5 Year % Change`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap', background: color },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal', backgroundColor: color },
                    formatter: percentFormatter
                }
            },
            {
                accessorKey: `${dataPrefix}Year10PercentChange`,
                header: '10 Year % Change',
                meta: {
                    headerDisplayName: `${headerPrefix} Comparison 10 Year % Change`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap', background: color },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal', backgroundColor: color },
                    formatter: percentFormatter
                }
            },
            {
                accessorKey: `${dataPrefix}Year20PercentChange`,
                header: '20 Year % Change',
                meta: {
                    headerDisplayName: `${headerPrefix} Comparison 20 Year % Change`,
                    columnClassName: 'text-center',
                    columnStyle: { whiteSpace: 'pre-wrap', background: color },
                    cellClassName: 'text-right',
                    cellStyle: { whiteSpace: 'normal', backgroundColor: color },
                    formatter: percentFormatter
                }
            }
        ]
    });

const AnalysisToolColumns = (defaultYear, showHspcDetail) => {
    if (!defaultYear || defaultYear.defaultYear === '') {
        return [];
    }

    return showHspcDetail === true
        ? [
              {
                  accessorKey: 'TypeOfCare',
                  header: 'Type of Care',
                  meta: {
                      headerDisplayName: 'Type of Care',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              {
                  accessorKey: 'Mpc',
                  header: 'HSPCG',
                  meta: {
                      headerDisplayName: 'HSPC Group',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              {
                  id: 'Spc',
                  accessorKey: 'Spc',
                  header: 'HSPC',
                  meta: {
                      headerDisplayName: 'HSPC Group',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              columnHelper.group({
                  id: defaultYear.defaultYear,
                  header: defaultYear.defaultYear,
                  enableSorting: false,
                  meta: {
                      headerDisplayName: defaultYear.defaultYear,
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      csvExportIgnore: true
                  },
                  columns: [
                      {
                          accessorKey: 'Year0',
                          header: 'Baseline',
                          meta: {
                              headerDisplayName: defaultYear.defaultYearWithFiscalString,
                              columnClassName: 'text-center',
                              columnStyle: { whiteSpace: 'pre-wrap' },
                              cellClassName: 'text-right',
                              cellStyle: { whiteSpace: 'normal' },
                              formatter: commaNAFormatter
                          }
                      }
                  ]
              }),
              generateYearSection(
                  defaultYear.fiveYearsOfDefault,
                  'Year5',
                  defaultYear.fiveYearWithFiscalString
              ),
              generateYearSection(
                  defaultYear.tenYearsOfDefault,
                  'Year10',
                  defaultYear.tenYearWithFiscalString
              ),
              generateYearSection(
                  defaultYear.twentyYearsOfDefault,
                  'Year20',
                  defaultYear.twentyYearWithFiscalString
              ),
              generateYearColumns('VISN', 'Visn', '#90ee90'),
              generateYearColumns('National', 'National', '#f0e68c')
          ]
        : [
              {
                  accessorKey: 'TypeOfCare',
                  header: 'Type of Care',
                  meta: {
                      headerDisplayName: 'Type of Care',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              {
                  accessorKey: 'Mpc',
                  header: 'HSPCG',
                  meta: {
                      headerDisplayName: 'HSPC Group',
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      cellStyle: { whiteSpace: 'normal' }
                  }
              },
              columnHelper.group({
                  id: defaultYear.defaultYear,
                  header: defaultYear.defaultYear,
                  enableSorting: false,
                  meta: {
                      headerDisplayName: defaultYear.defaultYear,
                      columnClassName: 'text-center',
                      columnStyle: { whiteSpace: 'pre-wrap' },
                      cellClassName: 'text-center',
                      csvExportIgnore: true
                  },
                  columns: [
                      {
                          accessorKey: 'Year0',
                          header: 'Baseline',
                          meta: {
                              headerDisplayName: defaultYear.defaultYearWithFiscalString,
                              columnClassName: 'text-center',
                              columnStyle: { whiteSpace: 'pre-wrap' },
                              cellClassName: 'text-right',
                              cellStyle: { whiteSpace: 'normal' },
                              formatter: commaNAFormatter
                          }
                      }
                  ]
              }),
              generateYearSection(
                  defaultYear.fiveYearsOfDefault,
                  'Year5',
                  defaultYear.fiveYearWithFiscalString
              ),
              generateYearSection(
                  defaultYear.tenYearsOfDefault,
                  'Year10',
                  defaultYear.tenYearWithFiscalString
              ),
              generateYearSection(
                  defaultYear.twentyYearsOfDefault,
                  'Year20',
                  defaultYear.twentyYearWithFiscalString
              ),
              generateYearColumns('VISN', 'Visn', '#90ee90'),
              generateYearColumns('National', 'National', '#f0e68c')
          ];
};

export default AnalysisToolColumns;
