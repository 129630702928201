/**
 * Stakeholders form.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, Form } from 'redux-form';
import Button from 'Lib/Button';
import TextAreaField from 'Lib/ReduxFormFields/TextAreaField';
import { getCurrentMarketName, getCurrentVISN, canEditVISN } from 'Modules/UserSession/selector';
import { getStakeholderFormObject } from 'Modules/EnvironmentalScan/Services/selector';

class ESStakeholdersForm extends React.Component {
    componentDidMount() {
        const { marketName, fetchDataAsync } = this.props;
        if (marketName) {
            fetchDataAsync(marketName);
        }
    }

    componentWillUpdate(nextProps) {
        const { marketName, fetchDataAsync } = this.props;
        const { marketName: nextMarketName } = nextProps;
        if (nextMarketName !== marketName) {
            fetchDataAsync(nextMarketName);
        }
    }

    formGenerate = () => {
        const { marketName, currentVISN, generatePowerPointAsync } = this.props;

        generatePowerPointAsync({ market: marketName, visnCode: currentVISN.VisnCode });
    };

    formSubmit = (values) => {
        const { dispatch, saveAsync } = this.props;
        saveAsync(values);
        dispatch(reset('ESStakeholdersForm')); // Resets all fields
    };

    render() {
        const {
            canEdit,
            loading,
            saving,
            submitting,
            generating,
            invalid,
            pristine,
            handleSubmit,
            reset: resetForm
        } = this.props;

        let disableForm = !canEdit || saving || generating;
        return (
            <div style={{ marginTop: 15 }}>
                <div className="col-md-6 offset-md-3">
                    <br />
                    <Form onSubmit={handleSubmit(this.formSubmit)}>
                        <TextAreaField
                            name="EmployeeStakeholders"
                            label="Employee Stakeholders"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="VeteranServiceOrgs"
                            caption={
                                <p>
                                    Please see the following link which may be used to research{' '}
                                    <a
                                        href="https://www.va.gov/ogc/apps/accreditation/index.asp"
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Veteran Service Organizations
                                    </a>
                                </p>
                            }
                            label="Veteran Service Organizations"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="LaborRelations"
                            caption={
                                <p>
                                    Please see the following link which may be used to research{' '}
                                    <a
                                        href="https://www1.va.gov/lmr/laborunions.asp"
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Union Representation by VISN
                                    </a>
                                </p>
                            }
                            label="Labor Relations"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="CongressionalReps"
                            caption={
                                <p>
                                    Please see the following link which may be used to
                                    research&nbsp;
                                    <a
                                        href="https://www.senate.gov/general/contact_information/senators_cfm.cfm"
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        U.S. Senators
                                    </a>
                                    &nbsp;and&nbsp;
                                    <a
                                        href="http://clerk.house.gov/member_info/index.aspx"
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Congressional Representatives
                                    </a>
                                </p>
                            }
                            label="Congressional Representatives"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="LocalStateStakeholders"
                            caption={
                                <p>
                                    Please input information about current partners such as local
                                    and state government offices, social service organizations,
                                    community groups, etc.
                                </p>
                            }
                            label="Local, State and Private Sector Stakeholders/Partners"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="OtherSigStakeholders"
                            label="Other Significant Stakeholders"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />

                        <div className="col-xs-12">
                            <div className="form-group">
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        color="success"
                                        type="submit"
                                        disabled={!canEdit || submitting}
                                        label={
                                            saving ? 'Saving...' : 'Save Stakeholder Information'
                                        }
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        type="button"
                                        label={generating ? 'Generating...' : 'Generate PowerPoint'}
                                        disabled={invalid || submitting}
                                        onClick={this.formGenerate}
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        color="warning"
                                        type="button"
                                        label="Cancel"
                                        disabled={pristine || submitting}
                                        onClick={resetForm}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}
ESStakeholdersForm = reduxForm({
    form: 'ESStakeholdersForm',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.EmployeeStakeholders) {
            errors.EmployeeStakeholders = 'Employee stakeholders is required';
        }
        if (!values.VeteranServiceOrgs) {
            errors.VeteranServiceOrgs = 'Veteran service orgs is required';
        }
        if (!values.LaborRelations) {
            errors.LaborRelations = 'Labor relations is required';
        }
        if (!values.CongressionalReps) {
            errors.CongressionalReps = 'Congressional representatives is required';
        }
        if (!values.LocalStateStakeholders) {
            errors.LocalStateStakeholders = 'Local state stakeholders is required';
        }
        if (!values.OtherSigStakeholders) {
            errors.OtherSigStakeholders = 'Other significant stakeholders is required';
        }

        return errors;
    }
})(ESStakeholdersForm);

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataAsync: (visnCode) => dispatch.StakeholdersFormModel.fetchDataAsync(visnCode),
        generatePowerPointAsync: (payload) =>
            dispatch.StakeholdersFormModel.generatePowerPointAsync(payload),
        saveAsync: (formValues) => dispatch.StakeholdersFormModel.saveAsync(formValues)
    };
};

const mapStateToProps = (state) => {
    const StakeholderFormObject = getStakeholderFormObject(state);
    return {
        initialValues: StakeholderFormObject.data,
        saving: StakeholderFormObject.saving,
        generating: StakeholderFormObject.generating,
        loading: StakeholderFormObject.loading,
        marketName: getCurrentMarketName(state),
        currentVISN: getCurrentVISN(state),
        canEdit: canEditVISN(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESStakeholdersForm);
