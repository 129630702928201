import React from 'react';
import { useSelector } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getCapacityOverheadData } from 'Modules/MatchCapacityDemand/Services/selector';
import PropTypes from 'prop-types';

import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import MCDPlannerWorkbenchDrilldown from '../../MCDPlannerWorkbenchDrilldown';
import GenerateCapacityTableColumns from './MCDPWCapacityTableColumns';

/**
 * The MCDPWCapacityTable component.
 *
 * @returns {React.ReactNode}
 */
const MCDPWCapacityTable = React.memo(
    ({
        calculationType,
        canEdit,
        currentPlan,
        defaultYear,
        updateWorkspaceData,
        tableType = 'Capacity'
    }) => {
        const tableData = useSelector((state) => getCapacityOverheadData(state, calculationType));

        /**
         * Passes table record elements to the MCDPlannerWorkbenchDrilldown component
         *
         * @returns {React.ReactNode} - returns a MCDPlannerWorkbenchDrilldown component
         */
        const expandComponent = () => (
            <MCDPlannerWorkbenchDrilldown
                calculationType={calculationType}
                canEdit={canEdit}
                filter="Available In-House Capacity"
                isEditable
                updateWorkspaceData={updateWorkspaceData}
                tableType={tableType}
            />
        );

        const columns = GenerateCapacityTableColumns(defaultYear, calculationType);

        return (
            <ReactDataTable
                list={tableData}
                columns={columns}
                loading={currentPlan.loading}
                isExportable={false}
                isSearchable={false}
                isPageable={false}
                isSortable={false}
                striped={false}
                hover={false}
                keyValue="RowName"
                rowClasses="matchCapacityHeader"
                expandContent={{
                    canRender: (row) => row.IsExpandable,
                    renderer: expandComponent,
                    expandedIcon: faCaretDown,
                    collapsedIcon: faCaretRight
                }}
                containerStyle={{ height: '100%', marginBottom: '-14px'}}
            />
        );
    }
);

MCDPWCapacityTable.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    currentPlan: PropTypes.shape({}).isRequired,
    defaultYear: PropTypes.number.isRequired,
    updateWorkspaceData: PropTypes.func.isRequired,
    calculationType: PropTypes.string.isRequired,
    tableType: PropTypes.string
};

export default MCDPWCapacityTable;
