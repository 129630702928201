import React from 'react';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import PropTypes from 'prop-types';

export default class ESRTable extends React.Component {
    static propTypes = {
        keyValue: PropTypes.string,
        tableColumnDefs: PropTypes.array,
        tableData: PropTypes.object,
        currentMarket: PropTypes.string,
        fetchDataFunc: PropTypes.func,
        csvFileName: PropTypes.string,
        generatePowerpointFunc: PropTypes.func
    };
    componentWillMount() {
        if (this.props.currentMarket && this.props.tableData.list.length === 0)
            this.props.fetchDataFunc(this.props.currentMarket);
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.currentMarket !== this.props.currentMarket)
            this.props.fetchDataFunc(nextProps.currentMarket);
    }
    render() {
        return (
            <>
                <ReactDataTable
                    keyValue={this.props.keyValue}
                    columns={this.props.tableColumnDefs}
                    list={this.props.tableData.list}
                    loading={this.props.tableData.loading}
                    csvFileName={this.props.csvFileName}
                    btnRightFunc={this.props.generatePowerpointFunc}
                    btnRightColor="danger"
                    btnRightLabel={
                        this.props.tableData.generating ? 'Generating...' : 'Export to Powerpoint'
                    }
                    showCsvPptBtnGroup={true}
                />
            </>
        );
    }
}
ESRTable.defaultProps = {
    keyValue: '',
    tableColumnDefs: [],
    tableData: [],
    currentMarket: '',
    fetchDataFunc: null,
    csvFileName: '',
    generatePowerpointFunc: null
};
