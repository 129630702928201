import { deleteCapitalProject as deleteCapitalProjectAPI } from 'Data/capital-projects';
import epicRequest from 'Utilities/epicRequest';

import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    deleteCapitalProject as deleteCapitalProjectAction,
    deleteCapitalProjectDone,
    deleteCapitalProjectError,
    deleteCapitalProjectStart,
    deletedCapitalProject
} from '../capitalProjectsSlice';

/**
 * An epic that listens for deleteCapitalProjects actions and calls the API.
 *
 * @param action$ - A stream of the redux actions dispatched to the application's redux store.
 * @returns Actions to set capital projects on load.
 */
const deleteCapitalProject = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        filter(deleteCapitalProjectAction.match),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Send a delete request and return the capital project.
             *
             * @param action - The action to take.
             * @param action.payload - The capital project to delete.
             * @returns An observable of redux actions.
             */
            request: ({ payload }: PayloadAction<number>) =>
                from(deleteCapitalProjectAPI(payload)).pipe(
                    map((capitalProjectID) => deletedCapitalProject(capitalProjectID as number))
                ),
            actions: {
                start: deleteCapitalProjectStart,
                done: deleteCapitalProjectDone,
                fail: deleteCapitalProjectError
            }
        })
    );

export default deleteCapitalProject;
