import '../style.css';

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { Field } from 'redux-form';

import RadioGroup from './RadioGroup';

type RadioGroupFieldProps = {
    name: string;
    headerLabel: React.ReactNode;
    firstLabel: React.ReactNode;
    secondLabel: React.ReactNode;
    labelPosition: number;
    inputPosition: number;
};

/**
 * A redux-form enabled group of yes/no radio buttons with a label.  Layout of the label and radio
 * buttons are determined using Bootstrap grid props.
 *
 * @param props - props passed into this component
 * @param props.name - name of the radio group
 * @param props.headerLabel - label for the radio group
 * @param props.firstLabel - label for the first radio button
 * @param props.secondLabel - label for the second radio button
 * @param props.labelPosition - Bootstrap grid width for the label
 * @param props.inputPosition - Bootstrap grid width for the input
 * @returns the radio group field
 */
const RadioGroupField = ({
    name,
    headerLabel,
    firstLabel,
    secondLabel,
    labelPosition,
    inputPosition
}: RadioGroupFieldProps): JSX.Element => {
    const options = [
        { title: firstLabel, value: 'true' },
        { title: secondLabel, value: 'false' }
    ];

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={labelPosition} id={name}>
                <strong>{headerLabel}</strong>
            </Form.Label>
            <Col sm={inputPosition} role="radiogroup" aria-labelledby={name}>
                <Field component={RadioGroup} name={name} required options={options} />
            </Col>
        </Form.Group>
    );
};

export default RadioGroupField;
