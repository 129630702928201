import { FilterFn, Row } from '@tanstack/react-table';

/**
 * The Global Filter Function.
 *
 * @param row - The current row in the table.
 * @param columnId - The column identifier of the value being checked.
 * @param value - The value to search against.
 * @returns - Whether or not the search term is in the row item.
 */
const globalFilterFn: FilterFn<unknown> = (
    row: Row<unknown>,
    columnId: string,
    value: string
): boolean => {
    const search = value.toLowerCase();

    let rowValue = row.getValue(columnId) as string;
    if (typeof rowValue === 'number') {
        rowValue = String(rowValue);
    }

    return rowValue?.toLowerCase().includes(search);
};

export default globalFilterFn;
