import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import Form from './Form';

const ReportWizardForm = () => {
    const { path } = useRouteMatch();

    return <Route path={[`${path}/:reportID`, `${path}`]} component={Form} />;
};

export default ReportWizardForm;
