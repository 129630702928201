/**
 * Displays user created reports.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getUserReportsSelector } from 'Modules/Report/Services/selector';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';

import UserCreatedReportColumnDefs from './TableOutline';

/**
 * Displays user created reports in a table.
 *
 * @returns {React.ReactNode} the rendered report table component
 */
const SavedReports = () => {
    const dispatch = useDispatch();
    const marketName = useSelector(getCurrentMarketName);
    const userReports = useSelector(getUserReportsSelector);
    const canEdit = useSelector(canEditVISN);

    useEffect(() => {
        if (marketName) {
            dispatch.SavedReportModel.fetchUserReportsAsync(marketName);
        }
    }, [dispatch, marketName]);

    const deleteReport = useCallback(
        (reportId) => {
            dispatch.ReportFormModel.deleteReportAsync({ reportId, marketName });
        },
        [dispatch, marketName]
    );

    return (
        <div>
            <h4 className="text-primary">
                <b>User Created Reports</b>
            </h4>
            <ReactDataTable
                keyValue="Id"
                list={userReports.list}
                columns={UserCreatedReportColumnDefs(
                    deleteReport,
                    dispatch.ReportFormModel.generateReportByIdAsync,
                    canEdit
                )}
                loading={userReports.loading}
                isExportable={false}
            />
        </div>
    );
};

export default SavedReports;
