import { postJSON } from './apiCalls';

const userApiUrl = `${process.env.REACT_APP_HSPA_API as string}/User/`;

interface AnalyticsLogger {
    trackPageChange: (page: { pathName: string }) => void;
    trackTabChange: (tab: string) => void;
    sendLogReport: () => void;
    setVisn: (visn: number) => void;
    setMarket: (market: string) => void;
}

interface Activity {
    Page: string;
    Tabs: string[];
    Market: string;
    VISN: number;
    StartTime: Date | string;
    EndTime: Date | string;
}

/**
 * Log the user telemetry to the user activity endpoint.
 *
 * @param {Activity} activity - the telemetry event to log
 */
const logAnalytics = (activity: Activity) => {
    try {
        if (activity.Page !== '') {
            postJSON(`${userApiUrl}LogUserActivity`, {
                ...activity,
                EndTime: new Date(),
                Tabs: activity.Tabs.toString(),
                UserAgent: navigator.userAgent
            });
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * Create an AnalyticsLogger.  This is used to create the logger the first time an instance is
 * requested and as a result this should only be called once.
 * The AnalyticsLogger collects telemetry data over several calls and then sends it as a single
 * event.
 *
 * @returns {AnalyticsLogger} the newly initialized AnalyticsLogger
 */
const init = (): AnalyticsLogger => {
    // private methods and variables
    let activity: Activity = {
        Page: '',
        Tabs: [],
        Market: '',
        VISN: 0,
        StartTime: '',
        EndTime: ''
    };

    /**
     * After logging a telemetry event, reset the current recorded telemetry.
     */
    const resetActivity = () => {
        activity = {
            Page: '',
            Tabs: [],
            Market: activity.Market,
            VISN: activity.VISN,
            StartTime: '',
            EndTime: ''
        };
    };

    return {
        // public methods and variables
        /**
         * This is used to track page change telemetry.  When the page or route changes in the app,
         * this method should be called to record the update.  This will log the update to the
         * telemetry endpoint.
         *
         * @param {object} page - data on the page that was just loaded
         * @param {string} page.pathName - the path to the current page
         */
        trackPageChange: (page: { pathName: string }) => {
            let pageName = page.pathName.replace(/\/([a-zA-Z])([^/]*)\//g, '');

            if (pageName === '') {
                pageName = 'Home';
            }

            if (activity.Page !== pageName) {
                logAnalytics(activity);
                resetActivity();
                activity.Page = pageName;
                activity.StartTime = new Date();
            }
        },

        /**
         * This is used to track tab change telemetry.  When a tab is opened in the app, this method
         * should be called to record the update.
         *
         * @param {string} tab - the name of the tab selected
         */
        trackTabChange: (tab: string) => {
            activity.Tabs.push(tab);
        },

        /**
         * Send current data to the telemetry endpoint.  This does not reset the current telemetry
         * data.
         */
        sendLogReport: () => {
            logAnalytics(activity);
        },

        /**
         * Set the VISN field of the telemetry data.
         *
         * @param {number} visn - the current VISN number
         */
        setVisn: (visn: number) => {
            activity.VISN = visn;
        },

        /**
         * Set the Market field of the telemetry data.
         *
         * @param {string} market - the current market name
         */
        setMarket: (market: string) => {
            activity.Market = market;
        }
    };
};

let instance: AnalyticsLogger;

/**
 * A set of utility functions to track user telemetry.
 */
const analytics = {
    /**
     * Get the analytics logger object.  Only creates the instance the first time it's needed.
     *
     * @returns {AnalyticsLogger} the logger interface
     */
    getInstance: (): AnalyticsLogger => {
        if (!instance) {
            instance = init();
        }
        return instance;
    }
};

export default analytics;
