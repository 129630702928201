import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { dateFormatter } from 'Utilities/formatters';

const UserQuestionsColumnDefs = [
    {
        accessorKey: 'VisnCode',
        header: 'VISN',
        meta: {
            headerDisplayName: 'VISN',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '50px' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'MarketName',
        header: 'Market',
        meta: {
            headerDisplayName: 'Market',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '140px' },
            cellClassName: 'text-left',
            cellStyle: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
        }
    },
    {
        accessorKey: 'Name',
        header: 'Name',
        meta: {
            headerDisplayName: 'Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '100px' },
            cellClassName: 'text-left'
        }
    },
    {
        accessorKey: 'Email',
        header: 'Email',
        cell: ({ getValue }) => (
            <OverlayTrigger placement="top" overlay={<Tooltip>{getValue()}</Tooltip>}>
                <span>{getValue()}</span>
            </OverlayTrigger>
        ),
        meta: {
            headerDisplayName: 'Email',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '110px' },
            cellStyle: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
            cellClassName: 'text-left'
        }
    },
    {
        accessorKey: 'Phone',
        header: 'Phone',
        meta: {
            headerDisplayName: 'Phone',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '110px' },
            cellClassName: 'text-left'
        }
    },
    {
        accessorKey: 'Subject',
        header: 'Subject',
        meta: {
            headerDisplayName: 'Subject',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '150px' },
            cellClassName: 'text-left'
        }
    },
    {
        accessorKey: 'DateRec',
        header: 'Date Sent',
        meta: {
            headerDisplayName: 'Date Sent',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '100px' },
            cellClassName: 'text-left',
            formatter: dateFormatter
        }
    },
    {
        accessorKey: 'Question',
        header: 'Question',
        meta: {
            headerDisplayName: 'Question',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-left',
            cellStyle: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '80px'
            }
        }
    },
    {
        accessorKey: 'ResponseText',
        header: 'Response',
        meta: {
            headerDisplayName: 'Response',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', maxWidth: '50px' },
            cellClassName: 'text-left',
            cellStyle: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '80px'
            }
        }
    }
];

export default UserQuestionsColumnDefs;
