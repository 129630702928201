/**
 * Tool to work with Patient Origins in the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector, getFormValues, reset, Form } from 'redux-form';
import { connect } from 'react-redux';
import { WorkloadTypeText } from 'Utilities/constants';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import Button from 'Lib/Button';
import { getCurrentMarketName, canEditVISN } from 'Modules/UserSession/selector';
import {
    getPatientOriginFacilitiesSelector,
    getPatientOriginWorkloadTypesSelector,
    getPatientOriginOriginsListSelector,
    getPatientOriginMpcsSelector,
    getPatientOriginFormSelector
} from 'Modules/UnderstandDemand/Services/selector';
import PatientOriginTable from './UDPOTable';
import DDFContainer from 'Lib/DDFSlides/DDFContainer';

class UDPatientOriginTool extends React.Component {
    static propTypes = {
        marketName: PropTypes.string.isRequired,
        currentFacility: PropTypes.string.isRequired,
        currentHSPCG: PropTypes.string.isRequired,
        currentOrigin: PropTypes.string.isRequired,
        currentWorkloadType: PropTypes.string.isRequired,
        facilities: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        mpcs: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        origins: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        workloadTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        patientOriginForm: PropTypes.shape({
            saving: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        getPoFacilities: PropTypes.func.isRequired,
        getPoWorkloadTypes: PropTypes.func.isRequired,
        getPoMarketOrigins: PropTypes.func.isRequired,
        getPoMpcs: PropTypes.func.isRequired,
        savePoReportTool: PropTypes.func.isRequired,
        clearPatientOriginForm: PropTypes.func.isRequired,
        resetPatientOrigins: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false
    };

    componentWillMount() {
        const {
            facilities,
            marketName,
            workloadTypes,
            getPoFacilities,
            getPoWorkloadTypes
        } = this.props;

        if (facilities.list.length === 0 && marketName) {
            getPoFacilities(marketName);
        }
        if (workloadTypes.list.length === 0 && marketName) {
            getPoWorkloadTypes();
        }
    }

    componentWillUpdate(nextProps) {
        const { marketName, clearPatientOriginForm, getPoFacilities, dispatch } = this.props;
        const { marketName: nextMarketName } = nextProps;

        if (nextMarketName !== marketName) {
            clearPatientOriginForm();
            dispatch(reset('PatientOriginForm'));
            getPoFacilities(nextMarketName);
        }
    }

    componentWillUnmount() {
        const { clearPatientOriginForm, resetPatientOrigins, dispatch } = this.props;

        clearPatientOriginForm();
        dispatch(reset('PatientOriginForm'));
        resetPatientOrigins();
    }

    resetForm = () => {
        const { resetPatientOrigins, dispatch } = this.props;

        resetPatientOrigins();
        dispatch(reset('PatientOriginForm'));
    };

    onFacilityChange = (option, inputName) => {
        const { getPoMarketOrigins, getPoMpcs } = this.props;

        if (inputName === 'Facility') {
            this.resetForm();
            getPoMarketOrigins(option);
            getPoMpcs(option);
        }
    };

    formSubmit = () => {
        const {
            marketName,
            currentFacility,
            currentHSPCG,
            currentOrigin,
            currentWorkloadType,
            savePoReportTool,
            dispatch
        } = this.props;

        const reportTool =
            currentFacility != null
                ? `Facility::${currentFacility}${
                      currentHSPCG != null
                          ? `; HSPC::${currentHSPCG}${
                                currentOrigin != null ? `; Market Origin::${currentOrigin}` : ''
                            }${
                                currentWorkloadType != null
                                    ? `; Workload Type::${WorkloadTypeText[currentWorkloadType]}`
                                    : ''
                            }`
                          : ''
                  }`
                : '';
        savePoReportTool(reportTool, marketName);
        dispatch(reset('UDPatientOriginTool'));
    };

    render() {
        const {
            marketName,
            currentFacility,
            currentHSPCG,
            currentOrigin,
            currentWorkloadType,
            facilities,
            mpcs,
            origins,
            workloadTypes,
            patientOriginForm,
            canEdit,
            handleSubmit
        } = this.props;

        return (
            <div>
                <div>
                    <DDFContainer
                        text={
                            'There is relevant data published in the Data Discovery and Findings.'
                        }
                        reports={[
                            {
                                title: 'Encounters Maps',
                                slideNumbers: [26, 27, 32, 35, 36]
                            }
                        ]}
                    ></DDFContainer>
                </div>
                <div className="col-sm-8 offset-sm-2">
                    <Form style={{ marginTop: '15px' }} onSubmit={handleSubmit(this.formSubmit)}>
                        <div>
                            <TextboxField
                                name="Market"
                                label="Market of Study"
                                disabled
                                labelPosition={3}
                                inputPosition={9}
                                defaultValue={marketName}
                            />
                            <DropdownField
                                name="Facility"
                                label="Facility"
                                options={facilities.list}
                                loading={facilities.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                                onChangeFunction={this.onFacilityChange}
                            />
                            <DropdownField
                                name="HSPCG"
                                label="HSPCG"
                                options={mpcs.list}
                                loading={mpcs.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                            />
                            <DropdownField
                                name="Origin"
                                label="Market of Patient Origin"
                                options={origins.list}
                                loading={origins.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                            />
                            <DropdownField
                                name="WorkloadType"
                                label="Workload Type"
                                options={workloadTypes.list}
                                loading={workloadTypes.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={3}
                                inputPosition={9}
                            />
                            <TextboxField
                                name="ReportTool"
                                label="Patient Origin Tool Report Title"
                                disabled
                                labelPosition={3}
                                inputPosition={9}
                                defaultValue={
                                    currentFacility != null
                                        ? `Facility::${currentFacility}${
                                              currentHSPCG != null
                                                  ? `; HSPC::${currentHSPCG}${
                                                        currentOrigin != null
                                                            ? `; Market Origin::${currentOrigin}`
                                                            : ''
                                                    }${
                                                        currentWorkloadType != null
                                                            ? `; Workload Type::${WorkloadTypeText[currentWorkloadType]}`
                                                            : ''
                                                    }`
                                                  : ''
                                          }`
                                        : ''
                                }
                            />
                        </div>
                        <div className="col-xs-12">
                            <div className="form-group" style={{ textAlign: 'center' }}>
                                <Button
                                    color="success"
                                    type="submit"
                                    label={
                                        patientOriginForm.saving ? 'Saving...' : 'Save for Report'
                                    }
                                    disabled={!canEdit || patientOriginForm.saving}
                                />
                            </div>
                            <br />
                        </div>
                    </Form>
                </div>
                <PatientOriginTable
                    facility={currentFacility}
                    marketName={currentOrigin}
                    mpc={currentHSPCG}
                    workloadType={currentWorkloadType}
                />
            </div>
        );
    }
}
const ReduxFormUDPatientOriginTool = reduxForm({
    form: 'UDPatientOriginTool',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.Facility) {
            errors.Facility = 'Required';
        }
        if (!values.HSPCG) {
            errors.HSPCG = 'Required';
        }
        if (!values.Origin) {
            errors.Origin = 'Required';
        }
        if (!values.WorkloadType && values.WorkloadType !== 0) {
            errors.WorkloadType = 'Required';
        }
        return errors;
    }
})(UDPatientOriginTool);

const selector = formValueSelector('UDPatientOriginTool');

const mapDispatchToProps = (dispatch) => ({
    getPoFacilities: dispatch.PatientOriginToolModel.fetchPOFacilitiesAsync,
    getPoWorkloadTypes: dispatch.PatientOriginToolModel.fetchPOWorkloadTypesAsync,
    getPoMpcs: dispatch.PatientOriginToolModel.fetchPOHspcsAsync,
    getPoMarketOrigins: dispatch.PatientOriginToolModel.fetchPOMarketOriginsAsync,
    savePoReportTool: dispatch.PatientOriginToolModel.savePatientOriginReportAsync,
    resetPatientOrigins: () => dispatch.PatientOriginToolModel.resetTable('patientOrigins'),
    clearPatientOriginForm: () => dispatch.PatientOriginToolModel.resetTable('patientOriginForm')
});

const mapStateToProps = (state) => ({
    formValues: getFormValues('UDPatientOriginTool')(state),
    currentFacility: selector(state, 'Facility'),
    currentHSPCG: selector(state, 'HSPCG'),
    currentOrigin: selector(state, 'Origin'),
    currentWorkloadType: selector(state, 'WorkloadType'),

    // initialValues: state.demand.patientOriginTool.patientOriginForm.data || {},
    facilities: getPatientOriginFacilitiesSelector(state),
    workloadTypes: getPatientOriginWorkloadTypesSelector(state),
    origins: getPatientOriginOriginsListSelector(state),
    mpcs: getPatientOriginMpcsSelector(state),
    patientOriginForm: getPatientOriginFormSelector(state),
    marketName: getCurrentMarketName(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormUDPatientOriginTool);
