import * as React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import ReactHighcharts from 'react-highcharts';
import { useSelector } from 'react-redux';

import _map from 'lodash/map';

import { getPlanObject } from 'Modules/MatchCapacityDemand/Services/selector';
import { getPlanName, PlanDTO } from 'Utilities/types';

import HighchartsMore from 'highcharts-more';

HighchartsMore(ReactHighcharts.Highcharts);

type PlanCompareChartProps = {
    title: string;
    plan: PlanDTO;
    year: number;
};

const PlanCompareChart = ({ title, plan, year }: PlanCompareChartProps): JSX.Element => {
    const planObject = useSelector(getPlanObject);

    if (planObject.loading === true) {
        return (
            <Row style={{ marginLeft: '150px' }}>
                <Col>
                    <Spinner as="span" animation="border" size="sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        );
    }

    const chartOptions = {
        chart: {
            type: 'spline',
            height: '500'
        },
        title: {
            text: `${title} Workload`
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        colors: ['#2C3E50', '#68354C'],
        exporting: { enabled: false }
    };

    const categories = Array(10)
        .fill(0)
        .map((_item, index) => year + 1 + index);

    if (plan?.Demand?.length > 0) {
        const series: any[] = [];
        const totalDemandData = Array(10).fill(0);
        for (let index = 0; index < plan.Demand.length; ++index) {
            totalDemandData[0] += plan.Demand[index].Year1;
            totalDemandData[1] += plan.Demand[index].Year2;
            totalDemandData[2] += plan.Demand[index].Year3;
            totalDemandData[3] += plan.Demand[index].Year4;
            totalDemandData[4] += plan.Demand[index].Year5;
            totalDemandData[5] += plan.Demand[index].Year6;
            totalDemandData[6] += plan.Demand[index].Year7;
            totalDemandData[7] += plan.Demand[index].Year8;
            totalDemandData[8] += plan.Demand[index].Year9;
            totalDemandData[9] += plan.Demand[index].Year10;

            const demandData = Array(10).fill(0);
            demandData[0] += plan.Demand[index].Year1;
            demandData[1] += plan.Demand[index].Year2;
            demandData[2] += plan.Demand[index].Year3;
            demandData[3] += plan.Demand[index].Year4;
            demandData[4] += plan.Demand[index].Year5;
            demandData[5] += plan.Demand[index].Year6;
            demandData[6] += plan.Demand[index].Year7;
            demandData[7] += plan.Demand[index].Year8;
            demandData[8] += plan.Demand[index].Year9;
            demandData[9] += plan.Demand[index].Year10;

            series.push({
                name: `Demand (${plan.Demand[index].Type})`,
                data: demandData
            });
        }

        series.push({ name: 'Total Demand', data: totalDemandData });

        const options = {
            ...chartOptions,
            yAxis: {
                title: {
                    text: 'Demand'
                }
            },
            xAxis: {
                name: 'Year',
                categories
            },
            series
        };

        return (
            <>
                <div>
                    <div>
                        <div className="mt-5">
                            <ReactHighcharts config={options} />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Row style={{ textAlign: 'center' }}>
            <Col>
                <p>
                    No Demand Data Found For{' '}
                    {plan.Plan && plan.Plan.Id ? getPlanName(plan.Plan) : 'this plan.'}
                </p>
            </Col>
        </Row>
    );
};

export default PlanCompareChart;
