/**
 * Future Market Trends for Understand Demand.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import DDFContainer from 'Lib/DDFSlides/DDFContainer';
import SubTabComponent from 'Lib/Tabs/SubTabComponent';
import { getFutureMarketTrendsSelector } from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentMarketName } from 'Modules/UserSession/selector';

import UDFMTGroupTable from './UDFMTGroupTable';
import UDFMTSpecificTable from './UDFMTSpecificTable';

class UDFutureMarketTrends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }

    componentDidMount() {
        const { marketName } = this.props;
        const {
            hspcRvu,
            hspcGroupRvu,
            hspcUtilization,
            hspcGroupUtilization
        } = this.props.futureMarketTrends;

        // HspcGroupUtilization
        if (this.state.activeTab === 0 && marketName && hspcGroupUtilization.list.length === 0) {
            this.props.getFutureMarketTrends(marketName, 0, 0);
            this.props.getFutureMarketTrends(marketName, 1, 2);
        }

        // HspcUtilization
        if (this.state.activeTab === 1 && marketName && hspcUtilization.list.length === 0) {
            this.props.getFutureMarketTrends(marketName, 0, 1);
            this.props.getFutureMarketTrends(marketName, 1, 3);
        }

        // HspcGroupRvu
        if (this.state.activeTab === 2 && marketName && hspcGroupRvu.list.length === 0) {
            this.props.getFutureMarketTrends(marketName, 1, 2);
        }

        // HspcRvu
        if (this.state.activeTab === 3 && marketName && hspcRvu.list.length === 0) {
            this.props.getFutureMarketTrends(marketName, 1, 3);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { marketName: prevMarketName } = prevProps;
        const { marketName } = this.props;
        const {
            hspcRvu,
            hspcGroupRvu,
            hspcUtilization,
            hspcGroupUtilization
        } = this.props.futureMarketTrends;

        // HspcGroupUtilization
        if (
            (prevState.activeTab !== this.state.activeTab &&
                this.state.activeTab === 0 &&
                hspcGroupUtilization.list.length === 0) ||
            (prevState.activeTab === 0 && prevMarketName !== marketName)
        ) {
            this.props.getFutureMarketTrends(marketName, 0, 0);
            this.props.getFutureMarketTrends(marketName, 1, 2);
        }

        // HspcUtilization
        if (
            (prevState.activeTab !== this.state.activeTab &&
                this.state.activeTab === 1 &&
                hspcUtilization.list.length === 0) ||
            (this.state.activeTab === 1 && prevMarketName !== marketName)
        ) {
            this.props.getFutureMarketTrends(marketName, 0, 1);
            this.props.getFutureMarketTrends(marketName, 1, 3);
        }

        // HspcGroupRvu
        if (
            (prevState.activeTab !== this.state.activeTab &&
                this.state.activeTab === 2 &&
                hspcGroupRvu.list.length === 0) ||
            (this.state.activeTab === 2 && prevMarketName !== marketName)
        ) {
            this.props.getFutureMarketTrends(marketName, 1, 2);
        }

        // HspcRvu
        if (
            (prevState.activeTab !== this.state.activeTab &&
                this.state.activeTab === 3 &&
                hspcRvu.list.length === 0) ||
            (this.state.activeTab === 3 && prevMarketName !== marketName)
        ) {
            this.props.getFutureMarketTrends(marketName, 1, 3);
        }
    }

    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const tabs = [
            {
                key: 0,
                label: 'HSPC Group Utilization'
            },
            {
                key: 1,
                label: 'HSPC Utilization'
            },
            {
                key: 2,
                label: 'HSPC Group RVUs'
            },
            {
                key: 3,
                label: 'HSPC RVUs'
            }
        ];
        return (
            <div>
                <DDFContainer
                    text={'There is relevant data published in the Data Discovery and Findings.'}
                    reports={[
                        {
                            title: 'Estimated Current and Historic Market Demand - Outpatient',
                            subSections: [
                                {
                                    sectionHeader: 'Primary Care',
                                    sectionSlides: [26, 27, 28, 29, 30, 31, 165]
                                },
                                {
                                    sectionHeader: 'Outpatient',
                                    sectionSlides: [25, 33, 44, 47, 48, 46]
                                },
                                {
                                    sectionHeader: 'Specialty Care',
                                    sectionSlides: [35, 36, 37, 162, 39]
                                },
                                {
                                    sectionHeader: 'Urgent/Emergency',
                                    sectionSlides: [43, 169, 42, 156, 168]
                                },
                                {
                                    sectionHeader: 'Telehealth',
                                    sectionSlides: [40, 41, 161]
                                },
                                {
                                    sectionHeader: 'Mental Health',
                                    sectionSlides: [32, 34]
                                },

                                {
                                    sectionHeader: 'Core Trends and Top Services',
                                    sectionSlides: [24, 49]
                                }
                            ]
                        },
                        {
                            title: 'Estimated Future Market Demand - Outpatient',
                            subSections: [
                                {
                                    sectionHeader: 'Workload',
                                    sectionSlides: [56, 57, 58]
                                },
                                {
                                    sectionHeader: 'Outpatient Utilization Projections',
                                    sectionSlides: [52, 53, 54, 55, 189]
                                },
                                {
                                    sectionHeader:
                                        'Outpatient Parent Facility Utilization Projections',
                                    sectionSlides: [60, 61, 62, 63, 190],
                                    isFacilityReport: true
                                },
                                {
                                    sectionHeader: 'Facility and Overall Reports',
                                    sectionSlides: [51, 59, 64, 196]
                                }
                            ]
                        },
                        {
                            title: 'Estimated Current and Historic Market Demand - Inpatient',
                            subSections: [
                                {
                                    sectionHeader: 'Inpatient Demand',
                                    sectionSlides: [66]
                                },
                                {
                                    sectionHeader: 'Medical/Surgical BDOC',
                                    sectionSlides: [67, 68, 75, 77]
                                },
                                {
                                    sectionHeader: 'Mental Health',
                                    sectionSlides: [71, 72, 73]
                                },
                                {
                                    sectionHeader: 'Nursing Homes',
                                    sectionSlides: [78, 79, 80, 81]
                                },
                                {
                                    sectionHeader: 'Surgical Cases',
                                    sectionSlides: [84]
                                },
                                {
                                    sectionHeader: 'Community Care & Rehab',
                                    sectionSlides: [74, 82, 83]
                                },
                                {
                                    sectionHeader: 'Top in Field',
                                    sectionSlides: [69, 70, 157, 158, 159]
                                }
                            ]
                        },
                        {
                            title: 'Estimated Future Market Demand - Inpatient',
                            subSections: [
                                {
                                    sectionHeader:
                                        'Market Utilization Projections (Acute Inpatient)',
                                    sectionSlides: [86, 88]
                                },
                                {
                                    sectionHeader:
                                        'Market Utilization Projections (Other Inpatient)',
                                    sectionSlides: [87, 89]
                                },
                                {
                                    sectionHeader: 'Overall Inpatient Commercial Demand',
                                    sectionSlides: [90, 197]
                                }
                            ]
                        }
                    ]}
                ></DDFContainer>
                <div>
                    <div style={{ paddingTop: 15 }}>
                        <SubTabComponent
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            handleOnClick={this.changeTab}
                        />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        {this.state.activeTab === 0 ? (
                            <UDFMTGroupTable
                                filter="HSPC Group Utilization"
                                isExpandable
                                exportName="-HspcGroupUtil.csv"
                                expandTableType="HSPC Group RVUs"
                            />
                        ) : null}
                        {this.state.activeTab === 1 ? (
                            <UDFMTSpecificTable
                                filter="HSPC Utilization"
                                isExpandable
                                exportName="-HspcUtil.csv"
                                expandTableType="HSPC RVUs"
                            />
                        ) : null}
                        {this.state.activeTab === 2 ? (
                            <UDFMTGroupTable
                                filter="HSPC Group RVUs"
                                isExpandable={false}
                                exportName="-HspcGroupRvu.csv"
                            />
                        ) : null}
                        {this.state.activeTab === 3 ? (
                            <UDFMTSpecificTable
                                filter="HSPC RVUs"
                                isExpandable={false}
                                exportName="-HspcRvu.csv"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getFutureMarketTrends: (marketName, workloadType, tableId) =>
        dispatch.FutureMarketTrendsModel.fetchWorkloadAllocationMatrixAsync({
            marketName,
            workloadType,
            tableId
        })
});

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    futureMarketTrends: getFutureMarketTrendsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UDFutureMarketTrends);
