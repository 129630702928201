import { percentFormatter } from 'Utilities/formatters';

/**
 * Given the state and data from the MetricPopupModel, this function maps the series data as well
 * as the group data into the format of the metric table.
 *
 * @param {function} rowCheckBox - JSX code for the checkbox for each row
 * @param {function} columnHeaderCheckbox - JSX code for the column header checkbox
 * @param {string} groupColumnName - Name for the group column based on the data being shown
 * @returns {object} - column format for table
 */

const MetricColumns = (rowCheckBox, columnHeaderCheckbox, groupColumnName, actualColumn) => {
    return [
        {
            dataField: 'visible',
            sort: false,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAttrs: { tabIndex: 'false' },
            headerAlign: 'center',
            align: 'center',
            text: '',
            headerFormatter: columnHeaderCheckbox,
            formatter: (cell, row) => rowCheckBox(cell, row)
        },
        {
            dataField: 'HspType',
            text: groupColumnName(),
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'Description',
            text: 'Description',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'MeasureActual',
            text: 'Actual',
            headerFormatter: actualColumn,
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            formatter: percentFormatter
        }
    ];
};

export const MetricColumnDefs = (
    rowCheckBox,
    columnHeaderCheckbox,
    groupColumnName,
    actualColumn,
    customSortMetrics
) => [
    {
        id: 'visible',
        header: columnHeaderCheckbox,
        accessorKey: 'visible',
        enableSorting: false,
        headerAttrs: { tabIndex: 'false' },
        cell: ({ row, getValue }) => rowCheckBox(getValue(), row.original),
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'HspType',
        header: () => groupColumnName,
        sortingFn: customSortMetrics,
        meta: {
            headerDisplayName: groupColumnName,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'Description',
        header: 'Description',
        sortingFn: customSortMetrics,
        meta: {
            headerDisplayName: 'Description',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'MeasureActual',
        sortingFn: customSortMetrics,
        header: () => actualColumn(),
        meta: {
            headerDisplayName: 'Actual',
            formatter: percentFormatter,
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-right'
        }
    }
];

export default MetricColumns;
