import { commaFormatter, singlePlaceDecimalFormatter } from 'Utilities/formatters';

export const PrimaryCareCapacityModalColumns = (defaultYear) => {
    return [
        {
            dataField: 'PanelizedEnrollees',
            text: 'Panelized Enrollees',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            formatter: commaFormatter,
            editable: false
            // validator: (newValue, row, columns) => {
            //     var regex=/^[0-9]+([,.][0-9]+)?$/g;
            //     if(!regex.test(newValue)){
            //         return {
            //             valid: false,
            //             message: 'Input must be a number'
            //         };
            //     }
            //     return true;
            // }
        },
        {
            dataField: 'APPFTE',
            text: 'APP FTEs',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            formatter: singlePlaceDecimalFormatter,
            validator: (newValue, row, columns) => {
                var regex = /^[0-9]+([,.][0-9]+)?$/g;
                if (!regex.test(newValue)) {
                    return {
                        valid: false,
                        message: 'Input must be a number'
                    };
                }
                return true;
            }
        },
        {
            dataField: 'MDFTE',
            text: 'Physician FTEs',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            formatter: singlePlaceDecimalFormatter,
            validator: (newValue, row, columns) => {
                var regex = /^[0-9]+([,.][0-9]+)?$/g;
                if (!regex.test(newValue)) {
                    return {
                        valid: false,
                        message: 'Input must be a number'
                    };
                }
                return true;
            }
        },
        {
            dataField: 'PanelCapacity',
            text: 'Panel Capacity',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            formatter: commaFormatter,
            validator: (newValue, row, columns) => {
                var regex = /^[0-9]+([,.][0-9]+)?$/g;
                if (!regex.test(newValue)) {
                    return {
                        valid: false,
                        message: 'Input must be a number'
                    };
                }
                return true;
            }
        }
    ];
};

const PrimaryCareCapacityModalColumnDefs = [
    {
        accessorKey: 'PanelizedEnrollees',
        header: 'Panelized Enrollees',
        meta: {
            headerDisplayName: 'Panelized Enrollees',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '25%' },
            cellClassName: 'text-right',
            formatter: commaFormatter
        }
    },
    {
        accessorKey: 'APPFTE',
        header: 'APP FTEs',
        meta: {
            headerDisplayName: 'APP FTEs',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '25%' },
            cellClassName: 'text-right',
            formatter: singlePlaceDecimalFormatter,
            editable: true,
            editInputAttributes: { type: 'number' }
        }
    },
    {
        accessorKey: 'MDFTE',
        header: 'Physician FTEs',
        meta: {
            headerDisplayName: 'Physician FTEs',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '25%' },
            cellClassName: 'text-right',
            formatter: singlePlaceDecimalFormatter,
            editable: true,
            editInputAttributes: { type: 'number' }
        }
    },
    {
        accessorKey: 'PanelCapacity',
        header: 'Panel Capacity',
        meta: {
            headerDisplayName: 'Panel Capacity',
            columnClassName: 'text-right',
            columnStyle: { whiteSpace: 'pre-wrap', width: '25%' },
            cellClassName: 'text-right',
            formatter: commaFormatter,
            editable: true,
            editInputAttributes: { type: 'number' }
        }
    }
];

export default PrimaryCareCapacityModalColumnDefs;
