/**
 * Tab handler.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */
import React from 'react';
import { connect } from 'react-redux';

import { getDefaultYearObject } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import ModuleNavigation from '../../../../Lib/Tabs/ModuleNavigation';
import analytics from '../../../../Utilities/analytics';
import HeaderContainer from '../../../App/HeaderContainer';
import UDAnalysisTool from '../UDAnalysisTool';
import UDFutureMarketTrends from '../UDFutureMarketTrends';
import UDPatientOriginTool from '../UDPatientOriginTool';
import UDReviewProjectedDemand from '../UDReviewProjectedDemand';
import UDWaitTimes from '../UDWaitTimes';
import UDWorkloadAllocationMatrix from '../UDWorkloadAllocationMatrix';

class UDContainer extends React.Component {
    static propTypes = {
        defaultYear: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        document.title = 'HSPA - Understand Demand';
        this.state = { activeTab: 0 };
    }
    componentWillMount() {
        let analyticsObj = analytics.getInstance();
        analyticsObj.trackPageChange({ pathName: 'UnderstandDemand' });
        analyticsObj.trackTabChange('Review Projected Demand');
    }

    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const { defaultYear } = this.props;
        const tabs = [
            { key: 0, label: 'Review Projected Demand' },
            { key: 1, label: 'Patient Origin Tool' },
            { key: 2, label: 'Wait Times' },
            { key: 3, label: 'Future Market Trends' },
            { key: 4, label: 'Analysis Tools' }
            //{ key: 5, label: 'VISN Level Workload Allocation Matrix' }
        ];

        return (
            <div className="col-sm-12">
                <HeaderContainer pageName="Understand Demand" />
                <div>
                    <label style={{ fontSize: 13 }}>
                        In the {defaultYear.defaultYearWithBudgetStringShort} EHCPM, Facility
                        Utilization projections are provided at the parent facility level. In HSPA,
                        these projections are allocated to the treating facility level based on the
                        percentage of total parent’s workload the facility provided in{' '}
                        {defaultYear.defaultYearWithBudgetStringShort}.
                    </label>
                </div>
                <div>
                    <label style={{ fontSize: 13 }}>
                        <a
                            href="#"
                            onClick={() => this.props.fetchDataDefinitionsAsync()}
                            style={{ textDecoration: 'underline' }}
                        >
                            Download: {defaultYear.defaultYearWithBudgetStringShort} Projected
                            Utilization Data Definitions
                        </a>
                    </label>
                </div>
                {/* <div>
                    <label style={{ fontSize: 13 }}>
                        The COVID-19 pandemic had a significant impact on VA health care in FY21 and
                        is expected to impact the amount of care provided for the next few years.
                        During the pandemic, nationwide health care utilization saw a reduced amount
                        of care provided in FY20 and FY21 as individuals chose to defer certain
                        care. Typically, the EHCPM is updated annually to reflect emerging
                        information on the enrollee population and their utilization of VHA health
                        care. These analyses are generally tied to the most recent fiscal years of
                        data and establish assumptions that are based on this recent information.
                        Due to the uncertainty surrounding these years, FY19 and FY20 actuals have
                        been removed from HSPA.
                    </label>
                </div> */}
                <ModuleNavigation
                    activeTab={this.state.activeTab}
                    tabs={tabs}
                    handleOnClick={this.changeTab}
                />
                <div>
                    {this.state.activeTab === 0 ? <UDReviewProjectedDemand /> : null}
                    {this.state.activeTab === 1 ? <UDPatientOriginTool /> : null}
                    {this.state.activeTab === 2 ? <UDWaitTimes /> : null}
                    {this.state.activeTab === 3 ? <UDFutureMarketTrends /> : null}
                    {this.state.activeTab === 4 ? <UDAnalysisTool /> : null}
                    {this.state.activeTab === 5 ? <UDWorkloadAllocationMatrix /> : null}
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataDefinitionsAsync: () => dispatch.UserSessionModel.fetchDataDefinitionsAsync()
    };
};
const mapStateToProps = (state) => ({
    defaultYear: getDefaultYearObject(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(UDContainer);
