import './style.css';

import React from 'react';
import FontAwesome from 'react-fontawesome';
import Select from 'react-select';

import _map from 'lodash/map';

import proptypes from 'prop-types';

export default class DropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const options = _map(this.props.options, (item) => ({
            label: item[this.props.displayField],
            value: item[this.props.valueField]
        }));
        return (
            <div className="form-group row">
                <div className={'col-sm-' + this.props.labelPosition}>
                    <strong>{this.props.label === '' ? '' : this.props.label + ':'}</strong>
                </div>
                <div id={this.props.fieldId} className={'col-sm-' + this.props.inputPosition}>
                    <Select
                        aria-label={this.props.label}
                        isDisabled={this.props.disabled}
                        isMulti={this.props.isMultiselect}
                        options={options}
                        placeholder={this.props.placeholder}
                        isClearable={this.props.isClearable}
                        onChange={this.handleChange}
                        value={options.filter(({ value }) => `${value}` === `${this.props.value}`)}
                    />
                    {this.props.loading === true ? (
                        <div className="feedback-icon">
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
    handleChange = (option) => {
        if (this.props.onChangeFunction) this.props.onChangeFunction(option ? option.value : '');
    };
    static propTypes = {
        fieldId: proptypes.string.isRequired,
        value: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        placeholder: proptypes.string,
        options: proptypes.array.isRequired,
        loading: proptypes.bool.isRequired,
        displayField: proptypes.string.isRequired,
        valueField: proptypes.string.isRequired,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        disabled: proptypes.bool,
        onChangeFunction: proptypes.func,
        isClearable: proptypes.bool,
        isMultiselect: proptypes.bool
    };
}
DropdownField.defaultProps = {
    fieldId: '',
    placeholder: '',
    disabled: false,
    labelPosition: 2,
    inputPosition: 10,
    value: '',
    label: '',
    onChangeFunction: null,
    loading: false,
    options: [],
    isClearable: true,
    isMultiselect: false
};
