import React from 'react';
import { connect } from 'react-redux';

import { canEditVISN, getCurrentMarket } from 'Modules/UserSession/selector';

const HSPA_VIEWER_URL =
    'https://portal.vhagis.inv.vaec.va.gov/arcgis/apps/experiencebuilder/experience/?id=c8089c2dd01845f39e26c3cfa487e27f';

class ESDynamicMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapTitleValue: ''
        };
    }

    updateMapTitle = (e) => {
        this.setState({ mapTitleValue: e.target.value });
    };

    SaveMapURL = () => {
        let newMap = {};
        if (process.env.NODE_ENV === 'production') {
            document.domain = 'va.gov';
            newMap = {
                MarketName: this.props.currentMarket.MarketName,
                ReportText: `Title::${this.state.mapTitleValue}; URL::${
                    document.getElementById('mapiFrame').contentWindow.location.href
                }`
            };
        } else {
            newMap = {
                MarketName: this.props.currentMarket.MarketName,
                ReportText: `Title::${this.state.mapTitleValue}; URL::${
                    document.getElementById('mapiFrame').src
                }`
            };
        }

        this.props.saveNewMap(newMap);
    };

    render() {
        // const marketInHSPAViewerURL = `${HSPA_VIEWER_URL}#?entity=MARKETDISPLAY,${this.props.currentMarket.MarketId}`;
        const marketInHSPAViewerURL = HSPA_VIEWER_URL;

        return (
            <div>
                <p>
                    {' '}
                    Using the VHA GIS Insite Planning Portal tools, review the destiny of enrolled
                    Veteran population in your area, nothing their proximity to the nearest VA
                    facility. These maps are useful when presenting potential strategic solutions to
                    other local leadership and stakeholders.
                </p>
                <p>NOTE: The GIS Data Viewer may take some time to load. Please be patient.</p>
                {/* <div className="col-md-12" style={{ marginBottom: '5px' }}>
                    <div className="col-md-6">
                        <TextboxField
                            label="Enter Map Title"
                            placeholder="Enter Map Title..."
                            value={this.state.mapTitleValue}
                            onChangeFunction={this.updateMapTitle}
                            labelPosition={12}
                            inputPosition={12}
                        />
                    </div>
                    <div className="col-md-6" style={{ marginTop: '18px' }}>
                        <Button
                            label="Save Map"
                            disabled={!this.props.canEdit}
                            onClick={this.SaveMapURL}
                        />
                    </div>
                </div>
                <br /> */}
                <div>
                    <iframe
                        id="mapiFrame"
                        title="GIS Data Viewer"
                        src={marketInHSPAViewerURL}
                        style={{ width: '100%', height: '750px' }}
                    />
                </div>
                <a
                    id="mapAnchorTag"
                    href={marketInHSPAViewerURL}
                    style={{ color: '#0000FF', textAlign: 'left' }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Larger Map
                </a>
                <br />
                <p>
                    If the dynamic mapping tool doesn't load, then please make sure the application
                    is opened in Internet Explorer.
                </p>
                <p>
                    If you experience any issues viewing this map, please follow the directions in
                    the
                    <a href="Documents/HSPA_Compatibility_View_Setting.pdf" target="_blank">
                        &nbsp;linked PDF.
                    </a>
                </p>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveNewMap: dispatch.SavedMapsModel.saveAsync
    };
};

const mapStateToProps = (state) => {
    return {
        currentMarket: getCurrentMarket(state),
        canEdit: canEditVISN(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESDynamicMapping);
