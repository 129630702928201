import { createColumnHelper } from '@tanstack/react-table';

const numeral = require('numeral');

const numberFormat = (cell, row) => {
    if (cell === null || cell === '') {
        return '';
    }

    if (row.RowName === 'Percentage of In-House Capacity Utilized') {
        if (cell === Infinity) {
            return 'Infinity%';
        }

        return `${numeral(cell).format('0,0.00')}%`;
    }

    if (row.RowName === 'Imbalance') {
        return numeral(cell).format('0,0.00');
    }

    return numeral(cell).format('0,0');
};

const columnHelper = createColumnHelper();

/**
 * Adds "FTE: " to the front of the row name field if staffing calculation is set.
 *
 * @param {string} rowName - The name of the row.
 * @param {string} calculationType - The calculation type.
 * @returns {Function} - Returns a function for formatting.
 */
const nameFormatter = (rowName, calculationType) => {
    switch (calculationType) {
        case 'Staffing':
            return `FTE: ${rowName}`;
        default:
            return rowName;
    }
};

const GenerateSolutionTableColumns = (defaultYear, calculationType) => {
    if (!defaultYear) {
        return [];
    }

    if (calculationType !== 'Cost') {
        return [
            columnHelper.display({
                id: 'blank-expand',
                header: '',
                cell: () => null,
                meta: {
                    columnClassName: 'text-center',
                    columnStyle: { width: '34px' },
                    cellClassName: 'text-center',
                    cellStyle: { width: '34px' }
                }
            }),
            {
                accessorKey: 'RowName',
                header: 'Solutions',
                meta: {
                    headerDisplayName: 'Solutions',
                    columnClassName: 'text-center',
                    columnStyle: { width: '20%' },
                    cellStyle: { whiteSpace: 'normal', width: '20%' },
                    formatter: (value) => nameFormatter(value, calculationType)
                }
            },
            {
                accessorKey: 'Year0',
                header: defaultYear,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year1',
                header: defaultYear + 1,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year2',
                header: defaultYear + 2,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year3',
                header: defaultYear + 3,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year4',
                header: defaultYear + 4,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year5',
                header: defaultYear + 5,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year6',
                header: defaultYear + 6,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year7',
                header: defaultYear + 7,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year8',
                header: defaultYear + 8,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year9',
                header: defaultYear + 9,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            {
                accessorKey: 'Year10',
                header: defaultYear + 10,
                cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
                meta: {
                    columnClassName: 'text-right',
                    columnStyle: { width: '6.66%' },
                    cellClassName: 'text-right',
                    cellStyle: { width: '6.66%' }
                }
            },
            columnHelper.display({
                id: 'blank-1',
                header: '',
                cell: () => null,
                meta: {
                    columnClassName: 'text-center',
                    columnStyle: { width: '45px' },
                    cellClassName: 'text-center',
                    cellStyle: { width: '45px' }
                }
            }),
            columnHelper.display({
                id: 'blank-2',
                header: '',
                cell: () => null,
                meta: {
                    columnClassName: 'text-center',
                    columnStyle: { width: '45px' },
                    cellClassName: 'text-center',
                    cellStyle: { width: '45px' }
                }
            })
        ];
    }

    return [
        columnHelper.display({
            id: 'blank-expand',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '34px' },
                cellClassName: 'text-center',
                cellStyle: { width: '34px' }
            }
        }),
        {
            accessorKey: 'RowName',
            header: 'Solutions',
            meta: {
                headerDisplayName: 'Solutions',
                columnClassName: 'text-center',
                columnStyle: { width: '20%' },
                cellStyle: { whiteSpace: 'normal', width: '20%' },
                formatter: (value) => nameFormatter(value, calculationType)
            }
        },
        {
            accessorKey: 'Year5',
            header: defaultYear + 5,
            cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.66%' },
                cellClassName: 'text-right',
                cellStyle: { width: '14.66%' }
            }
        },
        {
            accessorKey: 'Year6',
            header: defaultYear + 6,
            cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.66%' },
                cellClassName: 'text-right',
                cellStyle: { width: '14.66%' }
            }
        },
        {
            accessorKey: 'Year7',
            header: defaultYear + 7,
            cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.66%' },
                cellClassName: 'text-right',
                cellStyle: { width: '14.66%' }
            }
        },
        {
            accessorKey: 'Year8',
            header: defaultYear + 8,
            cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.66%' },
                cellClassName: 'text-right',
                cellStyle: { width: '14.66%' }
            }
        },
        {
            accessorKey: 'Year9',
            header: defaultYear + 9,
            cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.66%' },
                cellClassName: 'text-right',
                cellStyle: { width: '14.66%' }
            }
        },
        {
            accessorKey: 'Year10',
            header: defaultYear + 10,
            cell: ({ getValue, row }) => numberFormat(getValue(), row.original),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { width: '14.66%' },
                cellClassName: 'text-right',
                cellStyle: { width: '14.66%' }
            }
        },
        columnHelper.display({
            id: 'blank-1',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' }
            }
        }),
        columnHelper.display({
            id: 'blank-2',
            header: '',
            cell: () => null,
            meta: {
                columnClassName: 'text-center',
                columnStyle: { width: '45px' },
                cellClassName: 'text-center',
                cellStyle: { width: '45px' }
            }
        })
    ];
};

export default GenerateSolutionTableColumns;
