import React from 'react';
import styled from 'styled-components/macro';
import './style.css';

type StateCardProps = {
    id: number;
    name: string,
    url: string,
    image: string
};

/**
 * A component that displays a preview of metric data in a card.  It displays a projection value,
 * an icon for the projected direction, and a small bar graph showing the past values.
 *
 * @param state - props passed into this component
 * @param state.name - state name
 * @param state.image - state icon
 * @param state.url - url of the state policy reference
 * @returns the metric card
 */
const StateCard = (state: StateCardProps): JSX.Element => {

    return (
        <a
            className="stateCardContainer"
            href={state.url}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img className="stateIcon" src={state.image} alt={state.name} />
            <label style={{ margin: 'auto', marginBottom: '0px', cursor: "pointer" }} >{state.name}</label>
        </a>
    );
};

export default StateCard;
