import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { deleteRequest, getJSON, postJSON } from 'Utilities/apiCalls';

const capacityApi = `${process.env.REACT_APP_HSPA_API}/UnderstandCapacity/`;

export const VaFacilitiesModel = {
    state: {
        vaFacilities: { list: [], loading: false },
        facilityModal: {
            statesDrp: { list: [], loading: false },
            statusDrp: { list: [], loading: false },
            facilityForm: { formData: {}, saving: false, loading: false },
            show: false
        }
    },
    reducers: {
        populate(state, data, objectName) {
            switch (objectName) {
                case 'vaFacilities':
                    return {
                        ...state,
                        vaFacilities: {
                            ...state.vaFacilities,
                            list: data
                        }
                    };
                case 'statesDrp':
                    return {
                        ...state,
                        facilityModal: {
                            ...state.facilityModal,
                            statesDrp: {
                                ...state.facilityModal.statesDrp,
                                list: data
                            }
                        }
                    };
                case 'statusDrp':
                    return {
                        ...state,
                        facilityModal: {
                            ...state.facilityModal,
                            statusDrp: {
                                ...state.facilityModal.statusDrp,
                                list: data
                            }
                        }
                    };
                default:
                    return state;
            }
        },
        toggleLoading(state, objectName) {
            switch (objectName) {
                case 'vaFacilities':
                    return {
                        ...state,
                        vaFacilities: {
                            ...state.vaFacilities,
                            loading: !state.vaFacilities.loading
                        }
                    };
                case 'statesDrp':
                    return {
                        ...state,
                        facilityModal: {
                            ...state.facilityModal,
                            statesDrp: {
                                ...state.facilityModal.statesDrp,
                                loading: !state.facilityModal.statesDrp.loading
                            }
                        }
                    };
                case 'statusDrp':
                    return {
                        ...state,
                        facilityModal: {
                            ...state.facilityModal,
                            statusDrp: {
                                ...state.facilityModal.statusDrp,
                                loading: !state.facilityModal.statusDrp.loading
                            }
                        }
                    };
                default:
                    return state;
            }
        },
        populateForm(state, id) {
            let facility = Object.assign(
                {},
                state.vaFacilities.list.find((o) => o.Id === id)
            );
            let date = new Date(facility.AnticipatedDate);
            if (date.getMonth() + 1 < 10)
                facility.AnticipatedDate = '0' + (date.getMonth() + 1) + '/' + date.getFullYear();
            else facility.AnticipatedDate = date.getMonth() + 1 + '/' + date.getFullYear();

            return {
                ...state,
                facilityModal: {
                    ...state.facilityModal,
                    facilityForm: {
                        ...state.facilityModal.facilityForm,
                        formData: facility
                    }
                }
            };
        },
        toggleFormLoading(state) {
            return {
                ...state,
                facilityModal: {
                    ...state.facilityModal,
                    facilityForm: {
                        ...state.facilityModal.facilityForm,
                        loading: !state.facilityModal.facilityForm.loading
                    }
                }
            };
        },
        toggleFormSaving(state) {
            return {
                ...state,
                facilityModal: {
                    ...state.facilityModal,
                    facilityForm: {
                        ...state.facilityModal.facilityForm,
                        saving: !state.facilityModal.facilityForm.saving
                    }
                }
            };
        },
        toggleFacilityModal(state) {
            return {
                ...state,
                facilityModal: {
                    ...state.facilityModal,
                    show: !state.facilityModal.show
                }
            };
        },
        clearForm(state) {
            return {
                ...state,
                facilityModal: {
                    ...state.facilityModal,
                    facilityForm: {
                        ...state.facilityModal.facilityForm,
                        formData: {}
                    }
                }
            };
        },
        resetTable(state) {
            return {
                ...state,
                vaFacilities: {
                    ...state.vaFacilities,
                    list: []
                }
            };
        },
        reset(state) {
            return {
                ...state,
                vaFacilities: { list: [], loading: false },
                facilityModal: {
                    ...state.facilityModal,
                    // statesDrp:      { list: [], loading: false},
                    // statusDrp:      { list: [], loading: false},
                    facilityForm: { formData: {}, saving: false, generating: false, loading: false }
                }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchVaFacilitiesAsync(market, state) {
            try {
                this.resetTable();
                this.toggleLoading('vaFacilities');
                let result = await getJSON(`${capacityApi}GetFacilitiesByMarket?market=${market}`);
                this.populate(result, 'vaFacilities');
                this.toggleLoading('vaFacilities');
            } catch (error) {
                this.toggleLoading('vaFacilities');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch VA facilities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchStateDropdownOptionsAsync(state) {
            try {
                this.toggleLoading('statesDrp');
                let result = await getJSON(`${capacityApi}GetFacilityStateOptions`);
                this.populate(result, 'statesDrp');
                this.toggleLoading('statesDrp');
            } catch (error) {
                this.toggleLoading('statesDrp');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch state dropdown options',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchStatusDropdownOptionsAsync(state) {
            try {
                this.toggleLoading('statusDrp');
                let result = await getJSON(`${capacityApi}GetFacilityStatusOptions`);
                this.populate(result, 'statusDrp');
                this.toggleLoading('statusDrp');
            } catch (error) {
                this.toggleLoading('statusDrp');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch facility status dropdown options',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveVaFacilityAsync(formValues, state) {
            try {
                this.toggleFormSaving();
                await postJSON(`${capacityApi}SaveFacility`, formValues);
                this.toggleFormSaving();
                dispatch(
                    addNotification({
                        message: 'The VA facility has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchVaFacilitiesAsync(formValues.Market);
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save market description',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async deleteVaFacilityAsync(payload, state) {
            try {
                await deleteRequest(`${capacityApi}DeleteVaFacility?id=${payload.id}`);
                dispatch(
                    addNotification({
                        message: 'The VA facility has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchVaFacilitiesAsync(payload.market);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to delete VA facility',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
