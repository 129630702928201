import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON, putJSON } from 'Utilities/apiCalls';

const siteActionApiUrl = `${process.env.REACT_APP_HSPA_API}/SiteAction/`;

export const AdministrationModel = {
    state: {
        show: false,
        userQuestions: { list: [], loading: false },
        applicationAnalytics: { list: [], loading: false },
        submittingResponse: false
    },
    reducers: {
        toggle(state) {
            return {
                ...state,
                show: !state.show
            };
        },
        updateLoading(state, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: !state[object].loading
                }
            };
        },
        updateSubmitting(state) {
            return {
                ...state,
                submittingResponse: !state.submittingResponse
            };
        },
        populate(state, object, data) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    list: data
                }
            };
        },
        reset(state) {
            return {
                ...state,
                userQuestions: { list: [], loading: false },
                applicationAnalytics: { list: [], loading: false },
                submittingResponse: false
            };
        }
    },
    effects: (dispatch) => ({
        async fetchUserQuestionsAsync(state) {
            try {
                this.updateLoading('userQuestions');
                let result = await getJSON(`${siteActionApiUrl}GetQuestions`);
                this.updateLoading('userQuestions');
                this.populate('userQuestions', result);
            } catch (error) {
                this.updateLoading('userQuestions');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch user questions',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchApplicationAnalytics(state) {
            try {
                this.updateLoading('applicationAnalytics');
                let result = await getJSON(`${siteActionApiUrl}GetUserActivity`);
                this.updateLoading('applicationAnalytics');
                this.populate('applicationAnalytics', result);
            } catch (error) {
                this.updateLoading('applicationAnalytics');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch application analytics',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async submitQuestionResponseAsync(formValues, state) {
            try {
                this.updateSubmitting();
                await putJSON(`${siteActionApiUrl}SubmitQuestionResponse`, formValues);
                this.updateSubmitting();
                dispatch(
                    addNotification({
                        message: 'Your response has been submitted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.reset();
                this.fetchUserQuestionsAsync();
            } catch (error) {
                this.updateSubmitting();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to submit response',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
