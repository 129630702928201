import { combineEpics } from 'redux-observable';

import createCapitalProject from './createCapitalProjects';
import deleteCapitalProject from './deleteCapitalProject';
import loadCapitalProjects from './loadCapitalProjects';
import setCapitalProjectApproval from './setCapitalProjectApproval';

export default combineEpics(
    loadCapitalProjects,
    createCapitalProject,
    deleteCapitalProject,
    setCapitalProjectApproval
);
