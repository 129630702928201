import React from 'react';
import proptypes from 'prop-types';
import { Field } from 'redux-form';
import Select from 'react-select';
import _find from 'lodash/find';
import _map from 'lodash/map';
import FontAwesome from 'react-fontawesome';
import './style.css';

export default class DropdownField extends React.Component {
    render() {
        return (
            <Field
                name={this.props.name}
                label={this.props.label}
                aria-label={this.props.label}
                placeholder={
                    this.props.placeholder === ''
                        ? 'Enter ' + this.props.label.toLowerCase() + '...'
                        : this.props.placeholder
                }
                options={this.props.options}
                loading={this.props.loading}
                displayField={this.props.displayField}
                value={this.props.value}
                valueField={this.props.valueField}
                disabled={this.props.disabled}
                labelPosition={this.props.labelPosition}
                inputPosition={this.props.inputPosition}
                onChangeFunction={this.props.onChangeFunction}
                component={DropdownField.renderDropDownField}
            />
        );
    }
    static renderDropDownField(props) {
        let validationState = '';
        if (props.meta.touched) {
            if (props.meta.error) validationState = 'invalidDropdown';
            else validationState = 'validDropdown';
        }

        function handleChange({ value }) {
            if (props.onChangeFunction) props.onChangeFunction(value, props.input.name);
            props.input.onChange(value);
        }
        const options = _map(props.options, (item) => ({
            label: item[props.displayField],
            value: item[props.valueField]
        }));
        return (
            <div className={'form-group row'}>
                <div className={'col-sm-' + props.labelPosition + ' col-form-label'}>
                    <strong>{props.label + ':'}</strong>
                </div>
                <div className={'col-sm-' + props.inputPosition}>
                    <Select
                        className={validationState}
                        {...props.input}
                        closeOnSelect={true}
                        disabled={props.disabled}
                        multi={false}
                        options={options}
                        placeholder={props.placeholder}
                        onChange={handleChange}
                        value={options.filter(({ value }) => `${value}` === `${props.input.value}`)}
                        aria-label={props.label}
                    />
                    {props.loading === true ? (
                        <div className="feedback-icon">
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </div>
                    ) : null}
                    <span className="invalid-feedback d-block">
                        {props.meta.touched && props.meta.error ? props.meta.error : null}
                    </span>
                </div>
            </div>
        );
    }
    static propTypes = {
        name: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        placeholder: proptypes.string,
        options: proptypes.array.isRequired,
        loading: proptypes.bool.isRequired,
        displayField: proptypes.string.isRequired,
        valueField: proptypes.string.isRequired,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        disabled: proptypes.bool,
        onChangeFunction: proptypes.func
    };
}
DropdownField.defaultProps = {
    placeholder: '',
    disabled: false,
    labelPosition: 2,
    inputPosition: 10,
    value: '',
    loading: false,
    onChangeFunction: null
};

// return(
//     <FormGroup validationState={!props.meta.touched ? null : (props.meta.error ? 'error': 'success')}>
//         <Col sm={props.labelPosition}><strong>{props.label + ':'}</strong></Col>
//         <Col sm={props.inputPosition}>
//             <Select
//                 {...props.input}
//                 closeOnSelect={true}
//                 disabled={props.disabled}
//                 multi={false}
//                 options={_map(props.options, (item) => { return { label: item[props.displayField], value: item[props.valueField] }; })}
//                 placeholder={props.placeholder}
//                 simpleValue
//                 onChange={handleChange}
//                 value={props.input.value}
//             />
//             <FormControl.Feedback>
//                 {
//                     (props.loading === true) ? <span><FontAwesome name="check" spin className="fa fa-spinner"/></span> : ''
//                 }
//             </FormControl.Feedback>
//             <HelpBlock>{props.meta.touched && props.meta.error ? props.meta.error : null}</HelpBlock>
//         </Col>
//     </FormGroup>
// );
