import React, { useState } from 'react';
import { Button, Card, Col, ListGroup, Modal, Row } from 'react-bootstrap';

import { CapitalProject, getTotalSquareFootage } from 'Utilities/types';

type SquareFootageModalProps = {
    capitalProject: CapitalProject;
};

/**
 * Formats the square footage number for display.
 *
 * @param squareFootage - The square footage number.
 * @param isDisposalProject - Whether or not the square footage represents a disposal project.
 * @returns - The formated square footage string.
 */
function formatSquareFootage(
    squareFootage: number | undefined,
    isDisposalProject: boolean
): string {
    return isDisposalProject && squareFootage && squareFootage > 0
        ? `{${squareFootage.toLocaleString()}}`
        : (squareFootage || 0).toLocaleString();
}

/**
 * Builds the Square Footage Modal.
 *
 * @param props - The component properties.
 * @param props.capitalProject - The Capital Project that this modal is showing.
 * @returns - The Component JSX.
 */
const SquareFootageModal = ({ capitalProject }: SquareFootageModalProps) => {
    const [showModal, setShowModal] = useState(false);

    /**
     * Handles when the Modal is closed.
     */
    const handleModalClose = () => setShowModal(false);

    /**
     * Handles when the Modal is opened.
     */
    const handleModalOpen = () => setShowModal(true);

    return (
        <>
            <Button variant="link" onClick={handleModalOpen} style={{ float: 'right' }}>
                {formatSquareFootage(
                    getTotalSquareFootage(capitalProject),
                    capitalProject.isDisposalProject
                )}
            </Button>
            <Modal
                show={showModal}
                onHide={handleModalClose}
                size="lg"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Square Footage for the{' '}
                        {capitalProject.isDisposalProject ? 'Disposal' : 'In-Process'} Project{' '}
                        {capitalProject.projectNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Inpatient</Card.Header>
                                <ListGroup>
                                    <ListGroup.Item>
                                        Acute Inpatient Medicine & Surgery:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.acuteInpatientMedicineSurgery,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Inpatient Mental Health:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.inpatientMentalHealth,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Acute Special Program:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.acuteSpecialPrograms,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>Long Term Care</Card.Header>
                                <ListGroup>
                                    <ListGroup.Item>
                                        CLC/Hospice:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.clcHospice,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>Outpatient</Card.Header>
                                <ListGroup>
                                    <ListGroup.Item>
                                        Ancillary/Diagnostic:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.ancillaryDiagnostic,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Primary Care:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.primaryCare,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Specialty Care:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.specialtyCare,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Mental Health:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.mentalHealth,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>Other</Card.Header>
                                <ListGroup>
                                    <ListGroup.Item>
                                        Administration:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.administration,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Support:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.support,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Common/Swing/Construction:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.commonSwingConstruction,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        Research:{' '}
                                        <span style={{ float: 'right' }}>
                                            {formatSquareFootage(
                                                capitalProject.research,
                                                capitalProject.isDisposalProject
                                            )}
                                        </span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SquareFootageModal;
