import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';

import { getGraphDataSelector, getGraphDrilldownSelector } from '../../Services/selector';
import UserActivityColumnDefs from './TableOutline';

var ReactHighcharts = require('react-highcharts');
var HighchartsMore = require('highcharts-more');
HighchartsMore(ReactHighcharts.Highcharts);

var HighchartsDrilldown = require('highcharts-drilldown')
HighchartsDrilldown(ReactHighcharts.Highcharts);

var HighchartsExporting = require('highcharts-exporting');
HighchartsExporting(ReactHighcharts.Highcharts);

class SAApplicationAnalytics extends React.Component {
    componentDidMount(){
        if(this.props.analytics.list.length === 0)
            this.props.fetchApplicationAnalytics();
    }
    render() {
        var options = {
            chart: { type: 'column' },
            title: { text: 'HSPA Page Visits' },
            xAxis: { type: 'category' },
            yAxis: {
                min: 0,
                title: {
                    text: '# of Page Visits'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                headerFormat: null,
                pointFormatter: function() {
                    if(this.hasOwnProperty("drilldown")) {
                        return '<tr><td style="color:{this.color};padding:0">'+this.name +':</td><td style="padding:0"><b> '+this.y+'</b></td></tr>';
                    }
                    else {
                        return '<span style="font-size:10px">' + this.options.visn + '</span><table><br/>' +
                        '<tr><td style="color:{this.color};padding:0">'+this.name +':</td><td style="padding:0"><b> '+this.y+'</b></td></tr>';
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: "VISN View",
                colorByPoint: true,
                data: this.props.graphData
            }],
            drilldown: {
                series: this.props.drilldownData
            }
        };

        return (
            <div>
                <ReactHighcharts config={options} ref="chart"></ReactHighcharts>
                <br/>
                <ReactDataTable
                    keyValue="Id"
                    list={this.props.analytics.list.sort((a,b) => { return new Date(b.StartTime).getTime() - new Date(a.EndTime).getTime(); })}
                    loading={this.props.analytics.loading}
                    columns={UserActivityColumnDefs}
                    csvFileName={"UserActivity.csv"}/>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchApplicationAnalytics: () => dispatch.AdministrationModel.fetchApplicationAnalytics()
    }
}

const mapStateToProps = state => ({
    analytics: state.AdministrationModel.applicationAnalytics,
    graphData: getGraphDataSelector(state),
    drilldownData: getGraphDrilldownSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SAApplicationAnalytics)