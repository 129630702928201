import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const understandDemandApi = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand/`;

export const FutureMarketTrendsModel = {
    state: {
        hspcGroupUtilization: { list: [], loading: false },
        hspcUtilization: { list: [], loading: false },
        hspcGroupRvu: { list: [], loading: false },
        hspcRvu: { list: [], loading: false }
    },
    reducers: {
        populate(state, data, tableId) {
            let tableName = '';
            if (tableId === 0) tableName = 'hspcGroupUtilization';
            else if (tableId === 1) tableName = 'hspcUtilization';
            else if (tableId === 2) tableName = 'hspcGroupRvu';
            else if (tableId === 3) tableName = 'hspcRvu';

            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, tableId) {
            let tableName = '';
            if (tableId === 0) tableName = 'hspcGroupUtilization';
            else if (tableId === 1) tableName = 'hspcUtilization';
            else if (tableId === 2) tableName = 'hspcGroupRvu';
            else if (tableId === 3) tableName = 'hspcRvu';

            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: isLoading
                }
            };
        },
        resetTable(state, tableId) {
            let tableName = '';
            if (tableId === 0) tableName = 'hspcGroupUtilization';
            else if (tableId === 1) tableName = 'hspcUtilization';
            else if (tableId === 2) tableName = 'hspcGroupRvu';
            else if (tableId === 3) tableName = 'hspcRvu';

            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false
                }
            };
        },
        reset(state) {
            return {
                hspcGroupUtilization: { list: [], loading: false },
                hspcUtilization: { list: [], loading: false },
                hspcGroupRvu: { list: [], loading: false },
                hspcRvu: { list: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchWorkloadAllocationMatrixAsync(payload, state) {
            try {
                this.resetTable(payload.tableId);
                this.updateLoading(true, payload.tableId);
                let result = {};
                if (payload.tableId === 0 || payload.tableId === 2)
                    result = await getJSON(
                        `${understandDemandApi}GetHspcWorkloadGroupByHspcGroupEnrolleeMarket?marketName=` +
                            payload.marketName +
                            '&workloadType=' +
                            payload.workloadType +
                            '&searchByHspc=' +
                            false
                    );
                else if (payload.tableId === 1 || payload.tableId === 3)
                    result = await getJSON(
                        `${understandDemandApi}GetHspcWorkloadGroupByHspcGroupEnrolleeMarket?marketName=` +
                            payload.marketName +
                            '&workloadType=' +
                            payload.workloadType +
                            '&searchByHspc=' +
                            true
                    );
                this.updateLoading(false, payload.tableId);
                this.populate(result, payload.tableId);
            } catch (error) {
                this.updateLoading(false, payload.tableId);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch future market trends',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
