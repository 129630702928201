/**
 * Displays a table of all facilities in the current market and links to open their facility-level
 * reports.
 *
 * @author Brandan D. Reed
 */
import React from 'react';

import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';

import FacilitySelectButton from './FacilitySelectButton';

/**
 * Render a table of facilities with links to the facility-level reports.
 *
 * @param {object} props - props passed into this component
 * @returns {React.ReactNode} the rendered table component
 */
const FacilityTable = ({ facilities, className }) => {
    const facilityLines = facilities.map((facility) => (
        <tr key={facility}>
            <td className="text-center">{facility.Name}</td>
            <td className="text-center">
                <FacilitySelectButton facility={facility} />
            </td>
        </tr>
    ));

    const body =
        facilityLines.length === 0 ? (
            <tr>
                <td colSpan="2" style={{ color: 'gray', fontStyle: 'italic', textAlign: 'center' }}>
                    No facilities loaded.
                </td>
            </tr>
        ) : (
            facilityLines
        );

    return (
        <Table striped bordered hover className={className}>
            <thead>
                <th className="text-center" style={{ width: '60%' }}>
                    Facility
                </th>
                <th className="text-center">Select</th>
            </thead>
            <tbody>{body}</tbody>
        </Table>
    );
};

FacilityTable.propTypes = {
    /** Array of facility names. */
    facilities: PropTypes.arrayOf(PropTypes.string),
    /** CSS class string to apply to this component. */
    className: PropTypes.string
};

FacilityTable.defaultProps = {
    facilities: [],
    className: ''
};

export default FacilityTable;
