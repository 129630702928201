import React, { useState } from 'react';
import { Button, Card, Col, ListGroup, Modal, Row } from 'react-bootstrap';

import { map } from 'lodash';

import { CapitalProject } from 'Utilities/types';

type CapitalProjectLinksModalProps = {
    capitalProject: CapitalProject;
};

const CapitalProjectLinksModal = ({ capitalProject }: CapitalProjectLinksModalProps) => {
    const [showModal, setShowModal] = useState(false);

    /**
     * Handles when the Modal is closed.
     */
    const handleModalClose = () => setShowModal(false);

    /**
     * Handles when the Modal is opened.
     */
    const handleModalOpen = () => setShowModal(true);

    const plansListGroupItems = map(
        capitalProject.plans,
        (p): JSX.Element => <ListGroup.Item key={p.id}>{p.name || `Plan #${p.id}`}</ListGroup.Item>
    );

    const hspcListGroupItems = map(
        capitalProject.specialties,
        (s): JSX.Element => <ListGroup.Item key={s.id}>{s.name}</ListGroup.Item>
    );

    return (
        <>
            <Button
                variant="secondary"
                onClick={handleModalOpen}
                style={{ float: 'right', borderRadius: '16px' }}
            >
                Plans
            </Button>
            <Modal
                show={showModal}
                onHide={handleModalClose}
                size="xl"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Linked Plans and Specialties for {capitalProject.projectNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Plans</Card.Header>
                                <ListGroup>{plansListGroupItems}</ListGroup>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>Specialties</Card.Header>
                                <ListGroup>{hspcListGroupItems}</ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CapitalProjectLinksModal;
