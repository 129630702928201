import React from 'react';
import proptypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import './style.css';
export default class TextboxField extends React.Component {
    render() {
        return (
            <div className="form-group row">
                <div className={'col-sm-' + this.props.labelPosition + ' col-form-label'}>
                    <strong>{this.props.label === '' ? '' : this.props.label + ':'}</strong>
                </div>
                <div className={'col-sm-' + this.props.inputPosition}>
                    <input
                        type="text"
                        aria-label={this.props.label}
                        className="form-control"
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        onChange={this.props.onChangeFunction}
                    />
                    {this.props.loading === true ? (
                        <span className="feedback-icon">
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }
    handleChange = (option) => {
        if (this.props.onChangeFunction) this.props.onChangeFunction(option);
    };
    static propTypes = {
        value: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        placeholder: proptypes.string,
        loading: proptypes.bool.isRequired,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        disabled: proptypes.bool,
        onChangeFunction: proptypes.func
    };
}
TextboxField.defaultProps = {
    placeholder: '',
    disabled: false,
    labelPosition: 2,
    inputPosition: 10,
    value: '',
    label: '',
    onChangeFunction: null,
    loading: false
};
