import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const reportsApiUrl = `${process.env.REACT_APP_HSPA_API}/Report/`;

export const SavedReportModel = {
    state: {
        userReports: { list: [], generating: false, loading: false },
        templateReports: { list: [], generating: false, loading: false }
    },
    reducers: {
        populate(state, data, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: isLoading
                }
            };
        },
        updateGenerating(state, isGenerating, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    generating: isGenerating
                }
            };
        },
        updateSaving(state, isSaving, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    saving: isSaving
                }
            };
        },
        resetTable(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false,
                    generating: false
                }
            };
        },
        resetTables(state) {
            return {
                ...state,
                userReports: { list: [], loading: false },
                templateReports: { list: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchUserReportsAsync(market, state) {
            try {
                this.resetTable('userReports');
                this.updateLoading(true, 'userReports');
                let result = await getJSON(
                    `${reportsApiUrl}GetReportsByMarketType?marketname=${market}`
                );
                this.populate(result, 'userReports');
                this.updateLoading(false, 'userReports');
            } catch (error) {
                this.updateLoading(false, 'userReports');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch user reports',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchTemplateReportsAsync(market, state) {
            try {
                this.resetTable('templateReports');
                this.updateLoading(true, 'templateReports');
                let result = await getJSON(`${reportsApiUrl}GetReportsByType`);
                this.populate(result, 'templateReports');
                this.updateLoading(false, 'templateReports');
            } catch (error) {
                this.updateLoading(false, 'templateReports');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch template reports',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
