/**
 * Modal for modifying available beds per facility.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';

import Button from 'Lib/Button';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import { getAllCapacitySelector } from 'Modules/UnderstandCapacity/Services/selector';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import { Form, formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form';

import OperatingBedsColumnDefs from './TableOutline';

// TODO: This component does too much.  Break it up.
class UCHCROperatingBedsModal extends React.Component {
    static propTypes = {
        autofill: PropTypes.func.isRequired,
        currentFacility: PropTypes.string.isRequired,
        currentGroup: PropTypes.string.isRequired,
        marketName: PropTypes.string.isRequired,
        AllCapacity: PropTypes.shape({
            list: PropTypes.arrayOf(
                PropTypes.shape({
                    edited: PropTypes.bool,
                    Beds: PropTypes.string,
                    previousBedsFormattedValue: PropTypes.string,
                    BaseCapacityFormatted: PropTypes.string,
                    previousBaseCapacityFormatted: PropTypes.string
                })
            ),
            loading: PropTypes.bool
        }).isRequired,
        modal: PropTypes.shape({
            show: PropTypes.bool,
            saving: PropTypes.bool
        }).isRequired,
        error: PropTypes.string,
        canEdit: PropTypes.bool,
        pristine: PropTypes.bool,
        submitting: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        updateOperatingBeds: PropTypes.func.isRequired,
        clearEditBedsModal: PropTypes.func.isRequired,
        toggleOperatingBedsModal: PropTypes.func.isRequired
    };

    static defaultProps = {
        error: '',
        canEdit: false,
        pristine: true,
        submitting: false
    };

    setTextBoxFields = (inputName, list) => {
        const { autofill } = this.props;
        if (inputName === 'TotalBeds') {
            if (list[0]) {
                autofill('TotalBeds', list[0].HspcGroupBeds);
            } else {
                autofill('TotalBeds', 0);
            }
        } else if (inputName === 'CurrentTotal') {
            let sum = 0;
            let temp = 0;
            for (let i = 0; i < list.length; i++) {
                temp = parseInt(list[i].Beds, 10);
                sum += !Number.isNaN(temp) ? temp : 0;
            }
            autofill('CurrentTotal', sum);
        }
    };

    handleChange = () => {
        const { AllCapacity } = this.props;
        for (let i = 0; i < AllCapacity.list.length; i++) {
            if (AllCapacity.list[i].edited) {
                AllCapacity.list[i].Beds = AllCapacity.list[i].previousBedsFormattedValue;
                AllCapacity.list[i].BaseCapacityFormatted =
                    AllCapacity.list[i].previousBaseCapacityFormatted;
                delete AllCapacity.list[i].edited;
                delete AllCapacity.list[i].previousBedsFormattedValue;
                delete AllCapacity.list[i].previousBaseCapacityFormatted;
            }
        }
    };

    formSubmit = (values) => {
        const {
            AllCapacity,
            currentFacility,
            currentGroup,
            marketName,
            updateOperatingBeds
        } = this.props;

        if (values.CurrentTotal !== values.TotalBeds) {
            throw new SubmissionError({ _error: '"Current Total" must equal "Total Beds"' });
        } else {
            const data = _filter(AllCapacity.list, {
                Facility: currentFacility,
                HspcGroup: currentGroup
            });
            let save = false;
            for (let i = 0; i < data.length; i++) {
                if (data[i].edited) {
                    save = true;
                    delete data[i].edited;
                    delete data[i].previousBaseCapacityFormatted;
                    delete data[i].previousBedsFormattedValue;
                }
            }
            if (save) {
                updateOperatingBeds(data, marketName);
            } else {
                throw new SubmissionError({ _error: 'Not saving, beds have not changed.' });
            }
        }
    };

    handleHide = () => {
        const { AllCapacity, dispatch, clearEditBedsModal, toggleOperatingBedsModal } = this.props;
        for (let i = 0; i < AllCapacity.list.length; i++) {
            if (AllCapacity.list[i].edited) {
                AllCapacity.list[i].Beds = AllCapacity.list[i].previousBedsFormattedValue;
                AllCapacity.list[i].BaseCapacityFormatted =
                    AllCapacity.list[i].previousBaseCapacityFormatted;
                delete AllCapacity.list[i].edited;
                delete AllCapacity.list[i].previousBedsFormattedValue;
                delete AllCapacity.list[i].previousBaseCapacityFormatted;
            }
        }
        dispatch(reset('UCHCROperatingBedsModal'));
        clearEditBedsModal();
        toggleOperatingBedsModal();
    };

    render() {
        const {
            AllCapacity,
            currentFacility,
            currentGroup,
            modal,
            error,
            canEdit,
            pristine,
            submitting,
            reset: resetForm,
            handleSubmit
        } = this.props;
        const data = _filter(AllCapacity.list, {
            Facility: currentFacility,
            HspcGroup: currentGroup
        });
        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (oldValue, newValue, row) => {
                // TODO: Don't modify function parameters
                row.BaseCapacityFormatted = row.Beds * 365;
                this.setTextBoxFields('CurrentTotal', data);
            },
            beforeSaveCell: (oldValue, newValue, row) => {
                row.previousBaseCapacityFormatted = row.BaseCapacityFormatted;
                row.previousBedsFormattedValue = row.Beds;
                row.edited = true;
            }
        };
        return (
            <div>
                <Modal size="lg" show={modal.show} onHide={this.handleHide}>
                    <Form onSubmit={handleSubmit(this.formSubmit)}>
                        <Modal.Header closeButton>
                            <div className="modal-title">
                                <h4>Operating Beds</h4>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <DropdownField
                                name="Facility"
                                label="Facility"
                                placeholder="Select facility..."
                                onChangeFunction={this.handleChange}
                                options={_uniqBy(AllCapacity.list, 'Facility')}
                                displayField="Facility"
                                valueField="Facility"
                            />
                            <DropdownField
                                name="HspcGroup"
                                label="Group"
                                placeholder="Select group..."
                                onChangeFunction={this.handleChange}
                                options={_uniqBy(
                                    _filter(
                                        AllCapacity.list,
                                        (item) => item.Facility === currentFacility
                                    ),
                                    'HspcGroup'
                                )}
                                displayField="HspcGroup"
                                valueField="HspcGroup"
                            />
                            <TextboxField
                                name="TotalBeds"
                                label="Total Beds"
                                disabled
                                defaultValue={this.setTextBoxFields('TotalBeds', data)}
                                setTextBoxFields={this.setTextBoxFields}
                            />
                            <TextboxField
                                name="CurrentTotal"
                                label="Current Total"
                                disabled
                                defaultValue={this.setTextBoxFields('CurrentTotal', data)}
                                setTextBoxFields={this.setTextBoxFields}
                            />
                            <ReactDataTable
                                keyValue="Hspc"
                                list={data}
                                columns={OperatingBedsColumnDefs(modal.saving)}
                                loading={AllCapacity.loading}
                                cellEditOptions={cellEditProp}
                                isExportable={false}
                                isEditable
                            />
                            {error && <strong style={{ color: 'red' }}>{error}</strong>}
                        </Modal.Body>
                        <Modal.Footer style={{ height: '55px' }}>
                            <div className="col-sm-12">
                                <div className="form-group" style={{ paddingTop: '20px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            label={modal.saving ? 'Saving...' : 'Save'}
                                            type="submit"
                                            color="success"
                                            disabled={!canEdit || pristine || submitting}
                                        />
                                        &nbsp;&nbsp;
                                        <Button
                                            color="warning"
                                            type="button"
                                            disabled={pristine || submitting}
                                            onClick={resetForm}
                                            label="Cancel"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const selector = formValueSelector('UCHCROperatingBedsModal');
const ReduxFormUCHCROperatingBedsModal = reduxForm({
    form: 'UCHCROperatingBedsModal',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.Facility) {
            errors.Facility = 'Required';
        }
        if (!values.HspcGroup) {
            errors.HspcGroup = 'Required';
        }
        return errors;
    }
})(UCHCROperatingBedsModal);

const mapDispatchToProps = (dispatch) => ({
    toggleOperatingBedsModal: () => dispatch.InHouseCapacityModel.toggleModal('operatingBedsModal'),
    updateOperatingBeds: (formValues, market) =>
        dispatch.InHouseCapacityModel.saveOperatingBedsAsync({
            formValues,
            market
        }),
    clearEditBedsModal: () => dispatch.InHouseCapacityModel.resetModalForm('operatingBedsModal')
});
const mapStateToProps = (state) => ({
    currentFacility: selector(state, 'Facility'),
    currentGroup: selector(state, 'HspcGroup'),
    initialValues: state.InHouseCapacityModel.operatingBedsModal.form,
    AllCapacity: getAllCapacitySelector(state),
    modal: state.InHouseCapacityModel.operatingBedsModal || {},
    marketName: getCurrentMarketName(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormUCHCROperatingBedsModal);
