import { HSPANotification, HSPANotificationLevel } from 'Types/HSPANotification';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NotificationsState = HSPANotification;

const initialState = {} as NotificationsState;

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        /**
         * Add a new notification by setting the state to the value of the notification.
         *
         * @param state - a mutable ImmerJS state for notifications
         * @param action - the dispatched add notification action
         * @param action.payload - the notification to add
         * @returns the new notification state
         */
        addNotification(
            state,
            { payload }: PayloadAction<{ message: string; level: HSPANotificationLevel }>
        ) {
            return {
                ...payload,
                timestamp: new Date()
            };
        }
    }
});

export { HSPANotificationLevel };

export const { addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
