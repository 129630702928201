/**
 * Display the narrative for a market.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, Form } from 'redux-form';
import TextAreaField from 'Lib/ReduxFormFields/TextAreaField';
import Button from 'Lib/Button';
import { getCurrentMarketName, getCurrentVISN } from 'Modules/UserSession/selector';
import { getMarketDescriptionFormSelector } from 'Modules/UnderstandCapacity/Services/selector';

class UCMarketNarrative extends React.Component {
    componentDidMount() {
        if (this.props.marketName) {
            this.props.fetchMarketDescriptionAsync(this.props.marketName);
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.marketName !== this.props.marketName) {
            this.props.fetchMarketDescriptionAsync(nextProps.marketName);
        }
    }

    formGenerate = () => {
        this.props.generateMarketDescriptionAsync(
            this.props.marketName,
            this.props.currentVISN.VisnCode
        );
    };

    formSubmit = (values) => {
        this.props.saveMarketDescriptionAsync(values);
        this.props.dispatch(reset('UCMarketNarrative')); // resets all fields
    };

    render() {
        const disabledActions = this.props.saving || this.props.generating || !!this.props.loading;

        return (
            <div>
                <div className="col-md-8 offset-md-2">
                    <Form onSubmit={this.props.handleSubmit(this.formSubmit)}>
                        <TextAreaField
                            name="CapitalAssetDesc"
                            label="Capital Asset Narrative"
                            labelPosition={12}
                            inputPosition={12}
                            disabled={disabledActions}
                        />
                        <TextAreaField
                            name="WorkforceTrendsDesc"
                            label="Workforce Trends Narrative"
                            labelPosition={12}
                            inputPosition={12}
                            disabled={disabledActions}
                        />
                        <div className="col-xs-12">
                            <div className="form-group" style={{ textAlign: 'center' }}>
                                <Button
                                    type="submit"
                                    color="success"
                                    label={
                                        !this.props.saving ? 'Save Market Narrative' : 'Saving...'
                                    }
                                />
                                &nbsp;&nbsp;
                                <Button
                                    color="primary"
                                    type="button"
                                    disabled={this.props.invalid || this.props.generating}
                                    onClick={this.formGenerate}
                                    label={
                                        this.props.generating === false
                                            ? 'Generate PowerPoint'
                                            : 'Generating...'
                                    }
                                />
                                &nbsp;&nbsp;
                                <Button
                                    color="warning"
                                    type="button"
                                    disabled={this.props.pristine || this.props.saving}
                                    onClick={this.props.reset}
                                    label="Cancel"
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

const ReduxFormUCMarketNarrative = reduxForm({
    form: 'UCMarketNarrative',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.CapitalAssetDesc) {
            errors.CapitalAssetDesc = 'Required';
        }
        if (!values.WorkforceTrendsDesc) {
            errors.WorkforceTrendsDesc = 'Required';
        }
        return errors;
    }
})(UCMarketNarrative);

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMarketDescriptionAsync: dispatch.MarketDescriptionModel.fetchMarketDescriptionAsync,
        saveMarketDescriptionAsync: dispatch.MarketDescriptionModel.saveMarketDescriptionAsync,
        generateMarketDescriptionAsync: (market, visnCode) =>
            dispatch.MarketDescriptionModel.generateMarketDescriptionAsync({
                market,
                visnCode
            })
    };
};

const mapStateToProps = (state) => {
    const UCMarketNarrative = getMarketDescriptionFormSelector(state);
    return {
        initialValues: UCMarketNarrative.form,
        saving: UCMarketNarrative.saving,
        generating: UCMarketNarrative.generating,
        loading: UCMarketNarrative.loading,
        currentVISN: getCurrentVISN(state),
        marketName: getCurrentMarketName(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormUCMarketNarrative);
