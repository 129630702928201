import React from 'react';

import { CapitalProject } from 'Utilities/types';

import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';

import CapitalApproval from './CapitalApprovalModal';
import CapitalProjectLinksModal from './CapitalProjectLinksModal';
import DeleteCapitalProjectModal from './DeleteCapitalProjectModal';
import SquareFootageModal from './SquareFootageModal';

const columnHelper = createColumnHelper<CapitalProject>();

const columns: ColumnDef<CapitalProject>[] = [
    {
        header: 'Project',
        accessorKey: 'projectNumber',
        enableGrouping: true,
        enableHiding: false,
        cell: ({ getValue, row }) => {
            if (row.getIsGrouped() === true) {
                return <h5>{getValue()}</h5>;
            }
            return (
                <>
                    <h5>{getValue()}</h5>
                    <p>{row.original.description}</p>
                </>
            );
        },
        filterFn: (row: Row<CapitalProject>, columnId: string, value: string): boolean => {
            const search = value.toLowerCase();
            const rowValue = String(row.getValue(columnId)).toLowerCase();
            const description = row?.original?.description?.toLowerCase();

            return rowValue?.includes(search) === true || description?.includes(search) === true;
        },
        meta: {
            headerDisplayName: 'Project',
            columnStyle: { width: '35%' },
            cellStyle: { width: '35%' }
        }
    },
    {
        header: 'SCIP Project',
        accessorKey: 'isSCIPProject',
        cell: ({ getValue }) => {
            if (getValue() === true) {
                return (
                    <div className="text-success">
                        <FontAwesomeIcon icon={faCheck} title="Imported From SCIP" />
                    </div>
                );
            }

            return (
                <div className="text-danger">
                    <FontAwesomeIcon icon={faTimesCircle} title="Created on HSPA" />
                </div>
            );
        },
        meta: {
            headerDisplayName: 'SCIP Project',
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    },
    {
        header: 'In Process or Disposal?',
        accessorKey: 'isDisposalProject',
        cell: ({ row }) =>
            row.getIsGrouped() === true ? null : (
                <p>{row.original.isDisposalProject === true ? 'Disposal' : 'In-Process'}</p>
            ),
        meta: {
            headerDisplayName: 'In Process or Disposal?',
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Project Type',
        accessorKey: 'capitalProjectType',
        meta: {
            headerDisplayName: 'Project Type',
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Facility Affected',
        accessorKey: 'facility',
        aggregationFn: 'unique',
        meta: {
            headerDisplayName: 'Facility Affected',
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Starting Year',
        accessorKey: 'year',
        aggregationFn: 'min',
        meta: {
            headerDisplayName: 'Starting Year',
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    },
    {
        header: 'Completion Year',
        accessorKey: 'completionYear',
        aggregationFn: 'max',
        meta: {
            headerDisplayName: 'Completion Year',
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    },
    columnHelper.display({
        header: 'Total Square Footage',
        enableSorting: false,
        cell: ({ row }) =>
            row.getIsGrouped() !== true && row.original.isSCIPProject === true ? (
                <SquareFootageModal capitalProject={row.original} />
            ) : (
                <span>N/A</span>
            ),
        meta: {
            csvExportIgnore: true,
            headerDisplayName: 'Total Square Footage',
            columnClassName: 'text-center',
            cellClassName: 'text-right'
        }
    }),
    columnHelper.display({
        header: 'Linked Plans',
        enableSorting: false,
        cell: ({ row }) => {
            if (row.getIsGrouped() === true || row.original.isSCIPProject === true) {
                return <></>;
            }

            return <CapitalProjectLinksModal capitalProject={row.original} />;
        },
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    }),
    columnHelper.display({
        header: 'Review',
        enableSorting: false,
        cell: ({ row }) => {
            const capitalProject = row.original;
            if (row.getIsGrouped() === true || capitalProject.isSCIPProject === true) {
                return <></>;
            }

            if (
                capitalProject.capitalProjectStatusID === 1 ||
                capitalProject.capitalProjectStatusID === 2
            ) {
                return <CapitalApproval capitalProject={capitalProject} />;
            }

            return <p>{capitalProject.capitalProjectStatus}</p>;
        },
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    }),
    columnHelper.display({
        header: 'Delete',
        enableSorting: false,
        cell: ({ row }) => {
            const capitalProject = row.original;
            if (
                row.getIsGrouped() === true ||
                capitalProject.isSCIPProject === true ||
                (capitalProject.capitalProjectStatusID !== 1 &&
                    capitalProject.capitalProjectStatusID !== 2)
            ) {
                return <></>;
            }

            return <DeleteCapitalProjectModal capitalProject={capitalProject} />;
        },
        meta: {
            csvExportIgnore: true,
            columnClassName: 'text-center',
            cellClassName: 'text-center'
        }
    })
];

export default columns;
