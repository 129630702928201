import { commaFormatter, commaNAFormatter, percentFormatter } from 'Utilities/formatters';

const FutureMarketSpecificColumns = (defaultYear) => {
    if (!defaultYear || defaultYear.defaultYear === '') {
        return [];
    }

    return [
        {
            accessorKey: 'HspcGroup',
            header: 'HSPCG',
            meta: {
                headerDisplayName: 'HSPC Group',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                cellStyle: { whiteSpace: 'normal' }
            }
        },
        {
            accessorKey: 'Hspc',
            header: 'HSPC',
            meta: {
                headerDisplayName: 'HSPC',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                cellStyle: { whiteSpace: 'normal' }
            }
        },
        {
            accessorKey: 'ActualYear2Total',
            header: `FY ${defaultYear.twoYearActual}`,
            enableHiding: true,
            meta: {
                headerDisplayName: `FY ${defaultYear.twoYearActual}`,
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaNAFormatter
            }
        },
        {
            accessorKey: 'ActualYear1Total',
            header: `FY ${defaultYear.oneYearActual}`,
            enableHiding: true,
            meta: {
                headerDisplayName: `FY ${defaultYear.oneYearActual}`,
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaNAFormatter
            }
        },
        {
            accessorKey: 'Year0Total',
            header: defaultYear.defaultYearWithFiscalString,
            meta: {
                headerDisplayName: defaultYear.defaultYearWithFiscalString,
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaNAFormatter
            }
        },
        {
            accessorKey: 'Year5Total',
            header: defaultYear.fiveYearWithFiscalString,
            meta: {
                headerDisplayName: defaultYear.fiveYearWithFiscalString,
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaNAFormatter
            }
        },
        {
            accessorKey: 'Year5TotalDiff',
            header: '5-Year Diff #',
            meta: {
                headerDisplayName: '5-Year Diff #',
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaFormatter
            }
        },
        {
            accessorKey: 'Year5TotalDiffPercent',
            header: '5-Year Diff %',
            meta: {
                headerDisplayName: '5-Year Diff %',
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: percentFormatter
            }
        },
        {
            accessorKey: 'Year10Total',
            header: defaultYear.tenYearWithFiscalString,
            meta: {
                headerDisplayName: defaultYear.tenYearWithFiscalString,
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaNAFormatter
            }
        },
        {
            accessorKey: 'Year10TotalDiff',
            header: '10-Year Diff #',
            meta: {
                headerDisplayName: '10-Year Diff #',
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaFormatter
            }
        },
        {
            accessorKey: 'Year10TotalDiffPercent',
            header: '10-Year Diff %',
            meta: {
                headerDisplayName: '10-Year Diff %',
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: percentFormatter
            }
        },
        {
            accessorKey: 'Year20Total',
            header: defaultYear.twentyYearWithFiscalString,
            meta: {
                headerDisplayName: defaultYear.twentyYearWithFiscalString,
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaNAFormatter
            }
        },
        {
            accessorKey: 'Year20TotalDiff',
            header: '20-Year Diff #',
            meta: {
                headerDisplayName: '20-Year Diff #',
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: commaFormatter
            }
        },
        {
            accessorKey: 'Year20TotalDiffPercent',
            header: '20-Year Diff %',
            meta: {
                headerDisplayName: '20-Year Diff %',
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                cellStyle: { whiteSpace: 'normal' },
                formatter: percentFormatter
            }
        }
    ];
};

export default FutureMarketSpecificColumns;
