/**
 * A button to render in a table row.  It's slightly squished, but not quite a "small" Bootstrap
 * button.
 *
 * @author Brandan D. Reed
 */
import styled from 'styled-components/macro';
import Button from 'react-bootstrap/Button';

export default styled(Button)`
    padding-top: 1px;
    padding-bottom: 1px;
`;
