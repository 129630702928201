import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import styled from 'styled-components/macro';

const StyledSpinner = styled(Spinner)`
    width: 4rem;
    height: 4rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
`;

const Content = styled.div`
    flex-grow: 1;
    position: relative;
    padding: 10px;
    background-color: #fff;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    img {
        border: 1px solid #e0e0e0;
        margin-bottom: 10px;
    }
`;

const Header = styled.div`
    font-size: 24pt;
    align-self: flex-start;
    justify-content: start;
`;

const SubHeader = styled.div`
    font-size: 14pt;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
`;

const SpinnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
`;

const CenterContainer = styled.div`
    text-align: center;
`;

interface DatabaseStartProps {
    login: () => void;
}

/**
 * DatabaseStartPage component for HSPA.
 *This is component shows when the database is paused or not yet up.
 *
 * @returns {JSX.Element} the rendered component
 */
const DatabaseStartPage = (): JSX.Element => (
    <Container>
        <Content>
            <Header>Welcome to HSPA!</Header>

            <SpinnerContainer>
                <CenterContainer>
                    <StyledSpinner
                        animation="border"
                        className="databaseSpinner"
                        variant="secondary"
                    />
                    <SubHeader>
                        Please wait, the application is starting. This may take a few moments...
                    </SubHeader>
                </CenterContainer>
            </SpinnerContainer>
        </Content>
    </Container>
);

export default DatabaseStartPage;
