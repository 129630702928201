/**
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import proptypes from 'prop-types';

import * as TableOutline from './TableOutline';

export default class UCMPFacilityTable extends React.Component {
    getTableColumns() {
        switch(this.props.currentFacilityType){
            case 'FQHC':
                return TableOutline.FqhcFacilityGridColumns((row) => { this.props.selectFacilityInGrid(this.props.currentFacilityType, row)});
            case 'DoD':
                return TableOutline.DoDFacilityGridColumns((row) => { this.props.selectFacilityInGrid(this.props.currentFacilityType, row)});
            case 'Community':
                return TableOutline.CommunityFacilityGridColumns((row) => { this.props.selectFacilityInGrid(this.props.currentFacilityType, row)});
            case 'AA':
                return TableOutline.AaFacilityGridColumns((row) => { this.props.selectFacilityInGrid(this.props.currentFacilityType, row)});
            case 'IHS':
                return TableOutline.IhsFacilityGridColumns((row) => { this.props.selectFacilityInGrid(this.props.currentFacilityType, row)});
            default:
                return "";
        }
    }

    render() {
        return(
            <div>
                {
                    (this.props.showGrid) ?
                        <ReactDataTable
                            keyValue={"Id"}
                            list={this.props.data.list}
                            loading={this.props.data.loading}
                            columns={this.getTableColumns()}
                            isExportable={false}
                            isSearchable={true}/>
                        : null
                }
            </div>
        );
    }
    
    static propTypes = {
        currentFacilityType: proptypes.string,
        data: proptypes.object.isRequired,
        showGrid: proptypes.bool.isRequired,
        selectFacilityInGrid: proptypes.func.isRequired
    }
}

UCMPFacilityTable.defaultProps ={
    currentFacilityType: ""
}