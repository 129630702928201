import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON, postJSON } from 'Utilities/apiCalls';

const understandDemandApi = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const PatientOriginToolModel = {
    state: {
        facilities: { list: [], loading: false },
        hspcs: { list: [], loading: false },
        origins: { list: [], loading: false },
        patientOrigins: { list: [], loading: false, generating: false },
        workloadTypes: { list: [], loading: false },
        patientOriginForm: { data: {}, saving: false, loading: false }
    },
    reducers: {
        populate(state, data, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    list: data
                }
            };
        },
        toggleLoading(state, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: !state[object].loading
                }
            };
        },
        toggleGenerating(state) {
            return {
                ...state,
                patientOrigins: {
                    ...state.patientOrigins,
                    generating: !state.patientOrigins.generating
                }
            };
        },
        toggleSaving(state) {
            return {
                ...state,
                patientOriginForm: {
                    ...state.patientOriginForm,
                    saving: !state.patientOriginForm.saving
                }
            };
        },
        resetTable(state, object) {
            if (object === 'patientOriginForm')
                return {
                    ...state,
                    [object]: { data: {}, saving: false, loading: false }
                };

            return {
                ...state,
                [object]: {
                    ...state,
                    list: [],
                    loading: false
                }
            };
        },
        reset(state) {
            return {
                ...state,
                facilities: { list: [], loading: false },
                hspcs: { list: [], loading: false },
                origins: { list: [], loading: false },
                patientOrigins: { list: [], loading: false, generating: false },
                // workloadTypes:          { list: [], loading: false },
                patientOriginForm: { data: {}, saving: false, loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchPOFacilitiesAsync(marketName, state) {
            try {
                this.resetTable('facilities');
                this.toggleLoading('facilities');
                let result = await getJSON(
                    `${understandDemandApi}GetPatientOriginFacilitiesByMarket?marketName=` +
                        marketName
                );
                this.populate(result, 'facilities');
                this.toggleLoading('facilities');
            } catch (error) {
                this.toggleLoading('facilities');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch patient origin facilities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchPOMarketOriginsAsync(facility, state) {
            try {
                this.resetTable('origins');
                this.toggleLoading('origins');
                let result = await getJSON(
                    `${understandDemandApi}GetPatientOriginMarketOriginsByFacility?facility=${facility}`
                );
                this.populate(result, 'origins');
                this.toggleLoading('origins');
            } catch (error) {
                this.toggleLoading('origins');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market origins',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchPOHspcsAsync(facility, state) {
            try {
                this.resetTable('hspcs');
                this.toggleLoading('hspcs');
                let result = await getJSON(
                    `${understandDemandApi}GetPatientOriginMpcsByFacility?facility=${facility}`
                );
                this.populate(result, 'hspcs');
                this.toggleLoading('hspcs');
            } catch (error) {
                this.toggleLoading('hspcs');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch patient origin HSPCs',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchPOWorkloadTypesAsync(state) {
            try {
                this.resetTable('workloadTypes');
                this.toggleLoading('workloadTypes');
                let result = await getJSON(`${understandDemandApi}GetWorkloadTypes`);
                this.populate(result, 'workloadTypes');
                this.toggleLoading('workloadTypes');
            } catch (error) {
                this.toggleLoading('workloadTypes');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch workload types',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchPatientOriginsAsync(payload, state) {
            try {
                this.resetTable('patientOrigins');
                this.toggleLoading('patientOrigins');
                let result = await getJSON(
                    `${understandDemandApi}GetPatientOrigins?facility=` +
                        payload.facility +
                        '&marketName=' +
                        payload.marketName +
                        '&mpc=' +
                        payload.mpc +
                        '&workloadType=' +
                        payload.workloadType
                );
                this.populate(result, 'patientOrigins');
                this.toggleLoading('patientOrigins');
            } catch (error) {
                this.toggleLoading('patientOrigins');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch patient origin table data',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async savePatientOriginReportAsync(payload, state) {
            try {
                this.toggleSaving();
                await postJSON(
                    `${understandDemandApi}SavePatientOriginToolForReport?patientOriginTool=` +
                        payload.reportTool +
                        '&marketName=' +
                        payload.market,
                    payload.formValues
                );
                this.toggleSaving();

                dispatch(
                    addNotification({
                        message: 'The PO report has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save patient origins',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generatePOReportAsync(payload, state) {
            try {
                this.toggleGenerating();
                await downloadFile(
                    `${exportApiUrl}ExportPatientOriginPPT?market=` +
                        payload.market +
                        '&visnCode=' +
                        payload.visnCode +
                        '&facility=' +
                        payload.facility +
                        '&hspcg=' +
                        payload.hspcg +
                        '&origin=' +
                        payload.origin +
                        '&workloadType=' +
                        payload.workloadType
                );
                this.toggleGenerating();
            } catch (error) {
                this.toggleGenerating();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate PO report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
