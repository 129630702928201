import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components/macro';

import StepNav, { StepItem, StepLink } from 'Lib/StepNav';

import {
    faClinicMedical,
    faDollarSign,
    faMedkit,
    faUserMd
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavIcon = styled(FontAwesomeIcon)`
    font-size: 3em;
    margin-right: 6px;
    vertical-align: middle;
`;

const StickyStepNav = styled(StepNav)`
    background-color: #fff;
    position: sticky;
    top: 66px;
    left: 0;
    right: 0;
    z-index: 60;
    overflow: hidden;
    margin-bottom: ${({ isSticky }) => (isSticky ? '56px' : '10px')};
    transition: margin 0.5s ease;
    .nav-link {
        height: ${({ isSticky }) => (isSticky ? '36px' : '84px')};
        transition: height 0.5s ease;
    }
    .nav-link:after,
    .nav-link:before {
        top: ${({ isSticky }) => (isSticky ? '0px' : '26px')};
        transition: top 0.5s ease;
    }
    .nav-link > svg {
        font-size: ${({ isSticky }) => (isSticky ? '1.5em' : '3em')};
        margin-right: ${({ isSticky }) => (isSticky ? '6px' : '0')};
        transition: font-size 0.5s ease;
    }
    .nav-link > span {
        font-size: ${({ isSticky }) => (isSticky ? '14px' : '16px')};
        transition: font-size 0.5s ease;
    }
    .nav-link > br {
        display: ${({ isSticky }) => (isSticky ? 'none' : 'initial')};
    }
`;

const StickySentinel = styled.div`
    position: absolute;
    top: 40px;
`;

const routeProps = [
    {
        path: 'workload',
        careTypes: ['Inpatient', 'Outpatient', 'Primary'],
        type: 'General',
        label: 'Workload',
        color: '#007bff',
        icon: faMedkit
    },
    {
        path: 'staffing',
        careTypes: ['Outpatient', 'Primary'],
        type: 'Staffing',
        label: 'Staffing',
        color: '#c926ff',
        icon: faUserMd
    },
    {
        path: 'space',
        // Ambulatory Pathology cannot reliably calculate Space with the latest Space Estimator
        // updates from CFM.  The units for this HSPC changed, so for now the solution is to simply
        // disable the Space dimension for this HSPC.
        excludeHSPCs: ['Amb Pathology'],
        careTypes: ['Inpatient', 'Outpatient', 'Primary'],
        type: 'Space',
        label: 'Space',
        color: '#ffbf00',
        icon: faClinicMedical
    },
    {
        path: 'cost',
        careTypes: ['Inpatient', 'Outpatient'],
        type: 'Cost',
        label: 'Cost',
        color: '#28a745',
        icon: faDollarSign
    }
];

const PlanNavigation = ({ typeOfCare, hspc }) => {
    const { path, url } = useRouteMatch();

    const filteredRoutes = routeProps.filter(
        ({ careTypes, excludeHSPCs }) =>
            (!typeOfCare || careTypes.includes(typeOfCare)) &&
            (!hspc || !excludeHSPCs?.includes(hspc))
    );
    const routes = filteredRoutes.map(({ path: routePath }) => (
        <Route path={`${path}/${routePath}`} />
    ));

    const links = filteredRoutes.map(({ path: routePath, color, icon, label }) => (
        <StepItem>
            <StepLink to={`${url}/${routePath}`} color={color}>
                <NavIcon icon={icon} />
                <br />
                <span>{label}</span>
            </StepLink>
        </StepItem>
    ));

    // Keep track of when this element becomes "sticky" fixed
    const [isSticky, setIsSticky] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const cachedRef = ref.current;
        const observer = new IntersectionObserver(
            ([e]) => {
                setIsSticky(e.intersectionRatio < 1);
            },
            { threshold: [1] }
        );

        observer.observe(cachedRef);

        return () => {
            observer.unobserve(cachedRef);
        };
    }, [setIsSticky]);

    return (
        <>
            <Switch>
                {routes}
                <Route>
                    <Redirect to={`${url}/${routeProps[0].path}`} />
                </Route>
            </Switch>
            <StickySentinel ref={ref} />
            <StickyStepNav
                isSticky={isSticky}
                fill
                variant="pills"
                defaultActiveKey={`${url}/workload`}
            >
                {links}
            </StickyStepNav>
        </>
    );
};

export default PlanNavigation;
