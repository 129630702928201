import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import CustomExpandToggleCell from './CustomExpandToggleCell';

const columnHelper = createColumnHelper<unknown>();

const CustomExpandToggleColumnDef = columnHelper.display({
    id: 'CustomExpand',
    enableSorting: false,
    enableGlobalFilter: false,
    enableHiding: false,
    cell: ({ row, table }) => (
        <CustomExpandToggleCell row={row} expandContent={table.options.meta?.expandContent} />
    ),
    meta: {
        columnClassName: 'text-center',
        columnStyle: { width: '34px' },
        cellClassName: 'text-center',
        cellStyle: { width: '34px' },
        csvExportIgnore: true
    }
});

export default CustomExpandToggleColumnDef;
