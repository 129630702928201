import fetch from 'isomorphic-fetch';

import { saveAs } from 'file-saver';

import { authProvider } from '../authProvider';

type JSONType = Record<string, unknown> | string | number | boolean;

type SingleOrArray<T> = T | T[];

type JSONResponse = SingleOrArray<JSONType>;

/**
 * Send an HTTP request.
 *
 * @param method - The HTTP request method to use.
 * @param url - The URL to send the request to.
 * @param body - An optional request body used in POST and PUT requests.
 * @returns A response object.
 */
const request = async (
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    url: string,
    body?: string
) => {
    const bodyHeaders = {} as Record<string, string>;
    if (body) {
        bodyHeaders['Content-Type'] = 'application/json';
        bodyHeaders.Accept = 'application/json';
    }

    const response = await fetch(url, {
        method,
        credentials: 'include',
        headers: {
            Authorization: `Bearer ${(await authProvider.getAccessToken()).accessToken}`,
            'Cache-Control': 'no-cache',
            pragma: 'no-cache',
            ...bodyHeaders
        },
        body: body || undefined
    });

    if (response.status >= 400) {
        throw new Error(response.statusText);
    }

    return response;
};

/**
 * Send a GET HTTP request.
 *
 * @param url - The URL to send the request to.
 * @returns A response object.
 */
export const getRequest = async (url: string): Promise<Response> => request('get', url);

/**
 * Send a POST HTTP request.
 *
 * @param url - The URL to send the request to.
 * @param body - The request body to include in the POST request.
 * @returns A response object.
 */
export const postRequest = async (url: string, body: string): Promise<Response> =>
    request('post', url, body);

/**
 * Send a PUT HTTP request.
 *
 * @param url - The URL to send the request to.
 * @param body - The request body to include in the PUT request.
 * @returns A response object.
 */
export const putRequest = async (url: string, body: string): Promise<Response> =>
    request('put', url, body);

/**
 * Send a PATCH HTTP request.
 *
 * @param url - The URL to send the request to.
 * @param body - The request body to include in the PATCH request.
 * @returns A response object.
 */
export const patchRequest = async (url: string, body: string): Promise<Response> =>
    request('patch', url, body);

/**
 * Send a DELETE HTTP request.
 *
 * @param url - The URL to send the request to.
 * @returns A response object.
 */
export const deleteRequest = async (url: string): Promise<Response> => request('delete', url);

/**
 * Send a GET request for JSON data.
 *
 * @param url - The URL to send the request to.
 * @returns An object read in from the JSON response.
 */
export const getJSON = async (url: string): Promise<JSONResponse> => {
    const response = await getRequest(url);

    const result = (await response.json()) as JSONResponse;

    return result;
};

/**
 * Send a PUT request with JSON data.
 *
 * @param url - The URL to send the request to.
 * @param body - An object to send as the request body.
 * @returns An object read in from the JSON response.
 */
export const putJSON = async (
    url: string,
    body: Record<string, unknown>
): Promise<JSONResponse | void> => {
    const response = await putRequest(url, JSON.stringify(body));

    if (response.headers.get('Content-Type')?.startsWith('application/json') !== true) {
        return undefined;
    }

    const result = (await response.json()) as JSONResponse;

    return result;
};

/**
 * Send a PATCH request with JSON data.
 *
 * @param url - The URL to send the request to.
 * @param body - An object to send as the request body.
 * @returns An object read in from the JSON response.
 */
export const patchJSON = async (
    url: string,
    body: Record<string, unknown>
): Promise<JSONResponse | void> => {
    const response = await patchRequest(url, JSON.stringify(body));

    if (response.headers.get('Content-Type')?.startsWith('application/json') !== true) {
        return undefined;
    }

    const result = (await response.json()) as JSONResponse;

    return result;
};

/**
 * Send a POST request with JSON data.
 *
 * @param url - The URL to send the request to.
 * @param body - An object to send as the request body.
 * @returns An object read in from the JSON response.
 */
export const postJSON = async (
    url: string,
    body: Record<string, unknown>
): Promise<JSONResponse | void> => {
    const response = await postRequest(url, JSON.stringify(body));

    if (response.headers.get('Content-Type')?.startsWith('application/json') !== true) {
        return undefined;
    }

    const result = (await response.json()) as JSONResponse;

    return result;
};

/**
 * Download a file using a request.
 *
 * @param url - The URL to send the request to.
 */
export const downloadFile = async (url: string): Promise<void> => {
    const response = await getRequest(url);

    const fileName = response.headers.get('Content-Disposition')?.substring(21);
    saveAs(await response.blob(), fileName);
};

/**
 * Download a file based on submitted form data.
 *
 * @param url - The URL to send the request to.
 * @param body - An object to send as the request body.
 */
export const downloadFileByFormData = async (
    url: string,
    body: Record<string, unknown>
): Promise<void> => {
    const response = await postRequest(url, JSON.stringify(body));

    const fileName = response.headers.get('Content-Disposition')?.substring(21);
    saveAs(await response.blob(), fileName);
};
