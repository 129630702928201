import React from 'react';
import { Modal } from 'react-bootstrap';

import { useHSPADispatch } from 'storeTypes';

import { useAppSelector } from 'App/hooks';

import TrainingVideoPanelBody from './SAUserTrainingModalBody';

/**
 * Render and display the training video modal
 *
 * @returns Rendered modal
 */
const TrainingVideoPanel = (): JSX.Element => {
    const dispatch = useHSPADispatch();

    const show = useAppSelector((state) => state.UserTrainingModel.show);

    return (
        <Modal size="lg" centered show={show} onHide={dispatch.UserTrainingModel.toggle}>
            <Modal.Header closeButton>
                <Modal.Title>User Training</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TrainingVideoPanelBody />
            </Modal.Body>
        </Modal>
    );
};

export default TrainingVideoPanel;
