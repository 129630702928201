import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Lib/Button';

const RWFButtonGroup = (props) => {
    const {
        isFirstPage,
        isLastPage,
        previousPage,
        canEdit,
        saving,
        submitting,
        loading,
        generating,
        invalid,
        pristine,
        form_type,
        reset,
        formGenerate
    } = props;
    return (
        <div className="row" style={{ paddingTop: 15 }}>
            <div className="col-sm-2">
                <Button
                    color="info"
                    type="button"
                    label="Previous Page"
                    disabled={isFirstPage}
                    onClick={previousPage}
                />
            </div>
            <div className="col-sm-8" style={{ textAlign: 'center' }}>
                <Button
                    color="success"
                    type="submit"
                    label={saving ? 'Saving...' : 'Save Report'}
                    disabled={!canEdit || !isLastPage || submitting || loading}
                />
                &nbsp;&nbsp;
                <Button
                    color="primary"
                    type="button"
                    label={generating ? 'Generating...' : 'Generate Report'}
                    disabled={invalid || submitting || loading}
                    onClick={formGenerate}
                />
                &nbsp;&nbsp;
                <Button
                    color="warning"
                    type="button"
                    label={form_type === 'create' ? 'Cancel Report' : 'Clear Edits'}
                    disabled={pristine || submitting || loading}
                    onClick={reset}
                />
            </div>
            <div className="col-sm-2" style={{ textAlign: 'right' }}>
                <Button
                    color="info"
                    type="submit"
                    label="Next Page"
                    disabled={isLastPage || submitting || loading}
                />
            </div>
        </div>
    );
};

RWFButtonGroup.propTypes = {
    isFirstPage: PropTypes.bool,
    isLastPage: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    generating: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    formGenerate: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

RWFButtonGroup.defaultProps = {
    isFirstPage: false,
    isLastPage: false,
    canEdit: false
};

export default RWFButtonGroup;
