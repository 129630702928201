import React from 'react';

const Bar = ({ x, y, width, height, color }) => (
    <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        color={color}
    />
)
export default Bar;