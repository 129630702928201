/**
 * Patient Origin Tool for the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getPatientOriginsSelector } from 'Modules/UnderstandDemand/Services/selector';
import {
    getCurrentMarketName,
    getCurrentVISN,
    getDefaultYearObject
} from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import PatientOriginColumns from './PatientOriginColumns';

class UDPOTable extends React.Component {
    componentDidMount() {
        if (
            this.props.marketName &&
            this.props.facility &&
            this.props.mpc &&
            (this.props.workloadType || this.props.workloadType === 0) &&
            this.props.patientOrigins.list.length === 0
        ) {
            this.props.getPatientOrigins(
                this.props.facility,
                this.props.marketName,
                this.props.mpc,
                this.props.workloadType
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.marketName !== this.props.marketName ||
            prevProps.facility !== this.props.facility ||
            prevProps.mpc !== this.props.mpc ||
            prevProps.workloadType !== this.props.workloadType
        ) {
            if (
                this.props.mpc &&
                this.props.facility &&
                this.props.mpc &&
                (this.props.workloadType || this.props.workloadType === 0)
            ) {
                this.props.getPatientOrigins(
                    this.props.facility,
                    this.props.marketName,
                    this.props.mpc,
                    this.props.workloadType
                );
            }
        }
    }

    render() {
        let disabled = true;
        if (
            this.props.marketName &&
            this.props.facility &&
            this.props.mpc &&
            (this.props.workloadType || this.props.workloadType === 0)
        ) {
            disabled = false;
        }
        const defaultYear = this.props.defaultYear || '';
        const { generatePatientOriginPowerpoint, currentMarket, currentVISN, facility, mpc, marketName, workloadType } = this.props;
        const generateRightFunc = () => {
            if (generatePatientOriginPowerpoint && currentMarket && currentVISN && facility && mpc && marketName && workloadType) {
                return generatePatientOriginPowerpoint(currentMarket, currentVISN.VisnCode, facility, mpc, marketName, workloadType);
            }

            return () => {};
        };

        const columns = PatientOriginColumns(mpc || '', defaultYear);
        const csvName = `${currentMarket || ''} ${mpc || ''} -${facility || ''}-${workloadType || ''}-PatientOrigin.csv`;

        return (
            <div>
                <ReactDataTable
                    list={this.props.patientOrigins.list || []}
                    columns={columns}
                    loading={this.props.patientOrigins.loading}
                    csvFileName={csvName}
                    isExportable={!disabled}
                    showCsvPptBtnGroup={!disabled}
                    btnRightFunc={generateRightFunc}
                    btnRightColor='danger'
                    btnRightLabel={this.props.patientOrigins.generating
                        ? 'Generating...'
                        : 'Export to PowerPoint'}
                />
            </div>
        );
    }

    static propTypes = {
        facility: PropTypes.string,
        marketName: PropTypes.string,
        mpc: PropTypes.string,
        workloadType: PropTypes.number
    };
}
const mapDispatchToProps = (dispatch) => ({
    getPatientOrigins: (facility, marketName, mpc, workloadType) =>
        dispatch.PatientOriginToolModel.fetchPatientOriginsAsync({
            facility,
            marketName,
            mpc,
            workloadType
        }),
    generatePatientOriginPowerpoint: (market, visnCode, facility, hspcg, origin, workloadType) =>
        dispatch.PatientOriginToolModel.generatePOReportAsync({
            market,
            visnCode,
            facility,
            hspcg,
            origin,
            workloadType
        })
});
const mapStateToProps = (state) => ({
    currentMarket: getCurrentMarketName(state),
    currentVISN: getCurrentVISN(state),
    defaultYear: getDefaultYearObject(state),
    patientOrigins: getPatientOriginsSelector(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(UDPOTable);
