import React from 'react';

import FacilityReportDDFContainer from './FacilityReportDDFContainer';
import HSPCGroupSection from './HSPCGroupSection';

const sectionConfig = [
    {
        path: 'Primary',
        title: 'Primary Care (Enrollment)',
        units: 'Panelized Enrollees',
        hideTotalsRow: true
    },
    {
        path: 'Inpatient',
        title: 'Inpatient Care (Utilization: Beds)',
        units: 'Beds'
    },
    {
        path: 'Outpatient',
        title: 'Specialty Care (RVU)',
        units: 'RVUs'
    }
];

type FacilityReportContentProps = {
    stationNumber: string;
    report: {
        [key: string]: unknown[];
    };
    baseYear: number;
};

/**
 * A React component that displays the contents of a facility report.
 *
 * @param props - props passed into this component
 * @param props.stationNumber - the facility number
 * @param props.report - the report data
 * @param props.baseYear - the base year
 * @returns the facility report content
 */
const FacilityReportContent = ({
    stationNumber,
    report,
    baseYear
}: FacilityReportContentProps): JSX.Element => {
    const sections = sectionConfig.map(({ path, title, units, hideTotalsRow }) => (
        <HSPCGroupSection
            key={path}
            title={title}
            rows={report[path]}
            units={units}
            baseYear={baseYear}
            hideTotalsRow={!!hideTotalsRow}
        />
    ));

    return (
        <div>
            <FacilityReportDDFContainer stationNumber={stationNumber} />
            {sections}
        </div>
    );
};

export default FacilityReportContent;
