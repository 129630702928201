import React, { useEffect, useState } from 'react';

import { useHSPADispatch, useHSPASelector } from 'storeTypes';
import styled from 'styled-components/macro';

import LoadingSpinner from 'Lib/AsyncContent/LoadingPanel/LoadingSpinner';
import ModuleNavigation from 'Lib/Tabs/ModuleNavigation';
import { getVideoTutorialList } from 'Modules/UserSession/selector';
import { VideoTutorial } from 'Utilities/types';

const VideoContentPane = styled.div`
    padding-top: 15px;
`;

const BlankModalPanel = styled.div`
    height: 650px;
`;

type VideoTutorialResult = {
    list: VideoTutorial[];
    loading: boolean;
};

/**
 * Render and display the training video modal
 *
 * @returns Rendered modal
 */
const TrainingVideoPanelBody = (): JSX.Element => {
    const dispatch = useHSPADispatch();
    const { list, loading } = useHSPASelector(getVideoTutorialList) as VideoTutorialResult;

    const [activeTab, setActiveTab] = useState<string | undefined>();

    const handleOnClick = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchVideoTutorialsAsync = async () => {
            await dispatch.UserSessionModel.fetchVideoTutorialsAsync();
        };

        fetchVideoTutorialsAsync();
    }, []);

    useEffect(() => {
        if (list && list.length > 0 && !activeTab) {
            setActiveTab(list[0].key);
        }
    }, [list]);

    const videos = list.map(({ key, src }: VideoTutorial): JSX.Element | null =>
        activeTab === key ? (
            <video width="100%" height="600" controls>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        ) : null
    );

    if (loading) {
        return (
            <BlankModalPanel>
                <LoadingSpinner animation="border" variant="primary" />
            </BlankModalPanel>
        );
    }

    if (!list || list.length <= 0) {
        return (
            <BlankModalPanel>
                <span className="mx-auto align-middle text-center font-weight-bold">
                    Video Tutorials Have Not Been Loaded
                </span>
            </BlankModalPanel>
        );
    }

    return (
        <>
            <ModuleNavigation activeTab={activeTab} tabs={list} handleOnClick={handleOnClick} />
            <VideoContentPane>{videos}</VideoContentPane>
        </>
    );
};

export default TrainingVideoPanelBody;
