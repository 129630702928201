import React, { useEffect, useState } from 'react';

import { difference, intersection, map } from 'lodash';
import styled from 'styled-components/macro';

import ModuleNavigation from 'Lib/Tabs/ModuleNavigation';
import { MarketDDFContent } from 'Modules/SlideData/DDFSlides';

import DDFFacilityReportPane from '../DDFFacilityReportPane/index';
import DDFReportPane from '../DDFReportPane/index';

interface paneProps {
    sectionHeader?: string;
    sectionSlides: MarketDDFContent[][];
}

const defaultProps = {
    sectionHeader: undefined,
    sectionSlides: []
};

const ContentPane = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const emptyPlaceholder = <div className="text-muted">No DD&amp;F slides available</div>;

/**
 * Get all of the common words across a set of strings.
 *
 * @param stringArray - An array of strings.
 * @param tokenFilter - A collection of strings, that are removed from the common words.
 * @returns The common words between all provided strings, optionally with tokens in tokenFilter
 * filtered out.
 */
const getCommonWords = (stringArray: string[], tokenFilter: string[] = []): string => {
    const tokenized = map(stringArray, (item) => item.split(' '));
    const commonTokens = intersection(...tokenized);
    const filteredTokens = difference(commonTokens, tokenFilter);

    return filteredTokens.join(' ');
};

/**
 * Generate the pane components to use -- a standard Report or a FacilityReport pane.  A
 * FacilityReportPane is used if there are multiple slides, whereas a regular one is used if there's
 * only one slide.
 *
 * @param input - A 2-dimensional array of slide data.
 * @returns The component to use for the provided slides.
 */
const generatePaneElements = (input: MarketDDFContent[][]) => {
    const reports = input.map((slides) => {
        if (slides.length === 1) {
            return <DDFReportPane slide={slides[0]} />;
        }

        return <DDFFacilityReportPane slides={slides} />;
    });

    return reports;
};

function DDFPane({ sectionSlides }: paneProps) {
    const [activeTab, setActiveTab] = useState(0);
    useEffect(() => setActiveTab(0), [sectionSlides]);

    const changeTab = (tab: React.SetStateAction<number>) => {
        setActiveTab(tab);
    };

    // Get the tab labels from the provided slides
    const tabs = sectionSlides?.map((slideGroup, index) => {
        const titles = slideGroup?.map((slide) => slide.titleShort || slide.title);
        const commonWords = getCommonWords(titles, ['–']);
        return {
            key: index,
            label: commonWords
        };
    });

    const reportFrames = generatePaneElements(sectionSlides);

    const navigation =
        reportFrames.length > 1 ? (
            <ModuleNavigation activeTab={activeTab} tabs={tabs} handleOnClick={changeTab} />
        ) : null;

    const content = reportFrames.length > 0 ? reportFrames[activeTab] : emptyPlaceholder;

    return (
        <>
            {navigation}
            <ContentPane className="embed-responsive embed-responsive-16by9">{content}</ContentPane>
        </>
    );
}

export default DDFPane;
