import React from 'react';
import Alert from 'react-bootstrap/Alert';

type ErrorAlertProps = {
    actionDescription: string;
    errorMessage?: string;
};

/**
 * This component wraps a react-bootstrap Button with a react-router Link.
 *
 * @param {object} props - Props passed into this component.
 * @param {string} props.actionDescription - Description for what kind of action triggered the err.
 * @param {string} props.errorMessage - The error message.
 * @returns {React.ReactNode} The a bootstrap alert component used for error messaging.
 */
const ErrorAlert = ({ actionDescription, errorMessage }: ErrorAlertProps): JSX.Element => (
    <Alert variant="danger">
        <p className="mb-0">
            There was a problem with {actionDescription}. Please refresh the page and try again. If
            you continue to have problems, please take a screenshot and report this error to an
            administrator.
        </p>
        {Boolean(errorMessage) && (
            <p>
                <b>Error Message: {errorMessage}</b>
            </p>
        )}
    </Alert>
);

export default ErrorAlert;
