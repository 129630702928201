import React from 'react';

/**
 * Entry JS file for the HSPA application.
 * @author Anthony P. Pancerella
 * @version October 1, 2018
 */
import DDFContainer from 'Lib/DDFSlides/DDFContainer';

import SubTabComponent from '../../../../Lib/Tabs/SubTabComponent';
import ESSDHospitalCompare from './ESSDHospitalCompare';
import ESSDSail from './ESSDSail';
import ESSDSurveyIntroduction from './ESSDSurveyIntroduction';

export default class ESSurveyDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const tabs = [
            { key: 0, label: 'Survey of Enrollees' },
            { key: 1, label: 'Strategic Analytics for Improvement and Learning (SAIL)' },
            { key: 2, label: 'Hospital Compare' }
        ];
        return (
            <div>
                <DDFContainer
                    text={'There is relevant data published in the Data Discovery and Findings.'}
                    reports={[
                        {
                            title: 'Quality & Satisfaction',
                            subSections: [
                                {
                                    sectionHeader: 'Quality',
                                    sectionSlides: [132, 133, 134, 160]
                                },

                                {
                                    sectionHeader: 'Outpatient Procedure Volume',
                                    sectionSlides: [135]
                                },
                                {
                                    sectionHeader: 'Awards & Recognition',
                                    sectionSlides: [131]
                                },
                                {
                                    sectionHeader: 'Quality of Community Hospitals',
                                    sectionSlides: [130]
                                },
                                {
                                    sectionHeader: 'Patient Satisfaction',
                                    sectionSlides: [124, 125, 128, 129]
                                },
                                {
                                    sectionHeader: 'Market Trends',
                                    sectionSlides: [126, 127]
                                }
                            ]
                        }
                    ]}
                ></DDFContainer>
                <div>
                    <div style={{ paddingTop: 15 }}>
                        <SubTabComponent
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            handleOnClick={this.changeTab}
                        />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        {this.state.activeTab === 0 ? (
                            <div>
                                <ESSDSurveyIntroduction />
                                
                            </div>
                        ) : null}
                        {this.state.activeTab === 1 ? <ESSDSail /> : null}
                        {this.state.activeTab === 2 ? <ESSDHospitalCompare /> : null}
                    </div>
                </div>
            </div>
        );
    }
}
