import React from 'react';

import Button from 'Lib/Button';

export const UsersInNetworkColumnDefs = (editUser, deleteUser) => [
    {
        accessorKey: 'FirstName',
        header: 'First Name',
        meta: {
            headerDisplayName: 'First Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '120px' },
            cellClassName: 'text-left',
            cellStyle: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
        }
    },
    {
        accessorKey: 'LastName',
        header: 'Last Name',
        meta: {
            headerDisplayName: 'Last Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '120px' },
            cellClassName: 'text-left',
            cellStyle: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
        }
    },
    {
        accessorKey: 'Username',
        header: 'Username',
        meta: {
            headerDisplayName: 'Username',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-left',
            cellStyle: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
        }
    },
    {
        accessorKey: 'UserPhone',
        header: 'Phone',
        meta: {
            headerDisplayName: 'Phone',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '150px' },
            cellClassName: 'text-left'
        }
    },
    {
        id: 'Edit',
        header: 'Edit',
        enableSorting: false,
        cell: ({ row }) => (
            <Button
                color="warning"
                type="button"
                label="Edit"
                onClick={() => editUser(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            headerDisplayName: 'Edit',
            csvExportIgnore: true,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '80px' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'Delete',
        header: 'Delete',
        enableSorting: false,
        cell: ({ row }) => (
            <Button
                color="danger"
                type="button"
                label="Delete"
                onClick={() => deleteUser(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            headerDisplayName: 'Delete',
            csvExportIgnore: true,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap', width: '80px' },
            cellClassName: 'text-center'
        }
    }
];

export const UserGroupColumnDefs = (formatVisn, deleteRole, isAdmin, isVisnAdmin, curVisn) => [
    {
        id: 'GroupName',
        accessorFn: (originalRow) => originalRow.Group.GroupName,
        header: 'Role Name',
        meta: {
            headerDisplayName: 'Role Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'VISN',
        accessorFn: (originalRow) => originalRow.Group.VisnId,
        header: 'VISN',
        meta: {
            headerDisplayName: 'VISN',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: formatVisn
        }
    },
    {
        id: 'Delete',
        header: 'Delete',
        enableSorting: false,
        cell: ({ row }) => (
            <Button
                color="danger"
                type="button"
                label="Delete"
                disabled={!isAdmin && row.original.Group.VisnId !== curVisn.VisnNumber}
                onClick={() => deleteRole(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            headerDisplayName: 'Delete',
            csvExportIgnore: true,
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    }
];
