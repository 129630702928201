import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook designed for help with skipping re-renders.
 *
 * @returns - The hook data to use when skipping re-renders.
 */
const useSkipper = () => {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    const skip = useCallback(() => {
        shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
        shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
};

export default useSkipper;
