import React, { Component } from 'react';

export default class ESSDHospitalCompare extends Component {
    render() {
        return (
            <div>
                {/* <p>VA facility data is shown on some quality measures found on the Centers for Medicare and Medicaid Services (CMS) Hospital Compare website.
                     Hospital Compare Data helps consumers make decisions about where to get health care and encourages hospitals to improve the quality of care.</p> */}
                <p>The information on Hospital Compare helps Medicare beneficiaries and general public make decisions about where to get health care and encourages hospitals to improve the quality of care they provide.</p>
                <a href="https://www.accesstocare.va.gov/Healthcare/HospitalCompareData" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">Hospital Compare Data</a>
                <br/>
                <a href="https://www.medicare.gov/hospitalcompare/search.html" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">Hospital Compare External Website</a>
                <br/>
                <a href="https://www.va.gov/qualityofcare/apps/mcps-app.asp" style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">VA Medical Center Performance</a>
            </div>
        );
    }
}