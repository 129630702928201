/**
 * VISN Form component.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, Form } from 'redux-form';
import Button from 'Lib/Button';
import { getCurrentMarketName, getCurrentVISN, canEditVISN } from 'Modules/UserSession/selector';
import { getVisnFormObject } from 'Modules/EnvironmentalScan/Services/selector';

import TextAreaField from 'Lib/ReduxFormFields/TextAreaField';

class ESVISNMissionForm extends React.Component {
    componentDidMount() {
        const { currentVISN, fetchDataAsync } = this.props;
        if (currentVISN.VisnCode) {
            fetchDataAsync(currentVISN.VisnCode);
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const { currentVISN, fetchDataAsync } = this.props;
        const { currentVISN: nextCurrentVISN } = nextProps;

        if (nextCurrentVISN.VisnCode !== currentVISN.VisnCode) {
            fetchDataAsync(nextCurrentVISN.VisnCode);
        }
    }

    formGenerate = () => {
        const { marketName, currentVISN, generatePowerPointAsync } = this.props;
        generatePowerPointAsync({ market: marketName, visnCode: currentVISN.VisnCode });
    };

    formSubmit = (values) => {
        const { saveAsync, dispatch } = this.props;
        saveAsync(values);
        dispatch(reset('ESVISNMissionForm')); // Resets all fields
    };

    render() {
        const {
            canEdit,
            saving,
            loading,
            submitting,
            generating,
            invalid,
            pristine,
            reset: resetForm,
            handleSubmit
        } = this.props;
        const disableForm = !canEdit || saving || generating;
        return (
            <div style={{ marginTop: 15 }}>
                <div className="col-md-6 offset-md-3">
                    <br />
                    <Form onSubmit={handleSubmit(this.formSubmit)}>
                        <TextAreaField
                            name="VisnMission"
                            label="VISN Mission"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="VisnVision"
                            label="VISN Vision"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="VisnValues"
                            label="VISN Values"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <TextAreaField
                            name="VisnGoals"
                            label="VISN Goals"
                            loading={loading}
                            numberOfRows={3}
                            disabled={disableForm}
                        />
                        <div className="col-xs-12">
                            <div className="form-group">
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        color="success"
                                        type="submit"
                                        disabled={!canEdit || submitting}
                                        label={saving ? 'Saving...' : 'Save VISN Information'}
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        type="button"
                                        label={generating ? 'Generating...' : 'Generate PowerPoint'}
                                        disabled={invalid || submitting}
                                        onClick={this.formGenerate}
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        color="warning"
                                        type="button"
                                        label="Cancel"
                                        disabled={pristine || submitting}
                                        onClick={resetForm}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

const ReduxESVISNMissionForm = reduxForm({
    form: 'ESVISNMissionForm',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.VisnMission) {
            errors.VisnMission = 'VISN mission is required';
        }
        if (!values.VisnVision) {
            errors.VisnVision = 'VISN vision is required';
        }
        if (!values.VisnValues) {
            errors.VisnValues = 'VISN values is required';
        }
        if (!values.VisnGoals) {
            errors.VisnGoals = 'VISN goals is required';
        }

        return errors;
    }
})(ESVISNMissionForm);

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataAsync: (visnCode) => dispatch.VisnFormModel.fetchDataAsync(visnCode),
        generatePowerPointAsync: (payload) =>
            dispatch.VisnFormModel.generatePowerPointAsync(payload),
        saveAsync: (formValues) => dispatch.VisnFormModel.saveAsync(formValues)
    };
};

const mapStateToProps = (state) => {
    const VisnFormObject = getVisnFormObject(state);
    return {
        initialValues: VisnFormObject.data,
        saving: VisnFormObject.saving,
        generating: VisnFormObject.generating,
        loading: VisnFormObject.loading,
        marketName: getCurrentMarketName(state),
        currentVISN: getCurrentVISN(state),
        canEdit: canEditVISN(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxESVISNMissionForm);
