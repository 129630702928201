import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON, postJSON } from 'Utilities/apiCalls';

const capacityApi = `${process.env.REACT_APP_HSPA_API}/UnderstandCapacity/`;

export const ProviderFormModel = {
    state: {
        form: { data: {}, saving: false },
        drpOptionObjects: {
            facilityTypes: { list: [], loading: false },
            providerTypes: { list: [], loading: false },
            aa: {
                states: { list: [], loading: false },
                grid: { list: [], loading: false }
            },
            cp: {
                states: { list: [], loading: false },
                cities: { list: [], loading: false },
                specialties: { list: [], loading: false },
                grid: { list: [], loading: false }
            },
            fqhc: {
                states: { list: [], loading: false },
                counties: { list: [], loading: false },
                grid: { list: [], loading: false }
            },
            ihs: {
                states: { list: [], loading: false },
                grid: { list: [], loading: false }
            },
            dod: {
                states: { list: [], loading: false },
                grid: { list: [], loading: false }
            }
        }
    },
    reducers: {
        populateDrpLists(state, payload) {
            if (payload.objectName === 'facilityTypes' || payload.objectName === 'providerTypes')
                return {
                    ...state,
                    drpOptionObjects: {
                        ...state.drpOptionObjects,
                        [payload.objectName]: {
                            ...state.drpOptionObjects[payload.objectName],
                            list: payload.data
                        }
                    }
                };
            return {
                ...state,
                drpOptionObjects: {
                    ...state.drpOptionObjects,
                    [payload.objectName]: {
                        ...state.drpOptionObjects[payload.objectName],
                        [payload.subObjectName]: {
                            ...state.drpOptionObjects[payload.objectName][payload.subObjectName],
                            list: payload.data
                        }
                    }
                }
            };
        },
        toggleDrpLoading(state, objectName, subObjectName) {
            if (objectName === 'facilityTypes' || objectName === 'providerTypes')
                return {
                    ...state,
                    drpOptionObjects: {
                        ...state.drpOptionObjects,
                        [objectName]: {
                            ...state.drpOptionObjects[objectName],
                            loading: !state.drpOptionObjects[objectName].loading
                        }
                    }
                };
            return {
                ...state,
                drpOptionObjects: {
                    ...state.drpOptionObjects,
                    [objectName]: {
                        ...state.drpOptionObjects[objectName],
                        [subObjectName]: {
                            ...state.drpOptionObjects[objectName][subObjectName],
                            loading: !state.drpOptionObjects[objectName][subObjectName].loading
                        }
                    }
                }
            };
        },
        resetDrp(state, objectName, subObjectName) {
            if (objectName === 'facilityTypes' || objectName === 'providerTypes')
                return {
                    ...state,
                    drpOptionObjects: {
                        ...state.drpOptionObjects,
                        [objectName]: {
                            ...state.drpOptionObjects[objectName],
                            list: []
                        }
                    }
                };
            return {
                ...state,
                drpOptionObjects: {
                    ...state.drpOptionObjects,
                    [objectName]: {
                        ...state.drpOptionObjects[objectName],
                        [subObjectName]: {
                            ...state.drpOptionObjects[objectName][subObjectName],
                            list: []
                        }
                    }
                }
            };
        },
        populateForm(state, data) {
            return {
                ...state,
                form: {
                    ...state.form,
                    data: data
                }
            };
        },
        toggleSaving(state) {
            return {
                ...state,
                form: {
                    ...state.form,
                    saving: !state.form.saving
                }
            };
        },
        resetDrpOption(state, object, subObject) {
            return {
                ...state,
                drpOptionObjects: {
                    ...state.drpOptionObjects,
                    [object]: {
                        ...state.drpOptionObjects[object],
                        [subObject]: {
                            ...state.drpOptionObjects[object][subObject],
                            list: []
                        }
                    }
                }
            };
        },
        resetProviderForm(state) {
            return {
                ...state,
                form: { data: {}, saving: false, loading: false }
            };
        },
        reset(state) {
            return {
                form: { data: {}, saving: false, loading: false },
                drpOptionObjects: {
                    facilityTypes: { list: [], loading: false },
                    providerTypes: { list: [], loading: false },
                    aa: {
                        states: { list: [], loading: false },
                        grid: { list: [], loading: false }
                    },
                    cp: {
                        states: { list: [], loading: false },
                        cities: { list: [], loading: false },
                        specialties: { list: [], loading: false },
                        grid: { list: [], loading: false }
                    },
                    fqhc: {
                        states: { list: [], loading: false },
                        counties: { list: [], loading: false },
                        grid: { list: [], loading: false }
                    },
                    ihs: {
                        states: { list: [], loading: false },
                        grid: { list: [], loading: false }
                    },
                    dod: {
                        states: { list: [], loading: false },
                        grid: { list: [], loading: false }
                    }
                }
            };
        }
    },
    effects: (dispatch) => ({
        async saveProviderAsync(payload, state) {
            try {
                this.toggleSaving();
                await postJSON(
                    `${capacityApi}SaveProvider?facilityInGridId=${payload.facilityInGridId}`,
                    payload.formValues
                );
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'The provider has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                dispatch.ProvidersModel.fetchProvidersAsync(payload.formValues.MarketName);
                this.resetProviderForm();
                if (payload.planId !== null)
                    dispatch.PlannerModel.fetchPlanByIdAsync(payload.planId);
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save provider',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchFacilityTypesAsync(state) {
            try {
                this.resetDrp('facilityTypes');
                this.toggleDrpLoading('facilityTypes');
                let result = await getJSON(`${capacityApi}GetFacilityTypes`);
                this.populateDrpLists({
                    data: result,
                    objectName: 'facilityTypes',
                    subObjectName: ''
                });
                this.toggleDrpLoading('facilityTypes');
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch facility types',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchProviderTypesAsync(state) {
            try {
                this.resetDrp('providerTypes');
                this.toggleDrpLoading('providerTypes');
                let result = await getJSON(`${capacityApi}GetProviderTypes`);
                this.populateDrpLists({
                    data: result,
                    objectName: 'providerTypes',
                    subObjectName: ''
                });
                this.toggleDrpLoading('providerTypes');
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch provider types',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchFacilityStatesAsync(facilityType, state) {
            try {
                this.resetDrp(facilityType, 'states');
                this.toggleDrpLoading(facilityType, 'states');
                let facilityTypeId = 0;
                switch (facilityType) {
                    case 'fqhc':
                        facilityTypeId = 1;
                        break;
                    case 'ihs':
                        facilityTypeId = 2;
                        break;
                    case 'dod':
                        facilityTypeId = 3;
                        break;
                    case 'cp':
                        facilityTypeId = 4;
                        break;
                    case 'aa':
                        facilityTypeId = 5;
                        break;
                    default:
                        break;
                }
                let result = await getJSON(
                    `${capacityApi}GetFacilityStates?facilityType=${facilityTypeId}`
                );

                this.populateDrpLists({
                    data: result,
                    objectName: facilityType,
                    subObjectName: 'states'
                });
                this.toggleDrpLoading(facilityType, 'states');
            } catch (error) {
                this.toggleDrpLoading(facilityType, 'states');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch ' + facilityType + ' states',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchFqhcCountiesAsync(usState, state) {
            try {
                this.resetDrp('fqhc', 'counties');
                this.toggleDrpLoading('fqhc', 'counties');
                let result = await getJSON(
                    `${capacityApi}GetFqhcCountiesByStateAndType?state=${usState}`
                );
                this.populateDrpLists({
                    data: result,
                    objectName: 'fqhc',
                    subObjectName: 'counties'
                });
                this.toggleDrpLoading('fqhc', 'counties');
            } catch (error) {
                this.toggleDrpLoading('fqhc', 'counties');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch FQHC counties',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCpCitiesAsync(usState, state) {
            try {
                this.resetDrp('cp', 'cities');
                this.toggleDrpLoading('cp', 'cities');
                let result = await getJSON(
                    `${capacityApi}GetCommunityProviderCitiesByState?state=${usState}`
                );
                this.populateDrpLists({ data: result, objectName: 'cp', subObjectName: 'cities' });
                this.toggleDrpLoading('cp', 'cities');
            } catch (error) {
                this.toggleDrpLoading('cp', 'cities');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch community provider cities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchVaSpecialtiesAsync(state) {
            try {
                this.resetDrp('cp', 'specialties');
                this.toggleDrpLoading('cp', 'specialties');
                let result = await getJSON(`${capacityApi}GetAllVASpecialties`);
                this.populateDrpLists({
                    data: result,
                    objectName: 'cp',
                    subObjectName: 'specialties'
                });
                this.toggleDrpLoading('cp', 'specialties');
            } catch (error) {
                this.toggleDrpLoading('cp', 'specialties');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch community provider VA specialties',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchIhsFacilitiesAsync(usState, state) {
            try {
                this.resetDrp('ihs', 'grid');
                this.toggleDrpLoading('ihs', 'grid');
                let result = await getJSON(
                    `${capacityApi}GetAllIHSByStateCurrentYear?state=${usState}`
                );
                this.populateDrpLists({ data: result, objectName: 'ihs', subObjectName: 'grid' });
                this.toggleDrpLoading('ihs', 'grid');
            } catch (error) {
                this.toggleDrpLoading('ihs', 'grid');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch IHS facilities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchFqhcFacilitiesAsync(payload, state) {
            try {
                this.resetDrp('fqhc', 'grid');
                this.toggleDrpLoading('fqhc', 'grid');
                let result = await getJSON(
                    `${capacityApi}GetAvailableFqhcFacilitiesByTypeStateCountyAndMarketAsync?state=` +
                        payload.state +
                        '&county=' +
                        payload.county
                );
                this.populateDrpLists({ data: result, objectName: 'fqhc', subObjectName: 'grid' });
                this.toggleDrpLoading('fqhc', 'grid');
            } catch (error) {
                this.toggleDrpLoading('fqhc', 'grid');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch FQHC facilities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchDodFacilitiesAsync(usState, state) {
            try {
                this.resetDrp('dod', 'grid');
                this.toggleDrpLoading('dod', 'grid');
                let result = await getJSON(
                    `${capacityApi}GetDodInstallationsAsync?state=${usState}`
                );
                this.populateDrpLists({ data: result, objectName: 'dod', subObjectName: 'grid' });
                this.toggleDrpLoading('dod', 'grid');
            } catch (error) {
                this.toggleDrpLoading('dod', 'grid');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch DoD facilites',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchAaFacilitiesAsync(payload, state) {
            try {
                this.resetDrp('aa', 'grid');
                this.toggleDrpLoading('aa', 'grid');
                let result = await getJSON(
                    `${capacityApi}GetAcademicAffiliationsAsync?state=` +
                        payload.state +
                        '&market=' +
                        payload.market
                );
                this.populateDrpLists({ data: result, objectName: 'aa', subObjectName: 'grid' });
                this.toggleDrpLoading('aa', 'grid');
            } catch (error) {
                this.toggleDrpLoading('aa', 'grid');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch academic affiliation facilities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchCpFacilitiesAsync(payload, state) {
            try {
                this.resetDrp('cp', 'grid');
                this.toggleDrpLoading('cp', 'grid');
                let result = await getJSON(
                    `${capacityApi}GetCommunityProviderByStateCitySpecialtyAsync?specialty=` +
                        payload.specialty +
                        '&state=' +
                        payload.state +
                        '&city=' +
                        payload.city
                );
                this.populateDrpLists({ data: result, objectName: 'cp', subObjectName: 'grid' });
                this.toggleDrpLoading('cp', 'grid');
            } catch (error) {
                this.toggleDrpLoading('cp', 'grid');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch community provider facilities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
