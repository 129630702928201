import React from 'react';

import Button from 'Lib/Button';

const SavedMapsColumnDefs = (deleteSavedMap, canEdit) => {
    return [
        {
            accessorKey: 'GetElementZero',
            header: 'Map Title',
            meta: {
                headerDisplayName: 'Map Title',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'CreatedById',
            header: 'View Map',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                    color="primary"
                    type="button"
                    label="View Map"
                    onClick={() => {
                        var win = window.open(row.original.GetElementOne, '_blank');
                        win.focus();
                    }}
                    btnPaddingTop={1}
                    btnPaddingBottom={1}
                />
            ),
            meta: {
                headerDisplayName: 'View Map',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                csvExportIgnore: true
            }
        },
        {
            accessorKey: 'Id',
            header: 'Delete',
            enableSorting: false,
            cell: ({ row }) => (
                <Button
                        color="danger"
                        type="button"
                        label="Delete"
                        disabled={!canEdit}
                        onClick={() => deleteSavedMap(row.original.Id)}
                        btnPaddingTop={1}
                        btnPaddingBottom={1}
                    />
            ),
            meta: {
                headerDisplayName: 'View Map',
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-center',
                csvExportIgnore: true
            }
        }
    ];
};

export default SavedMapsColumnDefs;
