
export const UserAccountModel = {
    state: {
        show: false
    },
    reducers: {
        toggle(state) {
            return {
                ...state,
                show: !state.show
            }
        }
    },
    effects: (dispatch) => ({})
}
