import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'Utilities/constants';

const facilityReportSlice = createSlice({
    name: 'Facility Report',
    initialState: {
        facilities: {
            items: [],
            loadingStatus: null
        },
        reportFacility: null,
        report: {},
        reportLoadingStatus: null
    },
    reducers: {
        /**
         * Set the facilities stored in state.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setFacilities action
         * @param {string[]} action.payload - array of facilities
         */
        setFacilities(draftState, { payload }) {
            draftState.facilities.items = payload;
        },

        /**
         * Emit a loadFacilities action.  Does not update the state, but is used to kick off the
         * loadFacilities epic.
         */
        loadFacilities() {},

        /**
         * Update the loading status for the facilities to PENDING.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadFacilitiesStart(draftState) {
            draftState.facilities.loadingStatus = AsyncState.PENDING;
        },

        /**
         * Update the loading status for the facilities to SUCCESS.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadFacilitiesDone(draftState) {
            draftState.facilities.loadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Update the loading status for the facilities to ERROR.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadFacilitiesError(draftState) {
            draftState.facilities.loadingStatus = AsyncState.ERROR;
        },

        /**
         * Set the value of the current facility report.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setFacilityReport action
         * @param {object} action.payload - the payload for the action
         * @param {object} action.payload.report - the facility report data
         * @param {string} action.payload.stationNumber - the station number for the loaded facility
         */
        setFacilityReport(draftState, { payload }) {
            draftState.report = payload.report;
            draftState.reportFacility = payload.stationNumber;
        },

        /**
         * Emit a loadFacilityReport action.  Does not update the state, but is used to kick off the
         * loadFacilityReport epic.
         */
        loadFacilityReport() {},

        /**
         * Update the loading status for the facilityReport to PENDING.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadFacilityReportStart(draftState) {
            draftState.reportLoadingStatus = AsyncState.PENDING;
        },

        /**
         * Update the loading status for the facilityReport to SUCCESS.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadFacilityReportDone(draftState) {
            draftState.reportLoadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Update the loading status for the facilityReport to ERROR.
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadFacilityReportError(draftState) {
            draftState.reportLoadingStatus = AsyncState.ERROR;
        }
    }
});

export const {
    setFacilities,
    loadFacilities,
    loadFacilitiesStart,
    loadFacilitiesDone,
    loadFacilitiesError,
    setSelectedFacility,
    setFacilityReport,
    loadFacilityReport,
    loadFacilityReportStart,
    loadFacilityReportDone,
    loadFacilityReportError
} = facilityReportSlice.actions;

export default facilityReportSlice.reducer;
