import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useParentRoute from 'Utilities/hooks/useParentRoute';

type FacilityReportNavigationProps = {
    facility: {
        Name: string;
    };
};

/**
 * A React component to display navigation options for the facility reports in the form of a
 * breadcrumb.
 *
 * @param props - props passed into this component
 * @param props.facility - the facility information for the current facility
 * @returns the facility title with a breadcrumb
 */
const FacilityReportNavigation = ({ facility }: FacilityReportNavigationProps): JSX.Element => {
    const parentRoute = useParentRoute();

    return (
        <div>
            <h3>{facility.Name}</h3>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={parentRoute}>Facility List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{facility.Name}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    );
};

export default FacilityReportNavigation;
