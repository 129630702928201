import React from 'react';

import styled from 'styled-components/macro';

interface ActionContentProps {
    content: React.ReactNode;
    button: React.ReactNode;
}

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ContentContainer = styled.div`
    flex-grow: 1;
`;

const ButtonContainer = styled.div`
    margin-left: 24px;
`;

/**
 * A call to action div containing content with an action button.
 *
 * @param props - props passed into this component
 * @param props.content - content to display within this panel
 * @param props.button - an action button for the panel
 * @returns the rendered info panel
 */
const ActionContent: React.FC<ActionContentProps> = ({ content, button }) => (
    <FlexContainer>
        <ContentContainer>{content}</ContentContainer>
        <ButtonContainer>{button}</ButtonContainer>
    </FlexContainer>
);

export default ActionContent;
