import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { canEditVISN, getDefaultYear } from 'Modules/UserSession/selector';

import MCDCostChart from '../../MCDCostChart';
import MCDPlannerPrimaryChart from '../../MCDPlannerPrimaryChart';
import MatchModuleButtons from '../MatchModuleButtons';
import CapacityTable from '../MCDPWCapacityTable';
import DemandTable from '../MCDPWDemandTable';
import SolutionTable from '../MCDPWSolutionsTable';
import SupplyTable from '../MCDPWSupplyTable';
import CostErrorPanel from './CostErrorPanel';
import PlanInfoPanel from './PlanInfoPanel';

const pathToType = {
    workload: 'General',
    staffing: 'Staffing',
    space: 'Space',
    cost: 'Cost'
};

const PlanContent = ({ plan }) => {
    const { type } = useParams();
    const dispatch = useDispatch();
    const calculationType = pathToType[type];

    const year = useSelector(getDefaultYear);
    const canEdit = useSelector(canEditVISN);

    const toggleJustificationModal = useCallback(
        (planItem, calcType) =>
            dispatch.PlannerModel.toggleJustificationModal({ planItem, calcType }),
        [dispatch]
    );

    const updateWorkspaceData = useCallback(
        (calcType, rowID, element, value, totalsFilter) =>
            dispatch.PlannerModel.updateWorkspaceData({
                calcType,
                rowID,
                element,
                value,
                totalsFilter
            }),
        [dispatch]
    );

    const resetPlanRow = useCallback(
        (planId, rowId, rowType, objectName) =>
            dispatch.PlannerModel.resetPlanRowAsync({
                planId,
                rowId,
                rowType,
                objectName
            }),
        [dispatch]
    );

    return (
        <>
            <PlanInfoPanel
                calculationType={calculationType}
                typeOfCare={plan.planData.Plan?.Hspc.MpcType}
            />
            <CostErrorPanel 
                calculationType={calculationType}
                costData={plan.planData.HspaCosts}
            />
            {calculationType !== 'Cost' ?
                <MCDPlannerPrimaryChart calculationType={calculationType} />
                : <MCDCostChart calculationType={calculationType} />
            }
            {calculationType !== 'Cost' ? (
                <DemandTable
                    calculationType={calculationType}
                    currentPlan={plan}
                    defaultYear={year}
                />
            ) : null }
            {calculationType === 'General' ? (
                <CapacityTable
                    calculationType={calculationType}
                    currentPlan={plan}
                    defaultYear={year}
                    updateWorkspaceData={updateWorkspaceData}
                    canEdit={canEdit}
                />
            ) : null}
            <SupplyTable
                calculationType={calculationType}
                currentPlan={plan}
                defaultYear={year}
                canEdit={canEdit}
                toggleJustificationModal={toggleJustificationModal}
                updateWorkspaceData={updateWorkspaceData}
                resetPlanRow={resetPlanRow}
            />
            {calculationType !== 'Cost' ? (
                <SolutionTable
                calculationType={calculationType}
                currentPlan={plan}
                defaultYear={year}
            />
            ) : null }
            
            <MatchModuleButtons
                type={calculationType}
                plan={plan.planData.Plan}
            />
        </>
    );
};

export default PlanContent;
