/**
 * Title bar, below the navigation bar.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import './style.css';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DropdownField from 'Lib/Fields/DropdownField';
import {
    getCurrentMarket,
    getCurrentVISN,
    getDefaultYear,
    getMarketList,
    getUserProfile,
    getVISNList
} from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';
import useMountEffect from 'Utilities/hooks/useMountEffect';

const HeaderContainer = ({ pageName }) => {
    const dispatch = useDispatch();
    const userMarket = useSelector(getCurrentMarket);
    const userVISN = useSelector(getCurrentVISN);
    const marketList = useSelector(getMarketList);
    const visnList = useSelector(getVISNList);
    const defaultYear = useSelector(getDefaultYear);
    const userProfile = useSelector(getUserProfile);

    const setUserVISN = dispatch.UserSessionModel.setCurrentVISN;
    const setUserMarket = dispatch.UserSessionModel.setCurrentMarket;
    const fetchUserProfile = dispatch.UserSessionModel.fetchUserProfileAsync;
    const { fetchDefaultYear } = dispatch.UserSessionModel;
    const resetTables = dispatch.UserSessionModel.resetTablesOnVISNAndMarketChangeAsync;

    const onVISNSelection = (selectedVISNCode) => {
        // check if selected VISN is same as current VISN
        if (selectedVISNCode === userVISN.VisnCode) {
            return;
        }

        visnList.list.forEach((visn) => {
            if (visn.VisnCode === selectedVISNCode) {
                setUserVISN(visn);
                // set first market in VISN
                setUserMarket(marketList.list.find((market) => market.VisnCode === visn.VisnCode));
            }
        });
        resetTables('visn');
    };

    const onMarketSelection = (selectedMarket) => {
        if (selectedMarket === userMarket.MarketName) {
            return;
        }

        setUserMarket(marketList.list.find((item) => item.MarketName === selectedMarket));
        resetTables('market');
    };

    useMountEffect(() => {
        if (!userProfile) {
            fetchUserProfile();
        }
        if (!defaultYear) {
            fetchDefaultYear();
        }
    });

    return (
        <div
            style={{
                paddingTop: '10px',
                backgroundColor: '#fff',
                borderBottom: '1px solid #e0e0e0'
            }}
        >
            <form className="row">
                <div className="col-sm-4" style={{ paddingTop: '20px', paddingLeft: '30px' }}>
                    <div>
                        <h2 style={{ font: '24pt', color: '#8C001A' }}>{pageName}</h2>
                    </div>
                </div>
                <div className="col-sm-8 row">
                    <div className="col-sm-6">
                        <DropdownField
                            className="titleBarDropdown"
                            fieldId="titleComponentField"
                            label="Select Your VISN"
                            options={visnList.list}
                            displayField="DisplayName"
                            valueField="VisnCode"
                            value={userVISN.VisnCode}
                            onChangeFunction={onVISNSelection}
                            loading={visnList.loading}
                            isClearable={false}
                            labelPosition={12}
                            inputPosition={12}
                        />
                    </div>
                    <div className="col-sm-6">
                        <DropdownField
                            className="titleBarDropdown"
                            fieldId="titleComponentField"
                            label="Select Your Market"
                            options={marketList.list.filter(
                                (item) => item.VisnCode === userMarket.VisnCode
                            )}
                            displayField="MarketName"
                            valueField="MarketName"
                            value={userMarket.MarketName}
                            onChangeFunction={onMarketSelection}
                            loading={marketList.loading}
                            isClearable={false}
                            labelPosition={12}
                            inputPosition={12}
                        />
                    </div>
                </div>
            </form>
            <div className="col-md-12" hidden={process.env.REACT_APP_SERVER !== 'TEST'}>
                <p style={{ color: 'red' }}>
                    You are visiting the Development or Test HSPA application. The changes you make
                    here will not be considered. You must use the production{' '}
                    <a
                        href="https://hspa.prod.mah.vaec.va.gov/"
                        style={{ textDecoration: 'underline' }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        HSPA
                    </a>
                    &nbsp; application in order to have your changes considered.
                </p>
            </div>
        </div>
    );
};

HeaderContainer.propTypes = {
    pageName: PropTypes.string.isRequired
};

export default HeaderContainer;
