import React from 'react';
import { useSelector } from 'react-redux';

/**
 * DCSO chart that displays current metrics of a plan as a graph.
 *
 * @author Anthony P. Pancerella, Brandan D. Reed
 */
import { getDefaultYear } from 'Modules/UserSession/selector';
import propTypes from 'prop-types';

import { getChartData } from '../../Services/selector';

const ReactHighcharts = require('react-highcharts');
const HighchartsMore = require('highcharts-more');

HighchartsMore(ReactHighcharts.Highcharts);

const HighchartsExporting = require('highcharts-exporting');

HighchartsExporting(ReactHighcharts.Highcharts);

const chartOptions = {
    chart: {
        type: 'line',
        height: '500',
        align: 'center'
    },
    title: {
        text: 'Demand Capacity Supply Planner'
    },
    exporting: {
        enabled: true,
        sourceHeight: '500',
        sourceWidth: '1000'
    },
    plotOptions: {
        column: {
            stacking: 'normal'
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
    },
    colors: [
        '#68354C',
        '#7B5E3F',
        '#2C3E50',
        '#617487',
        '#85919C',
        '#D0B090',
        '#AF7992',
        '#6B9A79',
        '#1B502B'
    ]
};

const MCDPlannerPrimaryChart = React.memo(({ calculationType }) => {
    const defaultYear = useSelector(getDefaultYear);
    const chartData = useSelector((state) => getChartData(state, calculationType));

    const categories = Array(11)
        .fill()
        .map((item, index) => defaultYear + index);

    const options = {
        ...chartOptions,
        yAxis: {
            title: null,
            reversedStacks: false,
            labels: {
                formatter: function() {
                    return this.value;
                }
            }
        },
        xAxis: { categories },
        tooltip: {
            valueDecimals: calculationType === 'Staffing' ? 2 : 0
        },
        series: chartData
    };

    return (
        <div>
            <ReactHighcharts config={options} />
            <br />
        </div>
    );
});

MCDPlannerPrimaryChart.propTypes = {
    calculationType: propTypes.string.isRequired
};

export default MCDPlannerPrimaryChart;
