import React, { Component } from 'react';

import Button from 'Lib/Button';
import TextAreaField from 'Lib/Fields/TextAreaField';

class SAAdminResponseForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            userResponse: props.row.ResponseText ? props.row.ResponseText : "",
            id: props.row.Id
        };
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    <div className="col-md-12">
                        <TextAreaField
                            value={this.props.row.Question}
                            label="Question"
                            disabled
                            onChangeFunction={() => null}
                            labelPosition={12}
                            inputPosition={12}/>
                        <TextAreaField
                            value={this.state.userResponse}
                            label="Response"
                            disabled={false}
                            onChangeFunction={(responseInput) => {
                                let input = responseInput.target.value;
                                this.setState((prevState) => ({
                                    ...prevState,
                                    userResponse: input
                                }));
                            }}
                            labelPosition={12}
                            inputPosition={12}/>
                    </div>
                    <div className="col-md-12" style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Button
                            color="primary"
                            type="button"
                            label="Save Response"
                            onClick={() =>
                                this.props.editQuestionResponse(this.props.row, this.state)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SAAdminResponseForm;
