import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import PropTypes from 'prop-types';

type HSPACarouselItemProps = {
    backgroundImage: string;
    headerLinkPath?: string;
    title?: React.ReactNode;
    header?: React.ReactNode;
    body?: React.ReactNode;
    isActive?: boolean;
};

const CarouselImg = styled.img`
    @media screen and (min-width: 992px) and (max-width: 1150px) {
        height: calc(100vh - 64px);
    }
    object-fit: cover;
    height: calc(100vh - 45px);
    width: 100%;
`;

/**
 * An item in the carousel on the HSPA home page, based on Bootstrap carousel classes.  Title and
 * header can be customized, as well as the background image and a path for a link to one of the
 * application's tabs.
 * @param {Object} props - props passed into this component
 * @returns {React.ReactNode} the carousel item to render to the DOM
 */
const HSPACarouselItem: React.FC<HSPACarouselItemProps> = ({
    backgroundImage,
    headerLinkPath,
    title,
    header,
    body,
    isActive = false
}) => {
    const classNames = ['carousel-item'];
    if (isActive) {
        classNames.push('active');
    }

    const titleElement = title ? <h1 style={{ textAlign: 'center' }}>{title}</h1> : null;

    const carouselHeader = headerLinkPath ? (
        <Link to={`${headerLinkPath}`} style={{ color: '#000' }}>
            <h4>{header}</h4>
        </Link>
    ) : (
        <h4>{header}</h4>
    );

    return (
        <div className={classNames.join(' ')}>
            <CarouselImg alt="" src={backgroundImage} />
            <div
                className="carousel-caption"
                style={{
                    textAlign: 'left',
                    color: 'black',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: 0,
                    height: 'max-content'
                }}
            >
                <div className="main-content">
                    {titleElement}
                    {carouselHeader}
                    <p>{body}</p>
                </div>
            </div>
        </div>
    );
};

HSPACarouselItem.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    headerLinkPath: PropTypes.string,
    title: PropTypes.node,
    header: PropTypes.node,
    body: PropTypes.node,
    isActive: PropTypes.bool
};

export default HSPACarouselItem;
