import React from 'react';

import styled from 'styled-components/macro';

import Callout from 'Lib/Callout';
import BarChart from 'Lib/Charts/BarChart';

import Header from './Header';

type MetricCardProps = {
    metric: {
        id: number;
        hspcType: string;
        market: string;
        projectionType: string;
        status: 'good' | 'bad';
        projectedValue: number;
        description: string;
        barGraphData: [];
    };
    onClick: (id: number, hspcType: string, market: string, projectionType: string) => void;
};

const MetricCardButton = styled.button`
    background-color: inherit;
    border: none;
    padding: 0;
    width: 250px;
    height: 130px;
    margin: 10px;
`;

const CalloutCard = styled(Callout)`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: left;
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;

const calloutConfigs = {
    good: {
        variant: 'primary',
        color: '#0059FF'
    },
    bad: {
        variant: 'warning',
        color: '#FFA500'
    }
};

/**
 * A component that displays a preview of metric data in a card.  It displays a projection value,
 * an icon for the projected direction, and a small bar graph showing the past values.
 *
 * @param props - props passed into this component
 * @param props.metric - metric data
 * @param props.onClick - behavior that should occur when clicking on this card
 * @returns the metric card
 */
const MetricCard = ({ metric, onClick }: MetricCardProps): JSX.Element => {
    const calloutConfig = calloutConfigs[metric.status];

    return (
        <MetricCardButton
            type="button"
            onClick={() =>
                onClick(metric.id, metric.hspcType, metric.market, metric.projectionType)
            }
        >
            <CalloutCard variant={calloutConfig.variant}>
                <Header value={metric.projectedValue} color={calloutConfig.color} />
                <CardContent>
                    <div>{metric.description}</div>
                    <BarChart data={metric.barGraphData} color={calloutConfig.color} />
                </CardContent>
            </CalloutCard>
        </MetricCardButton>
    );
};

export default MetricCard;
