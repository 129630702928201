import { AsyncState } from 'Utilities/constants';
import { Recommendation } from 'Utilities/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OpportunitiesState = {
    items: Recommendation[];
    market?: string;
    loadingStatus?: AsyncState;
};

type MarketOpportunities = {
    opportunities: Recommendation[];
    market: string;
};

const opportunitiesSlice = createSlice({
    name: 'Opportunities',
    initialState: { items: [] } as OpportunitiesState,
    reducers: {
        /**
         * Update the state with the provided opportunities and market.
         *
         * @param draftState - the current opportunities slice of the application redux state
         * @param action - the setOpportunities action
         * @param action.payload - the payload containing a market and its related opportunities
         */
        setOpportunities(draftState, { payload }: PayloadAction<MarketOpportunities>) {
            draftState.items = payload.opportunities;
            draftState.market = payload.market;
        },

        /**
         * Does not update the state, but the related action is use to kick off an epic that loads
         * the opportunities.
         *
         * @param draftState - the current opportunities slice of the application redux state
         * @param action - the loadOpportunities action
         * @param action.payload - the market to request opportunities for
         */
        loadOpportunities(
            // This action is not used to update the state, but we do want to define its payload
            // type here
            draftState,
            action: PayloadAction<string>
        ) {},

        /**
         * Set the opportunities loading status to PENDING.  This is called when the initial request
         * to load opportunities is first dispatched, so that the loadingStatus can be used to
         * update the user as to the status of the request.
         *
         * @param draftState - the current opportunities slice of the application redux state
         */
        loadOpportunitiesStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },

        /**
         * Set the opportunities loading status to SUCCESS.  This is called when the request has
         * successfully completed loading opportunities, to indicate that the opportunities data
         * in the state can now be displayed.
         *
         * @param draftState - the current opportunities slice of the application redux state
         */
        loadOpportunitiesDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the opportunities loading status to FAILURE.  This is called when the request
         * encounters an error of some kind, so that an error can be displayed to the user.
         *
         * @param draftState - the current opportunities slice of the application redux state
         */
        loadOpportunitiesError(draftState) {
            draftState.loadingStatus = AsyncState.FAILURE;
        }
    }
});

export const {
    loadOpportunities,
    loadOpportunitiesDone,
    loadOpportunitiesError,
    loadOpportunitiesStart,
    setOpportunities
} = opportunitiesSlice.actions;

export default opportunitiesSlice.reducer;
