import React from 'react';
import { connect } from 'react-redux';

import _filter from 'lodash/filter';
import _merge from 'lodash/merge';

import DropdownField from 'Lib/Fields/DropdownField';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import {
    getWorkloadAllocationMatrixDataSelector,
    getWorkloadAllocationMatrixMpcsSelector
} from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentVISN, getDefaultYear } from 'Modules/UserSession/selector';

import WorkloadAllocationMatrixTableColumnDefs from './TableOutline';

class UDWorkloadAllocationMatrix extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedHSPCG: '',
            projectionFilter: 'Baseline'
        };
    }

    componentDidMount() {
        if (this.props.hspcOptions.list.length === 0) {
            this.props.getMatrixMpcs();
        }
    }

    // TODO this needs to be rewritten. componentDidUpdate is previous state/props not next
    componentDidUpdate(nextProps, nextState) {
        const { VisnCode } = this.props.userVISN;
        if (nextProps.userVISN.VisnCode !== VisnCode && this.state.selectedHSPCG !== '') {
            this.props.getWorkloadAllocationMatrixData({
                visn: nextProps.userVISN.VisnCode,
                mpc: this.state.selectedHSPCG
            });
        } else if (nextState.selectedHSPCG !== this.state.selectedHSPCG) {
            this.props.getWorkloadAllocationMatrixData({
                visn: VisnCode,
                mpc: nextState.selectedHSPCG
            });
        }

        if (VisnCode && this.state.selectedHSPCG === '' && nextProps.hspcOptions.list.length > 0) {
            this.setState({ selectedHSPCG: nextProps.hspcOptions.list[0].DisplayField });
            this.props.getWorkloadAllocationMatrixData({
                visn: VisnCode,
                mpc: nextProps.hspcOptions.list[0].DisplayField
            });
        }
    }

    onHSPCGSelect = (values) => {
        values === null
            ? this.setState((previousState) => _merge({}, previousState, { selectedHSPCG: '' }))
            : this.setState((previousState) =>
                  _merge({}, previousState, { selectedHSPCG: values })
              );
    };

    onFilterSelect = (values) => {
        values === null
            ? this.setState((previousState) => _merge({}, previousState, { projectionFilter: '' }))
            : this.setState((previousState) =>
                  _merge({}, previousState, { projectionFilter: values })
              );
    };

    render() {
        const { hspcOptions, matrixData, defaultYear } = this.props;
        return (
            <div style={{ paddingTop: 15 }}>
                <div>
                    <div>
                        <DropdownField
                            options={hspcOptions.list}
                            label="Select an HSPCG"
                            placeholder="Select an HSPCG..."
                            loading={hspcOptions.loading}
                            value={this.state.selectedHSPCG}
                            onChangeFunction={this.onHSPCGSelect}
                            displayField="DisplayField"
                            valueField="DisplayField"
                            labelPosition={12}
                            inputPosition={12}
                        />
                    </div>
                </div>
                <div className="btn-group" style={{ paddingBottom: 15 }}>
                    <button
                        type="button"
                        className={
                            this.state.projectionFilter === 'Baseline'
                                ? 'btn btn-primary'
                                : 'btn btn-info'
                        }
                        onClick={() => this.onFilterSelect('Baseline')}
                    >
                        Baseline
                    </button>
                    <button
                        type="button"
                        className={
                            this.state.projectionFilter === 'TenYearProjected'
                                ? 'btn btn-primary'
                                : 'btn btn-info'
                        }
                        onClick={() => this.onFilterSelect('TenYearProjected')}
                    >
                        Ten Year Projected
                    </button>
                    <button
                        type="button"
                        className={
                            this.state.projectionFilter === 'TwentyYearProjected'
                                ? 'btn btn-primary'
                                : 'btn btn-info'
                        }
                        onClick={() => this.onFilterSelect('TwentyYearProjected')}
                    >
                        Twenty Year Projected
                    </button>
                </div>
                <ReactDataTable
                    keyValue="facility"
                    columns={WorkloadAllocationMatrixTableColumnDefs(
                        _filter(matrixData.list, (item) => {
                            if (item.type === this.state.projectionFilter) {
                                return item;
                            }
                        })
                    )}
                    list={_filter(matrixData.list, (item) => {
                        if (item.type === this.state.projectionFilter) {
                            return item;
                        }
                    })}
                    loading={matrixData.loading}
                    csvFileName={`${this.props.userVISN.VisnCode}-${this.state.selectedHSPCG}-WorkloadAllocationMatrix-${defaultYear}.csv`}
                    isPageable={false}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getWorkloadAllocationMatrixData: (payload) =>
        dispatch.WorkloadAllocationMatrixModel.fetchWorkloadAllocationMatrixAsync(payload),
    getMatrixMpcs: () => dispatch.WorkloadAllocationMatrixModel.fetchMatrixHspcOptionsAsync()
});
const mapStateToProps = (state) => ({
    matrixData: getWorkloadAllocationMatrixDataSelector(state),
    hspcOptions: getWorkloadAllocationMatrixMpcsSelector(state),
    userVISN: getCurrentVISN(state),
    defaultYear: getDefaultYear(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(UDWorkloadAllocationMatrix);
