import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import Stepper from 'Lib/Stepper';
import useMountEffect from 'Utilities/hooks/useMountEffect';

import { reduxForm, reset } from 'redux-form';

import EnvironmentalScanPage from './RWFEnvironmentalScan';
import ReportHeaderPage from './RWFHeader';
import MatchPage from './RWFMatchCapacityDemand';
import UnderstandCapacityPage from './RWFUnderstandCapacity';
import UnderstandDemandPage from './RWFUnderstandDemand';

const Form = () => {
    const dispatch = useDispatch();
    const formType = useSelector((state) => {
        if (_isEmpty(state.ReportFormModel.ReportForm.data)) {
            return 'create';
        }
        return 'edit';
    });
    const report = useSelector((state) => state.ReportFormModel);
    const reportForm = useSelector((state) => state.form.ReportForm || {});
    const gapMPCTypes = useSelector((state) => state.UserSessionModel.gapMpcTypes);
    const routeMatch = useRouteMatch();
    const { reportID } = useParams();

    const [page, setPage] = useState(0);

    useEffect(() => {
        if (reportID) {
            dispatch.ReportFormModel.fetchReportByIdAsync(reportID);
        }
    }, [dispatch, reportID]);

    // TODO: Move this to the RWFHeader
    useMountEffect(() => {
        dispatch(reset('ReportForm'));
        dispatch.ReportFormModel.resetTable('ReportForm');
        if (gapMPCTypes.list.length === 0) {
            dispatch.UserSessionModel.fetchGapMpcTypesAsync();
        }
    });

    const validatePage = useCallback((form) => {
        if (!_isEmpty(form)) {
            if (!_isEmpty(form.syncErrors) && form.submitFailed) {
                return false;
            }
        }
        return true;
    }, []);

    const nextPage = useCallback(() => {
        setPage(page + 1);
    }, [page, setPage]);

    const previousPage = useCallback(() => {
        setPage(page - 1);
    }, [page, setPage]);

    const resetStepper = useCallback(() => {
        setPage(0);
    }, [setPage]);

    const steps = [
        { name: 'Report Header', isValid: validatePage(reportForm) },
        { name: 'Environmental Scan', isValid: true },
        { name: 'Understand Demand', isValid: true },
        { name: 'Understand Capacity', isValid: true },
        { name: 'Match Capacity/Demand', isValid: true }
    ];

    return (
        <div className="col-md-8 offset-md-2">
            <div className="form-group" style={{ textAlign: 'center', paddingLeft: 15 }}>
                {report.ReportForm.loading ? (
                    <h3>Loading Report...</h3>
                ) : (
                    <h3>{formType === 'create' ? 'Create New Report' : 'Edit Report'} </h3>
                )}
            </div>
            <div className="step-progress">
                <Stepper steps={steps} currentStep={page} movePageByStepNum={setPage} />
            </div>
            <div style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 12 }}>
                {page === 0 && (
                    <ReportHeaderPage
                        onSubmit={nextPage}
                        currentPage={page}
                        previousPage={previousPage}
                    />
                )}
                {page === 1 && (
                    <EnvironmentalScanPage
                        onSubmit={nextPage}
                        currentPage={page}
                        previousPage={previousPage}
                    />
                )}
                {page === 2 && (
                    <UnderstandDemandPage
                        onSubmit={nextPage}
                        currentPage={page}
                        previousPage={previousPage}
                    />
                )}
                {page === 3 && (
                    <UnderstandCapacityPage
                        onSubmit={nextPage}
                        currentPage={page}
                        previousPage={previousPage}
                    />
                )}
                {page === 4 && (
                    <MatchPage
                        // onSubmit={this.formSubmit}
                        currentPage={page}
                        previousPage={previousPage}
                        resetStepper={resetStepper}
                    />
                )}
                <div style={{ width: '100%', height: '30px' }} />
            </div>
        </div>
    );
};

const ReduxForm = reduxForm({ form: 'ReportForm' })(Form);

export default ReduxForm;
