import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { HSPAState } from 'storeTypes';
import styled from 'styled-components/macro';

import { HSPANotification } from 'Types/HSPANotification';

import HSPAAlert from './HSPAAlert';

const AlertContainer = styled.div`
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    z-index: 1000;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px;
    padding-top: 50px;

    & > .alert {
        width: 300px;
    }

    & .close {
        top: -0.1rem;
    }
`;

/**
 * Displays notifications for HSPA.
 *
 * @returns a container which hosts the HSPA notifications
 */
const HSPANotifications = (): JSX.Element => {
    // Get the latest notification from the state
    const latestNotification = useSelector((state: HSPAState) => state.notifications);
    const [notifications, setNotifications] = useState<HSPANotification[]>([]);

    // Add the latest notification to the component
    useEffect(() => {
        if (latestNotification.message) {
            setNotifications((current) => [...current, latestNotification]);
        }
    }, [setNotifications, latestNotification]);

    // Remove a notification from the component
    const removeNotification = useCallback(
        (notification) => {
            const notificationIndex = notifications.indexOf(notification);
            setNotifications([
                ...notifications.slice(0, notificationIndex),
                ...notifications.slice(notificationIndex + 1)
            ]);
        },
        [setNotifications, notifications]
    );

    const alerts = notifications.map((notification) => (
        <HSPAAlert
            key={notification.timestamp.getTime()}
            variant={notification.level}
            onHide={() => removeNotification(notification)}
        >
            {notification.message}
        </HSPAAlert>
    ));

    return <AlertContainer>{alerts}</AlertContainer>;
};

export default HSPANotifications;
