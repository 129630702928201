import { AsyncState } from 'Utilities/constants';
import { Plan } from 'Utilities/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PlanSelectionState = {
    items: Plan[];
    market?: string;
    loadingStatus?: AsyncState;
};

export type PlanSelectionPayload = {
    planSelections: Plan[];
    market: string;
};

const planSelectionSlice = createSlice({
    name: 'Plan Selection',
    initialState: { items: [] } as PlanSelectionState,
    reducers: {
        /**
         * Set the plan selection loading status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadPlanSelectionStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },

        /**
         * Set the plan selection loading status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadPlanSelectionDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the plan selection loading status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadPlanSelectionError(draftState) {
            draftState.loadingStatus = AsyncState.FAILURE;
        },

        /**
         * Placeholder for loading a plan selection.
         *
         * @param draftState - The draft state.
         * @param action - The user action.
         */
        loadPlanSelection(draftState, action: PayloadAction<string>) {},

        /**
         * Sets the plan selection data.
         *
         * @param draftState - The draft state.
         * @param action - The user action.
         * @param action.payload - The PlanSelectionPayload data.
         */
        setPlanSelection(draftState, { payload }: PayloadAction<PlanSelectionPayload>) {
            draftState.items = payload.planSelections;
            draftState.market = payload.market;
        }
    }
});

export const {
    loadPlanSelectionStart,
    loadPlanSelectionDone,
    loadPlanSelectionError,
    loadPlanSelection,
    setPlanSelection
} = planSelectionSlice.actions;

export default planSelectionSlice.reducer;
