import { concat, findIndex, remove } from 'lodash';

import { AsyncState } from 'Utilities/constants';
import { ApprovalUpdate, CapitalProject } from 'Utilities/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CapitalProjectState = {
    items: CapitalProject[];
    marketId?: number;
    loadingStatus?: AsyncState;
    createStatus?: AsyncState;
    deleteStatus?: AsyncState;
    approvalStatus?: AsyncState;
};

export type CapitalProjectPayload = {
    items: CapitalProject[];
    marketId: number;
};

const capitalProjectsSlice = createSlice({
    name: 'Capital Project',
    initialState: { items: [] } as CapitalProjectState,
    reducers: {
        /**
         * Placeholder for creating capital project action.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The payload action.
         */
        createCapitalProject(draftState, action: PayloadAction<CapitalProject>) {},
        /**
         * Updates the state when a new Capital Project is created.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The payload action.
         * @param action.payload - The payload of the reducer.
         */
        createdCapitalProject(draftState, { payload }: PayloadAction<CapitalProject>) {
            if (payload) {
                draftState.items = concat(draftState.items, payload);
            }
        },
        /**
         * Placeholder for deleting capital project action.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The payload action.
         */
        deleteCapitalProject(draftState, action: PayloadAction<number>) {},
        /**
         * Updates the state when a Capital Project is deleted.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The payload action.
         * @param action.payload - The payload of the reducer.
         */
        deletedCapitalProject(draftState, { payload }: PayloadAction<number>) {
            if (payload) {
                draftState.items = remove(draftState.items, (project) => project.id !== payload);
            }
        },
        /**
         * Placeholder for setting a capital project approval action.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The payload action.
         */
        setCapitalProjectApproval(draftState, action: PayloadAction<ApprovalUpdate>) {},

        /**
         * Occurs when the project approval has been set.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The payload action.
         * @param action.payload - The Capital Project result.
         */
        capitalProjectApprovalSet(draftState, { payload }: PayloadAction<CapitalProject>) {
            if (payload) {
                const index = findIndex(draftState.items, (project) => project.id === payload.id);
                draftState.items[index] = payload;
            }
        },

        /**
         * Updates the state with the list of the Capital Project list.
         *
         * @param draftState - The draft state of the current reducer.
         * @param action - The action that was passed into the reducer.
         * @param action.payload - The list of Capital Project objects.
         */
        setCapitalProjects(draftState, { payload }: PayloadAction<CapitalProjectPayload>) {
            draftState.items = payload.items;
            draftState.marketId = payload.marketId;
        },
        /**
         * Does not update the state, but the related action is use to kick off an epic that loads
         * the capital projects.
         *
         * @param draftState - The draft state.
         * @param action - The payload action.
         */
        loadCapitalProjects(draftState, action: PayloadAction<number>) {},
        /**
         * Set the capital projects loading status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadCapitalProjectStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },

        /**
         * Set the capital projects loading status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadCapitalProjectDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the capital projects loading status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        loadCapitalProjectError(draftState) {
            draftState.loadingStatus = AsyncState.FAILURE;
        },
        /**
         * Set the capital projects create status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        createCapitalProjectStart(draftState) {
            draftState.createStatus = AsyncState.PENDING;
        },

        /**
         * Set the capital projects create status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        createCapitalProjectDone(draftState) {
            draftState.createStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the capital projects create status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        createCapitalProjectError(draftState) {
            draftState.createStatus = AsyncState.FAILURE;
        },
        /**
         * Set the capital projects delete status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        deleteCapitalProjectStart(draftState) {
            draftState.createStatus = AsyncState.PENDING;
        },

        /**
         * Set the capital projects delete status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        deleteCapitalProjectDone(draftState) {
            draftState.createStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the capital projects delete status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        deleteCapitalProjectError(draftState) {
            draftState.createStatus = AsyncState.FAILURE;
        },
        /**
         * Set the capital projects approval status to PENDING.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        setCapitalProjectApprovalStart(draftState) {
            draftState.approvalStatus = AsyncState.PENDING;
        },

        /**
         * Set the capital projects approval status to SUCCESS.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        setCapitalProjectApprovalDone(draftState) {
            draftState.approvalStatus = AsyncState.SUCCESS;
        },

        /**
         * Set the capital projects approval status to FAILURE.
         *
         * @param draftState - The current capital projects slice of the application redux state.
         */
        setCapitalProjectApprovalError(draftState) {
            draftState.approvalStatus = AsyncState.FAILURE;
        }
    }
});

export const {
    loadCapitalProjects,
    loadCapitalProjectDone,
    loadCapitalProjectError,
    loadCapitalProjectStart,
    createCapitalProjectDone,
    createCapitalProjectError,
    createCapitalProjectStart,
    deleteCapitalProjectDone,
    deleteCapitalProjectError,
    deleteCapitalProjectStart,
    setCapitalProjects,
    createCapitalProject,
    createdCapitalProject,
    deleteCapitalProject,
    deletedCapitalProject,
    setCapitalProjectApproval,
    capitalProjectApprovalSet,
    setCapitalProjectApprovalDone,
    setCapitalProjectApprovalError,
    setCapitalProjectApprovalStart
} = capitalProjectsSlice.actions;

export default capitalProjectsSlice.reducer;
