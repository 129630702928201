import React, { useEffect, useState } from 'react';

import { difference, intersection, some } from 'lodash';
import styled from 'styled-components/macro';

import ModuleNavigation from 'Lib/Tabs/ModuleNavigation';
import { MarketDDFContent } from 'Modules/SlideData/DDFSlides';

interface facilityPaneProps {
    slides: MarketDDFContent[];
}

const defaultProps = {
    slide: []
};

const ContentPane = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const emptyPlaceholder = <div className="text-muted">No DD&amp;F slides available</div>;

/**
 * Compare all strings in an array and map each to its own distinct set of words.
 *
 * @param stringArray - An array of strings.
 * @returns The distinct set of words for each string.
 */
const getDistinctWords = (stringArray: string[]): string[] => {
    const tokenized = stringArray.map((item) => item.split(' '));
    const commonTokens = intersection(...tokenized);

    return tokenized
        .map((tokens) => difference(tokens, commonTokens))
        .map((tokens) => tokens.join(' '));
};

/**
 * Generate a label for facility tabs.  This uses the facility name and ID -- if there is none, we
 * assume that it's a market slide and use "Market" as the label.
 *
 * @param slide - The slide to generate a label for.
 * @returns The label to use for this facility.
 */
const generateFacilityLabel = (slide: MarketDDFContent) => {
    if (slide.facilityId) {
        return `${slide.facilityName || ''} (${slide.facilityId})`;
    }

    return 'Market';
};

function DDFFacilityReportPane({ slides }: facilityPaneProps) {
    const [activeTab, setActiveTab] = useState(0);
    useEffect(() => setActiveTab(0), [slides]);

    const changeTab = (tab: React.SetStateAction<number>) => {
        setActiveTab(tab);
    };

    // Determine if this is a collection of facility slides
    const isFacilities = some(slides, (slide) => !!slide.facilityId);

    // Sort the slides by facility ID, slide order, and then by title
    const sortedSlides = slides
        .sort((slide1, slide2) =>
            (slide1.titleShort || slide1.title).localeCompare(slide2.titleShort || slide2.title)
        )
        .sort((slide1, slide2) => slide2.slideOrder - slide1.slideOrder)
        .sort((a, b) => (a.facilityId || '').localeCompare(b.facilityId || ''));

    // Determine the navigation labels for the slides
    const titles = sortedSlides.map((slide) => slide.titleShort || slide.title);
    const labels = getDistinctWords(titles).map((label) => label.replace(/^\(|\)$/g, ''));
    const tabs = sortedSlides.map((item, index) => ({
        key: index,
        label: isFacilities ? generateFacilityLabel(item) : labels[index]
    }));

    const reportFrames = sortedSlides.map((item) => {
        return (
            <iframe
                key={item.concatenatedKey}
                className="embed-responsive-item"
                width="1140"
                src={
                    item.isGIS
                        ? `${process.env.REACT_APP_GIS_URL}/apps/webappviewer/index.html${encodeURI(
                              item.powerBIContent.url
                          )}`
                        : `${
                              process.env.REACT_APP_POWER_BI_URL
                          }/reportEmbed?autoAuth=true&${encodeURI(item.powerBIContent.url)}`
                }
                allowFullScreen={true}
            ></iframe>
        );
    });

    const navigation =
        reportFrames.length > 1 ? (
            <ModuleNavigation activeTab={activeTab} tabs={tabs} handleOnClick={changeTab} />
        ) : null;

    const content = reportFrames.length > 0 ? reportFrames[activeTab] : emptyPlaceholder;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '5px' }}>
            {navigation}
            <ContentPane className="embed-responsive embed-responsive-16by9">{content}</ContentPane>
        </div>
    );
}

DDFFacilityReportPane.defaultProps = defaultProps;

export default DDFFacilityReportPane;
