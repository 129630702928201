import { HSPAState } from 'storeTypes';

import { createSelector } from '@reduxjs/toolkit';

/**
 * Get the capital project type slice of state from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The capital project type slice of state.
 */
const selectCapitalProjectTypesState = (state: HSPAState) => state.capitalProjectTypes;

/**
 * Gets the capital project types from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns - The list of Capital Project Types.
 */
export const selectCapitalProjectTypes = createSelector(
    selectCapitalProjectTypesState,
    (capitalProjectTypesState) => capitalProjectTypesState?.items || []
);

/**
 * Get the capital project types loading status from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The loading status.
 */
export const selectCapitalProjectTypesLoadingStatus = createSelector(
    selectCapitalProjectTypesState,
    (capitalProjectTypesState) => capitalProjectTypesState?.loadingStatus
);
