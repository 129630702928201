/**
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */
import _map from 'lodash/map';
import { createSelector } from 'reselect';

const getProviders = (state) => state.ProvidersModel;
export const getProvidersSelector = createSelector(
    getProviders,
    (ProvidersModel) => ProvidersModel
);

const getAllCapacity = (state) => state.InHouseCapacityModel.allCapacity;
const getInHousePrimaryCare = (state) => state.InHouseCapacityModel.inHousePrimaryCare;
const getAmbulatoryCare = (state) => state.InHouseCapacityModel.ambulatoryCare;

const getFacilityList = (state) => state.VaFacilitiesModel.vaFacilities;
const getMarketDescriptionForm = (state) => state.MarketDescriptionModel;
const getProviderForm = (state) => state.ProviderFormModel.form;
const getFacilityTypes = (state) => state.ProviderFormModel.drpOptionObjects.facilityTypes;
const getProviderTypes = (state) => state.ProviderFormModel.drpOptionObjects.providerTypes;
const getFacilityDrpLists = (state) => state.ProviderFormModel.drpOptionObjects;

export const getAllCapacitySelector = createSelector(getAllCapacity, (allCapacity) => allCapacity);
export const getInHousePrimaryCareSelector = createSelector(
    getInHousePrimaryCare,
    (inHousePrimaryCare) => inHousePrimaryCare
);
export const getAmbulatoryCareSelector = createSelector(
    getAmbulatoryCare,
    (ambulatoryCare) => ambulatoryCare
);
export const getFacilityListSelector = createSelector(
    getFacilityList,
    (vaFacilities) => vaFacilities
);
export const getMarketDescriptionFormSelector = createSelector(
    getMarketDescriptionForm,
    (MarketDescriptionModel) => MarketDescriptionModel
);
export const getProviderFormSelector = createSelector(getProviderForm, (form) => form);
export const getFacilityTypesSelector = createSelector(
    getFacilityTypes,
    (facilityTypes) => facilityTypes
);
export const getProviderTypesSelector = createSelector(
    getProviderTypes,
    (providerTypes) => providerTypes
);
export const getDrpListsSelector = createSelector(
    getFacilityDrpLists,
    (drpOptionObjects) => drpOptionObjects
);
