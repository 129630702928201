/**
 * Master component to handle tab switching and proptypes.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */
import React from 'react';

import ModuleNavigation from '../../../../Lib/Tabs/ModuleNavigation';
import analytics from '../../../../Utilities/analytics';
import HeaderContainer from '../../../App/HeaderContainer';
import ESDemographicTables from '../ESDemographicTables';
import ESGeographyRurality from '../ESGeographyRurality';
import ESHealthCareEnvironment from '../ESHealthCareEnvironment';
import ESStakeholdersForm from '../ESStakeholdersForm';
import ESStrategicPlanningFoundations from '../ESStrategicPlanningFoundations';
import ESSurveyDashboard from '../ESSurveyDashboard';
import ESVisnMissionForm from '../ESVisnMissionForm';

export default class ESContainer extends React.Component {
    constructor(props) {
        super(props);
        document.title = 'HSPA - Environmental Scan';
        this.state = { activeTab: 0 };
    }
    componentWillMount() {
        let analyticsObj = analytics.getInstance();
        analyticsObj.trackPageChange({ pathName: 'EnvironmentalScan' });
        analyticsObj.trackTabChange('Strategic Planning Foundations');
    }

    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const tabs = [
            { key: 0, label: 'Strategic Planning Foundations' },
            { key: 1, label: 'VISN Mission, Vision, Values and Goals' },
            { key: 2, label: 'Stakeholders' },
            { key: 3, label: 'Geography and Rurality' },
            { key: 4, label: 'Demographic Data' },
            { key: 5, label: 'Health Care Environment' },
            { key: 6, label: 'Quality & Survey Data' }
        ];

        return (
            <div className="col-sm-12">
                <HeaderContainer pageName="Environmental Scan" />
                <ModuleNavigation
                    activeTab={this.state.activeTab}
                    tabs={tabs}
                    handleOnClick={this.changeTab}
                />
                <div>
                    {this.state.activeTab === 0 ? <ESStrategicPlanningFoundations /> : null}
                    {this.state.activeTab === 1 ? <ESVisnMissionForm /> : null}
                    {this.state.activeTab === 2 ? <ESStakeholdersForm /> : null}
                    {this.state.activeTab === 3 ? <ESGeographyRurality /> : null}
                    {this.state.activeTab === 4 ? <ESDemographicTables /> : null}
                    {this.state.activeTab === 5 ? <ESHealthCareEnvironment /> : null}
                    {this.state.activeTab === 6 ? <ESSurveyDashboard /> : null}
                </div>
            </div>
        );
    }
}
