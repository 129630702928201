import { getJSON } from 'Utilities/apiCalls';

if (typeof process.env.REACT_APP_HSPA_API === 'undefined') {
    throw new Error('REACT_APP_HSPA_API environment variable should be defined.');
}

const USER_API_URL = `${process.env.REACT_APP_HSPA_API}/User`;

type VISN = {
    VisnNumber: number;
    VisnCode: string;
};

type Market = {
    MarketName: string;
};

type UserProfile = {
    User: {
        defaultVisn: number;
    };
};

/**
 * Request data for all of the VISNs.
 *
 * @returns An array of data for all of the VISNs.
 */
export const getAllVISNs = async (): Promise<VISN[]> => {
    const visns = await getJSON(`${USER_API_URL}/GetAllVisns`);
    return visns as VISN[];
};

/**
 * Request data for all of the VA markets.
 *
 * @returns An array of data for all of the VA markets.
 */
export const getAllMarkets = async (): Promise<Market[]> => {
    const markets = await getJSON(`${USER_API_URL}/GetAllMarkets`);
    return markets as Market[];
};

/**
 * Request the current user's HSPA profile, including their default VISN.
 *
 * @returns The current user's HSPA profile information.
 */
export const getUserProfile = async (): Promise<UserProfile> => {
    const userProfile = await getJSON(`${USER_API_URL}/GetUserProfile`);
    return userProfile as UserProfile;
};

/**
 * Request the base year currently in use by HSPA.
 *
 * @returns The base year.
 */
export const getBaseYear = async (): Promise<number> => {
    const defaultYear = await getJSON(`${USER_API_URL}/GetDefaultYear`);
    return defaultYear as number;
};
