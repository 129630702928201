import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import Button from 'Lib/Button';
import PropTypes from 'prop-types';
import { commaFormatter } from 'Utilities/formatters';

const CustomActiveProductivityStandardCell = ({ val, row, column, table, update }) => {
    const {
        ProductivityStandardMinimum,
        ProductivityStandardMaximum,
        ProductivityStandard
    } = row.original;
    const editInput = useRef(null);
    const [value, setValue] = useState(val);
    const [editMode, setEditMode] = useState(false);

    const onChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    const onBlur = useCallback(() => {
        table.options.meta?.updateData(val, value, row, column);
        setEditMode(false);
    }, [val, value, row, column]);

    const onClick = () => {
        setEditMode(true);
    };

    useEffect(() => {
        setValue(val);
    }, [val]);

    useEffect(() => {
        editInput.current?.focus();
    });

    const button =
        val === ProductivityStandard ? null : (
            <Button
                color="warning"
                type="button"
                title="Reset to default"
                icon="undo"
                btnPaddingTop={1}
                btnPaddingBottom={1}
                size="btn-sm"
                style={{ position: 'absolute', left: -40, top: -1 }}
                onClick={(e) => {
                    e.stopPropagation();
                    update(
                        val,
                        ProductivityStandard,
                        {
                            ...row.original,
                            ActiveProductivityStandard: ProductivityStandard
                        },
                        column.columnDef
                    );
                }}
            />
        );

    return editMode ? (
        <Form.Control
            ref={editInput}
            as="input"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type="number"
            min={ProductivityStandardMinimum}
            max={ProductivityStandardMaximum}
        />
    ) : (
        <div style={button ? { display: 'inline-block', position: 'relative' } : {}}>
            <div
                onClick={onClick}
                onKeyPress={onClick}
                onFocus={onClick}
                role="button"
                tabIndex={row.index}
            >
                {commaFormatter(value)}
            </div>
            {button}
        </div>
    );
};

CustomActiveProductivityStandardCell.propTypes = {
    val: PropTypes.any.isRequired,
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired
};

export default CustomActiveProductivityStandardCell;
