/**
 * @file Match capacity and demand selector.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import { pickBy, sumBy } from 'lodash';

import { WorkloadType } from 'Utilities/constants';

import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

/**
 * Get the full redux state for the application.  This is passed into reselect selectors.
 *
 * @param {object} state - the redux state of the application
 * @returns {object} the state
 */
const getState = (state) => state;

export const getPlanObject = createSelector(getState, (state) => state.PlannerModel.Plan);

export const getPlanData = createSelector(getPlanObject, (Plan) => Plan.planData);

export const getHspaCostsData = createSelector(getPlanObject, (Plan) => Plan.planData.HspaCosts);

export const getPlanDemandData = createCachedSelector(
    getPlanData,
    (_state_, calculationType) => calculationType,
    (plan, calculationType) => {
        switch (calculationType) {
            case 'General':
                return plan.Demand;
            case 'Staffing':
                return plan.StaffingDemand;
            case 'Space':
                return plan.SpaceDemand;
            case 'Cost':
                return plan.Demand;
            default:
                return [];
        }
    }
)((_state_, calculationType) => calculationType);

export const getPlanCapacityData = createCachedSelector(
    getPlanData,
    (_state_, calculationType) => calculationType,
    (plan, calculationType) => {
        switch (calculationType) {
            case 'General':
                return plan.Capacity;
            case 'Staffing':
            case 'Space':
            case 'Cost':
            default:
                return null;
        }
    }
)((_state_, calculationType) => calculationType);

export const getPlanSupplyData = createCachedSelector(
    getPlanData,
    (_state_, calculationType) => calculationType,
    (plan, calculationType) => {
        switch (calculationType) {
            case 'General':
                return plan.Supply;
            case 'Staffing':
                return plan.StaffingSupply;
            case 'Space':
                return plan.SpaceSupply;
            case 'Cost':
                return plan.Supply;
            default:
                return [];
        }
    }
)((_state_, calculationType) => calculationType);

export const getPlanOverheadData = createSelector(
    getPlanObject,
    (Plan) => Plan.planData.OverheadRows
);

export const getDrilldownTableData = createCachedSelector(
    getPlanData,
    (_state_, filter) => filter,
    (planData, filter) => {
        if (Object.keys(planData).length === 0) {
            return [];
        }
        switch (filter) {
            case 'Demand': {
                return [...planData.Demand]
                    .filter(
                        (item) =>
                            item.Type === 'inhouse' ||
                            item.Type === 'purchased' ||
                            item.Type === 'panelized' ||
                            item.Type === 'non-panelized'
                    )
                    .map((item) => {
                        const { Type } = item;
                        if (Type === 'inhouse' || Type === 'Actuarial In-House Estimate') {
                            return {
                                ...item,
                                Name: 'Actuarial In-House Estimate',
                                tableType: 'Demand'
                            };
                        }
                        if (Type === 'purchased' || Type === 'Actuarial Fee Estimate') {
                            return {
                                ...item,
                                Name: 'Community Care Estimate',
                                tableType: 'Demand'
                            };
                        }
                        if (Type === 'panelized') {
                            return {
                                ...item,
                                Name: 'Estimated Panelized Enrollees',
                                tableType: 'Demand'
                            };
                        }
                        if (Type === 'non-panelized') {
                            return {
                                ...item,
                                Name: 'Estimated Non-Panelized Enrollees',
                                tableType: 'Demand'
                            };
                        }
                        return item;
                    });
            }
            case 'Available In-House Capacity':
                return [...planData.Capacity].map((item) => ({
                    ...item,
                    Name: item.Facility,
                    tableType: 'Capacity'
                }));
            case 'Federal Partners':
                return [
                    ...planData.Supply.filter(
                        (item) =>
                            item.Provider !== null &&
                            item.ProviderName !== null &&
                            (item.Provider.FacilityType === 'IHS' ||
                                item.Provider.FacilityType === 'FQHC' ||
                                item.Provider.FacilityType === 'DoD')
                    )
                ].map((item) => ({ ...item, Name: item.ProviderName, tableType: 'Supply' }));
            case 'Community Providers': {
                const communityProviders = [
                    ...planData.Supply.filter(
                        (item) =>
                            item.Provider !== null && item.Provider.FacilityType === 'Community'
                    )
                ].map((item) => ({ ...item, Name: item.ProviderName, tableType: 'Supply' }));
                const cpRowType = [...planData.Supply.filter((item) => item.RowType === 'cp')].map(
                    (item) => ({ ...item, Name: planData.Plan.SPCString, tableType: 'Supply' })
                );
                return [...communityProviders, ...cpRowType];
            }
            case 'Affiliates':
                return [
                    ...planData.Supply.filter(
                        (item) => item.Provider !== null && item.Provider.FacilityType === 'AA'
                    )
                ].map((item) => ({ ...item, Name: item.ProviderName, tableType: 'Supply' }));
            case 'Current Projected Utilization':
                return [...planData.Supply.filter((item) => item.RowType === 'inmarket')].map(
                    (item) => ({ ...item, Name: item.ProviderName, tableType: 'Supply' })
                );
            default:
                return [];
        }
    }
)((_state_, filter) => filter);

export const getDrilldownStaffingData = createCachedSelector(
    getPlanData,
    (_state_, filter) => filter,
    (planData, filter) => {
        let rows = [];
        if (Object.keys(planData).length === 0) {
            return [];
        }

        switch (filter) {
            case 'Demand': {
                rows = [...planData.StaffingDemand]
                    .filter(
                        (item) =>
                            item.Type === 'inhouse' ||
                            item.Type === 'purchased' ||
                            item.Type === 'panelized'
                    )
                    .map((item) => {
                        const { Type } = item;
                        if (
                            Type === 'inhouse' ||
                            Type === 'panelized' ||
                            Type === 'Actuarial In-House Estimate'
                        ) {
                            return {
                                ...item,
                                Name: `${item.StaffingType}: Actuarial In-House Estimate`,
                                tableType: 'Demand'
                            };
                        }
                        if (Type === 'purchased' || Type === 'Actuarial Fee Estimate') {
                            return {
                                ...item,
                                Name: `${item.StaffingType}: Community Care Estimate`,
                                tableType: 'Demand'
                            };
                        }
                        return item;
                    });
                break;
            }
            case 'Available In-House Capacity':
                rows = [...planData.StaffingCapacity].map((item) => ({
                    ...item,
                    Name: `${item.StaffingType}: ${item.Facility}`,
                    tableType: 'Capacity'
                }));
                break;
            case 'Federal Partners':
                rows = [
                    ...planData.StaffingSupply.filter(
                        (item) =>
                            item.Provider !== null &&
                            item.ProviderName !== null &&
                            (item.Provider.FacilityType === 'IHS' ||
                                item.Provider.FacilityType === 'FQHC' ||
                                item.Provider.FacilityType === 'DoD')
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.StaffingType}: ${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            case 'Community Providers': {
                const communityProviders = [
                    ...planData.StaffingSupply.filter(
                        (item) =>
                            item.Provider !== null && item.Provider.FacilityType === 'Community'
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.StaffingType}: ${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                const cpRowType = [
                    ...planData.StaffingSupply.filter((item) => item.RowType === 'cp')
                ].map((item) => ({
                    ...item,
                    Name: `${item.StaffingType}: ${planData.Plan.SPCString}`,
                    tableType: 'Supply'
                }));
                rows = [...communityProviders, ...cpRowType];
                break;
            }
            case 'Affiliates':
                rows = [
                    ...planData.StaffingSupply.filter(
                        (item) => item.Provider !== null && item.Provider.FacilityType === 'AA'
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.StaffingType}: ${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            case 'Current Projected Utilization':
                rows = [
                    ...planData.StaffingSupply.filter((item) => item.RowType === 'inmarket')
                ].map((item) => ({
                    ...item,
                    Name: `${item.StaffingType}: ${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            default:
                rows = [];
                break;
        }

        rows.forEach((row) => {
            row.Name = row.Name.replace(/^null: /, '');
        });

        return rows;
    }
)((_state_, filter) => filter);

export const getDrilldownSpaceData = createCachedSelector(
    getPlanData,
    (_state_, filter) => filter,
    (planData, filter) => {
        let rows = [];
        if (Object.keys(planData).length === 0) {
            return [];
        }

        switch (filter) {
            case 'Demand': {
                rows = [...planData.SpaceDemand]
                    .filter(
                        (item) =>
                            item.Type === 'inhouse' ||
                            item.Type === 'purchased' ||
                            item.Type === 'panelized'
                    )
                    .map((item) => {
                        const { Type } = item;
                        if (
                            Type === 'inhouse' ||
                            Type === 'panelized' ||
                            Type === 'Actuarial In-House Estimate'
                        ) {
                            return {
                                ...item,
                                Name: `Actuarial In-House Estimate`,
                                tableType: 'Demand'
                            };
                        }
                        if (Type === 'purchased' || Type === 'Actuarial Fee Estimate') {
                            return {
                                ...item,
                                Name: `Community Care Estimate`,
                                tableType: 'Demand'
                            };
                        }
                        return item;
                    });
                break;
            }
            case 'Available In-House Capacity':
                rows = [...planData.SpaceCapacity].map((item) => ({
                    ...item,
                    Name: `${item.Facility}`,
                    tableType: 'Capacity'
                }));
                break;
            case 'Federal Partners':
                rows = [
                    ...planData.SpaceSupply.filter(
                        (item) =>
                            item.Provider !== null &&
                            item.ProviderName !== null &&
                            (item.Provider.FacilityType === 'IHS' ||
                                item.Provider.FacilityType === 'FQHC' ||
                                item.Provider.FacilityType === 'DoD')
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            case 'Community Providers': {
                const communityProviders = [
                    ...planData.SpaceSupply.filter(
                        (item) =>
                            item.Provider !== null && item.Provider.FacilityType === 'Community'
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                const cpRowType = [
                    ...planData.SpaceSupply.filter((item) => item.RowType === 'cp')
                ].map((item) => ({
                    ...item,
                    Name: `${planData.Plan.SPCString}`,
                    tableType: 'Supply'
                }));
                rows = [...communityProviders, ...cpRowType];
                break;
            }
            case 'Affiliates':
                rows = [
                    ...planData.SpaceSupply.filter(
                        (item) => item.Provider !== null && item.Provider.FacilityType === 'AA'
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            case 'Current Projected Utilization':
                rows = [...planData.SpaceSupply.filter((item) => item.RowType === 'inmarket')].map(
                    (item) => ({
                        ...item,
                        Name: `${item.ProviderName}`,
                        tableType: 'Supply'
                    })
                );
                break;
            default:
                rows = [];
                break;
        }

        rows.forEach((row) => {
            row.Name = row.Name.replace(/^null: /, '');
        });

        return rows;
    }
)((_state_, filter) => filter);

export const getDrilldownCostData = createCachedSelector(
    getPlanData,
    (_state_, filter) => filter,
    (planData, filter) => {
        let rows = [];
        if (Object.keys(planData).length === 0) {
            return [];
        }

        switch (filter) {
            case 'Demand': {
                rows = [...planData.Demand]
                    .filter(
                        (item) =>
                            item.Type === 'inhouse' ||
                            item.Type === 'purchased' ||
                            item.Type === 'panelized'
                    )
                    .map((item) => {
                        const { Type } = item;
                        if (
                            Type === 'inhouse' ||
                            Type === 'panelized' ||
                            Type === 'Actuarial In-House Estimate'
                        ) {
                            return {
                                ...item,
                                Name: `Actuarial In-House Estimate`,
                                tableType: 'Demand'
                            };
                        }
                        if (Type === 'purchased' || Type === 'Actuarial Fee Estimate') {
                            return {
                                ...item,
                                Name: `Community Care Estimate`,
                                tableType: 'Demand'
                            };
                        }
                        return item;
                    });
                break;
            }
            case 'Available In-House Capacity':
                rows = [...planData.SpaceCapacity].map((item) => ({
                    ...item,
                    Name: `${item.Facility}`,
                    tableType: 'Capacity'
                }));
                break;
            case 'Federal Partners':
                rows = [
                    ...planData.Supply.filter(
                        (item) =>
                            item.Provider !== null &&
                            item.ProviderName !== null &&
                            (item.Provider.FacilityType === 'IHS' ||
                                item.Provider.FacilityType === 'FQHC' ||
                                item.Provider.FacilityType === 'DoD')
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            case 'Community Providers': {
                const communityProviders = [
                    ...planData.Supply.filter(
                        (item) =>
                            item.Provider !== null && item.Provider.FacilityType === 'Community'
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                const cpRowType = [...planData.Supply.filter((item) => item.RowType === 'cp')].map(
                    (item) => ({
                        ...item,
                        Name: `${planData.Plan.SPCString}`,
                        tableType: 'Supply'
                    })
                );
                // Get Facility Obj from HSPA Costs
                const marketObj = planData.HspaCosts.filter((item) => item.RowType == 'market');
                // Get the Facility items from the object
                const marketItems = pickBy(marketObj[0], (value, key) =>
                    key.includes('CommunityUC')
                );
                // Add all the cost values from all the facility items
                let totalMarketCosts = 0;
                for (const k in marketItems) {
                    totalMarketCosts += marketItems[k];
                }
                rows = [...communityProviders, ...cpRowType].map((item) => ({
                    ...item,
                    YearM1: Number(item.YearM1) * totalMarketCosts,
                    Year0: Number(item.Year0) * totalMarketCosts,
                    Year1: Number(item.Year1) * totalMarketCosts,
                    Year2: Number(item.Year2) * totalMarketCosts,
                    Year3: Number(item.Year3) * totalMarketCosts,
                    Year4: Number(item.Year4) * totalMarketCosts,
                    Year5: Number(item.Year5) * totalMarketCosts,
                    Year6: Number(item.Year6) * totalMarketCosts,
                    Year7: Number(item.Year7) * totalMarketCosts,
                    Year8: Number(item.Year8) * totalMarketCosts,
                    Year9: Number(item.Year9) * totalMarketCosts,
                    Year10: Number(item.Year10) * totalMarketCosts
                }));
                break;
            }
            case 'Affiliates':
                rows = [
                    ...planData.Supply.filter(
                        (item) => item.Provider !== null && item.Provider.FacilityType === 'AA'
                    )
                ].map((item) => ({
                    ...item,
                    Name: `${item.ProviderName}`,
                    tableType: 'Supply'
                }));
                break;
            case 'Current Projected Utilization':
                // Get Facility Obj from HSPA Costs
                const facilityArrObj = planData.HspaCosts.filter(
                    (item) => item.RowType == 'facility'
                );

                rows = [...planData.Supply.filter((item) => item.RowType === 'inmarket')].map(
                    (item) => {
                        // Find the HSPA Cost Object that matches the Station Number of the current item
                        let facilityData = facilityArrObj.filter((facility) =>
                            item.StationNumber.includes(facility.ParentStation)
                        );
                        // Group all the Facility values together
                        const facilityObj = pickBy(facilityData[0], (value, key) =>
                            key.includes('FacilityUC')
                        );
                        // Calculate the total multiplier value for the FacilityUC values
                        let totalFacilityCosts = 0;
                        for (const k in facilityObj) {
                            totalFacilityCosts += facilityObj[k];
                        }
                        return {
                            ...item,
                            YearM1: Number(item.YearM1) * totalFacilityCosts,
                            Year0: Number(item.Year0) * totalFacilityCosts,
                            Year1: Number(item.Year1) * totalFacilityCosts,
                            Year2: Number(item.Year2) * totalFacilityCosts,
                            Year3: Number(item.Year3) * totalFacilityCosts,
                            Year4: Number(item.Year4) * totalFacilityCosts,
                            Year5: Number(item.Year5) * totalFacilityCosts,
                            Year6: Number(item.Year6) * totalFacilityCosts,
                            Year7: Number(item.Year7) * totalFacilityCosts,
                            Year8: Number(item.Year8) * totalFacilityCosts,
                            Year9: Number(item.Year9) * totalFacilityCosts,
                            Year10: Number(item.Year10) * totalFacilityCosts,
                            Name: `${item.ProviderName}`,
                            tableType: 'Supply'
                        };
                    }
                );
                break;
            default:
                rows = [];
                break;
        }

        rows.forEach((row) => {
            row.Name = row.Name.replace(/^null: /, '');
        });

        return rows;
    }
)((_state_, filter) => filter);

export const getDemandOverheadData = createCachedSelector(
    getPlanDemandData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (demandData, overheadData, calculationType) => {
        if (!demandData || demandData.length === 0) {
            return [];
        }

        const upperDemand =
            demandData.find(
                (item) => item.Type === 'Upper Demand Estimate' || item.Type === 'upper'
            ) || {};
        const actuarialDemand = demandData.filter(
            (item) =>
                item.Type === 'purchased' ||
                item.Type === 'inhouse' ||
                item.Type === 'panelized' ||
                item.Type === 'non-panelized'
        );
        const lowerDemand =
            demandData.find(
                (item) => item.Type === 'Lower Demand Estimate' || item.Type === 'lower'
            ) || {};

        let upperOverhead =
            upperDemand === {}
                ? {}
                : overheadData.find((item) => item.RowName === 'Upper Demand Estimate') || {};
        let actuarialOverhead =
            overheadData.find((item) => item.RowName === 'Actuarial Demand Estimate') || {};
        let lowerOverhead =
            lowerDemand === {}
                ? {}
                : overheadData.find((item) => item.RowName === 'Lower Demand Estimate') || {};

        upperOverhead = {
            ...upperOverhead,
            YearM1: upperDemand.YearM1 || 0,
            Year0: upperDemand.Year0 || 0,
            Year1: upperDemand.Year1 || 0,
            Year2: upperDemand.Year2 || 0,
            Year3: upperDemand.Year3 || 0,
            Year4: upperDemand.Year4 || 0,
            Year5: upperDemand.Year5 || 0,
            Year6: upperDemand.Year6 || 0,
            Year7: upperDemand.Year7 || 0,
            Year8: upperDemand.Year8 || 0,
            Year9: upperDemand.Year9 || 0,
            Year10: upperDemand.Year10 || 0
        };

        actuarialOverhead = {
            ...actuarialOverhead,
            ...actuarialDemand.reduce(
                (a, b) => ({
                    YearM1: a.YearM1 + (b.YearM1 || 0),
                    Year0: a.Year0 + (b.Year0 || 0),
                    Year1: a.Year1 + (b.Year1 || 0),
                    Year2: a.Year2 + (b.Year2 || 0),
                    Year3: a.Year3 + (b.Year3 || 0),
                    Year4: a.Year4 + (b.Year4 || 0),
                    Year5: a.Year5 + (b.Year5 || 0),
                    Year6: a.Year6 + (b.Year6 || 0),
                    Year7: a.Year7 + (b.Year7 || 0),
                    Year8: a.Year8 + (b.Year8 || 0),
                    Year9: a.Year9 + (b.Year9 || 0),
                    Year10: a.Year10 + (b.Year10 || 0)
                }),
                actuarialOverhead
            )
        };

        lowerOverhead = {
            ...lowerOverhead,
            YearM1: lowerDemand.YearM1 || 0,
            Year0: lowerDemand.Year0 || 0,
            Year1: lowerDemand.Year1 || 0,
            Year2: lowerDemand.Year2 || 0,
            Year3: lowerDemand.Year3 || 0,
            Year4: lowerDemand.Year4 || 0,
            Year5: lowerDemand.Year5 || 0,
            Year6: lowerDemand.Year6 || 0,
            Year7: lowerDemand.Year7 || 0,
            Year8: lowerDemand.Year8 || 0,
            Year9: lowerDemand.Year9 || 0,
            Year10: lowerDemand.Year10 || 0
        };

        const data = [];
        if (
            Object.keys(upperDemand).length !== 0 /* &&
            !(
                upperOverhead.YearM1 === 0 &&
                upperOverhead.Year0 === actuarialOverhead.Year0 &&
                upperOverhead.Year1 === 0 &&
                upperOverhead.Year2 === 0 &&
                upperOverhead.Year3 === 0 &&
                upperOverhead.Year4 === 0 &&
                upperOverhead.Year5 === 0 &&
                upperOverhead.Year6 === 0 &&
                upperOverhead.Year7 === 0 &&
                upperOverhead.Year8 === 0 &&
                upperOverhead.Year9 === 0 &&
                upperOverhead.Year10 === 0
            ) */
        ) {
            data.push(upperOverhead);
        }
        data.push(actuarialOverhead);
        if (
            Object.keys(lowerDemand).length !== 0 /* &&
            !(
                lowerOverhead.YearM1 === 0 &&
                lowerOverhead.Year0 === actuarialOverhead.Year0 &&
                lowerOverhead.Year1 === 0 &&
                lowerOverhead.Year2 === 0 &&
                lowerOverhead.Year3 === 0 &&
                lowerOverhead.Year4 === 0 &&
                lowerOverhead.Year5 === 0 &&
                lowerOverhead.Year6 === 0 &&
                lowerOverhead.Year7 === 0 &&
                lowerOverhead.Year8 === 0 &&
                lowerOverhead.Year9 === 0 &&
                lowerOverhead.Year10 === 0
            ) */
        ) {
            data.push(lowerOverhead);
        }

        return data;
    }
)((_state_, calculationType) => calculationType);

export const getCapacityOverheadData = createCachedSelector(
    getPlanCapacityData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (capacityData, overheadData, calculationType) => {
        if (!capacityData || capacityData.length === 0) {
            return [];
        }
        let capacityOverhead =
            overheadData.find((item) => item.RowName === 'Available In-House Capacity') || {};

        capacityOverhead = {
            ...capacityOverhead,
            ...capacityData.reduce(
                (a, b) => ({
                    YearM1: a.YearM1 + (b.YearM1 || 0),
                    Year0: a.Year0 + (b.Year0 || 0),
                    Year1: a.Year1 + (b.Year1 || 0),
                    Year2: a.Year2 + (b.Year2 || 0),
                    Year3: a.Year3 + (b.Year3 || 0),
                    Year4: a.Year4 + (b.Year4 || 0),
                    Year5: a.Year5 + (b.Year5 || 0),
                    Year6: a.Year6 + (b.Year6 || 0),
                    Year7: a.Year7 + (b.Year7 || 0),
                    Year8: a.Year8 + (b.Year8 || 0),
                    Year9: a.Year9 + (b.Year9 || 0),
                    Year10: a.Year10 + (b.Year10 || 0)
                }),
                capacityOverhead
            )
        };
        return [capacityOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getInHouseUtilizationOverheadData = createCachedSelector(
    getPlanSupplyData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (supplyData, overheadData, calculationType) => {
        if (!supplyData || supplyData.length === 0) {
            return [];
        }

        let supplyOverhead =
            overheadData.find(
                (item) => item.RowName === 'VA Current and Projected In-House Utilization'
            ) || {};

        supplyOverhead = {
            ...supplyOverhead,
            ...supplyData.reduce(
                (a, b) =>
                    b.RowType === 'inmarket'
                        ? {
                              YearM1: a.YearM1 + (b.YearM1 || 0),
                              Year0: a.Year0 + (b.Year0 || 0),
                              Year1: a.Year1 + (b.Year1 || 0),
                              Year2: a.Year2 + (b.Year2 || 0),
                              Year3: a.Year3 + (b.Year3 || 0),
                              Year4: a.Year4 + (b.Year4 || 0),
                              Year5: a.Year5 + (b.Year5 || 0),
                              Year6: a.Year6 + (b.Year6 || 0),
                              Year7: a.Year7 + (b.Year7 || 0),
                              Year8: a.Year8 + (b.Year8 || 0),
                              Year9: a.Year9 + (b.Year9 || 0),
                              Year10: a.Year10 + (b.Year10 || 0)
                          }
                        : a,
                supplyOverhead
            )
        };

        return [supplyOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getCommunityProvidersOverheadData = createCachedSelector(
    getPlanSupplyData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (supplyData, overheadData, calculationType) => {
        if (
            !supplyData ||
            supplyData.length === 0 ||
            calculationType === 'Space' ||
            calculationType === 'Staffing'
        ) {
            return [];
        }

        let supplyOverhead =
            overheadData.find((item) => item.RowName === 'Community Providers') || {};

        supplyOverhead = {
            ...supplyOverhead,
            ...supplyData.reduce(
                (a, b) =>
                    (b.Provider !== null && b.Provider.FacilityType === 'Community') ||
                    b.RowType === 'cp'
                        ? {
                              YearM1: a.YearM1 + (b.YearM1 || 0),
                              Year0: a.Year0 + (b.Year0 || 0),
                              Year1: a.Year1 + (b.Year1 || 0),
                              Year2: a.Year2 + (b.Year2 || 0),
                              Year3: a.Year3 + (b.Year3 || 0),
                              Year4: a.Year4 + (b.Year4 || 0),
                              Year5: a.Year5 + (b.Year5 || 0),
                              Year6: a.Year6 + (b.Year6 || 0),
                              Year7: a.Year7 + (b.Year7 || 0),
                              Year8: a.Year8 + (b.Year8 || 0),
                              Year9: a.Year9 + (b.Year9 || 0),
                              Year10: a.Year10 + (b.Year10 || 0)
                          }
                        : a,
                supplyOverhead
            )
        };

        return [supplyOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getFederalPartnersOverheadData = createCachedSelector(
    getPlanSupplyData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (supplyData, overheadData, calculationType) => {
        if (
            !supplyData ||
            supplyData.length === 0 ||
            calculationType === 'Space' ||
            calculationType === 'Staffing'
        ) {
            return [];
        }

        let supplyOverhead = overheadData.find((item) => item.RowName === 'Federal Partners') || {};

        supplyOverhead = {
            ...supplyOverhead,
            ...supplyData.reduce(
                (a, b) =>
                    b.Provider !== null &&
                    b.ProviderName !== null &&
                    (b.Provider.FacilityType === 'IHS' ||
                        b.Provider.FacilityType === 'FQHC' ||
                        b.Provider.FacilityType === 'DoD')
                        ? {
                              YearM1: a.YearM1 + (b.YearM1 || 0),
                              Year0: a.Year0 + (b.Year0 || 0),
                              Year1: a.Year1 + (b.Year1 || 0),
                              Year2: a.Year2 + (b.Year2 || 0),
                              Year3: a.Year3 + (b.Year3 || 0),
                              Year4: a.Year4 + (b.Year4 || 0),
                              Year5: a.Year5 + (b.Year5 || 0),
                              Year6: a.Year6 + (b.Year6 || 0),
                              Year7: a.Year7 + (b.Year7 || 0),
                              Year8: a.Year8 + (b.Year8 || 0),
                              Year9: a.Year9 + (b.Year9 || 0),
                              Year10: a.Year10 + (b.Year10 || 0)
                          }
                        : a,
                supplyOverhead
            )
        };

        return [supplyOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getAffiliatesOverheadData = createCachedSelector(
    getPlanSupplyData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (supplyData, overheadData, calculationType) => {
        if (
            !supplyData ||
            supplyData.length === 0 ||
            calculationType === 'Space' ||
            calculationType === 'Staffing'
        ) {
            return [];
        }

        let supplyOverhead = overheadData.find((item) => item.RowName === 'Affiliates') || {};

        supplyOverhead = {
            ...supplyOverhead,
            ...supplyData.reduce(
                (a, b) =>
                    b.Provider !== null && b.Provider.FacilityType === 'AA'
                        ? {
                              YearM1: a.YearM1 + (b.YearM1 || 0),
                              Year0: a.Year0 + (b.Year0 || 0),
                              Year1: a.Year1 + (b.Year1 || 0),
                              Year2: a.Year2 + (b.Year2 || 0),
                              Year3: a.Year3 + (b.Year3 || 0),
                              Year4: a.Year4 + (b.Year4 || 0),
                              Year5: a.Year5 + (b.Year5 || 0),
                              Year6: a.Year6 + (b.Year6 || 0),
                              Year7: a.Year7 + (b.Year7 || 0),
                              Year8: a.Year8 + (b.Year8 || 0),
                              Year9: a.Year9 + (b.Year9 || 0),
                              Year10: a.Year10 + (b.Year10 || 0)
                          }
                        : a,
                supplyOverhead
            )
        };

        return [supplyOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getSupplyTotalOverheadData = createCachedSelector(
    getInHouseUtilizationOverheadData,
    getCommunityProvidersOverheadData,
    getFederalPartnersOverheadData,
    getAffiliatesOverheadData,
    getPlanOverheadData,
    (inHouseUtilizationData, cpData, fedPartnersData, affiliatesData, overheadData) => {
        if (!overheadData) {
            return [];
        }

        let totalOverhead = overheadData.find((item) => item.RowName === 'Total') || {};
        const supplyTotalRows = [
            ...inHouseUtilizationData,
            ...cpData,
            ...fedPartnersData,
            ...affiliatesData
        ];

        totalOverhead = {
            ...totalOverhead,
            ...supplyTotalRows.reduce(
                (a, b) => ({
                    YearM1: a.YearM1 + (b.YearM1 || 0),
                    Year0: a.Year0 + (b.Year0 || 0),
                    Year1: a.Year1 + (b.Year1 || 0),
                    Year2: a.Year2 + (b.Year2 || 0),
                    Year3: a.Year3 + (b.Year3 || 0),
                    Year4: a.Year4 + (b.Year4 || 0),
                    Year5: a.Year5 + (b.Year5 || 0),
                    Year6: a.Year6 + (b.Year6 || 0),
                    Year7: a.Year7 + (b.Year7 || 0),
                    Year8: a.Year8 + (b.Year8 || 0),
                    Year9: a.Year9 + (b.Year9 || 0),
                    Year10: a.Year10 + (b.Year10 || 0)
                }),
                totalOverhead
            )
        };
        return [totalOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getSupplyImbalanceOverheadData = createCachedSelector(
    getDemandOverheadData,
    getSupplyTotalOverheadData,
    getPlanOverheadData,
    (demandData, supplyTotalData, overheadData) => {
        if (!demandData || demandData.length === 0) {
            return [];
        }
        if (!supplyTotalData || supplyTotalData.length === 0) {
            return [];
        }

        const supplyTotal = supplyTotalData[0];
        const actuarialDemand =
            demandData.find((x) => x.RowName === 'Actuarial Demand Estimate') || {};
        let imbalanceOverhead = overheadData.find((item) => item.RowName === 'Imbalance') || {};

        imbalanceOverhead = {
            ...imbalanceOverhead,
            YearM1: (supplyTotal.YearM1 || 0) - (actuarialDemand.YearM1 || 0),
            Year0: (supplyTotal.Year0 || 0) - (actuarialDemand.Year0 || 0),
            Year1: (supplyTotal.Year1 || 0) - (actuarialDemand.Year1 || 0),
            Year2: (supplyTotal.Year2 || 0) - (actuarialDemand.Year2 || 0),
            Year3: (supplyTotal.Year3 || 0) - (actuarialDemand.Year3 || 0),
            Year4: (supplyTotal.Year4 || 0) - (actuarialDemand.Year4 || 0),
            Year5: (supplyTotal.Year5 || 0) - (actuarialDemand.Year5 || 0),
            Year6: (supplyTotal.Year6 || 0) - (actuarialDemand.Year6 || 0),
            Year7: (supplyTotal.Year7 || 0) - (actuarialDemand.Year7 || 0),
            Year8: (supplyTotal.Year8 || 0) - (actuarialDemand.Year8 || 0),
            Year9: (supplyTotal.Year9 || 0) - (actuarialDemand.Year9 || 0),
            Year10: (supplyTotal.Year10 || 0) - (actuarialDemand.Year10 || 0)
        };
        return [imbalanceOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getCapacitySupplyDifferenceOverheadData = createCachedSelector(
    getInHouseUtilizationOverheadData,
    getCapacityOverheadData,
    getPlanOverheadData,
    (inHouseUtilizationData, capacityTotalData, overheadData) => {
        if (!capacityTotalData || capacityTotalData.length === 0) {
            return [];
        }
        if (!inHouseUtilizationData || inHouseUtilizationData.length === 0) {
            return [];
        }

        const capacityTotal = capacityTotalData[0];
        const inHouseUtilization = inHouseUtilizationData[0];
        let capacitySupplyDiffOverhead =
            overheadData.find(
                (item) => item.RowName === 'Difference in In-House Capacity/Supply'
            ) || {};

        capacitySupplyDiffOverhead = {
            ...capacitySupplyDiffOverhead,
            YearM1: (inHouseUtilization.YearM1 || 0) - (capacityTotal.YearM1 || 0),
            Year0: (inHouseUtilization.Year0 || 0) - (capacityTotal.Year0 || 0),
            Year1: (inHouseUtilization.Year1 || 0) - (capacityTotal.Year1 || 0),
            Year2: (inHouseUtilization.Year2 || 0) - (capacityTotal.Year2 || 0),
            Year3: (inHouseUtilization.Year3 || 0) - (capacityTotal.Year3 || 0),
            Year4: (inHouseUtilization.Year4 || 0) - (capacityTotal.Year4 || 0),
            Year5: (inHouseUtilization.Year5 || 0) - (capacityTotal.Year5 || 0),
            Year6: (inHouseUtilization.Year6 || 0) - (capacityTotal.Year6 || 0),
            Year7: (inHouseUtilization.Year7 || 0) - (capacityTotal.Year7 || 0),
            Year8: (inHouseUtilization.Year8 || 0) - (capacityTotal.Year8 || 0),
            Year9: (inHouseUtilization.Year9 || 0) - (capacityTotal.Year9 || 0),
            Year10: (inHouseUtilization.Year10 || 0) - (capacityTotal.Year10 || 0)
        };
        return [capacitySupplyDiffOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getPercentageCapacityInHouseUtilizedOverheadData = createCachedSelector(
    getCapacitySupplyDifferenceOverheadData,
    getCapacityOverheadData,
    getPlanOverheadData,
    (differenceData, capacityTotalData, overheadData) => {
        if (!differenceData || differenceData.length === 0) {
            return [];
        }
        if (!capacityTotalData || capacityTotalData.length === 0) {
            return [];
        }

        const difference = differenceData[0];
        const capacityTotal = capacityTotalData[0];
        let percentage =
            overheadData.find(
                (item) => item.RowName === 'Percentage of In-House Capacity Utilized'
            ) || {};

        percentage = {
            ...percentage,
            YearM1: (1 + (difference.YearM1 || 0) / (capacityTotal.YearM1 || 0)) * 100,
            Year0: (1 + (difference.Year0 || 0) / (capacityTotal.Year0 || 0)) * 100,
            Year1: (1 + (difference.Year1 || 0) / (capacityTotal.Year1 || 0)) * 100,
            Year2: (1 + (difference.Year2 || 0) / (capacityTotal.Year2 || 0)) * 100,
            Year3: (1 + (difference.Year3 || 0) / (capacityTotal.Year3 || 0)) * 100,
            Year4: (1 + (difference.Year4 || 0) / (capacityTotal.Year4 || 0)) * 100,
            Year5: (1 + (difference.Year5 || 0) / (capacityTotal.Year5 || 0)) * 100,
            Year6: (1 + (difference.Year6 || 0) / (capacityTotal.Year6 || 0)) * 100,
            Year7: (1 + (difference.Year7 || 0) / (capacityTotal.Year7 || 0)) * 100,
            Year8: (1 + (difference.Year8 || 0) / (capacityTotal.Year8 || 0)) * 100,
            Year9: (1 + (difference.Year9 || 0) / (capacityTotal.Year9 || 0)) * 100,
            Year10: (1 + (difference.Year10 || 0) / (capacityTotal.Year10 || 0)) * 100
        };
        return [percentage];
    }
)((_state_, calculationType) => calculationType);

export const getSupplyOverheadData = createCachedSelector(
    getInHouseUtilizationOverheadData,
    getCommunityProvidersOverheadData,
    getFederalPartnersOverheadData,
    getAffiliatesOverheadData,
    getSupplyTotalOverheadData,
    getSupplyImbalanceOverheadData,
    (inHouseUtilizationData, cpData, fpData, affiliatesData, totalData, imbalanceData) => [
        ...inHouseUtilizationData,
        ...cpData,
        ...fpData,
        ...affiliatesData,
        ...totalData,
        ...imbalanceData
    ]
)((_state_, calculationType) => calculationType);

export const getInHouseUtilizationOverheadDataCost = createCachedSelector(
    getPlanData,
    getPlanSupplyData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (planData, supplyData, overheadData, calculationType) => {
        if (!supplyData || supplyData.length === 0) {
            return [];
        }

        // Get Facility Obj from HSPA Costs
        const facilityArrObj = planData.HspaCosts.filter((item) => item.RowType === 'facility');

        let supplyOverhead =
            overheadData.find(
                (item) => item.RowName === 'VA Current and Projected In-House Utilization'
            ) || {};

        // Create a new array with values calculated for cost amounts
        let inmarketData = supplyData
            .filter((item) => item.RowType === 'inmarket')
            .map((item) => {
                // Find the HSPA Cost Object that matches the Station Number of the current item
                let facilityData = facilityArrObj.filter((facility) =>
                    item.StationNumber.includes(facility.ParentStation)
                );
                // Group all the Facility values together
                const facilityObj = pickBy(facilityData[0], (value, key) =>
                    key.includes('FacilityUC')
                );
                // Calculate the total multiplier value for the FacilityUC values
                let totalFacilityCosts = 0;
                for (const k in facilityObj) {
                    totalFacilityCosts += facilityObj[k];
                }
                // return a new array with multiplies values
                return {
                    ...item,
                    YearM1: item.YearM1 * totalFacilityCosts,
                    Year0: item.Year0 * totalFacilityCosts,
                    Year1: item.Year1 * totalFacilityCosts,
                    Year2: item.Year2 * totalFacilityCosts,
                    Year3: item.Year3 * totalFacilityCosts,
                    Year4: item.Year4 * totalFacilityCosts,
                    Year5: item.Year5 * totalFacilityCosts,
                    Year6: item.Year6 * totalFacilityCosts,
                    Year7: item.Year7 * totalFacilityCosts,
                    Year8: item.Year8 * totalFacilityCosts,
                    Year9: item.Year9 * totalFacilityCosts,
                    Year10: item.Year10 * totalFacilityCosts
                };
            });

        supplyOverhead = {
            ...supplyOverhead,
            ...inmarketData.reduce(
                (a, b) =>
                    b.RowType === 'inmarket'
                        ? {
                              YearM1: a.YearM1 + (b.YearM1 || 0),
                              Year0: a.Year0 + (b.Year0 || 0),
                              Year1: a.Year1 + (b.Year1 || 0),
                              Year2: a.Year2 + (b.Year2 || 0),
                              Year3: a.Year3 + (b.Year3 || 0),
                              Year4: a.Year4 + (b.Year4 || 0),
                              Year5: a.Year5 + (b.Year5 || 0),
                              Year6: a.Year6 + (b.Year6 || 0),
                              Year7: a.Year7 + (b.Year7 || 0),
                              Year8: a.Year8 + (b.Year8 || 0),
                              Year9: a.Year9 + (b.Year9 || 0),
                              Year10: a.Year10 + (b.Year10 || 0)
                          }
                        : a,
                supplyOverhead
            )
        };
        return [supplyOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getCommunityProvidersOverheadDataCost = createCachedSelector(
    getPlanData,
    getPlanSupplyData,
    getPlanOverheadData,
    (_state_, calculationType) => calculationType,
    (planData, supplyData, overheadData, calculationType) => {
        if (!supplyData || supplyData.length === 0) {
            return [];
        }
        // Get Market Obj from HSPA Costs
        const marketObj = planData.HspaCosts.filter((item) => item.RowType === 'market');
        // Get the Market items from the object
        const marketItems = pickBy(marketObj[0], (value, key) => key.includes('CommunityUC'));
        // Add all the cost values from all the Market items
        let totalMarketCosts = 0;
        for (const k in marketItems) {
            totalMarketCosts += marketItems[k];
        }

        let supplyOverhead =
            overheadData.find((item) => item.RowName === 'Community Providers') || {};

        supplyOverhead = {
            ...supplyOverhead,
            ...supplyData.reduce(
                (a, b) =>
                    (b.Provider !== null && b.Provider.FacilityType === 'Community') ||
                    b.RowType === 'cp'
                        ? {
                              YearM1: a.YearM1 + (b.YearM1 || 0),
                              Year0: a.Year0 + (b.Year0 || 0),
                              Year1: a.Year1 + (b.Year1 || 0),
                              Year2: a.Year2 + (b.Year2 || 0),
                              Year3: a.Year3 + (b.Year3 || 0),
                              Year4: a.Year4 + (b.Year4 || 0),
                              Year5: a.Year5 + (b.Year5 || 0),
                              Year6: a.Year6 + (b.Year6 || 0),
                              Year7: a.Year7 + (b.Year7 || 0),
                              Year8: a.Year8 + (b.Year8 || 0),
                              Year9: a.Year9 + (b.Year9 || 0),
                              Year10: a.Year10 + (b.Year10 || 0)
                          }
                        : a,
                supplyOverhead
            )
        };

        return [
            {
                ...supplyOverhead,
                YearM1: supplyOverhead.YearM1 * totalMarketCosts,
                Year0: supplyOverhead.Year0 * totalMarketCosts,
                Year1: supplyOverhead.Year1 * totalMarketCosts,
                Year2: supplyOverhead.Year2 * totalMarketCosts,
                Year3: supplyOverhead.Year3 * totalMarketCosts,
                Year4: supplyOverhead.Year4 * totalMarketCosts,
                Year5: supplyOverhead.Year5 * totalMarketCosts,
                Year6: supplyOverhead.Year6 * totalMarketCosts,
                Year7: supplyOverhead.Year7 * totalMarketCosts,
                Year8: supplyOverhead.Year8 * totalMarketCosts,
                Year9: supplyOverhead.Year9 * totalMarketCosts,
                Year10: supplyOverhead.Year10 * totalMarketCosts
            }
        ];
    }
)((_state_, calculationType) => calculationType);
export const getSupplyTotalOverheadDataCost = createCachedSelector(
    getInHouseUtilizationOverheadDataCost,
    getCommunityProvidersOverheadDataCost,
    getPlanOverheadData,
    (inHouseUtilizationData, cpData, overheadData) => {
        if (!overheadData) {
            return [];
        }

        let totalOverhead = overheadData.find((item) => item.RowName === 'Total') || {};
        const supplyTotalRows = [...inHouseUtilizationData, ...cpData];

        totalOverhead = {
            ...totalOverhead,
            ...supplyTotalRows.reduce(
                (a, b) => ({
                    YearM1: a.YearM1 + (b.YearM1 || 0),
                    Year0: a.Year0 + (b.Year0 || 0),
                    Year1: a.Year1 + (b.Year1 || 0),
                    Year2: a.Year2 + (b.Year2 || 0),
                    Year3: a.Year3 + (b.Year3 || 0),
                    Year4: a.Year4 + (b.Year4 || 0),
                    Year5: a.Year5 + (b.Year5 || 0),
                    Year6: a.Year6 + (b.Year6 || 0),
                    Year7: a.Year7 + (b.Year7 || 0),
                    Year8: a.Year8 + (b.Year8 || 0),
                    Year9: a.Year9 + (b.Year9 || 0),
                    Year10: a.Year10 + (b.Year10 || 0)
                }),
                totalOverhead
            )
        };
        return [totalOverhead];
    }
)((_state_, calculationType) => calculationType);
export const getSupplyOverheadDataForCost = createCachedSelector(
    getInHouseUtilizationOverheadDataCost,
    getCommunityProvidersOverheadDataCost,
    getSupplyTotalOverheadDataCost,
    (inHouseUtilizationData, cpData, totalData) => [
        ...inHouseUtilizationData,
        ...cpData,
        ...totalData
    ]
)((_state_, calculationType) => calculationType);
export const getStaffingImbalanceOverheadData = createCachedSelector(
    getDemandOverheadData,
    getCapacityOverheadData,
    getPlanOverheadData,
    (demandData, capacityData, overheadData) => {
        if (!demandData || demandData.length === 0) {
            return [];
        }
        if (!capacityData || capacityData.length === 0) {
            return [];
        }

        const plannedFTEs = capacityData[0];
        const actuarialDemand =
            demandData.find((x) => x.RowName === 'Actuarial Demand Estimate') || {};
        let imbalanceOverhead = overheadData.find((item) => item.RowName === 'Imbalance') || {};

        imbalanceOverhead = {
            ...imbalanceOverhead,
            YearM1: (plannedFTEs.YearM1 || 0) - (actuarialDemand.YearM1 || 0),
            Year0: (plannedFTEs.Year0 || 0) - (actuarialDemand.Year0 || 0),
            Year1: (plannedFTEs.Year1 || 0) - (actuarialDemand.Year1 || 0),
            Year2: (plannedFTEs.Year2 || 0) - (actuarialDemand.Year2 || 0),
            Year3: (plannedFTEs.Year3 || 0) - (actuarialDemand.Year3 || 0),
            Year4: (plannedFTEs.Year4 || 0) - (actuarialDemand.Year4 || 0),
            Year5: (plannedFTEs.Year5 || 0) - (actuarialDemand.Year5 || 0),
            Year6: (plannedFTEs.Year6 || 0) - (actuarialDemand.Year6 || 0),
            Year7: (plannedFTEs.Year7 || 0) - (actuarialDemand.Year7 || 0),
            Year8: (plannedFTEs.Year8 || 0) - (actuarialDemand.Year8 || 0),
            Year9: (plannedFTEs.Year9 || 0) - (actuarialDemand.Year9 || 0),
            Year10: (plannedFTEs.Year10 || 0) - (actuarialDemand.Year10 || 0),
            RowType: 'Solutions'
        };
        return [imbalanceOverhead];
    }
)((_state_, calculationType) => calculationType);

export const getSolutionsOverheadData = createCachedSelector(
    getCapacitySupplyDifferenceOverheadData,
    getPercentageCapacityInHouseUtilizedOverheadData,
    getStaffingImbalanceOverheadData,
    (_state_, calculationType) => calculationType,
    (capSupDiffData, capInHouseUtilizationData, staffingImbalance, calculationType) => {
        if (calculationType === 'Staffing') {
            return staffingImbalance;
        }
        return [...capSupDiffData, ...capInHouseUtilizationData];
    }
)((_state_, calculationType) => calculationType);

export const getChartData = createCachedSelector(
    getDemandOverheadData,
    getCapacityOverheadData,
    getSupplyOverheadData,
    getPlanSupplyData,
    getSolutionsOverheadData,
    (state, calculationType) => calculationType,
    (demandData, capacityData, supplyData, facilitySupplyData, solutionData, calculationType) => {
        const supplyRows = [];
        if (
            calculationType === 'Staffing' &&
            facilitySupplyData?.[0] &&
            facilitySupplyData[0].HspcWorkloadType !== WorkloadType.ENROLLMENT
        ) {
            const types = ['MD', 'APP'];
            for (let typeIndex = 0; typeIndex < types.length; ++typeIndex) {
                const type = types[typeIndex];
                const typeSupply = {
                    RowName: `VA Current and Projected In-House Utilization (${type})`
                };
                const typeRows = facilitySupplyData.filter(
                    (row) => row.InHouseFacility && row.StaffingType === type
                );
                typeRows.forEach((facility) => {
                    const yearProperty = `YearM1`;
                    const yearValue = typeSupply[yearProperty] || 0;
                    typeSupply[yearProperty] = yearValue + facility[yearProperty];

                    for (let j = 0; j < 11; j++) {
                        const yearProperty = `Year${j}`;
                        const yearValue = typeSupply[yearProperty] || 0;
                        typeSupply[yearProperty] = yearValue + facility[yearProperty];
                    }
                });
                supplyRows.push(typeSupply);
            }
            supplyRows.push(...supplyData.slice(1));
        } else {
            supplyRows.push(...supplyData);
        }

        const overheadRows = [...demandData, ...capacityData, ...supplyRows, ...solutionData];
        const seriesData = [];

        for (let i = 0; i < overheadRows.length; i++) {
            let addToSeries = false;
            const objectData = {
                name: overheadRows[i].RowName,
                type: 'line',
                data: [],
                zIndex: 1
            };

            // TODO: Update Highcharts definitions below to set formatting strings instead
            const value = overheadRows[i][`YearM1`];
            objectData.data.push(value);
            for (let j = 1; j < 12; j++) {
                const value = overheadRows[i][`Year${j}`];
                objectData.data.push(value);
            }

            switch (overheadRows[i].RowName) {
                case 'Upper Demand Estimate':
                case 'Actuarial Demand Estimate':
                case 'Lower Demand Estimate':
                    addToSeries = true;
                    objectData.zIndex = 2;
                    break;
                case 'Available In-House Capacity':
                case 'Available In-House Capacity (APP)':
                case 'Available In-House Capacity (MD)':
                    addToSeries = true;
                    objectData.zIndex = 3;
                    objectData.dashStyle = 'Dash';
                    break;
                case 'VA Current and Projected In-House Utilization':
                case 'VA Current and Projected In-House Utilization (APP)':
                case 'VA Current and Projected In-House Utilization (MD)':
                    addToSeries = true;
                    objectData.type = 'column';
                    break;
                case 'Federal Partners':
                case 'VA Fee':
                case 'Community Providers':
                case 'Affiliates':
                    if (calculationType === 'General') {
                        addToSeries = true;
                    }
                    objectData.type = 'column';
                    break;
                default:
                    break;
            }
            if (addToSeries === true) {
                seriesData.push(objectData);
            }
        }
        return seriesData;
    }
)((_state_, calculationType) => calculationType);

export const getChartDataCost = createCachedSelector(
    getPlanData,
    getSupplyOverheadData,
    getPlanSupplyData,
    (state, calculationType) => calculationType,
    (planData, supplyData, facilitySupplyData, calculationType) => {
        if (!planData || planData.length === 0) {
            return [];
        }
        if (!supplyData || supplyData.length === 0) {
            return [];
        }
        if (!facilitySupplyData || facilitySupplyData.length === 0) {
            return [];
        }

        const overheadRows = [...supplyData];
        const seriesData = [];
        // Get Facility Obj from HSPA Costs
        const facilityArrObj = planData.HspaCosts.filter((item) => item.RowType === 'facility');
        // Get Market Obj from HSPA Costs
        const marketArrObj = planData.HspaCosts.filter((item) => item.RowType === 'market');

        const costData = overheadRows.filter(
            (item) =>
                item.RowName === 'VA Current and Projected In-House Utilization' ||
                item.RowName === 'Community Providers'
        );

        for (let i = 0; i < costData.length; i++) {
            const objectData = {
                name: costData[i].RowName,
                type: 'column',
                data: [],
                zIndex: 1
            };

            if (costData[i].RowName === 'VA Current and Projected In-House Utilization') {
                objectData.name = 'VA Projected In-House Costs';
                // Create a new array with values calculated for cost amounts
                const inmarketData = facilitySupplyData.filter(
                    (item) => item.RowType === 'inmarket'
                );
                for (let j = 6; j < 11; j++) {
                    let yearValue = 0;
                    // Loop through the inmarket data get total costs multiply by units
                    for (let x = 0; x < inmarketData.length; x++) {
                        // Find the HSPA Cost Object that matches the Station Number of the current item
                        const facilityData = facilityArrObj.filter((facility) =>
                            inmarketData[x].StationNumber.includes(facility.ParentStation)
                        );
                        // Group all the Facility values together
                        const facilityObj = pickBy(facilityData[0], (value, key) =>
                            key.includes('FacilityUC')
                        );
                        // Calculate the total multiplier value for the FacilityUC values
                        let totalFacilityCosts = 0;
                        for (const k in facilityObj) {
                            totalFacilityCosts += facilityObj[k];
                        }
                        yearValue += inmarketData[x][`Year${j}`] * totalFacilityCosts;
                    }
                    objectData.data.push(yearValue);
                }
            } else if (costData[i].RowName === 'Community Providers') {
                // Get the Market items from the object
                const marketItems = pickBy(marketArrObj[0], (value, key) =>
                    key.includes('CommunityUC')
                );
                // Add all the cost values from all the Market items
                let totalMarketCosts = 0;
                for (const k in marketItems) {
                    totalMarketCosts += marketItems[k];
                }
                for (let j = 6; j < 11; j++) {
                    const value = costData[i][`Year${j}`];
                    objectData.data.push(value * totalMarketCosts);
                }
            } else {
                for (let j = 6; j < 11; j++) {
                    const value = costData[i][`Year${j}`];
                    objectData.data.push(value);
                }
            }
            seriesData.push(objectData);
        }
        return seriesData;
    }
)((_state_, calculationType) => calculationType);

export const getDrilldownCostDataForFacility = createCachedSelector(
    getPlanData,
    getHspaCostsData,
    (_state_, filter) => filter,
    (_state_, filter, facility) => facility,
    (planData, hspaCostData, filter, facility) => {
        let rows = [];
        if (hspaCostData.length === 0) {
            return [];
        }

        switch (filter) {
            case 'Current Projected Utilization':
                let inMarketRows = [
                    ...planData.Supply.filter(
                        (item) =>
                            item.RowType === 'inmarket' &&
                            item.ProviderName === facility.ProviderName
                    )
                ];
                const facilityObj = hspaCostData.find((obj) => obj.RowType === 'facility');
                for (const k in facilityObj) {
                    if (k.includes('FacilityUC')) {
                        rows.push({
                            Id: facility.Id,
                            ProviderName: facility.ProviderName,
                            FacilityDataName: k.replace('FacilityUC', '').trim(),
                            YearM1: Number(inMarketRows[0].YearM1) * facilityObj[k],
                            Year0: Number(inMarketRows[0].Year0) * facilityObj[k],
                            Year5: Number(inMarketRows[0].Year5) * facilityObj[k],
                            Year6: Number(inMarketRows[0].Year6) * facilityObj[k],
                            Year7: Number(inMarketRows[0].Year7) * facilityObj[k],
                            Year8: Number(inMarketRows[0].Year8) * facilityObj[k],
                            Year9: Number(inMarketRows[0].Year9) * facilityObj[k],
                            Year10: Number(inMarketRows[0].Year10) * facilityObj[k]
                        });
                    }
                }
                break;
            case 'Community Providers':
                let cpRows = [
                    ...planData.Supply.filter((item) => item.ProviderName === facility.ProviderName)
                ];
                const marketObj = hspaCostData.find((obj) => obj.RowType === 'market');
                for (const k in marketObj) {
                    if (k.includes('CommunityUC')) {
                        rows.push({
                            Id: facility.Id,
                            ProviderName: facility.ProviderName,
                            FacilityDataName: k.replace('CommunityUC', '').trim(),
                            YearM1: Number(sumBy(cpRows, 'YearM1')) * marketObj[k],
                            Year0: Number(sumBy(cpRows, 'Year0')) * marketObj[k],
                            Year5: Number(sumBy(cpRows, 'Year5')) * marketObj[k],
                            Year6: Number(sumBy(cpRows, 'Year6')) * marketObj[k],
                            Year7: Number(sumBy(cpRows, 'Year7')) * marketObj[k],
                            Year8: Number(sumBy(cpRows, 'Year8')) * marketObj[k],
                            Year9: Number(sumBy(cpRows, 'Year9')) * marketObj[k],
                            Year10: Number(sumBy(cpRows, 'Year10')) * marketObj[k]
                        });
                    }
                }
                break;
            default:
                break;
        }
        return rows;
    }
)((_state_, facility) => facility);
