import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { deleteRequest, getJSON } from 'Utilities/apiCalls';

const understandDemandApi = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand/`;

export const WaitTimesModel = {
    state: {
        list: [],
        loading: false
    },
    reducers: {
        populate(state, data) {
            return {
                ...state,
                list: data
            };
        },
        updateLoading(state, isLoading) {
            return {
                ...state,
                loading: isLoading
            };
        },
        reset(state) {
            return {
                list: [],
                loading: false
            };
        }
    },
    effects: (dispatch) => ({
        async fetchWaitTimesAsync(payload, state) {
            try {
                this.reset();
                this.updateLoading(true);
                let result = await getJSON(
                    `${understandDemandApi}GetWaitTimes?market=${payload.market}`
                );
                this.populate(result);
                this.updateLoading(false);
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch wait times',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async deleteWaitTimesAsync(payload, state) {
            try {
                await deleteRequest(
                    `${understandDemandApi}DeleteWaitTimesReport?id=` +
                        payload.id +
                        '&marketName=' +
                        payload.market +
                        '&facilityName=' +
                        payload.facility
                );
                dispatch(
                    addNotification({
                        message:
                            'Wait Times associated with ' +
                            payload.facility +
                            ' have been deleted.',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchWorkloadAllocationMatrixAsync({
                    market: payload.market,
                    reportingType: '',
                    facility: ''
                });
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to delete associated wait times',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
