import React, { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import DebouncedFormControl from 'Lib/DebouncedFormControl/DebouncedFormControl';

import { Column } from '@tanstack/react-table';

export type FilterHeaderProps = {
    column: Column<unknown, unknown>;
};

const FilterHeader = ({ column }: FilterHeaderProps) => {
    const filterType = column.columnDef.meta?.filterType;

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = useMemo(
        () =>
            filterType === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys())
                      .sort()
                      .map((value) => ({ value, label: value })),
        [column.getFacetedUniqueValues()]
    );

    const selectStyles = {
        control: (base) => ({
            ...base,
            height: '34px',
            fontSize: '.875rem',
            fontWeight: 400,
            lineHeight: '1.5',
            borderRadius: '0.2rem',
            cursor: 'pointer'
        })
    };

    return filterType === 'number' ? (
        <Form.Row>
            <Col>
                <DebouncedFormControl
                    type="number"
                    size="sm"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={(value) =>
                        column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                    }
                    placeholder={`Min ${
                        column.getFacetedMinMaxValues()?.[0]
                            ? `(${column.getFacetedMinMaxValues()?.[0]})`
                            : ''
                    }`}
                />
            </Col>
            <Col>
                <DebouncedFormControl
                    type="number"
                    size="sm"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={(value) =>
                        column.setFilterValue((old: [number, number]) => [old?.[0], value])
                    }
                    placeholder={`Max ${
                        column.getFacetedMinMaxValues()?.[1]
                            ? `(${column.getFacetedMinMaxValues()?.[1]})`
                            : ''
                    }`}
                />
            </Col>
        </Form.Row>
    ) : (
        <Select
            id={`${column.id}-options`}
            className="mt-1 mb-1"
            styles={selectStyles}
            aria-label={column.id}
            options={sortedUniqueValues}
            placeholder={`Select ${column.columnDef.meta?.headerDisplayName}...`}
            isClearable
            onChange={(value) => column.setFilterValue(value ? value.value : null)}
        />
    );
};

export default FilterHeader;
