import React from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';

/**
 * Site Action -> Manage Users -> add or edit user tab.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import Button from 'Lib/Button';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import { isAdministrator } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import { reduxForm, reset } from 'redux-form';

class SAManageUserForm extends React.Component {
    static propTypes = {
        changeTab: PropTypes.func.isRequired
    };

    componentWillMount() {
        this.props.getAccessOptionsNewUser();
    }

    componentWillUnmount() {
        this.props.clearManageUserForm();
    }

    formSubmit = (values) => {
        if (this.props.formType === 'create') {
            const selectedGroup = values.RoleCode === 'guest' ? '' : values.RoleCode;
            delete values.RoleCode;
            this.props.saveNewUser(values, selectedGroup, this.props.user.currentVisn.VisnCode);
        } else {
            // var selectedGroup = values.UserGroups[this.props.groupId].Group.RoleCode;
            // delete values.UserGroups;
            this.props.updateUser(values, '', this.props.user.currentVisn.VisnCode);
        }
        this.props.clearManageUserForm();
        this.props.dispatch(reset('SAManageUserForm'));
        this.props.changeTab(0);
    };

    render() {
        return (
            <div>
                <div>
                    As a VISN Administrator, you can manage users in your network with the tabs on
                    the left.
                    <ul>
                        <li>
                            To{' '}
                            <u>
                                <b>add</b>
                            </u>{' '}
                            a User who has not been previously given access to HSPA, simply fill out
                            the form on the “Add/Edit Users” Tab and click the <b>Insert</b> button.
                            You will need to provide all information.
                        </li>
                        <li>
                            To{' '}
                            <u>
                                <b>modify</b>
                            </u>{' '}
                            a user simply click the <b>Edit</b> button on the “Manage Users” Tab and the form will be populated with the selected user's
                            information. Once you make your changes, click the <b>Update</b> button
                            and your changes will be saved.
                        </li>
                        <li>
                            To grant access to the VISN for an Existing User (i.e., a User who
                            already has access to HSPA in some manner), select the “User” from the
                            appropriate drop-down box at the bottom of the “Manage Users”
                            Tab and then the role for your particular VISN that you have selected
                            for that User from the “User Role”. Once you have made your selections,
                            click the <b>Save</b> button.
                        </li>
                    </ul>
                    <span style={{ fontSize: 'smaller' }}>
                        Note: The username must exactly match the user's email address for logging
                        in (i.e., jsmith@va.gov)
                    </span>
                </div>
                <br />
                <Form onSubmit={this.props.handleSubmit(this.formSubmit)}>
                    <TextboxField
                        name="Username"
                        label="VA Email Address"
                        placeholder="Enter VA email address..."
                        disabled={this.props.saving}
                        labelPosition={2}
                        inputPosition={10}
                    />
                    {/* <TextboxField
                        name="DefaultVisn"
                        label="VISN"
                        placeholder="Enter VISN number..."
                        disabled={true}
                        labelPosition={2}
                        inputPosition={10}/> */}
                    <DropdownField
                        name="DefaultVisn"
                        label="VISN"
                        options={this.props.visnList.list}
                        placeholder="Select user VISN..."
                        displayField="VisnNumber"
                        valueField="VisnNumber"
                        disabled={this.props.saving || !this.props.isAdmin}
                        loading={this.props.visnList.loading}
                        labelPosition={2}
                        inputPosition={10}
                    />
                    {this.props.formType === 'create' ? (
                        <DropdownField
                            name={
                                this.props.formType === 'create'
                                    ? 'RoleCode'
                                    : `UserGroups[${this.props.groupId}].Group.RoleCode`
                            }
                            label="User Group"
                            options={this.props.accessOptions.list}
                            placeholder="Select group..."
                            displayField="DisplayText"
                            valueField="RoleName"
                            disabled={this.props.saving === true}
                            loading={this.props.accessOptions.loading}
                            labelPosition={2}
                            inputPosition={10}
                        />
                    ) : null}
                    <TextboxField
                        name="FirstName"
                        label="First Name"
                        placeholder="Enter first name..."
                        disabled={this.props.saving}
                        labelPosition={2}
                        inputPosition={10}
                    />
                    <TextboxField
                        name="LastName"
                        label="Last Name"
                        placeholder="Enter last name..."
                        disabled={this.props.saving}
                        labelPosition={2}
                        inputPosition={10}
                    />
                    <TextboxField
                        name="UserPhone"
                        label="Phone"
                        placeholder="Enter Phone... Ex: 555-555-5551x123"
                        disabled={this.props.saving}
                        labelPosition={2}
                        inputPosition={10}
                    />
                    <FormGroup style={{ paddingTop: '10px' }}>
                        <Col style={{ textAlign: 'center' }}>
                            <Button
                                color="success"
                                type="submit"
                                label={
                                    this.props.formType === 'create' && this.props.saving
                                        ? 'Saving...'
                                        : this.props.formType === 'create'
                                        ? 'Save new user'
                                        : this.props.formType !== 'create' && this.props.saving
                                        ? 'Updating...'
                                        : 'Update user'
                                }
                                disabled={this.props.saving}
                            />
                            &nbsp;&nbsp;
                            <Button
                                color="warning"
                                type="button"
                                label="Cancel"
                                disabled={this.props.pristine || this.props.saving}
                                onClick={this.props.reset}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

SAManageUserForm = reduxForm({
    form: 'SAManageUserForm',
    enableReinitialize: true,
    validate(values, props) {
        const errors = {};
        if (!values.Username) {
            errors.Username = 'VA email address is required.';
        } else if (
            !/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
                values.Username
            )
        ) {
            errors.Username = 'VA email address not in correct format...Ex: name@va.gov';
        }
        if (!values.FirstName) {
            errors.FirstName = 'First name is required';
        }
        if (!values.LastName) {
            errors.LastName = 'Last name is required';
        }
        if (
            !/^[01]?[- .]?(\([2-9]\d{2}\)|[2-9]\d{2})[- .]?\d{3}[- .]?\d{4}[x]?(\d{3,5})?$/.test(
                values.UserPhone
            )
        ) {
            errors.UserPhone = 'Phone is not in US format';
        }
        return errors;
    }
})(SAManageUserForm);

const mapDispatchToProps = (dispatch) => {
    return {
        saveNewUser: (formValues, selectedGroup, visnCode) =>
            dispatch.ManageUserModel.saveNewUserAsync({
                formValues,
                selectedGroup,
                visnCode
            }),
        updateUser: (formValues, selectedGroup, visnCode) =>
            dispatch.ManageUserModel.updateUserAsync({
                formValues,
                selectedGroup,
                visnCode
            }),
        getAccessOptionsNewUser: () => dispatch.ManageUserModel.fetchNewUserAccessOptionsAsync(),
        clearManageUserForm: () => dispatch.ManageUserModel.resetTable('formData')
    };
};

const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ManageUserModel.formData) ? 'create' : 'edit',
    groupId: _findIndex(state.ManageUserModel.formData.UserGroups, (o) => {
        return (
            o.Group.VisnId === state.UserSessionModel.currentVisn.VisnNumber &&
            o.Group.RoleCode !== 'hcpm_administrator'
        );
    }),
    initialValues: _isEmpty(state.ManageUserModel.formData)
        ? { DefaultVisn: state.UserSessionModel.currentVisn.VisnNumber }
        : state.ManageUserModel.formData || {},
    accessOptions: state.ManageUserModel.accessOptionsNewUser || {},
    user: state.UserSessionModel,
    saving: state.ManageUserModel.saving,
    visn: state.UserSessionModel.currentVisn.VisnNumber,
    visnList: state.UserSessionModel.visns,
    isAdmin: isAdministrator(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SAManageUserForm);
