/**
 * Master component to handle tabs.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */
import React from 'react';

import DDFContainer from 'Lib/DDFSlides/DDFContainer';

import SubTabComponent from '../../../../Lib/Tabs/SubTabComponent';
import ESDistanceEligible from './ESDistanceEligible';
import ESDynamicMapping from './ESDynamicMapping';
import ESRurality from './ESRurality';
import ESSavedMaps from './ESSavedMaps';

export default class ESGeographyRurality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1
        };
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const tabs = [
            //{ key: 0, label: 'Saved Maps' },
            { key: 1, label: 'Dynamic Mapping' },
            { key: 2, label: 'Rurality Data' }
            // { key: 3, label: "40-Mile Veterans" }
        ];

        return (
            <div>
                <div>
                    <DDFContainer
                        text={
                            'There is relevant data published in the Data Discovery and Findings.'
                        }
                        reports={[
                            {
                                title: 'Market Overview',
                                slideNumbers: [9]
                            }
                        ]}
                    ></DDFContainer>
                </div>
                <div>
                    <div style={{ paddingTop: 15 }}>
                        <SubTabComponent
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            handleOnClick={this.changeTab}
                        />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        {this.state.activeTab === 0 ? (
                            <ESSavedMaps changeTab={this.changeTab} />
                        ) : null}
                        {this.state.activeTab === 1 ? <ESDynamicMapping /> : null}
                        {this.state.activeTab === 2 ? <ESRurality /> : null}
                        {this.state.activeTab === 3 ? <ESDistanceEligible /> : null}
                    </div>
                </div>
            </div>
        );
    }
}
