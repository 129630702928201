import './style.css';

import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Cookies, withCookies } from 'react-cookie';

import VAApplicationOverlay from '../../VAApplicationOverlay';

const systemDescription =
    'This U.S. government system is intended to be used by [authorized VA network users] for ' +
    'viewing and retrieving information only, except as otherwise explicitly authorized. VA ' +
    'information resides on and transmits through computer systems and networks funded by VA. ' +
    'All use is considered to be with an understanding and acceptance that there is no ' +
    'reasonable expectation of privacy for any data or transmissions on Government Intranet or ' +
    'Extranet (non-public) networks or systems. All transactions that occur on this system are ' +
    'subject to review and action including (but not limited to) monitoring, recording, ' +
    'retrieving, copying, auditing, inspecting, investigating, restricting access, blocking, ' +
    'tracking, disclosing to authorized personnel, or any other authorized actions by all ' +
    'authorized VA and law enforcement personnel. All use of this system constitutes ' +
    'understanding and unconditional acceptance of these terms.';

const warningDescription =
    'Unauthorized attempts or acts to either (1) access, upload, change, or delete information ' +
    'on this system, (2) modify this system, (3) deny access to this system, or (4) accrue ' +
    'resources for unauthorized use on this system are strictly prohibited. Such attempts or ' +
    'acts are subject to action that may result in criminal, civil, or administrative penalties.';

/**
 * @typedef GovernmentOverlayProps
 * @property {Function} showApp - callback made when showing the app
 * ...etc
 */
interface GovernmentOverlayProps {
    showApp: () => void;
    handleWarningDate: () => void;
    showPage: boolean;
    warningLastShown: Date;
    cookies: Cookies;
}

interface GovernmentOverlayState {
    warningLastShown: Date;
    showPage: boolean;
}

/**
 * Generate the Government Warning Page
 */
class GovernmentSystemWarning extends React.Component<
    GovernmentOverlayProps,
    GovernmentOverlayState
> {
    /**
     * @param {GovernmentOverlayProps} props - props passed into this component
     */
    constructor(props: GovernmentOverlayProps) {
        super(props);

        const { cookies } = props;
        this.state = {
            warningLastShown: new Date(cookies.get<string>('warningLastShown')),
            showPage: true
        };
    }

    /**
     * Function that sets the state to no longer show the government warning page.
     */
    showApp() {
        this.setState(() => ({
            showPage: false
        }));
        this.handleWarningDate();
    }

    /**
     * Function that handles the date the warning was last shown in cookies
     */
    handleWarningDate() {
        const { cookies } = this.props;
        const newDate = new Date();
        cookies.set('warningLastShown', newDate, { path: '/' });
        this.setState(() => ({
            warningLastShown: newDate
        }));
    }

    /**
     * Render the government overlay component.
     *
     * @returns {JSX.Element} the rendered component
     */
    render() {
        const { warningLastShown, showPage } = this.state;

        const currentDate = new Date().getTime();
        const lastDate = Number.isNaN(warningLastShown.valueOf()) ? 0 : warningLastShown.valueOf();
        const dateDifference = currentDate - lastDate;
        const hourLimit = 24;
        const timeValue = hourLimit * 60 * 60 * 1000;

        return (
            <VAApplicationOverlay fadePage={showPage && dateDifference > timeValue}>
                <Container fluid>
                    <div className="content">
                        <p>&nbsp;</p>
                        <Row className="master-legal-warning-row">
                            <Col md="2">
                                <p className="master-warning">WARNING: </p>
                            </Col>
                            <Col id="warning" md="10">
                                <p className="master-warning-body">{systemDescription}</p>
                                <p className="master-warning-body">{warningDescription}</p>
                            </Col>
                        </Row>
                        <p style={{ textAlign: 'center' }}>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{ paddingTop: 6, paddingBottom: 6 }}
                                onClick={() => this.showApp()}
                            >
                                Continue
                            </button>
                        </p>
                        <p>&nbsp;</p>
                    </div>
                </Container>
            </VAApplicationOverlay>
        );
    }
}

export default withCookies(GovernmentSystemWarning);
