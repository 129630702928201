import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _ from 'lodash';

const getApplicationAnalytics = state => state.AdministrationModel.applicationAnalytics

export const getGraphDataSelector       = createSelector(getApplicationAnalytics, (applicationAnalytics) => {
    return _(applicationAnalytics.list)
        .groupBy(x => x.VISN)
        .map((objs, key) => (
            {
                name: (key > 9) ? "V"+key : "V0"+key,
                drilldown: key,
                y: objs.length
            }))
        .value()
})
export const getGraphDrilldownSelector  = createSelector(getApplicationAnalytics, (applicationAnalytics) => {
    return _map(
        _groupBy(applicationAnalytics.list, "VISN"),
        (groupedList, key) => {
            return {
                name: (key > 9) ? "V"+key : "V0"+key,
                id: key,
                data:
                    _map(
                        _groupBy(
                            _filter(groupedList, (item) => { return item.VISN === Number(key) }),
                            "Page"
                        ),
                        (item) => { return { name: item[0].Page, y: item.length, visn: (item[0].VISN > 9) ? "V"+item[0].VISN : "V0"+item[0].VISN } }
                    )
            }
        }
    )
})

