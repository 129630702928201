import { commaNAFormatter } from 'Utilities/formatters';

const WorkloadAllocationMatrixTableColumnDefs = (list) => {
    const columnList = [];
    columnList.push({
        accessorKey: 'facility',
        header: 'Facility',
        meta: {
            columnClassName: 'text-left',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-left'
        }
    });
    if (list.length > 0) {
        for (const property in list[0]) {
            if (property !== 'facility' && property !== 'type') {
                columnList.push({
                    accessorKey: property,
                    header: property,
                    meta: {
                        columnClassName: 'text-right',
                        columnStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
                        cellClassName: 'text-right',
                        formatter: commaNAFormatter
                    }
                });
            }
        }
    }
    return columnList;
};

export default WorkloadAllocationMatrixTableColumnDefs;
