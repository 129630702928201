import { HSPAState } from 'storeTypes';

import { createSelector } from '@reduxjs/toolkit';

/**
 * Get the facility selection slice of state from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The facility selection slice of state.
 */
const selectFacilitySelectionState = (state: HSPAState) => state.facilitySelection;

/**
 * Gets the facility selections.
 *
 * @param state - The HSPA redux state.
 * @returns - The list of facility selections.
 */
export const selectFacilitySelection = createSelector(
    selectFacilitySelectionState,
    (facilitySelectionState) => facilitySelectionState?.items || []
);

/**
 * Get the facility selection loading status from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The loading status.
 */
export const selectFacilitySelectionLoadingStatus = createSelector(
    selectFacilitySelectionState,
    (facilitySelectionState) => facilitySelectionState?.loadingStatus
);

/**
 * Get the facility selection market from the HSPA state.
 *
 * @param state - The HSPA redux state.
 * @returns The market.
 */
export const selectFacilitySelectionMarket = createSelector(
    selectFacilitySelectionState,
    (facilitySelectionState) => facilitySelectionState?.market
);
