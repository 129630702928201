/**
 * Constants for the HSPA application.
 */
const BuildEnvironment = {
    DEV_LOCAL: 'DEV_LOCAL',
    DEV: 'DEV',
    STAGING: 'TEST',
    PROD: 'PROD'
};

export const ADMINISTRATOR_ROLE = 'hcpm_administrator';
export const VISN_PLANNER_ROLE = 'hcpm_visn_planner';
export const CAM_ROLE = 'hspa_cam';
export const FACILITY_PLANNER = 'hcpm_facility_planner';

export const SURVEY_OF_ENROLLEES_YEAR = '2019';

export enum AsyncState {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}

export const WorkloadType = {
    UTILIZATION: 0,
    RVU: 1,
    ENROLLMENT: 2
};

export const WorkloadTypeText = {
    [WorkloadType.UTILIZATION]: 'Utilization',
    [WorkloadType.RVU]: 'RVU',
    [WorkloadType.ENROLLMENT]: 'Enrollment'
};

export const REACT_APP_APPLICATION_ROOT_ROUTE = (() => {
    switch (process.env.REACT_APP_SERVER) {
        case BuildEnvironment.STAGING:
            return 'HSPA_Test';
        default:
            return 'HSPA';
    }
})();

export const USE_DEMO_OPPORTUNITIES = false;
