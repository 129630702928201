import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _map from 'lodash/map';

import Button from 'Lib/Button';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import {
    getDrilldownCostData,
    getDrilldownSpaceData,
    getDrilldownStaffingData,
    getDrilldownTableData
} from 'Modules/MatchCapacityDemand/Services/selector';
import PropTypes from 'prop-types';
import {
    commaCurrencyFormatter,
    commaDecimalFormatter,
    commaFormatter
} from 'Utilities/formatters';

import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import CapitalProjectOverlay from './CapitalProjectOverlay';
import GeneratePlannerWorkbenchDrilldownColumns from './MCDPlannerWorkbenchDrilldownColumns';
import MCDSupplyDrilldown from './MCDSupplyDrilldown';

/**
 * Expand component for planner tables.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
const MCDPlannerWorkbenchDrilldown = React.memo(
    ({ calculationType, canEdit, filter, updateWorkspaceData, isEditable, tableType }) => {
        const dispatch = useDispatch();
        const tableData = useSelector((state) => {
            switch (calculationType) {
                case 'General':
                    return getDrilldownTableData(state, filter);
                case 'Staffing':
                    return getDrilldownStaffingData(state, filter);
                case 'Space':
                    return getDrilldownSpaceData(state, filter);
                case 'Cost':
                    return getDrilldownCostData(state, filter);
                default:
                    return [];
            }
        });
        const numDecimalPlaces =
            calculationType === 'Staffing' || calculationType === 'Cost' ? 2 : 0;
        const formattedData = _map(tableData, (row) => {
            const updatedRow = { ...row };
            for (let i = 0; i <= 10; i++) {
                const fieldName = `Year${i}`;
                updatedRow[fieldName] = updatedRow[fieldName].toFixed(numDecimalPlaces);
            }
            return updatedRow;
        });
        const toggleJustificationModal = useCallback(
            (planItem) => dispatch.PlannerModel.toggleJustificationModal({ planItem }),
            [dispatch]
        );

        const resetPlanRow = useCallback(
            (planId, rowId, rowType, objectName) =>
                dispatch.PlannerModel.resetPlanRowAsync({
                    planId,
                    rowId,
                    rowType,
                    objectName
                }),
            [dispatch]
        );

        const justificationBtn = (cell, row) => {
            if (calculationType !== 'General' || tableType === 'Demand') {
                return null;
            }
            return (
                <a data-toggle="tooltip" data-placement="top" title="Add Justification">
                    <Button
                        color="success"
                        type="button"
                        label=""
                        title="Edit Justification"
                        disabled={!isEditable || !canEdit}
                        icon="edit"
                        btnPaddingTop={1}
                        btnPaddingBottom={1}
                        size="btn-sm"
                        onClick={() => toggleJustificationModal(row)}
                    />
                </a>
            );
        };

        const resetRow = (cell, row) => {
            if (calculationType !== 'General' || tableType === 'Demand') {
                return null;
            }

            const tableObj = calculationType === 'Staffing' ? `Staffing${tableType}` : tableType;

            return (
                <a data-toggle="tooltip" data-placement="top" title="Reset Row">
                    <Button
                        color="warning"
                        type="button"
                        label=""
                        title="Reset Row"
                        disabled={!isEditable || !canEdit}
                        icon="undo"
                        btnPaddingTop={1}
                        btnPaddingBottom={1}
                        size="btn-sm"
                        onClick={() => {
                            resetPlanRow(
                                row.PlanId,
                                row.Id,
                                tableObj,
                                tableType === 'Capacity' ? row.Facility : row.ProviderName
                            );
                        }}
                    />
                </a>
            );
        };

        const dataFormatter = useCallback(
            (val) => {
                switch (calculationType) {
                    case 'Staffing':
                        return commaDecimalFormatter(val);
                    case 'Cost':
                        return commaCurrencyFormatter(val);
                    default:
                        return commaFormatter(val);
                }
            },
            [calculationType]
        );

        const expandComponent = (row) => (
            <MCDSupplyDrilldown
                calculationType={calculationType}
                canEdit={canEdit}
                filter={filter}
                facility={row}
                isEditable
                updateWorkspaceData={updateWorkspaceData}
                tableType="Supply"
            />
        );

        const cellEditOptions = {
            afterSaveCell: (_oldValue, newValue, row, column) => {
                const tableObj =
                    calculationType === 'Staffing' ? `Staffing${tableType}` : tableType;
                updateWorkspaceData(tableObj, row.Id, column.id, newValue, filter);
            }
        };

        const enableEditing = calculationType === 'General' && isEditable && canEdit;

        const popOverFormatter = (cell, row) => {
            if (!row.HasCapitalProject) {
                return cell || null;
            }

            return (
                <CapitalProjectOverlay
                    capitalProjects={row.CapitalProjects}
                    planCapacityId={row.Id}
                />
            );
        };

        const sortFunc = (rowA, rowB) => (rowA.StationNumber > rowB.StationNumber ? 1 : -1);

        const columns = GeneratePlannerWorkbenchDrilldownColumns(
            calculationType,
            popOverFormatter,
            sortFunc,
            dataFormatter,
            justificationBtn,
            resetRow,
            enableEditing
        );

        const defaultVisibility = { Id: false };

        const defaultSorted = [{ id: 'Id', desc: false }];

        return (
            <ReactDataTable
                list={formattedData}
                columns={columns}
                isExportable={false}
                isSearchable={false}
                isPageable={false}
                isSortable={false}
                striped={false}
                isEditable={enableEditing}
                cellEditOptions={calculationType === 'General' ? cellEditOptions : undefined}
                showHeader={false}
                containerStyle={{
                    marginBottom: '-18px',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    marginTop: '-5px',
                    borderRadius: 0,
                    border: 0
                }}
                rowClasses="matchExpandRowColor"
                keyValue="Name"
                expandContent={
                    calculationType === 'Cost'
                        ? {
                              renderer: expandComponent,
                              expandedIcon: faCaretDown,
                              collapsedIcon: faCaretRight
                          }
                        : undefined
                }
                defaultVisibility={defaultVisibility}
                defaultSorted={defaultSorted}
            />
        );
    }
);

MCDPlannerWorkbenchDrilldown.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    filter: PropTypes.string.isRequired,
    updateWorkspaceData: PropTypes.func,
    isEditable: PropTypes.bool.isRequired,
    tableType: PropTypes.string,
    calculationType: PropTypes.string
};

export default MCDPlannerWorkbenchDrilldown;
