/**
 * Strategic Outlook selector.
 *
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import { createSelector } from 'reselect';

/**
 * @param state
 */
const getStrategicOutlookState = (state) => state.MetricProjectionsModel;
export const getStrategicOutlookMetrics = createSelector(
    getStrategicOutlookState,
    (state) => state.list
);

/**
 * @param state
 */
const getStrategicOutlookPopupState = (state) => state.MetricPopupModel;
export const getStrategicOutlookGraph1Metrics = createSelector(
    getStrategicOutlookPopupState,
    (state) => state.graph1.data
);

/**
 *
 */
export const getStrategicOutlookGraph2Metrics = createSelector(
    getStrategicOutlookPopupState,
    (state) => state.graph2.data
);

/**
 * @param n
 * @param max
 * @param min
 */
const calculateRatio = (n, max, min) => ((n - min) / (max - min)) * 100;

export const getVisnMetrics = createSelector(getStrategicOutlookMetrics, (metricList) =>
    metricList.map((metric) => {
        const mappedMetric = {
            id: metric.Id,
            description: metric.Description,
            projectedValue: metric.MeasureActual,
            market: metric.MarketName,
            status: metric.StatusString,
            projectionType: metric.ProjectionTypeString,
            hspcType: metric.HspType,
            barGraphData: []
        };

        let upperBound = metric.Year0;
        let lowerBound = metric.Year0;
        for (let j = 1; j < 11; j++) {
            if (metric[`Year${j}Projected`] > upperBound) {
                upperBound = metric[`Year${j}Projected`];
            }
            if (metric[`Year${j}Projected`] < lowerBound) {
                lowerBound = metric[`Year${j}Projected`];
            }
        }

        mappedMetric.barGraphData.push({
            name: '0',
            value: calculateRatio(metric.Year0, upperBound, lowerBound)
        });

        for (let j = 1; j < 11; j++) {
            mappedMetric.barGraphData.push({
                name: j.toString(),
                value: calculateRatio(metric[`Year${j}Projected`], upperBound, lowerBound)
            });
        }
        return mappedMetric;
    })
);

// TODO: Update database to include description
/**
 *
 * @param {*} projectionType
 * @param {*} hspType
 * @param {*} actual
 */
const getDescription = (projectionType, hspType, actual) => {
    const direction = actual >= 0 ? 'Increase' : 'Decrease';
    if (projectionType === 'Demand') {
        return `${direction} in demand (utilization): `;
    }
    if (hspType === 'Enrollment_Special_Conflict' || hspType === 'Enrollment') {
        return `${direction} in enrollment: `;
    }
    return `${direction} in eligible veterans: `;
};

/**
 *
 * @param {*} state
 * @param {*} graph
 * @param {*} index
 */
const mapDataToTableConfig = (state, graph, index) => {
    const actual = (graph.data[graph.data.length - 1] - graph.data[0]) / graph.data[0];
    const mappedGraph = {
        Id: state.data.Id + index,
        HspType: graph.name,
        MeasureActual: actual,
        Description:
            getDescription(state.data.ProjectionTypeString, state.data.HspType, actual) +
            graph.name,
        IsTotal:
            state.data.HspType === 'Enrollment_Gender'
                ? graph.name.indexOf('Total') >= 0
                : index === 0
    };
    return mappedGraph;
};

/**
 * Given the state and data from the MetricPopupModel, this function maps the series data as well
 * as the group data into the format of the metric table.
 *
 * @param {object} state - application redux state
 * @returns {object} an object containing the formatted data for both graphs
 */
export const getGraphMetrics = createSelector(
    getStrategicOutlookPopupState,
    getStrategicOutlookGraph1Metrics,
    getStrategicOutlookGraph2Metrics,
    (state, graph1List, graph2list) => ({
        graph1: graph1List.map((graph, index) => mapDataToTableConfig(state, graph, index)),
        graph2: graph2list.map((graph, index) => mapDataToTableConfig(state, graph, index))
    })
);
