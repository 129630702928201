import { dateFormatter, visnFormatter } from 'Utilities/formatters';

const UserActivityColumnDefs = [
    {
        id: 'FirstName',
        accessorKey: 'FirstName',
        header: 'User',
        cell: ({ row }) =>
            row.original.User.FirstName && row.original.User.LastName
                ? row.original.User.FirstName + ' ' + row.original.User.LastName
                : '',
        meta: {
            headerDisplayName: 'User',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'VISN',
        accessorKey: 'VISN',
        header: 'VISN',
        meta: {
            headerDisplayName: 'VISN',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: visnFormatter
        }
    },
    {
        id: 'Market',
        accessorKey: 'Market',
        header: 'Market',
        meta: {
            headerDisplayName: 'Market',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'Page',
        accessorKey: 'Page',
        header: 'Page',
        meta: {
            headerDisplayName: 'Page',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'Tabs',
        accessorKey: 'Tabs',
        header: 'Tabs',
        meta: {
            headerDisplayName: 'Tabs',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        id: 'StartTime',
        accessorKey: 'StartTime',
        header: 'Start Time',
        meta: {
            headerDisplayName: 'Start Time',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: dateFormatter
        }
    },
    {
        id: 'EndTime',
        accessorKey: 'EndTime',
        header: 'End Time',
        meta: {
            headerDisplayName: 'Start Time',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: dateFormatter
        }
    },
    {
        id: 'TimeElapsed',
        accessorKey: 'TimeElapsed',
        header: 'Time Elapsed',
        meta: {
            headerDisplayName: 'Time Elapsed',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    }
];

export default UserActivityColumnDefs;
