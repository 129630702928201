/**
 * Form for configuring output of Understand Capacity module data for reports.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Form } from 'redux-form';
import _isEmpty from 'lodash/isEmpty';
import CheckboxField from 'Lib/ReduxFormFields/CheckboxField';
import { canEditVISN } from 'Modules/UserSession/selector';
import FormButtonGroup from '../RWFButtonGroup';

class RWFUnderstandCapacity extends React.Component {
    static propTypes = {
        formValues: PropTypes.shape({
            VisnCode: PropTypes.string,
            ReportId: PropTypes.number,
            ChkCapitalAssets: PropTypes.bool,
            ChkWorkforceTrends: PropTypes.bool,
            ChkCapacityPc: PropTypes.bool,
            ChkCapacityIn: PropTypes.bool,
            ChkAllProviders: PropTypes.bool,
            ChkCcProviders: PropTypes.bool,
            ChkFqhcProviders: PropTypes.bool,
            ChkIhsProviders: PropTypes.bool,
            ChkAaProviders: PropTypes.bool,
            ChkDodProviders: PropTypes.bool,
            ChkAmbulatoryCapacity: PropTypes.bool
        }).isRequired,
        change: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        report: PropTypes.shape({
            ReportForm: PropTypes.shape({
                loading: PropTypes.bool
            })
        }).isRequired,
        gapMPCTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        submitting: PropTypes.bool,
        saving: PropTypes.bool,
        generating: PropTypes.bool,
        loading: PropTypes.bool,
        invalid: PropTypes.bool,
        pristine: PropTypes.bool,
        previousPage: PropTypes.func.isRequired,
        currentPage: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        generateReportById: PropTypes.func.isRequired,
        generateReportByFormData: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        submitting: false,
        saving: false,
        generating: false,
        loading: false,
        invalid: false,
        pristine: true,
        canEdit: false
    };

    formGenerate = () => {
        const { formValues, generateReportById, generateReportByFormData } = this.props;

        if (formValues.ReportId) {
            generateReportById(formValues.ReportId);
        } else {
            generateReportByFormData(formValues);
        }
    };

    selectAllCheck = () => {
        const { formValues, change } = this.props;

        change(
            'ChkCapitalAssets',
            !formValues || !formValues.ChkCapitalAssets ? true : !formValues.ChkCapitalAssets
        );
        change(
            'ChkWorkforceTrends',
            !formValues || !formValues.ChkWorkforceTrends ? true : !formValues.ChkWorkforceTrends
        );
        change(
            'ChkCapacityPc',
            !formValues || !formValues.ChkCapacityPc ? true : !formValues.ChkCapacityPc
        );
        change(
            'ChkCapacityIn',
            !formValues || !formValues.ChkCapacityIn ? true : !formValues.ChkCapacityIn
        );
        change(
            'ChkAllProviders',
            !formValues || !formValues.ChkAllProviders ? true : !formValues.ChkAllProviders
        );
        change(
            'ChkCcProviders',
            !formValues || !formValues.ChkCcProviders ? true : !formValues.ChkCcProviders
        );
        change(
            'ChkFqhcProviders',
            !formValues || !formValues.ChkFqhcProviders ? true : !formValues.ChkFqhcProviders
        );
        change(
            'ChkIhsProviders',
            !formValues || !formValues.ChkIhsProviders ? true : !formValues.ChkIhsProviders
        );
        change(
            'ChkAaProviders',
            !formValues || !formValues.ChkAaProviders ? true : !formValues.ChkAaProviders
        );
        change(
            'ChkDodProviders',
            !formValues || !formValues.ChkDodProviders ? true : !formValues.ChkDodProviders
        );
        change(
            'ChkAmbulatoryCapacity',
            !formValues || !formValues.ChkAmbulatoryCapacity
                ? true
                : !formValues.ChkAmbulatoryCapacity
        );
    };

    render() {
        const {
            submitting,
            saving,
            generating,
            loading,
            invalid,
            pristine,
            currentPage,
            reset,
            canEdit,
            previousPage,
            handleSubmit
        } = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckboxField
                                name="ucSelectAll"
                                label="Select All"
                                onChangeFunction={this.selectAllCheck}
                            />
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <h5 className="card-header card-info">VA Health Care Resources</h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Text
                                </h6>
                                <div className="card-body">
                                    <CheckboxField name="ChkCapitalAssets" label="Capital Assets" />
                                    <CheckboxField
                                        name="ChkWorkforceTrends"
                                        label="Workforce Trends"
                                    />
                                    <CheckboxField
                                        name="ChkCapacityPc"
                                        label="In-House Primary Care Capacity"
                                    />
                                    <CheckboxField
                                        name="ChkCapacityIn"
                                        label="In-House Non Primary Care Capacity"
                                    />
                                    <CheckboxField
                                        name="ChkAmbulatoryCapacity"
                                        label="In-House Ambulatory Care Capacity"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <h5 className="card-header card-info">Healthcare Providers</h5>
                                <h6 className="text-danger" style={{ marginBottom: -15 }}>
                                    Content Type: Text
                                </h6>
                                <div className="card-body">
                                    <CheckboxField
                                        name="ChkAllProviders"
                                        label="All Selected Providers"
                                    />
                                    <CheckboxField
                                        name="ChkCcProviders"
                                        label="Community Care Providers"
                                    />
                                    <CheckboxField
                                        name="ChkFqhcProviders"
                                        label="Federally Qualified Health Center (FQHC) Collaborations"
                                    />
                                    <CheckboxField
                                        name="ChkIhsProviders"
                                        label="Indian Health Service (IHS) Collaborations"
                                    />
                                    <CheckboxField
                                        name="ChkAaProviders"
                                        label="Academic Affiliations"
                                    />
                                    <CheckboxField name="ChkDodProviders" label="DoD Facilities" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12" style={{ paddingTop: 15 }}>
                    <FormButtonGroup
                        formGenerate={this.formGenerate}
                        previousPage={previousPage}
                        submitting={submitting}
                        saving={saving}
                        generating={generating}
                        loading={loading}
                        invalid={invalid}
                        pristine={pristine}
                        reset={reset}
                        isFirstPage={currentPage === 0}
                        isLastPage={currentPage === 4}
                        canEdit={canEdit}
                    />
                </div>
            </Form>
        );
    }
}
const ReduxFormRWFUnderstandCapacity = reduxForm({
    form: 'ReportForm',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.ReportName) {
            errors.ReportName = 'Report name is required';
        }
        if (!values.VisnCode) {
            errors.VisnCode = 'Visn is required';
        }
        if (!values.MarketName) {
            errors.MarketName = 'Market is required';
        }
        return errors;
    }
})(RWFUnderstandCapacity);

const mapDispatchToProps = (dispatch) => {
    return {
        getReportDropdowns: (marketName, visnCode) =>
            dispatch.ReportFormModel.fetchDropdownOptionsAsync({
                marketName,
                visnCode
            }),
        clearReportForm: () => dispatch.ReportFormModel.resetTable('ReportForm'),
        generateReportById: (reportId) =>
            dispatch.ReportFormModel.generateReportByIdAsync(reportId),
        generateReportByFormData: (formValues) =>
            dispatch.ReportFormModel.generateReportByFormDataAsync(formValues)
    };
};

const mapStateToProps = (state) => ({
    formType: _isEmpty(state.ReportFormModel.ReportForm.data) ? 'create' : 'edit',
    formValues: getFormValues('ReportForm')(state),
    initialValues: state.ReportFormModel.ReportForm.data || {},
    saving: state.ReportFormModel.ReportForm.saving,
    loading: state.ReportFormModel.ReportForm.loading,
    generating: state.ReportFormModel.ReportForm.generating,
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormRWFUnderstandCapacity);
