import React, { Component } from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class ESSDSail extends Component {
    render() {
        return (
            <div>
                <p>This section of the Environmental Scan is under revision and to be 
                    determined since the VA no longer issues star ratings for VA Medical Centers.</p>

                <br/>
                <div style={{width: '100%', height: '30px'}}></div>
                {/* <a href="https://www.va.gov/qualityofcare/apps/mcps-app.asp" style={{textDecoration: 'underline'}}>Dartmouth Atlas of Health Care</a> */}
            </div>
        );
    }
}