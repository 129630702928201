/**
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';

import SubTabComponent from '../../../../Lib/Tabs/SubTabComponent';
import InHouseCapacityTables from './UCHCRCapacityDashboard';
import MarketDescriptionForm from './UCMarketNarrative';
import FacilityForm from './UCVaFacilities';
import VaFacilities from './UCVaFacilities/UCVFTable';

export default class UCHealthCareResources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const tabs = [
            {
                key: 0,
                label: 'Market Narrative'
            },
            {
                key: 1,
                label: 'In-House Capacity'
            },
            {
                key: 2,
                label: 'VA Facilities'
            }
        ];

        return (
            <div>
                <div>
                    <div>
                        <SubTabComponent
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            handleOnClick={this.changeTab}
                        />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                        {this.state.activeTab === 0 ? <MarketDescriptionForm /> : null}
                        {this.state.activeTab === 1 ? <InHouseCapacityTables /> : null}
                        {this.state.activeTab === 2 ? <VaFacilities /> : null}
                    </div>
                </div>
                <FacilityForm />
            </div>
        );
    }
}
