import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components/macro';

import AsyncContent from 'Lib/AsyncContent';
import LinkButton from 'Lib/LinkButton';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getPlanObject } from '../../Services/selector';
import MCDJustificationModal from '../MCDJustificationModal';
import MCDProviderModal from '../MCDProviderModal';
import PlanContent from './PlanContent';
import PlanHeader from './PlanHeader';
import PlanNavigation from './PlanNavigation';

const BackButtonMedium = styled(LinkButton)`
    font-size: 1.08em;
    text-transform: uppercase;
    color: #101010;
    opacity: 0.7;
    > * {
        display: inline-block;
        vertical-align: middle;
    }
    &:hover {
        text-decoration: none;
        color: #101010;
        opacity: 1;
    }
`;

const Container = styled.div`
    background-color: #e0e0e0;
    padding-top: 8px;
    padding-bottom: 15px;
    height: 100%;
`;

const PlanCard = styled(Card)`
    position: relative;
`;

/**
 * The Match Capacity module workbench.  Renders the contents of the Match Capacity module.
 *
 * @returns info to render the component
 */
const MCDPlannerWorkbench = () => {
    const { path } = useRouteMatch();

    const dispatch = useDispatch();
    const [isPlanLoaded, setIsPlanLoaded] = useState(false);
    const { url } = useRouteMatch();
    const { id } = useParams();
    const plan = useSelector(getPlanObject);

    const previousURL = url.substring(0, url.lastIndexOf('/'));

    useEffect(() => {
        dispatch.PlannerModel.fetchPlanByIdAsync(id);
    }, [id, dispatch.PlannerModel]);

    useEffect(() => {
        setIsPlanLoaded(!!plan.planData.Plan);
    }, [setIsPlanLoaded, plan.planData.Plan]);

    return (
        <Container>
            <BackButtonMedium to={previousURL} variant="link">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                <span>Go back</span>
            </BackButtonMedium>
            <div className="col-sm-12">
                <PlanCard>
                    <AsyncContent
                        isLoading={!isPlanLoaded}
                        loadingLabel="Plan loading, please wait..."
                        loadedLabel="Plan loaded"
                    >
                        <Route path={`${path}/:type`}>
                            <PlanHeader plan={plan.planData.Plan} />
                        </Route>
                        <Card.Body>
                            <PlanNavigation
                                typeOfCare={plan.planData.Plan?.Hspc.MpcType}
                                hspc={plan.planData.Plan?.Hspc.Hspc}
                            />
                            <Route path={`${path}/:type`}>
                                <PlanContent plan={plan} />
                            </Route>
                        </Card.Body>
                    </AsyncContent>
                </PlanCard>
            </div>
            <MCDProviderModal />
            <Route path={`${path}/:type`}>
                <MCDJustificationModal />
            </Route>
        </Container>
    );
};

export default MCDPlannerWorkbench;
