/**
 * Goals and objectives of the HSPA.
 * @author Anthony P. Pancerella
 * @version November 27, 2018
 */
import React from 'react';

export default class ESSPFStrategicPolicy extends React.Component {
    render() {
        return (
            <div>
                The VA Strategic Plan provides the foundation for how the Department of Veterans
                Affairs will transform as a department in order to meet its mission to fulfill
                President Lincoln's promise{' '}
                <em>
                    "To care for him who shall have borne the battle, and for his widow and his
                    orphan"
                </em>{' '}
                by serving and honoring the men and women who are America's Veterans. Combined with
                the VA Core Values and Characteristics, the VA Strategic Plan provides the
                foundation from which VHA strategic plans and guidance arise. The VHA Strategic Plan
                aligns with and supports the accomplishment of the VA Strategic Plan Goals and
                Objectives. The VA Strategic Plan Goals and Objectives are:
                <br />
                <br />
                <strong>Veterans Choice Program</strong>
                <ul>
                    <li>
                        <a
                            href="https://www.va.gov/opa/choiceact/index.asp"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://www.va.gov/opa/choiceact/
                        </a>
                    </li>
                </ul>
                <br />
                <strong>Mission Act</strong>
                <ul>
                    <li>
                        <a
                            href="https://missionact.va.gov/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://missionact.va.gov/
                        </a>
                    </li>
                    <li>
                        <strong>Overall</strong>
                        <ul>
                            <li>
                                <p>
                                    The VA MISSION Act of 2018 will fundamentally transform elements
                                    of VA’s health care system, fulfilling the President’s
                                    commitment to helping Veterans live a healthy and fulfilling
                                    life. It was signed into law by the President on June 6, 2018.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br />
                <p>Our transformed health care system will:</p>
                <ol>
                    <li>
                        <p>
                            <strong>Ensure easy and reliable access to care</strong> when they need
                            it.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Provide exceptional care to Veterans</strong> anytime, anywhere.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Serve as a trusted, caring partner</strong>, helping Veterans
                            and their families be healthy and well.
                        </p>
                    </li>
                </ol>
                <br />
                <strong>Accessible, Easy, and Reliable Care</strong>
                <ul>
                    <li>
                        <p>
                            Veterans need to be able to get the care they need when they need it,
                            and where they want it. VA has been providing care for Veterans in their
                            communities for years, but it has been managed through a variety of
                            different programs which resulted in confusion. The MISSION Act will
                            allow VHA to continue consolidating community care programs to provide{' '}
                            <strong>One</strong> program that is easy to understand, simple to
                            administer and meets the needs of Veterans and their families, VA staff
                            and community providers.
                        </p>
                        <ul>
                            <li>
                                <p>Timeline:</p>
                                <ul>
                                    <li>
                                        <p>
                                            Regulations and procedures for the new Veterans
                                            Community Care Program are in development.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            The new program is anticipated to be in place by October
                                            2019.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            In the meantime, the Mission Act provides $5.2 billion
                                            for the continuation of existing community care programs
                                            and uninterrupted care for Veterans.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        As the largest health care system in the United States, recruiting and
                        retaining providers has always been a challenge.
                        <p>
                            As the largest health care system in the United States, recruiting and
                            retaining providers has always been a challenge. To ensure easy and
                            reliable access to care, we need to have enough providers—doctors,
                            nurses, clinicians, specialists, and technicians. Through implementation
                            of the MISSION Act, we will be better equipped to recruit and retain the
                            best medical providers through new scholarship programs, education debt
                            reduction, and bonuses for recruitment, relocation, and retention. We
                            are also starting a pilot scholarship program for Veterans to get
                            medical training in return for serving in a VA hospital or clinic for
                            four years. Who better to understand and treat Veterans than Veterans
                            themselves?
                        </p>
                        <ul>
                            <li>
                                <p>Timeline:</p>
                                <ul>
                                    <li>
                                        <p>
                                            Recruitment and retention programs will be initiated
                                            over the course of 2019 and into 2020.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            The needs of Veterans have changed immensely over the last two decades.
                            Our infrastructure—our buildings, equipment, communication systems—needs
                            to be modernized to meet those changing needs. VHA is participating in a
                            thorough review of our infrastructure to ensure we have modern
                            hospitals, clinics, and services so Veterans can receive exceptional
                            care anytime, anywhere.
                        </p>
                        <ul>
                            <li>
                                <p>Timeline:</p>
                                <ul>
                                    <li>
                                        <p>
                                            A thorough VA asset and infrastructure review will take
                                            several years.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            VHA is establishing a triage process to identify and
                                            address the most urgent needs quickly, including
                                            underserved areas.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br />
                <strong>High Quality Care</strong>
                <ul>
                    <li>
                        <p>
                            Implementation of the Mission Act will enable us to deliver high quality
                            care by:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Expanding eligibility for caregiver support to Veterans of all
                                    eras of service. The current program has been a success for
                                    post-911 Veterans and their caregivers. Caregivers and Veterans
                                    can learn more about available support by visiting
                                    www.caregiver.va.gov or by calling the Caregiver Support Line
                                    toll-free at 1-855-260-3274.
                                </p>
                                <ul>
                                    <li>
                                        <p>Timeline:</p>
                                        <ul>
                                            <li>
                                                <p>
                                                    Expansion will occur in two phases, starting
                                                    with those who incurred or aggravated a serious
                                                    injury in the line of duty on or before May 7,
                                                    1975, with further expansion beginning two years
                                                    after that.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    In September, we will submit a report to
                                                    Congress with a timeline for implementation.
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Enhancing our ability to provide telemedicine for Veterans in
                                    remote areas through convenient and comfortable care.
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Timeline: We will roll out these quality initiatives as
                                            quickly as possible over the next year.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <br />
                <strong>Trusted, Caring Partner</strong>
                <ul>
                    <li>
                        <p>
                            Veterans need a trusted, caring partner who will provide them with the
                            highest quality care, support and tools at their convenience. We have
                            not always earned that trust. The MISSION Act defines the changes we
                            need to make, beginning right now, to regain trust, become the caring
                            partner all Veterans deserve and provide exceptional, coordinated and
                            connected care, anytime and anywhere, for the health and well-being of
                            our Nation’s Veterans.
                        </p>
                    </li>
                    <li>
                        <p>
                            VHA has the most accessible health care system in the Nation because of
                            all you do. We can take heart in the fact that certain Veterans—women,
                            for example—are choosing VA health care in numbers greater than ever
                            before.
                        </p>
                    </li>
                </ul>
                <div style={{ width: '100%', height: '30px' }}></div>
            </div>
        );
    }
}
