import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON, postJSON } from 'Utilities/apiCalls';

const understandDemandApi = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const AnalysisToolModel = {
    state: {
        visns: { list: [], loading: false },
        markets: { list: [], loading: false },
        hspcTypes: { list: [], loading: false },
        analysisToolList: { list: [], loading: false, generating: false },
        workloadTypes: { list: [], loading: false },
        analysisToolForm: { data: {}, saving: false, loading: false }
    },
    reducers: {
        populate(state, data, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    list: data
                }
            };
        },
        updateLoading(state, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: !state[object].loading
                }
            };
        },
        updateGenerating(state) {
            return {
                ...state,
                analysisToolList: {
                    ...state.analysisToolList,
                    generating: !state.analysisToolList.generating
                }
            };
        },
        updateSaving(state) {
            return {
                ...state,
                analysisToolForm: {
                    ...state.analysisToolForm,
                    saving: !state.analysisToolForm.saving
                }
            };
        },
        resetTable(state, object) {
            if (object === 'analysisToolForm')
                return {
                    ...state,
                    [object]: { data: {}, saving: false, loading: false }
                };

            return {
                ...state,
                [object]: {
                    list: [],
                    loading: false
                }
            };
        },
        reset(state) {
            return {
                ...state,
                // visns:              { list: [], loading: false },
                // markets:            { list: [], loading: false },
                hspcTypes: { list: [], loading: false },
                analysisToolList: { list: [], loading: false, generating: false },
                // workloadTypes:      { list: [], loading: false },
                analysisToolForm: { data: {}, saving: false, loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchATVisnsAsync(state) {
            try {
                this.resetTable('visns');
                this.updateLoading('visns');
                let result = await getJSON(`${understandDemandApi}GetAllVisns`);
                this.populate(result, 'visns');
                this.updateLoading('visns');
            } catch (error) {
                this.updateLoading('visns');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch AT visns',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchATMarketsAsync(state) {
            try {
                this.resetTable('markets');
                this.updateLoading('markets');
                let result = await getJSON(`${understandDemandApi}GetAllMarkets`);
                this.populate(result, 'markets');
                this.updateLoading('markets');
            } catch (error) {
                this.updateLoading('markets');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch AT markets',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchATGapHspcTypesAsync(showHspcDetail, state) {
            try {
                this.resetTable('hspcTypes');
                this.updateLoading('hspcTypes');
                let result = await getJSON(
                    `${understandDemandApi}GetGapMpcTypesForAnalysisTool?showHspcDetail=${showHspcDetail}`
                );
                this.populate(result, 'hspcTypes');
                this.updateLoading('hspcTypes');
            } catch (error) {
                this.updateLoading('hspcs');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch AT HSPCs',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchAnalaysisTableDataAsync(payload, state) {
            try {
                this.resetTable('analysisToolList');
                this.updateLoading('analysisToolList');
                let result = await getJSON(
                    `${understandDemandApi}GetAnalysisTableData?visn=` +
                        payload.visn +
                        '&market=' +
                        payload.marketName +
                        '&comparedVisn=' +
                        payload.comparedVisn +
                        '&showHspcDetail=' +
                        payload.showHspcDetail +
                        '&workloadType=' +
                        payload.workloadType
                );
                this.populate(result, 'analysisToolList');
                this.updateLoading('analysisToolList');
            } catch (error) {
                this.updateLoading('analysisToolList');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch workload types',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveATReportAsync(payload, state) {
            try {
                this.updateSaving();
                await postJSON(
                    `${understandDemandApi}SaveAnalysisToolForReport?analysisTool=` +
                        payload.reportTool +
                        '&marketName=' +
                        payload.market,
                    payload.formValues
                );
                this.updateSaving();

                dispatch(
                    addNotification({
                        message: 'The AT report has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                this.updateSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save analysis tool report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchATWorkloadTypesAsync(state) {
            try {
                this.resetTable('workloadTypes');
                this.updateLoading('workloadTypes');
                let result = await getJSON(`${understandDemandApi}GetWorkloadTypes`);
                this.populate(result, 'workloadTypes');
                this.updateLoading('workloadTypes');
            } catch (error) {
                this.updateLoading('workloadTypes');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch workload types',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generateATReport(payload, state) {
            try {
                this.updateGenerating();
                await downloadFile(
                    `${exportApiUrl}ExportAnalysisToolPPT?market=` +
                        payload.market +
                        '&visnCode=' +
                        payload.visnCode +
                        '&selectedVisn=' +
                        payload.selectedVisn +
                        '&selectedMarket=' +
                        payload.selectedMarket +
                        '&comparedVisn=' +
                        payload.comparedVisn +
                        '&showHspcDetail=' +
                        payload.showHspcDetail +
                        '&workloadType=' +
                        payload.workloadType
                );
                this.updateGenerating();
            } catch (error) {
                this.updateGenerating();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate AT report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
