import { getPlanSelectionsByMarket } from 'Data/capital-projects';
import epicRequest from 'Utilities/epicRequest';

import { from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    loadPlanSelection as loadPlanSelectionAction,
    loadPlanSelectionDone,
    loadPlanSelectionError,
    loadPlanSelectionStart,
    setPlanSelection
} from '../planSelectionSlice';

/**
 * An epic that listens for loadCapitalProjects actions and requests capital projects from the API.
 *
 * @param action$ - A stream of the redux actions dispatched to the application's redux store.
 * @returns Actions to set capital projects on load.
 */
const loadPlanSelections = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        filter(loadPlanSelectionAction.match),
        distinctUntilChanged((previous, current) => previous.payload === current.payload),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Request capital projects by the specified market identifier and emits a
             * setFacilitySelections action.
             *
             * @param action - The action to take.
             * @param action.payload - The market identifier.
             * @returns An observable of redux actions.
             */
            request: ({ payload }: PayloadAction<string>) =>
                from(getPlanSelectionsByMarket(payload)).pipe(
                    map((plans) => setPlanSelection({ planSelections: plans, market: payload }))
                ),
            actions: {
                start: loadPlanSelectionStart,
                done: loadPlanSelectionDone,
                fail: loadPlanSelectionError
            }
        })
    );

export default loadPlanSelections;
