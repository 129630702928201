import React from 'react';

import { CellContext, ColumnDef } from '@tanstack/react-table';

import EditTableCell from './EditTableCell';

const editColumn: Partial<ColumnDef<unknown, unknown>> = {
    cell: (props: CellContext<unknown, unknown>) => <EditTableCell cellContext={props} />
};

export default editColumn;
