/**
 * Environmental scan selector.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import { createSelector } from 'reselect';

// const getEnvScanState = state => state.envscan
const getStakeholdersForm = (state) => state.StakeholdersFormModel;
const getVisnForm = (state) => state.VisnFormModel;
const getSavedMap = (state) => state.SavedMapsModel;
const getRuralityTotals = (state) => state.RuralityModel.ruralityTotals;
const getRuralityPercent = (state) => state.RuralityModel.ruralityPercent;
const getRuralityCounty = (state) => state.RuralityModel.ruralityCounty;
const getMarketEligiblePriority = (state) => state.DemographicModel.marketEligiblePriority;
const getMarketEligibleAge = (state) => state.DemographicModel.marketEligibleAge;
const getMarketEnrollmentPriority = (state) => state.DemographicModel.marketEnrollmentPriority;
const getMarketEnrollmentAge = (state) => state.DemographicModel.marketEnrollmentAge;
const getMarketSharePriority = (state) => state.DemographicModel.marketSharePriority;
const getMarketShareAge = (state) => state.DemographicModel.marketShareAge;
const getMarketSpecialGender = (state) => state.DemographicModel.marketSpecialGender;
const getMarketSpecialOefoifond = (state) => state.DemographicModel.marketSpecialOefoifond;
const getCountyEligible = (state) => state.DemographicModel.countyEligible;
const getCountyEnrollment = (state) => state.DemographicModel.countyEnrollment;
const getCountyMarketShare = (state) => state.DemographicModel.countyMarketShare;
const getCountySpecialGender = (state) => state.DemographicModel.countySpecialGender;
const getCountySpecialOefoifond = (state) => state.DemographicModel.countySpecialOefoifond;
const getDistanceEligible = (state) => state.FortyMileVeteransModel;
const getSurveyData = (state) => state.SurveyDataModel;

export const getStakeholderFormObject = createSelector(getStakeholdersForm, (state) => state);
export const getVisnFormObject = createSelector(getVisnForm, (state) => state);

export const getSavedMapSelector = createSelector(getSavedMap, (state) => state);
export const getRuralityTotalsSelector = createSelector(
    getRuralityTotals,
    (ruralityTotals) => ruralityTotals
);
export const getRuralityPercentSelector = createSelector(
    getRuralityPercent,
    (ruralityPercent) => ruralityPercent
);
export const getRuralityCountySelector = createSelector(
    getRuralityCounty,
    (ruralityCounty) => ruralityCounty
);
export const getMarketEligiblePrioritySelector = createSelector(
    getMarketEligiblePriority,
    (marketEligiblePriority) => marketEligiblePriority
);
export const getMarketEligibleAgeSelector = createSelector(
    getMarketEligibleAge,
    (marketEligibleAge) => marketEligibleAge
);
export const getMarketEnrollmentPrioritySelector = createSelector(
    getMarketEnrollmentPriority,
    (marketEnrollmentPriority) => marketEnrollmentPriority
);
export const getMarketEnrollmentAgeSelector = createSelector(
    getMarketEnrollmentAge,
    (marketEnrollmentAge) => marketEnrollmentAge
);
export const getMarketSharePrioritySelector = createSelector(
    getMarketSharePriority,
    (marketSharePriority) => marketSharePriority
);
export const getMarketShareAgeSelector = createSelector(
    getMarketShareAge,
    (marketShareAge) => marketShareAge
);
export const getMarketSpecialGenderSelector = createSelector(
    getMarketSpecialGender,
    (marketSpecialGender) => marketSpecialGender
);
export const getMarketSpecialOefoifondSelector = createSelector(
    getMarketSpecialOefoifond,
    (marketSpecialOefoifond) => marketSpecialOefoifond
);
export const getCountyEligibleSelector = createSelector(
    getCountyEligible,
    (countyEligible) => countyEligible
);
export const getCountyEnrollmentSelector = createSelector(
    getCountyEnrollment,
    (countyEnrollment) => countyEnrollment
);
export const getCountyMarketShareSelector = createSelector(
    getCountyMarketShare,
    (countyMarketShare) => countyMarketShare
);
export const getCountySpecialGenderSelector = createSelector(
    getCountySpecialGender,
    (countySpecialGender) => countySpecialGender
);
export const getCountySpecialOefoifondSelector = createSelector(
    getCountySpecialOefoifond,
    (countySpecialOefoifond) => countySpecialOefoifond
);
export const getDistanceEligibleSelector = createSelector(getDistanceEligible, (state) => state);
export const getSurveyDataSelector = createSelector(getSurveyData, (state) => state);
