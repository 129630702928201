/**
 * Grant access form - grants access to specific users.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */

import React from 'react';
import { Form, FormGroup, Col } from 'react-bootstrap';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import _filter from 'lodash/filter';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import Button from 'Lib/Button';
import { getCurrentVISN, getVISNList, isAdministrator } from 'Modules/UserSession/selector';

class SAGrantAccessForm extends React.Component {
    componentWillMount() {
        this.props.getAccessOptionsExistingUser();
    }

    formSubmit = (values) => {
        const selectedGroup = values.RoleCode;
        delete values.RoleCode;
        this.props.grantAccessToExistingUser(
            values,
            selectedGroup,
            values.VisnCode ? values.VisnCode : this.props.currentVISN.VisnCode
        );
        this.props.dispatch(reset('SAGrantAccessForm'));
    };

    render() {
        return (
            <div>
                <div>Grant Access to an Existing User:</div>
                <br />
                <Form onSubmit={this.props.handleSubmit(this.formSubmit)}>
                    <DropdownField
                        name="Username"
                        label="User"
                        placeholder="Select username..."
                        disabled={this.props.saving === true}
                        options={_filter(this.props.allSystemUsers.list, (item) => {
                            let hasAdminRole = false;
                            for (let i = 0; i < item.UserGroups.length; i++) {
                                if (item.UserGroups[i].Group.RoleCode === 'hcpm_administrator') {
                                    hasAdminRole = true;
                                    break;
                                }
                            }
                            return !(hasAdminRole && !this.props.isAdmin);
                        })}
                        displayField="DropDownListDisplay"
                        valueField="Username"
                    />
                    {this.props.isAdmin ? (
                        <DropdownField
                            name="VisnCode"
                            label="Visn"
                            placeholder="Select visn..."
                            disabled={this.props.saving === true}
                            options={this.props.visnList.list}
                            displayField="DisplayName"
                            valueField="VisnCode"
                        />
                    ) : null}
                    <DropdownField
                        name="RoleCode"
                        label="User Group"
                        placeholder="Select group..."
                        disabled={this.props.saving === true}
                        options={this.props.accessOptions.list}
                        displayField="DisplayText"
                        valueField="RoleName"
                    />

                    <FormGroup style={{ paddingTop: '10px' }}>
                        <Col style={{ textAlign: 'center' }}>
                            <Button
                                color="success"
                                type="submit"
                                disabled={
                                    this.props.pristine ||
                                    this.props.invalid ||
                                    this.props.submitting ||
                                    this.props.saving
                                }
                                label={this.props.saving ? 'Granting access...' : 'Grant access'}
                            />
                            &nbsp;&nbsp;
                            <Button
                                color="warning"
                                type="button"
                                label="Cancel"
                                disabled={this.props.invalid || this.props.submitting}
                                onClick={this.props.reset}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const ReduxFormSAGrantAccessForm = reduxForm({
    form: 'SAGrantAccessForm',
    enableReinitialize: true,
    validate(values, props) {
        const errors = {};
        if (!values.Username) {
            errors.Username = 'User is required';
        }
        if (props.isAdmin) {
            if (!values.VisnCode) {
                errors.VisnCode = 'Visn is required';
            }
        }
        if (!values.UserGroup) {
            errors.UserGroup = 'User group is required';
        }
        return errors;
    }
})(SAGrantAccessForm);

const mapDispatchToProps = (dispatch) => {
    return {
        grantAccessToExistingUser: (formValues, selectedGroup, visnCode) =>
            dispatch.ManageUserModel.grantAccessToExistingUserAsync({
                formValues,
                selectedGroup,
                visnCode
            }),
        getAccessOptionsExistingUser: () =>
            dispatch.ManageUserModel.fetchExistingUserAccessOptionsAsync()
    };
};

const mapStateToProps = (state) => ({
    allSystemUsers: state.ManageUserModel.allSystemUsers,
    initialValues: state.ManageUserModel.grantAccessFormData,
    accessOptions: state.ManageUserModel.accessOptionsExistingUser,
    user: state.UserSessionModel,
    saving: state.ManageUserModel.saving,
    visnList: getVISNList(state),
    currentVISN: getCurrentVISN(state),
    isAdmin: isAdministrator(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormSAGrantAccessForm);
