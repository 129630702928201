/**
 * Values and Characteristics of the HSPA.
 * @author Anthony P. Pancerella
 * @version September 18, 2018
 */
import React from 'react';
import styled from 'styled-components/macro';

const ContentPane = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

function getSource() {
    let environment = process.env.REACT_APP_SERVER;
    switch (environment) {
        case 'DEV':
        case 'DEV_LOCAL':
            return 'https://app.powerbi.com/reportEmbed?reportId=5f6a5570-7641-49ec-885f-d2acb9702cd3&autoAuth=true&ctid=f5727296-5b49-4b31-a544-d1d85d64a529&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9';
        case 'PROD':
        case 'TEST':
            return 'https://app.powerbigov.us/reportEmbed?reportId=e843b849-c165-48e3-a41d-1ef8e47bba82&appId=f177d056-dd6f-4aaf-bca8-63737e58134e&autoAuth=true&ctid=e95f1b23-abaf-45ee-821d-b7ab251ab3bf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWdvdi12aXJnaW5pYS1yZWRpcmVjdC5hbmFseXNpcy51c2dvdmNsb3VkYXBpLm5ldC8ifQ%3D%3D';
        case undefined:
        default:
            return '';
    }
}

export default class ESSDSurveyIntroduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '1'
        };
    }
    handleSelect(activeKey) {
        this.setState({ activeKey });
    }

    render() {
        return (
            <ContentPane
                className="embed-responsive embed-responsive-16by9"
                style={{ paddingTop: 15 }}
            >
                {' '}
                <iframe
                    className="embed-responsive-item"
                    width="1140"
                    src={getSource()}
                    allowFullScreen={true}
                ></iframe>
            </ContentPane>
        );
    }
}
