/**
 * Table for Specifics in Future Market Trends of the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { makeGetFutureMarketTrend } from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentMarketName, getDefaultYearObject } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import ExpandTable from '../ExpandTable';
import FutureMarketSpecificColumns from './FutureMarketSpecificColumns';

class UDFMTSpecificTable extends React.Component {
    isExpandableRow = (row) => {
        if (!this.props.isExpandable) {
            return false;
        }
        return row.IsExpandable;
    };

    expandComponent = (row) => {
        const dataArray = this.props.expandTableData.list.filter(
            (rvu) => rvu.HspcGroup === row.HspcGroup && rvu.Hspc === row.Hspc
        );
        return (
            <ExpandTable
                data={dataArray}
                defaultYear={this.props.defaultYear}
                demand={this.props.demand}
            />
        );
    };

    rowClassNameFormat(row) {
        const { marketName } = this.props;
        const outOfMarketStyle = {
            backgroundColor: '#adc8da'
        };
        return marketName !== row.Facility ? outOfMarketStyle : undefined;
    }

    render() {
        const defaultYear = this.props.defaultYear || '';
        const expandContent = {
            canRender: this.isExpandableRow,
            renderer: this.expandComponent
        };

        const { marketName } = this.props;
        const generateRowStyle = (market) => (row) => {
            const outOfMarketStyle = {
                backgroundColor: '#adc8da'
            };

            return market !== (row.Facility || market) ? outOfMarketStyle : undefined;
        };

        return (
            <div>
                <ReactDataTable
                    list={this.props.tableData.list}
                    columns={FutureMarketSpecificColumns(defaultYear)}
                    loading={this.props.tableData.loading}
                    csvFileName={this.props.marketName + this.props.exportName}
                    defaultVisibility={{ ActualYear2Total: false, ActualYear1Total: false }}
                    expandContent={expandContent}
                    keyValue='no-id'
                    rowStyles={generateRowStyle(marketName)}
                />
            </div>
        );
    }

    static propTypes = {
        filter: PropTypes.string.isRequired,
        isExpandable: PropTypes.bool.isRequired,
        exportName: PropTypes.string.isRequired,
        expandTableType: PropTypes.string
    };
}

const makeMapStateToProps = () => {
    const getFutureMarketTrend = makeGetFutureMarketTrend();
    const mapStateToProps = (state, props) => ({
        marketName: getCurrentMarketName(state),
        defaultYear: getDefaultYearObject(state),
        tableData: getFutureMarketTrend(state, props.filter),
        expandTableData: getFutureMarketTrend(state, props.expandTableType)
    });
    return mapStateToProps;
};
export default connect(makeMapStateToProps)(UDFMTSpecificTable);
