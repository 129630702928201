import React from 'react';
import { connect } from 'react-redux';

/**
 * Understand Capacity dashboard for Healthcare Resources.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import DDFContainer from 'Lib/DDFSlides/DDFContainer';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import {
    getAllCapacitySelector,
    getAmbulatoryCareSelector,
    getInHousePrimaryCareSelector
} from 'Modules/UnderstandCapacity/Services/selector';
import { getCurrentMarketName } from 'Modules/UserSession/selector';

import EditAmbulatoryCareCapacityModal from './EditAmbulatoryCareCapacityModal';
import * as TableOutline from './TableOutline';
import EditBedsModal from './UCHCROperatingBedsModal';
import EditPrimaryCareModal from './UCHRCPrimaryCareModal';

class UCHCRCapacityDashboard extends React.Component {
    componentDidMount() {
        if (this.props.marketName) {
            if (this.props.InHousePrimaryCare.list.length === 0) {
                this.props.inHousePrimaryCareFetchList(this.props.marketName);
            }
            if (this.props.AllCapacity.list.length === 0) {
                this.props.allCapacityByMarket(this.props.marketName);
            }
            if (this.props.AmbulatoryCare.list.length === 0) {
                this.props.ambulatoryCareFetchList(this.props.marketName);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.marketName !== this.props.marketName) {
            this.props.inHousePrimaryCareFetchList(this.props.marketName);
            this.props.allCapacityByMarket(this.props.marketName);
            this.props.ambulatoryCareFetchList(this.props.marketName);
        }
    }

    render() {
        return (
            <div>
                <div>
                    <DDFContainer
                        text={
                            'There is relevant data published in the Data Discovery and Findings.'
                        }
                        reports={[
                            {
                                title: 'Estimated Capacity for Outpatient Workload',
                                slideNumbers: [116, 166]
                            }
                        ]}
                    ></DDFContainer>
                </div>
                <h4 className="text-primary">
                    <b>Primary Care Capacity</b>
                </h4>
                <ReactDataTable
                    keyValue="Id"
                    list={this.props.InHousePrimaryCare.list}
                    columns={TableOutline.PrimaryCareCapacityColumnDefs}
                    loading={this.props.InHousePrimaryCare.loading}
                    csvFileName={`${this.props.marketName}-PC In House Capacity.csv`}
                    btnRightColor={'info'}
                    btnRightFunc={() => this.props.togglePrimaryCareModal()}
                    btnRightLabel={'Edit Primary Care'}
                    showCsvPptBtnGroup={true}
                />
                <h4 className="text-primary">
                    <b>Inpatient Care Capacity</b>
                </h4>
                <ReactDataTable
                    keyValue="Id"
                    list={this.props.AllCapacity.list} // InHouseNonPrimaryCare.list}
                    columns={TableOutline.NonPrimaryCareCapacityColumnDefs}
                    loading={this.props.AllCapacity.loading}
                    csvFileName={`${this.props.marketName}-Non-PC In House Capactiy.csv`}
                />
                <h4 className="text-primary">
                    <b>Specialty Care Capacity</b>
                </h4>
                <ReactDataTable
                    keyValue="Id"
                    list={this.props.AmbulatoryCare.list} // InHouseNonPrimaryCare.list}
                    columns={TableOutline.AmbulatoryCareCapacityColumnDefs}
                    loading={this.props.AmbulatoryCare.loading}
                    csvFileName={`${this.props.marketName}-SpecialtyCapacity.csv`}
                    btnRightColor="info"
                    btnRightFunc={() => this.props.toggleAmbCareModal()}
                    btnRightLabel="Edit Specialty Care Capacity"
                    showCsvPptBtnGroup
                />
                <EditBedsModal />
                <EditAmbulatoryCareCapacityModal />
                <EditPrimaryCareModal />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inHousePrimaryCareFetchList: (market) =>
            dispatch.InHouseCapacityModel.fetchPrimaryCareAsync(market),
        inHouseNonPrimaryCareFetchList: (market) =>
            dispatch.InHouseCapacityModel.fetchNonPrimaryCareAsync(market),
        ambulatoryCareFetchList: (market) =>
            dispatch.InHouseCapacityModel.fetchAmbulatoryCareAsync(market),
        allCapacityByMarket: (market) =>
            dispatch.InHouseCapacityModel.fetchAllCapacityAsync(market),
        toggleOperatingBedsModal: () =>
            dispatch.InHouseCapacityModel.toggleModal('operatingBedsModal'),
        toggleAmbCareModal: () => dispatch.InHouseCapacityModel.toggleModal('ambulatoryCareModal'),
        togglePrimaryCareModal: () => dispatch.InHouseCapacityModel.toggleModal('primaryCareModal')
    };
};

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    InHousePrimaryCare: getInHousePrimaryCareSelector(state),
    AllCapacity: getAllCapacitySelector(state),
    AmbulatoryCare: getAmbulatoryCareSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UCHCRCapacityDashboard);
