import { getJSON } from 'Utilities/apiCalls';
import { USE_DEMO_OPPORTUNITIES } from 'Utilities/constants';
import { Recommendation } from 'Utilities/types';

import data from './data';

if (typeof process.env.REACT_APP_MAHSO_API === 'undefined') {
    throw new Error(
        'REACT_APP_MAHSO_API is undefined.  Please ensure it contains the URL to the MAHSO API.'
    );
}

const RECOMMENDATIONS_URL = `${process.env.REACT_APP_MAHSO_API}/mahso/aol/v1/recommendations`;
const OPPS_BY_MARKETNAME_URL = `${RECOMMENDATIONS_URL}/displayMarket/`;

/**
 * Gets the proper Market Identifier reference for the MAHSO database.
 *
 * @param marketName - The current market identifier.
 * @returns - The MAHSO market identifier.
 */
const getMarketId = (marketName: string): string => {
    switch (marketName) {
        case '02-e':
            return '02-d';
        case '04-d':
            return '04-c';
        case '07-h':
            return '07-e';
        case '07-i':
            return '07-g';
        case '08-p':
            return '08-n';
        case '08-o':
            return '08-m';
        case '09-f':
            return '09-a';
        case '09-g':
            return '09-b';
        case '09-h':
            return '09-e';
        case '09-i':
            return '09-d';
        case '10-k':
            return '10-d';
        case '10-m':
            return '10-h';
        case '19-a':
            return '19-j';
        case '19-c':
            return '19-k';
        case '19-e':
            return '19-m';
        case '19-d':
            return '19-l';
        case '21-g':
            return '21-h';
        case '21-j':
            return '21-i';
        case '21-b':
            return '21-e';
        case '21-a':
            return '21-d';
        case '21-c':
            return '21-f';
        case '22-a':
            return '22-c';
        default:
            return marketName;
    }
};

/**
 * Get the recommendations for a market.
 *
 * @param marketName - name of the market
 * @returns recommendations
 */
export const getOpportunitiesByMarket = async (marketName: string): Promise<Recommendation[]> => {
    if (USE_DEMO_OPPORTUNITIES) {
        return Promise.resolve(data);
    }

    if (!marketName) {
        return [];
    }

    const recommendations = (await getJSON(
        `${OPPS_BY_MARKETNAME_URL}${getMarketId(marketName)}`
    )) as Recommendation[];

    const formatter = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' });

    const mappedRecommendations = recommendations.map((recommendation) => {
        recommendation.created = recommendation.created
            ? formatter.format(new Date(recommendation.created))
            : '';
        recommendation.modified = recommendation.modified
            ? formatter.format(new Date(recommendation.modified))
            : '';
        return recommendation;
    });
    return mappedRecommendations;
};

export default { getOpportunitiesByMarket };
