import React from 'react';

import Button from 'Lib/Button';
import { dateFormatter } from 'Utilities/formatters';

import { createColumnHelper } from '@tanstack/react-table';

import EditReportButton from '../EditReportButton';

const columnHelper = createColumnHelper();

const UserCreatedReportColumnDefs = (deleteReport, generateReport, canEdit) => [
    {
        accessorKey: 'ReportName',
        header: 'Report Name',
        meta: {
            headerDisplayName: 'Report Name',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'CreatedBy',
        header: 'Created By',
        cell: ({ row }) =>
            row.original.CreatedBy
                ? `${row.original.CreatedBy.FirstName} ${row.original.CreatedBy.LastName}`
                : '',
        meta: {
            headerDisplayName: 'Created By',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'CreatedDate',
        header: 'Created Data',
        meta: {
            headerDisplayName: 'Created Date',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: dateFormatter
        }
    },
    {
        accessorKey: 'UpdatedBy',
        header: 'Updated By',
        cell: ({ row }) =>
            row.original.UpdatedBy
                ? `${row.original.UpdatedBy.FirstName} ${row.original.UpdatedBy.LastName}`
                : '',
        meta: {
            headerDisplayName: 'Updated By',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center'
        }
    },
    {
        accessorKey: 'UpdatedDate',
        header: 'Last Updated Date',
        meta: {
            headerDisplayName: 'Last Updated Date',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            formatter: dateFormatter
        }
    },
    columnHelper.display({
        id: 'Edit',
        header: 'Edit',
        enableSorting: false,
        cell: ({ row }) => <EditReportButton id={row.original.Id} />,
        meta: {
            headerDisplayName: 'Edit',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            csvExportIgnore: true
        }
    }),
    columnHelper.display({
        id: 'Delete',
        header: 'Delete',
        enableSorting: false,
        cell: ({ row }) => (
            <Button
                color="danger"
                type="button"
                label="Delete"
                disabled={!canEdit}
                onClick={() => deleteReport(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            headerDisplayName: 'Delete',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            csvExportIgnore: true
        }
    }),
    columnHelper.display({
        id: 'Export',
        header: 'Export',
        enableSorting: false,
        cell: ({ row }) => (
            <Button
                color="success"
                type="button"
                label="Generate"
                onClick={() => generateReport(row.original.Id)}
                btnPaddingTop={1}
                btnPaddingBottom={1}
            />
        ),
        meta: {
            headerDisplayName: 'Export',
            columnClassName: 'text-center',
            columnStyle: { whiteSpace: 'pre-wrap' },
            cellClassName: 'text-center',
            csvExportIgnore: true
        }
    })
];

export default UserCreatedReportColumnDefs;
