export const MetricFilterModel = {
    state: {
        ProjectionTypeFilter: {
            label: 'Enrollment'
        },
        WithinThreshold: { filter: true },
        BelowThreshold: { filter: true },
        UnknownThreshold: { filter: true }
    },
    reducers: {
        updateProjectionFilter(state, key, filter) {
            return {
                ...state,
                ProjectionTypeFilter: {
                    label: filter
                }
            };
        },
        updateThresholdFilter(state, threshold, filter) {
            if (threshold === 'WithinThreshold')
                return {
                    ...state,
                    WithinThreshold: { filter: filter }
                };
            else if (threshold === 'BelowThreshold')
                return {
                    ...state,
                    BelowThreshold: { filter: filter }
                };
            else
                return {
                    ...state,
                    UnknownThreshold: { filter: filter }
                };
        },
        reset(state) {
            return {
                ProjectionTypeFilter: {
                    key: 0,
                    label: 'Enrollment'
                },
                WithinThreshold: { filter: true },
                BelowThreshold: { filter: true },
                UnknownThreshold: { filter: true }
            };
        }
    }
};
