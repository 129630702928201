/**
 * Display saved maps.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getSavedMapSelector } from 'Modules/EnvironmentalScan/Services/selector';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import SavedMapsColumnDefs from './TableOutline';

class ESSavedMaps extends React.Component {
    static propTypes = {
        marketName: PropTypes.string.isRequired,
        savedMapList: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        canEdit: PropTypes.bool,
        savedMapFetchList: PropTypes.func.isRequired,
        deleteSavedMap: PropTypes.func.isRequired
    };

    static defaultProps = {
        canEdit: false
    };

    componentDidMount() {
        const { marketName, savedMapList, savedMapFetchList } = this.props;
        if (marketName && savedMapList.list.length === 0) {
            savedMapFetchList(marketName);
        }
    }

    componentDidUpdate(prevProps) {
        const { marketName, savedMapFetchList } = this.props;
        const { marketName: prevMarketName } = prevProps;
        if (prevMarketName !== marketName) {
            savedMapFetchList(marketName);
        }
    }

    render() {
        const { marketName, savedMapList, canEdit, deleteSavedMap } = this.props;
        return (
            <div>
                <ReactDataTable
                    keyValue="Id"
                    columns={SavedMapsColumnDefs(
                        (id) => deleteSavedMap({ id, market: marketName }),
                        canEdit
                    )}
                    list={savedMapList.list}
                    loading={savedMapList.loading}
                    csvFileName={`${marketName}-SavedMaps.csv`}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
        savedMapFetchList: (market) => dispatch.SavedMapsModel.fetchDataAsync(market),
        deleteSavedMap: (payload) => dispatch.SavedMapsModel.deleteAsync(payload)
    });

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    savedMapList: getSavedMapSelector(state),
    canEdit: canEditVISN(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ESSavedMaps);
