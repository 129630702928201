import _find from 'lodash/find';
import _set from 'lodash/set';
import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';
import { WorkloadType } from 'Utilities/constants';

/**
 * Get the facilityReport slice of the state.
 *
 * @param {object} state - the application redux state
 * @returns {object} the facilityReport slice of the state
 */
const selectFacilityReportState = (state) => state.facilityReport;

/**
 * Get the facilities structure within the facilityReport slice of state.
 *
 * @param {object} state - the application redux state
 * @returns {object} the facilities structure
 */
const selectFacilitiesStructure = createSelector(
    selectFacilityReportState,
    (state) => state.facilities
);

/**
 * Get the currently loaded facility.
 *
 * @param {object} state - the application redux state
 * @returns {string} the station number of the currently loaded facility
 */
export const selectLoadedFacility = createSelector(
    selectFacilityReportState,
    (state) => state.reportFacility
);

/**
 * Get the facilities in the current market.
 *
 * @param {object} state - the application redux state
 * @returns {object[]} an array of facility data
 */
export const selectFacilities = createSelector(
    selectFacilitiesStructure,
    (facilities) => facilities.items
);

/**
 * Get a specific facility.
 *
 * @param {object} state - the application redux state
 * @returns {object} the requested facility's data
 */
export const selectFacility = createCachedSelector(
    selectFacilities,
    (state, stationNumber) => stationNumber,
    (facilities, stationNumber) => _find(facilities, { StationNumber: stationNumber })
)((state, stationNumber) => `${stationNumber}`);

/**
 * Get the loading status of the facilities.
 *
 * @param {object} state - the application redux state
 * @returns {string} the loading status of the facilities
 */
export const selectFacilitiesLoadingStatus = createSelector(
    selectFacilitiesStructure,
    (facilities) => facilities.loadingStatus
);

/**
 * Get the currently loaded facility report.
 *
 * @param {object} state -t he application redux state
 * @returns {object} the facility report data
 */
export const selectFacilityReport = createSelector(
    selectFacilityReportState,
    (state) => state.report
);

/**
 * Get the loading status of the facility report.
 *
 * @param {object} state - the application redux state
 * @returns {string} the loading status of the facility report
 */
export const selectFacilityReportLoadingStatus = createSelector(
    selectFacilityReportState,
    (state) => state.reportLoadingStatus
);
