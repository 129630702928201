import React from 'react';
import { Table } from 'react-bootstrap';
import { useFilters, useGlobalFilter, useTable } from 'react-table';

import styled from 'styled-components/macro';

import { Recommendation } from 'Utilities/types';

import AIRCommissionReport from '../AIRCommissionReport';
import columns from './columns';
import { GlobalSearchFilter } from './tableFilters';

/**
 * @returns A Simple div to display if there are no recommendations within the VISN.
 */
const NoRecommendationsDiv = (): JSX.Element => (
    <div>
        <hr style={{ marginTop: '0' }} />
        <h5 style={{ margin: '10px 20px 15px' }}>No recommendations found for selected market</h5>
    </div>
);

const Styled = styled(Table)`
    th,
    td {
        padding: 10px;
    }
`;

const CardHeader = styled.div`
    padding: 10px;
`;
const CardHeaderTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledTableHeader = styled.th`
    position: relative;
    &:after {
        content: '';
        height: 70%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 15%;
        background-color: rgb(206, 212, 218);
    }
    &:last-of-type:after {
        background-color: inherit;
    }
`;

// background-color: grey;
// border-right: 1px solid magenta;
// border-radious: 5px;
// &:last-of-type {
//     border-right: none;
// }
// `;

type OpportunityTableProps = {
    recommendations: Recommendation[];
};
/**
 * A table of MAHSO healthcare modernization recommendations.
 * These can be enabled/disabled to affect the generation of Match module plans.
 *
 * @param props - Props passed into this component.
 * @param props.recommendations - Array of recommendations to display in the table.
 * @returns The table with all recommendations from the array represented.
 */
const OpportunityTable = ({ recommendations }: OpportunityTableProps): JSX.Element => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, preGlobalFilteredRows,
        setGlobalFilter } = useTable(
            {
                columns,
                data: recommendations,
                defaultCanFilter: false
            },
            useFilters,
            useGlobalFilter
        );

    // React Table standard usage leverages extensive props spreading

    if (recommendations.length === 0) {
        return <NoRecommendationsDiv />;
    }
    return (
        <div>
            <CardHeader>
                <CardHeaderTitle>
                    <h2>Market Assessment Recommendations</h2>
                    <AIRCommissionReport />
                </CardHeaderTitle>
                <GlobalSearchFilter
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </CardHeader>

            <Styled {...getTableProps()}>
                <thead style={{ whiteSpace: 'nowrap' }}>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <StyledTableHeader {...column.getHeaderProps()}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        {column.render('Header')}
                                        <div style={{ display: 'inline' }}>
                                            {column.canFilter && column.Filter
                                                ? column.render('Filter')
                                                : null}{' '}
                                        </div>
                                    </div>
                                </StyledTableHeader>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td style={{ whiteSpace: 'pre-wrap' }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Styled >
        </div >
    );
    /* eslint-enable react/jsx-props-no-spreading */
};

export default OpportunityTable;
