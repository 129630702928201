import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { postJSON } from 'Utilities/apiCalls';

const siteActionApiUrl = `${process.env.REACT_APP_HSPA_API}/SiteAction/`;

export const AskAQuestionModel = {
    state: {
        show: false,
        formData: {},
        submitting: false
    },
    reducers: {
        toggle(state, name, email) {
            return {
                ...state,
                show: !state.show,
                formData: { Name: name, Email: email }
            };
        },
        updateSubmitting(state) {
            return {
                ...state,
                submitting: !state.submitting
            };
        },
        populateForm(state, data) {
            return {
                ...state,
                formData: data
            };
        },
        resetForm(state) {
            return {
                ...state,
                formData: {}
            };
        }
    },
    effects: (dispatch) => ({
        async submitQuestionAsync(formValues, state) {
            try {
                this.updateSubmitting();
                await postJSON(`${siteActionApiUrl}SubmitQuestion`, formValues);
                this.updateSubmitting();
                this.resetForm();
                dispatch(
                    addNotification({
                        message: 'Your question has been submitted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                this.updateSubmitting();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to submit question',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
