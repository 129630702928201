import React from 'react';
import proptypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import './style.css';
export default class TextAreaField extends React.Component {
    render() {
        return (
            <div className="form-group">
                <div
                    className={'col-sm-' + this.props.labelPosition}
                    style={{ paddingRight: 0, paddingLeft: 0 }}
                >
                    <strong>{this.props.label === '' ? '' : this.props.label + ':'}</strong>
                </div>
                <div
                    className={'col-sm-' + this.props.inputPosition}
                    style={{ paddingRight: 0, paddingLeft: 0 }}
                >
                    <textarea
                        style={{ whiteSpace: 'pre-wrap' }}
                        type="text"
                        className="form-control"
                        aria-label={this.props.label}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        rows={this.props.numberOfRows}
                        onChange={this.props.onChangeFunction}
                    />
                    {this.props.loading === true ? (
                        <span className="form-control-feedback" style={{ right: 15 }}>
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }
    static propTypes = {
        value: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        placeholder: proptypes.string,
        loading: proptypes.bool.isRequired,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        disabled: proptypes.bool,
        onChangeFunction: proptypes.func,
        numberOfRows: proptypes.number
    };
}
TextAreaField.defaultProps = {
    placeholder: '',
    disabled: false,
    labelPosition: 2,
    inputPosition: 10,
    value: '',
    label: '',
    onChangeFunction: null,
    loading: false,
    numberOfRows: 6
};
