import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON, putJSON } from 'Utilities/apiCalls';

const envscanApi = `${process.env.REACT_APP_HSPA_API}/EnvironmentalScan/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const StakeholdersFormModel = {
    state: {
        data: {},
        loading: false,
        saving: false,
        generating: false
    },
    reducers: {
        populateForm(state, data) {
            return {
                ...state,
                data: data
            };
        },
        updateLoading(state, isLoading) {
            return {
                ...state,
                loading: isLoading
            };
        },
        updateGenerating(state, isGenerating) {
            return {
                ...state,
                generating: isGenerating
            };
        },
        updateSaving(state, isSaving) {
            return {
                ...state,
                saving: isSaving
            };
        },
        resetForm(state) {
            return {
                ...state,
                data: {},
                loading: false,
                saving: false,
                generating: false
            };
        }
    },
    effects: (dispatch) => ({
        async fetchDataAsync(marketName, state) {
            try {
                this.resetForm();
                this.updateLoading(true);
                let result = await getJSON(
                    `${envscanApi}GetMarketDataByMarketName?market=${marketName}`
                );
                this.populateForm(result);
                this.updateLoading(false);
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch visn form data',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveAsync(formValues, state) {
            try {
                this.updateSaving(true);
                await putJSON(`${envscanApi}SaveStakeholderInformation`, formValues);
                this.updateSaving(false);
                dispatch(
                    addNotification({
                        message: 'Stakeholders information has been updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );

                this.fetchDataAsync(formValues.MarketName);
            } catch (error) {
                this.updateSaving(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save stakeholders information',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generatePowerPointAsync(payload, state) {
            try {
                this.updateGenerating(true);
                await downloadFile(
                    `${exportApiUrl}ExportStakeholdersPPT?market=` +
                        payload.market +
                        '&visnCode=' +
                        payload.visnCode
                );
                this.updateGenerating(false);
            } catch (error) {
                this.updateGenerating(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate PowerPoint',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
