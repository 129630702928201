import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import AdministrationModal from 'Modules/SiteActions/AdministrationModal';
import AskQuestionModal from 'Modules/SiteActions/SAAskAQuestionModal';
import ChangelogModal from 'Modules/SiteActions/SAChangeLogModal';
import FAQModal from 'Modules/SiteActions/SAFaqModal';
import ManageUsersModal from 'Modules/SiteActions/SAManageUserModal';
import UserAccountModal from 'Modules/SiteActions/SAUserAccountModal';
import UserTrainingModal from 'Modules/SiteActions/SAUserTrainingModal';
import { getUserProfile } from 'Modules/UserSession/selector';
import capitalizeFirstCharacter from 'Utilities/capitalizeFirstCharacter';

import useApplicationMenuItemConfig from './useApplicationMenuItemConfig';

/**
 * Create the application action menu component for HSPA.  This provides access to application-level
 * controls and dialogs for HSPA.
 *
 * @returns the application action menu component
 */
const HSPAApplicationMenu = () => {
    const userProfile = useSelector(getUserProfile);

    const title = userProfile
        ? `Welcome, ${capitalizeFirstCharacter(userProfile.FirstName)} ` +
          `${capitalizeFirstCharacter(userProfile.LastName)}`
        : '';

    const applicationMenuItemConfig = useApplicationMenuItemConfig();

    const menuItems = applicationMenuItemConfig.map((item) => {
        if (item.label === null) {
            return <NavDropdown.Divider key={`${item.key}`} />;
        }

        return (
            <NavDropdown.Item key={item.key} hidden={item.hidden} onClick={item.onClick}>
                {item.label}
            </NavDropdown.Item>
        );
    });

    return (
        <>
            <NavDropdown title={title} alignRight>
                {menuItems}
            </NavDropdown>
            <ChangelogModal />
            <FAQModal />
            <AskQuestionModal />
            <AdministrationModal />
            <ManageUsersModal />
            <UserAccountModal />
            <UserTrainingModal />
        </>
    );
};

export default HSPAApplicationMenu;
