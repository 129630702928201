import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { deleteRequest, getJSON, postJSON } from 'Utilities/apiCalls';

const envscanApi = `${process.env.REACT_APP_HSPA_API}/EnvironmentalScan/`;
const reportApi = `${process.env.REACT_APP_HSPA_API}/Report/`;

export const SavedMapsModel = {
    state: {
        list: [],
        loading: false,
        saving: false
    },
    reducers: {
        populate(state, data) {
            return {
                ...state,
                list: data
            };
        },
        updateLoading(state, isLoading) {
            return {
                ...state,
                loading: isLoading
            };
        },
        updateSaving(state, isSaving) {
            return {
                ...state,
                saving: isSaving
            };
        },
        reset(state) {
            return {
                ...state,
                list: [],
                loading: false,
                saving: false
            };
        }
    },
    effects: (dispatch) => ({
        async fetchDataAsync(market, state) {
            try {
                this.reset();
                this.updateLoading(true);
                let result = await getJSON(
                    `${reportApi}GetReportSavedModuleConfigByMarketType?market=` +
                        market +
                        '&reportItemName=ES_MAP_URL'
                );
                this.populate(result);
                this.updateLoading(false);
            } catch (error) {
                this.updateLoading(false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch saved maps',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveAsync(payload, state) {
            try {
                await postJSON(`${envscanApi}SaveNewMap`, payload);
                dispatch(
                    addNotification({
                        message: 'Map has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchDataAsync(payload.MarketName);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save new map',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async deleteAsync(payload, state) {
            try {
                await deleteRequest(`${envscanApi}DeleteMap?id=${payload.id}`);
                dispatch(
                    addNotification({
                        message: 'Map has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchDataAsync(payload.market);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to delete new map',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
