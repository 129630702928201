import React from 'react';
import FontAwesome from 'react-fontawesome';

import styled from 'styled-components/macro';

import { percentFormatter } from 'Utilities/formatters';

type HeaderProps = {
    value: number;
    color: string;
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

/**
 * Get a font icon name based on a projected value.  For values greater than zero, return an arrow
 * pointing up.  For values less than zero, return an arrow pointing down.
 *
 * @param value - the projected value
 * @returns the name of the icon based on the projected value
 */
const getMetricIcon = (value: number) => {
    if (value > 0) {
        return 'arrow-up';
    }
    if (value < 0) {
        return 'arrow-down';
    }
    return '';
};

/**
 * A component that displays header values for a metric card.  This separates the value on the left
 * side and an icon on the right.
 *
 * @param props - props passed into this component
 * @param props.value - the numeric value to display
 * @param props.color - the color of the text
 * @returns the header with the value and icon
 */
const Header = ({ value, color }: HeaderProps): JSX.Element => {
    const icon = getMetricIcon(value);

    return (
        <Container>
            <h4>{percentFormatter(value)}</h4>
            <h4>
                <FontAwesome name={icon} style={{ fontSize: 25, color }} />
            </h4>
        </Container>
    );
};

export default Header;
