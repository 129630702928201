import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

/**
 * The component for an HSPC data row in a facility report.
 *
 * @author Brandan D. Reed
 */
import _map from 'lodash/map';
import styled from 'styled-components/macro';

import PropTypes from 'prop-types';
import {
    commaCurrencyFormatter,
    commaDecimalFormatter,
    commaFormatter
} from 'Utilities/formatters';

const SidewaysHeader = styled.td`
    writing-mode: vertical-lr;
    transform: rotate(180deg);
`;

const costRowMap = [
    {
        property: 'Overhead',
        label: 'Overhead'
    },
    {
        property: 'ResearchAndEducation',
        label: 'Research & Education'
    },
    {
        property: 'VaSpecificIndirect',
        label: 'VA Specific Indirect'
    },
    {
        property: 'Indirect',
        label: 'Indirect'
    },
    {
        property: 'FixedDirect',
        label: 'Fixed Direct'
    },
    {
        property: 'SpecificDirect',
        label: 'Specific Direct'
    },
    {
        property: 'Direct',
        label: 'Direct'
    }
];

const getProjectionDataCells = (projection, formatter = commaFormatter) => {
    const cells = [];
    for (let index = 0; index <= 10; index++) {
        const value = projection ? formatter(projection[`Year${index}`]) : '--';
        cells.push(<td style={{ 'text-align': 'right' }}>{value}</td>);
    }
    return cells;
};

const getStaffingRows = (staffing, className) => {
    const staffingRows = [];

    if (staffing) {
        if (staffing.Total) {
            staffingRows.push({
                label: 'FTEs',
                data: staffing.Total
            });
        } else if (staffing.MD || staffing.APP) {
            staffingRows.push({
                label: 'MD',
                data: staffing.MD
            });
            staffingRows.push({
                label: 'APP',
                data: staffing.APP
            });
        }
    }

    return _map(staffingRows, ({ label, data }) => {
        const staffingCells = getProjectionDataCells(data, commaDecimalFormatter);
        return (
            <tr key={label} className={className}>
                <td colSpan={2}>{label}</td>
                {staffingCells}
            </tr>
        );
    });
};

const getCostRows = (cost, className) => {
    if (!cost) {
        return [];
    }

    return costRowMap.map((rowInfo, index) => {
        const costCells = [];
        if (index === 0) {
            costCells.push(<SidewaysHeader rowSpan={costRowMap.length}>Cost</SidewaysHeader>);
        }
        costCells.push(
            <td>{rowInfo.label}</td>,
            <td>--</td>,
            <td>--</td>,
            <td>--</td>,
            <td>--</td>,
            <td>--</td>,
            <td>--</td>,
            ...cost.map((costData) => (
                <td style={{ 'text-align': 'right' }}>
                    {commaCurrencyFormatter(costData[rowInfo.property])}
                </td>
            ))
        );

        return (
            <tr key={rowInfo.label} className={className}>
                {costCells}
            </tr>
        );
    });
};

/**
 * A single row of values for an HSPC within a facility report.
 *
 * @param {object} props - props passed into this component.
 * @returns {React.ReactNode} the rendered component.
 */
const HSPCRow = ({ capacity, supply, staffing, space, cost, hspc, className }) => {
    const capacityCells = getProjectionDataCells(capacity);
    const supplyCells = getProjectionDataCells(supply);
    const staffingRows = getStaffingRows(staffing, className);
    const spaceCells = getProjectionDataCells(space);
    const costRows = getCostRows(cost, className);

    const capacityPlanID = capacity ? capacity.PlanId : null;
    const supplyPlanID = supply ? supply.PlanId : null;

    const planIcon = capacityPlanID > 0 || supplyPlanID > 0 ? '📃' : null;

    return (
        <>
            <tr className={className} style={{ borderTop: '2px solid #c0c0c0' }}>
                <td rowSpan={3 + staffingRows.length + costRows.length}>
                    {hspc}
                    <span> </span>
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Match Capacity/Demand HSPC plan data</Tooltip>}
                    >
                        <span>{planIcon}</span>
                    </OverlayTrigger>
                </td>
                <td colSpan="3">Estimated Internal Capacity</td>
                {capacityCells}
            </tr>
            <tr className={className}>
                <SidewaysHeader rowSpan={2 + staffingRows.length + costRows.length}>
                    Planned
                </SidewaysHeader>
                <td colSpan={2}>Workload</td>
                {supplyCells}
            </tr>
            {staffingRows}
            <tr className={className}>
                <td colSpan={2}>Space</td>
                {spaceCells}
            </tr>
            {costRows}
        </>
    );
};

HSPCRow.propTypes = {
    /** The name of the HSPC. */
    hspc: PropTypes.string.isRequired,
    /** The capacity projections for the HSPC. */
    capacity: PropTypes.arrayOf(
        PropTypes.shape({
            Year0: PropTypes.number,
            Year1: PropTypes.number,
            Year2: PropTypes.number,
            Year3: PropTypes.number,
            Year4: PropTypes.number,
            Year5: PropTypes.number,
            Year6: PropTypes.number,
            Year7: PropTypes.number,
            Year8: PropTypes.number,
            Year9: PropTypes.number,
            Year10: PropTypes.number
        })
    ),
    /** The supply projections for the HSPC. */
    supply: PropTypes.arrayOf(
        PropTypes.shape({
            Year0: PropTypes.number,
            Year1: PropTypes.number,
            Year2: PropTypes.number,
            Year3: PropTypes.number,
            Year4: PropTypes.number,
            Year5: PropTypes.number,
            Year6: PropTypes.number,
            Year7: PropTypes.number,
            Year8: PropTypes.number,
            Year9: PropTypes.number,
            Year10: PropTypes.number
        })
    ),
    /** The staffing projections for the HSPC. */
    staffing: PropTypes.arrayOf(
        PropTypes.shape({
            MD: PropTypes.shape({
                Year0: PropTypes.number,
                Year1: PropTypes.number,
                Year2: PropTypes.number,
                Year3: PropTypes.number,
                Year4: PropTypes.number,
                Year5: PropTypes.number,
                Year6: PropTypes.number,
                Year7: PropTypes.number,
                Year8: PropTypes.number,
                Year9: PropTypes.number,
                Year10: PropTypes.number
            }),
            APP: PropTypes.shape({
                Year0: PropTypes.number,
                Year1: PropTypes.number,
                Year2: PropTypes.number,
                Year3: PropTypes.number,
                Year4: PropTypes.number,
                Year5: PropTypes.number,
                Year6: PropTypes.number,
                Year7: PropTypes.number,
                Year8: PropTypes.number,
                Year9: PropTypes.number,
                Year10: PropTypes.number
            }),
            Total: PropTypes.shape({
                Year0: PropTypes.number,
                Year1: PropTypes.number,
                Year2: PropTypes.number,
                Year3: PropTypes.number,
                Year4: PropTypes.number,
                Year5: PropTypes.number,
                Year6: PropTypes.number,
                Year7: PropTypes.number,
                Year8: PropTypes.number,
                Year9: PropTypes.number,
                Year10: PropTypes.number
            })
        })
    ),
    /** A css class name applied to the rows of the table. */
    className: PropTypes.string
};

HSPCRow.defaultProps = {
    capacity: undefined,
    supply: undefined,
    staffing: undefined,
    className: ''
};

export default HSPCRow;
