/**
 * Analysis table for the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { getAnalysisToolListSelector } from 'Modules/UnderstandDemand/Services/selector';
import {
    getCurrentMarketName,
    getCurrentVISN,
    getDefaultYearObject
} from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import AnalysisToolColumns from './AnalysisToolColumns';

class UDATTable extends React.Component {
    static propTypes = {
        visn: PropTypes.string,
        market: PropTypes.string,
        comparedVisn: PropTypes.string,
        showHspcDetail: PropTypes.string,
        workloadType: PropTypes.number
    };

    componentDidMount() {
        if (
            this.props.visn &&
            this.props.market &&
            this.props.comparedVisn &&
            this.props.showHspcDetail &&
            this.props.workloadType &&
            this.props.analysisToolList.list.length === 0
        ) {
            this.props.getAnalysisTableData({
                visn: this.props.visn,
                marketName: this.props.market,
                comparedVisn: this.props.comparedVisn,
                showHspcDetail: this.props.showHspcDetail,
                workloadType: this.props.workloadType
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.visn !== this.props.visn ||
            prevProps.market !== this.props.market ||
            prevProps.comparedVisn !== this.props.comparedVisn ||
            prevProps.showHspcDetail !== this.props.showHspcDetail ||
            prevProps.workloadType !== this.props.workloadType
        ) {
            if (
                this.props.visn &&
                this.props.market &&
                this.props.comparedVisn &&
                this.props.showHspcDetail &&
                (this.props.workloadType || this.props.workloadType === 0)
            ) {
                this.props.getAnalysisTableData({
                    visn: this.props.visn,
                    marketName: this.props.market,
                    comparedVisn: this.props.comparedVisn,
                    showHspcDetail: this.props.showHspcDetail,
                    workloadType: this.props.workloadType
                });
            }
        }
    }

    render() {
        const defaultYear = this.props.defaultYear || '';
        let disabled = true;
        if (
            this.props.visn &&
            this.props.market &&
            this.props.comparedVisn &&
            this.props.showHspcDetail &&
            this.props.workloadType
        ) {
            disabled = false;
        }

        const generatePowerpointFunc = () =>
        this.props.generateGapAnalysisPowerpoint({
            market: this.props.marketName,
            visn: this.props.currentVISN.VisnCode,
            selectedVisn: this.props.visn,
            selectedMarket: this.props.market,
            comparedVisn: this.props.comparedVisn,
            showHspcDetail: this.props.showHspcDetail,
            workloadType: this.props.workloadType
        });

        const csvName = `${this.props.marketName}-${this.props.visn}-${this.props.market}-${this.props.comparedVisn}-${this.props.workloadType}-GapAnalysis.csv`;
        const showDetail = this.props.showHspcDetail === 'true';

        return (
            <div>
                <ReactDataTable
                    list={this.props.analysisToolList.list}
                    columns={AnalysisToolColumns(defaultYear, showDetail)}
                    loading={this.props.analysisToolList.loading}
                    csvFileName={csvName}
                    isExportable={!disabled}
                    showCsvPptBtnGroup={!disabled}
                    btnRightFunc={generatePowerpointFunc}
                    btnRightColor='danger'
                    btnRightLabel={this.props.analysisToolList.generating
                        ? 'Generating...'
                        : 'Export to PowerPoint'}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAnalysisTableData: (payload) =>
        dispatch.AnalysisToolModel.fetchAnalaysisTableDataAsync(payload),
    generateGapAnalysisPowerpoint: (payload) => dispatch.AnalysisToolModel.generateATReport(payload)
});

const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    currentVISN: getCurrentVISN(state),
    defaultYear: getDefaultYearObject(state),
    analysisToolList: getAnalysisToolListSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UDATTable);
