export const OperatingBedsColumns = (saving) => {
    return [
        {
            dataField: 'Hspc',
            text: 'HSPC',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap', width: '40%' },
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            dataField: 'Beds',
            text: 'Beds',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            validator: (newValue, row, columns) => {
                if (saving) return false;
                var regex = /^[0-9]+$/;
                if (!regex.test(newValue)) {
                    return {
                        valid: false,
                        message: 'Input must be an integer'
                    };
                }
                return true;
            }
        },
        {
            dataField: 'Adc',
            text: 'ADC',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            editable: false
        },
        {
            dataField: 'BaseCapacityFormatted',
            text: 'BDOC',
            sort: true,
            headerStyle: { whiteSpace: 'pre-wrap' },
            headerAlign: 'right',
            align: 'right',
            editable: false,
            formatter: (cell, row, enumObject, rowIndex) => {
                if (row.Beds) return row.Beds * 365;
                else return 0;
            }
        }
    ];
};

const OperatingBedsColumnDefs = (saving) => {
    return [
        {
            accessorKey: 'Hspc',
            header: 'HSPC',
            meta: {
                columnClassName: 'text-center',
                columnStyle: { whiteSpace: 'pre-wrap', width: '40%' },
                cellClassName: 'text-center'
            }
        },
        {
            accessorKey: 'Beds',
            header: 'Beds',
            meta: {
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right',
                editable: true,
                editInputAttributes: { type: 'number' }
            }
        },
        {
            accessorKey: 'Adc',
            header: 'ADC',
            meta: {
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right'
            }
        },
        {
            accessorKey: 'BaseCapacityFormatted',
            header: 'BDOC',
            cell: ({ row }) => (row.original.Beds ? row.original.Beds * 365 : 0),
            meta: {
                columnClassName: 'text-right',
                columnStyle: { whiteSpace: 'pre-wrap' },
                cellClassName: 'text-right'
            }
        }
    ];
};

export default OperatingBedsColumnDefs;
