import { getDefaultYear } from 'Modules/UserSession/selector';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getChartDataCost } from '../../Services/selector';

const ReactHighcharts = require('react-highcharts');
const HighchartsMore = require('highcharts-more');

HighchartsMore(ReactHighcharts.Highcharts);

const HighchartsExporting = require('highcharts-exporting');

HighchartsExporting(ReactHighcharts.Highcharts);

const chartOptions = {
    chart: {
        type: 'line',
        height: '500',
        align: 'center'
    },
    title: {
        text: 'Demand Capacity Supply Planner'
    },
    exporting: {
        enabled: true,
        sourceHeight: '500',
        sourceWidth: '1000'
    },
    plotOptions: {
        column: {
            stacking: 'normal'
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
    },
    colors: ['#2C3E50', '#68354C']
};

const MCDCostChart = React.memo(({ calculationType }) => {
    const defaultYear = useSelector(getDefaultYear);
    const chartData = useSelector((state) => getChartDataCost(state, calculationType));
    const categories = Array(5)
        .fill()
        .map((item, index) => defaultYear + index + 6);
    const options = {
        ...chartOptions,
        yAxis: {
            title: null,
            reversedStacks: false,
            labels: {
                 formatter: function() {
                    return `$${this.value / 1000000}M`;
                }
            }
        },
        xAxis: { categories },
        tooltip: {
            valueDecimals: 0,
            valuePrefix: '$'
        },
        series: chartData
    };
    return (
        <div>
            <ReactHighcharts config={options} />
            <br />
        </div>
    );
});

MCDCostChart.propTypes = {
    calculationType: propTypes.string.isRequired
};

export default MCDCostChart;
