import React from 'react';
import { Alert } from 'react-bootstrap';

import CostDataDescriptionContent from './CostDataDescriptionContent';
import SpaceEstimatorContent from './SpaceEstimatorContent';

interface PlanInfoPanelProps {
    calculationType: 'Workload' | 'Staffing' | 'Space' | 'Cost';
    typeOfCare: 'Primary' | 'Inpatient' | 'Outpatient';
}

/**
 * An info panel to display within the Match module.  This is used to provide additional data about
 * the current type of data dimension selected.
 *
 * @param props - props passed into this component
 * @param props.calculationType - the type of calculation (Workload, Staffing, Space, Cost)
 * @param props.typeOfCare - the type of care (Primary, Inpatient, Outpatient)
 * @returns the rendered info panel
 */
const PlanInfoPanel: React.FC<PlanInfoPanelProps> = ({ calculationType, typeOfCare }) => {
    if (calculationType !== 'Space' && calculationType !== 'Cost') {
        return null;
    }

    let content: React.ReactNode = null;

    if (calculationType === 'Space') {
        content = <SpaceEstimatorContent typeOfCare={typeOfCare} />;
    }

    if (calculationType === 'Cost') {
        content = <CostDataDescriptionContent />;
    }

    return (
        <Alert variant="secondary" role="note">
            {content}
        </Alert>
    );
};

export default PlanInfoPanel;
