import React, { ReactElement } from 'react';
import { Column, Row } from 'react-table';

import styled from 'styled-components/macro';

import { Recommendation } from 'Utilities/types';

import { SelectColumnFilter } from './tableFilters';

const StyledList = styled.ul`
    list-style: none;
    padding: 0px;
    li {
        display: block;
        white-space: nowrap;
        &:after {
            content: ', ';
        }
        &:last-child:after {
            content: '';
        }
    }
`;

const columns: Column<Recommendation>[] = [
    {
        Header: 'As of Date',
        accessor: 'modified',
        Filter: SelectColumnFilter as unknown as ReactElement,
        filter: 'includes'
    },
    {
        Header: 'Parent Facility',
        accessor: 'facilityLevel',
        Filter: SelectColumnFilter as unknown as ReactElement,
        filter: 'includes'
    },
    {
        Header: 'Category',
        accessor: 'serviceLines',
        Filter: SelectColumnFilter as unknown as ReactElement,
        filter: 'includes',
        Cell: ({ row, value }: { row: Row<Recommendation>; value: string[] }): JSX.Element => (
            <StyledList>
                {value ? value.map((item) => <li key={`${row.id}-${item}`}>{item}</li>) : null}
            </StyledList>
        )
    },
    {
        Header: 'Type',
        accessor: 'recType',
        Filter: SelectColumnFilter as unknown as ReactElement,
        filter: 'includes'
    },
    {
        Header: 'Section',
        accessor: 'sectionNumbers'
    },
    {
        Header: 'Language',
        accessor: 'recLanguage'
    },
    {
        Header: 'Justification',
        accessor: 'recJustification'
    }
];

export default columns;
