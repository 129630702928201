import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import { CellContext } from '@tanstack/react-table';

export type EditTableCellProps = {
    cellContext: CellContext<unknown, unknown>;
};

const EditTableCell = ({ cellContext }: EditTableCellProps) => {
    const { getValue, row, column, table } = cellContext;

    const editInput = useRef<HTMLInputElement>(null);

    const getDisplayValue = (actualValue: unknown): string =>
        column.columnDef.meta?.formatter
            ? column.columnDef.meta.formatter(actualValue)
            : (actualValue as string) || '';

    const oldValue = getValue();

    const [displayValue, setDisplayValue] = useState(getDisplayValue(oldValue));
    const [value, setValue] = useState(oldValue);
    const [editMode, setEditMode] = useState(false);

    const onBlur = useCallback(() => {
        table.options.meta?.updateData(oldValue, value, row, column);
        setEditMode(false);
    }, [oldValue, value, row, column]);

    const onClick = () => {
        setEditMode(true);
    };

    const onChange = useCallback((e) => {
        setValue(e.target.value);
        setDisplayValue(getDisplayValue(e.target.value));
    }, []);

    useEffect(() => {
        editInput.current?.focus();
    });

    useEffect(() => {
        setValue(oldValue);
        setDisplayValue(getDisplayValue(oldValue));
    }, [oldValue]);

    if (
        column.columnDef.meta?.editable !== true ||
        (table.options.meta?.cellEditOptions?.canSaveCell &&
            table.options.meta.cellEditOptions.canSaveCell(
                oldValue,
                row.original,
                column.columnDef
            ) !== true)
    ) {
        return <>{displayValue}</>;
    }

    const editControl = column.columnDef.meta?.customEditInput ? (
        column.columnDef.meta.customEditInput(cellContext)
    ) : (
        <Form.Control
            {...column.columnDef.meta?.editInputAttributes}
            ref={editInput}
            as="input"
            value={typeof value === 'number' ? value : (value as string)}
            onChange={onChange}
            onBlur={onBlur}
            className="editable-cell-input"
        />
    );

    return editMode ? (
        <>{editControl}</>
    ) : (
        <div
            onClick={onClick}
            onKeyPress={onClick}
            onFocus={onClick}
            role="button"
            className="editable-cell-value"
        >
            {displayValue}
        </div>
    );
};

export default EditTableCell;
