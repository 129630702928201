import React from 'react';
import { Button } from 'react-bootstrap';

import { faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<unknown>();

const ExpandToggleColumnDef = columnHelper.display({
    id: 'Expand',
    enableSorting: false,
    enableGlobalFilter: false,
    enableHiding: false,
    header: ({ table }) => (
        <Button variant="light" onClick={table.getToggleAllRowsExpandedHandler()}>
            <FontAwesomeIcon
                icon={table.getIsAllRowsExpanded() ? faChevronCircleDown : faChevronCircleRight}
            />
        </Button>
    ),
    cell: ({ row }) =>
        row.getCanExpand() ? (
            <Button variant="light" onClick={row.getToggleExpandedHandler()}>
                <FontAwesomeIcon
                    icon={row.getIsExpanded() ? faChevronCircleDown : faChevronCircleRight}
                />
            </Button>
        ) : null,
    meta: {
        columnClassName: 'text-center',
        columnStyle: { width: '1%' },
        cellClassName: 'text-center',
        csvExportIgnore: true
    }
});

export default ExpandToggleColumnDef;
