/**
 * Container for saved reports.
 *
 * @author Brandan D. Reed
 * @author Anthony P. Pancerella
 */
import React from 'react';
import TemplateReports from './TemplateReports';
import RDSavedReports from './RDSavedReports';

/**
 * The Reports module dashboard.  Contains report templates and saved reports.
 *
 * @returns {React.ReactNode} the rendered contents of the Reports module dashboard
 */
const ReportDashboard = () => (
    <>
        <TemplateReports />
        <RDSavedReports />
    </>
);

export default ReportDashboard;
