import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShortButton from 'Lib/ShortButton';

type Facility = { StationNumber: string };
type FacilitySelectButtonProps = { facility: Facility };

/**
 * A button that will open a facility report within the Facility Reports view of the Reports module.
 *
 * @param {object} props - props passed into this component
 * @returns {React.ReactNode} a React node
 */
const FacilitySelectButton: React.FC<FacilitySelectButtonProps> = ({ facility }) => {
    const { url } = useRouteMatch();

    return (
        <Link to={`${url}/${facility.StationNumber}`}>
            <ShortButton variant="success">
                <span>Select</span>
            </ShortButton>
        </Link>
    );
};

FacilitySelectButton.propTypes = {
    /** The facility selected to show a report. */
    facility: PropTypes.shape({
        /** The station number of the facility. */
        StationNumber: PropTypes.string.isRequired
    }).isRequired
};

export default FacilitySelectButton;
