import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

import ColumnVisibilityButton, { ColumnVisibilityButtonProps } from './ColumnVisibilityButton';
import ExportCSVButton, { ExportCSVButtonProps } from './ExportCSVButton';

export type ButtonActionProps = {
    isExportable?: boolean;
    showCsvPptBtnGroup?: boolean;
    btnRightFunc?: () => void;
    btnRightColor?: ButtonVariant;
    btnRightLabel?: string;
    showHideColumns?: boolean;
} & ColumnVisibilityButtonProps &
    Omit<ExportCSVButtonProps, 'table'>;

/**
 * Component for the buttons.
 *
 * @param props - The component properties.
 * @param props.csvExportFunc - The export event handler.
 * @param props.btnRightFunc - The right button event handler.
 * @param props.btnRightColor - The right button variant color.
 * @param props.btnRightLabel - The right button label content.
 * @param props.showCsvPptBtnGroup - Whether to show the extra button.
 * @param props.isExportable - Whether or not the table data is exportable.
 * @param props.showHideColumns - Whether or not to show the column visibility dropdown.
 * @param props.visibilityButtonText - The text of the visibility button.
 * @param props.visibilityButtonColor - The color of the visibility button.
 * @param props.table - The Tanstack table object.
 * @param props.csvFileName - The name of the CSV file to export to.
 * @returns - The component.
 */
const ButtonActions = ({
    btnRightFunc,
    btnRightColor = 'primary',
    btnRightLabel = '',
    showCsvPptBtnGroup = false,
    isExportable = false,
    showHideColumns = false,
    visibilityButtonText,
    visibilityButtonColor,
    table,
    csvFileName
}: ButtonActionProps): JSX.Element => {
    /**
     * Handles the Right button click event.
     */
    const btnRightHandleClick = () => {
        if (btnRightFunc) {
            btnRightFunc();
        }
    };

    return (
        <ButtonGroup>
            {isExportable === true ? (
                <ExportCSVButton table={table} csvFileName={csvFileName} />
            ) : null}
            {showCsvPptBtnGroup === true ? (
                <Button
                    variant={btnRightColor}
                    style={{ fontSize: 12 }}
                    onClick={btnRightHandleClick}
                >
                    {btnRightLabel}
                </Button>
            ) : null}
            {showHideColumns === true ? (
                <ColumnVisibilityButton
                    table={table}
                    visibilityButtonText={visibilityButtonText}
                    visibilityButtonColor={visibilityButtonColor}
                />
            ) : null}
        </ButtonGroup>
    );
};

export default ButtonActions;
