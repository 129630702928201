/**
 * Faq Modal.
 * @author Anthony P. Pancerella
 * @version August 27, 2018
 */

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

class SAFaqModal extends React.Component {
    render() {
        return (
            <div>
                <Modal size="lg" show={this.props.show} onHide={this.handleHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>FAQs</Modal.Title>
                    </Modal.Header>
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        General Questions
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseOne"
                                className="collapse show"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <strong>
                                                How do I determine the appropriate level of
                                                permission for different users?
                                            </strong>
                                        </li>
                                        <li>
                                            There are currently 5 levels of access: Administrator,
                                            VISN Administrator, CAM User, Read/Write Access, and
                                            Guest Access. The Administrator role is restricted to
                                            those who manage HSPA. VISN Planners have read/write
                                            authority for their respective VISN and read permission
                                            for all other VISNs. They are also authorized to add or
                                            edit users as VISN Planner, Read/Write (typically
                                            reserved for Facility Planners), CAM, or Guest Access.
                                            Guest Access only enables the user to view.
                                        </li>
                                        <li>
                                            <strong>Where do I go to add or edit a user?</strong>
                                        </li>
                                        <li>
                                            On the top right corner, click on your name. then
                                            “Manage Users” on the drop-down menu. Follow the
                                            bulleted instructions to add a new user or modify
                                            existing user(s). Please note that the user’s domain
                                            name is no longer a required entry.
                                        </li>
                                        <li>
                                            <strong>
                                                Where can I view the current list of VISN Planners
                                                and CSO Liaisons?
                                            </strong>
                                        </li>
                                        <li>
                                            It can be found on the CSO Intranet site at:{' '}
                                            <a
                                                href="http://vaww.va.gov/VHAOPP/10P1B_liaisons.asp"
                                                target="_blank"
                                            >
                                                http://vaww.va.gov/VHAOPP/10P1B_liaisons.asp
                                            </a>
                                        </li>
                                        <li>
                                            <strong>
                                                What are my options to obtain more information about
                                                HSPA’s features and functionality?
                                            </strong>
                                        </li>
                                        <li>
                                            New and seasoned users alike are encouraged to view the
                                            set of recorded tutorials, “Help” documents for each
                                            module, and live trainings that are offered following
                                            each major release. For questions that require
                                            additional research, please contact your VISN Planner or
                                            CSO Liaison. “Ask-a-Question” is another good way to
                                            reach the team. Most of these options are found on the
                                            drop-down menu that appears when you click on your name
                                            on the top right of the page.
                                        </li>
                                        <li>
                                            <strong>
                                                How can I export the tables and charts in HSPA?
                                            </strong>
                                        </li>
                                        <li>
                                            Most tables and charts can, at minimum, be exported to
                                            Excel. The cards under Strategic Outlook, as well as the
                                            chart found on Match Capacity/Demand, can be downloaded
                                            as JPEG, PNG, PDF, and SVG. The tabulated data in
                                            Understand Demand and Understand Capacity can be
                                            exported as CSV. Embedded Power BI reports can be
                                            exported by clicking on the ellipses.
                                        </li>
                                        <li>
                                            <strong>
                                                What are HSPCs, RVUs, and Utilization and how are
                                                they related to each other?
                                            </strong>
                                        </li>
                                        <li>
                                            VA uses the Enrollee Health Care Projection Model
                                            (EHCPM) to project Veteran enrollment, enrolled
                                            Veterans’ use of health care services, and the cost of
                                            providing these services. The utilization projections,
                                            which are based on modeling assumptions and historical
                                            data, are structured as a set of Health Systems Planning
                                            Categories (HSPCs) - categories of workload that allow
                                            capture of data without limitations to where the care is
                                            provided. They are rolled up into Health Systems
                                            Planning Groups (HSPGs) to facilitate summarization of
                                            projections. Utilization is a unit of measure for
                                            workload, and is shown in different types. The units
                                            include Bed Days of Care for inpatient care, clinic
                                            stops for some outpatient care, as well as procedures,
                                            services, and visits. Global RVUs (reflect differences
                                            in workload mix and intensity, and cover the total
                                            resource requirements for each health care service, as
                                            well as the professional resource requirements. Because
                                            the EHCPM Global RVUs assign a consistent resource unit
                                            to all health care services, health care services of
                                            unequal intensity (e.g., immunizations and surgeries)
                                            can be aggregated and compared on an equivalent basis.
                                            It also facilitates an accurate comparison of workload
                                            between VA facility and community care and between
                                            different geographic areas (e.g., Community Care Network
                                            contract regions, VISNs, facilities). WRVUs better
                                            represent the growth/decline in workload than
                                            utilization counts. In fact, WRVUs generally grow or
                                            decline at a different rate than utilization for the
                                            same service. WRVUs can also be used for translating
                                            utilization projections into professional staffing
                                            requirements.
                                        </li>
                                        <li>
                                            <strong>
                                                What is the difference between Demand, Capacity, and
                                                Supply?
                                            </strong>
                                        </li>
                                        <li>
                                            <p>
                                                In the Match module HSPA uses all three concepts for
                                                Demand, Capacity and Supply in one chart and in the
                                                supporting tables. These factors are used to
                                                understand the overall delivery of healthcare within
                                                a market and at specific facilities, with each
                                                factor offering a different view of the same system.
                                            </p>
                                            <p>
                                                The Demand data refers to the quantity of healthcare
                                                services, within an HSPC that Veterans are expected
                                                to need at that projected year. The HSPA demand data
                                                originates with the Enrollee Healthcare Projection
                                                Model (EHCPM) and is reported by HSPC for each
                                                facility. All the facility level data is then
                                                aggregated to calculate Market level Demand for each
                                                HSPC in all projected years. In the Match module
                                                this is represented as a solid line, and in the
                                                first table. These values are read-only as we cannot
                                                change the expected demand for healthcare services.
                                            </p>
                                            <p>
                                                The Capacity data refers to the maximum computed
                                                output of VA in-house healthcare services for each
                                                HSPC. It can be viewed as maximum potential output
                                                assuming all resources are utilized efficiently.
                                                There are three different types of Capacity
                                                calculations in HSPA – Primary Care (Panel
                                                Capacity), Specialty Care (RVUs calculated from
                                                FTEs, complexity ratings and productivity standards)
                                                and Inpatient Care (Bed Days of Care). Each of these
                                                calculations are based on data pulled for the last
                                                actual completed fiscal year for each facility and
                                                then aggregated to HSPC and Market levels. The Match
                                                module represents this data as the dashed line and
                                                the 2nd section in the supporting table. The
                                                Capacity data is editable in the Understand Capacity
                                                module as well as in the Match module.
                                            </p>
                                            <p>
                                                The Supply data refers to the quantity of an HSPC
                                                that VA intends to deliver at specific facility. It
                                                can be compared directly to demand to plan how care
                                                is delivered across all internal and external
                                                providers in a market to best meet that demand for
                                                care. Supply data includes both in-house care at VA
                                                facilities as well as Community Care, Federally
                                                Qualified Health Centers (FQHCs), Academic
                                                Affiliates, Military Treatment Facilities and Indian
                                                Health Services. In the Match module this is
                                                represented as a stacked bar chart and in the third
                                                table below it. These values are editable within the
                                                Match module, and it is recommended that users
                                                review, analyze and update these to best plan how
                                                they intend to deliver services most effectively to
                                                meet the Demand for care.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        Strategic Outlook
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseTwo"
                                className="collapse"
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <strong>
                                                How are the cards under the Enrollment and Demand
                                                sections organized, and what do the colors signify?
                                            </strong>
                                        </li>
                                        <li>
                                            The cards are shown by established populations
                                            (enrollees, eligibles, female enrollees, and post-9/11
                                            enrollees), as well as HSPC Groups. Clicking on
                                            individual cards will allow the user to view additional
                                            information, such as age and priority groups for
                                            enrollees, and HSPC and Points of Service for
                                            utilization. There are two colors – blue for those
                                            within a 10% change of the base year (in either
                                            direction), and yellow for those with greater than 10%
                                            change.
                                        </li>
                                        <li>
                                            <strong>
                                                Is the VISN/National DD&F the same as the
                                                market-based DD&F used during the Market
                                                Assessments?
                                            </strong>
                                        </li>
                                        <li>
                                            The National DD&F Report found in the Strategic Outlook
                                            module is similar to the market-based DD&F used during
                                            the Market Assessment; however, it has several key
                                            differences. 1) As the name suggests, this data is shown
                                            at the National level with dynamic filtering available
                                            to view slides for VISN level as well. Market based
                                            slides are not included in the filtering. 2) Some of the
                                            data found in the National DD&F Report is updated from
                                            source systems including the Enrollee Healthcare
                                            Projection Model (EHCPM) when new data becomes
                                            available. The Market based DD&F are point-in-time PDF
                                            reports that are not automatically updated. 3) The
                                            National DD&F Report does not contain all of the Market
                                            based DD&F slides. Many of the slides found in the
                                            market based DD&F could not be aggregated from Facility
                                            and Market levels to the National level. When possible,
                                            the National DD&F Report preserved the intent of the
                                            slide, but modified the layout. In other cases, the
                                            market-based slides were not reproduced at the National
                                            level.
                                        </li>
                                        <li>
                                            <strong>
                                                How do I delete a Capital Project? How do I delete a
                                                Match Plan?
                                            </strong>
                                        </li>
                                        <li>
                                            Nomenclature for capital projects that have been created
                                            using HSPA (and have not been entered in SCIP) begin
                                            with “HSPA” – three additional columns on the far right
                                            give the user the option to view the linked HSPC plans
                                            that were created in the Match Capacity/Demand module,
                                            review, and delete. To delete a HSPC plan, the user
                                            needs to open the plan in the Match module and scroll to
                                            the bottom of the workload page, where the “Delete Plan”
                                            button can be found.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        Environmental Scan
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <strong>
                                                Who can write the text for VISN goals, mission,
                                                values, and vision?
                                            </strong>
                                        </li>
                                        <li>
                                            The text can be written and edited by any VISN user.
                                            Once written, the language is shown to be applicable to
                                            all markets within the VISN.
                                        </li>
                                        <li>
                                            <strong>
                                                What is the source of Veteran population and
                                                enrollment data?
                                            </strong>
                                        </li>
                                        <li>
                                            HSPA uses the Enrollee Health Care Projection Model
                                            (EHCPM), which projects enrollment, utilization, and
                                            expenditures for the enrolled Veteran population for 148
                                            categories of health care services 20 years into the
                                            future. First, the model determines how many Veterans
                                            will be enrolled in VA in each projection year and their
                                            age, priority, and geographic location. Next, the model
                                            projects the total health care services needed by those
                                            enrollees and then estimates the portion of that care
                                            those enrollees will demand from VA. The most recently
                                            updated data definitions can be found in the Understand
                                            Demand module.
                                        </li>
                                        <li>
                                            <strong>
                                                Why doesn’t the total enrollee count Reported for my
                                                VISN/Market in the Demographics Data Reports and
                                                Special Population Data Reports match the enrollee
                                                count reported in the Rurality Data?
                                            </strong>
                                        </li>
                                        <li>
                                            The data from these tables come from two different
                                            sources that use slightly different methodologies for
                                            counting enrollees. Demographic and Special Population
                                            data are derived from the EHCPM. Rurality data come from
                                            the Geocoded Enrollee File. Enrollee counts in the
                                            Demographic and Special Population tables are captured
                                            at the end of each fiscal year, whereas the rurality
                                            tables display counts for the total Veterans enrolled at
                                            any time during the fiscal year.
                                        </li>
                                        <li>
                                            <strong>
                                                Am I able to see the non-VA sites of care on the GIS
                                                map found in Geography & Rurality?
                                            </strong>
                                        </li>
                                        <li>
                                            Yes. The layers icon on the right side of the map can be
                                            used to view the non-VHA medical facilities, including
                                            private hospitals, ambulatory surgical centers, DoD
                                            MTFs, FQHCs, and IHS facilities.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                    >
                                        Understand Demand
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseFour"
                                className="collapse"
                                aria-labelledby="headingFour"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <strong>
                                                What if the data and maps provided by HSPA are not
                                                adequate to explain a particular gap?
                                            </strong>
                                        </li>
                                        <li>
                                            The information found in HSPA is to serve as a
                                            launch-point, and the users are encouraged to conduct an
                                            extensive analysis that takes into consideration certain
                                            market-specific attributes and solution processes. For
                                            more data granularity, please use the drill-down option.
                                        </li>
                                        <li>
                                            <strong>
                                                It appears that certain projected utilizations
                                                differ from last year’s data. How should I
                                                understand these differences?
                                            </strong>
                                        </li>
                                        <li>
                                            Each year’s model uses different base years, with
                                            adjusted assumptions and environmental factors. Please
                                            review the model documentation and key drivers that
                                            explain any substantial shifts in the trending data.
                                        </li>
                                        <li>
                                            <strong>
                                                Why don’t the workload values associated with my
                                                facility match the numbers I pull from the Projected
                                                Utilization by Parent Facility cube available via
                                                VSSC?
                                            </strong>
                                        </li>
                                        <li>
                                            The Projected Utilization by Parent Facility cube
                                            captures workload that may be consumed by patients
                                            traveling from markets outside where the facility is
                                            located. HSPA is designed to enable identification of
                                            health care needs of Veterans at a market-level. As it
                                            shows demand for care based on where the patient lives,
                                            not which facility that patient seeks care, the focus is
                                            on the utilization of services within a given geographic
                                            unit. Market/geographic projections are generally more
                                            appropriate for considering the future needs of a
                                            system, such as planning for new facilities in locations
                                            without a VA presence. Once the differentiating factor
                                            is understood, planner can better examine the facility
                                            data for any specific market by drilling down to the
                                            desired level of geography.
                                        </li>
                                        <li>
                                            <strong>
                                                How can I study trends in health care demand by
                                                enrollees living within our market?
                                            </strong>
                                        </li>
                                        <li>
                                            HSPA enables the user to review projected trends in
                                            health care demand in any given market (or VISN) and
                                            understand the future health care supply needs in terms
                                            of how we are to deliver or distribute the care. The
                                            market utilization data is provided at the level of
                                            HSPC, and can be analyzed by funding source (in-house
                                            vs. community). The base years provide the actual data,
                                            and the model projects future utilization over the
                                            period of 20 fiscal years by making explicit assumptions
                                            about how specific utilization patterns may differ from
                                            current patterns under various scenarios.
                                        </li>
                                        <li>
                                            <strong>
                                                How do I determine the amount of workload/demand
                                                that may be coming from places outside my market?
                                            </strong>
                                        </li>
                                        <li>
                                            The Patient Origin Tool shows where the Veterans are
                                            coming from to access care in the selected market of
                                            study, at the HSPC Group level. The table displays the
                                            baseline, 5-, 10-, and 20-year projected values.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingFive">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                    >
                                        Understand Capacity
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseFive"
                                className="collapse"
                                aria-labelledby="headingFive"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <strong>
                                                Will the providers I add in this module populate
                                                other sections of HSPA?
                                            </strong>
                                        </li>
                                        <li>
                                            Yes – the providers entered in the Add/Edit Provider
                                            section will auto-populate other relevant parts of HSPA.
                                            This feature is especially helpful in the Match
                                            Capacity/Demand module, where the list of providers will
                                            be available for you to allocate workload to as a
                                            component of the plan developed.
                                        </li>
                                        <li>
                                            <strong>
                                                Where do all the providers that are already
                                                “registered” in HSPA come from?
                                            </strong>
                                        </li>
                                        <li>
                                            The provider information comes from various sources,
                                            including HHS, IHS, DoD and the VHA Office of Academic
                                            Affiliations.
                                        </li>
                                        <li>
                                            <strong>How are the bed counts derived?</strong>
                                        </li>
                                        <li>
                                            We apply 85% occupancy rate for acute inpatient services
                                            (Med/Surg and MH) and IRR, and 95% for subacute
                                            inpatient services (SCI, Blind Rehabilitation, and LTSS)
                                            to calculate the required beds from the bed projections.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingSix">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseSix"
                                        aria-expanded="false"
                                        aria-controls="collapseSix"
                                    >
                                        Match Capacity/Demand
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseSix"
                                className="collapse"
                                aria-labelledby="headingSix"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <strong>
                                                How do I create a HSPC plan, and how does it link to
                                                a Capital plan I want to create?
                                            </strong>
                                        </li>
                                        <li>
                                            You have to first create a plan for a particular HSPC,
                                            which can be done by first clicking on the green “Create
                                            Plan” button at the top, then using the drop-down menu
                                            and selecting a category. Once you have one or more
                                            plans for any given category, select the final option
                                            and provide justification in the text box. This will
                                            prompt you to create a new capital project.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
    handleHide = () => {
        this.props.faqModalToggleShow();
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        faqModalToggleShow: () => dispatch.FaqModel.toggle()
    };
};

const mapStateToProps = (state) => ({
    show: state.FaqModel.show
});

export default connect(mapStateToProps, mapDispatchToProps)(SAFaqModal);
