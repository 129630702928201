import React from 'react';
import { ListGroup, OverlayTrigger, Popover, PopoverContent, PopoverTitle } from 'react-bootstrap';

import _map from 'lodash/map';

import { CapitalProjectDTO, shortenDescription } from 'Utilities/types';

import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    capitalProjects: CapitalProjectDTO[];
    planCapacityId: number;
};

/**
 * Component that defines an overlay for the Capital Projects.
 *
 * @author - Benjamin Oravetz
 * @param props - The properties passed into the component.
 * @param props.capitalProjects - The list of capital projects to display in the popover.
 * @param props.planCapacityId - The plan capacity identifier.
 * @param props.placement - The placement of the overlay.
 * @returns - The component to display.
 */
const CapitalProjectOverlay = ({ capitalProjects, planCapacityId }: Props): JSX.Element => {
    const list = _map(capitalProjects, (project) => (
        <ListGroup.Item>
            <h6>{project.ProjectNumber}</h6>
            <p>{shortenDescription(project.Description || '', 50)}</p>
        </ListGroup.Item>
    ));

    return (
        <OverlayTrigger
            key={`overlay-${planCapacityId}`}
            placement="right"
            delay={{ show: 100, hide: 1000 }}
            overlay={
                <Popover id={`popover-${planCapacityId}`}>
                    <PopoverTitle as="h3">Related Capital Projects</PopoverTitle>
                    <PopoverContent>
                        <ListGroup variant="flush">{list}</ListGroup>
                    </PopoverContent>
                </Popover>
            }
        >
            <span>
                <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faHardHat} />
            </span>
        </OverlayTrigger>
    );
};

export default CapitalProjectOverlay;
