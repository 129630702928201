import './style.css';

import React, { useState } from 'react';
import Pagination from 'react-js-pagination';

import PropTypes from 'prop-types';

import MetricCard from '../MetricCard';

const SOMetricGroup = React.memo(
    ({ id = 0, metrics = [], market = '', toggleModalFunc = null }) => {
        const [activePage, setActivePage] = useState(1);
        const cards = metrics.map((item, i) => {
            let upperBound = activePage * 5;
            let lowerBound = (activePage - 1) * 5;

            return i >= lowerBound && i <= upperBound ? (
                <MetricCard key={item.id} metric={item} onClick={toggleModalFunc} />
            ) : null;
        });
        return (
            <React.Fragment key={id}>
                <div className={'col-xs-12 metricGroupContainer'} hidden={!metrics.length}>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{market}</h2>
                    </div>
                    <div className="col-xs-12 cardContainer">{cards}</div>
                    <div className="col-xs-12 paginationContainer">
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={5}
                            totalItemsCount={metrics.length}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={(pageNum) => setActivePage(pageNum)}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
);

SOMetricGroup.propTypes = {
    id: PropTypes.number.isRequired,
    metrics: PropTypes.array.isRequired,
    market: PropTypes.string.isRequired,
    toggleModalFunc: PropTypes.func.isRequired
};

export default SOMetricGroup;
