/**
 * Market table for reviewing projected demand in the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import _map from 'lodash/map';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { makeGetReviewProjectedDemand } from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentMarketName, getDefaultYearObject } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import ExpandTable from './ExpandTable';
import MarketColumns from './MarketColumns';

// TODO: Fix ESLint, Use react-bootstrap components
class UDRPDMarketTable extends React.Component {
    static propTypes = {
        filter: PropTypes.string.isRequired,
        isExpandable: PropTypes.bool.isRequired,
        exportName: PropTypes.string.isRequired,
        tableType: PropTypes.string.isRequired,
        expandTableType: PropTypes.string,
        defaultYear: PropTypes.shape({ twoYearActual: PropTypes.string.isRequired, oneYearActual: PropTypes.string.isRequired }).isRequired
    };

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    isExpandableRow = (row) => {
        if (!this.props.isExpandable) {
            return false;
        }
        return row.IsExpandable;
    };

    expandComponent = (row) => {
        const dataArray = this.props.expandTableData.list.filter(
            (x) => x.HspcGroup === row.HspcGroup
        );
        return (
            <ExpandTable
                data={dataArray}
                defaultYear={this.props.defaultYear}
                tableType={this.props.tableType}
            />
        );
    };

    rowClassNameFormat(row) {
        const outOfMarketStyle = {
            backgroundColor: '#adc8da'
        };
        return this.props.marketName !== row.Facility ? outOfMarketStyle : '';
    }

    patientOriginModalBtn(cell, row, enumObject, rowIndex) {
        return (
            <Button
                bsStyle={row.FacilityMarket === this.props.marketName ? 'primary' : 'warning'}
                bsSize="small"
                style={{ paddingTop: 1, paddingBottom: 1 }}
                onClick={() => {
                    this.props.toggleFacilityModal(
                        row.Facility,
                        row.HspcGroup,
                        row.FacilityMarket,
                        row.WorkloadType
                    );
                }}
            >
                &nbsp;
                {row.FacilityMarket === this.props.marketName
                    ? `${row.Facility.replace(/\(.*?\)/g, '')} - In Market`
                    : `${row.Facility.replace(/\(.*?\)/g, '')} - Out of Market`}
            </Button>
        );
    }

    render() {
        const { defaultYear } = this.props;

        const { twoYearActual } = defaultYear;
        const twoYearTotal = `${twoYearActual}-total`;
        const twoYearIn = `${twoYearActual}-inKey`;
        const twoYearOut = `${twoYearActual}-outKey`;
        const twoYearPurchased = `${twoYearActual}-purchased`;
        const { oneYearActual } = defaultYear;
        const oneYearTotal = `${oneYearActual}-total`;
        const oneYearIn = `${oneYearActual}-inKey`;
        const oneYearOut = `${oneYearActual}-outKey`;
        const oneYearPurchased = `${oneYearActual}-purchased`;

        const defaultVisibility = {};
        defaultVisibility[twoYearActual] = false;
        defaultVisibility[twoYearTotal] = false;
        defaultVisibility[twoYearIn] = false;
        defaultVisibility[twoYearOut] = false;
        defaultVisibility[twoYearPurchased] = false;
        defaultVisibility[oneYearActual] = false;
        defaultVisibility[oneYearTotal] = false;
        defaultVisibility[oneYearIn] = false;
        defaultVisibility[oneYearOut] = false;
        defaultVisibility[oneYearPurchased] = false;

        const expandContent = this.props.isExpandable === true ? {
            canRender: this.isExpandableRow,
            renderer: this.expandComponent
        } : null;

        const columns = MarketColumns(defaultYear, this.props.filter);

        return (
            <div ref={this.tableRef}>
                <ReactDataTable
                    keyValue="Id"
                    columns={columns}
                    list={this.props.tableData.list}
                    loading={this.props.tableData.loading}
                    csvFileName={this.props.marketName + this.props.exportName}
                    showHideColumns
                    defaultVisibility={defaultVisibility}
                    expandContent={expandContent}
                />
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    toggleFacilityModal: (facility, mpc, market, workloadType) =>
        dispatch.ReviewProjectedDemandModel.togglePOModal({
            facility,
            mpc,
            market,
            workloadType
        })
});
const makeMapStateToProps = () => {
    const getReviewProjectedDemand = makeGetReviewProjectedDemand();
    const mapStateToProps = (state, props) => ({
        marketName: getCurrentMarketName(state),
        defaultYear: getDefaultYearObject(state),
        tableData: getReviewProjectedDemand(state, props.filter),
        expandTableData: getReviewProjectedDemand(state, props.expandTableType)
    });
    return mapStateToProps;
};
export default connect(makeMapStateToProps, mapDispatchToProps)(UDRPDMarketTable);
