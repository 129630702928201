import { downloadFile, getJSON } from 'Utilities/apiCalls';
import { VideoTutorial } from 'Utilities/types';

if (typeof process.env.REACT_APP_HSPA_API === 'undefined') {
    throw new Error('REACT_APP_HSPA_API environment variable should be defined.');
}

const EXPORT_API_URL = `${process.env.REACT_APP_HSPA_API}/Export`;

/**
 * Download the HSPA User Instructions.
 */
export const downloadUserInstructions = async (): Promise<void> =>
    downloadFile(`${EXPORT_API_URL}/GetUserInstructions`);

/**
 * Download the VA Core Values and Characteristics document.
 */
export const downloadVACoreValuesAndCharacteristics = async (): Promise<void> =>
    downloadFile(`${EXPORT_API_URL}/GetVaCoreValuesAndCharacteristicsPdf`);

/**
 * Download the HSPA Data Definitions document.
 */
export const downloadDataDefinitions = async (): Promise<void> =>
    downloadFile(`${EXPORT_API_URL}/GetDataDefinitions`);

/**
 * Retrieves video tutorials from the server.
 * @returns A promise that resolves to an array of VideoTutorial objects.
 */
export const getVideoTutorials = async (): Promise<VideoTutorial[]> => {
    const videos = await getJSON(`${EXPORT_API_URL}/GetVideoTraining`);
    return videos as VideoTutorial[];
};
