/**
 * An epic to request the facilities in a market and emit actions to update the redux state.
 *
 * @author Brandan D. Reed
 */
import shallowEqual from 'shallowequal';
import { from, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import epicRequest from 'Utilities/epicRequest';
import { getFacilitiesByMarket } from 'Modules/Report/Data/FacilityReport';
import {
    setFacilities,
    loadFacilities as loadFacilitiesAction,
    loadFacilitiesStart,
    loadFacilitiesDone,
    loadFacilitiesError
} from '../slice';

/**
 * When a loadFacilities action is emitted to the store, this epic makes a request to get the list
 * of the market's facilities.  The list of facilities sent back is then emitted as the payload for
 * a setFacilities redux action.
 * Any loadFacilities actions whose payload matches the payload of the previous loadFacilities
 * action are ignored.
 *
 * @param {Observable} action$ - stream of actions dispatched to the Redux store
 * @returns {Observable} actions emitted by this epic
 */
const loadFacilities = (action$) =>
    action$.pipe(
        ofType(loadFacilitiesAction),
        distinctUntilChanged((previous, current) =>
            shallowEqual(previous.payload, current.payload)
        ),
        epicRequest({
            request: ({ payload }) =>
                from(getFacilitiesByMarket(payload.marketName, payload.year)).pipe(
                    switchMap((facilities) => of(setFacilities(facilities)))
                ),
            actions: {
                start: loadFacilitiesStart,
                done: loadFacilitiesDone,
                fail: loadFacilitiesError
            }
        })
    );

export default loadFacilities;
