import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON, putJSON } from 'Utilities/apiCalls';

const capacityApi = `${process.env.REACT_APP_HSPA_API}/UnderstandCapacity/`;

export const InHouseCapacityModel = {
    state: {
        allCapacity: { list: [], loading: false },
        inHousePrimaryCare: { list: [], loading: false },
        inHouseNonPrimaryCare: { list: [], loading: false },
        ambulatoryCare: { list: [], loading: false },
        operatingBedsModal: { show: false, form: {}, saving: false },
        ambulatoryCareModal: { show: false, form: {}, saving: false },
        primaryCareModal: { show: false, form: {}, saving: false }
    },
    reducers: {
        populate(state, data, objectName) {
            return {
                ...state,
                [objectName]: {
                    ...state[objectName],
                    list: data
                }
            };
        },
        toggleLoading(state, objectName) {
            return {
                ...state,
                [objectName]: {
                    ...state[objectName],
                    loading: !state[objectName].loading
                }
            };
        },
        toggleModal(state, modalName) {
            return {
                ...state,
                [modalName]: {
                    ...state[modalName],
                    show: !state[modalName].show
                }
            };
        },
        toggleSaving(state, modalName) {
            return {
                ...state,
                [modalName]: {
                    ...state[modalName],
                    saving: !state[modalName].saving
                }
            };
        },
        resetTable(state, objectName) {
            return {
                ...state,
                [objectName]: {
                    ...state[objectName],
                    list: []
                }
            };
        },
        resetModalForm(state, modalName) {
            return {
                ...state,
                [modalName]: {
                    ...state[modalName],
                    form: {}
                }
            };
        },
        reset(state) {
            return {
                allCapacity: { list: [], loading: false },
                inHousePrimaryCare: { list: [], loading: false },
                inHouseNonPrimaryCare: { list: [], loading: false },
                ambulatoryCare: { list: [], loading: false },
                operatingBedsModal: { show: false, form: {}, saving: false },
                ambulatoryCareModal: { show: false, form: {}, saving: false },
                primaryCareModal: { show: false, form: {}, saving: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchPrimaryCareAsync(market, state) {
            try {
                this.resetTable('inHousePrimaryCare');
                this.toggleLoading('inHousePrimaryCare');
                let result = await getJSON(
                    `${capacityApi}GetPrimaryCareCapacityByMarket?marketname=${market}`
                );
                this.populate(result, 'inHousePrimaryCare');
                this.toggleLoading('inHousePrimaryCare');
            } catch (error) {
                this.toggleLoading('inHousePrimaryCare');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch primary care capacities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchNonPrimaryCareAsync(market, state) {
            try {
                this.resetTable('inHouseNonPrimaryCare');
                this.toggleLoading('inHouseNonPrimaryCare');
                let result = await getJSON(
                    `${capacityApi}GetCapacityByMarketGroupByHspcGroup?marketname=${market}`
                );
                this.populate(result, 'inHouseNonPrimaryCare');
                this.toggleLoading('inHouseNonPrimaryCare');
            } catch (error) {
                this.toggleLoading('inHousePrimaryCare');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch non-primary care capacities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchAllCapacityAsync(market, state) {
            try {
                this.resetTable('allCapacity');
                this.toggleLoading('allCapacity');
                let result = await getJSON(
                    `${capacityApi}GetAllCapacityByMarket?marketname=${market}`
                );
                this.populate(result, 'allCapacity');
                this.toggleLoading('allCapacity');
            } catch (error) {
                this.toggleLoading('allCapacity');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch in-house capacities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchAmbulatoryCareAsync(market, state) {
            try {
                this.resetTable('ambulatoryCare');
                this.toggleLoading('ambulatoryCare');
                let result = await getJSON(
                    `${capacityApi}GetAmbulatoryCapacityByMarket?marketname=${market}`
                );
                this.populate(result, 'ambulatoryCare');
                this.toggleLoading('ambulatoryCare');
            } catch (error) {
                this.toggleLoading('ambulatoryCare');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch ambulatory care capacities',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveOperatingBedsAsync(payload) {
            try {
                this.toggleSaving('operatingBedsModal');
                await putJSON(`${capacityApi}UpdateOperatingBeds`, payload.formValues);
                this.toggleSaving('operatingBedsModal');
                dispatch(
                    addNotification({
                        message: 'Operating beds were updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchAllCapacityAsync(payload.market);
            } catch (error) {
                this.toggleSaving('operatingBedsModal');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to update operating beds',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveAmbulatoryCareAsync(payload) {
            try {
                this.toggleSaving('ambulatoryCareModal');
                await putJSON(`${capacityApi}UpdateAmbulatoryCare`, payload.formValues);
                this.toggleSaving('ambulatoryCareModal');
                dispatch(
                    addNotification({
                        message: 'Ambulatory care values were updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchAmbulatoryCareAsync(payload.market);
            } catch (error) {
                this.toggleSaving('ambulatoryCareModal');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to update ambulatory care',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async savePrimaryCareAsync(payload) {
            try {
                this.toggleSaving('primaryCareModal');
                await putJSON(`${capacityApi}UpdatePrimaryCare`, payload.formValues);
                this.toggleSaving('primaryCareModal');
                dispatch(
                    addNotification({
                        message: 'Primary care values were updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchPrimaryCareAsync(payload.market);
            } catch (error) {
                this.toggleSaving('primaryCareModal');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to update primary care',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
