/**
 * Instructions for the Users in Network form.
 *
 * @author Brandan D. Reed
 */
import React from 'react';

const bulletedItems = [
    <li>
        To <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>add</span> a User who
        has not been previously given access to HSPA, simply fill out the form on the “Add/Edit
        Users” Tab and click the <strong>Insert</strong> button. You will need to provide all
        information.
    </li>,
    <li>
        To <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>modify</span> a user
        simply click the <strong>Edit</strong> button on the “Manage Users” Tab and the form will be
        populated with the selected user&apos;s information. Once you make your changes, click the{' '}
        <strong>Update</strong> button and your changes will be saved.
    </li>,
    <li>
        To grant access to the VISN for an Existing User (i.e. a User who already has access to HSPA
        in some manner), select the User from the appropriate drop-down box at the bottom of the
        &quot;Manage Users&quot; Tab and then the role for your particular VISN that you have
        selected for that User from the “User Role”. Once you have made your selections, click the{' '}
        <strong>Save</strong> button.
    </li>
];

/**
 * Output instructions to display in the Users in Network form.
 *
 * @returns {React.ReactNode} the rendered component
 */
const Instructions = () => (
    <div>
        As a VISN Administrator, you can manage users in your network with the tabs on the left.
        <ul>{bulletedItems}</ul>
        <span style={{ fontSize: 'smaller' }}>
            Note: The username must exactly match the user’s address for logging in (i.e.,
            jsmith@va.gov)
        </span>
    </div>
);

export default Instructions;
