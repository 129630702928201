import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from './AppDispatch';
import { RootState } from './RootState';

/**
 * Provides the redux dispatch function.  Use this instead of the normal useDispatch to support type
 * information for dispatch and the redux state.
 *
 * @returns a typed dispatch function
 */
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
