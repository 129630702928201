/**
 * Form to update available providers for VISN.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */

import React from 'react';
import { connect } from 'react-redux';

import Button from 'Lib/Button';
import CheckboxField from 'Lib/ReduxFormFields/CheckboxField';
import DropdownField from 'Lib/ReduxFormFields/DropdownField';
import MultiSelectField from 'Lib/ReduxFormFields/MultiSelectField';
import RadioGroupField from 'Lib/ReduxFormFields/RadioGroupField';
import TextboxField from 'Lib/ReduxFormFields/TextboxField';
import {
    getDrpListsSelector,
    getFacilityTypesSelector,
    getProviderFormSelector,
    getProviderTypesSelector
} from 'Modules/UnderstandCapacity/Services/selector';
import { getGapMpcTypesSelector } from 'Modules/UnderstandDemand/Services/selector';
import { canEditVISN, getCurrentMarketName, getCurrentVISN } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import {
    change,
    Form,
    formValueSelector,
    getFormValues,
    reduxForm,
    reset,
    untouch
} from 'redux-form';

import UCMPFacilityTable from './UCMPFacilityTable';

class UCManageProvidersForm extends React.Component {
    static propTypes = {
        initialValues: PropTypes.shape({
            FacilityType: PropTypes.string,
            State: PropTypes.string,
            County: PropTypes.string,
            City: PropTypes.string,
            MarketName: PropTypes.string,
            Id: PropTypes.number
        }).isRequired,
        gapMpcTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        facilityTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        providerTypes: PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({})),
            loading: PropTypes.bool
        }).isRequired,
        drpLists: PropTypes.shape({
            fqhc: PropTypes.shape({
                states: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                counties: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                grid: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({}))
                })
            }),
            ihs: PropTypes.shape({
                states: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                grid: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({}))
                })
            }),
            dod: PropTypes.shape({
                states: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({}))
                }),
                grid: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({}))
                })
            }),
            cp: PropTypes.shape({
                states: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                cities: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                specialties: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                grid: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({}))
                })
            }),
            aa: PropTypes.shape({
                states: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({})),
                    loading: PropTypes.bool
                }),
                grid: PropTypes.shape({
                    list: PropTypes.arrayOf(PropTypes.shape({}))
                })
            })
        }).isRequired,
        currentFacilityType: PropTypes.string.isRequired,
        currentCity: PropTypes.string.isRequired,
        currentCounty: PropTypes.string.isRequired,
        currentSpecialty: PropTypes.string.isRequired,
        currentState: PropTypes.string.isRequired,
        currentProvider: PropTypes.string.isRequired,
        marketName: PropTypes.string.isRequired,
        matchPlanner: PropTypes.shape({
            AddProviderModal: PropTypes.shape({
                show: PropTypes.bool
            }),
            Plan: PropTypes.shape({
                planData: PropTypes.shape({
                    Plan: PropTypes.shape({
                        Id: PropTypes.number
                    })
                })
            })
        }).isRequired,
        loading: PropTypes.bool,
        saving: PropTypes.bool,
        submitting: PropTypes.bool,
        pristine: PropTypes.bool,
        canEdit: PropTypes.bool,
        reset: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        saveProvider: PropTypes.func.isRequired,
        getGapMpcTypes: PropTypes.func.isRequired,
        facilityTypesFetchList: PropTypes.func.isRequired,
        providerTypesFetchList: PropTypes.func.isRequired,
        fqhcCountiesFetchList: PropTypes.func.isRequired,
        allIHSByStateFetchList: PropTypes.func.isRequired,
        facilityStatesFetchList: PropTypes.func.isRequired,
        fqhcFacilitiesFetchList: PropTypes.func.isRequired,
        dodInstallationsFetchList: PropTypes.func.isRequired,
        cpCitiesFetchList: PropTypes.func.isRequired,
        vaSpecialtiesFetchList: PropTypes.func.isRequired,
        academicAffiliationsFetchList: PropTypes.func.isRequired,
        communityProviderFetchList: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        clearCommunityProviderGrid: PropTypes.func.isRequired,
        clearProviderForm: PropTypes.func.isRequired,
        autofill: PropTypes.func.isRequired
    };

    static defaultProps = {
        loading: false,
        saving: false,
        submitting: false,
        pristine: true,
        canEdit: false
    };

    componentWillMount() {
        const {
            gapMpcTypes,
            facilityTypes,
            providerTypes,
            initialValues,
            drpLists,
            getGapMpcTypes,
            facilityTypesFetchList,
            providerTypesFetchList,
            facilityStatesFetchList,
            fqhcCountiesFetchList,
            fqhcFacilitiesFetchList,
            allIHSByStateFetchList,
            dodInstallationsFetchList,
            cpCitiesFetchList,
            vaSpecialtiesFetchList,
            academicAffiliationsFetchList
        } = this.props;

        this.facilityInGridId = 0;
        if (gapMpcTypes.list.length === 0) {
            getGapMpcTypes();
        }
        if (facilityTypes.list.length === 0) {
            facilityTypesFetchList();
        }
        if (providerTypes.list.length === 0) {
            providerTypesFetchList();
        }

        const { FacilityType } = initialValues;
        if (initialValues !== {}) {
            if (FacilityType === 'FQHC' && drpLists.fqhc.states.list.length === 0) {
                facilityStatesFetchList('fqhc');
                if (initialValues.State && drpLists.fqhc.counties.list.length === 0) {
                    fqhcCountiesFetchList(initialValues.State);
                    fqhcFacilitiesFetchList(initialValues.State, initialValues.County);
                }
            } else if (FacilityType === 'IHS' && drpLists.ihs.states.list.length === 0) {
                facilityStatesFetchList('ihs');
                if (initialValues.State && drpLists.ihs.grid.list.l) {
                    allIHSByStateFetchList(initialValues.State);
                }
            } else if (FacilityType === 'DoD' && drpLists.dod.states.list.length === 0) {
                facilityStatesFetchList('dod');
                dodInstallationsFetchList(initialValues.State);
            } else if (FacilityType === 'Community' && drpLists.cp.states.list.length === 0) {
                facilityStatesFetchList('cp');
                if (initialValues.State && drpLists.cp.cities.list.length === 0) {
                    cpCitiesFetchList(initialValues.State);
                    if (initialValues.City && drpLists.cp.specialties.list.length === 0) {
                        vaSpecialtiesFetchList();

                        // this.props.communityProviderFetchList(initialValues.Specialty, initialValues.State, initialValues.City);
                    }
                }
            } else if (FacilityType === 'AA' && drpLists.aa.states.list.length === 0) {
                facilityStatesFetchList('aa');
                academicAffiliationsFetchList(initialValues.State, initialValues.MarketName);
            }
        }
    }

    componentWillUnmount() {
        const { clearProviderForm } = this.props;
        clearProviderForm();
    }

    onDrpChange = (option, inputName) => {
        const {
            currentFacilityType,
            autofill,
            fqhcCountiesFetchList,
            cpCitiesFetchList,
            clearCommunityProviderGrid,
            allIHSByStateFetchList
        } = this.props;

        if (inputName === 'FacilityType') {
            this.resetLocationFields(inputName);
            this.fetchFacilityDrps(option);
        } else if (inputName === 'State') {
            this.resetLocationFields(inputName);
            if (currentFacilityType === 'FQHC') {
                autofill('County', undefined);
                fqhcCountiesFetchList(option);
            } else if (currentFacilityType === 'Community') {
                cpCitiesFetchList(option);
                clearCommunityProviderGrid();
            } else if (currentFacilityType === 'DoD') {
                this.fetchGridData(option, inputName);
            } else if (currentFacilityType === 'AA') {
                this.fetchGridData(option, inputName);
            } else if (currentFacilityType === 'IHS') {
                allIHSByStateFetchList(option);
            }
        } else if (inputName === 'City') {
            this.resetLocationFields(inputName);
            this.fetchGridData(option, inputName);
        } else if (inputName === 'Specialty') {
            this.fetchGridData(option, inputName);
        } else if (inputName === 'County') {
            this.resetLocationFields(inputName);
            this.fetchGridData(option, inputName);
        }
    };

    fetchFacilityDrps = (option) => {
        const { drpLists, facilityStatesFetchList, vaSpecialtiesFetchList } = this.props;
        if (option === 'FQHC' && drpLists.fqhc.states.list.length === 0) {
            facilityStatesFetchList('fqhc');
        } else if (option === 'IHS') {
            if (drpLists.ihs.states.list.length === 0) {
                facilityStatesFetchList('ihs');
            }
        } else if (option === 'DoD' && drpLists.dod.states.list.length === 0) {
            facilityStatesFetchList('dod');
        } else if (option === 'Community') {
            if (drpLists.cp.states.list.length === 0) {
                facilityStatesFetchList('cp');
            }
            if (drpLists.cp.specialties.list.length === 0) {
                vaSpecialtiesFetchList();
            }
        } else if (option === 'AA' && drpLists.aa.states.list.length === 0) {
            facilityStatesFetchList('aa');
        }
    };

    resetLocationFields = (inputName) => {
        const { currentFacilityType, dispatch } = this.props;
        if (inputName === 'FacilityType') {
            dispatch(change('UCManageProvidersForm', 'State', null));
            dispatch(untouch('UCManageProvidersForm', 'State', null));
        }
        dispatch(change('UCManageProvidersForm', 'Name', null));
        dispatch(untouch('UCManageProvidersForm', 'Name', null));
        dispatch(change('UCManageProvidersForm', 'Address1', null));
        dispatch(untouch('UCManageProvidersForm', 'Address1', null));
        dispatch(change('UCManageProvidersForm', 'Address2', null));
        dispatch(untouch('UCManageProvidersForm', 'Address2', null));
        dispatch(change('UCManageProvidersForm', 'ZipCode', null));
        dispatch(untouch('UCManageProvidersForm', 'ZipCode', null));
        if (
            inputName !== 'City' ||
            (currentFacilityType === 'Community' && inputName === 'GridSelection') ||
            inputName === 'FacilityType'
        ) {
            dispatch(change('UCManageProvidersForm', 'City', null));
            dispatch(untouch('UCManageProvidersForm', 'City', null));
        }
        if (
            inputName !== 'County' ||
            (currentFacilityType === 'FQHC' && inputName === 'GridSelection') ||
            inputName === 'FacilityType'
        ) {
            dispatch(change('UCManageProvidersForm', 'County', null));
            dispatch(untouch('UCManageProvidersForm', 'County', null));
        }
    };

    resetForm = () => {
        const { dispatch } = this.props;
        dispatch(reset('UCManageProvidersForm'));
    };

    fetchGridData = (option, inputName) => {
        const {
            currentFacilityType,
            currentState,
            currentCity,
            currentSpecialty,
            marketName,
            fqhcFacilitiesFetchList,
            dodInstallationsFetchList,
            communityProviderFetchList,
            academicAffiliationsFetchList
        } = this.props;

        if (currentFacilityType === 'FQHC') {
            fqhcFacilitiesFetchList(currentState, option);
        } else if (currentFacilityType === 'DoD') {
            dodInstallationsFetchList(option);
        } else if (
            currentFacilityType === 'Community' &&
            inputName === 'City' &&
            currentSpecialty
        ) {
            communityProviderFetchList(currentSpecialty, currentState, option);
        } else if (currentFacilityType === 'Community' && inputName === 'Specialty') {
            communityProviderFetchList(option, currentState, currentCity);
        } else if (currentFacilityType === 'AA') {
            academicAffiliationsFetchList(option, marketName);
        }
    };

    selectFacilityInGrid = (facilityType, record) => {
        const { autofill } = this.props;

        // this.props.resetLocationFields("GridSelection");

        this.facilityInGridId = record.Id;
        if (facilityType === 'AA') {
            autofill('Name', record.Affiliate);
            autofill('Address1', record.Address1);
            autofill('Address2', record.Address2);
            autofill('City', record.City);
            autofill('ZipCode', record.Zip);

            // autofill("County", "");
        } else if (facilityType === 'FQHC') {
            autofill('Name', record.Name);
            autofill('Address1', record.Address);
            autofill('City', record.City);
            autofill('ZipCode', record.ZipCode);
            autofill('County', record.County);
        } else if (facilityType === 'Community') {
            autofill('Name', record.FacilityNameString);
            autofill('Address1', record.Address);
            autofill('Address2', record.Address2);
            autofill('City', record.City);
            autofill('State', record.State);
            autofill('ZipCode', record.Zip);
        } else if (facilityType === 'DoD') {
            autofill('Name', record.Label);
            autofill('City', record.FacilityCityName);
            autofill('ZipCode', record.Facility5DigitZIPCode);
        } else if (facilityType === 'IHS') {
            autofill('Name', record.FacilityName);
            autofill('City', record.City);
            autofill('Address1', record.Address);
            autofill('ZipCode', record.ZIP);
        }

        // this.props.dispatch(reset('ProviderForm'));
        // this.props.dispatch(clearProviderForm());
    };

    formSubmit = (values) => {
        const { marketName, matchPlanner, dispatch, saveProvider, clearProviderForm } = this.props;

        const savedValues = { ...values, MarketName: marketName };
        const planId = matchPlanner.AddProviderModal.show
            ? matchPlanner.Plan.planData.Plan.Id
            : null;
        saveProvider(savedValues, this.facilityInGridId, planId);
        clearProviderForm();
        dispatch(reset('UCManageProvidersForm'));
    };

    getStates = () => {
        const { currentFacilityType, drpLists } = this.props;

        switch (currentFacilityType) {
            case 'FQHC':
                return drpLists.fqhc.states;
            case 'AA':
                return drpLists.aa.states;
            case 'Community':
                return drpLists.cp.states;
            case 'IHS':
                return drpLists.ihs.states;
            case 'DoD':
                return drpLists.dod.states;
            default:
                return { lists: [], loading: false };
        }
    };

    getGridOptions = () => {
        const {
            currentFacilityType,
            drpLists,
            currentState,
            currentSpecialty,
            currentCounty,
            currentCity
        } = this.props;

        const options = [];
        if (currentFacilityType === 'FQHC') {
            options.push(drpLists.fqhc.grid);
        } else if (currentFacilityType === 'DoD' && currentState) {
            options.push(drpLists.dod.grid);
        } else if (currentFacilityType === 'Community' && currentSpecialty) {
            options.push(drpLists.cp.grid);
        } else if (currentFacilityType === 'AA' && currentState) {
            options.push(drpLists.aa.grid);
        } else if (currentFacilityType === 'IHS' && currentState) {
            options.push(drpLists.ihs.grid);
        } else {
            options.push({});
        }

        if (currentFacilityType === 'FQHC' && currentCounty) {
            options.push(true);
        } else if (currentFacilityType === 'DoD' && currentState) {
            options.push(true);
        } else if (currentFacilityType === 'Community' && currentSpecialty && currentCity) {
            options.push(true);
        } else if (currentFacilityType === 'AA' && currentState) {
            options.push(true);
        } else if (currentFacilityType === 'IHS' && currentState) {
            options.push(true);
        } else {
            options.push(false);
        }

        return options;
    };

    render() {
        const {
            initialValues,
            facilityTypes,
            providerTypes,
            currentFacilityType,
            currentProvider,
            drpLists,
            gapMpcTypes,
            loading,
            saving,
            submitting,
            pristine,
            canEdit,
            reset: resetForm,
            handleSubmit
        } = this.props;

        const formType = initialValues.Id ? 'edit' : 'create';

        const states = this.getStates();
        const [grid, showGrid] = this.getGridOptions();

        const createLabel = saving ? 'Saving...' : 'Save Provider';
        const updateLabel = saving ? 'Updating...' : 'Update Provider';
        const submitLabel = formType === 'create' ? createLabel : updateLabel;

        return (
            <div>
                <div className="col-md-8 offset-md-2">
                    <Form onSubmit={handleSubmit(this.formSubmit)}>
                        <div className="form-group">
                            <h3 className="text-left">
                                {formType === 'create' ? 'Create New Provider' : 'Edit Provider'}
                            </h3>
                        </div>
                        <div>
                            <DropdownField
                                name="FacilityType"
                                label="Provider Type"
                                options={facilityTypes.list}
                                loading={facilityTypes.loading}
                                onChangeFunction={this.onDrpChange}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={12}
                                inputPosition={12}
                            />
                            <DropdownField
                                name="State"
                                label="State"
                                options={states.list}
                                loading={states.loading}
                                onChangeFunction={this.onDrpChange}
                                displayField="DisplayField"
                                valueField="ValueField"
                                labelPosition={12}
                                inputPosition={12}
                            />
                            <div hidden={currentFacilityType !== 'FQHC'}>
                                <DropdownField
                                    name="County"
                                    label="County"
                                    options={drpLists.fqhc.counties.list}
                                    loading={drpLists.fqhc.counties.loading}
                                    onChangeFunction={this.onDrpChange}
                                    displayField="DisplayField"
                                    valueField="ValueField"
                                    labelPosition={12}
                                    inputPosition={12}
                                />
                            </div>
                            <div hidden={currentFacilityType !== 'Community'}>
                                <DropdownField
                                    name="City"
                                    label="City"
                                    options={drpLists.cp.cities.list}
                                    loading={drpLists.cp.cities.loading}
                                    onChangeFunction={this.onDrpChange}
                                    displayField="DisplayField"
                                    valueField="ValueField"
                                    labelPosition={12}
                                    inputPosition={12}
                                />
                                <DropdownField
                                    name="Specialty"
                                    label="Specialty"
                                    options={drpLists.cp.specialties.list}
                                    loading={drpLists.cp.specialties.loading}
                                    valueField="Id"
                                    displayField="Specialty"
                                    onChangeFunction={this.onDrpChange}
                                    labelPosition={12}
                                    inputPosition={12}
                                />
                            </div>
                            <UCMPFacilityTable
                                currentFacilityType={currentFacilityType}
                                data={grid}
                                showGrid={showGrid}
                                selectFacilityInGrid={this.selectFacilityInGrid}
                            />
                        </div>
                        <div>
                            <p>
                                Either select provider location from the dropdowns above{' '}
                                <strong>OR</strong> fill out the form below.
                            </p>
                            <br />
                            <TextboxField name="Name" label="Facility Name" loading={loading} />
                            <TextboxField
                                name="Address1"
                                label="Address"
                                placeholder="Enter address line 1..."
                                loading={loading}
                            />
                            <TextboxField
                                name="Address2"
                                label="Address Line 2"
                                placeholder="Enter address line 2..."
                                loading={loading}
                            />
                            <TextboxField name="City" label="City" loading={loading} />
                            <TextboxField name="State" label="State" loading={loading} />
                            <TextboxField
                                name="ZipCode"
                                label="ZIP Code"
                                placeholder="Enter ZIP Code..."
                                loading={loading}
                            />
                            <TextboxField name="County" label="County" loading={loading} />
                            <DropdownField
                                name="ProviderType"
                                label="Provider Type"
                                options={providerTypes.list}
                                loading={providerTypes.loading}
                                displayField="DisplayField"
                                valueField="ValueField"
                            />
                            <RadioGroupField
                                name="CurrentProvider"
                                headerLabel="Does VA currently partner with this provider?"
                                firstLabel="Yes"
                                secondLabel="No"
                                labelPosition={12}
                                inputPosition={12}
                            />
                            <div hidden={currentProvider !== 'false'}>
                                <p>
                                    If VA <strong>DOES NOT</strong> currently partner with this
                                    provider, please explain why not: (check all appropriate boxes)
                                </p>
                                <CheckboxField
                                    name="FutureRelationship"
                                    label="Future relationship being developed"
                                />
                                <CheckboxField
                                    name="GeographicAccess"
                                    label="Geographic access issues"
                                />
                                <CheckboxField
                                    name="CapacityStaffing"
                                    label="Capacity/Staffing issues"
                                />
                                <CheckboxField name="Cost" label="Cost issues" />
                                <CheckboxField name="Quality" label="Quality issues" />
                                <CheckboxField
                                    name="NoInterest"
                                    label="No interest from provider"
                                />
                                <CheckboxField name="Other" label="Other" />
                                <CheckboxField
                                    name="OtherText"
                                    label="Description"
                                    placeholder='Describe why "Other" is selected'
                                />
                            </div>
                            <br />
                            <MultiSelectField
                                name="SPCListString"
                                label="Provider Services"
                                options={gapMpcTypes.list}
                                valueField="Hspc"
                                loading={gapMpcTypes.loading}
                                labelPosition={12}
                                inputPosition={12}
                                disabled={saving === true}
                            />
                        </div>
                        <div className="col-xs-12">
                            <div className="form-group" style={{ textAlign: 'center' }}>
                                <Button
                                    type="submit"
                                    color="success"
                                    label={submitLabel}
                                    disabled={loading || !canEdit}
                                />
                                &nbsp;&nbsp;
                                <Button
                                    color="warning"
                                    type="button"
                                    label="Cancel"
                                    disabled={pristine || submitting || loading}
                                    onClick={resetForm}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
                <div style={{ width: '100%', height: '30px' }} />
            </div>
        );
    }
}

const ReduxFormUCManageProvidersForm = reduxForm({
    form: 'UCManageProvidersForm',
    enableReinitialize: true,
    validate(values) {
        const errors = {};
        if (!values.CurrentProvider) {
            errors.CurrentProvider = 'Required';
        }
        if (!values.FacilityType) {
            errors.FacilityType = 'Provider type is required.';
        }
        if (!values.ProviderType) {
            errors.ProviderType = 'Provider type is required.';
        }

        return errors;
    }
})(UCManageProvidersForm);

const selector = formValueSelector('UCManageProvidersForm');
const mapDispatchToProps = (dispatch) => ({
    getGapMpcTypes: () => dispatch.UserSessionModel.fetchGapMpcTypesAsync(),
    facilityTypesFetchList: () => dispatch.ProviderFormModel.fetchFacilityTypesAsync(),
    providerTypesFetchList: () => dispatch.ProviderFormModel.fetchProviderTypesAsync(),
    facilityStatesFetchList: (facilityType) =>
        dispatch.ProviderFormModel.fetchFacilityStatesAsync(facilityType),
    fqhcCountiesFetchList: (state) => dispatch.ProviderFormModel.fetchFqhcCountiesAsync(state),
    cpCitiesFetchList: (state) => dispatch.ProviderFormModel.fetchCpCitiesAsync(state),
    vaSpecialtiesFetchList: () => dispatch.ProviderFormModel.fetchVaSpecialtiesAsync(),
    allIHSByStateFetchList: (state) => dispatch.ProviderFormModel.fetchIhsFacilitiesAsync(state),
    saveProvider: (formValues, facilityInGridId, planId) =>
        dispatch.ProviderFormModel.saveProviderAsync({
            formValues,
            facilityInGridId,
            planId
        }),
    clearProviderForm: () => dispatch.ProviderFormModel.reset(),
    fqhcFacilitiesFetchList: (state, county) =>
        dispatch.ProviderFormModel.fetchFqhcFacilitiesAsync({ state, county }),
    dodInstallationsFetchList: (state) => dispatch.ProviderFormModel.fetchDodFacilitiesAsync(state),
    academicAffiliationsFetchList: (state, market) =>
        dispatch.ProviderFormModel.fetchAaFacilitiesAsync({ state, market }),
    communityProviderFetchList: (specialty, state, city) =>
        dispatch.ProviderFormModel.fetchCpFacilitiesAsync({
            specialty,
            state,
            city
        }),
    clearCommunityProviderGrid: () => dispatch.ProviderFormModel.resetDrpOption('cp', 'grid')
});

const mapStateToProps = (state) => {
    const formData = getProviderFormSelector(state);
    return {
        formValues: getFormValues('UCManageProvidersForm')(state),
        currentFacilityType: selector(state, 'FacilityType'),
        currentProvider: selector(state, 'CurrentProvider'),
        currentCounty: selector(state, 'County'),
        currentCity: selector(state, 'City'),
        currentState: selector(state, 'State'),
        currentSpecialty: selector(state, 'Specialty'),
        initialValues: formData.data,
        saving: formData.saving,

        // loading: ProviderForm.loading,

        drpLists: getDrpListsSelector(state),
        facilityTypes: getFacilityTypesSelector(state),
        providerTypes: getProviderTypesSelector(state),
        gapMpcTypes: getGapMpcTypesSelector(state),
        matchPlanner: state.PlannerModel,
        marketName: getCurrentMarketName(state),
        currentVISN: getCurrentVISN(state),
        canEdit: canEditVISN(state)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormUCManageProvidersForm);
