import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, FormControl, FormLabel, Modal, Row, Spinner } from 'react-bootstrap';

import { useHSPADispatch, useHSPASelector } from 'storeTypes';

import DropdownField from 'Lib/Fields/DropdownField';
import { canEditVISN, getCurrentMarketName } from 'Modules/UserSession/selector';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Component that shows a Modal for creating a new Plan.
 *
 * @returns - The component.
 */
const CreatePlanModal = () => {
    const dispatch = useHSPADispatch();
    const [showModal, setShowModal] = useState(false);
    const [hspcID, setHSPCID] = useState('');
    const managePlansModel = useHSPASelector((state) => state.ManagePlansModel);
    const marketName = useHSPASelector(getCurrentMarketName);
    const hasPermission = useHSPASelector(canEditVISN);
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);
    const [name, setName] = useState('');

    /**
     * Handles when the Modal is closed.
     */
    const handleModalClose = () => setShowModal(false);

    /**
     * Handles when the Modal is opened.
     */
    const handleModalOpen = () => setShowModal(true);

    const createPlan = useCallback(() => {
        setCreating(true);
        return dispatch.ManagePlansModel.createPlanByHspcAsync({
            name,
            hspcID,
            marketName,
            description
        }).then(() => {
            setName('');
            setDescription('');
            setHSPCID('');
            setCreating(false);
            setShowModal(false);
        });
    }, [dispatch, hspcID, marketName, description, name]);

    useEffect(() => {
        // Calling fetchHSPCItemsAsync explicitly to prevent returning a Promise to useEffect
        dispatch.ManagePlansModel.fetchHSPCItemsAsync();
    }, [dispatch]);

    return (
        <>
            <Button
                variant="success"
                size="lg"
                type="button"
                disabled={!hasPermission || managePlansModel.Plans.loading}
                className="btn-block"
                style={{ borderRadius: '16px' }}
                onClick={handleModalOpen}
            >
                <span>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    &nbsp;Create Plan
                </span>
            </Button>
            <Modal
                show={showModal}
                onHide={handleModalClose}
                backdrop="static"
                size="xl"
                fullscreen="md-down"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create a New Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <DropdownField
                                options={managePlansModel.hspcList.list}
                                label="Create a new HSPC plan"
                                placeholder="Select an HSPC that needs a plan..."
                                loading={managePlansModel.hspcList.loading}
                                value={hspcID.toString()}
                                onChangeFunction={(values: any) => setHSPCID(values || '')}
                                displayField="Hspc"
                                valueField="Id"
                                labelPosition={12}
                                inputPosition={12}
                                disabled={creating}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <FormLabel itemRef="name" style={{ fontWeight: 'bold' }}>
                                Plan Name:
                            </FormLabel>
                            <FormControl
                                id="name"
                                as="input"
                                placeholder="Plan Name"
                                value={name}
                                disabled={creating}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormLabel itemRef="description" style={{ fontWeight: 'bold' }}>
                                Plan Description:
                            </FormLabel>
                            <FormControl
                                id="description"
                                as="textarea"
                                placeholder="Plan Description"
                                style={{ height: '100px' }}
                                value={description}
                                disabled={creating}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleModalClose}
                        disabled={creating}
                        style={{ borderRadius: '16px' }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="success"
                        type="button"
                        disabled={!hspcID || !hasPermission || creating}
                        style={{ borderRadius: "16px" }}
                        onClick={createPlan}
                    >
                        {creating ? (
                            <Spinner as="span" animation="border" size="sm" role="status">
                                <span className="sr-only">Submitting...</span>
                            </Spinner>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreatePlanModal;
