import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';

import _map from 'lodash/map';

/**
 * Table for reviewing projected demand in the Understand Demand module.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import Button from 'Lib/Button';
import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import { makeGetReviewProjectedDemand } from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentMarketName, getDefaultYearObject } from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';

import ExpandTable from './ExpandTable';
import FacilityColumns from './FacilityColumns';

class UDRPDFacilityTable extends React.Component {
    constructor(props) {
        super(props);
        const defaultYear = props.defaultYear;
        this.state = {
            activeColumns: [
                {
                    key: 0,
                    value: <label>{defaultYear.twoYearWithFiscalStringShortActual}</label>,
                    text: defaultYear.twoYearWithFiscalStringShortActual,
                    hidden: true
                },
                {
                    key: 1,
                    value: <label>{defaultYear.oneYearWithFiscalStringShortActual}</label>,
                    text: defaultYear.oneYearWithFiscalStringShortActual,
                    hidden: true
                },
                {
                    key: 2,
                    value: (
                        <label>
                            <span className="glyphicon glyphicon-ok" aria-hidden="true" />{' '}
                            {defaultYear.defaultYearWithFiscalStringShortBaseline}
                        </label>
                    ),
                    text: defaultYear.defaultYearWithFiscalStringShortBaseline,
                    hidden: false
                },
                {
                    key: 3,
                    value: (
                        <label>
                            <span className="glyphicon glyphicon-ok" aria-hidden="true" />{' '}
                            {defaultYear.fiveYearWithFiscalStringShortProjection}
                        </label>
                    ),
                    text: defaultYear.fiveYearWithFiscalStringShortProjection,
                    hidden: false
                },
                {
                    key: 4,
                    value: (
                        <label>
                            <span className="glyphicon glyphicon-ok" aria-hidden="true" />{' '}
                            {defaultYear.tenYearWithFiscalStringShortProjection}
                        </label>
                    ),
                    text: defaultYear.tenYearWithFiscalStringShortProjection,
                    hidden: false
                },
                {
                    key: 5,
                    value: (
                        <label>
                            <span className="glyphicon glyphicon-ok" aria-hidden="true" />{' '}
                            {defaultYear.twentyYearWithFiscalStringShortProjection}
                        </label>
                    ),
                    text: defaultYear.twentyYearWithFiscalStringShortProjection,
                    hidden: false
                },
                {
                    key: 6,
                    value: (
                        <label>
                            <span className="glyphicon glyphicon-ok" aria-hidden="true" /> In-House
                        </label>
                    ),
                    text: 'In-House',
                    hidden: false
                },
                {
                    key: 7,
                    value: (
                        <label>
                            <span className="glyphicon glyphicon-ok" aria-hidden="true" /> Community
                            Care
                        </label>
                    ),
                    text: 'Community Care',
                    hidden: false
                }
            ]
        };
    }

    _setTableOption(data) {
        if (data.loading) {
            return (
                <div className="progress">
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: '100%', backgroundRepeat: 'repeat' }}
                    >
                        Fetching Data
                    </div>
                </div>
            );
        }
        return 'No data available in the table';
    }

    isExpandableRow = (row) => {
        if (!this.props.isExpandable) {
            return false;
        }
        return row.IsExpandable;
    };

    expandComponent = (row) => {
        let facilityHeaderName = '';
        if (this.props.tableType === 'facility') {
            facilityHeaderName =
                row.FacilityMarket === this.props.marketName
                    ? `${row.Facility} - In Market RVU`
                    : `${row.Facility} - Out of Market RVU`;
        }
        const dataArray = this.props.expandTableData.list.filter(
            (x) => x.HspcGroup === row.HspcGroup && x.Facility === row.Facility
        );
        return (
            <ExpandTable
                data={dataArray}
                defaultYear={this.props.defaultYear}
                tableType={this.props.tableType}
                facilityHeader={facilityHeaderName}
            />
        );
    };

    rowClassNameFormat = (row) => {
        const outOfMarketStyle = {
            backgroundColor: '#adc8da'
        };
        return this.props.marketName !== row.Facility ? outOfMarketStyle : '';
    };

    onToggleSelect = (key) => {
        const items = this.state.activeColumns;
        items[key].hidden = !items[key].hidden;
        items[key].value = items[key].hidden ? (
            <label>{items[key].text}</label>
        ) : (
            <label>
                <span className="glyphicon glyphicon-ok" aria-hidden="true" />
                {items[key].text}
            </label>
        );
        this.forceUpdate();
    };

    createCustomButtonGroup = (props) => (
        <div className="col-xs-12">
            <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Button group with nested dropdown"
            >
                {props.exportCSVBtn}
                <div className="btn-group" role="group">
                    <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Show/Hide Columns
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        {_map(this.state.activeColumns, (item) => {
                            const icon = item.hidden ? 'eye-slash' : 'eye';
                            return (
                                <a
                                    className="dropdown-item"
                                    key={item.key}
                                    onClick={() => this.onToggleSelect(item.key)}
                                >
                                    <FontAwesome name={icon} /> {` ${item.text}`}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );

    render() {
        const defaultYear = this.props.defaultYear || '';

        const defaultVisibility = {
            'ActualYear2InHouse': false,
            'ActualYear2Purchased': false,
            'ActualYear0InHouse': false,
            'ActualYear0Purchased': false
        };

        const expandContent = {
            canRender: this.isExpandableRow,
            renderer: this.expandComponent
        };

        function checkToggle(state, columnParent, column) {
            let result = false;
            if (columnParent !== null && state.activeColumns[columnParent].hidden) {
                result = true;
            } else if (state.activeColumns[column].hidden) {
                result = true;
            }
            return result;
        }
        function columnSpan(state) {
            let result = 2;
            result -= state.activeColumns[6].hidden ? 1 : 0;
            result -= state.activeColumns[7].hidden ? 1 : 0;
            return result;
        }

        return (
            <div>
                <ReactDataTable
                    list={this.props.tableData.list}
                    columns={FacilityColumns(defaultYear, this.patientOriginModalBtn)}
                    loading={this.props.tableData.loading}
                    csvFileName={this.props.marketName + this.props.exportName}
                    showHideColumns
                    defaultVisibility={defaultVisibility}
                    expandContent={expandContent}
                    keyValue='no-id'
                />
            </div>
        );
    }

    patientOriginModalBtn = (cell, row, enumObject, rowIndex) => (
        <Button
            color={row.FacilityMarket === this.props.marketName ? 'primary' : 'warning'}
            size="btn-sm"
            type="button"
            btnPaddingBottom={1}
            btnPaddingTop={1}
            label={
                row.FacilityMarket === this.props.marketName
                    ? `${row.Facility.replace(/\(.*?\)/g, '')} - In Market`
                    : `${row.Facility.replace(/\(.*?\)/g, '')} - Out of Market`
            }
            onClick={() => {
                this.props.toggleFacilityModal(
                    row.Facility,
                    row.HspcGroup,
                    row.FacilityMarket,
                    row.WorkloadType
                );
            }}
        />
    );

    static propTypes = {
        filter: PropTypes.string.isRequired,
        isExpandable: PropTypes.bool.isRequired,
        exportName: PropTypes.string.isRequired,
        tableType: PropTypes.string.isRequired,
        expandTableType: PropTypes.string
    };
}

const mapDispatchToProps = (dispatch) => ({
    toggleFacilityModal: (facility, mpc, market, workloadType) =>
        dispatch.ReviewProjectedDemandModel.togglePOModal({
            facility,
            mpc,
            market,
            workloadType
        })
});

const makeMapStateToProps = (props) => {
    const getReviewProjectedDemand = makeGetReviewProjectedDemand();
    const mapStateToProps = (state, props) => ({
        marketName: getCurrentMarketName(state),
        defaultYear: getDefaultYearObject(state),
        tableData: getReviewProjectedDemand(state, props.filter),
        expandTableData: getReviewProjectedDemand(state, props.expandTableType)
    });
    return mapStateToProps;
};

export default connect(makeMapStateToProps, mapDispatchToProps)(UDRPDFacilityTable);
