import { ADMINISTRATOR_ROLE, VISN_PLANNER_ROLE } from 'Utilities/constants';

/**
 * Check if a specified user has specific rights under a VISN.  Administrators have access to all
 * rights under all VISNs.
 *
 * @param {object} user - user profile object
 * @param {string} role - role string (i.e. 'hcpm_administrator')
 * @param {number} visnID - the ID of the VISN
 * @returns {boolean} true if the current user has the role under the VISN
 */
export const hasRoleInVISN = (user, role, visnID) => {
    if (Number.isNaN(Number(visnID)) || !user) {
        return false;
    }

    const { UserGroups } = user;

    return UserGroups.some((groupInfo) => {
        const { RoleCode, VisnId: groupVISNID } = groupInfo.Group;

        return RoleCode === ADMINISTRATOR_ROLE || (RoleCode === role && groupVISNID === visnID);
    });
};

/**
 * Does the specified user have edit rights for the specified VISN?
 *
 * @param {object} user - user profile object
 * @param {number} visnID - ID of the VISN
 * @returns {boolean} true if the user can edit records related to the VISN
 */
export const canEdit = (user, visnID) => {
    if (!visnID || !user) {
        return false;
    }

    const { UserGroups } = user;
    const hasAdminAccess = UserGroups.some(
        (groupInfo) => groupInfo.Group.RoleCode === ADMINISTRATOR_ROLE
    );
    const hasPlannerAccess = UserGroups.some((groupInfo) => {
        const { RoleCode, VisnId } = groupInfo.Group;
        return (
            (RoleCode === 'hcpm_facility_planner' || RoleCode === VISN_PLANNER_ROLE) &&
            VisnId === visnID
        );
    });

    return hasAdminAccess || hasPlannerAccess;
};
