import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';

import styled from 'styled-components/macro';

const StyledSpinner = styled(Spinner)`
    position: relative;
    width: 4rem;
    height: 4rem;
`;

const SpinnerContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 800px;
    margin-top: 200px;
    text-align: center;
`;

type LoadingSpinnerProps = {
    animation: 'border' | 'grow';
    className?: string;
    variant: Variant;
};

/**
 * Render a Bootstrap spinner to be used as part of a loading overlay.
 *
 * @param props - props pass into this component
 * @param props.animation - the animation style of the spinner (spinning or growing)
 * @param props.className - a class name for the spinner's container
 * @param props.variant - the visual color style of the spinner
 * @returns a loading spinner component
 */
const LoadingSpinner = ({ animation, className, variant }: LoadingSpinnerProps): JSX.Element => (
    <SpinnerContainer className={className}>
        <StyledSpinner animation={animation} variant={variant} />
    </SpinnerContainer>
);

export default LoadingSpinner;
