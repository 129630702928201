import { deleteRequest, getJSON, postJSON } from 'Utilities/apiCalls';
import {
    ApprovalUpdate,
    CapitalProject,
    FacilitySelectionDTO,
    NameId,
    Plan
} from 'Utilities/types';

if (typeof process.env.REACT_APP_HSPA_API === 'undefined') {
    throw new Error(
        'REACT_APP_HSPA_API is undefined.  Please ensure it contains the URL to the HSPA API.'
    );
}

const GET_CAPITAL_PROJECTS_URL = `${process.env.REACT_APP_HSPA_API}/capitalproject/getcapitalprojectsbymarket`;
const CREATE_CAPITAL_PROJECT = `${process.env.REACT_APP_HSPA_API}/capitalproject`;
const GET_CAPITAL_PROJECT_TYPES = `${process.env.REACT_APP_HSPA_API}/capitalproject/types`;
const GET_FACILITY_SELECTIONS_BY_MARKET = `${process.env.REACT_APP_HSPA_API}/capitalproject/facilities`;

const GET_PLAN_SELECTIONS_BY_MARKET = `${process.env.REACT_APP_HSPA_API}/capitalproject/plans`;
const GET_SPECIALTY_SELECTIONS = `${process.env.REACT_APP_HSPA_API}/capitalproject/specialties`;
const CAPITAL_PROJECT_APPROVAL = `${process.env.REACT_APP_HSPA_API}/capitalproject/approval`;

const DELETE_CAPITAL_PROJECT = `${process.env.REACT_APP_HSPA_API}/capitalproject`;

/**
 * Gets the Capital Projects by the specified market identifier.
 *
 * @param id - The market identifier.
 * @returns - The list of Capital Projects.
 */
export const getCapitalProjectsByMarketId = async (id: number): Promise<CapitalProject[]> => {
    if (id) {
        const capitalProjects = (await getJSON(
            `${GET_CAPITAL_PROJECTS_URL}?marketId=${id}`
        )) as CapitalProject[];

        return capitalProjects;
    }

    return [];
};

/**
 * Gets the Capital Project Types.
 *
 * @returns - The Capital Project Types.
 */
export const getCapitalProjectTypes = async (): Promise<NameId[]> => {
    const capitalProjectTypes = (await getJSON(GET_CAPITAL_PROJECT_TYPES)) as NameId[];

    return capitalProjectTypes;
};

/**
 * Creates a new Capital Project.
 *
 * @param capitalProject - The capital project to create.
 */
export const createCapitalProject = async (
    capitalProject: CapitalProject
): Promise<CapitalProject | undefined> => {
    if (capitalProject) {
        const result = (await postJSON(CREATE_CAPITAL_PROJECT, capitalProject)) as CapitalProject;

        return result;
    }

    return undefined;
};

/**
 * Gets the facilities for selection when creating a new capital project.
 *
 * @param market - The facility market identifier.
 * @returns - The list of facilities to select from.
 */
export const getFacilitySelectionsByMarket = async (
    market: string
): Promise<FacilitySelectionDTO[]> => {
    if (market) {
        const facilitySelections = (await getJSON(
            `${GET_FACILITY_SELECTIONS_BY_MARKET}?market=${market}`
        )) as FacilitySelectionDTO[];

        return facilitySelections;
    }

    return [];
};

/**
 * Gets the plans selections filtered by the specified market.
 *
 * @param marketName - The market name.
 * @returns - The list of plan selections.
 */
export const getPlanSelectionsByMarket = async (marketName: string): Promise<Plan[]> => {
    if (marketName) {
        const planSelections = (await getJSON(
            `${GET_PLAN_SELECTIONS_BY_MARKET}?marketname=${marketName}`
        )) as Plan[];

        return planSelections;
    }

    return [];
};

/**
 * Sets the Capital Project approval status.
 *
 * @param update - The approval data to update.
 * @returns - The list of plan selections.
 */
export const setCapitalProjectApproval = async (
    update: ApprovalUpdate
): Promise<CapitalProject | undefined> => {
    if (update) {
        const capitalProject = await postJSON(CAPITAL_PROJECT_APPROVAL, update);

        return capitalProject as CapitalProject;
    }

    return undefined;
};

/**
 * Gets the specialty selections.
 *
 * @returns - The list of specialty selections.
 */
export const getSpecialtySelections = async (): Promise<NameId[]> => {
    const specialties = (await getJSON(GET_SPECIALTY_SELECTIONS)) as NameId[];

    return specialties || [];
};

/**
 * Deletes the capital project.
 *
 * @param capitalProjectID - The capital project identifier.
 * @returns - Whether or not the capital project was deleted.
 */
export const deleteCapitalProject = async (
    capitalProjectID: number
): Promise<number | undefined> => {
    const result = await deleteRequest(
        `${DELETE_CAPITAL_PROJECT}?capitalProjectID=${capitalProjectID}`
    );

    return result.ok ? capitalProjectID : undefined;
};

export default {
    getCapitalProjectsByMarketId,
    getCapitalProjectTypes,
    createCapitalProject,
    getFacilitySelectionsByMarket,
    getPlanSelectionsByMarket,
    getSpecialtySelections,
    setCapitalProjectApproval,
    deleteCapitalProject
};
