import React from 'react';

import ReactDataTable from 'Lib/ReactDataTable/ReactDataTable';
import PropTypes from 'prop-types';

export default class ESDTable extends React.Component {
    static propTypes = {
        keyValue: PropTypes.string,
        tableColumnDefs: PropTypes.array,
        tableData: PropTypes.object,
        currentMarket: PropTypes.string,
        fetchDataFunc: PropTypes.func,
        csvFileName: PropTypes.string
    };

    componentDidMount() {
        if (
            this.props.currentMarket &&
            this.props.tableData &&
            this.props.tableData.list.length === 0
        )
            this.props.fetchDataFunc(this.props.currentMarket);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentMarket !== this.props.currentMarket)
            this.props.fetchDataFunc(this.props.currentMarket);
    }

    render() {
        return (
            <ReactDataTable
                keyValue={this.props.keyValue}
                columns={this.props.tableColumnDefs}
                list={this.props.tableData.list}
                loading={this.props.tableData.loading ? 1 : undefined}
                csvFileName={this.props.csvFileName}
            />
        );
    }
}

ESDTable.defaultProps = {
    keyValue: '',
    tableColumnDefs: [],
    tableData: [],
    currentMarket: '',
    fetchDataFunc: null,
    csvFileName: ''
};
