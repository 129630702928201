import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { deleteRequest, getJSON } from 'Utilities/apiCalls';

const capacityApi = `${process.env.REACT_APP_HSPA_API}/UnderstandCapacity/`;

export const ProvidersModel = {
    state: {
        list: [],
        loading: false
    },
    reducers: {
        populate(state, data) {
            return {
                ...state,
                list: data
            };
        },
        toggleLoading(state) {
            return {
                ...state,
                loading: !state.loading
            };
        },
        reset(state) {
            return {
                ...state,
                list: []
            };
        }
    },
    effects: (dispatch) => ({
        async fetchProvidersAsync(market, state) {
            try {
                this.reset();
                this.toggleLoading();
                let result = await getJSON(
                    `${capacityApi}GetProvidersByMarket?marketname=${market}`
                );
                this.populate(result);
                this.toggleLoading();
            } catch (error) {
                this.toggleLoading();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch providers',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async deleteProviderAsync(payload, state) {
            try {
                await deleteRequest(
                    `${capacityApi}DeleteProvider?id=` +
                        payload.providerId +
                        '&marketname=' +
                        payload.market
                );
                dispatch(
                    addNotification({
                        message: 'Provider has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                this.fetchProvidersAsync(payload.market);
            } catch (error) {
                if (error.status === 409)
                    dispatch(
                        addNotification({
                            message:
                                'Error: Cannot delete provider. The provider is currently being used by a plan.',
                            level: HSPANotificationLevel.ERROR
                        })
                    );
                else
                    dispatch(
                        addNotification({
                            message: 'Error: Unable to delete providers',
                            level: HSPANotificationLevel.ERROR
                        })
                    );
            }
        },
        async populateProviderForm(id, state) {
            try {
                let provider = Object.assign(
                    {},
                    state.ProvidersModel.list.find((o) => o.Id === id)
                );
                provider.CurrentProvider =
                    provider.CurrentProvider === true || provider.CurrentProvider === 'true'
                        ? 'true'
                        : provider.CurrentProvider === null
                        ? null
                        : 'false';
                dispatch.ProviderFormModel.populateForm(provider);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to populate provider form',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
