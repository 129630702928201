import React from 'react';

import ModuleNavigation from '../../../../Lib/Tabs/ModuleNavigation';
import analytics from '../../../../Utilities/analytics';
import HeaderContainer from '../../../App/HeaderContainer';
import UCHealthCareResources from '../UCHealthCareResources';
import UCManageProviders from '../UCManageProviders';
import UCProvidersDashboard from '../UCProvidersDashboard';

/* const TableStyled = styled(Table)`
    th:nth-child(2),
    td:nth-child(2) {
        text-align: center;
    }
`;
const Check = () => <FontAwesomeIcon icon={faCheck} style={{ color: '#28a745' }} />;
const Ban = () => <FontAwesomeIcon icon={faBan} style={{ color: '#dc3545' }} />;

const Addition = ({ children }) => {
    return (
        <li>
            <FontAwesomeIcon icon={faPlusCircle} style={{ color: '#28a745' }} /> {children}
        </li>
    );
}; */

/* const OpportunityToggleButton = () => {
    const [isEnabled, setIsEnabled] = useState(false);

    const variant = isEnabled ? 'danger' : 'success';
    const label = isEnabled ? 'Disable' : 'Enable';

    return (
        <Button variant={variant} onClick={() => setIsEnabled(!isEnabled)}>
            {label}
        </Button>
    );
}; */
/*
const stages = ['Analysis', 'Planning', 'Implementation', 'Verification', 'Complete'];
const stageNumbers = [0, 2, 3, 1, 4];
let numberIndex = 0;
 */
/* const OpportunityStageBarContainer = ({ className }) => {
    const [stageNumber] = useState(stageNumbers[numberIndex++]);

    const stageItems = stages.map((title, index) => {
        const status = index < stageNumber ? 'complete' : index === stageNumber ? 'current' : '';

        return (
            <li role="button" className={status}>
                {title}
            </li>
        );
    });
    return (
        <div class={`${className}`}>
            <ul class="step-menu">{stageItems}</ul>
        </div>
    );
}; */

/* const OpportunityStageBar = styled(OpportunityStageBarContainer)`
    .step-menu {
        list-style-type: none;
        white-space: nowrap;
        padding: 0;
        display: flex;
        flex-direction: row;
    }
    .step-menu li {
        flex-grow: 1;
        box-sizing: border-box;
        float: left;
        background: #ddd;
        height: 30px;
        text-align: center;
        padding: 0;
        position: relative;
        margin-left: 6px;
        color: transparent;
        width: 20px;
    }
    .step-menu li:before {
        content: '';
        position: absolute;
        border: 15px solid transparent;
        border-left-color: #fff;
        top: 0;
        right: -36px;
    }
    .step-menu li:after {
        content: '';
        position: absolute;
        border: 15px solid transparent;
        border-left-color: #ddd;
        top: 0;
        right: -30px;
    }
    .step-menu li:nth-child(1) {
        z-index: 600;
        margin-left: 0;
        border-radius: 5px 0 0 5px;
    }
    .step-menu li.current:nth-child(1) {
        padding-left: 15px;
    }
    .step-menu li:nth-child(2) {
        z-index: 500;
    }
    .step-menu li:nth-child(3) {
        z-index: 400;
    }
    .step-menu li:nth-child(4) {
        z-index: 300;
    }
    .step-menu li:last-child {
        z-index: 100;
        border-radius: 0 5px 5px 0;
        width: 30px;
    }
    .step-menu li:last-child:after,
    .step-menu li:last-child:before {
        border: none;
    }
    .step-menu li.current {
        background: rgba(51, 122, 183, 0.75);
        box-sizing: border-box;
        float: left;
        height: 30px;
        text-align: center;
        padding: 5px 10px 10px 25px;
        position: relative;
        margin-left: 6px;
        color: white;
        width: auto;
    }
    .step-menu li.current:after {
        border-left-color: rgba(51, 122, 183, 0.75);
    }
    .step-menu li.complete {
        background: rgba(51, 122, 183, 0.4);
        color: transparent;
    }
    .step-menu li.complete:after {
        border-left-color: rgba(51, 122, 183, 0.4);
    }
`;

const OpportunityChangesList = styled.ul`
    list-style-type: none;
    padding: 0;
    white-space: nowrap;
    ul {
        list-style-type: none;
        padding-left: 20px;
    }
`; */

export default class UCContainer extends React.Component {
    constructor(props) {
        super(props);
        document.title = 'HSPA - Understand Capacity';
        this.state = { activeTab: 0 };
    }
    componentWillMount() {
        let analyticsObj = analytics.getInstance();
        analyticsObj.trackPageChange({ pathName: 'UnderstandCapacity' });
        analyticsObj.trackTabChange('VA Health Care Resources');
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const tabs = [
            { key: 0, label: 'VA Health Care Resources' },
            { key: 1, label: 'Add/Edit Provider' },
            { key: 2, label: 'All Providers' }
        ];

        return (
            <div className="col-sm-12">
                <HeaderContainer pageName="Understand Capacity" />
                <ModuleNavigation
                    activeTab={this.state.activeTab}
                    tabs={tabs}
                    handleOnClick={this.changeTab}
                />
                {/* <div style={{ paddingTop: 20 }}>

                    <Card>
                        <div
                            style={{
                                fontSize: '1.6rem',
                                padding: 10,
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <span>
                                <FontAwesomeIcon icon={faStar} /> Opportunities
                            </span>
                            <Button variant="success">
                                <FontAwesomeIcon icon={faPlusSquare} /> Add a new opportunity{' '}
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </Button>
                        </div>
                        <TableStyled>
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: 300 }} />
                                <col style={{ width: 200 }} />
                                <col style={{ width: 100 }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Opportunity</th>
                                    <th>AIR</th>
                                    <th>Status</th>
                                    <th>Services Affected</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                    </td>
                                    <td>
                                        <Check />
                                    </td>
                                    <td>
                                        <OpportunityStageBar />
                                    </td>
                                    <td>
                                        <OpportunityChangesList>
                                            <li>
                                                <FontAwesomeIcon
                                                    icon={faClinicMedical}
                                                    style={{ color: '#6c757d' }}
                                                />{' '}
                                                (523GD) Plymouth
                                                <ul>
                                                    <Addition>Amb Medical: Cardiology</Addition>
                                                    <Addition>
                                                        Amb Medical: Gastroenterology
                                                    </Addition>
                                                    <Addition>Amb Medical: Optometry</Addition>
                                                    <Addition>Amb Medical: Neurology</Addition>
                                                    <Addition>
                                                        Amb Medical: Physical Medicine &amp;
                                                        Rehabilitation
                                                    </Addition>
                                                </ul>
                                            </li>
                                        </OpportunityChangesList>
                                    </td>
                                    <td>
                                        <OpportunityToggleButton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                                        lobortis consequat fermentum. Nam et lacus et ex ultricies
                                        feugiat. In.
                                    </td>
                                    <td>
                                        <Check />
                                    </td>
                                    <td>
                                        <OpportunityStageBar />
                                    </td>
                                    <td>
                                        <OpportunityChangesList>
                                            <li>
                                                <FontAwesomeIcon
                                                    icon={faClinicMedical}
                                                    style={{ color: '#6c757d' }}
                                                />{' '}
                                                (523BY) Lowell
                                            </li>
                                            <ul>
                                                <Addition>Amb Medical: Optometry</Addition>
                                                <Addition>
                                                    Amb Rehab Therapies: Physical Therapy
                                                </Addition>
                                            </ul>
                                        </OpportunityChangesList>
                                    </td>
                                    <td>
                                        <OpportunityToggleButton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Proin dictum congue mauris, sed lobortis eros dictum at. Ut
                                        tristique ipsum.
                                    </td>
                                    <td>
                                        <Check />
                                    </td>
                                    <td>
                                        <OpportunityStageBar />
                                    </td>
                                    <td>
                                        <OpportunityChangesList>
                                            <li>
                                                <span className="fa-layers fa-fw">
                                                    <FontAwesomeIcon icon={faClinicMedical} />
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        transform="shrink-6 right-6 down-6"
                                                        style={{ color: '#fff' }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        transform="shrink-4 right-6 down-6"
                                                        style={{ color: '#28a745' }}
                                                    />
                                                </span>{' '}
                                                Chicopee, MA
                                            </li>
                                            <ul>
                                                <Addition>Amb Medical: Optometry</Addition>
                                                <Addition>Amb Mental Health</Addition>
                                                <Addition>
                                                    Amb Primary Care: Geriatric Medicine
                                                </Addition>
                                                <Addition>
                                                    Amb Rehab Therapies: Physical Therapy
                                                </Addition>
                                                <Addition>Amb Rehab Therapies: Audiology</Addition>
                                                <Addition>
                                                    Amb Surgical: Obstetrics &amp; Gynecology
                                                </Addition>
                                                <Addition>Amb Surgical: Podiatry</Addition>
                                            </ul>
                                        </OpportunityChangesList>
                                    </td>
                                    <td>
                                        <OpportunityToggleButton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Realign services from (523GA) Framingham to a new facility
                                        in Chicopee, MA. Eliminate the Framingham CBOC.
                                    </td>
                                    <td>
                                        <Ban />
                                    </td>
                                    <td className="text-muted">Not Applicable</td>
                                    <td>
                                        <OpportunityChangesList>
                                            <li>
                                                <span className="fa-layers fa-fw">
                                                    <FontAwesomeIcon icon={faClinicMedical} />
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        transform="shrink-6 right-6 down-6"
                                                        style={{ color: '#fff' }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        transform="shrink-4 right-6 down-6"
                                                        style={{ color: '#28a745' }}
                                                    />
                                                </span>{' '}
                                                Chicopee, MA
                                            </li>
                                            <ul>
                                                <Addition>Primary Care</Addition>
                                                <Addition>Amb Mental Health</Addition>
                                            </ul>
                                            <li>
                                                <span className="fa-layers fa-fw">
                                                    <FontAwesomeIcon icon={faClinicMedical} />
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        transform="shrink-6 right-6 down-6"
                                                        style={{ color: '#fff' }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faMinusCircle}
                                                        transform="shrink-4 right-6 down-6"
                                                        style={{ color: '#dc3545' }}
                                                    />
                                                </span>{' '}
                                                (523GA) Framingham
                                            </li>
                                        </OpportunityChangesList>
                                    </td>
                                    <td>
                                        <OpportunityToggleButton />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Fusce dapibus fermentum ligula, in tristique ex porta in.
                                        Lorem ipsum dolor.
                                    </td>
                                    <td>
                                        <Check />
                                    </td>
                                    <td>
                                        <OpportunityStageBar />
                                    </td>
                                    <td>
                                        <OpportunityChangesList>
                                            <li>
                                                <span className="fa-layers fa-fw">
                                                    <FontAwesomeIcon icon={faClinicMedical} />
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        transform="shrink-6 right-6 down-6"
                                                        style={{ color: '#fff' }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        transform="shrink-4 right-6 down-6"
                                                        style={{ color: '#28a745' }}
                                                    />
                                                </span>{' '}
                                                Chicopee, MA
                                            </li>
                                            <ul>
                                                <Addition>Primary Care</Addition>
                                                <Addition>Amb Mental Health</Addition>
                                            </ul>
                                            <li>
                                                <span className="fa-layers fa-fw">
                                                    <FontAwesomeIcon icon={faClinicMedical} />
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        transform="shrink-6 right-6 down-6"
                                                        style={{ color: '#fff' }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faMinusCircle}
                                                        transform="shrink-4 right-6 down-6"
                                                        style={{ color: '#dc3545' }}
                                                    />
                                                </span>{' '}
                                                (650GD) Middletown
                                            </li>
                                        </OpportunityChangesList>
                                    </td>
                                    <td>
                                        <OpportunityToggleButton />
                                    </td>
                                </tr>
                            </tbody>
                        </TableStyled>
                    </Card>
                </div> */}
                <div style={{ paddingTop: 15 }}>
                    {this.state.activeTab === 0 ? <UCHealthCareResources /> : null}
                    {this.state.activeTab === 1 ? <UCManageProviders /> : null}
                    {this.state.activeTab === 2 ? (
                        <UCProvidersDashboard changeTab={this.changeTab} />
                    ) : null}
                </div>
            </div>
        );
    }
}
