import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { getJSON } from 'Utilities/apiCalls';

const understandDemandApi = `${process.env.REACT_APP_HSPA_API}/UnderstandDemand/`;

export const WorkloadAllocationMatrixModel = {
    state: {
        matrixTableData: { list: [], loading: false },
        hspcOptions: { list: [], loading: false }
    },
    reducers: {
        populate(state, data, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, object) {
            return {
                ...state,
                [object]: {
                    ...state[object],
                    loading: isLoading
                }
            };
        },
        resetTable(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false
                }
            };
        },
        resetTables(state) {
            return {
                matrixData: { list: [], loading: false },
                hspcOptions: { list: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchWorkloadAllocationMatrixAsync(payload, state) {
            try {
                this.resetTable('matrixTableData');
                this.updateLoading(true, 'matrixTableData');
                let result = await getJSON(
                    `${understandDemandApi}GetAllocationMatrix?visn=` +
                        payload.visn +
                        '&mpc=' +
                        payload.mpc
                );
                this.populate(result, 'matrixTableData');
                this.updateLoading(false, 'matrixTableData');
            } catch (error) {
                this.updateLoading(false, 'matrixTableData');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch workload allocation table data',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchMatrixHspcOptionsAsync(state) {
            try {
                this.resetTable('hspcOptions');
                this.updateLoading(true, 'hspcOptions');
                let result = await getJSON(`${understandDemandApi}GetMatrixMpcs`);
                this.populate(result, 'hspcOptions');
                this.updateLoading(false, 'hspcOptions');
            } catch (error) {
                this.updateLoading(false, 'hspcOptions');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market eligible ages',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
