/**
 * Custom hooks.
 *
 * @author Brandan D. Reed
 */
import { useEffect } from 'react';

/**
 * Run a side effect on mount only.
 *
 * @param {Function} effectFunction - function to run on mount
 */
const useMountEffect = (effectFunction) => {
    useEffect(effectFunction, []);
};

export default useMountEffect;
