import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { deleteRequest, getJSON, postJSON } from 'Utilities/apiCalls';

const matchApiUrl = `${process.env.REACT_APP_HSPA_API}/Match/`;

export const ManagePlansModel = {
    state: {
        Plans: { list: [], loading: false },
        CreatePlanForm: { loading: false },
        hspcList: { list: [], loading: false }
    },
    reducers: {
        populate(state, data, objectName) {
            return {
                ...state,
                [objectName]: {
                    ...state[objectName],
                    list: data
                }
            };
        },
        setLoading(state, objectName, loadingState) {
            return {
                ...state,
                [objectName]: {
                    ...state[objectName],
                    loading: loadingState
                }
            };
        },
        resetByName(state, name) {
            return {
                ...state,
                [name]: {
                    list: [],
                    loading: false
                }
            };
        },
        reset(state) {
            return {
                ...state,
                Plans: { list: [], loading: false },
                CreatePlanForm: { loading: false }
                // hspcList:       { list: [], loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchPlansAsync(market, state) {
            try {
                this.resetByName('Plans');
                this.setLoading('Plans', true);
                let result = await getJSON(`${matchApiUrl}GetPlansByMarket?marketName=` + market);
                this.populate(result, 'Plans');
                this.setLoading('Plans', false);
            } catch (error) {
                this.setLoading('Plans', false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch market plans',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchHSPCItemsAsync(state) {
            try {
                this.resetByName('hspcList');
                this.setLoading('hspcList', true);
                let result = await getJSON(`${matchApiUrl}GetCreatePlansDrpItems`);
                this.populate(result, 'hspcList');
                this.setLoading('hspcList', false);
            } catch (error) {
                this.setLoading('hspcList', false);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch HSPC items',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async selectPlanOptionAsync(payload, state) {
            try {
                await postJSON(`${matchApiUrl}SelectPlanOption`, {
                    spcId: payload.spcId,
                    marketName: payload.marketName,
                    planId: payload.planId,
                    year: payload.year,
                    justification: payload.justification
                });
                dispatch(
                    addNotification({
                        message: 'Final plan option has been selected',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to select plan',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async createPlanByHspcAsync(payload, state) {
            try {
                this.setLoading('CreatePlanForm', true);
                await postJSON(`${matchApiUrl}CreateSpcPlan`, {
                    name: payload.name,
                    spcId: payload.hspcID,
                    marketName: payload.marketName,
                    description: payload.description
                });
                this.fetchPlansAsync(payload.marketName);
                this.setLoading('CreatePlanForm', false);
                dispatch(
                    addNotification({
                        message: 'Plan has been created',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                this.setLoading('CreatePlanForm', false);
                if (error.status === 409)
                    dispatch(
                        addNotification({
                            message: 'Error: Plan for the selected HSPC already exists',
                            level: HSPANotificationLevel.ERROR
                        })
                    );
                else
                    dispatch(
                        addNotification({
                            message: 'Error: Unable to create plan',
                            level: HSPANotificationLevel.ERROR
                        })
                    );
            }
        },
        async deletePlanAsync(payload, state) {
            try {
                await deleteRequest(`${matchApiUrl}DeleteSpcPlan?id=` + payload.id);
                this.fetchPlansAsync(payload.marketName);
                dispatch(
                    addNotification({
                        message: 'The plan has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to delete plan',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
