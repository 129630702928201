import { stringToHash } from 'Utilities/formatters';

/**
 * Gets the row key based on the keyValue property.
 *
 * @param keyValue - The keyValue of the property identifier.
 * @returns - The row key function.
 */
const getRowIdFunction = (keyValue: string) => (originalRow: unknown, index: number): string => {
    if (typeof originalRow === 'object' && originalRow !== null && keyValue in originalRow) {
        if (typeof originalRow[keyValue] === 'number') {
            const result = originalRow[keyValue] as number;
            return result.toString();
        }

        const result = (stringToHash(originalRow[keyValue] as string) as unknown) as string;
        return result;
    }

    return index.toString();
};

export default getRowIdFunction;
