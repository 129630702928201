import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Fade, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { debounce, filter, groupBy, includes, map, orderBy, uniqBy } from 'lodash';
import { useHSPASelector } from 'storeTypes';
import styled from 'styled-components/macro';

import { selectCapitalProjectsCreateStatus } from 'Modules/CapitalProject/selectors';
import { getCurrentMarketName } from 'Modules/UserSession/selector';
import { AsyncState } from 'Utilities/constants';
import useParentRoute from 'Utilities/hooks/useParentRoute';

import CreatePlanModal from './CreatePlanModal';
import PlanCard from './PlanCard';

const LoadingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 20rem;
    flex-direction: column;
    align-items: center;
`;

const BigSpinner = styled(Spinner)`
    width: 4rem;
    height: 4rem;
`;

const LoadingLabel = styled.span`
    font-size: 14pt;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
`; 

const PlanManagement = () => {
    const dispatch = useDispatch();
    const marketName = useSelector(getCurrentMarketName);
    const plansList = useHSPASelector((state) => state.ManagePlansModel.Plans.list);
    const plansLoading = useHSPASelector((state) => state.ManagePlansModel.Plans.loading);
    const capitalProjectCreateStatus = useSelector(selectCapitalProjectsCreateStatus);
    const [workloadTypeFilter, setWorkloadTypeFilter] = useState([]);
    const [careTypeFilter, setCareTypeFilter] = useState([]);
    const [plans, setPlans] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailureAlert, setShowFailureAlert] = useState(false);
    const capitalProjectRoute = useParentRoute() + '/StrategicOutlook/capitalprojects';
    const [workloadTypes, setWorkLoadTypes] = useState([]);
    const careTypes = [
        {label: "Inpatient", value: "Inpatient"},
        {label: "Outpatient", value: "Outpatient"},
        {label: "Primary", value: "Primary"}
    ];

    const setSearch = debounce(setSearchFilter, 200);

    const setupWorkloadTypes = () => {
        const types = map(
            orderBy(
                uniqBy(plansList, (plan) => plan.CurrentHspcWorkloadType),
                ['CurrentHspcWorkloadTypeString'],
                ['asc']
            ),
            (plan) => ({
                label: plan.CurrentHspcWorkloadTypeString,
                value: plan.CurrentHspcWorkloadTypeString
            })
        );

        setWorkLoadTypes(types);
    };

    const setupPlans = () => {
        if (plansLoading || !plansList || plansList.length <= 0) {
            setPlans([]);
            return;
        }

        let planList = plansList;        
        if (workloadTypeFilter && workloadTypeFilter.length > 0) {
            planList = filter(
                planList,
                (plan) => includes(map(workloadTypeFilter, (filter) => filter.value), plan.CurrentHspcWorkloadTypeString)
            );
        }

        if (careTypeFilter && careTypeFilter.length > 0) {
            planList = filter(
                planList,
                (plan) => includes(map(careTypeFilter, (filter) => filter.value), plan.Hspc.MpcType)
            )
        }

        if (searchFilter) {
            const formattedFilter = searchFilter.toLowerCase();
            planList = filter(
                planList,
                (plan) => plan.Id.toString().includes(formattedFilter)
                || plan.SPCString.toLowerCase().includes(formattedFilter) 
                || (plan.PlanName && plan.PlanName.toLowerCase().includes(formattedFilter))
                || (plan.Description && plan.Description.toLowerCase().includes(formattedFilter))
            )
        }

        const planGroup = groupBy(planList, p => p.SPCString);

        setPlans(planGroup);
    };

    // Get all plans whenever the market is changed
    useEffect(() => {
        if (marketName) {
            dispatch.ManagePlansModel.fetchPlansAsync(marketName);
        }
    }, [dispatch, marketName]);

    useEffect(() => {
        setupPlans();
    }, [workloadTypeFilter, careTypeFilter, searchFilter, plansLoading, plansList]);

    useEffect(() => {
        setupWorkloadTypes();
    }, [plansList]);

    const planCards = () => {
        let cards = []
        for (const [key, value] of Object.entries(plans)) {
            cards.push(<PlanCard key={key} hspc={key} plans={value} />);
        }
        return cards;
    }

    const closeSuccessAlert = () => {
        setShowSuccessAlert(false);
    }

    const closeFailureAlert = () => {
        setShowFailureAlert(false);
    }

    useEffect(() => {
        setShowSuccessAlert(capitalProjectCreateStatus === AsyncState.SUCCESS)
        setShowFailureAlert(capitalProjectCreateStatus === AsyncState.FAILURE)
    }, [capitalProjectCreateStatus]);

    const loadingSpinner = (
        <Fade in={plansLoading} unmountOnExit>
            <LoadingContainer>
                <BigSpinner variant="secondary" animation="border" />
                <LoadingLabel>Loading Plans...</LoadingLabel>
            </LoadingContainer>
        </Fade>
    );

    return (
        <>
            {loadingSpinner}
            <Container fluid className="mt-3 col-sm-12">
                <Row className="m1-2">
                    <Col>
                        <Alert show={showSuccessAlert} onClose={() => closeSuccessAlert()} variant='success' dismissible>
                            Capital Project was successfully created.  <Link to={capitalProjectRoute}>Click here to go the Capital Project page.</Link>
                        </Alert>
                    </Col>
                </Row>
                <Row className="m1-2">
                    <Col>
                        <Alert show={showFailureAlert} onClose={() => closeFailureAlert()} variant='error' dismissible>
                            Failed to create the capital project.  Contact your administrator for help.
                        </Alert>
                    </Col>
                </Row>
                <Row className="ml-1 mb-2" style={{ justifyContent: 'center'}}>
                    <Col xs="2" className="text-sm-right">
                        <CreatePlanModal />
                    </Col>
                    <Col xs="3">
                        <Select
                            id="workloadFilter"
                            aria-label="Workload Type"
                            isDisabled={plansLoading}
                            options={workloadTypes}
                            placeholder="Filter by Workload Type"
                            value={workloadTypeFilter}
                            onChange={(values) => setWorkloadTypeFilter(values || [])}
                            isMulti
                        />
                    </Col>
                    <Col xs="3">
                        <Select
                            id="careTypeFilter"
                            aria-label="Care Type"
                            isDisabled={plansLoading}
                            options={careTypes}
                            placeholder="Filter by Care Type"
                            value={careTypeFilter}
                            onChange={(values) => setCareTypeFilter(values || [])}
                            isMulti
                        />
                    </Col>
                    <Col xs="3">
                        <Form.Control
                            type="text"
                            placeholder="Search Plans"
                            aria-label="Search Plans"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row xs={1} md={4} className="g-4 ml-1">
                    {plansLoading ? <></> : planCards()}
                </Row>
            </Container>
        </>
    );
};

export default PlanManagement;
