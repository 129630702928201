import { setCapitalProjectApproval as setCapitalProjectApprovalAPI } from 'Data/capital-projects';
import epicRequest from 'Utilities/epicRequest';
import { ApprovalUpdate, CapitalProject } from 'Utilities/types';

import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
    capitalProjectApprovalSet,
    setCapitalProjectApproval as setCapitalProjectApprovalAction,
    setCapitalProjectApprovalDone,
    setCapitalProjectApprovalError,
    setCapitalProjectApprovalStart
} from '../capitalProjectsSlice';

/**
 * An epic that listens for approveCapitalProject actions and calls the API.
 *
 * @param action$ - A stream of the redux actions dispatched to the application's redux store.
 * @returns Actions to set capital projects on load.
 */
const setCapitalProjectApproval = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        filter(setCapitalProjectApprovalAction.match),
        epicRequest({
            payloadReducer: undefined,
            /**
             * Send a patch request to update the Capital Project approval.
             *
             * @param action - The action to take.
             * @param action.payload - The approval update.
             * @returns An observable of redux actions.
             */
            request: ({ payload }: PayloadAction<ApprovalUpdate>) =>
                from(setCapitalProjectApprovalAPI(payload)).pipe(
                    map((capitalProject) =>
                        capitalProjectApprovalSet(capitalProject as CapitalProject)
                    )
                ),
            actions: {
                start: setCapitalProjectApprovalStart,
                done: setCapitalProjectApprovalDone,
                fail: setCapitalProjectApprovalError
            }
        })
    );

export default setCapitalProjectApproval;
