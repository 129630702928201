import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentVISN } from 'Modules/UserSession/selector';

/**
 * A component to display a link for the user to open the Recomendations to the AIR Commission PDF
 */
const AIRCommissionReport = (): JSX.Element => {

    type VISN = {
        VisnNumber: number;
        VisnCode: string;
        DisplayName: string;
        VisnDataId: number;
        Year: number;
        Id: number;
    };

    const visn: VISN = useSelector(getCurrentVISN);
    const visnNumber: string = visn && visn.VisnCode ? visn.VisnCode.split('V')[1] : '';
    const airReportLink = `https://www.va.gov/AIRCOMMISSIONREPORT/docs/VISN${visnNumber}-Market-Recommendation.pdf`;

    return visnNumber ? (
        <a
            href={airReportLink}
            target="_blank"
            className="btn btn-primary"
            style={{
                margin: '0px 0px 10px',
                display: 'block'
            }}
            rel="noopener noreferrer"
        >
            <i className="mr-2 fas fa-file-pdf" />
            Market Assessment Recommendations - VISN {visnNumber}
        </a>
    ) : (
        <></>
    );
};

export default AIRCommissionReport;
