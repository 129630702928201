import React, { Component } from 'react';

export default class ESHealthCareEnvironment extends Component {
    render() {
        return (
            <div className="col-sm-12">
                <br/>
                <p>The links below may be useful in reviewing the health care environment external to the VA health care system.</p>
                <table style={{borderCollapse: "collapse"}}>
                    <tbody>
                        <tr>
                            <th style={{textAlign: "center", width: '20%'}}>Area</th>
                            <th style={{textAlign: "center", width: '20%'}}>Resource</th>
                            <th style={{textAlign: "center"}}>Description</th>
                        </tr>
                        <tr>
                            <td rowSpan="2" style={{textAlign: "center"}}>Populations, Trends, and Utilization</td>
                            <td style={{textAlign: "center"}}><a href="http://www.dartmouthatlas.org/data/region/" style={{textDecoration: 'underline'}}  target="_blank" rel="noopener noreferrer">Dartmouth Atlas of Health Care</a></td>
                            <td style={{textAlign: "center"}}>Leverages Medicare data to provide information and analysis about national, regional, and local Medicare markets, as well as hospitals and their affiliated physicians. This research has helped broad audiences improve their understanding of the US health care system and forms the foundation for many of the ongoing efforts to improve health and health systems across America.</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}><a href="http://kff.org/statedata/" style={{textDecoration: 'underline'}}  target="_blank" rel="noopener noreferrer">Kaiser Family Foundation State Health Facts </a></td>
                            <td style={{textAlign: "center"}}>Provides a great deal of information about the health care environment across numerous categories covering areas such as demographics, health insurance coverage, health status, Medicare/Medicaid/CHIP, and providers and service use.</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Medically Underserved Areas (MUA)</td>
                            <td style={{textAlign: "center"}}><a href="http://muafind.hrsa.gov/index.aspx" style={{textDecoration: 'underline'}}  target="_blank" rel="noopener noreferrer">HHS MUA Finder tool</a></td>
                            <td style={{textAlign: "center"}}>Allows you to research which counties or areas in the market are considered Medically Underserved. MUAs are designated by HHS’s Health Resources and Services Administration (HRSA) as having: too few primary care providers, high infant mortality, high poverty and/or high elderly population. MUAs may be a whole county or a group of contiguous counties, a group of county or civil divisions or a group of urban census tracts in which residents have a shortage of health services. Identifying underserved areas in the market in conjunction with enrollee populations helps inform appropriate strategies for improving access to care to Veterans who live in underserved areas.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}