import './style.css';

import React from 'react';
import { Variant } from 'react-bootstrap/esm/types';

type CalloutProps = {
    variant: Variant;
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
};

/**
 * A component that uses the classic callout appearance that Bootstrap used in their old
 * documentation.  It resembles a modern Card component with a line of color on the left-hand side.
 *
 * @param props - props passed into this component
 * @param props.variant - the Bootstrap color variant for this callout
 * @param props.children - content to display within the callout
 * @param props.style - styling for this component
 * @param props.className - class name for this component
 * @returns the callout with its content
 */
const Callout = ({
    variant = 'default',
    children,
    style,
    className
}: CalloutProps): JSX.Element => {
    const classNames = ['bs-callout'];
    classNames.push(`bs-callout-${variant}`);

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} style={style}>
            {children}
        </div>
    );
};

export default Callout;
