import { Slides } from './DDFSlides';
import devData from './slides.json';
import devDataUnofficial from './ToCOutputUnofficial.json';
import prodData from './TOCOutputUnofficial_VAProd.json';
import testData from './ToCOutputUnofficial_VATest.json';

const useOfficial = false;

function getSource() {
    let environment = process.env.REACT_APP_SERVER;
    switch (environment) {
        case 'DEV':
        case 'DEV_LOCAL':
            if (useOfficial) {
                return (devData as unknown) as Slides[];
            } else {
                return (devDataUnofficial as unknown) as Slides[];
            }
        case 'TEST':
            return (testData as unknown) as Slides[];
        case 'PROD':
            return (prodData as unknown) as Slides[];
        case undefined:
        default:
            return (devData as unknown) as Slides[];
    }
}

const slidesSource = getSource();

export default slidesSource;
