import logo from 'Content/images/va-logo-white.png';
import React from 'react';
import styled from 'styled-components/macro';

const Header = styled.div`
    background-color: #05263f;
`;

const Logo = styled.img`
    width: 300px;
`;

/**
 * React Element for placing a header with VA logo on a page
 *
 * @returns {JSX.Element} component to render the VA header
 */
const VAHeader = (): JSX.Element => (
    <Header>
        <Logo className="img-responsive" role="complementary" src={logo} alt="VA Logo" />
    </Header>
);

export default VAHeader;
