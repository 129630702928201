import React from 'react';
import { connect } from 'react-redux';

import noop from 'lodash';

/**
 * The Home page for the HSPA application.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import Albany from 'Content/images/bgs/AlbanyNT.jpg';
import Augusta from 'Content/images/bgs/AugustaGA.jpg';
import Biloxi from 'Content/images/bgs/BiloxiMS.jpg';
import Lexington from 'Content/images/bgs/LexingtonKY.jpg';
import SaltLake from 'Content/images/bgs/SaltLakeUT.jpg';
import Waco from 'Content/images/bgs/WacoTX.jpg';
import Washington from 'Content/images/bgs/WashingtonDC.jpg';
import {
    getDefaultYear,
    getMarketList,
    getUserProfile,
    getVISNList
} from 'Modules/UserSession/selector';
import PropTypes from 'prop-types';
import analytics from 'Utilities/analytics';

import HSPACarouselItem from './HSPACarouselItem';

/**
 * Home component for HSPA.  This is the top-level component of HSPA's home page content.
 */
class Home extends React.Component {
    static propTypes = {
        marketList: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
        userProfile: PropTypes.shape({}),
        defaultYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        getUserProfile: PropTypes.func,
        getDefaultYear: PropTypes.func
    };

    static defaultProps = {
        userProfile: {},
        getUserProfile: noop,
        getDefaultYear: noop
    };

    /**
     * The HSPA Home constructor
     *
     * @param {object} props - props passed into this component
     * @param {object} context - React context for this component
     */
    constructor(props, context) {
        super(props, context);
        document.title = 'HSPA';
    }

    /**
     * This is called when the component is first mounted to the DOM.
     * This makes the initial requests to get the current user's profile and the default data year.
     */
    componentWillMount() {
        const { marketList, userProfile, defaultYear, getDefaultYear, getUserProfile } = this.props;

        if (!userProfile || marketList.list.length === 0) {
            getUserProfile();
        }
        if (defaultYear === '') {
            getDefaultYear();
        }

        const analyticsObj = analytics.getInstance();
        analyticsObj.trackPageChange({ pathName: 'Home' });
    }

    /**
     * Renders the Home page for HSPA.
     *
     * @returns {React.ReactNode} the React elements to render to the DOM
     */
    render() {
        const carouselProps = [
            {
                backgroundImage: Waco,
                isActive: true,
                title: 'HEALTH SYSTEMS PLANNING APPLICATION',
                header: 'Introduction',
                key: 'carouselIntroduction',
                body: (
                    <>
                        Welcome to the Health Systems Planning Application (HSPA)! HSPA is a tool
                        that assists VHA, VISN and facility strategic planners in:
                        <br />
                        <br />
                        1) Assessing their local Veteran population, including eligible and enrolled
                        Veterans, as well as trends impacting Veterans associated health care;
                        <br />
                        2) Identifying projected imbalances in health care capacity;
                        <br />
                        3) And visualizing market supply and demand. The analyses and assessments
                        conducted by VISN and facility strategic planners using the HSPA 5, 10, and
                        20 year planning horizons will enable VHA leaders to develop strategies,
                        initiatives, projects, and products that best serve the needs of Veterans.
                        <br />
                        <br />
                        <small>
                            To request HSPA access, verify your VA email address (i.e.
                            john.smith@va.gov) and e-mail it to your
                            <> </>
                            <a
                                href="http://vaww.va.gov/VHAOPP/10P1B_liaisons.asp"
                                style={{ textDecoration: 'underline' }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                VISN Planner
                            </a>
                            .
                        </small>
                    </>
                )
            },
            {
                backgroundImage: Albany,
                headerLinkPath: 'StrategicOutlook',
                header: 'Strategic Outlook',
                key: 'carouselStrategicOutlook',
                body:
                    'The Strategic Outlook module was developed to provide visual markers for ' +
                    'overall utilization thresholds within a VISN or market.'
            },
            {
                backgroundImage: Lexington,
                headerLinkPath: 'EnvironmentalScan',
                header: 'Environmental Scan',
                key: 'carouselEnvironmentalScan',
                body:
                    'The Environmental Scan Module provides quick access to internal documents ' +
                    'that set the planning foundation for VA’s strategic direction and the ' +
                    'resources for assessing the local and national health care climate.  In ' +
                    'addition, the module provides Veteran population demographics, including ' +
                    'those living beyond 40 miles and by rurality designations.'
            },
            {
                backgroundImage: SaltLake,
                headerLinkPath: 'UnderstandDemand',
                header: 'Understand Demand',
                key: 'carouselUnderstandDemand',
                body:
                    'The tabs in the Understand Demand Module show projections for utilization, ' +
                    'patient origin, and workload to help the planner understand Veteran current ' +
                    'and future needs from both a market and facility perspective.'
            },
            {
                backgroundImage: Biloxi,
                headerLinkPath: 'UnderstandCapacity',
                header: 'Understand Capacity',
                key: 'carouselUnderstandCapacity',
                body:
                    'The Understand Capacity Module organizes VA, public sector, and private ' +
                    'sector health care delivery resources to enable improved understanding of ' +
                    'local assets that can assist in serving Veterans.  These resources include ' +
                    'FQHCs, DoD facilities, IHS facilities, and academic affiliates.'
            },
            {
                backgroundImage: Washington,
                headerLinkPath: 'Match',
                header: 'Match Capacity and Demand',
                key: 'carouselMatch',
                body:
                    'The Match Capacity/Demand Module visually displays health care delivery ' +
                    'gaps between a market’s capacity and health care supply based on the needs ' +
                    'of the Veteran.  This Module also allows the planner to adjust and account ' +
                    'for local conditions and partnerships which may not be captured nationally.'
            },
            {
                backgroundImage: Augusta,
                headerLinkPath: 'Reports',
                header: 'Report',
                key: 'carouselReports',
                body:
                    'HSPA presents an updated BY19 Reports Module.  The features includes ' +
                    'selectable components of HSPA that will produce a template report for ' +
                    'communicating strategic planning across VISN levels.'
            }
        ];

        const carouselIndicators = carouselProps.map((props, index) => (
            <li
                key={props.header}
                data-target="#myCarousel"
                data-slide-to={index}
                className={props.isActive ? 'active' : ''}
            />
        ));
        const items = carouselProps.map((itemProps) => (
            <HSPACarouselItem
                key={itemProps.key}
                backgroundImage={itemProps.backgroundImage}
                headerLinkPath={itemProps.headerLinkPath}
                isActive={itemProps.isActive}
                title={itemProps.title}
                header={itemProps.header}
                body={itemProps.body}
            />
        ));

        return (
            <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
                data-interval={false}
            >
                <ol className="carousel-indicators">{carouselIndicators}</ol>

                <div className="carousel-inner">{items}</div>
                <a
                    className="carousel-control-prev"
                    href="#myCarousel"
                    role="button"
                    data-slide="prev"
                >
                    <span className="carousel-control-prev-icon" />
                    <span className="sr-only">Previous</span>
                </a>
                <a
                    className="carousel-control-next"
                    href="#myCarousel"
                    role="button"
                    data-slide="next"
                >
                    <span className="carousel-control-next-icon" />
                    <span className="sr-only">Next</span>
                </a>
            </div>
        );
    }
}

/**
 * @param {Object} dispatch - props component needs to dispatch
 * @returns {Object} mapped functions
 */
const mapDispatchToProps = (dispatch) => ({
    getUserProfile: dispatch.UserSessionModel.fetchUserProfileAsync,
    getDefaultYear: dispatch.UserSessionModel.fetchDefaultYear
});

/**
 * Get prop values for this component from the application Redux state.
 *
 * @param {Object} state - the application Redux state
 * @returns {Object} props for this component
 */
const mapStateToProps = (state) => ({
    marketList: getMarketList(state),
    visnList: getVISNList(state),
    defaultYear: getDefaultYear(state),
    userProfile: getUserProfile(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
