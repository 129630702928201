import React from 'react';

import DDFContainer from 'Lib/DDFSlides/DDFContainer';

type FacilityReportDDFContainerProps = {
    stationNumber: string;
};

const facilitySlideConfig = [
    {
        sectionHeader: 'Inpatient Campus Snapshot',
        sectionSlides: [137]
    },
    {
        sectionHeader: 'Inpatient Campus and Outpatient Care Locations',
        sectionSlides: [138]
    },
    {
        sectionHeader: 'Investment',
        sectionSlides: [139]
    },
    {
        sectionHeader: 'Site Plan',
        sectionSlides: [140]
    },
    {
        sectionHeader: 'Building Inventory (Owned)',
        sectionSlides: [141]
    },
    {
        sectionHeader: 'Building Inventory (Leased)',
        sectionSlides: [142]
    },
    {
        sectionHeader: 'Summary of Architectural and Engineering Challenges',
        sectionSlides: [143]
    },
    {
        sectionHeader: 'Active and Planned Projects',
        sectionSlides: [144]
    }
];

const missionSlideConfig = [
    {
        sectionHeader: 'Medical Education/Training',
        sectionSlides: [147, 148]
    },
    {
        sectionHeader: 'Research',
        sectionSlides: [149, 150]
    }
];

/**
 * Renders a button that opens a modal with DD&F slide data related to a facility.
 *
 * @param props - props passed into this component
 * @param props.stationNumber - the facility station number
 * @returns the DDF button container
 */
const FacilityReportDDFContainer = ({
    stationNumber
}: FacilityReportDDFContainerProps): JSX.Element => (
    <DDFContainer
        text="There is relevant data published in the Data Discovery and Findings."
        reports={[
            {
                title: `Facility (${stationNumber}) Slides`,
                subSections: facilitySlideConfig,
                facilityId: stationNumber
            },
            {
                title: `Mission (${stationNumber}) Slides`,
                subSections: missionSlideConfig,
                facilityId: stationNumber
            }
        ]}
    />
);

export default FacilityReportDDFContainer;
