import React from 'react';
import proptypes from 'prop-types';
import { Field } from 'redux-form';
import FontAwesome from 'react-fontawesome';
import './style.css';

export default class TextAreaField extends React.Component {
    render() {
        return (
            <Field
                name={this.props.name}
                label={this.props.label}
                placeholder={
                    this.props.placeholder === ''
                        ? 'Enter ' + this.props.label.toLowerCase() + '...'
                        : this.props.placeholder
                }
                disabled={this.props.disabled}
                labelPosition={this.props.labelPosition}
                inputPosition={this.props.inputPosition}
                loading={this.props.loading}
                numberOfRows={this.props.numberOfRows}
                type="text"
                caption={this.props.caption}
                component={TextAreaField.renderTextAreaField}
            />
        );
    }

    static renderTextAreaField(props) {
        let validationState = '';
        if (props.meta.touched) {
            if (props.meta.error) validationState = 'invalidFormInput';
            else validationState = 'validFormInput';
        }
        return (
            <div className={'form-group row'}>
                <div className={'col-sm-' + props.labelPosition + ' col-form-label'}>
                    <strong>{props.label + ':'}</strong>
                </div>
                {props.caption !== null ? (
                    <div className={'col-sm-' + props.labelPosition}>{props.caption}</div>
                ) : null}
                <div className={'col-sm-' + props.inputPosition}>
                    <textarea
                        className={'form-control ' + validationState}
                        {...props.input}
                        id={props.name}
                        placeholder={props.placeholder}
                        rows={props.numberOfRows}
                        disabled={props.disabled}
                        aria-label={props.label}
                    />
                    {props.loading === true ? (
                        <div className="feedback-icon">
                            <FontAwesome name="check" spin className="fa fa-spinner" />
                        </div>
                    ) : null}
                    <div className="invalid-feedback d-block">
                        {props.meta.touched && props.meta.error ? props.meta.error : null}
                    </div>
                </div>
            </div>
        );
    }
    static propTypes = {
        name: proptypes.string.isRequired,
        label: proptypes.string.isRequired,
        placeholder: proptypes.string,
        labelPosition: proptypes.number,
        inputPosition: proptypes.number,
        disabled: proptypes.bool,
        numberOfRows: proptypes.number,
        loading: proptypes.bool
        // caption: proptypes.Component
    };
}
TextAreaField.defaultProps = {
    placeholder: '',
    disabled: false,
    labelPosition: 12,
    inputPosition: 12,
    numberOfRows: 6,
    loading: false,
    caption: null
};
