import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON } from 'Utilities/apiCalls';

const envscanApi = `${process.env.REACT_APP_HSPA_API}/EnvironmentalScan/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const RuralityModel = {
    state: {
        ruralityTotals: { list: [], generating: false, loading: false },
        ruralityPercent: { list: [], generating: false, loading: false },
        ruralityCounty: { list: [], generating: false, loading: false }
    },
    reducers: {
        populate(state, data, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: isLoading
                }
            };
        },
        updateGenerating(state, isGenerating, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    generating: isGenerating
                }
            };
        },
        updateSaving(state, isSaving, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    saving: isSaving
                }
            };
        },
        resetTable(state, tableName) {
            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false,
                    generating: false
                }
            };
        },
        resetTables(state) {
            return {
                ...state,
                ruralityTotals: { list: [], generating: false, loading: false },
                ruralityPercent: { list: [], generating: false, loading: false },
                ruralityCounty: { list: [], generating: false, loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchRuralityTotalsAsync(market, state) {
            try {
                this.resetTable('ruralityTotals');
                this.updateLoading(true, 'ruralityTotals');
                let result = await getJSON(
                    `${envscanApi}GetUrbanRuralByMarket?market=${market}&include=false`
                );
                this.populate(result, 'ruralityTotals');
                this.updateLoading(false, 'ruralityTotals');
            } catch (error) {
                this.updateLoading(false, 'ruralityTotals');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch rurality totals data',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchRuralityPercentAsync(market, state) {
            try {
                this.resetTable('ruralityPercent');
                this.updateLoading(true, 'ruralityPercent');
                let result = await getJSON(
                    `${envscanApi}GetUrbanRuralByMarket?market=${market}&include=true`
                );
                this.populate(result, 'ruralityPercent');
                this.updateLoading(false, 'ruralityPercent');
            } catch (error) {
                this.updateLoading(false, 'ruralityPercent');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch rurality percent data',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchRuralityCountyAsync(market, state) {
            try {
                this.resetTable('ruralityCounty');
                this.updateLoading(true, 'ruralityCounty');
                let result = await getJSON(`${envscanApi}GetRuralityByMarket?market=${market}`);
                this.populate(result, 'ruralityCounty');
                this.updateLoading(false, 'ruralityCounty');
            } catch (error) {
                this.updateLoading(false, 'ruralityCounty');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to rurality county data',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generatePowerPointAsync(payload, state) {
            try {
                let table = '';
                if (payload.ruralityTable === 'Rurality1') table = 'ruralityTotals';
                else if (payload.ruralityTable === 'Rurality2') table = 'ruralityPercent';
                else if (payload.ruralityTable === 'RuralityByCounty') table = 'ruralityCounty';

                this.updateGenerating(true, table);
                await downloadFile(
                    `${exportApiUrl}ExportRuralityTablesPPT?ruralityTableName=` +
                        payload.ruralityTable +
                        '&market=' +
                        payload.market +
                        '&visnCode=' +
                        payload.visnCode
                );
                this.updateGenerating(false, table);
            } catch (error) {
                let table = '';
                if (payload.ruralityTable === 'Rurality1') table = 'ruralityTotals';
                else if (payload.ruralityTable === 'Rurality2') table = 'ruralityPercent';
                else if (payload.ruralityTable === 'RuralityByCounty') table = 'ruralityCounty';

                this.updateGenerating(false, table);
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate rurality PowerPoint',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
