import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import { downloadFile, getJSON, putJSON } from 'Utilities/apiCalls';

const matchApiUrl = `${process.env.REACT_APP_HSPA_API}/Match/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

// TODO: Replace with React Toolkit

export const PlannerModel = {
    state: {
        Plan: {
            planData: {},
            loading: false,
            saving: false,
            generating: false
        },
        DcspChartLarge: [],
        AddProviderModal: { show: false },
        JustificationModal: { show: false, planItem: {}, calculationType: '' }
    },
    reducers: {
        setPlan(state, data) {
            return {
                ...state,
                Plan: {
                    ...state.Plan,
                    planData: data
                }
            };
        },
        editWorkspaceData(state, payload) {
            return {
                ...state,
                Plan: {
                    ...state.Plan,
                    planData: {
                        ...state.Plan.planData,
                        [payload.calcType]: state.Plan.planData[payload.calcType].map((item) =>
                            item.Id === payload.rowID
                                ? { ...item, [payload.element]: Number(payload.value) }
                                : item
                        )
                    }
                }
            };
        },
        addJustification(state, payload) {
            let isFee = payload.planItem.SupplyFee || false;
            let tableObj =
                payload.calculationType === 'Staffing'
                    ? `Staffing${payload.planItem.tableType}`
                    : payload.planItem.tableType;
            if (isFee) {
                return {
                    ...state,
                    Plan: {
                        ...state.Plan,
                        planData: {
                            ...state.Plan.planData,
                            [tableObj]: state.Plan.planData[tableObj].map((item, i) =>
                                item.Id === payload.planItem.Id
                                    ? {
                                          ...item,
                                          Justification: payload.justification
                                      }
                                    : item
                            ),
                            OverheadRows: state.Plan.planData.OverheadRows.map((item) =>
                                item.Id === payload.planItem.Id
                                    ? {
                                          ...item,
                                          Justification: payload.justification
                                      }
                                    : item
                            )
                        }
                    }
                };
            }
            return {
                ...state,
                Plan: {
                    ...state.Plan,
                    planData: {
                        ...state.Plan.planData,
                        [tableObj]: state.Plan.planData[tableObj].map((item, i) =>
                            item.Id === payload.planItem.Id
                                ? {
                                      ...item,
                                      Justification: payload.justification
                                  }
                                : item
                        )
                    }
                }
            };
        },
        changePlanCalculationType(state, payload) {
            return state;
            return {
                ...state,
                Plan: {
                    ...state.Plan,
                    calculationType: payload.calculationType
                }
            };
        },
        toggleProviderModal(state) {
            return {
                ...state,
                AddProviderModal: {
                    ...state.AddProviderModel,
                    show: !state.AddProviderModal.show
                }
            };
        },
        toggleJustificationModal(state, payload) {
            return {
                ...state,
                JustificationModal: {
                    show: !state.JustificationModal.show,
                    planItem: payload.planItem || {},
                    calculationType: payload.calculationType
                }
            };
        },
        toggleLoading(state, objectName) {
            return {
                ...state,
                [objectName]: {
                    ...state[objectName],
                    loading: !state[objectName].loading
                }
            };
        },
        toggleSaving(state) {
            return {
                ...state,
                Plan: {
                    ...state.Plan,
                    saving: !state.Plan.saving
                }
            };
        },
        toggleGenerating(state) {
            return {
                ...state,
                Plan: {
                    ...state.Plan,
                    generating: !state.Plan.generating
                }
            };
        },
        reset(state) {
            return {
                Plan: {
                    planData: {},
                    loading: false,
                    saving: false,
                    generating: false
                },
                DcspChartLarge: [],
                AddProviderModal: { show: false },
                JustificationModal: { show: false, planItem: {}, calculationType: '' }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchPlanByIdAsync(id, state) {
            try {
                this.reset();
                this.toggleLoading('Plan');
                let result = await getJSON(`${matchApiUrl}GetPlan?id=${id}`);
                await this.setPlan(result);
                this.toggleLoading('Plan');
            } catch (error) {
                this.toggleLoading('Plan');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch plan',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async resetPlanRowAsync(payload, state) {
            try {
                await putJSON(
                    `${matchApiUrl}ResetPlanRecord?id=` +
                        payload.rowId +
                        '&rowType=' +
                        payload.rowType,
                    {}
                );
                this.fetchPlanByIdAsync(payload.planId);
                dispatch(
                    addNotification({
                        message: payload.objectName + ' has been reset.',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to reset ' + payload.objectName + '.',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async updateWorkspaceData(payload, state) {
            await this.editWorkspaceData(payload);
        },
        async savePlanAsync(payload, state) {
            try {
                let plan = {
                    Plan: state.PlannerModel.Plan.planData.Plan,
                    Capacity: state.PlannerModel.Plan.planData.Capacity,
                    Supply: state.PlannerModel.Plan.planData.Supply,
                    StaffingSupply: state.PlannerModel.Plan.planData.StaffingSupply,
                    StaffingCapacity: state.PlannerModel.Plan.planData.StaffingCapacity
                };
                this.reset();
                this.toggleSaving();
                await putJSON(`${matchApiUrl}SavePlan`, plan);
                this.toggleSaving();
                this.fetchPlanByIdAsync(plan.Plan.Id);
                dispatch(
                    addNotification({
                        message: 'Plan has been updated',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                this.toggleSaving();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to update plan',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generatePlanReportAsync(payload, state) {
            try {
                this.toggleGenerating();
                await downloadFile(
                    `${exportApiUrl}ExportDcsp?id=` +
                        payload.planId +
                        '&marketName=' +
                        payload.marketName
                );
                this.toggleGenerating();
            } catch (error) {
                this.toggleGenerating();
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate excel document',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
