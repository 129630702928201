import {
    addNotification,
    HSPANotificationLevel
} from 'Modules/App/HSPANotifications/notifications';
import {
    deleteRequest,
    downloadFile,
    downloadFileByFormData,
    getJSON,
    postJSON
} from 'Utilities/apiCalls';

const reportsApiUrl = `${process.env.REACT_APP_HSPA_API}/Report/`;
const exportApiUrl = `${process.env.REACT_APP_HSPA_API}/Export/`;

export const ReportFormModel = {
    state: {
        savedMapUrls: { list: [], loading: false },
        patientOriginToolConfigs: { list: [], loading: false },
        facilitiesWaitTimeRetrieval: { list: [], loading: false },
        analysisToolConfigs: { list: [], loading: false },
        ReportForm: { data: {}, saving: false, generating: false, loading: false }
    },
    reducers: {
        populate(state, data, tableName) {
            if (tableName === 'ReportForm') {
                if (data.ReportType === 0) {
                    data.ReportType = 1;
                    data.ReportId = 0;
                }
                return {
                    ...state,
                    [tableName]: {
                        ...state[tableName],
                        data
                    }
                };
            }
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    list: data
                }
            };
        },
        updateLoading(state, isLoading, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    loading: isLoading
                }
            };
        },
        updateGenerating(state, isGenerating, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    generating: isGenerating
                }
            };
        },
        updateSaving(state, isSaving, tableName) {
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    saving: isSaving
                }
            };
        },
        resetTable(state, tableName) {
            if (tableName === 'ReportForm') {
                return {
                    ...state,
                    ReportForm: {
                        ...state.ReportForm,
                        data: {},
                        saving: false,
                        generating: false,
                        loading: false
                    }
                };
            }
            return {
                ...state,
                [tableName]: {
                    list: [],
                    loading: false
                }
            };
        },
        resetTables(state) {
            return {
                ...state,
                savedMapUrls: { list: [], loading: false },
                patientOriginToolConfigs: { list: [], loading: false },
                facilitiesWaitTimeRetrieval: { list: [], loading: false },
                analysisToolConfigs: { list: [], loading: false },
                ReportForm: { data: {}, saving: false, generating: false, loading: false }
            };
        }
    },
    effects: (dispatch) => ({
        async fetchDropdownOptionsAsync(payload, state) {
            try {
                this.resetTable('savedMapUrls');
                this.updateLoading(true, 'savedMapUrls');
                const result = await getJSON(
                    `${reportsApiUrl}GetMultiSelectOptionsByMarket?marketname=${payload.marketName}&reportItemName=ES_MAP_URL`
                );
                this.populate(result, 'savedMapUrls');
                this.updateLoading(false, 'savedMapUrls');
            } catch (error) {
                this.updateLoading(false, 'savedMapUrls');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch user reports',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }

            try {
                this.resetTable('patientOriginToolConfigs');
                this.updateLoading(true, 'patientOriginToolConfigs');
                const result = await getJSON(
                    `${reportsApiUrl}GetMultiSelectOptionsByMarket?marketname=${payload.marketName}&reportItemName=UD_PATIENT_ORIGIN`
                );
                this.populate(result, 'patientOriginToolConfigs');
                this.updateLoading(false, 'patientOriginToolConfigs');
            } catch (error) {
                this.updateLoading(false, 'patientOriginToolConfigs');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch patiient origins',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }

            try {
                this.resetTable('analysisToolConfigs');
                this.updateLoading(true, 'analysisToolConfigs');
                const result = await getJSON(
                    `${reportsApiUrl}GetMultiSelectOptionsByMarket?marketname=${payload.marketName}&reportItemName=UD_GAP_ANALYSIS`
                );
                this.populate(result, 'analysisToolConfigs');
                this.updateLoading(false, 'analysisToolConfigs');
            } catch (error) {
                this.updateLoading(false, 'analysisToolConfigs');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch gap analysis options',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }

            try {
                this.resetTable('facilitiesWaitTimeRetrieval');
                this.updateLoading(true, 'facilitiesWaitTimeRetrieval');
                const result = await getJSON(
                    `${reportsApiUrl}FacilitiesWaitTimes?marketName=${payload.marketName}&visnCode=${payload.visnCode}`
                );
                this.populate(result, 'facilitiesWaitTimeRetrieval');
                this.updateLoading(false, 'facilitiesWaitTimeRetrieval');
            } catch (error) {
                this.updateLoading(false, 'facilitiesWaitTimeRetrieval');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch wait times',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async fetchReportByIdAsync(reportId, state) {
            try {
                this.resetTable('ReportForm');
                this.updateLoading(true, 'ReportForm');
                const result = await getJSON(`${reportsApiUrl}GetReportById?reportId=${reportId}`);
                this.populate(result, 'ReportForm');
                this.updateLoading(false, 'ReportForm');
                this.fetchDropdownOptionsAsync({
                    marketName: result.MarketName,
                    visnCode: result.VisnCode
                });
            } catch (error) {
                this.updateLoading(false, 'ReportForm');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to fetch report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async saveReportAsync(payload, state) {
            try {
                this.updateSaving(true, 'ReportForm');
                await postJSON(`${reportsApiUrl}SaveReport`, payload.formValues);
                this.updateSaving(false, 'ReportForm');
                dispatch.SavedReportModel.fetchUserReportsAsync(payload.marketName);
                dispatch(
                    addNotification({
                        message: 'The report has been saved',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
            } catch (error) {
                this.updateSaving(false, 'ReportForm');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to save report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async deleteReportAsync(payload, state) {
            try {
                this.resetTable('ReportForm');
                await deleteRequest(`${reportsApiUrl}/DeleteReport?reportId=${payload.reportId}`);
                dispatch(
                    addNotification({
                        message: 'The report has been deleted',
                        level: HSPANotificationLevel.SUCCESS
                    })
                );
                dispatch.SavedReportModel.fetchUserReportsAsync(payload.marketName);
            } catch (error) {
                dispatch(
                    addNotification({
                        message: 'Error: Unable to delete report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generateReportByIdAsync(reportId, state) {
            try {
                this.updateGenerating(true, 'ReportForm');
                await downloadFile(
                    `${exportApiUrl}GenerateReportDocumentById?reportId=${reportId}`
                );
                this.updateGenerating(false, 'ReportForm');
            } catch (error) {
                this.updateGenerating(false, 'ReportForm');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        },
        async generateReportByFormDataAsync(formValues, state) {
            try {
                this.updateGenerating(true, 'ReportForm');
                await downloadFileByFormData(
                    `${exportApiUrl}GenerateReportDocumentByFormValues`,
                    formValues
                );
                this.updateGenerating(false, 'ReportForm');
            } catch (error) {
                this.updateGenerating(false, 'ReportForm');
                dispatch(
                    addNotification({
                        message: 'Error: Unable to generate report',
                        level: HSPANotificationLevel.ERROR
                    })
                );
            }
        }
    })
};
