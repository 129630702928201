import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import _map from 'lodash/map';

import proptypes from 'prop-types';

class TabComponent extends Component {
    render() {
        return (
            <Tabs
                fill
                key={this.props.activeTab}
                activeKey={this.props.activeTab}
                onSelect={(k) => this.props.handleOnClick(Number(k))}
            >
                {_map(this.props.tabs, (item) => {
                    if (!item.hidden) {
                        return (
                            <Tab
                                key={item.key}
                                eventKey={item.key}
                                title={item.label}
                                disabled={item.disabled}
                            />
                        );
                    }
                })}
            </Tabs>
        );
    }
    static propTypes = {
        activeTab: proptypes.number.isRequired,
        tabs: proptypes.arrayOf(
            proptypes.shape({
                key: proptypes.number.isRequired,
                label: proptypes.string.isRequired,
                hidden: false,
                disabled: false
            })
        ).isRequired,
        handleOnClick: proptypes.func
    };
}
TabComponent.defaultProps = {
    activeTab: 0,
    tabs: {},
    handleOnClick: null
};

export default TabComponent;
